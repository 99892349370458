/* eslint-disable react-hooks/exhaustive-deps */
import { HiArrowsExpand, HiX } from 'react-icons/hi';

import {
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  IconButton,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@/shared/ui';

import { CampaignEditorProps, CampaignHTMLEmailEditor } from './CampaignHTMLEmailEditor';
import {
  CampaignTextEmailEditor,
  CampaignTextEmailEditorProps,
} from './CampaignTextEmailEditor';

export const CampaignHTMLEmailEditorDialog = (
  props: CampaignEditorProps &
    CampaignTextEmailEditorProps & {
      isOpen: boolean;
      onOpen: () => void;
      onClose: () => void;
    }
): JSX.Element => {
  const {
    message,
    setMessage,
    campaignId,
    emailCampaignJSONBody,
    setEmailCampaignJSONBody,
    isHTMLMessage,
    isOpen,
    onClose,
    onOpen,
    subject,
    attachments,
    setAttachments,
    channel_type,
  } = props;

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild>
        <Tooltip>
          <TooltipTrigger asChild>
            <IconButton onClick={() => onOpen()} size={2} css={{ fontSize: 20 }}>
              <HiArrowsExpand />
            </IconButton>
          </TooltipTrigger>
          <TooltipContent>
            Expand Editor
            <TooltipArrow />
          </TooltipContent>
        </Tooltip>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent
          css={{
            height: '100vh',
            width: '100vw',
            transform: 'none',
            top: 0,
            left: 0,
            marginTop: 0,
            borderRadius: 0,
          }}
          onEscapeKeyDown={() => onClose()}
          onPointerDownOutside={() => onClose()}
        >
          <DialogTitle variant="bold">
            {isHTMLMessage ? 'HTML Email' : 'Plain Text Email'}
          </DialogTitle>
          {isHTMLMessage ? (
            <CampaignHTMLEmailEditor
              message={message}
              setMessage={setMessage}
              emailCampaignJSONBody={emailCampaignJSONBody}
              setEmailCampaignJSONBody={setEmailCampaignJSONBody}
              isHTMLMessage={isHTMLMessage}
              campaignId={campaignId}
              height="calc(100vh - 150px)"
            />
          ) : (
            <CampaignTextEmailEditor
              subject={subject}
              message={message}
              setMessage={setMessage}
              attachments={attachments}
              setAttachments={setAttachments}
              channel_type={channel_type}
              emailCampaignJSONBody={emailCampaignJSONBody}
              isHTMLMessage={isHTMLMessage}
              height="calc(100vh - 150px)"
            />
          )}
          <DialogFooter
            css={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
          >
            <DialogClose>
              <Button onClick={onClose}>Save & Close</Button>
            </DialogClose>
          </DialogFooter>
          <DialogCloseIcon onClick={() => onClose()} size="2">
            <HiX size="15px" />
          </DialogCloseIcon>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
