import { Formik } from 'formik';
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { MultiStepForm } from '@/settings/organization/channels/utils/MultiStepForm';
import {
  BrandFormValidation,
  editSteps,
  validateBrandStep,
} from '@/settings/organization/trust/brands/createBrandView';
import { useTenDlc } from '@/settings/organization/trust/context/TenDlcContext';
import { TrustLayout } from '@/settings/organization/trust/TrustLayout';
import { editBrand } from '@/shared/api/tendlc';
import { SettingsLayout } from '@/shared/layouts';
import { CreateBrandType } from '@/shared/types/trust/brands';
import { Flex } from '@/shared/ui';
import { toE164 } from '@/shared/utils/validations/validations';

const breadcrumbs = [
  { title: 'Settings', path: '/settings/trust/brands' },
  { title: 'Trust Center', path: `/settings/trust/brands` },
  { title: 'Brands', path: `/settings/trust/brands` },
  { title: 'Edit Brand', path: `/settings/trust/brands/:id` },
];

interface RouteParams {
  id: string;
}

export const EditBrand = () => {
  const history = useHistory();
  const tenDlcContext = useTenDlc();
  const { tenDlcState, getBrand } = tenDlcContext;
  const { brand, loading } = tenDlcState;
  const { id: brandId } = useParams<RouteParams>();

  useEffect(() => {
    getBrand(brandId);
  }, []);

  const normalizeValues = (values: CreateBrandType): CreateBrandType => {
    return Object.keys(values).reduce((acc, key) => {
      const typedKey = key as keyof CreateBrandType;
      const value = values[typedKey];
      return {
        ...acc,
        [typedKey]: value === null ? '' : value,
      };
    }, {} as CreateBrandType);
  };

  const handleComplete = async (values: CreateBrandType) => {
    const params = {
      ...values,
      phone: toE164(values?.phone),
      alt_business_registration_type:
        values?.alt_business_registration_type !== 'NONE'
          ? values?.alt_business_registration_type
          : '',
      representative_phone: toE164(values?.representative_phone),
    };
    try {
      await editBrand(brandId, params);
      history.push('/settings/trust/brands');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <TrustLayout>
      <SettingsLayout
        background="white"
        padding="0"
        width="100%"
        breadcrumbs={breadcrumbs}
      >
        <Flex css={{ p: 30 }} direction="column">
          {brand && !loading && (
            <Formik
              initialValues={normalizeValues(brand)}
              validationSchema={BrandFormValidation}
              onSubmit={(values) => handleComplete(values)}
            >
              {(formikProps) => (
                <form onSubmit={(e) => e.preventDefault()}>
                  <MultiStepForm
                    steps={editSteps}
                    onComplete={formikProps.handleSubmit}
                    formData={formikProps.values}
                    onStepValidation={validateBrandStep}
                    onCompleteText="Edit Brand"
                  />
                </form>
              )}
            </Formik>
          )}
        </Flex>
      </SettingsLayout>
    </TrustLayout>
  );
};
