import dayjs from 'dayjs';
import React from 'react';

import {
  DatepickerDates,
  DateSelector,
} from '@/shared/components/datepicker/DateSelector';
import { Flex, VStack } from '@/shared/ui';

import { useReport } from '../context/ReportsContext';
import { ReportsContentContainer } from '../layout/ReportsContentContainer';
import { CampaignOverviews } from '../usage/CampaignOverviews';
import { UserCampaign } from '../usage/UserCampaign';

export const CampaignsReport = () => {
  const reports = useReport();
  const { dates, setDates } = reports;

  return (
    <ReportsContentContainer title="Campaigns" hideDatepicker>
      <VStack gap={5}>
        <Flex>
          <DateSelector
            dates={dates as DatepickerDates}
            setDates={setDates}
            maxDate={dayjs(new Date()).format('YYYY-MM-DD')}
          />
        </Flex>
        <CampaignOverviews />
        <UserCampaign />
      </VStack>
    </ReportsContentContainer>
  );
};
