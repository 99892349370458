import React, { useEffect } from 'react';

import { Box, Flex, Text } from '@/shared/ui';

interface TemplateSetupViewProps {
  selectedCategory: 'MARKETING' | 'UTILITY';
  setSelectedCategory: (category: 'MARKETING' | 'UTILITY') => void;
  selectedType?: string;
  setSelectedType: (type: string) => void;
  onNext?: () => void;
}

export function WhatsAppTemplateCategoryView({
  selectedCategory,
  setSelectedCategory,
  setSelectedType,
}: TemplateSetupViewProps): JSX.Element {
  // When category changes, set the type to 'custom'
  useEffect(() => {
    setSelectedType('custom');
  }, [selectedCategory, setSelectedType]);

  return (
    <Box>
      <Text css={{ color: '$gray700', mb: '$5' }}>
        Choose the category that best describes your message template. Then select the
        type of message you want to send.
      </Text>

      <Flex css={{ mb: '$6', gap: '$4' }}>
        {/* Marketing option */}
        <Flex
          css={{
            flexDirection: 'column',
            flex: 1,
            alignItems: 'flex-start',
            gap: '$3',
            p: '$3',
            borderRadius: '4px',
            border:
              selectedCategory === 'MARKETING'
                ? '1px solid #3e63dd'
                : '1px solid #E2E8F0',
            backgroundColor: 'transparent',
            cursor: 'pointer',
          }}
          onClick={() => setSelectedCategory('MARKETING')}
        >
          <Flex css={{ alignItems: 'center', gap: '$3' }}>
            <Flex
              css={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                border:
                  selectedCategory === 'MARKETING'
                    ? '2px solid #3e63dd'
                    : '2px solid #A0AEC0',
                alignItems: 'center',
                justifyContent: 'center',
                flexShrink: 0,
              }}
            >
              {selectedCategory === 'MARKETING' && (
                <Box
                  css={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: '#3e63dd',
                  }}
                />
              )}
            </Flex>
            <Text css={{ fontWeight: '500' }}>Marketing</Text>
          </Flex>
          <Text css={{ fontSize: '14px', color: '#4A5568' }}>
            This template is good for welcome messages, promotions, offers, coupons,
            newsletters, announcements
          </Text>
        </Flex>

        {/* Utility option */}
        <Flex
          css={{
            flexDirection: 'column',
            flex: 1,
            alignItems: 'flex-start',
            gap: '$3',
            p: '$3',
            borderRadius: '4px',
            border:
              selectedCategory === 'UTILITY' ? '1px solid #3e63dd' : '1px solid #E2E8F0',
            backgroundColor: 'transparent',
            cursor: 'pointer',
          }}
          onClick={() => setSelectedCategory('UTILITY')}
        >
          <Flex css={{ alignItems: 'center', gap: '$3' }}>
            <Flex
              css={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                border:
                  selectedCategory === 'UTILITY'
                    ? '2px solid #3e63dd'
                    : '2px solid #A0AEC0',
                alignItems: 'center',
                justifyContent: 'center',
                flexShrink: 0,
              }}
            >
              {selectedCategory === 'UTILITY' && (
                <Box
                  css={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: '#3e63dd',
                  }}
                />
              )}
            </Flex>
            <Text css={{ fontWeight: '500' }}>Utility</Text>
          </Flex>
          <Text css={{ fontSize: '14px', color: '#4A5568' }}>
            This template is good for order confirmations, account update, receipts,
            appointment reminders, billing
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
}
