import React from 'react';
import { Helmet } from 'react-helmet-async';
import { HiDesktopComputer, HiExternalLink, HiKey } from 'react-icons/hi';
import { useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';

import { useSidenavigationSettings } from '@/shared/components/navigation/context/SideNavigationContext';
import {
  SideNavigation,
  sideNavigationGroup,
} from '@/shared/components/navigation/SideNavigation';
import {
  SidebarNavigationContainer,
  SidebarNavigationWrapper,
} from '@/shared/components/navigation/SideNavigationContainer';
import { LayoutContent, MainLayout } from '@/shared/layouts';

import DeveloperState from '../context/DevelopersContext';

export function DeveloperNavigation() {
  const { navigationSettings, toggleExpandedState } = useSidenavigationSettings();
  const location = useLocation();
  const isApiKeys = location.pathname.startsWith('/developer/keys');
  const isWebhooks = location.pathname.startsWith('/developer/webhooks');

  const developerSideNavigationTabs: Array<sideNavigationGroup> = [
    {
      sideNavigationGroup: [
        {
          title: 'API Keys',
          image: <HiKey size={18} />,
          isActive: isApiKeys,
          link: '/developer/keys',
        },
        {
          title: 'Webhooks',
          image: <HiDesktopComputer size={18} />,
          isActive: isWebhooks,
          link: '/developer/webhooks',
        },
        {
          title: 'Documentation',
          image: <HiExternalLink size={18} />,
          isActive: false,
          href: 'https://docs.whippy.ai/docs',
          target: '_blank',
        },
      ],
    },
  ];

  return (
    <SidebarNavigationContainer>
      <SidebarNavigationWrapper>
        <SideNavigation
          expanded={navigationSettings.expanded}
          header="Developers"
          sideNavigationTabs={developerSideNavigationTabs}
          toggleExpandedState={toggleExpandedState}
        />
      </SidebarNavigationWrapper>
    </SidebarNavigationContainer>
  );
}

export const DeveloperContainer = ({ children }: { children: React.ReactNode }) => {
  const isDesktop = useMedia('(min-width: 912px)');
  return (
    <DeveloperState>
      <MainLayout>
        <Helmet>
          <title>Whippy | Developer</title>
        </Helmet>
        {isDesktop && <DeveloperNavigation />}
        <LayoutContent>{children}</LayoutContent>
      </MainLayout>
    </DeveloperState>
  );
};
