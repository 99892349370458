/* eslint-disable react-hooks/exhaustive-deps */
import Fuse from 'fuse.js';
import { Channel } from 'phoenix';
import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { toast } from 'sonner';

import { useAuth } from '@/auth/context/AuthProvider';
import { useSocket } from '@/pages/auth/context/socket/SocketProvider';
import {
  ADD_CONTACT,
  ADD_NOTE,
  ARCHIVE_CONTACT,
  BLOCK_CONTACT,
  CLEAR_CURRENT,
  CLEAR_SEARCHED_CONTACTS,
  CONTACT_ERROR,
  CREATE_CONTACT_TAG,
  DELETE_CONTACT_TAG,
  DELETE_NOTE,
  GET_CONTACT_CAMPAIGNS,
  GET_CONTACT_CONVERSATIONS,
  GET_CONTACT_SEQUENCES,
  SEARCH_CONTACT_CAMPAIGNS,
  SEARCH_CONTACT_CONVERSATIONS,
  SEARCH_CONTACTS,
  SET_CURRENT,
  SET_IS_SEARCHED,
  SET_LAST_ADDED_CONTACT,
  SET_LOADING_CONTACT,
  SET_LOADING_CONTACT_CAMPAIGNS,
  SET_LOADING_CONTACT_CONVERSATIONS,
  SET_LOADING_CONTACT_SEQUENCES,
  SET_LOADING_TAGS,
  SET_VIEW,
  UPDATE_CONTACT,
  UPDATE_CONTACT_LANGUAGE,
  UPDATE_CONTACTS_NOTES,
  UPDATE_CONTACTS_TAGS,
} from '@/pages/contacts/context/types';
import * as API from '@/shared/api/contacts/v1';
import { BulkAddTagsParams } from '@/shared/api/contacts/v1';
import {
  ContactParams,
  getContact as getContactV2,
  updateContact as updateContactV2,
} from '@/shared/api/contacts/v2';
import * as ConversationsAPI from '@/shared/api/conversations';
import { AddNote, ContactTagItem, NewContact } from '@/shared/types';
import { Campaign } from '@/shared/types/campaigns';
import { Contact } from '@/shared/types/contacts';
import { ConversationType, WhippyQueryLanguage } from '@/shared/types/conversations';
import { ContactSequence, Sequence } from '@/shared/types/sequences';
import { getViewSettings, setViewSettings } from '@/shared/utils/storage';
import i18next from '@/shared/utils/translation';

import ContactReducer from './ContactReducer';
import { useData } from './DataContext';

export type ContactsState = {
  searchedContacts: Array<Contact>;
  isSearched: boolean;
  loadingContact: boolean;
  loadingTags: boolean;
  current: Contact | null;
  lastAddedContact: Contact | null;
  error: unknown | null;
  viewSettings: Record<string, any>;
  currentContactCampaigns: Array<Campaign>;
  searchedCurrentContactCampaigns: Array<Campaign>;
  currentContactCampaignsTotal: number;
  searchedCurrentContactCampaignsTotal: number;
  loadingContactCampaigns: boolean;
  currentContactSequences: Array<ContactSequence>;
  loadingContactSequences: boolean;
  currentContactConversations: Array<ConversationType>;
  searchedCurrentContactConversations: Array<ConversationType>;
  currentContactConversationsTotal: number;
  searchedCurrentContactConversationsTotal: number;
  loadingContactConversations: boolean;
};

export const initialContactsState: ContactsState = {
  searchedContacts: [],
  isSearched: false,
  current: null,
  lastAddedContact: null,
  error: null,
  loadingContact: true,
  loadingTags: true,
  viewSettings: getViewSettings(),
  currentContactCampaigns: [],
  searchedCurrentContactCampaigns: [],
  currentContactCampaignsTotal: 0,
  searchedCurrentContactCampaignsTotal: 0,
  loadingContactCampaigns: true,
  currentContactSequences: [],
  loadingContactSequences: true,
  currentContactConversations: [],
  searchedCurrentContactConversations: [],
  currentContactConversationsTotal: 0,
  searchedCurrentContactConversationsTotal: 0,
  loadingContactConversations: true,
};

export const ContactContext = createContext<{
  contactState: ContactsState;
  updateViewSettings: (key: string, value: boolean) => Promise<void>;
  getContact: (id: string) => Promise<Contact>;
  getAndSetCurrent: (id: string) => Promise<void>;
  addContact: (contact: NewContact) => void;
  searchForContacts: (name: string, phone: string) => void;
  clearSearchedContacts: () => void;
  updateContact: (contact: Contact) => void;
  updateContactHttp: (contact: ContactParams) => Promise<Contact | void>;
  setCurrent: (contact: Contact | null) => void;
  setLastAddedContact: (contact: Contact | null) => void;
  setIsSearched: (searchStatus: boolean) => void;
  findAndSetCurrent: (contactId: string) => void;
  clearCurrent: () => void;
  getUserNotes: (contact_id: string | undefined) => Promise<void>;
  addNote: (note: AddNote) => Promise<void>;
  deleteNote: (id: string, contact_id: string) => Promise<void>;
  updateContactTags: (contactId: string, tagId: string) => void;
  deleteOneContactTag: (contactId: string, tagId: string, contact_tag?: any) => void;
  setLoadingTags: (state: boolean) => void;
  bulkAddTags: (params: BulkAddTagsParams) => void;
  blockOneContact: (id: string, isBlocked: boolean) => void;
  getContactTags: (id: string) => Promise<void | Array<ContactTagItem>>;
  getContactCampaigns: (
    contactId: string,
    params: { offset: number; limit: number },
    search?: string,
    isLoading?: boolean
  ) => Promise<void | Array<Campaign>>;
  getContactSequences: (contactId: string) => Promise<void | Array<Sequence>>;
  getContactConversations: (
    contactId: string,
    params: { offset: number; limit: number },
    search?: string,
    isLoading?: boolean
  ) => Promise<void | Array<ConversationType>>;
}>({
  contactState: initialContactsState,
  updateViewSettings: () => Promise.resolve(),
  getContact: () => Promise.resolve({} as Contact),
  getAndSetCurrent: () => Promise.resolve(),
  addContact: () => Promise.resolve(),
  searchForContacts: () => Promise.resolve(),
  clearSearchedContacts: () => Promise.resolve(),
  updateContact: () => Promise.resolve(),
  updateContactHttp: () => Promise.resolve(),
  setCurrent: () => Promise.resolve(),
  setLastAddedContact: () => Promise.resolve(),
  setIsSearched: () => Promise.resolve(),
  findAndSetCurrent: () => Promise.resolve(),
  clearCurrent: () => Promise.resolve(),
  getUserNotes: () => Promise.resolve(),
  addNote: () => Promise.resolve(),
  deleteNote: () => Promise.resolve(),
  updateContactTags: () => Promise.resolve(),
  deleteOneContactTag: () => Promise.resolve(),
  setLoadingTags: () => Promise.resolve(),
  bulkAddTags: () => Promise.resolve(),
  blockOneContact: () => Promise.resolve(),
  getContactTags: () => Promise.resolve(),
  getContactCampaigns: () => Promise.resolve(),
  getContactSequences: () => Promise.resolve(),
  getContactConversations: () => Promise.resolve(),
});

export const useContacts = () => useContext(ContactContext);

const ContactState = ({ children }: { children: React.ReactNode }) => {
  const [contactState, dispatch] = useReducer(ContactReducer, initialContactsState);

  const updateContactController = useRef<AbortController | null>(null);

  const { dataState, updateContact: updateContactData } = useData();

  const auth = useAuth();

  const { socket, joinChannel, handleConnect } = useSocket();
  const [contactsChannel, setContactsChannel] = useState<Channel | null>(null);

  // connect to organizations contacts channel
  useEffect(() => {
    if (!socket) {
      handleConnect();
    }
  }, [socket]);

  useEffect(() => {
    if (auth.organizationId) {
      const channel = joinChannel(`organization_contacts:${auth.organizationId}`);
      setContactsChannel(channel);
    }
  }, [auth.organizationId, socket]);

  // handle contact channel events
  useEffect(() => {
    if (!contactsChannel) return;

    contactsChannel.on(ARCHIVE_CONTACT, (payload: Record<string, any>) => {
      dispatch({
        type: ARCHIVE_CONTACT,
        payload: payload,
      });
    });

    contactsChannel.on(UPDATE_CONTACTS_TAGS, (payload: Record<string, any>) => {
      console.log('UPDATE_CONTACTS_TAGS', UPDATE_CONTACTS_TAGS);
      dispatch({
        type: UPDATE_CONTACTS_TAGS,
        payload: payload,
      });
      updateContactData(payload as Contact);
    });

    contactsChannel.on(UPDATE_CONTACT_LANGUAGE, (payload: Record<string, any>) => {
      dispatch({
        type: UPDATE_CONTACT_LANGUAGE,
        payload: payload,
      });
      updateContactData(payload as Contact);
    });

    contactsChannel.on(UPDATE_CONTACTS_NOTES, (payload: Record<string, any>) => {
      dispatch({
        type: UPDATE_CONTACTS_NOTES,
        payload: payload,
      });
      updateContactData(payload as Contact);
    });
  }, [contactsChannel]);

  // controls the open/close state of contacts panel
  const updateViewSettings = async (key: string, value: boolean) => {
    setViewSettings(key, value);

    dispatch({
      type: SET_VIEW,
      payload: getViewSettings(),
    });
  };

  const getContact = async (id: string) => {
    try {
      const data = await getContactV2(id);
      return data;
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: CONTACT_ERROR,
          payload: err.response.msg,
        });
      }
      return {} as Contact;
    }
  };

  const getAndSetCurrent = async (id: string) => {
    try {
      dispatch({
        type: SET_LOADING_CONTACT,
        payload: true,
      });
      const data = await getContactV2(id);

      dispatch({
        type: SET_CURRENT,
        payload: data,
      });
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: CONTACT_ERROR,
          payload: err.response.msg,
        });
      }
    } finally {
      dispatch({
        type: SET_LOADING_CONTACT,
        payload: false,
      });
    }
  };

  const searchForContacts = async (name: string, phone: string) => {
    try {
      const data = await API.searchContacts(name, phone);

      const fuse = new Fuse(data, {
        keys: ['name', 'phone'],
      });

      const results = fuse.search(name || phone).map((r) => r.item);

      dispatch({
        type: SEARCH_CONTACTS,
        payload: results,
      });
    } catch (err) {
      if (err?.response) {
        dispatch({
          type: CONTACT_ERROR,
          payload: err.response.msg,
        });
      }
    }
  };

  const clearSearchedContacts = () => {
    dispatch({
      type: CLEAR_SEARCHED_CONTACTS,
      payload: [],
    });
  };

  const updateContactHttp = async (contact: ContactParams) => {
    try {
      if (updateContactController.current) {
        updateContactController.current.abort();
        updateContactController.current = null;
      }
      const controller = new AbortController();
      updateContactController.current = controller;

      const data = await updateContactV2(contact, controller?.signal);

      dispatch({
        type: UPDATE_CONTACT,
        payload: data,
      });

      toast.success(i18next.t('contact_updated') as string);

      return data;
    } catch (err) {
      if (err?.response?.data?.errors?.length > 0) {
        toast.error(err.response?.data?.errors[0]?.description);
        dispatch({
          type: CONTACT_ERROR,
          payload: err.response?.data?.errors[0]?.description,
        });
      }
    }
  };

  const addContact = (contact: NewContact) => {
    contactsChannel?.push(ADD_CONTACT, contact);
  };

  const updateContact = (contact: Contact) => {
    contactsChannel?.push(UPDATE_CONTACT, contact);
  };

  const setCurrent = (contact: Contact | null) => {
    dispatch({ type: SET_CURRENT, payload: contact });
  };

  const setLastAddedContact = (contact: Contact | null) => {
    dispatch({ type: SET_LAST_ADDED_CONTACT, payload: contact });
  };

  const findAndSetCurrent = async (contactId: string) => {
    dispatch({
      type: SET_LOADING_CONTACT,
      payload: true,
    });
    const current = dataState.contacts.find((c: Contact) => c.id === contactId);

    if (current) {
      setCurrent(current);
    } else {
      const contact = await getContactV2(contactId);
      setCurrent(contact);
    }
    dispatch({
      type: SET_LOADING_CONTACT,
      payload: false,
    });
  };

  const clearCurrent = () => {
    dispatch({ type: CLEAR_CURRENT });
  };

  const getUserNotes = async (contact_id: string | undefined) => {
    try {
      const data = await API.getNotes(contact_id);

      dispatch({
        type: UPDATE_CONTACTS_NOTES,
        payload: { id: contact_id, notes: data },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const addNote = async (note: AddNote) => {
    contactsChannel?.push(ADD_NOTE, note);
  };

  const deleteNote = async (id: string, contact_id: string) => {
    contactsChannel?.push(DELETE_NOTE, { id: id, contact_id: contact_id });
  };

  const updateContactTags = async (contactId: string, tagId: string) => {
    contactsChannel?.push(CREATE_CONTACT_TAG, {
      contact_id: contactId,
      tag_id: tagId,
    });
  };

  const deleteOneContactTag = async (contactId: string, tagId: string) => {
    contactsChannel?.push(DELETE_CONTACT_TAG, {
      contact_id: contactId,
      contact_tag_id: tagId,
    });
  };

  const setLoadingTags = (state: boolean) => {
    dispatch({
      type: SET_LOADING_TAGS,
      payload: state,
    });
  };

  const setIsSearched = (searchStatus: boolean) => {
    dispatch({
      type: SET_IS_SEARCHED,
      payload: searchStatus,
    });
  };

  const bulkAddTags = async (params: BulkAddTagsParams) => {
    try {
      await API.bulkAddTagsToContacts(params);
      toast.success(i18next.t('tag_updated_success') as string);
    } catch (err) {
      console.error(err);
    }
  };

  const blockOneContact = async (id: string, isBlocked: boolean) => {
    const data = await API.blockContact(id, isBlocked);

    dispatch({
      type: BLOCK_CONTACT,
      payload: data,
    });
    updateContactData(data as Contact);
  };

  const getContactTags = async (id: string) => {
    try {
      const { data } = await API.getContactTags(id);
      return data;
    } catch (error) {
      console.error(error);
    }
  };

  const getContactCampaigns = async (
    contactId: string,
    params: { offset: number; limit: number },
    search = '',
    isLoading?: boolean
  ) => {
    if (params?.offset === 0 && !search && !!isLoading) {
      dispatch({
        type: SET_LOADING_CONTACT_CAMPAIGNS,
        payload: true,
      });
    }

    const searchParams = {
      ...params,
      filter: [
        {
          column: 'id',
          comparison: '==',
          resource: 'contact',
          value: contactId,
          and: [
            {
              column: 'title',
              comparison: 'ilike',
              resource: 'campaign',
              value: `%${search}%`,
              or: [
                {
                  column: 'body',
                  comparison: 'ilike',
                  resource: 'campaign_message',
                  value: `%${search}%`,
                },
              ],
            },
          ],
        },
      ],
      sort: [],
    };

    try {
      const { data } = await API.getContactCampaigns(searchParams);

      if (search) {
        dispatch({
          type: SEARCH_CONTACT_CAMPAIGNS,
          payload: { ...data, offset: params.offset, search },
        });
      } else {
        dispatch({
          type: GET_CONTACT_CAMPAIGNS,
          payload: { ...data, offset: params.offset },
        });
      }
    } catch (err) {
      dispatch({
        type: SET_LOADING_CONTACT_CAMPAIGNS,
        payload: false,
      });

      if (err?.response) {
        dispatch({
          type: CONTACT_ERROR,
          payload: err.response.msg,
        });
      }
    }
  };

  const getContactSequences = async (contactId: string, withLoading?: boolean) => {
    if (withLoading) {
      dispatch({
        type: SET_LOADING_CONTACT_SEQUENCES,
        payload: true,
      });
    }

    try {
      const { data } = await API.getContactSequences(contactId);
      console.log('data', data);
      if (data) {
        dispatch({
          type: GET_CONTACT_SEQUENCES,
          payload: data,
        });

        return data;
      }
    } catch (error) {
      if (withLoading) {
        dispatch({
          type: SET_LOADING_CONTACT_SEQUENCES,
          payload: false,
        });
      }
      console.error(error);
    }
  };

  const getContactConversations = async (
    contactId: string,
    params: { offset: number; limit: number },
    search = '',
    isLoading?: boolean
  ) => {
    if (params?.offset === 0 && !search && !!isLoading) {
      dispatch({
        type: SET_LOADING_CONTACT_CONVERSATIONS,
        payload: true,
      });
    }

    const searchParams = {
      ...params,
      filter: search
        ? [
            {
              column: 'body',
              comparison: 'ilike',
              resource: 'message',
              value: `%${search}%`,
              and: [
                {
                  column: 'id',
                  comparison: '==',
                  resource: 'contact',
                  value: contactId,
                },
              ],
            },
          ]
        : [
            {
              column: 'id',
              comparison: '==',
              resource: 'contact',
              value: contactId,
            },
          ],
      sort: [
        {
          column: 'last_message_timestamp',
          order: 'desc',
          resource: 'conversation',
        },
      ],
    } as WhippyQueryLanguage;

    try {
      const data = await ConversationsAPI.conversationSearch(searchParams);

      if (search) {
        dispatch({
          type: SEARCH_CONTACT_CONVERSATIONS,
          payload: { ...data, offset: params.offset, search },
        });
      } else {
        dispatch({
          type: GET_CONTACT_CONVERSATIONS,
          payload: { ...data, offset: params.offset },
        });
      }
    } catch (err) {
      dispatch({
        type: SET_LOADING_CONTACT_CONVERSATIONS,
        payload: false,
      });

      if (err?.response) {
        dispatch({
          type: CONTACT_ERROR,
          payload: err.response.msg,
        });
      }
    }
  };

  return (
    <ContactContext.Provider
      value={{
        contactState,
        addContact,
        setCurrent,
        setLastAddedContact,
        findAndSetCurrent,
        clearCurrent,
        updateContact,
        updateContactHttp,
        getContact,
        getAndSetCurrent,
        searchForContacts,
        clearSearchedContacts,
        updateViewSettings,
        getUserNotes,
        addNote,
        deleteNote,
        updateContactTags,
        deleteOneContactTag,
        setLoadingTags,
        setIsSearched,
        bulkAddTags,
        blockOneContact,
        getContactTags,
        getContactCampaigns,
        getContactSequences,
        getContactConversations,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};

export default ContactState;
