import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { SequenceSubNavigationItem } from '@/pages/sequences/sequence/SequenceContainer';
import { SendToProvider } from '@/settings/organization/trust/brands/sendToProvider';
import { TrustLayout } from '@/settings/organization/trust/TrustLayout';
import { SettingsLayout } from '@/shared/layouts';
import { BrandsTypes } from '@/shared/types/trust/brands';
import { Box, Button, DropdownMenuItem, Flex, HStack } from '@/shared/ui';
import { Table, TableColumn } from '@/shared/v2/components/table/Table';
import { TableActionMenu } from '@/shared/v2/components/table/TableActionMenu';
import { styled } from '@/stitches.config';

import { PAGE_SIZE, useTenDlc } from '../context/TenDlcContext';

export const TenDlcBrandsList = () => {
  const tenDlcContext = useTenDlc();
  const { tenDlcState, getAllBrands } = tenDlcContext;
  const { brands, loading } = tenDlcState;
  const history = useHistory();

  useEffect(() => {
    getAllBrands();
  }, []);

  const navigateToEditBrandPage = (brandId: string) => {
    history.push(`/settings/trust/brands/${brandId}`);
  };

  const columns: Array<TableColumn<BrandsTypes>> = useMemo(
    () => [
      {
        Header: 'Brand Name',
        colWidth: '100%',
        accessor: 'display_name',
        Cell: (props: { row: { original: BrandsTypes } }) => (
          <Flex direction="row" align="center">
            <Flex direction="column">
              <Box>{props?.row.original?.display_name || ''}</Box>
            </Flex>
          </Flex>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        Cell: (props: { row: { original: BrandsTypes } }) => (
          <>
            <TableActionMenu width={200}>
              <>
                <SendToProvider brand={props?.row?.original}>
                  <DropdownMenuItem onClick={(e) => e.preventDefault()}>
                    Create a Provider Brand
                  </DropdownMenuItem>
                </SendToProvider>
                <DropdownMenuItem
                  onClick={() => navigateToEditBrandPage(props?.row?.original?.id)}
                >
                  Edit Brand
                </DropdownMenuItem>
              </>
            </TableActionMenu>
          </>
        ),
      },
    ],
    []
  );

  const currentBreadcrumbs = [
    { title: 'Settings', path: '/settings/trust/brands' },
    { title: 'Trust Center', path: `/settings/trust/brands` },
    { title: 'Brands', path: `/settings/trust/brands` },
  ];

  const navigateToCreatePage = () => {
    history.push('/settings/trust/brands/create');
  };

  return (
    <TrustLayout>
      <SettingsLayout
        background="white"
        padding="0"
        width="100%"
        breadcrumbs={currentBreadcrumbs}
        actions={
          <HStack>
            <Button
              size="2"
              onClick={navigateToCreatePage}
              data-testid="create-brand-btn"
            >
              Create Brand
            </Button>
          </HStack>
        }
      >
        <Box>
          <TabsContainer align="center">
            <HStack>
              <SequenceSubNavigationItem selected={true}>
                Brands
              </SequenceSubNavigationItem>
            </HStack>
            <SequenceSubNavigationItem
              css={{ ml: 5 }}
              selected={false}
              onClick={() => {
                history.push('/settings/trust/campaigns');
              }}
            >
              Campaigns
            </SequenceSubNavigationItem>
          </TabsContainer>
        </Box>
        <StepsContainer
          direction="column"
          css={{
            padding: 24,
            height: 'calc(100vh - 205px)',
          }}
        >
          {brands && (
            <Table
              data={brands}
              columns={columns}
              caption="Brands Table"
              totalCount={brands?.length}
              pageSize={PAGE_SIZE}
              emptyTitle="No brands have been created."
              isLoading={loading}
              colHeight={43}
              setOffset={(offset) => offset}
            />
          )}
        </StepsContainer>
      </SettingsLayout>
    </TrustLayout>
  );
};

export const StepsContainer = styled(Flex, {
  height: '100%',
  flex: 1,
  overflow: 'auto',
  backgroundColor: '#fafafa',
});

export const TabsContainer = styled(Flex, {
  width: '100%',
  height: 60,
  px: 20,
  borderBottom: 'thin solid $gray4',
  backgroundColor: 'white',
});
