import { FormikValues, useFormikContext } from 'formik';
import React from 'react';

import { FormFieldWrapper, TextAreaInput } from '@/shared/components/forms';
import { Box, Flex } from '@/shared/ui';

export const StepFour = () => {
  const { values } = useFormikContext<FormikValues>();

  return (
    <Flex css={{ width: '100%' }} direction="column">
      <Box>
        <FormFieldWrapper name="message_flow" label="Message Flow">
          <TextAreaInput name="messageFlow" placeholder="Message flow description" />
        </FormFieldWrapper>
        <FormFieldWrapper name="messageSample1" label="Message Sample 1">
          <TextAreaInput
            name="messageSample1"
            placeholder="E.g. Thank you for messaging. A member of our team will contact you shortly."
          />
        </FormFieldWrapper>
        <FormFieldWrapper name="messageSample2" label="Message Sample 2">
          <TextAreaInput
            name="messageSample2"
            placeholder="E.g. Hi! Please, reply with your full name to confirm your appointment."
          />
        </FormFieldWrapper>
        <FormFieldWrapper name="messageSample3" label="Message Sample 3">
          <TextAreaInput
            name="messageSample3"
            placeholder="E.g. Hi! We have jobs that might interest you. Please, reply INTERESTED if you want to find out more."
          />
        </FormFieldWrapper>
        {values?.industry !== 'none' && (
          <FormFieldWrapper name="messageSample4" label="Message Sample 4">
            <TextAreaInput
              name="messageSample4"
              placeholder="E.g. Hi! We have jobs that might interest you. Please, reply INTERESTED if you want to find out more."
            />
          </FormFieldWrapper>
        )}
        {values?.industry !== 'none' && values.messageSample5 && (
          <FormFieldWrapper name="messageSample5" label="Message Sample 5">
            <TextAreaInput
              name="messageSample5"
              placeholder="E.g. Hi! We have jobs that might interest you. Please, reply INTERESTED if you want to find out more."
            />
          </FormFieldWrapper>
        )}
      </Box>
    </Flex>
  );
};
