import {
  IDeveloperField,
  IPublicConnectionMethods,
  IReviewStepData,
  IReviewStepPostHooksData,
  IRowHookInput,
  IRowHookOutput,
  IUploadStepData,
} from 'dromo-uploader-js/dist/interfaces.js';
import { isValidPhoneNumber } from 'libphonenumber-js';

import { toE164 } from './validations/validations';

export const LARGE_UPLOAD_LIMIT = 50000;
export const SMALL_UPLOAD_LIMIT = 10000;

export const dromo_settings_large = {
  displayEncoding: false,
  invalidDataBehavior: 'REMOVE_INVALID_ROWS' as const,
  allowInvalidSubmit: false,
  backendSync: true,
  manualInputDisabled: false,
  manualInputOnly: false,
  allowCustomFields: false,
  maxRecords: LARGE_UPLOAD_LIMIT,
  developmentMode: false,
  styleOverrides: {
    primaryButton: {
      backgroundColor: '#3E54CF',
      textColor: '#FFFFFF',
    },
  },
};

export const dromo_settings_small = {
  displayEncoding: false,
  invalidDataBehavior: 'REMOVE_INVALID_ROWS' as const,
  allowInvalidSubmit: false,
  backendSync: true,
  manualInputDisabled: false,
  manualInputOnly: false,
  autoMapHeaders: false,
  allowCustomFields: false,
  developmentMode: false,
  styleOverrides: {
    primaryButton: {
      backgroundColor: '#3E54CF',
      textColor: '#FFFFFF',
    },
  },
};

export const dromo_contact_fields: IDeveloperField[] = [
  {
    label: 'Full Name',
    key: 'name',
    validators: [
      {
        validate: 'regex_match',
        regex: '^.{0,250}$',
      },
    ],
  },
  {
    label: 'First Name',
    key: 'first_name',
  },
  {
    label: 'Last Name',
    key: 'last_name',
  },
  {
    label: 'Phone Number (10 digits)',
    key: 'phone',
  },
  {
    label: 'Email',
    key: 'email',
    validators: [{ validate: 'regex_match', regex: '^\\S+@\\S+\\.\\S+$' }],
  },
  {
    label: 'Area Code (3 digits)',
    key: 'area_code',
  },
  {
    label: 'Prefix & Line Number (7 digits)',
    key: 'prefix_line_number',
  },
  {
    label: 'Last Name, First Name',
    key: 'last_name_first_name',
  },
  {
    label: 'Birth Date',
    key: 'birth_date',
    type: 'date',
  },
  {
    label: 'Custom Field A',
    key: 'custom_a',
  },
  {
    label: 'Custom Field B',
    key: 'custom_b',
  },
  {
    label: 'Custom Field C',
    key: 'custom_c',
  },
];

export const dromo_contact_step_hooks = [
  {
    type: 'REVIEW_STEP' as const,
    callback: async (
      instance: IPublicConnectionMethods,
      data: IUploadStepData | IReviewStepData | IReviewStepPostHooksData
    ) => {
      const func = async (instance: IPublicConnectionMethods, data: IReviewStepData) => {
        // add phone if area code and prefix line number are provided
        // and if the phone field is not already present
        if (
          (data.fields.area_code || data.fields.prefix_line_number) &&
          !data.fields.phone
        ) {
          instance.addField({
            label: 'Phone Number',
            key: 'phone',
          });
        }

        // add field for name if last name, first name is present
        const lastNameFirstNameCasesPresent =
          data.fields.last_name_first_name ||
          data.fields.last_name ||
          data.fields.first_name;
        if (lastNameFirstNameCasesPresent && !data.fields.name) {
          instance.addField({
            label: 'name',
            key: 'name',
            validators: [
              {
                validate: 'regex_match',
                regex: '^.{0,250}$',
              },
            ],
          });
        }
      };

      return await func(instance, data as IReviewStepData);
    },
  },
];

export const dromo_contact_row_hooks = [
  (data: IRowHookInput) => {
    const out: IRowHookOutput = { row: {} };
    // if the name value is present, camel case it and remove and
    // only allow spaces between works, remove tabs and new lines,
    // trim at the end and remove any extra spaces
    if (data.row.name) {
      out.row.name = {
        value: formatName(data.row.name.value || ''),
        info: [
          {
            message: 'Contact Full Name',
            level: 'info',
          },
        ],
      };
    }

    if (data.row.last_name_first_name) {
      // convert "last name, first name" to first and last
      const firstName = data.row.last_name_first_name?.value.split(',')[1] || '';
      const lastName = data.row.last_name_first_name?.value.split(',')[0] || '';

      out.row.name = {
        value: formatName(`${firstName} ${lastName}`),
        info: [
          {
            message: 'Contact Full Name',
            level: 'info',
          },
        ],
      };
    }

    if (data.row.first_name || data.row.last_name) {
      // convert "last name, first name" to first and last
      let firstName = data.row.first_name?.value || '';
      const lastName = data.row.last_name?.value || '';
      // Remove the [E000... ] section from firstName
      // Note this is for a specific use case (Exact Staff)
      // and should be removed if not needed
      firstName = firstName.replace(/\[e\d+.*?\]/gi, '').trim();

      out.row.name = {
        value: formatName(`${firstName} ${lastName}`),
        info: [
          {
            message: 'Contact Full Name',
            level: 'info',
          },
        ],
      };
    }

    if (data.row.area_code && data.row.prefix_line_number) {
      // convert area code and prefix line number to phone number
      const phoneNumber = `${data.row.area_code.value}${data.row.prefix_line_number.value}`;

      if (isValidPhoneNumber(toE164(phoneNumber))) {
        out.row.phone = {
          value: toE164(phoneNumber),
          info: [
            {
              message: 'Valid Phone Number',
              level: 'info',
            },
          ],
        };
      } else {
        out.row.phone = {
          value: phoneNumber,
          info: [
            {
              message: 'Invalid Phone Number',
              level: 'error',
            },
          ],
        };
      }
    } else {
      if (isValidPhoneNumber(toE164(data.row.phone.value))) {
        out.row.phone = {
          value: toE164(data.row.phone.value),
          info: [
            {
              message: 'Valid Phone Number',
              level: 'info',
            },
          ],
        };
      } else {
        out.row.phone = {
          value: data.row.phone.value,
          info: [
            {
              message: 'Invalid Phone Number',
              level: 'error',
            },
          ],
        };
      }
    }

    return out;
  },
];

// take a name and format it
// remove all tabs, new lines and extra spaces
export const formatName = (str?: any | null) => {
  // Unsure why but we sometimes get a non string here and instead
  // we get dromo metadata
  if (!str || typeof str !== 'string') return '';

  // remove all tabs, new lines and extra spaces
  const clean = str
    .replace(/[\t ]+/g, ' ')
    .replace(/[\r ]+/g, ' ')
    .replace(/[\n ]+/g, ' ')
    .trim();

  return capitalizeWords(clean);
};

// take a string and capitalize each word
export const capitalizeWords = (str?: string | null) => {
  if (!str) {
    return '';
  }

  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};
