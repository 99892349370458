import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { CampaignHTMLEmailEditor } from '@/pages/campaigns/create/CampaignHTMLEmailEditor';
import { CampaignHTMLEmailEditorDialog } from '@/pages/campaigns/create/CampaignHTMLEmailEditorDialog';
import { CampaignTextEmailEditor } from '@/pages/campaigns/create/CampaignTextEmailEditor';
import { Attachments } from '@/shared/components/editor/v2/constants';
import { useDisclosure } from '@/shared/hooks';
import { JSONObject } from '@/shared/types/campaigns';
import { Box, Button, Fieldset, Flex } from '@/shared/ui';

type MessageEditorProps = {
  /*
   * the id is needed to be able to properly manage the state of pending sequences/campaigns.
   * For example, if we opened two sequences and started to create a message, we should be able to load
   * the pending message based off the id.
   */
  id: string | null;
  routeId?: string | null;
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  attachments: Attachments;
  setAttachments: Dispatch<SetStateAction<Attachments>>;
  subject: string;
  channel_type?: 'phone' | 'email' | 'whatsapp';
  emailCampaignJSONBody?: JSONObject;
  setEmailCampaignJSONBody: (JSONBody?: JSONObject | null) => void;
  isHTMLTemplate: boolean;
};

export const EmailMessageEditor = (props: MessageEditorProps): JSX.Element => {
  const {
    id,
    routeId,
    message,
    setMessage,
    attachments,
    setAttachments,
    subject,
    channel_type = 'phone',
    emailCampaignJSONBody,
    setEmailCampaignJSONBody,
    isHTMLTemplate,
  } = props;
  const [isHTMLMessage, setIsHTMLMessage] = useState(!!emailCampaignJSONBody);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!isHTMLMessage && isHTMLTemplate && emailCampaignJSONBody) {
      setIsHTMLMessage(true);
    }
  }, [isHTMLTemplate]);

  const switchToTextEditor = () => {
    setIsHTMLMessage(false);
    setEmailCampaignJSONBody(null);
  };

  const switchToEmailEditor = () => {
    setIsHTMLMessage(true);
  };

  return (
    <Box>
      <Flex justify="between">
        <Flex gap="1" css={{ marginBottom: 16 }}>
          <Button
            variant="grayBackground"
            css={isHTMLMessage ? { background: 'transparent' } : {}}
            onClick={switchToTextEditor}
          >
            Plain Text Email
          </Button>
          <Button
            variant="grayBackground"
            css={!isHTMLMessage ? { background: 'transparent' } : {}}
            onClick={switchToEmailEditor}
          >
            HTML Email
          </Button>
        </Flex>
        {((!!routeId && !!id) || (!routeId && !id)) && (
          <CampaignHTMLEmailEditorDialog
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            message={message}
            setMessage={setMessage}
            emailCampaignJSONBody={emailCampaignJSONBody}
            setEmailCampaignJSONBody={setEmailCampaignJSONBody}
            isHTMLMessage={isHTMLMessage}
            campaignId={id}
            subject={subject}
            attachments={attachments}
            setAttachments={setAttachments}
            channel_type={channel_type}
          />
        )}
      </Flex>
      <Fieldset>
        <div>
          <Box css={{ display: !isHTMLMessage ? 'none' : 'block' }}>
            {((!!routeId && !!id) || (!routeId && !id)) && (
              <CampaignHTMLEmailEditor
                message={message}
                setMessage={setMessage}
                emailCampaignJSONBody={emailCampaignJSONBody}
                setEmailCampaignJSONBody={setEmailCampaignJSONBody}
                isHTMLMessage={isHTMLMessage}
                campaignId={id}
                updateTemplate={!isOpen}
              />
            )}
          </Box>

          <CampaignTextEmailEditor
            subject={subject}
            message={message}
            setMessage={setMessage}
            attachments={attachments}
            setAttachments={setAttachments}
            channel_type={channel_type}
            emailCampaignJSONBody={emailCampaignJSONBody}
            isHTMLMessage={isHTMLMessage}
            updateTemplate={!isOpen}
          />
        </div>
      </Fieldset>
    </Box>
  );
};
