import React, { Dispatch, SetStateAction, useState } from 'react';
import { HiX } from 'react-icons/hi';

import { TagTextWrapper } from '@/pages/campaigns/audience/AudienceItem';
import { ContactTagLayout } from '@/pages/data/contacts/tags/ContactTag';
import { CircleIcon } from '@/shared/components/Icons';
import { Flex, HStack, IconButton, Input } from '@/shared/ui';
import { styled } from '@/stitches.config';

export const EmailAddressesInput = ({
  emails,
  setEmails,
}: {
  emails: Array<string>;
  setEmails: Dispatch<SetStateAction<Array<string>>>;
}) => {
  const [email, setEmail] = useState<string>('');

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (['Enter', 'Tab', ','].includes(e.key)) {
      e.preventDefault();

      const newEmail = email.trim();
      if (email && !emails.includes(newEmail)) {
        setEmails([...emails, newEmail]);
      }
      setEmail('');
    }
  };

  const handleDelete = (email: string) => {
    setEmails(emails.filter((e) => e !== email));
  };
  return (
    <EmailAddressesInputContainer justify="center" align="start" direction="column">
      <Flex gap={1}>
        {emails.map((email) => (
          <ContactTagLayout key={email} css={{ height: 30, ml: 0 }}>
            <HStack gap={1}>
              <CircleIcon />
              <TagTextWrapper>{email}</TagTextWrapper>
              <IconButton size={0} onClick={() => handleDelete(email)}>
                <HiX size={15} />
              </IconButton>
            </HStack>
          </ContactTagLayout>
        ))}
      </Flex>
      <Input
        css={{
          '&:focus': {
            backgroundColor: 'transparent',
          },
        }}
        variant="ghost"
        placeholder="Type or paste email addresses and press `Enter`"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        onKeyDown={handleKeyDown}
      />
    </EmailAddressesInputContainer>
  );
};

const EmailAddressesInputContainer = styled(Flex, {
  position: 'relative',
  boxShadow: 'inset 0 0 0 1px $colors$slate7',
  borderRadius: '$1',
  px: 8,
  background: '#FFF',
});
