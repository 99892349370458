import { Formik } from 'formik';
import * as React from 'react';
import { HiArrowsExpand, HiX } from 'react-icons/hi';

import { FormFieldWrapper } from '@/shared/components/forms/FormFieldWrapper';
import { TextAreaInput } from '@/shared/components/forms/TextAreaInput';
import { useDisclosure } from '@/shared/hooks';
import {
  Button,
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  HStack,
} from '@/shared/ui';

interface InstructionsDialogProps {
  instructions: string;
  onSave: (promptValue: string) => void;
  disabled?: boolean;
}

export const InstructionsDialog: React.FC<InstructionsDialogProps> = ({
  instructions,
  onSave,
  disabled = false,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleUpdateInstructions = (values: { instructions: string }) => {
    onSave(values.instructions);
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpen}>
      <DialogTrigger asChild>
        <Button
          css={{
            position: 'absolute',
            top: '8px',
            right: '8px',
          }}
          variant="gray"
        >
          <HiArrowsExpand />
          Edit in Full Screen
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div" css={{ zIndex: 1000 }}>
          <DialogContent
            css={{
              width: '90vw',
              height: '80vh',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <DialogTitle>Agent Instructions</DialogTitle>
            <Formik
              initialValues={{
                instructions,
              }}
              onSubmit={handleUpdateInstructions}
            >
              {(formik) => (
                <form
                  onSubmit={formik.handleSubmit}
                  data-testid="create-team-form"
                  style={{ height: '100%' }}
                >
                  <FormFieldWrapper name="instructions">
                    <TextAreaInput
                      placeholder="Enter instructions for your AI agent..."
                      css={{
                        lineHeight: '1.5',
                        height: 'calc( 100% - 70px )',
                      }}
                    />
                  </FormFieldWrapper>
                  <HStack
                    css={{
                      justifyContent: 'flex-end',
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      right: 0,
                      padding: '20px',
                      backgroundColor: 'white',
                      borderTop: '1px solid #e6e6e6',
                    }}
                  >
                    <Button type="button" variant="gray" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button type="submit" disabled={disabled}>
                      Save
                    </Button>
                  </HStack>
                </form>
              )}
            </Formik>
            <DialogCloseIcon size="2" onClick={onClose} aria-label="Close dialog">
              <HiX size="15px" />
            </DialogCloseIcon>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};
