import { FormikValues, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { Address, AddressInput } from '@/pages/data/contact/form/AddressInput';
import { stateList } from '@/settings/organization/trust/brands/lists';
import {
  FormFieldWrapper,
  helpers,
  SelectCombobox,
  TextInput,
} from '@/shared/components/forms';
import { Box } from '@/shared/ui';

type addressFieldType = {
  name: string;
  label: string;
  placeholder?: string;
  tooltip?: string;
};

const addressFields = [
  {
    name: 'city',
    label: 'City',
    placeholder: "Add contact's city",
    tooltip: 'City name.',
  },
  {
    name: 'state',
    label: 'State',
    tooltip: 'State. Must be 2 letters code for United States.',
  },
  {
    name: 'postal_code',
    label: 'Postal Code',
    placeholder: '60654',
    tooltip: 'Postal codes. Use 5 digit zip code for United States.',
  },
];

export const ContactAddressInput = () => {
  const formik = useFormikContext<FormikValues>();

  const [address, setAddress] = useState<Address>({});

  useEffect(() => {
    address.address_line_one &&
      formik.setValues((p) => ({
        ...p,
        street: address.address_line_one,
        city: address.city,
        state: address.state,
        postal_code: address.post_code,
      }));
  }, [address.address_line_one, address.city, address.state, address.post_code]);

  useEffect(() => {
    address.fullAddress = formik?.values?.street;
  }, []);

  return (
    <>
      <FormFieldWrapper
        label="Primary Address"
        tooltip="Street number and name."
        name="street"
      >
        <AddressInput
          address={address}
          setAddress={setAddress}
          name="street"
          placeholder="19240 Nordhoff St Suite C2"
        />
      </FormFieldWrapper>
      <Box css={{ mt: 10 }}>
        {addressFields?.map(({ label, name, placeholder, tooltip }: addressFieldType) => (
          <FormFieldWrapper label={label} name={name} key={name} tooltip={tooltip}>
            {name === 'state' ? (
              <SelectCombobox
                placeholder={formik.values.state ?? helpers.displaySelectedItem}
                isDropdown={true}
                options={stateList}
              />
            ) : (
              <TextInput placeholder={placeholder} />
            )}
          </FormFieldWrapper>
        ))}
      </Box>
    </>
  );
};
