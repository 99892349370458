import React, { createContext, useContext, useEffect, useReducer, useRef } from 'react';
import { toast } from 'sonner';

import { prepareFilters } from '@/pages/data/utils/prepareFilters';
import * as AgentAPI from '@/shared/api/ai/agents';
import * as LlmAPI from '@/shared/api/ai/llms';
import { searchCalls } from '@/shared/api/calls';
import { AgentActionTypes, AgentStateType } from '@/shared/types/ai';
import {
  Agent,
  AgentStatus,
  AgentVersion,
  CreateAgentParams,
  UpdateAgentParams,
} from '@/shared/types/ai/agents';
import { LLM, LLMParams } from '@/shared/types/ai/llms';
import { Call } from '@/shared/types/calls';
import { SearchFilters } from '@/shared/types/contacts';
import { FilterItem, Sort } from '@/shared/types/filter';

import { AgentReducer } from './AgentsReducer';

export const defaultSort: Array<Sort> = [
  {
    label: 'Updated At',
    column: 'updated_at',
    resource: 'agent',
    order: 'desc',
    id: 1,
  },
];

export const defaultCallsSort: Array<Sort> = [
  {
    label: 'Updated At',
    column: 'updated_at',
    resource: 'message_attachment',
    order: 'desc',
    id: 1,
  },
];

export const initialState: AgentStateType = {
  llms: { data: [], total: 0 },
  agents: { data: [], total: 0 },
  agentsFilters: {
    filter: [],
    searchFilter: [],
    defaultFilters: [
      {
        resource: 'agent',
        column: 'status',
        comparison: '!=',
        value: 'archived',
      },
    ],
    sort: [],
    limit: 10,
    offset: 0,
  },
  calls: { data: [], total: 0 },
  callsFilters: {
    filter: [],
    searchFilter: [],
    defaultFilters: [],
    sort: [],
    limit: 10,
    offset: 0,
  },
  currentAgent: null,
  currentLLM: null,
  loading: true,
};

export const AgentContext = createContext<{
  state: AgentStateType;
  fetchLLMs: (
    filter: Array<FilterItem>,
    sort: Array<Sort>,
    limit: number,
    offset: number
  ) => Promise<LLM[]>;
  getLLM: (id: string) => Promise<LLM>;
  fetchAgents: (
    filter: Array<FilterItem>,
    sort: Array<Sort>,
    limit: number,
    offset: number
  ) => Promise<Agent[]>;
  getAgent: (id: string) => Promise<Agent>;
  getAgentVersion: (id: string) => Promise<AgentVersion>;
  createLLM: (llm: LLMParams) => Promise<LLM>;
  updateLLM: (id: string, llm: LLMParams) => Promise<LLM>;
  deleteLLM: (id: string) => Promise<void>;
  createAgent: (agent: CreateAgentParams) => Promise<Agent>;
  updateAgent: (id: string, agent: UpdateAgentParams) => Promise<Agent>;
  deleteAgent: (id: string, agent: Agent) => Promise<void>;
  setCurrentAgent: (agent: Agent) => void;
  setCurrentLLM: (llm: LLM) => void;
  updateAgentsFilters: (param: SearchFilters) => void;
  fetchCalls: (
    filter: Array<FilterItem>,
    sort: Array<Sort>,
    limit: number,
    offset: number
  ) => Promise<Call[]>;
  updateCallsFilters: (param: SearchFilters) => void;
}>({
  state: initialState,
  fetchLLMs: async () => Promise.resolve([] as LLM[]),
  getLLM: async () => Promise.resolve({} as LLM),
  fetchAgents: async () => Promise.resolve([] as Agent[]),
  getAgent: async () => Promise.resolve({} as Agent),
  getAgentVersion: async () => Promise.resolve({} as AgentVersion),
  createLLM: async () => Promise.resolve({} as LLM),
  updateLLM: async () => Promise.resolve({} as LLM),
  deleteLLM: async () => Promise.resolve(),
  createAgent: async () => Promise.resolve({} as Agent),
  updateAgent: async () => Promise.resolve({} as Agent),
  deleteAgent: async () => Promise.resolve(),
  setCurrentAgent: () => Promise.resolve({} as Agent),
  setCurrentLLM: () => Promise.resolve({} as LLM),
  updateAgentsFilters: () => Promise.resolve(),
  fetchCalls: async () => Promise.resolve([] as Call[]),
  updateCallsFilters: () => Promise.resolve(),
});

const AgentProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(AgentReducer, initialState);
  const currentController = useRef<AbortController | null>(null);

  const fetchLLMs = async (
    filter: Array<FilterItem>,
    sort: Array<Sort>,
    limit: number,
    offset: number
  ): Promise<Array<LLM>> => {
    dispatch({ type: AgentActionTypes.SET_LOADING, payload: true });
    try {
      const llms = await LlmAPI.searchLLMs(filter, sort, limit, offset);
      dispatch({ type: AgentActionTypes.GET_LLMS, payload: llms });
      return llms.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  const getLLM = async (id: string): Promise<LLM> => {
    try {
      const { data } = await LlmAPI.getLLM(id);
      dispatch({ type: AgentActionTypes.GET_LLM, payload: data });
      return data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  useEffect(() => {
    const filters = prepareFilters(state.agentsFilters);
    fetchAgents(filters.filter, filters.sort, filters.limit, filters.offset);
  }, [state.agentsFilters]);

  useEffect(() => {
    const filters = prepareFilters(state.callsFilters);
    fetchCalls(filters.filter, filters.sort, filters.limit, filters.offset);
  }, [state.callsFilters]);

  const fetchAgents = async (
    filter: Array<FilterItem>,
    sort: Array<Sort>,
    limit = 100,
    offset = 0
  ): Promise<Array<Agent>> => {
    dispatch({ type: AgentActionTypes.SET_LOADING, payload: true });

    try {
      if (currentController.current) {
        currentController.current.abort();
        currentController.current = null;
      }
      const controller = new AbortController();
      currentController.current = controller;
      const agents = await AgentAPI.searchAgents(
        filter,
        sort,
        limit,
        offset,
        controller.signal
      );
      dispatch({ type: AgentActionTypes.GET_AGENTS, payload: agents });
      return agents.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  const getAgent = async (id: string): Promise<Agent> => {
    try {
      const { data } = await AgentAPI.getAgent(id);
      dispatch({ type: AgentActionTypes.GET_AGENT, payload: data });
      return data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  const getAgentVersion = async (id: string): Promise<AgentVersion> => {
    try {
      const { data } = await AgentAPI.getAgentVersion(id);
      return data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  const createLLM = async (llm: LLMParams): Promise<LLM> => {
    try {
      const { data } = await LlmAPI.createLLM(llm);
      dispatch({ type: AgentActionTypes.CREATE_LLM, payload: data });
      return data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  const updateLLM = async (id: string, llm: LLMParams): Promise<LLM> => {
    try {
      const { data } = await LlmAPI.updateLLM(id, llm);
      dispatch({ type: AgentActionTypes.UPDATE_LLM, payload: data });
      toast.success('Agent updated successfully');
      return data;
    } catch (error) {
      console.error(error);
      toast.error('Failed to update Agent');
      return Promise.reject(error);
    }
  };

  const deleteLLM = async (id: string): Promise<void> => {
    try {
      await LlmAPI.deleteLLM(id);
      dispatch({ type: AgentActionTypes.DELETE_LLM, payload: id });
      return Promise.resolve();
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  const createAgent = async (agent: CreateAgentParams): Promise<Agent> => {
    try {
      const newAgent = await AgentAPI.createAgent(agent);
      dispatch({ type: AgentActionTypes.CREATE_AGENT, payload: newAgent.data });
      toast.success('Agent created successfully');
      return newAgent.data;
    } catch (error) {
      console.error(error);
      toast.error('Failed to create Agent');
      return Promise.reject(error);
    }
  };

  const updateAgent = async (id: string, agent: UpdateAgentParams): Promise<Agent> => {
    try {
      const updatedAgent = await AgentAPI.updateAgent(id, agent);
      dispatch({ type: AgentActionTypes.UPDATE_AGENT, payload: updatedAgent.data });
      toast.success('Agent updated successfully');
      return updatedAgent.data;
    } catch (error) {
      console.error(error);
      toast.error('Failed to update Agent');
      return Promise.reject(error);
    }
  };

  const deleteAgent = async (id: string, agent: Agent): Promise<void> => {
    try {
      await AgentAPI.updateAgent(id, {
        agent: {
          status: AgentStatus.Archived,
          name: agent.current_version.name || 'Default Name', // Provide a default name if undefined
          description: agent.current_version.description || '', // Provide a default description if undefined
          ...agent.current_version,
        },
      });
      dispatch({ type: AgentActionTypes.DELETE_AGENT, payload: id });
      return Promise.resolve();
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  const setCurrentAgent = (agent: Agent) => {
    dispatch({ type: AgentActionTypes.SET_CURRENT_AGENT, payload: agent });
  };

  const setCurrentLLM = (llm: LLM) => {
    dispatch({ type: AgentActionTypes.SET_CURRENT_LLM, payload: llm });
  };

  const updateAgentsFilters = (params: SearchFilters) => {
    dispatch({
      type: AgentActionTypes.UPDATE_AGENT_FILTERS,
      payload: { filters: params, is_load_more: params.offset > 0 },
    });
  };

  const fetchCalls = async (
    filter: Array<FilterItem>,
    sort: Array<Sort>,
    limit = 100,
    offset = 0
  ): Promise<Array<Call>> => {
    dispatch({ type: AgentActionTypes.SET_LOADING, payload: true });

    try {
      if (currentController.current) {
        currentController.current.abort();
        currentController.current = null;
      }
      const controller = new AbortController();
      currentController.current = controller;
      const calls = await searchCalls(filter, sort, limit, offset, controller.signal);
      dispatch({ type: AgentActionTypes.GET_CALLS, payload: calls });
      return calls.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  const updateCallsFilters = (params: SearchFilters) => {
    dispatch({
      type: AgentActionTypes.UPDATE_CALL_FILTERS,
      payload: { filters: params, is_load_more: params.offset > 0 },
    });
  };

  return (
    <AgentContext.Provider
      value={{
        state,
        fetchLLMs,
        fetchAgents,
        getAgent,
        getAgentVersion,
        getLLM,
        createLLM,
        updateLLM,
        deleteLLM,
        createAgent,
        updateAgent,
        deleteAgent,
        setCurrentAgent,
        setCurrentLLM,
        updateAgentsFilters,
        fetchCalls,
        updateCallsFilters,
      }}
    >
      {children}
    </AgentContext.Provider>
  );
};

export const useLLMAgent = () => useContext(AgentContext);

export default AgentProvider;
