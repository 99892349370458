import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { HiChevronLeft, HiPlus, HiSearch, HiX } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import { DrawerHeaderContainer } from '@/pages/data/contacts/CreateContact';
import { useTags } from '@/pages/settings/organization/tags/context/TagsContext';
import { Contact, ContactTagItem, ContactTagType } from '@/shared/types';
import { Tag as TagType } from '@/shared/types/tags';
import {
  Box,
  Button,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerPortal,
  DrawerTrigger,
  Flex,
  HStack,
  IconButton,
  Input,
  Skeleton,
  Text,
  VStack,
} from '@/shared/ui';
import { ComboboxMultiselect } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselect';
import { ComboboxMultiselectItem } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectItem';
import { styled } from '@/stitches.config';

import { useUploads } from '../../../contacts/uploads/context/UploadContext';
import { CreateUpload } from '../../../contacts/uploads/CreateUpload';
import { useContacts } from '../../context/ContactContext';
import { AccordionValue } from '../';
import { ContactAccordion } from '../ContactAccordion';
import { ContactTag } from './ContactTag';

export const ContactTags = ({
  contact,
  loading,
  defaultSelectedTags,
  type = ContactTagType.STANDARD,
  accordionValue = AccordionValue.CONTACT_TAGS,
}: {
  contact?: Contact | null;
  loading: boolean;
  defaultSelectedTags: string[] | [];
  type?: ContactTagType;
  accordionValue?: AccordionValue;
}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>(defaultSelectedTags);

  // contacts context
  const { updateContactHttp } = useContacts();
  // tags context
  const {
    tagsState: { allTags },
  } = useTags();
  // uploads context
  const {
    uploadsState: { allUploads },
  } = useUploads();

  const history = useHistory();

  const handleUpdateTags = useCallback(
    debounce(async (values) => {
      if (contact?.id) {
        // remove duplicates
        const mergedTagIds = [...new Set(values)];
        const field = type === ContactTagType.UPLOAD ? 'contact_lists' : 'contact_tags';
        await updateContactHttp({
          id: contact?.id,
          [field]: mergedTagIds,
        });
      }
    }, 1000),
    [contact?.id]
  );

  const handleDeleteTag = useCallback(
    (id: string) => {
      const newItems = selectedItems.filter((item: string) => item !== id);
      setSelectedItems(newItems);
      handleUpdateTags(newItems);
    },
    [selectedItems]
  );

  // render tag item function
  const renderItem = useCallback(
    (contact_tag: ContactTagItem) => (
      <TagItem
        key={contact_tag.id}
        contactId={contact?.id}
        contactTag={contact_tag}
        onDelete={handleDeleteTag}
        type={type}
      />
    ),
    [contact?.id, handleDeleteTag]
  );

  // redirects to tags page if there are no tags created
  const redirectToTagsPage = () => {
    history.replace({ pathname: '/settings/tags' });
  };

  // list of tags
  const tagsList = useMemo(
    () =>
      type === ContactTagType.UPLOAD
        ? allUploads
        : allTags.filter((tag: TagType) => tag.type === 'standard'),
    [type, allTags, allUploads]
  );

  useEffect(() => {
    setSelectedItems(defaultSelectedTags);
  }, [defaultSelectedTags]);

  const handleSelectedItems = (values: string[]) => {
    setSelectedItems(values);
    handleUpdateTags(values);
  };

  const data = type === ContactTagType.UPLOAD ? allUploads : tagsList;
  const contactData =
    type === ContactTagType.UPLOAD ? contact?.contact_lists : contact?.contact_tags;

  const renderSelectButton = () => (
    <ComboboxMultiselect
      options={data?.map((tag: TagType) => ({
        label: tag.name as string,
        value: tag.id,
        color: tag.color,
      }))}
      selected={selectedItems}
      onSelect={handleSelectedItems}
      searchLabel="Search Tags"
      Trigger={() => (
        <Button size={1}>
          <HiPlus />
          <Text
            css={{ color: 'currentColor' }}
          >{`Add ${type === ContactTagType.UPLOAD ? 'to List' : 'Tag'}`}</Text>
        </Button>
      )}
      Option={ComboboxMultiselectItem}
      width={300}
      selectAll
      visualized
    />
  );

  return (
    <ContactAccordion
      title={type === ContactTagType.UPLOAD ? 'Lists' : 'Tags'}
      accordionValue={accordionValue}
      defaultValue={accordionValue}
    >
      {contact?.id && !loading && (
        <>
          {data?.length === 0 ? (
            <>
              {type === ContactTagType.UPLOAD ? (
                <CreateUpload>
                  <Button size={1} type="button">
                    <HiPlus />
                    <Text css={{ color: 'currentColor' }}>Create Upload</Text>
                  </Button>
                </CreateUpload>
              ) : (
                <Button size={1} onClick={redirectToTagsPage}>
                  <HiPlus />
                  <Text css={{ color: 'currentColor' }}>Create Tag</Text>
                </Button>
              )}
            </>
          ) : (
            <Flex
              direction="column"
              justify="center"
              css={{ textAlign: contactData?.length ? 'left' : 'center' }}
            >
              {!contactData?.length && (
                <>
                  <Box css={{ fontSize: 16, fontWeight: '700', marginBottom: 8 }}>
                    {type === ContactTagType.UPLOAD ? 'No Lists Yet' : 'No Tags Yet'}
                  </Box>
                  <Box css={{ fontSize: 16, mb: 16 }}>
                    {type === ContactTagType.UPLOAD
                      ? 'Add this user to the list using the button below'
                      : 'Add a tag using the button below'}
                  </Box>
                </>
              )}
              {renderSelectButton()}
            </Flex>
          )}
        </>
      )}
      {loading ? (
        <Flex css={{ flexWrap: 'wrap' }}>
          {Array.from({ length: 5 }, (_: any, k: React.Key | null | undefined) => (
            <Skeleton key={k} variant="tag" css={{ height: 25, width: 88, ml: 0 }} />
          ))}
        </Flex>
      ) : contactData?.length ? (
        <Flex css={{ flexWrap: 'wrap', mt: 12 }}>
          {contactData?.slice(0, 10).map(renderItem)}
        </Flex>
      ) : null}
      {!loading && contactData && contactData?.length > 10 && (
        <TagsDrawer
          data={contactData}
          contactId={contact?.id || ''}
          tags={data}
          selectedItems={selectedItems}
          setSelectedItems={handleSelectedItems}
          onDelete={handleDeleteTag}
          type={type}
        >
          <Button
            ghost
            size={1}
            css={{
              color: '#60646C',
              mt: 8,
              fontSize: 14,
              boxShadow: 'none',
              '&:focus': {
                boxShadow: 'none',
              },
            }}
          >
            {`See All ${type === ContactTagType.UPLOAD ? 'Lists' : 'Tags'}`}
          </Button>
        </TagsDrawer>
      )}
    </ContactAccordion>
  );
};

export const TagItem = ({
  contactTag,
  contactId,
  onDelete,
  type,
}: {
  contactTag: ContactTagItem;
  contactId?: string;
  onDelete?: (id: string) => void;
  type?: ContactTagType;
}) => {
  if (contactTag.tag) {
    return (
      <ContactTag
        key={contactTag.tag.id}
        text={contactTag.tag.name}
        color={contactTag.tag.color}
        id={contactTag.tag.id}
        contactId={contactId}
        onDelete={onDelete}
        type={type}
      />
    );
  }
  return null;
};

export const TagsDrawer = ({
  children,
  data,
  contactId,
  tags,
  selectedItems,
  setSelectedItems,
  onDelete,
  type = ContactTagType.STANDARD,
}: {
  children: React.ReactNode;
  data: ContactTagItem[];
  contactId: string;
  tags: TagType[];
  selectedItems: string[];
  setSelectedItems: (value: string[]) => void;
  onDelete?: (id: string) => void;
  type?: ContactTagType;
}) => {
  const [search, setSearch] = useState('');

  const handleSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleClearSearch = () => {
    setSearch('');
  };

  const filteredTags = useMemo(
    () =>
      data.filter((contactTag) =>
        (contactTag?.tag?.name || '').toLowerCase().includes(search.toLowerCase())
      ),
    [data, search]
  );

  // render tag item function
  const renderItem = useCallback(
    (contact_tag: ContactTagItem) => (
      <TagItem
        key={contact_tag.id}
        contactId={contactId}
        contactTag={contact_tag}
        onDelete={onDelete}
        type={type}
      />
    ),
    [contactId, onDelete]
  );

  const title = type === ContactTagType.UPLOAD ? 'Lists' : 'Tags';

  return (
    <Drawer>
      <DrawerTrigger asChild>{children}</DrawerTrigger>
      <DrawerPortal>
        <DrawerContent
          aria-describedby={`Contact ${title}`}
          css={{
            maxWidth: '420px',
            minWidth: '360px',
            top: 0,
            height: '100%',
            zIndex: 99,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Flex>
            <DrawerHeaderContainer css={{ px: 20 }}>
              <HStack>
                <DrawerClose>
                  <HiChevronLeft />
                </DrawerClose>
                <Heading css={{ textTransform: 'capitalize', ml: 20 }}>{title}</Heading>
              </HStack>
            </DrawerHeaderContainer>
          </Flex>
          <SearchContainer>
            <SearchIconContainer>
              <HiSearch />
            </SearchIconContainer>
            <SearchInput
              placeholder={`Search ${title}`}
              value={search}
              css={{ pl: 38 }}
              onChange={handleSearch}
            />
            {search.length > 2 && (
              <SearchControlsContainer css={{ right: 5 }}>
                <IconButton onClick={handleClearSearch}>
                  <HiX />
                </IconButton>
              </SearchControlsContainer>
            )}
          </SearchContainer>
          <DrawerContentContainer>
            <VStack gap={1} css={{ pt: 24 }}>
              <ComboboxMultiselect
                options={tags.map((tag: TagType) => ({
                  label: tag.name as string,
                  value: tag.id,
                  color: tag.color,
                }))}
                selected={selectedItems}
                onSelect={setSelectedItems}
                searchLabel={`Search ${title}`}
                Trigger={() => (
                  <Button size={1}>
                    <HiPlus />
                    <Text
                      css={{ color: 'currentColor' }}
                    >{`Add ${type === ContactTagType.UPLOAD ? 'to List' : 'Tag'}`}</Text>
                  </Button>
                )}
                Option={ComboboxMultiselectItem}
                width={300}
                selectAll
              />
              <Flex css={{ flexWrap: 'wrap', pt: 10, pb: 24 }}>
                {filteredTags?.map(renderItem)}
              </Flex>
            </VStack>
          </DrawerContentContainer>
        </DrawerContent>
      </DrawerPortal>
    </Drawer>
  );
};

export const DrawerContentContainer = styled(Flex, {
  position: 'relative',
  height: '100%',
  flex: 1,
  overflow: 'auto',
  px: 24,
});

export const Heading = styled(Flex, {
  flex: 'initial 0 initial',
  fontSize: 17,
  fontWeight: 800,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const SearchContainer = styled(Box, {
  position: 'relative',
  width: '100%',
  px: 16,
  py: 12,
  borderBottom: 'thin solid var(--colors-gray4)',
});

const SearchInput = styled(Input, {
  boxShadow: 'none',
  height: 32,
  '&:focus': {
    boxShadow: 'none',
  },
});

const SearchIconContainer = styled(Box, {
  position: 'absolute',
  top: 29,
  transform: 'translateY(-50%)',
  pointerEvents: 'none',
  left: 20,
});

const SearchControlsContainer = styled(Box, {
  position: 'absolute',
  top: 29,
  transform: 'translateY(-50%)',
  right: 16,
});
