import { CustomObject } from '@/shared/types/data';
import { FilterTypeInputType } from '@/shared/types/filter';

import { ResourcePropertiesMapping } from '../FilterBuilder';

export const generateCustomResourcesAndProperties = (customObjects: CustomObject[]) => {
  // Dynamically generate resources from allObjects
  const customResources = customObjects.map((obj) => ({
    key: obj.key,
    label: obj.label,
  }));

  // Generate resourcePropertiesMapping from customObjects
  const customResourcePropertiesMapping = customObjects.reduce<ResourcePropertiesMapping>(
    (acc, obj) => {
      acc[obj.key] = obj.custom_properties.map(({ key, type, label }) => ({
        key,
        type: type as FilterTypeInputType,
        label,
      }));
      return acc;
    },
    {}
  );

  // Optionally, define properties for 'join_custom_data' if needed
  // For example, if 'join_custom_data' should have specific properties, add them here
  // resourcePropertiesMapping['join_custom_data'] = [{ key: 'customKey', type: 'text' }];
  return { customResources, customResourcePropertiesMapping };
};
