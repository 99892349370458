/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { Box, DropdownMenuItem, DropdownMenuItemWarning, Flex } from '@/shared/ui';
import { Table, TableColumn } from '@/shared/v2/components/table/Table';
import { TableActionMenu } from '@/shared/v2/components/table/TableActionMenu';
import { styled } from '@/stitches.config';

import { CustomObject } from '../../../../shared/types/data';
import { PAGE_SIZE, useCustomData } from './context/CustomDataContext';

const DataTable = () => {
  const {
    deleteCustomObject,
    setCurrent,
    updateFilterParams,
    customDataState: { filterParams, customObjects, totalCount, loading },
  } = useCustomData();

  const history = useHistory();

  const handleEdit = (item: CustomObject) => {
    setCurrent(item);
    history.push(`/settings/data/${item.id}`);
  };

  const columns: Array<TableColumn<CustomObject>> = useMemo(
    () => [
      {
        Header: 'Name',
        colWidth: '90%',
        accessor: 'name',
        Cell: (props: { row: { original: CustomObject } }) => (
          <Flex direction="column">
            <DataTitle>{props.row.original.label}</DataTitle>
          </Flex>
        ),
      },
      {
        Header: 'Created At',
        id: 'inserted_at',
        accessor: 'inserted_at',
        colWidth: '100%',
        Cell: (props: { row: { original: CustomObject } }) => (
          <Flex align="center" css={{ minWidth: 125 }}>
            <Box>{dayjs(props.row.original.inserted_at).format('MMM D YYYY')}</Box>
          </Flex>
        ),
      },
      {
        Header: 'Updated At',
        id: 'updated_at',
        accessor: 'updated_at',
        colWidth: '100%',
        Cell: (props: { row: { original: CustomObject } }) => (
          <Flex align="center" css={{ minWidth: 125 }}>
            <Box>{dayjs(props.row.original.updated_at).format('MMM D YYYY')}</Box>
          </Flex>
        ),
      },
      {
        Header: '',
        colWidth: '10%',
        accessor: 'action',
        Cell: (props: { row: { original: CustomObject } }) => (
          <TableActionMenu>
            {!!props.row.original && (
              <>
                <DropdownMenuItem
                  data-testid="edit-option"
                  onClick={(e) => {
                    e.preventDefault();
                    handleEdit(props.row.original);
                  }}
                >
                  Edit Object
                </DropdownMenuItem>
                <ConfirmationDialog
                  width="432px"
                  title="Delete Object"
                  description="Are you sure you want to delete this custom object?"
                  onConfirm={() => deleteCustomObject(props.row.original.id)}
                  confirmButtonTitle="Yes, Delete Object"
                  cancelButtonTitle="Cancel"
                  confirmButtonVariant="primary"
                  cancelButtonVariant="grayBackground"
                >
                  <DropdownMenuItemWarning
                    data-testid="delete-option"
                    onClick={(e) => e.preventDefault()}
                  >
                    Delete Object
                  </DropdownMenuItemWarning>
                </ConfirmationDialog>
              </>
            )}
          </TableActionMenu>
        ),
      },
    ],
    [customObjects]
  );

  return (
    <Table
      data={customObjects}
      columns={columns}
      caption="Custom Objects"
      totalCount={totalCount}
      setOffset={(offset) => {
        updateFilterParams({
          ...filterParams,
          offset,
        });
      }}
      pageSize={PAGE_SIZE}
      isLoading={loading}
      emptyTitle="No Custom Data Match Search"
      colHeight={43}
    />
  );
};

const DataTitle = styled(Box, {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: 200,
  textOverflow: 'ellipsis',
  fontWeight: '500',
  color: '$slate12',
});

export default DataTable;
