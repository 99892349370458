import { useFlags } from 'launchdarkly-react-client-sdk';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';

import { useAuth } from '@/auth/context/AuthProvider';
import { usePageView } from '@/shared/hooks';
import { SettingsLayout } from '@/shared/layouts';
import { Button, HStack } from '@/shared/ui';

import { AddChannelV1 } from './AddChannelV1';
import { ChannelsTable } from './ChannelsTable';
export * from './channel';

export const Channels = () => {
  usePageView();

  const auth = useAuth();
  const isWhippyAdmin =
    auth?.tokens?.email && auth?.tokens?.email.includes('whippy' || 'hellowhippy');

  const history = useHistory();

  const handleCreateChannel = () => {
    history.push('/settings/channels/create');
  };

  const { allowAddCustomChannelKeys } = useFlags();

  return (
    <>
      <Helmet>
        <title>Whippy | Channels</title>
      </Helmet>
      <SettingsLayout
        background="#fafafa"
        padding="0"
        width="100%"
        breadcrumbs={[
          { title: 'Settings', path: '/settings/channels' },
          { title: 'Channels', path: `/settings/channels` },
        ]}
        actions={
          <HStack>
            {isWhippyAdmin && <AddChannelV1 />}
            {allowAddCustomChannelKeys && (
              <Button size="2" onClick={handleCreateChannel}>
                Add Channel
              </Button>
            )}
          </HStack>
        }
      >
        <ChannelsTable />
      </SettingsLayout>
    </>
  );
};
