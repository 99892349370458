import { CampaignData } from '@/shared/types/trust/campaigns';

export const validateCampaignStep = async (stepIndex: number, formData: CampaignData) => {
  switch (stepIndex) {
    case 0:
      return Boolean(formData.brand && formData.description && formData.use_case);
    case 1:
      return true;
    case 2:
      return Boolean(formData.help_message);
    case 3:
      return Boolean(
        formData.message_flow &&
          (formData.messageSample1 || formData.messageSample2 || formData.messageSample3)
      );
    case 4:
      return true;
    default:
      return true;
  }
};

export const validateEditCampaignStep = async (
  stepIndex: number,
  formData: CampaignData
) => {
  switch (stepIndex) {
    case 0:
      return Boolean(formData.description && formData.use_case);
    case 1:
      return true;
    case 2:
      return Boolean(formData.help_message);
    case 3:
      return Boolean(
        formData.message_flow &&
          (formData.messageSample1 || formData.messageSample2 || formData.messageSample3)
      );
    case 4:
      return true;
    default:
      return true;
  }
};
