import React from 'react';

import { FormFieldWrapper, PhoneNumberInput, TextInput } from '@/shared/components/forms';
import { Box, Flex } from '@/shared/ui';

export const StepOne = () => {
  return (
    <Flex css={{ width: '100%' }} direction="column">
      <Box>
        <FormFieldWrapper
          label="Company Name"
          tooltip="Legal company name."
          name="company_name"
        >
          <TextInput placeholder="Example Company Inc." />
        </FormFieldWrapper>
        <FormFieldWrapper
          label="Display Name"
          tooltip="Display or marketing name of the brand."
          name="display_name"
        >
          <TextInput placeholder="Example Company" />
        </FormFieldWrapper>
        <FormFieldWrapper
          label="Description"
          tooltip="Brand description"
          name="description"
        >
          <TextInput placeholder="Example Company" />
        </FormFieldWrapper>
        <FormFieldWrapper
          label="Business Email"
          tooltip="Valid email address of brand support contact."
          name="email"
        >
          <TextInput placeholder="examplename@examplecompany.com" />
        </FormFieldWrapper>
        <FormFieldWrapper
          label="Business Phone Number"
          tooltip="Valid phone number of brand support contact."
          name="phone"
        >
          <PhoneNumberInput placeholder="+13259390512" />
        </FormFieldWrapper>
      </Box>
    </Flex>
  );
};
