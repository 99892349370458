import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useCampaignsContext } from '../../context/CampaignsContext';
import { UpdateCampaign } from '../../create';
import CreateSMSCampaign from '../sms/CreateSMSCampaign';
import { hasV1Audience } from './utils';

export default function CreateCampaign() {
  const { v2AudienceBuilderCampaignSms } = useFlags();
  const campaignRouteParams = useParams<{ id?: string }>();
  const {
    campaignsState: { current },
    getCampaign,
  } = useCampaignsContext();

  // if we're presenting/loading an existing campaign, load the campaign the first time
  useEffect(() => {
    if (!current || current.id !== campaignRouteParams.id) {
      if (campaignRouteParams.id) {
        getCampaign(campaignRouteParams.id);
      }
    }
  }, [campaignRouteParams]);

  // if no campaign is set render the screen based off the feature flag
  if (!current || Object.keys(current).length === 0) {
    return v2AudienceBuilderCampaignSms ? <CreateSMSCampaign /> : <UpdateCampaign />;
  }

  // at this point we are looking at a campaign that was created
  // if we have a v1 audience we should show this regardless of the feature flag
  // otherwise regardless if the audience is empty or not we should default
  // to the feature flag
  if (hasV1Audience(current)) {
    return <UpdateCampaign />;
  } else {
    return v2AudienceBuilderCampaignSms ? <CreateSMSCampaign /> : <UpdateCampaign />;
  }
}
