import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { HiExternalLink, HiPhoneIncoming, HiPhoneOutgoing } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import { Call } from '@/shared/types/calls';
import { Avatar, Badge, Box, Button, Flex, HStack, Text } from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';
import { Table, TableColumn } from '@/shared/v2/components/table/Table';

import { formatDuration } from './';

interface CallsTableProps {
  data: Call[];
  loading: boolean;
  totalCount: number;
  onCardClick: (id: string) => void;
  setOffset: (offset: number) => void;
}

export const CallsTable: React.FC<CallsTableProps> = ({
  data,
  loading,
  totalCount,
  onCardClick,
  setOffset,
}) => {
  const history = useHistory();
  const columns: Array<TableColumn<Call>> = useMemo(
    () => [
      {
        Header: 'Date & Time',
        colWidth: '',
        accessor: 'call.time',
        Cell: (props: { row: { original: Call } }) => (
          <Flex direction="column" css={{ minWidth: 140 }}>
            <Box css={{ fontSize: 13 }}>
              {dayjs(props.row.original.updated_at).local().format('MMM D YYYY')}
            </Box>
            <Box css={{ fontSize: 10 }}>
              {dayjs(props.row.original.updated_at).local().format('h:mm A')}
            </Box>
          </Flex>
        ),
      },
      {
        Header: 'Contact',
        colWidth: '100%',
        accessor: 'contact.name',
        Cell: (props: { row: { original: Call } }) => (
          <Flex direction="column" css={{ minWidth: 200 }}>
            <HStack>
              <Avatar size="3" fallback={initials(props.row.original.contact.name)} />
              <Flex direction="column">
                <Box css={{ fontSize: 13 }}>{props.row.original.contact.name}</Box>
                <Box css={{ fontSize: 10 }}>
                  {formatPhoneNumber(props.row.original?.contact?.phone || '')}
                </Box>
              </Flex>
            </HStack>
          </Flex>
        ),
      },
      {
        Header: 'Agent Version',
        colWidth: '100%',
        accessor: 'call.agent_version_id',
        Cell: (props: { row: { original: Call } }) => (
          <Flex direction="column" css={{ minWidth: 160 }}>
            <HStack
              onClick={() =>
                history.push(
                  `/agents/${props.row.original.call_metadata?.agent_version?.agent_id}/versions/${props.row.original.call_metadata?.agent_version?.id}`
                )
              }
            >
              <Box>{props.row.original.call_metadata?.agent_version?.name}</Box>
              <HiExternalLink />
            </HStack>
          </Flex>
        ),
      },
      {
        Header: 'Call Rating',
        colWidth: '100%',
        accessor: 'call.completion',
        Cell: (props: { row: { original: Call } }) => (
          <Flex direction="column" css={{ minWidth: 110 }}>
            <Badge
              css={{ width: 'fit-content' }}
              variant={
                props.row.original.call_metadata?.analysis
                  ?.agent_task_completion_rating === 'Complete'
                  ? 'green'
                  : 'red'
              }
            >
              {props.row.original.call_metadata?.analysis?.agent_task_completion_rating ||
                'Incomplete'}
            </Badge>
          </Flex>
        ),
      },
      {
        Header: 'Call Sentiment',
        colWidth: '100%',
        accessor: 'call.sentiment',
        Cell: (props: { row: { original: Call } }) => (
          <Flex direction="column" css={{ minWidth: 110 }}>
            <Badge
              css={{ width: 'fit-content' }}
              variant={
                props.row.original.call_metadata?.analysis?.user_sentiment === 'Positive'
                  ? 'green'
                  : props.row.original.call_metadata?.analysis?.user_sentiment ===
                      'Negative'
                    ? 'red'
                    : 'gray'
              }
            >
              {props.row.original.call_metadata?.analysis?.user_sentiment || '-'}
            </Badge>
          </Flex>
        ),
      },
      {
        Header: 'Call Duration',
        colWidth: '100%',
        accessor: 'call.duration',
        Cell: (props: { row: { original: Call } }) => (
          <Flex direction="column" css={{ minWidth: 110 }}>
            {(props.row.original?.call_metadata?.duration &&
              formatDuration(props.row.original.call_metadata.duration)) ||
              '-'}
          </Flex>
        ),
      },
      {
        Header: 'Call Direction',
        colWidth: '100%',
        accessor: 'call.updated_at',
        Cell: (props: { row: { original: Call } }) => (
          <Flex direction="column" css={{ minWidth: 135 }}>
            <HStack align="center">
              {props.row.original.source_type === 'INBOUND' ? (
                <HiPhoneIncoming />
              ) : (
                <HiPhoneOutgoing />
              )}
              <Text>{props.row.original?.source_type || '-'}</Text>
            </HStack>
          </Flex>
        ),
      },
      {
        Header: 'Actions',
        colWidth: '100%',
        accessor: 'call.actions',
        Cell: (props: { row: { original: Call } }) => (
          <Flex direction="column" css={{ minWidth: 110 }}>
            <HStack>
              <Button
                data-testid="view-call-btn"
                variant="gray"
                onClick={() => onCardClick(props.row.original?.id)}
              >
                View Call
              </Button>
            </HStack>
          </Flex>
        ),
      },
    ],
    []
  );

  return (
    <Table
      data={data}
      columns={columns}
      caption="Calls Table"
      emptyTitle="No Calls Found"
      totalCount={totalCount}
      pageSize={10}
      isLoading={loading}
      setOffset={setOffset}
    />
  );
};
