import {
  CREATE_BRAND,
  CREATE_CAMPAIGN,
  CREATE_PROVIDER_BRAND,
  GET_BRAND,
  GET_BRANDS,
  GET_CAMPAIGN,
  GET_CAMPAIGNS,
  SET_CURRENT,
  SET_LOADING,
} from './types';

type TenDlcAction =
  | { type: 'GET_BRANDS'; payload: any }
  | { type: 'GET_CAMPAIGNS'; payload: any }
  | { type: 'CREATE_BRAND'; payload: any }
  | { type: 'CREATE_CAMPAIGN'; payload: any }
  | { type: 'SET_CURRENT'; payload: any }
  | { type: 'SET_LOADING'; payload: any }
  | { type: 'CREATE_PROVIDER_BRAND'; payload: any }
  | { type: 'GET_BRAND'; payload: any }
  | { type: 'GET_CAMPAIGN'; payload: any };

const TenDlcReducer = (state: any, action: TenDlcAction) => {
  switch (action.type) {
    case GET_BRANDS:
      return {
        ...state,
        brands: action.payload,
        loading: false,
      };
    case GET_BRAND:
      return {
        ...state,
        brand: action.payload,
        loading: false,
      };
    case GET_CAMPAIGNS:
      return {
        ...state,
        campaigns: action.payload,
        loading: false,
      };
    case GET_CAMPAIGN:
      return {
        ...state,
        campaign: action.payload,
        loading: false,
      };
    case CREATE_BRAND:
      return {
        ...state,
        brands: [...state.brands, action.payload],
        loading: false,
      };
    case CREATE_CAMPAIGN:
      return {
        ...state,
      };
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case CREATE_PROVIDER_BRAND:
      return {
        ...state,
        providers: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default TenDlcReducer;
