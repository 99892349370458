/* eslint-disable react-hooks/exhaustive-deps */

import dayjs from 'dayjs';

import {
  DatepickerDates,
  DateSelector,
} from '@/shared/components/datepicker/DateSelector';
import { Flex, VStack } from '@/shared/ui';

import { useReport } from '../context/ReportsContext';
import { ReportsContentContainer } from '../layout/ReportsContentContainer';
import { MessagingOverview } from './MessagingOverview';
import { Overview } from './Overview';
import { UserMessaging } from './UserMessaging';

export const UsageReport = () => {
  const reports = useReport();
  const { dates, setDates } = reports;

  return (
    <ReportsContentContainer title="Usage" hideDatepicker>
      <VStack gap={5}>
        <Flex>
          <DateSelector
            dates={dates as DatepickerDates}
            setDates={setDates}
            maxDate={dayjs(new Date()).format('YYYY-MM-DD')}
          />
        </Flex>
        <Overview />
        <MessagingOverview />
        <UserMessaging />
      </VStack>
    </ReportsContentContainer>
  );
};
