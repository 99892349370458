import { FaWhatsapp } from 'react-icons/fa';
import {
  HiChartPie,
  HiChat,
  HiClipboardList,
  HiDatabase,
  HiLightningBolt,
  HiLink,
  HiMailOpen,
  HiOutlineMail,
  HiPaperAirplane,
  HiPuzzle,
  HiSparkles,
  HiSpeakerphone,
  HiStar,
  HiUserGroup,
} from 'react-icons/hi';

export type tabDataType = {
  icon?: React.ReactNode;
  textColor?: string;
  title: string;
  path: string;
  hidden: boolean;
  dropdown?: Array<tabDataType>;
};

export type navigationDataType = {
  showSearch: boolean;
  tabsData: () => Array<tabDataType>;
};

function getTabsData(): Array<tabDataType> {
  const tabsData = [
    {
      icon: <HiMailOpen />,
      title: 'Inbox',
      path: '/inbox',
      hidden: false,
    },
    {
      icon: <HiDatabase />,
      title: 'Data',
      path: '/data/contacts',
      hidden: false,
      dropdown: [
        {
          title: 'Contacts',
          path: '/data/contacts',
          hidden: false,
          icon: <HiUserGroup />,
        },
        {
          title: 'Segments',
          path: '/data/segments',
          hidden: false,
          icon: <HiPuzzle />,
        },
        {
          title: 'Lists',
          path: '/data/lists',
          hidden: false,
          icon: <HiClipboardList />,
        },
      ],
    },
    {
      icon: <HiUserGroup />,
      title: 'Contacts',
      path: '/contacts',
      hidden: false,
    },
    {
      icon: <HiSpeakerphone />,
      title: 'Campaigns',
      path: '/campaigns',
      hidden: false,
      dropdown: [
        {
          title: 'SMS',
          path: '/campaigns/sms',
          hidden: false,
          icon: <HiChat />,
        },
        {
          title: 'Email',
          path: '/campaigns/email',
          icon: <HiOutlineMail />,
          hidden: false,
        },
        {
          title: 'WhatsApp',
          path: '/campaigns/whatsapp',
          icon: <FaWhatsapp />,
          hidden: false,
        },
        {
          title: 'Links',
          path: '/campaigns/links',
          icon: <HiLink />,
          hidden: false,
        },
      ],
    },
    {
      icon: <HiPaperAirplane style={{ rotate: '90deg' }} />,
      title: 'Sequences',
      path: '/sequences',
      hidden: false,
    },
    {
      icon: <HiSparkles />,
      title: 'Agents',
      path: '/agents',
      hidden: false,
    },
    {
      icon: <HiLightningBolt />,
      title: 'Automations',
      path: '/automations/keywords',
      hidden: false,
    },
    {
      icon: <HiStar />,
      title: 'Reviews',
      path: '/reviews/all',
      hidden: false,
    },
    {
      icon: <HiChartPie />,
      title: 'Reports',
      path: '/reports/usage',
      hidden: false,
    },
  ];
  return tabsData.map((item) => ({ ...item }));
}

export const navigationData: navigationDataType = {
  showSearch: true,
  tabsData: () => getTabsData(),
};
