import { Formik } from 'formik';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { MultiStepForm } from '@/settings/organization/channels/utils/MultiStepForm';
import { validateCampaignStep } from '@/settings/organization/trust/campaigns/createCampaignView/utils';
import { useTenDlc } from '@/settings/organization/trust/context/TenDlcContext';
import { TrustLayout } from '@/settings/organization/trust/TrustLayout';
import { SettingsLayout } from '@/shared/layouts';
import { CreateCampaignData } from '@/shared/types/trust/campaigns';
import { Flex } from '@/shared/ui';

import {
  createCampaignValidation,
  initialCampaignFormData,
  steps,
} from './CreateCampaignFormData';

export const CreateCampaignView = () => {
  const tenDlcContext = useTenDlc();
  const { createOneCampaign } = tenDlcContext;
  const history = useHistory();

  const handleComplete = async (campaignData: CreateCampaignData) => {
    const {
      brand,
      messageSample1,
      messageSample2,
      messageSample3,
      messageSample4,
      messageSample5,
      industry,
      ...rest
    } = campaignData;

    const messageSamples = [
      messageSample1,
      messageSample2,
      messageSample3,
      messageSample4,
      messageSample5,
    ].filter(Boolean);

    const campaignPayload = {
      ...rest,
      message_samples: messageSamples,
    };
    try {
      createOneCampaign(brand, campaignPayload);
      history.push('/settings/trust/campaigns');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <TrustLayout>
      <SettingsLayout
        background="white"
        padding="0"
        width="100%"
        breadcrumbs={[
          { title: 'Settings', path: '/settings/trust/campaigns' },
          { title: 'Trust Center', path: `/settings/trust/campaigns` },
          { title: 'Campaigns', path: `/settings/trust/campaigns` },
          { title: 'Create Campaign', path: `/settings/trust/campaigns/create` },
        ]}
      >
        <Flex css={{ p: 30 }} direction="column">
          <Formik
            initialValues={initialCampaignFormData}
            validationSchema={createCampaignValidation}
            onSubmit={(values) => handleComplete(values)}
          >
            {(formikProps) => (
              <form onSubmit={(e) => e.preventDefault()}>
                <MultiStepForm
                  steps={steps}
                  onComplete={formikProps.handleSubmit}
                  formData={formikProps.values}
                  onStepValidation={validateCampaignStep}
                  onCompleteText="Create Campaign"
                />
              </form>
            )}
          </Formik>
        </Flex>
      </SettingsLayout>
    </TrustLayout>
  );
};
