/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import { debounce, DebouncedFunc } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { handleFilterChange, handleSortChange } from '@/pages/data/utils/filterActions';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { default_tags_object } from '@/shared/components/filterBuilder/objects/tags';
import { CircleIcon } from '@/shared/components/Icons';
import { SearchFilters } from '@/shared/types/contacts';
import { FilterType, Sort } from '@/shared/types/filter';
import { Tag } from '@/shared/types/tags';
import {
  Box,
  Button,
  DropdownMenuItem,
  DropdownMenuItemWarning,
  Flex,
} from '@/shared/ui';
import { TooltipAvatar } from '@/shared/ui/TooltipAvatar';
import { Table, TableColumn } from '@/shared/v2/components/table/Table';
import { TableActionMenu } from '@/shared/v2/components/table/TableActionMenu';

import { ConfirmationDialogDescription } from '../users/UsersTable';
import { AddTag } from './AddTag';
import { initialTagsState, ITEMS_COUNT, useTags } from './context/TagsContext';
import { sortConfig } from './filterConfig';

const TagsTable = () => {
  const [quickSearchValue, setQuickSearchValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);

  const tagsContext = useTags();
  const { setCurrentTag, updateFilterParams, deleteTag, tagsState } = tagsContext;
  const { tags, filterParams, totalCount, loading } = tagsState;

  useEffect(() => {
    updateFilterParams({
      ...initialTagsState.filterParams,
    });
  }, []);

  const data = useMemo(() => tags, [tags]);

  const columns: Array<TableColumn<Tag>> = useMemo(
    () => [
      {
        Header: 'Tags',
        colWidth: '100%',
        accessor: 'name',
        Cell: (props: { row: { original: Tag } }) => (
          <Flex align="center" onClick={() => setCurrentTag(props.row.original)}>
            <Box css={{ mt: 2, mr: 7 }}>
              <CircleIcon color={props.row.original.color} />
            </Box>
            <Box>{props.row.original.name}</Box>
          </Flex>
        ),
      },
      {
        Header: 'Created by',
        accessor: 'created_by',
        Cell: (props: { row: { original: Tag } }) => (
          <>
            {!!props.row.original.created_by && (
              <TooltipAvatar user={props.row.original.created_by} />
            )}
          </>
        ),
      },
      {
        Header: 'Updated by',
        accessor: 'updated_by',
        Cell: (props: { row: { original: Tag } }) => (
          <>
            {!!props.row.original.updated_by && (
              <TooltipAvatar user={props.row.original.updated_by} />
            )}
          </>
        ),
      },
      {
        Header: 'Created At',
        id: 'inserted_at',
        accessor: 'inserted_at',
        colWidth: '100%',
        Cell: (props: { row: { original: Tag } }) => (
          <Flex align="center" css={{ minWidth: 125 }}>
            <Box>{dayjs(props.row.original.inserted_at).format('MMM D YYYY')}</Box>
          </Flex>
        ),
      },
      {
        Header: 'Updated At',
        id: 'updated_at',
        accessor: 'updated_at',
        colWidth: '100%',
        Cell: (props: { row: { original: Tag } }) => (
          <Flex align="center" css={{ minWidth: 125 }}>
            <Box>{dayjs(props.row.original.updated_at).format('MMM D YYYY')}</Box>
          </Flex>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        Cell: (props: { row: { original: Tag } }) => (
          <>
            <TableActionMenu>
              <>
                <AddTag tag={props.row.original}>
                  <DropdownMenuItem
                    data-testid="edit-tag-option"
                    onClick={(e) => e.preventDefault()}
                  >
                    Edit Tag
                  </DropdownMenuItem>
                </AddTag>
                <ConfirmationDialog
                  width="432px"
                  title="Delete Template?"
                  description={
                    <ConfirmationDialogDescription
                      value={props.row.original?.name ?? ''}
                      description="will be deleted."
                    />
                  }
                  onConfirm={() => deleteTag(props.row.original)}
                  confirmButtonTitle="Confirm"
                  cancelButtonTitle="Cancel"
                  confirmButtonVariant="redBackground"
                  cancelButtonVariant="grayBackground"
                >
                  <DropdownMenuItemWarning
                    data-testid="delete-tag-option"
                    onClick={(e) => e.preventDefault()}
                  >
                    Delete Tag
                  </DropdownMenuItemWarning>
                </ConfirmationDialog>
              </>
            </TableActionMenu>
          </>
        ),
      },
    ],
    []
  );

  const debouncedUpdate = useCallback(
    debounce((props: SearchFilters) => {
      updateFilterParams(props);
    }, 1000),
    []
  );

  const handleQuickSearch = (
    debouncedUpdate: DebouncedFunc<(props: SearchFilters) => void>,
    filters: SearchFilters,
    value: string
  ) => {
    const formattedValue = value.trim();
    const quickFilters = formattedValue
      ? [
          {
            column: 'name',
            comparison: 'ilike',
            value: `%${formattedValue}%`,
            resource: 'tag',
          },
        ]
      : [];
    debouncedUpdate({
      ...filters,
      searchFilter: quickFilters,
      offset: 0,
    });
  };

  return (
    <Flex direction="column" css={{ flex: '1 1 auto' }}>
      <Box css={{ paddingBottom: 24 }}>
        <CombinedFilters
          quickSearchPlaceholder="Search Tags"
          quickSearchValue={quickSearchValue}
          setQuickSearchValue={(value: string) => {
            setQuickSearchValue(value);
            handleQuickSearch(debouncedUpdate, filterParams, value);
          }}
          defaultObjects={[default_tags_object]}
          customObjects={[]}
          activeFilters={activeFilters}
          setFilters={(value: Array<FilterType>) => {
            setActiveFilters(value);
            handleFilterChange(debouncedUpdate, filterParams, value);
          }}
          sortConfig={sortConfig}
          activeSort={filterParams.sort}
          onSortUpdate={(value: Array<Sort>) =>
            handleSortChange(debouncedUpdate, filterParams, value)
          }
        />
      </Box>
      <Table
        data={data}
        columns={columns}
        caption="Tags Table"
        totalCount={totalCount}
        emptyTitle="No Tags Match Search"
        setOffset={(offset) => {
          updateFilterParams({
            ...filterParams,
            offset,
          });
        }}
        isLoading={loading}
        pageSize={ITEMS_COUNT}
        empty={
          <Box>
            <Box
              css={{
                width: 250,
                fontSize: 16,
                marginTop: 8,
                marginBottom: 16,
                fontWeight: 400,
              }}
            >
              <Box>
                <AddTag>
                  <Button onClick={(e) => e.preventDefault()}>Add Tag</Button>
                </AddTag>
              </Box>
            </Box>
          </Box>
        }
      />
    </Flex>
  );
};

export default TagsTable;
