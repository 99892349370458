import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HiX } from 'react-icons/hi';

import { searchChannels } from '@/shared/api/channels';
import { searchContacts } from '@/shared/api/contacts/v2';
import { Contact } from '@/shared/types';
import {
  Channel,
  ChannelsStates,
  ChannelTypes,
  ProviderTypes,
} from '@/shared/types/channels';
import { User, UserStates } from '@/shared/types/users';
import { CallType, Participant } from '@/shared/types/voip';
import { Avatar, Box, Button, Flex, Input } from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import {
  formatPhoneNumber,
  isValidPhoneNumber,
  phoneFormatting,
  toE164,
} from '@/shared/utils/validations/validations';

import { useAuth } from '../auth/context/AuthProvider';
import { showContactIcon } from '../inbox/list/ConversationPreview';
import { useChannels } from '../settings/organization/channels/context/ChannelContext';
import { useUsers } from '../settings/organization/users/context/UserContext';
import { useVoIP } from './context/VoIPContext';

type VoIPParticipantsProps = {
  data?: CallType | null;
  currentChannel?: Channel;
  onClose: () => void;
};

export const VoIPParticipants = ({
  data,
  onClose,
  currentChannel,
}: VoIPParticipantsProps) => {
  const contactsController = useRef<AbortController | null>(null);
  const channelsController = useRef<AbortController | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { tokens } = useAuth();
  const {
    channelsState: { allChannels },
  } = useChannels();
  const {
    userState: { users },
  } = useUsers();
  const {
    addParticipantToCall,
    voipState: { onlineUsers },
  } = useVoIP();

  const [contacts, setContact] = useState<Array<Contact>>([]);
  const [channels, setChannels] = useState<Array<Channel>>([]);
  const [searchValue, setSearchValue] = useState('');
  const [activeParticipant, setActiveParticipant] = useState<{
    phone: string;
    userId?: number;
  } | null>(null);

  const channelsList = useMemo(() => {
    return allChannels?.filter(
      (c: Channel) =>
        c.state === ChannelsStates.ENABLED &&
        c.provider === ProviderTypes.TWILIO &&
        c.type === ChannelTypes.PHONE &&
        c.phone !== currentChannel?.phone
    );
  }, [allChannels, currentChannel]);

  const usersList = useMemo(() => {
    return users?.filter(
      (user: User) =>
        user.id !== tokens?.user_id &&
        user?.state === UserStates.ENABLED &&
        !!user?.locations?.find((c: Channel) => c.id === currentChannel?.id) &&
        !data?.participants?.find(
          (p: Participant) => p.phone?.split('_')?.[1] === `${user.id}`
        )
    );
  }, [users, currentChannel, tokens, data?.participants]);

  const handleSearchContacts = (value: string) => {
    setSearchValue(value);
    debounceSearchContacts(value);
    debounceSearchChannels(value);
  };

  const fetchContacts = async (value: string) => {
    if (contactsController.current) {
      contactsController.current.abort();
      contactsController.current = null;
    }
    const controller = new AbortController();
    contactsController.current = controller;
    if (value.length > 0) {
      try {
        const contacts = await searchContacts(
          [
            {
              column: 'phone',
              comparison: 'ilike',
              resource: 'contact',
              value: `%${phoneFormatting(value)}%`,
              or: [
                {
                  column: 'name',
                  comparison: 'ilike',
                  resource: 'contact',
                  value: `%${value}%`,
                },
              ],
            },
          ],
          [],
          50,
          0,
          controller.signal
        );
        setContact(contacts.data);
      } catch (err) {
        console.error(err);
      }
    } else {
      setContact([]);
    }
  };

  const fetchChannels = async (value: string) => {
    if (channelsController.current) {
      channelsController.current.abort();
      channelsController.current = null;
    }
    const controller = new AbortController();
    channelsController.current = controller;
    if (value.length > 0) {
      try {
        const channels = await searchChannels(
          {
            filter: [
              {
                column: 'phone',
                comparison: 'ilike',
                resource: 'location',
                value: `%${phoneFormatting(value)}%`,
                or: [
                  {
                    column: 'name',
                    comparison: 'ilike',
                    resource: 'location',
                    value: `%${value}%`,
                  },
                ],
                and: [
                  {
                    column: 'state',
                    comparison: '==',
                    resource: 'location',
                    value: 'enabled',
                  },
                  {
                    column: 'provider',
                    comparison: '==',
                    resource: 'location',
                    value: 'twilio',
                  },
                  {
                    column: 'type',
                    comparison: '==',
                    resource: 'location',
                    value: 'phone',
                  },
                  {
                    column: 'id',
                    comparison: '!=',
                    resource: 'location',
                    value: currentChannel?.id,
                  },
                ],
              },
            ],
            sort: [],
            limit: 50,
            offset: 0,
          },
          controller
        );
        setChannels(channels.data);
      } catch (err) {
        console.error(err);
      }
    } else {
      setContact([]);
    }
  };

  const debounceSearchContacts = debounce(fetchContacts, 500);
  const debounceSearchChannels = debounce(fetchChannels, 500);

  const handleAdd = (phone: string, userId?: number) => {
    setActiveParticipant({ phone: toE164(phone), userId });
  };

  const handleAddParticipant = useCallback(async () => {
    if (activeParticipant && data?.call?.parameters?.CallSid) {
      await addParticipantToCall(
        data?.call?.parameters?.CallSid,
        activeParticipant?.userId ? '' : activeParticipant?.phone,
        activeParticipant?.userId
      );
      setActiveParticipant(null);
      onClose();
    }
  }, [data?.call?.parameters?.CallSid, activeParticipant]);

  const isOnline = useCallback(
    (id: number) => {
      const joins = Object.keys(onlineUsers?.joins);
      const leaves = onlineUsers?.leaves ? Object.keys(onlineUsers?.leaves) : [];
      const online = [...joins].filter((userId: string) => !leaves.includes(`${userId}`));
      return online.includes(`${id}`);
    },
    [onlineUsers]
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const isOnCall = useMemo(() => {
    return !!data?.participants?.find((p: Participant) =>
      p.phone?.includes(toE164(searchValue))
    );
  }, [data?.participants, searchValue]);

  return (
    <Flex
      direction="column"
      justify="between"
      css={{ flex: 1, width: 330, padding: 12, height: '100%', position: 'relative' }}
    >
      <HiX
        data-testid="close-add-people"
        onClick={onClose}
        size={14}
        style={{ cursor: 'pointer', position: 'absolute', top: 16, right: 12 }}
      />
      <Box>
        <Box css={{ color: 'white', fontSize: 14, fontWeight: 400, pb: 12 }}>
          <Box css={{ mb: 4 }}>Add people to this call</Box>
          <Box css={{ color: '#ADB1B8', mt: 4 }}>
            Bring your teammates, contacts, or phone numbers by sending them an invite.
          </Box>
        </Box>
        <Input
          ref={inputRef}
          css={{
            width: '100%',
            background: 'transparent',
            color: '#fff',
            boxShadow: 'inset 0 0 0 1px #4471FFC9',
            fontSize: 16,
            px: 12,
            borderRadius: 4,
            border: '1px solid transparent',
            '&:focus': {
              boxShadow:
                'inset 0px 0px 0px 1px $colors$primaryColor, 0px 0px 0px 2px $colors$primaryColor',
            },
          }}
          placeholder="Enter a name or phone number..."
          value={searchValue}
          onChange={(e) => handleSearchContacts(e.target.value)}
        />
        <Flex direction="column" css={{ maxHeight: 350, overflow: 'auto' }}>
          {!contacts.length && isValidPhoneNumber(searchValue) && (
            <>
              <Box css={{ py: 8, mt: 12 }}>Direct number</Box>
              <ParticipantItem
                phone={searchValue}
                handleAdd={handleAdd}
                isActive={
                  toE164(searchValue) === activeParticipant?.phone ||
                  !!data?.participants?.find((p: Participant) =>
                    p.phone?.includes(toE164(searchValue))
                  )
                }
                isOnCall={isOnCall}
              >
                <Flex css={{ fontSize: 14, color: 'white' }}>
                  <Box>#</Box>
                  <Box data-testid="user-phone" css={{ ml: 8 }}>
                    <Box>{formatPhoneNumber(searchValue) || '-'}</Box>
                  </Box>
                </Flex>
                {isOnCall && (
                  <Box css={{ fontSize: 12, color: 'white', whiteSpace: 'nowrap' }}>
                    Already on the call
                  </Box>
                )}
              </ParticipantItem>
            </>
          )}
          {!!contacts.length && (
            <>
              <Box css={{ py: 8, mt: 12 }}>Contacts</Box>
              <Box>
                {contacts.map((contact) => (
                  <ParticipantItem
                    key={contact.id}
                    phone={contact.phone || ''}
                    handleAdd={handleAdd}
                    isActive={contact?.phone === activeParticipant?.phone}
                    isOnCall={isOnCall}
                  >
                    <Flex align="center" css={{ flex: 1, fontSize: 14, color: 'white' }}>
                      <Avatar
                        data-testid="user-avatar"
                        size="2"
                        variant="lightGray"
                        src={
                          showContactIcon(contact?.name || '')
                            ? `${window.location.origin}/outline.svg`
                            : ''
                        }
                        alt={contact?.name || 'No name'}
                        fallback={initials(contact?.name || '')}
                      />
                      <Box
                        data-testid="user-phone"
                        css={{
                          flex: 1,
                          ml: 8,
                          maxWidth: 185,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {contact.name}
                      </Box>
                    </Flex>
                    <Box css={{ fontSize: 14, color: 'white', whiteSpace: 'nowrap' }}>
                      {contact.phone ? formatPhoneNumber(contact?.phone || '') : '-'}
                    </Box>
                  </ParticipantItem>
                ))}
              </Box>
            </>
          )}
          {(searchValue ? !!channels.length : !!channelsList.length) && (
            <>
              <Box css={{ py: 8, mt: 12 }}>Channels</Box>
              <Box>
                {(searchValue ? channels : channelsList)?.map((channel: Channel) => (
                  <ParticipantItem
                    key={channel.id}
                    phone={channel.phone || ''}
                    handleAdd={handleAdd}
                    isActive={channel?.phone === activeParticipant?.phone}
                    isOnCall={isOnCall}
                  >
                    <Flex align="center" css={{ flex: 1, fontSize: 14, color: 'white' }}>
                      <Box
                        data-testid="channel-phone"
                        css={{
                          flex: 1,
                          maxWidth: 185,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {channel.name}
                      </Box>
                    </Flex>
                    <Box css={{ fontSize: 14, color: 'white', whiteSpace: 'nowrap' }}>
                      {formatPhoneNumber(channel.phone) || '-'}
                    </Box>
                  </ParticipantItem>
                ))}
              </Box>
            </>
          )}
          {!searchValue && (
            <>
              <Box css={{ py: 8, mt: 12 }}>{`Others in ${currentChannel?.name}`}</Box>
              {usersList.map((user: User) => (
                <ParticipantItem
                  key={user.id}
                  userId={user.id}
                  phone=""
                  handleAdd={handleAdd}
                  isActive={user.id === activeParticipant?.userId}
                  isOnCall={
                    !!data?.participants?.find((p: Participant) => user.id === p?.userId)
                  }
                >
                  <Flex align="center" css={{ flex: 1, fontSize: 14, color: 'white' }}>
                    <Box style={{ position: 'relative' }}>
                      <Avatar
                        data-testid="user-avatar"
                        size="2"
                        variant="lightGray"
                        src={
                          showContactIcon(user?.name || '')
                            ? `${window.location.origin}/outline.svg`
                            : ''
                        }
                        alt={user?.name || 'No name'}
                        fallback={initials(user?.name || '')}
                      />
                      {isOnline(user.id) && (
                        <Box
                          style={{
                            position: 'absolute',
                            bottom: -2,
                            right: 0,
                            width: 8,
                            height: 8,
                            borderRadius: 8,
                            border: '2px solid #1B1B1F',
                            backgroundColor: '#30A46C',
                          }}
                        />
                      )}
                    </Box>
                    <Box
                      data-testid="user-phone"
                      css={{
                        flex: 1,
                        ml: 8,
                        maxWidth: 185,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {user.name}
                    </Box>
                  </Flex>
                </ParticipantItem>
              ))}
            </>
          )}
        </Flex>
      </Box>
      <Flex justify="end">
        <Button
          onClick={handleAddParticipant}
          disabled={!activeParticipant}
          css={{
            '&:disabled': {
              background: '#BDC8FF17',
              color: '#DFF2FD55',
              boxShadow: 'none',
              '&:hover': {
                boxShadow: 'none',
              },
            },
          }}
        >
          Add
        </Button>
      </Flex>
    </Flex>
  );
};

export const ParticipantItem = ({
  phone,
  userId,
  handleAdd,
  children,
  isActive,
  isOnCall,
}: {
  phone: string;
  userId?: number;
  handleAdd: (phone: string, userId?: number) => void;
  children: React.ReactNode;
  isActive?: boolean;
  isOnCall?: boolean;
}) => {
  return (
    <Flex
      data-testid="call-contact-item"
      onClick={() => !isOnCall && (userId ? handleAdd('', userId) : handleAdd(phone))}
      justify="between"
      align="center"
      css={{
        px: 12,
        py: 8,
        whiteSpace: 'nowrap',
        cursor: 'pointer',
        backgroundColor: isActive ? '#2A2B33' : 'transparent',
        '&:hover': {
          background: '#2A2B33',
        },
        opacity: isOnCall ? 0.5 : 1,
      }}
    >
      {children}
    </Flex>
  );
};
