import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useLinks } from '@/pages/links/context/LinksContext';
import { reportAnalytics, ReportData } from '@/shared/api/reports';
import {
  DatepickerDates,
  DateSelector,
} from '@/shared/components/datepicker/DateSelector';
import { ValueCombobox } from '@/shared/components/filterBuilder/values/Combobox';
import { Box, Flex, VStack } from '@/shared/ui';

import { useCampaignsContext } from '../../context/CampaignsContext';
import { Campaign } from './Campaign';
import { CampaignAnalyticsView } from './CampaignAnalyticsView';

export const initialDates = {
  startDate: moment().add(-4, 'week'),
  endDate: moment(),
};

export type EstimatedSavingsData = {
  timePerCompleteCall: number | null;
  timePerIncompleteCall: number | null;
  rate: number | null;
};

export const CampaignAnalytics = () => {
  const { id } = useParams<{ id: string }>();
  const campaigns = useCampaignsContext();
  const { campaignsState } = campaigns;
  const { current } = campaignsState;

  const { getLinkAnalytics } = useLinks();

  const initialDate = current?.schedule_options?.day
    ? moment(
        `${current?.schedule_options.day}-${current?.schedule_options.month}-${current?.schedule_options.year}`,
        'DD-MM-YYYY'
      )
    : current?.updated_at
      ? moment(current.updated_at)
      : null;
  const [data, setData] = useState<Array<ReportData>>([]);
  const [timeBucket, setTimeBucket] = useState<string>('day');
  const [campaignResponses, setCampaignResponses] = useState<any>([]);
  const [campaignUnsubscribes, setCampaignUnsubscribes] = useState<any>([]);
  const [error, setError] = useState<boolean>(false);

  const [stackedReportBar, setStackedReportBar] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dates, setDates] = useState<DatepickerDates>(
    initialDate
      ? {
          startDate: initialDate,
          endDate:
            !current?.schedule_options?.day && current?.updated_at
              ? moment(initialDate).add(5, 'day')
              : initialDate,
        }
      : initialDates
  );

  useEffect(() => {
    fetchReports(id, dates, timeBucket);
  }, [id, dates, timeBucket]);

  const fetchReports = async (id: string, dates: DatepickerDates, timeBucket: string) => {
    try {
      setIsLoading(true);
      const data = await reportAnalytics(
        [
          {
            column: 'campaign_id',
            comparison: 'in',
            value: [id],
          },
        ],
        dates,
        'campaign',
        timeBucket
      );
      setData(data);
      await getLinkAnalytics(
        id,
        moment(dates.startDate).format('YYYY-MM-DD'),
        moment(dates.endDate ?? dates.startDate).format('YYYY-MM-DD'),
        'metadata.campaign_id'
      );
      setCampaignResponses(
        data
          .find((d) => d.chart === 'bar' && d.type === 'campaign_responses')
          ?.values.map((d) => {
            return { key: d.y, count: d.x };
          })
      );
      setCampaignUnsubscribes(
        data
          .find((d) => d.chart === 'bar' && d.type === 'campaign_unsubscribes')
          ?.values.map((d) => {
            return { key: d.y, count: d.x };
          })
      );

      const delivered = data
        .find((d) => d.chart === 'bar' && d.type === 'delivered_campaign_messages')
        ?.values.map((d) => {
          return { key: d.y, count: d.x };
        });

      const failed = data
        .find((d) => d.chart === 'bar' && d.type === 'failed_campaign_messages')
        ?.values.map((d) => {
          return { key: d.y, count: d.x };
        });

      setStackedReportBar(
        delivered?.map((d, index) => {
          return {
            key: d.key,
            delivered: d.count,
            failed: failed?.[index].count,
          };
        })
      );
      setIsLoading(false);
      setError(false);
    } catch (error) {
      setIsLoading(false);
      setError(true);
      console.error(error);
    }
  };

  const handleSetDates = (value: DatepickerDates) => {
    setDates(value);
    if (timeBucket === 'hour' && dates.endDate.diff(dates.startDate, 'day') > 2) {
      setTimeBucket('day');
    }
  };

  return (
    <>
      <Campaign />
      <Box css={{ height: 'calc(100vh - 205px)', overflow: 'auto' }}>
        <Flex
          data-testid="campaign-analytics"
          direction="column"
          css={{ p: 24, minWidth: 'auto' }}
        >
          <VStack gap={5}>
            <Flex gap={2}>
              <DateSelector
                dates={dates}
                setDates={handleSetDates}
                maxDate={dayjs(new Date()).format('YYYY-MM-DD')}
              />
              <ValueCombobox
                hideChevron
                selectLabel="Time bucket"
                options={[
                  {
                    label: 'Hour',
                    value: 'hour',
                    disabled: dates.endDate.diff(dates.startDate, 'day') > 5,
                  },
                  { label: 'Day', value: 'day' },
                ]}
                onSelect={(option) => {
                  setTimeBucket(option.value);
                }}
                selected={{ label: capitalize(timeBucket), value: timeBucket }}
                withSearch={false}
                css={{ minWidth: 'auto', height: 40 }}
                renderOptionsIfOpen
              />
            </Flex>
            <CampaignAnalyticsView
              time_bucket={timeBucket}
              data={data}
              stackedReportBar={stackedReportBar}
              campaignResponses={campaignResponses}
              campaignUnsubscribes={campaignUnsubscribes}
              isLoading={isLoading}
              error={error}
            />
          </VStack>
        </Flex>
      </Box>
    </>
  );
};
