import { useEffect, useState } from 'react';
import React from 'react';

import { searchAgentsVersions } from '@/shared/api/ai/agents';
import { cleanFilters } from '@/shared/components/filterBuilder/utils/cleanFilters';
import { AgentVersion } from '@/shared/types/ai/agents';
import { FilterItem, Sort } from '@/shared/types/filter';
import { ComboboxMultiselect } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselect';
import { ComboboxMultiselectItem } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectItem';

import { ComboboxMultiselectTrigger } from './AnalyticFilterCombobox';

const ITEMS_COUNT = 50;

export const AgentVersionFilterCombobox = ({
  selectedItems,
  setSelectedItems,
}: {
  selectedItems: Array<string>;
  setSelectedItems: (value: Array<string>) => void;
}) => {
  const [offset, setOffset] = useState(0);
  const [agentVersions, setAgentVersions] = useState<Array<AgentVersion>>([]);
  const [totalAgents, setTotalAgents] = useState(0);
  const [loading, setLoading] = useState(false);

  // Default filter for agents that are not archived
  const default_status_filter = {
    resource: 'agent',
    column: 'status',
    comparison: '!=',
    value: 'archived',
  };

  useEffect(() => {
    fetchAgents(cleanFilters([default_status_filter]), [], ITEMS_COUNT, offset);
  }, [offset]);

  const fetchAgents = async (
    filter: Array<FilterItem>,
    sort: Array<Sort> = [],
    limit: number,
    offset: number
  ) => {
    setLoading(true);
    const data = await searchAgentsVersions(filter, sort, limit, offset);
    setAgentVersions([...agentVersions, ...data.data]);
    setTotalAgents(data.total);
    setLoading(false);
  };

  return (
    <ComboboxMultiselect
      options={agentVersions.map((agent: AgentVersion) => ({
        label: agent.name ?? '',
        value: agent.id,
      }))}
      width="auto"
      selected={selectedItems}
      onSelect={setSelectedItems}
      searchLabel="Search"
      selectLabel="Agent Versions"
      Trigger={ComboboxMultiselectTrigger}
      Option={ComboboxMultiselectItem}
      currentOffset={offset}
      totalOptions={totalAgents - ITEMS_COUNT}
      loadMore={() => setOffset(offset + ITEMS_COUNT)}
      loading={loading}
      visualized
    />
  );
};
