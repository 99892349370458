import moment from 'moment';
import { useState } from 'react';
import React from 'react';

import { DatepickerDates } from '@/shared/components/datepicker/DateSelector';
import {
  AnalyticFilterItem,
  AnalyticFilters,
  FilterOption,
} from '@/shared/components/reports/AnalyticFilters';
import { useReportData } from '@/shared/components/reports/useReportData';
import { Box, Flex, VStack } from '@/shared/ui';

import { ReportsContentContainer } from '../layout/ReportsContentContainer';
import { ConversationsAnalytics } from './ConversationsAnalytics';

export const initialDates = {
  startDate: moment().add(-4, 'week'),
  endDate: moment(),
};

const filterOptions: Array<FilterOption> = [
  {
    label: 'User',
    key: 'assigned_user_id',
    type: 'user',
    options: [],
  },
  {
    label: 'Channel',
    key: 'channel_id',
    type: 'channel',
    options: [],
  },
];

export const ConversationsReport = () => {
  const [dates, setDates] = useState<DatepickerDates>(initialDates);
  const [filters, setFilters] = useState<Array<AnalyticFilterItem>>([]);
  const [timeBucket, setTimeBucket] = useState<string>('day');

  const { data, isLoading, error } = useReportData(
    filters,
    dates,
    timeBucket,
    'conversations'
  );

  return (
    <ReportsContentContainer title="Conversations" hideDatepicker>
      <Flex direction="column">
        <VStack gap={5}>
          <Box>
            <AnalyticFilters
              filterOptions={filterOptions}
              filters={filters}
              setFilters={setFilters}
              dates={dates}
              setDates={setDates}
              timeBucket={timeBucket}
              setTimeBucket={setTimeBucket}
            />
          </Box>
          <ConversationsAnalytics
            error={error}
            data={data}
            isLoading={isLoading}
            timeBucket={timeBucket}
          />
        </VStack>
      </Flex>
    </ReportsContentContainer>
  );
};
