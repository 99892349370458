import { FormikValues, useFormikContext } from 'formik';
import React from 'react';

import { FormFieldWrapper } from '@/shared/components/forms';
import { SelectComboboxField } from '@/shared/v2/components/forms/selectCombobox/SelectComboboxField';

interface LanguageSelectProps {
  value: string;
  onChange: (value: string) => void;
  label?: string;
  id?: string;
}

// Language mapping object to display full names
const languageMap: Record<string, string> = {
  af: 'Afrikaans',
  sq: 'Albanian',
  ar: 'Arabic',
  ar_EG: 'Arabic (EGY)',
  ar_AE: 'Arabic (UAE)',
  ar_LB: 'Arabic (LBN)',
  ar_MA: 'Arabic (MAR)',
  ar_QA: 'Arabic (QAT)',
  az: 'Azerbaijani',
  be_BY: 'Belarusian',
  bn: 'Bengali',
  bn_IN: 'Bengali (IND)',
  bg: 'Bulgarian',
  ca: 'Catalan',
  zh_CN: 'Chinese (CHN)',
  zh_HK: 'Chinese (HKG)',
  zh_TW: 'Chinese (TAI)',
  hr: 'Croatian',
  cs: 'Czech',
  da: 'Danish',
  // cspell:disable-next-line
  prs_AF: 'Dari',
  nl: 'Dutch',
  nl_BE: 'Dutch (BEL)',
  en: 'English',
  en_GB: 'English (UK)',
  en_US: 'English (US)',
  en_AE: 'English (UAE)',
  en_AU: 'English (AUS)',
  en_CA: 'English (CAN)',
  en_GH: 'English (GHA)',
  en_IE: 'English (IRL)',
  en_IN: 'English (IND)',
  en_JM: 'English (JAM)',
  en_MY: 'English (MYS)',
  en_NZ: 'English (NZL)',
  en_QA: 'English (QAT)',
  en_SG: 'English (SGP)',
  en_UG: 'English (UGA)',
  en_ZA: 'English (ZAF)',
  et: 'Estonian',
  fil: 'Filipino',
  fi: 'Finnish',
  fr: 'French',
  fr_BE: 'French (BEL)',
  fr_CA: 'French (CAN)',
  fr_CH: 'French (CHE)',
  fr_CI: 'French (CIV)',
  fr_MA: 'French (MAR)',
  ka: 'Georgian',
  de: 'German',
  de_AT: 'German (AUT)',
  de_CH: 'German (CHE)',
  el: 'Greek',
  gu: 'Gujarati',
  ha: 'Hausa',
  he: 'Hebrew',
  hi: 'Hindi',
  hu: 'Hungarian',
  id: 'Indonesian',
  ga: 'Irish',
  it: 'Italian',
  ja: 'Japanese',
  kn: 'Kannada',
  kk: 'Kazakh',
  // cspell:disable-next-line
  rw_RW: 'Kinyarwanda',
  ko: 'Korean',
  ky_KG: 'Kyrgyz (Kyrgyzstan)',
  lo: 'Lao',
  lv: 'Latvian',
  lt: 'Lithuanian',
  mk: 'Macedonian',
  ms: 'Malay',
  ml: 'Malayalam',
  mr: 'Marathi',
  nb: 'Norwegian',
  ps_AF: 'Pashto',
  fa: 'Persian',
  pl: 'Polish',
  pt_BR: 'Portuguese (BR)',
  pt_PT: 'Portuguese (POR)',
  pa: 'Punjabi',
  ro: 'Romanian',
  ru: 'Russian',
  sr: 'Serbian',
  // cspell:disable-next-line
  si_LK: 'Sinhala',
  sk: 'Slovak',
  sl: 'Slovenian',
  es: 'Spanish',
  es_AR: 'Spanish (ARG)',
  es_CL: 'Spanish (CHL)',
  es_CO: 'Spanish (COL)',
  es_CR: 'Spanish (CRI)',
  es_DO: 'Spanish (DOM)',
  es_EC: 'Spanish (ECU)',
  es_HN: 'Spanish (HND)',
  es_MX: 'Spanish (MEX)',
  es_PA: 'Spanish (PAN)',
  es_PE: 'Spanish (PER)',
  es_ES: 'Spanish (SPA)',
  es_UY: 'Spanish (URY)',
  sw: 'Swahili',
  sv: 'Swedish',
  ta: 'Tamil',
  te: 'Telugu',
  th: 'Thai',
  tr: 'Turkish',
  uk: 'Ukrainian',
  ur: 'Urdu',
  uz: 'Uzbek',
  vi: 'Vietnamese',
  zu: 'Zulu',
};

export function LanguageSelect({
  value,
  onChange,
  label = 'Select Language',
  id = 'language',
}: LanguageSelectProps): JSX.Element {
  const formik = useFormikContext<FormikValues>();

  // Convert languageMap to options format required by SelectComboboxField
  const languageOptions = Object.entries(languageMap).map(([code, name]) => ({
    label: name,
    value: code,
  }));

  // Set the initial value in formik if it's not already set
  React.useEffect(() => {
    if (value && formik.values[id] !== value) {
      formik.setFieldValue(id, value);
    }
  }, [value, id, formik]);

  // Update the parent component when formik value changes
  React.useEffect(() => {
    const currentValue = formik.values[id];
    if (currentValue && currentValue !== value) {
      onChange(currentValue);
    }
  }, [formik.values[id], value, onChange, id]);

  return (
    <FormFieldWrapper label={label} name={id}>
      <SelectComboboxField
        options={languageOptions}
        name={id}
        searchLabel="Search languages"
        selectLabel="Select a language"
        valueStyles={{
          backgroundColor: '#01CCFF1D',
          color: '#005681DA',
        }}
      />
    </FormFieldWrapper>
  );
}
