/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getSequenceStepTriggers } from '@/shared/api/sequences';
import { PageFooterContainer } from '@/shared/layouts/PageLayout';
import { Sequence, SequenceStep, SequenceStepTrigger } from '@/shared/types/sequences';
import { Box, Button, Flex, Text, VStack } from '@/shared/ui';

import { useSequences } from '../../context/SequenceContext';
import { StepCard, StepLoadingCard } from './StepCard';
import { SequenceStepsContainer } from './UpdateStepNew';

type SequenceStepsPropsType = {
  disableEdit?: boolean;
};

export type SequenceTriggers = {
  id: string;
  triggers: Array<SequenceStepTrigger>;
};

export const SequenceSteps = (props: SequenceStepsPropsType) => {
  const { id } = useParams<{ step_id: string; id: string }>();
  const { disableEdit } = props;
  const sequenceContext = useSequences();
  const history = useHistory();
  const { sequencesState, getAndSetSequenceSteps, setCurrentSequenceStep } =
    sequenceContext;
  const { currentSequenceSteps, current, currentSequenceStep } = sequencesState;
  const itemRefs = useRef<HTMLDivElement[]>([]);
  const [loading, setLoading] = useState(false);
  const [sequenceTriggers, setSequenceTriggers] = useState<SequenceTriggers[]>([]);

  useEffect(() => {
    itemRefs.current = itemRefs.current.slice(0, currentSequenceSteps?.length);
    fetchTrigger(currentSequenceSteps, current, setSequenceTriggers, scrollToElement);
    scrollToElement(true);
  }, [currentSequenceSteps?.length]);

  const scrollToElement = (cleanCurrentSequenceStep?: boolean) => {
    if (currentSequenceSteps.length && currentSequenceStep) {
      const activeItemIndex = currentSequenceSteps.findIndex(
        (s) => s && s.id === currentSequenceStep
      );
      activeItemIndex !== -1 && itemRefs.current[activeItemIndex]?.scrollIntoView();
      cleanCurrentSequenceStep && setCurrentSequenceStep(null);
    }
  };

  useEffect(() => {
    const getSequences = async (id: string) => {
      try {
        await getAndSetSequenceSteps(id);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    if (current && current.id && (id !== current.id || !currentSequenceSteps.length)) {
      setLoading(true);
      getSequences(current.id);
    }
  }, [current]);

  if (loading) {
    return (
      <Box css={{ p: 30 }}>
        <VStack gap={4} align="center" css={{ width: '100%' }}>
          {[...Array(3)].map((_, i) => (
            <Box key={i} css={{ width: '100%', mb: 20 }}>
              <StepLoadingCard />
            </Box>
          ))}
          {!disableEdit && (
            <Box css={{ width: '100%' }}>
              <Button
                onClick={() => history.push(`/sequences/${current?.id}/steps/new`)}
                variant="gray"
              >
                Add New Step
              </Button>
            </Box>
          )}
        </VStack>
      </Box>
    );
  }

  if (currentSequenceSteps.length < 1 && !loading) {
    return (
      <Flex align="center" justify="center" css={{ height: '100%' }}>
        <VStack align="center" gap="4">
          <Text variant="bold" size={4} css={{ fontWeight: 600 }}>
            No Sequence Steps
          </Text>
          <Text variant="gray">Add the first step in your sequence to get started.</Text>
          {!disableEdit && (
            <Button onClick={() => history.push(`/sequences/${current?.id}/steps/new`)}>
              Add Step
            </Button>
          )}
        </VStack>
      </Flex>
    );
  }

  return (
    <Box>
      <SequenceStepsContainer>
        <VStack gap={4} align="center" css={{ width: '100%' }}>
          {currentSequenceSteps &&
            currentSequenceSteps.length > 0 &&
            currentSequenceSteps.map((step: SequenceStep | null, index: number) => (
              <Box
                ref={(el) => el && (itemRefs.current[index] = el)}
                key={index}
                css={{ width: '100%' }}
              >
                <StepCard
                  sequence={current}
                  sequence_id={current?.id || ''}
                  sequenceTrigger={sequenceTriggers.find((s) => s.id === step?.id)}
                  step={step}
                  disableEdit={disableEdit}
                />
              </Box>
            ))}
        </VStack>
      </SequenceStepsContainer>
      {!disableEdit && (
        <PageFooterContainer
          css={{
            width: '100%',
          }}
          border
        >
          <Flex justify="end" css={{ width: '100%' }}>
            <Button onClick={() => history.push(`/sequences/${current?.id}/steps/new`)}>
              Add New Step
            </Button>
          </Flex>
        </PageFooterContainer>
      )}
    </Box>
  );
};

export const fetchTrigger = async (
  currentSequenceSteps: Array<SequenceStep> | Array<null>,
  current: Sequence | null,
  setSequenceTriggers: (sequenceTriggers: SequenceTriggers[]) => void,
  scrollToElement: (value: boolean) => void
) => {
  if (!current?.id) return;

  try {
    // Create a map to store steps with their triggers
    const stepsWithTriggers: SequenceTriggers[] = currentSequenceSteps.map((step) => ({
      id: step?.id ?? '',
      triggers: [] as Array<SequenceStepTrigger>,
    }));
    // Fetch triggers for each step
    await Promise.all(
      stepsWithTriggers.map(async (step) => {
        if (!step.id) return;

        const triggerData = await getSequenceStepTriggers(current?.id ?? '', step.id);

        // Find and update the step with its triggers
        const stepIndex = stepsWithTriggers.findIndex((s) => s.id === step.id);
        if (stepIndex !== -1) {
          stepsWithTriggers[stepIndex].triggers = triggerData as SequenceStepTrigger[];
        }
      })
    );
    setSequenceTriggers(stepsWithTriggers);
    scrollToElement(true);
  } catch (error) {
    console.error('Error fetching sequence triggers:', error);
  }
};
