import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

// Configure dayjs to use timezone and UTC plugins
dayjs.extend(utc);
dayjs.extend(timezone);

// Set default timezone to browser's timezone
dayjs.tz.setDefault(dayjs.tz.guess());

export default dayjs;
