import {
  CallStatusTypes,
  CallType,
  Participant,
  VoIPActions,
  VoIPActionTypes,
  VoIPStateType,
} from '@/shared/types/voip';
import { removeDuplicates } from '@/shared/utils/removeDuplicates';

const VoIPReducer = (state: VoIPStateType, action: VoIPActions): VoIPStateType => {
  switch (action.type) {
    case VoIPActionTypes.ADD_DEVICE:
      return {
        ...state,
        device: action.payload,
      };
    case VoIPActionTypes.DESTROY_DEVICE:
      return {
        ...state,
        device: null,
      };
    case VoIPActionTypes.ADD_INCOMING_CALL:
      return {
        ...state,
        incomingCalls: removeDuplicates(
          [...state.incomingCalls, action.payload],
          'callSid'
        ),
        callStatus: CallStatusTypes.INCOMING,
      };
    case VoIPActionTypes.ACCEPT_INCOMING_CALL:
      return {
        ...state,
        incomingCalls: state.incomingCalls.filter(
          (call: CallType) => call?.callSid !== action.payload?.callSid
        ),
        calls: removeDuplicates([...state.calls, action.payload], 'callSid'),
        current: action.payload,
      };
    case VoIPActionTypes.DESTROY_INCOMING_CALL:
      return {
        ...state,
        incomingCalls: state.incomingCalls.filter(
          (call: CallType) => call?.callSid !== action.payload
        ),
        callStatus: null,
      };
    case VoIPActionTypes.ADD_OUTGOING_CALL:
      return {
        ...state,
        calls: removeDuplicates([...state.calls, action.payload]),
        current: action.payload,
        callStatus: CallStatusTypes.OUTGOING,
      };
    case VoIPActionTypes.ACCEPT_OUTGOING_CALL:
      return {
        ...state,
        incomingCalls: state.incomingCalls.filter(
          (call: CallType) => call?.callSid !== action.payload?.callSid
        ),
        calls: state.calls.find((c: CallType) => c.id === action.payload?.id)
          ? state.calls.map((c: CallType) =>
              c.id === action.payload?.id ? { ...c, ...action.payload } : c
            )
          : [...state.calls, action.payload],
        current: action.payload,
        callStatus: CallStatusTypes.OUTGOING,
      };
    case VoIPActionTypes.DESTROY_OUTGOING_CALL:
      return {
        ...state,
        calls: state.calls.filter(
          (call: CallType) => call?.call?.parameters?.CallSid !== action.payload
        ),
        current: state.current?.callSid === action.payload ? null : state.current,
      };
    case VoIPActionTypes.ADD_CALL_STATUS:
      return {
        ...state,
        callStatus: action.payload,
      };
    case VoIPActionTypes.START_CALL_RECORDING:
      return {
        ...state,
        calls: [
          ...state.calls.map((call: CallType) =>
            call?.call?.parameters?.CallSid === action.payload?.callSid
              ? {
                  ...call,
                  recording: true,
                  ...action.payload,
                }
              : call
          ),
        ],
      };
    case VoIPActionTypes.STOP_CALL_RECORDING:
      return {
        ...state,
        calls: [
          ...state.calls.map((call: CallType) =>
            call?.callSid === action.payload
              ? {
                  ...call,
                  recording: false,
                  recording_id: null,
                }
              : call
          ),
        ],
      };
    case VoIPActionTypes.SET_DEFAULT_VOIP_CHANNEL:
      return {
        ...state,
        defaultChannel: action.payload,
      };
    case VoIPActionTypes.ADD_PARTICIPANT:
      return {
        ...state,
        calls: state.calls.map((call: CallType) =>
          call?.callSid === action.payload?.callSid
            ? {
                ...call,
                participants: [...(call.participants || []), action.payload],
              }
            : call
        ),
        current:
          state.current?.callSid === action.payload.callSid
            ? {
                ...state.current,
                participants: [...(state.current.participants || []), action.payload],
              }
            : state.current,
        callStatus: CallStatusTypes.OUTGOING,
      };
    case VoIPActionTypes.DELETE_PARTICIPANT:
      return {
        ...state,
        calls: state.calls.map((call: CallType) =>
          call?.callSid === action.payload?.callSid
            ? {
                ...call,
                participants: call.participants?.filter(
                  (p: Participant) => p.phone !== action.payload?.phone
                ),
              }
            : call
        ),
        current:
          state.current?.callSid === action.payload.callSid
            ? {
                ...state.current,
                participants: state.current.participants?.filter(
                  (p: Participant) => p.phone !== action.payload?.phone
                ),
              }
            : state.current,
        callStatus: CallStatusTypes.OUTGOING,
      };
    case VoIPActionTypes.UPDATE_PARTICIPANT:
      return {
        ...state,
        calls: state.calls.map((call: CallType) =>
          call?.callSid === action.payload?.callSid
            ? {
                ...call,
                participants: call.participants?.map((p: Participant) =>
                  p.phone === action.payload?.phone ? { ...p, ...action.payload } : p
                ),
              }
            : call
        ),
        current:
          state.current?.callSid === action.payload.callSid
            ? {
                ...state.current,
                participants: state.current.participants?.map((p: Participant) =>
                  p.phone === action.payload?.phone ? { ...p, ...action.payload } : p
                ),
              }
            : state.current,
        callStatus: CallStatusTypes.OUTGOING,
      };
    case VoIPActionTypes.SET_USER_ONLINE:
      return {
        ...state,
        onlineUsers: { ...state.onlineUsers, ...action.payload },
      };
    default:
      return { ...state };
  }
};

export default VoIPReducer;
