import * as RadixSelect from '@radix-ui/react-select';
import { styled } from '@stitches/react';

export const SelectComboboxItemIndicator = () => {
  return (
    <StyledItemIndicator>
      <SelectIndicator />
      <SelectedIndicator />
    </StyledItemIndicator>
  );
};

export const StyledItemIndicator = styled('div', {
  position: 'absolute',
  top: 8,
  left: 8,
});

const SelectIndicator = styled('div', {
  width: '$3',
  height: '$3',
  borderRadius: '$3',
  alignItems: 'center',
  display: 'inline-flex',
  justifyContent: 'center',
  boxShadow: 'inset 0 0 0 1px $colors$slate7 !important',
  marginRight: '5px',
  '[data-active-item] &': {
    backgroundColor: '#F0F4FF',
  },
});

export const SelectedIndicator = styled(RadixSelect.ItemIndicator, {
  width: '$3',
  height: '$3',
  borderRadius: '$3',
  alignItems: 'center',
  display: 'inline-flex',
  justifyContent: 'center',
  boxShadow: 'inset 0 0 0 1px $colors$slate7 !important',
  borderWidth: 4,
  borderColor: '$primaryColor',
  position: 'absolute',
  top: 0.5,
  left: 0,
  zIndex: 99,
});
