/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { useResponsiveColumns } from '@/shared/hooks';
import { LayoutContent, MainLayout } from '@/shared/layouts';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Integration } from '@/shared/types/integrations';
import { Box, Flex, Grid, Skeleton, Text } from '@/shared/ui/';
import { styled } from '@/stitches.config';

import { useIntegrations } from './context/IntegrationsContext';
import { IntegrationCard } from './IntegrationCard';
import { IntegrationsFilter } from './IntegrationsFilter';

export const Integrations = () => {
  const integrationContext = useIntegrations();
  const { integrationsState } = integrationContext;
  const { loading, integrations } = integrationsState;

  const columns = useResponsiveColumns();

  return (
    <MainLayout>
      <LayoutContent>
        <PageLayout breadcrumbs={[{ title: 'Integrations', path: '/integrations' }]}>
          <Box
            css={{
              p: 30,
              height: 'fit-content',
              minHeight: '100%',
              overflow: 'auto',
            }}
          >
            <IntegrationsFilter />
            <Grid columns={columns as any} gap={2}>
              {!loading &&
                integrations.map((integration: Integration) => (
                  <IntegrationCard key={integration?.id} integration={integration} />
                ))}
              {loading &&
                Array.from(
                  { length: 20 },
                  (_: never, k: React.Key | null | undefined) => (
                    <IntegrationLoadingCard key={k} />
                  )
                )}
            </Grid>
            {!loading && integrations.length == 0 && <SearchedIntegrationEmptyState />}
          </Box>
        </PageLayout>
      </LayoutContent>
    </MainLayout>
  );
};

export const SearchedIntegrationEmptyState = () => {
  return (
    <EmptyStateContainer align="center" justify="center" direction="column">
      <Text css={{ fontWeight: 600 }}>No Integration Matches Your Search</Text>
      <Text css={{ mt: 10 }}>Please try a different key word or filter</Text>
    </EmptyStateContainer>
  );
};

const EmptyStateContainer = styled(Flex, {
  p: 20,
  height: '100%',
  width: '100%',
});

const IntegrationLoadingCard = styled(Skeleton, {
  minHeight: '175px',
  margin: '0 !important',
});
