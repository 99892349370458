/* eslint-disable react-hooks/exhaustive-deps */
import { compact, flatten } from 'lodash';
import React, { useEffect, useState } from 'react';

import { Notification } from '@/shared/types';
import { Box, VStack } from '@/shared/ui/index';
import { styled } from '@/stitches.config';

import { useNotifications } from './context/NotificationContext';
import {
  NOTIFICATION_PREFERENCES,
  NOTIFICATION_TYPES,
  NotificationPreferencesType,
} from './context/types';
import { Accordion, NotificationAccordion } from './NotificationAccordion';
import NotificationPreferenceItem from './NotificationPreferenceItem';

const NotificationsView = () => {
  const { notificationState, fetchNotifications, addNotification, deleteNotification } =
    useNotifications();
  const { notifications } = notificationState;
  const [accordions, setAccordions] = useState<NotificationPreferencesType[]>([
    NotificationPreferencesType.EMAIL,
    NotificationPreferencesType.SMS,
    NotificationPreferencesType.PUSH,
    NotificationPreferencesType.WEB,
    NotificationPreferencesType.VOICE,
  ]);
  // create temporary list of notifications for smoothing updating of checkboxes
  const [temporaryNotifications, setTemporaryNotifications] = useState<
    { type: string; delivery_method: string }[]
  >([]);

  useEffect(() => {
    fetchNotifications();
    // eslint-disable-next-line
  }, []);

  // set list of temporary notifications on start
  useEffect(() => {
    if (temporaryNotifications.length === 0 && notifications.length > 0) {
      setTemporaryNotifications(notifications);
    }
  }, [notifications.length]);

  // Create or delete notification subscription
  const toggleNotification = (type: string, deliveryMethod: string) => {
    const notification = getNotification(type, deliveryMethod);

    if (notification === undefined) {
      setTemporaryNotifications((prev) => [
        ...prev,
        { type: type, delivery_method: deliveryMethod },
      ]);
      addNotification({ type: type, delivery_method: deliveryMethod });
    } else {
      setTemporaryNotifications((prev) =>
        prev.filter(
          (item) => !(item.type === type && item.delivery_method === deliveryMethod)
        )
      );
      deleteNotification(notification);
    }
  };

  // handles the bulk api call for notification preference
  const handleToggleAllNotificationType = async (
    checked: boolean,
    deliveryMethod: string
  ) => {
    const notificationTypes = flatten(
      NOTIFICATION_TYPES.filter((type) =>
        type.allowedMethods.includes(deliveryMethod as NotificationPreferencesType)
      ).map((notification) => notification.items)
    );

    if (checked) {
      const notifications = notificationTypes.filter(
        (notification) => !getNotification(notification, deliveryMethod)
      );
      Promise.all(
        notifications.map((type) =>
          addNotification({ type: type, delivery_method: deliveryMethod })
        )
      );
    } else {
      const notifications = compact(
        notificationTypes.map((notification) =>
          getNotification(notification, deliveryMethod)
        )
      );
      Promise.all(notifications.map((notification) => deleteNotification(notification)));
    }
  };

  // Find the notification with the given type and delivery method
  const getNotification = (type: string, deliveryMethod: string) => {
    return notifications.find(
      (n: Notification) => n.type === type && n.delivery_method === deliveryMethod
    );
  };

  // handle the opening/closing of preferences
  const handleSelectPreference = (value: NotificationPreferencesType) => {
    setAccordions((prev) =>
      prev.find((item) => item === value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  // checks if the notification preference currently has a subscription
  // if there is a subscription, turn on the switch
  // if no subscriptions, turn the switch off
  const checkIfPreferenceHasSubscription = (deliveryMethod: string) => {
    const notificationTypes = flatten(
      NOTIFICATION_TYPES.filter((type) =>
        type.allowedMethods.includes(deliveryMethod as NotificationPreferencesType)
      ).map((notification) => notification.items)
    );
    const notifications = compact(
      notificationTypes.map((notification) =>
        checkIfNotificationChecked(notification, deliveryMethod)
      )
    );
    return notifications.length > 0;
  };

  // check if the box is checked/unchecked
  const checkIfNotificationChecked = (type: string, deliveryMethod: string) => {
    return temporaryNotifications.find(
      (n) => n.type === type && n.delivery_method === deliveryMethod
    );
  };

  // checks/unchecks all of a preferences checkboxes
  const toggleAllCheckboxesInAPreference = (checked: boolean, deliveryMethod: string) => {
    const notificationTypes = flatten(
      NOTIFICATION_TYPES.filter((type) =>
        type.allowedMethods.includes(deliveryMethod as NotificationPreferencesType)
      ).map((notification) => notification.items)
    );

    if (checked) {
      const notifications = notificationTypes.map((type) => ({
        type,
        delivery_method: deliveryMethod,
      }));
      setTemporaryNotifications((prev) => [...prev, ...notifications]);
    } else {
      setTemporaryNotifications((prev) =>
        prev.filter(
          (item) =>
            !(
              item.delivery_method === deliveryMethod &&
              notificationTypes.includes(item.type)
            )
        )
      );
    }
  };

  const getNotificationTypesForPreference = (
    preferenceType: NotificationPreferencesType
  ) => {
    return NOTIFICATION_TYPES.filter((type) =>
      type.allowedMethods.includes(preferenceType)
    );
  };

  return (
    <NotificationsLayoutContainer>
      <Accordion type="multiple" value={accordions}>
        <VStack css={{ p: 5 }} gap="4">
          {NOTIFICATION_PREFERENCES.map((pref) => (
            <NotificationAccordion
              handleToggleAllNotificationType={(checked) => {
                toggleAllCheckboxesInAPreference(checked, pref.value);
                handleToggleAllNotificationType(checked, pref.value);
              }}
              hasSubscription={checkIfPreferenceHasSubscription(pref.value)}
              title={pref.label}
              itemValue={pref.value as NotificationPreferencesType}
              setAccordions={() =>
                handleSelectPreference(pref.value as NotificationPreferencesType)
              }
              key={pref.value}
            >
              <VStack gap="1">
                {getNotificationTypesForPreference(
                  pref.value as NotificationPreferencesType
                ).map((val) => (
                  <NotificationPreferenceItem
                    isChecked={(type) => !!checkIfNotificationChecked(type, pref.value)}
                    onToggle={(item) => toggleNotification(item, pref.value)}
                    title={val.title}
                    items={val.items}
                    key={`${pref.value}-${val.title}`}
                  />
                ))}
              </VStack>
            </NotificationAccordion>
          ))}
        </VStack>
      </Accordion>
    </NotificationsLayoutContainer>
  );
};

const NotificationsLayoutContainer = styled(Box, {
  width: '100%',
  height: '100%',
});

export default NotificationsView;
