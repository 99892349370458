import React from 'react';
import {
  HiAdjustments,
  HiArrowCircleUp,
  HiBell,
  HiChat,
  HiChevronLeft,
  HiCurrencyDollar,
  HiFolderOpen,
  HiGlobeAlt,
  HiLibrary,
  HiOfficeBuilding,
  HiShieldCheck,
  HiTag,
  HiTemplate,
  HiUser,
  HiUserGroup,
  HiViewGrid,
} from 'react-icons/hi';
import { PiSignature } from 'react-icons/pi';
import { Link, useLocation } from 'react-router-dom';

import { CircleStack } from '@/shared/components/Icons';
import { styled } from '@/stitches.config';

import { Box, Flex, Text, VStack } from '../../ui';
import { useSidenavigationSettings } from './context/SideNavigationContext';
import { SideNavigation, sideNavigationGroup } from './SideNavigation';
import {
  SidebarNavigationContainer,
  SidebarNavigationWrapper,
} from './SideNavigationContainer';
import { SideNavigationHeading, SideNavigationItem } from './SideNavigationItem';

function SettingsNavigationList({ isDesktop }: { isDesktop?: boolean }) {
  const { navigationSettings, toggleExpandedState } = useSidenavigationSettings();
  const location = useLocation();
  const isProfile = location.pathname.startsWith('/settings/profile');
  const isGeneral = location.pathname.startsWith('/settings/general');
  const isOnboarding = location.pathname.startsWith('/settings/onboarding');
  const isUsers = location.pathname.startsWith('/settings/users');
  const isWidget = location.pathname.startsWith('/settings/webchat');
  const isChannels = location.pathname.startsWith('/settings/channels');
  const isNotifications = location.pathname.startsWith('/settings/notifications');
  const isTags = location.pathname.startsWith('/settings/tags');
  const isTemplates = location.pathname.startsWith('/settings/templates');
  const isSignatures = location.pathname.startsWith('/settings/signatures');
  const isPreferences = location.pathname.startsWith('/settings/preferences');
  const isBilling = location.pathname.startsWith('/settings/billing');
  const isTeams = location.pathname.startsWith('/settings/teams');
  const isData = location.pathname.startsWith('/settings/data');
  const isDomains = location.pathname.startsWith('/settings/domains');
  const isTrustCenter = location.pathname.startsWith('/settings/trust');
  const isAccounts = location.pathname.startsWith('/settings/accounts');

  const settingsSideNavigationTabs: Array<sideNavigationGroup> = [
    {
      sideNavigationGroup: [
        {
          title: 'Profile',
          image: <HiUser size={18} />,
          isActive: isProfile,
          link: '/settings/profile',
        },
        {
          title: 'Notifications',
          image: <HiBell size={18} />,
          isActive: isNotifications,
          link: '/settings/notifications',
        },
        {
          title: 'Preferences',
          image: <HiAdjustments size={18} />,
          isActive: isPreferences,
          link: '/settings/preferences',
        },
      ],
    },
    {
      title: 'Organization',
      sideNavigationGroup: [
        {
          title: 'General',
          image: <HiViewGrid size={18} />,
          isActive: isGeneral,
          link: '/settings/general',
        },
        {
          title: 'Billing',
          image: <HiCurrencyDollar size={18} />,
          isActive: isBilling,
          link: '/settings/billing',
        },
        {
          title: 'Onboarding',
          image: <HiArrowCircleUp size={18} />,
          isActive: isOnboarding,
          link: '/settings/onboarding',
        },
        {
          title: 'Users',
          image: <HiUserGroup size={18} />,
          isActive: isUsers,
          link: '/settings/users',
        },
        {
          title: 'Accounts',
          image: <HiLibrary size={18} />,
          isActive: isAccounts,
          link: '/settings/accounts',
        },
        {
          title: 'Trust Center',
          image: <HiShieldCheck size={18} />,
          isActive: isTrustCenter,
          link: '/settings/trust/brands',
          hidden: false,
        },
        {
          title: 'Channels',
          image: <HiOfficeBuilding size={18} />,
          isActive: isChannels,
          link: '/settings/channels',
          hidden: false,
        },
        {
          title: 'Teams',
          image: <HiFolderOpen size={18} />,
          isActive: isTeams,
          link: '/settings/teams',
          hidden: false,
        },
        {
          title: 'Data',
          image: <CircleStack />,
          isActive: isData,
          link: '/settings/data',
          hidden: false,
        },
        {
          title: 'Webchat',
          image: <HiChat size={18} />,
          isActive: isWidget,
          link: '/settings/webchat',
        },
        {
          title: 'Tags',
          image: <HiTag size={18} />,
          isActive: isTags,
          link: '/settings/tags',
        },
        {
          title: 'Templates',
          image: <HiTemplate size={18} />,
          isActive: isTemplates,
          link: '/settings/templates',
        },
        {
          title: 'Signatures',
          image: <PiSignature size={18} />,
          isActive: isSignatures,
          link: '/settings/signatures',
        },
        {
          title: 'Domains',
          image: <HiGlobeAlt size={18} />,
          isActive: isDomains,
          link: '/settings/domains',
          hidden: false,
        },
      ],
    },
  ];

  if (isDesktop) {
    return (
      <SidebarNavigationContainer>
        <SidebarNavigationWrapper>
          <SideNavigation
            expanded={navigationSettings.expanded}
            header="Account"
            sideNavigationTabs={settingsSideNavigationTabs}
            toggleExpandedState={toggleExpandedState}
            hideDivider
          />
        </SidebarNavigationWrapper>
      </SidebarNavigationContainer>
    );
  } else {
    return (
      <SidebarNavigationWrapper>
        <Box css={{ m: '15px' }}>
          <Link to="/inbox/all/open">
            <Flex align="center">
              <HiChevronLeft
                style={{
                  marginTop: '6px',
                }}
              />
              <SettingsHeading size={3} css={{ ml: '5px', fontWeight: 600 }}>
                Go Back
              </SettingsHeading>
            </Flex>
          </Link>
        </Box>
        <Box css={{ px: '8px' }}>
          <VStack gap={3}>
            <VStack gap={1}>
              <SideNavigationHeading align="center">Account</SideNavigationHeading>
              <Link to="/settings/profile">
                <SideNavigationItem active={isProfile}>
                  <HiUser />
                  <Text>Profile</Text>
                </SideNavigationItem>
              </Link>
              <Link to="/settings/notifications">
                <SideNavigationItem active={isNotifications}>
                  <HiBell />
                  <Text>Notifications</Text>
                </SideNavigationItem>
              </Link>
              <Link to="/settings/preferences">
                <SideNavigationItem active={isPreferences}>
                  <HiAdjustments />
                  <Text>Preferences</Text>
                </SideNavigationItem>
              </Link>
            </VStack>
            <VStack gap={1}>
              <SideNavigationHeading align="center">Organization</SideNavigationHeading>
              <Link to="/settings/general">
                <SideNavigationItem active={isGeneral}>
                  <HiViewGrid />
                  <Text>General</Text>
                </SideNavigationItem>
              </Link>
              <Link to="/settings/billing">
                <SideNavigationItem active={isBilling}>
                  <HiCurrencyDollar />
                  <Text>Billing</Text>
                </SideNavigationItem>
              </Link>
              <Link to="/settings/onboarding">
                <SideNavigationItem active={isOnboarding}>
                  <HiArrowCircleUp />
                  <Text>Onboarding</Text>
                </SideNavigationItem>
              </Link>
              <Link to="/settings/users" data-intercom-target="users-navigation">
                <SideNavigationItem active={isUsers}>
                  <HiUserGroup />
                  <Text>Users</Text>
                </SideNavigationItem>
              </Link>
              <Link to="/settings/data">
                <SideNavigationItem active={isData}>
                  <CircleStack />
                  <Text>Data</Text>
                </SideNavigationItem>
              </Link>
              <Link to="/settings/teams">
                <SideNavigationItem active={isTeams}>
                  <HiFolderOpen />
                  <Text>Teams</Text>
                </SideNavigationItem>
              </Link>
              <Link to="/settings/webchat" data-intercom-target="webchat-navigation">
                <SideNavigationItem active={isWidget}>
                  <HiChat />
                  <Text>Webchat</Text>
                </SideNavigationItem>
              </Link>
              <Link to="/settings/channels">
                <SideNavigationItem active={isChannels}>
                  <HiOfficeBuilding />
                  <Text>Channels</Text>
                </SideNavigationItem>
              </Link>
              <Link to="/settings/tags">
                <SideNavigationItem active={isTags}>
                  <HiTag />
                  <Text>Tags</Text>
                </SideNavigationItem>
              </Link>
              <Link to="/settings/templates">
                <SideNavigationItem active={isTemplates}>
                  <HiTemplate />
                  <Text>Templates</Text>
                </SideNavigationItem>
              </Link>
              <Link to="/settings/signatures">
                <SideNavigationItem active={isSignatures}>
                  <PiSignature />
                  <Text>Signatures</Text>
                </SideNavigationItem>
              </Link>
              <Link to="/settings/domains">
                <SideNavigationItem active={isDomains}>
                  <HiGlobeAlt />
                  <Text>Domains</Text>
                </SideNavigationItem>
              </Link>
            </VStack>
          </VStack>
        </Box>
      </SidebarNavigationWrapper>
    );
  }
}

export const SettingsHeading = styled(Text, {
  fontWeight: 600,
  p: '0px 10px 10px 10px',
  ml: '10px',
  height: '25px',
  mt: '4px',
});

export default SettingsNavigationList;
