import { FormikValues, useFormikContext } from 'formik';
import { get } from 'lodash';
import React from 'react';
import { HiOutlineInformationCircle } from 'react-icons/hi';

import { formatEIN } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { Box, Input } from '../../ui';
import { TextInputProps } from './types';

/** A basic input that can be used in conjunction with Formik, must be placed within a FormFieldWrapper */
export function TextInput(props: TextInputProps): JSX.Element {
  const formik = useFormikContext<FormikValues>();

  const isInvalid = !!(
    props.applyInvalidState &&
    props.name &&
    formik.touched[props.name] &&
    formik.errors[props.name]
  );

  return (
    <Box css={{ position: 'relative', width: '100%' }}>
      <Input
        {...formik.getFieldProps(props?.name || '')}
        type={props.type || 'text'}
        placeholder={props.placeholder}
        aria-label={props.ariaLabel}
        disabled={props.disabled}
        autoComplete="chrome-off"
        data-intercom-target={props.intercomId}
        maxLength={props.maxLength || 99999}
        required={props.required || false}
        multiple={props.multiple}
        {...(isInvalid && { variant: 'invalid' })}
        css={{ ...props.css, ...(isInvalid && { pr: 38 }) }}
        value={
          props.type === 'ein'
            ? formatEIN(formik.values[props?.name || ''])
            : get(formik.values, props?.name ?? '')
        }
      />
      {isInvalid && (
        <ErrorIconContainer>
          <HiOutlineInformationCircle />
        </ErrorIconContainer>
      )}
    </Box>
  );
}

const ErrorIconContainer = styled(Box, {
  color: 'red',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  right: '10px',
  pointerEvents: 'none',
});
