/* cspell:disable */
export const voices = [
  {
    voice_id: 'play-Andrew',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Andrew',
    provider: 'play',
    accent: 'American',
    gender: 'male',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/andrew.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Andrew.mp3',
  },
  {
    voice_id: '11labs-Dorothy',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Dorothy',
    provider: 'elevenlabs',
    accent: 'British',
    gender: 'female',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Dorothy.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/Dorothy.mp3',
  },
  {
    voice_id: 'play-Steve',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Steve',
    provider: 'play',
    accent: 'American',
    gender: 'male',
    age: 'Old',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Steve.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Steve.mp3',
  },
  {
    voice_id: 'openai-Nova',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Nova',
    provider: 'openai',
    accent: 'American',
    gender: 'female',
    age: 'Old',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/nova.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/nova_.wav',
  },
  {
    voice_id: '11labs-Anthony',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Anthony',
    provider: 'elevenlabs',
    accent: 'British',
    gender: 'male',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/anthony.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/anthony.mp3',
  },
  {
    voice_id: '11labs-Billy',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Billy',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'male',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/billy.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/billy.mp3',
  },
  {
    voice_id: '11labs-Lily',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Lily',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'female',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/lily.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/lily.mp3',
  },
  {
    voice_id: 'play-Alfonso',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Alfonso',
    provider: 'play',
    accent: 'American',
    gender: 'male',
    avatar_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/customvoice-icon.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Alfonso.mp3',
  },
  {
    voice_id: '11labs-Marissa',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Marissa',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'female',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Marissa.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/marissa.mp3',
  },
  {
    voice_id: '11labs-Amy',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Amy(UK)',
    provider: 'elevenlabs',
    accent: 'British',
    gender: 'female',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/valeria.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Amy.mp3',
  },
  {
    voice_id: '11labs-Bing',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Bing',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'male',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/bing.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/bing.mp3',
  },
  {
    voice_id: 'play-Zuri',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Zuri',
    provider: 'play',
    accent: 'American',
    gender: 'female',
    age: 'Old',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Zuri.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Zuri.mp3',
  },
  {
    voice_id: 'play-Nina',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Nina',
    provider: 'play',
    accent: 'American',
    gender: 'female',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/nina.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Nina.mp3',
  },
  {
    voice_id: 'play-Myra',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Myra',
    provider: 'play',
    accent: 'American',
    gender: 'female',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Myra.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Myra.mp3',
  },
  {
    voice_id: 'play-James',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'James',
    provider: 'play',
    accent: 'American',
    gender: 'male',
    age: 'Old',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/james.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-James.mp3',
  },
  {
    voice_id: '11labs-Jenny',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Jenny',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'female',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Jenny.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Jenny.mp3',
  },
  {
    voice_id: '11labs-Lucas',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Lucas',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'male',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/lucas.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/lucas.mp3',
  },
  {
    voice_id: '11labs-Samad',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Samad (en-IN)',
    provider: 'elevenlabs',
    accent: 'Indian',
    gender: 'male',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/samad.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/samad.mp3',
  },
  {
    voice_id: '11labs-Brian',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Brian',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'male',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/brian.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/brian.mp3',
  },
  {
    voice_id: '11labs-Kate',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Kate',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'female',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Kate.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/kate.mp3',
  },
  {
    voice_id: '11labs-Jason',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Jason',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'male',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Jason.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/jason.mp3',
  },
  {
    voice_id: 'openai-Echo',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Echo',
    provider: 'openai',
    accent: 'American',
    gender: 'male',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/echo.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/echo_.wav',
  },
  {
    voice_id: 'play-Julia',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Julia',
    provider: 'play',
    accent: 'American',
    gender: 'female',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Julia.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Julia.mp3',
  },
  {
    voice_id: '11labs-Ethan',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Ethan',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'male',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/ethan.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/ethan.mp3',
  },
  {
    voice_id: 'openai-Fable',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Fable',
    provider: 'openai',
    accent: 'British',
    gender: 'male',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/fable.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/fable_.wav',
  },
  {
    voice_id: 'play-Cimo',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Cimo',
    provider: 'play',
    accent: 'American',
    gender: 'female',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/cimo.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Cimo.mp3',
  },
  {
    voice_id: '11labs-Paul',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Paul',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'male',
    age: 'Old',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/paul.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/paul.mp3',
  },
  {
    voice_id: 'play-Adelaide',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Adelaide',
    provider: 'play',
    accent: 'American',
    gender: 'female',
    avatar_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/customvoice-icon.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Adelaide.mp3',
  },
  {
    voice_id: '11labs-John',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'John',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'male',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/John.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/John.mp3',
  },
  {
    voice_id: 'deepgram-Varun',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Varun',
    provider: 'deepgram',
    accent: 'American',
    gender: 'male',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/varun.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/varun.wav',
  },
  {
    voice_id: 'play-Susan',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Susan',
    provider: 'play',
    accent: 'American',
    gender: 'female',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Susan.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Susan.mp3',
  },
  {
    voice_id: '11labs-Joe',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Joe',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'male',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/joe.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/joe.mp3',
  },
  {
    voice_id: 'play-Angelo',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Angelo',
    provider: 'play',
    accent: 'American',
    gender: 'male',
    avatar_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/customvoice-icon.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Angelo.mp3',
  },
  {
    voice_id: '11labs-Adrian',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Adrian',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'male',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/adrian.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/adrian.mp3',
  },
  {
    voice_id: '11labs-Gilfoy',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Gilfoy',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'male',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/gilfoy.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/gilfoy.mp3',
  },
  {
    voice_id: '11labs-Zuri',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Zuri',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'female',
    age: 'Old',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Zuri.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/zuri.mp3',
  },
  {
    voice_id: '11labs-Noah',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Noah (en-AU)',
    provider: 'elevenlabs',
    accent: 'Australian',
    gender: 'male',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/noah.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/noah.mp3',
  },
  {
    voice_id: '11labs-Steve',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Steve',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'male',
    age: 'Old',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Steve.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/Steve-.mp3',
  },
  {
    voice_id: 'play-Ryan',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Ryan',
    provider: 'play',
    accent: 'American',
    gender: 'male',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/ryan.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Ryan.mp3',
  },
  {
    voice_id: '11labs-Grace',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Grace',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'female',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/grace.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/grace.mp3',
  },
  {
    voice_id: 'playht-Test',
    voice_type: 'standard',
    voice_name: 'Test',
    provider: 'playht',
    gender: 'male',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/onyx.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/onyx_.wav',
  },
  {
    voice_id: 'play-Max',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Max',
    provider: 'play',
    accent: 'American',
    gender: 'male',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/max.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Max.mp3',
  },
  {
    voice_id: '11labs-Chloe',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Chloe',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'female',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Chloe.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/chloe.mp3',
  },
  {
    voice_id: '11labs-Max',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Max',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'male',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/max.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Max.mp3',
  },
  {
    voice_id: '11labs-Anna',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Anna',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'female',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/anna.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/anna.mp3',
  },
  {
    voice_id: 'openai-Shimmer',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Shimmer',
    provider: 'openai',
    accent: 'American',
    gender: 'female',
    age: 'Old',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/shimmer.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/shimmer_.wav',
  },
  {
    voice_id: 'play-Chloe',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Chloe',
    provider: 'play',
    accent: 'American',
    gender: 'female',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Chloe.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Chloe.mp3',
  },
  {
    voice_id: '11labs-Julia',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Julia',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'female',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Julia.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/julia.mp3',
  },
  {
    voice_id: 'play-Axel',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Axel',
    provider: 'play',
    accent: 'American',
    gender: 'male',
    avatar_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/customvoice-icon.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Axel.mp3',
  },
  {
    voice_id: '11labs-victoria',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Victoria',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'female',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Victoria.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/Victoria.mp3',
  },
  {
    voice_id: 'deepgram-Angus',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Angus',
    provider: 'deepgram',
    accent: 'Irish',
    gender: 'male',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/augus.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/angus.wav',
  },
  {
    voice_id: '11labs-Susan',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Susan',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'female',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Susan.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/susan.mp3',
  },
  {
    voice_id: 'play-Daniel',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Daniel',
    provider: 'play',
    accent: 'American',
    gender: 'male',
    avatar_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/customvoice-icon.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Daniel.mp3',
  },
  {
    voice_id: 'play-Jason',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Jason',
    provider: 'play',
    accent: 'American',
    gender: 'male',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Jason.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Jason.mp3',
  },
  {
    voice_id: 'play-Gilfoy',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Gilfoy',
    provider: 'play',
    accent: 'American',
    gender: 'male',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/gilfoy.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Gilfoy.mp3',
  },
  {
    voice_id: 'deepgram-Perseus',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Perseus',
    provider: 'deepgram',
    accent: 'American',
    gender: 'male',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/perseus.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/perseus.wav',
  },
  {
    voice_id: 'deepgram-Orpheus',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Orpheus',
    provider: 'deepgram',
    gender: 'male',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/chris.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/orpheus.mp3',
  },
  {
    voice_id: 'play-Aaliyah',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Aaliyah',
    provider: 'play',
    accent: 'American',
    gender: 'female',
    avatar_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/customvoice-icon.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Aaliyah.mp3',
  },
  {
    voice_id: 'play-Anna',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Anna',
    provider: 'play',
    accent: 'American',
    gender: 'female',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/anna.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Anna.mp3',
  },
  {
    voice_id: 'play-Brian',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Brian',
    provider: 'play',
    accent: 'American',
    gender: 'male',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/brian.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Brian.mp3',
  },
  {
    voice_id: 'play-Ariana',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Ariana',
    provider: 'play',
    accent: 'American',
    gender: 'female',
    avatar_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/customvoice-icon.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Ariana.mp3',
  },
  {
    voice_id: '11labs-charlie',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Charlie (en-Au)',
    provider: 'elevenlabs',
    accent: 'Australian',
    gender: 'male',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/charlie.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/Charlie.mp3',
  },
  {
    voice_id: 'play-Kate',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Kate',
    provider: 'play',
    accent: 'American',
    gender: 'female',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Kate.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Kate.mp3',
  },
  {
    voice_id: '11labs-Emily',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Emily',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'female',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/emily.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/emily.mp3',
  },
  {
    voice_id: 'play-Marissa',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Marissa',
    provider: 'play',
    accent: 'American',
    gender: 'female',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Marissa.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Marissa.mp3',
  },
  {
    voice_id: 'play-John',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'John',
    provider: 'play',
    accent: 'American',
    gender: 'male',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/John.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-John.mp3',
  },
  {
    voice_id: 'play-Emily',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Emily',
    provider: 'play',
    accent: 'American',
    gender: 'female',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/emily.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Emily.mp3',
  },
  {
    voice_id: '11labs-Kathrine',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Kathrine',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'female',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/kathrine.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/Kathrine.mp3',
  },
  {
    voice_id: 'play-Nova',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Nova',
    provider: 'play',
    accent: 'American',
    gender: 'female',
    avatar_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/customvoice-icon.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Nova.mp3',
  },
  {
    voice_id: 'play-Jenny',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Jenny',
    provider: 'play',
    accent: 'American',
    gender: 'female',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Jenny.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Jenny.mp3',
  },
  {
    voice_id: 'deepgram-Helios',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Helios',
    provider: 'deepgram',
    accent: 'British',
    gender: 'male',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/helios.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/helios.wav',
  },
  {
    voice_id: '11labs-Monika',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Monika (en-IN)',
    provider: 'elevenlabs',
    accent: 'Indian',
    gender: 'female',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/monika.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/monika.mp3',
  },
  {
    voice_id: '11labs-Andrew',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Andrew',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'male',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/andrew.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/andrew.mp3',
  },
  {
    voice_id: 'play-Anthony',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Anthony',
    provider: 'play',
    accent: 'British',
    gender: 'male',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/anthony.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Anthony.mp3',
  },
  {
    voice_id: 'play-Victoria',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Victoria',
    provider: 'play',
    accent: 'American',
    gender: 'female',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Victoria.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Victoria.mp3',
  },
  {
    voice_id: 'play-Archie',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Archie',
    provider: 'play',
    accent: 'Australia',
    gender: 'male',
    avatar_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/customvoice-icon.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Archie.mp3',
  },
  {
    voice_id: '11labs-Nina',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Nina',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'female',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/nina.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/nina.mp3',
  },
  {
    voice_id: 'play-Dexter',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Dexter',
    provider: 'play',
    accent: 'American',
    gender: 'male',
    avatar_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/customvoice-icon.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Dexter.mp3',
  },
  {
    voice_id: '11labs-Amritanshu',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Amritanshu (en-IN)',
    provider: 'elevenlabs',
    accent: 'Indian',
    gender: 'male',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Amritanshu.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/Amritanshu.mp3',
  },
  {
    voice_id: 'play-Bing',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Bing',
    provider: 'play',
    accent: 'American',
    gender: 'male',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/bing.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Bing.mp3',
  },
  {
    voice_id: 'play-Lily',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Lily',
    provider: 'play',
    accent: 'American',
    gender: 'female',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/lily.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Lily.mp3',
  },
  {
    voice_id: 'deepgram-Luna',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Luna',
    provider: 'deepgram',
    accent: 'American',
    gender: 'female',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/luna.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/luna.wav',
  },
  {
    voice_id: '11labs-Santiago',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Santiago (es-ES)',
    provider: 'elevenlabs',
    accent: 'Spanish',
    gender: 'male',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Santiago.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/Santiago.mp3',
  },
  {
    voice_id: 'play-Nia',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Nia',
    provider: 'play',
    accent: 'American',
    gender: 'female',
    avatar_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/customvoice-icon.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Nia.mp3',
  },
  {
    voice_id: 'play-Baptiste',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Baptiste',
    provider: 'play',
    accent: 'French',
    gender: 'male',
    avatar_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/customvoice-icon.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Baptiste.mp3',
  },
  {
    voice_id: 'play-Lucas',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Lucas',
    provider: 'play',
    accent: 'American',
    gender: 'male',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/lucas.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Lucas.mp3',
  },
  {
    voice_id: 'deepgram-Hera',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Hera',
    provider: 'deepgram',
    accent: 'American',
    gender: 'female',
    age: 'Old',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/hera.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/hera.wav',
  },
  {
    voice_id: 'play-Nolan',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Nolan',
    provider: 'play',
    accent: 'American',
    gender: 'male',
    avatar_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/customvoice-icon.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Nolan.mp3',
  },
  {
    voice_id: 'openai-Alloy',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Alloy ',
    provider: 'openai',
    accent: 'American',
    gender: 'male',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/alloy-update.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/alloy_.wav',
  },
  {
    voice_id: 'play-Mia',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Mia',
    provider: 'play',
    accent: 'American',
    gender: 'female',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/mia.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Mia.mp3',
  },
  {
    voice_id: 'play-Kathrine',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Kathrine',
    provider: 'play',
    accent: 'American',
    gender: 'female',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/kathrine.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Kathrine.mp3',
  },
  {
    voice_id: '11labs-Myra',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Myra',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'female',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Myra.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/myra.mp3',
  },
  {
    voice_id: '11labs-Paola',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Paola',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'female',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Paola.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/paola.mp3',
  },
  {
    voice_id: 'play-Charles',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Charles',
    provider: 'play',
    accent: 'American',
    gender: 'male',
    avatar_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/customvoice-icon.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Charles.mp3',
  },
  {
    voice_id: 'play-Constanza',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Constanza',
    provider: 'play',
    accent: 'Mexican',
    gender: 'female',
    avatar_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/customvoice-icon.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Constanza.mp3',
  },
  {
    voice_id: '11labs-Evie',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Evie',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'female',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/evie.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/evie.mp3',
  },
  {
    voice_id: 'play-Grace',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Grace',
    provider: 'play',
    accent: 'American',
    gender: 'female',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/grace.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Grace.mp3',
  },
  {
    voice_id: 'deepgram-Asteria',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Asteria',
    provider: 'deepgram',
    accent: 'American',
    gender: 'female',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/asteria.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/asteria.wav',
  },
  {
    voice_id: 'play-Jennifer',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Jennifer',
    provider: 'play',
    accent: 'American',
    gender: 'female',
    avatar_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/customvoice-icon.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Jennifer.mp3',
  },
  {
    voice_id: 'openai-Onyx',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Onyx ',
    provider: 'openai',
    accent: 'American',
    gender: 'male',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/onyx.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/onyx_.wav',
  },
  {
    voice_id: '11labs-Carola',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Carola (de-DE)',
    provider: 'elevenlabs',
    accent: 'German',
    gender: 'female',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/carola.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/Carola.mp3',
  },
  {
    voice_id: 'play-Adrian',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Adrian',
    provider: 'play',
    accent: 'American',
    gender: 'male',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/adrian.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Adrian.mp3',
  },
  {
    voice_id: '11labs-James',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'James',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'male',
    age: 'Old',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/james.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/James.mp3',
  },
  {
    voice_id: 'play-Ethan',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Ethan',
    provider: 'play',
    accent: 'American',
    gender: 'male',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/ethan.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Ethan.mp3',
  },
  {
    voice_id: '11labs-Cimo',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Cimo',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'female',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/cimo.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/cimo.mp3',
  },
  {
    voice_id: 'play-Paul',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Paul',
    provider: 'play',
    accent: 'American',
    gender: 'male',
    age: 'Old',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/paul.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Paul.mp3',
  },
  {
    voice_id: 'deepgram-Athena',
    voice_type: 'standard',
    standard_voice_type: 'preset',
    voice_name: 'Athena',
    provider: 'deepgram',
    accent: 'British',
    gender: 'female',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/athena.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/athena.wav',
  },
  {
    voice_id: 'play-Evie',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Evie',
    provider: 'play',
    accent: 'American',
    gender: 'female',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/evie.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Evie.mp3',
  },
  {
    voice_id: 'play-Billy',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Billy',
    provider: 'play',
    accent: 'American',
    gender: 'male',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/billy.png',
    preview_audio_url:
      'https://retell-utils-public.s3.us-west-2.amazonaws.com/play-Billy.mp3',
  },
  {
    voice_id: '11labs-Ryan',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Ryan',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'male',
    age: 'Young',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/ryan.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/ryan.mp3',
  },
  {
    voice_id: '11labs-Mia',
    voice_type: 'standard',
    standard_voice_type: 'retell',
    voice_name: 'Mia',
    provider: 'elevenlabs',
    accent: 'American',
    gender: 'female',
    age: 'Middle Aged',
    avatar_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/mia.png',
    preview_audio_url: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/Mia.mp3',
  },
];
