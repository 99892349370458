import { Call } from '@/shared/types/calls';
import { FilterItem, Sort } from '@/shared/types/filter';

import { api } from '../api';

export const searchCalls = async (
  filter: Array<FilterItem> = [],
  sort: Array<Sort> = [],
  limit = 100,
  offset = 0,
  signal?: AbortSignal
): Promise<{ data: Call[]; total: number }> => {
  const { data } = await api.post(
    'v2/calls/search',
    {
      filter,
      sort,
      limit,
      offset,
    },
    { signal }
  );
  return data;
};

export const getCall = async (id: string): Promise<{ data: Call }> => {
  const { data } = await api.get(`v2/calls/${id}`);
  return data;
};

export const startCallRecording = async (
  callSid: string
): Promise<{ recording_id: string }> => {
  const { data } = await api.put(`v2/calls/${callSid}`, { recording: true });
  return data;
};

export const stopCallRecording = async (
  callSid: string,
  recording_id: string
): Promise<{ data: Call }> => {
  const { data } = await api.put(`v2/calls/${callSid}`, {
    recording: false,
    recording_id,
  });
  return data;
};

type CreatePreviewCall = {
  agent_id: string;
  channel_id: string;
  to: string;
};

export const createPreviewCall = async (
  params: CreatePreviewCall
): Promise<{ data: { message: string } }> => {
  const { data } = await api.post(`v2/agents/${params.agent_id}/call`, params);
  return data;
};

export const addCallParticipant = async (
  callSid: string,
  phone?: string,
  userId?: number
) => {
  const data = await api.post(`v2/calls/${callSid}/participants`, {
    phone: userId ? `user_${userId}` : phone,
  });
  return data;
};

export const deleteCallParticipant = async (
  callSid: string,
  phone?: string,
  userId?: number
) => {
  const data = await api.delete(
    `v2/calls/${callSid}/participants?phone=${userId ? `user_${userId}` : phone}`
  );
  return data;
};

export const holdCallParticipant = async (
  callSid: string,
  hold: boolean,
  phone?: string,
  userId?: number
) => {
  const data = await api.put(`v2/calls/${callSid}/participants`, {
    phone: userId ? `user_${userId}` : phone,
    hold,
  } as any);
  return data;
};
