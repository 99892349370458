import * as React from 'react';
import { HiArrowSmLeft } from 'react-icons/hi';

import { Button, HStack } from '@/shared/ui';
import { ProgressBar } from '@/shared/ui/Progress'; // Import the ProgressBar component
import { styled } from '@/stitches.config';

interface Step {
  title: string;
  description: string;
  children?: React.ReactNode;
  isValid?: boolean; // Add validation state for each step
}

interface MultiStepFormProps {
  steps: Step[];
  onComplete: (data: any) => void;
  // Add new props for validation
  onStepValidation?: (stepIndex: number, formData?: any) => boolean | Promise<boolean>;
  formData?: any; // Optional form data to be passed around
  onStepChange?: (nextStep: number) => void;
  onCompleteText?: string;
}

const FormContainer = styled('div', {
  width: '100%',
  maxWidth: '640px',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '$5',
  padding: '$5',
  border: '1px solid rgba(2, 2, 52, 0.08)',
  borderRadius: '$4',
});

const StepInfo = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const StepContent = styled('div', {
  minHeight: '200px',
  border: '2px dashed $mutedForeground',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const StepTitle = styled('h2', {
  fontSize: '24px',
  fontWeight: '600',
});

const StepDescription = styled('p', {
  color: '$mutedForeground',
});

const ButtonContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: '16px',
});

export function MultiStepForm({
  steps,
  onComplete,
  onStepValidation,
  formData,
  onStepChange,
  onCompleteText,
}: MultiStepFormProps) {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [isNextEnabled, setIsNextEnabled] = React.useState(false);
  const [isValidating, setIsValidating] = React.useState(false);

  // Validate step when mounted and when formData changes
  React.useEffect(() => {
    validateCurrentStep();
  }, [currentStep, formData]);

  const validateCurrentStep = async () => {
    if (!onStepValidation) {
      setIsNextEnabled(true);
      return;
    }

    setIsValidating(true);
    try {
      const isValid = await onStepValidation(currentStep, formData);
      setIsNextEnabled(isValid);
    } catch (error) {
      setIsNextEnabled(false);
    }
    setIsValidating(false);
  };

  const handleNext = async () => {
    if (currentStep < steps.length - 1) {
      const nextStep = currentStep + 1;
      setCurrentStep(nextStep);
      onStepChange?.(nextStep);
    } else if (currentStep === steps.length - 1 && isNextEnabled) {
      onComplete(formData);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      const prevStep = currentStep - 1;
      setCurrentStep(prevStep);
      onStepChange?.(prevStep);
    }
  };

  return (
    <FormContainer>
      <StepInfo>
        <div>
          Step {currentStep + 1} of {steps.length}
        </div>
        <ProgressBar
          value={((currentStep + 1) / steps.length) * 100}
          variant="primary" // You can choose the variant you prefer
        />
      </StepInfo>

      <div>
        <StepTitle>{steps[currentStep].title}</StepTitle>
        <StepDescription>{steps[currentStep].description}</StepDescription>
      </div>

      <StepContent>{steps[currentStep].children}</StepContent>

      <ButtonContainer>
        <Button
          onClick={handleBack}
          disabled={currentStep === 0 || isValidating}
          size="2"
          variant="grayBackground"
          css={{ fontWeight: 500 }}
        >
          <HStack>
            <HiArrowSmLeft />
            Back
          </HStack>
        </Button>
        <Button
          onClick={handleNext}
          size="2"
          disabled={!isNextEnabled || isValidating}
          type={currentStep === steps.length - 1 ? 'submit' : 'button'}
        >
          {currentStep === steps.length - 1 ? (onCompleteText ?? 'Complete') : 'Next'}
        </Button>
      </ButtonContainer>
    </FormContainer>
  );
}
