/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { HiCloudUpload, HiUserAdd, HiX } from 'react-icons/hi';

import { BulkImport } from '@/contacts/uploads/BulkImport';
import { useUploads } from '@/contacts/uploads/context/UploadContext';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { MultiSelect } from '@/shared/components/MultiSelect';
import { useDisclosure } from '@/shared/hooks';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Divider,
  Flex,
  HStack,
  IconButton,
  Input,
  Label,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  VStack,
} from '@/shared/ui';
import { Kbd } from '@/shared/ui/Kbd';

import { AudiencePaste } from './AudiencePaste';

type AddUploadToAudienceProps = {
  addPastedDataToAudience: any;
  setQuickCampaignDialogState: any;
  renderAsIconButton?: boolean;
};

export const AddUploadToAudience = (props: AddUploadToAudienceProps) => {
  const { uploadName, setUploadName, setUploadLocations } = useUploads();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const locationsContext = useChannels();
  const { channelsState } = locationsContext;

  const channels = channelsState.allChannels;

  const [selectedLocations, setSelectedLocations] = React.useState({
    locations: channels.map((location) => location.id) || [],
  });

  const closeDialog = (setLocationsToDefault: boolean) => {
    onClose();
    setLocationsToDefault
      ? setSelectedLocations({
          locations: channels.map((location) => location.id) || [],
        })
      : null;
  };

  useEffect(() => {
    setUploadLocations(selectedLocations.locations);
  }, [selectedLocations.locations]);

  return (
    <>
      <Dialog open={isOpen} modal={false}>
        <DialogTrigger asChild>
          <Tooltip>
            <TooltipTrigger asChild>
              {renderIconButton(onOpen, props.renderAsIconButton)}
            </TooltipTrigger>
            <TooltipContent>{'Paste or Upload Contacts'}</TooltipContent>
          </Tooltip>
        </DialogTrigger>
        <DialogPortal>
          <DialogContent
            onEscapeKeyDown={() => closeDialog(true)}
            onPointerDownOutside={() => closeDialog(true)}
          >
            <AudiencePaste
              addPastedDataToAudience={props.addPastedDataToAudience}
              setQuickCampaignDialogState={props.setQuickCampaignDialogState}
              closePasteDialog={() => closeDialog(true)}
            />
            <DialogTitle variant="bold">Create New Contact List</DialogTitle>
            <VStack gap={6}>
              <VStack gap={2}>
                <Box>
                  <Label htmlFor="name">
                    <Flex>
                      Name Contact List{' '}
                      <Box css={{ color: 'red', ml: 5 }}>
                        {uploadName.length > 28
                          ? '(max 30 characters)'
                          : '(name must be unique)'}
                      </Box>
                    </Flex>
                  </Label>
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    value={uploadName}
                    maxLength={29}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setUploadName(e.target.value)
                    }
                  />
                </Box>
                <Box>
                  <Label htmlFor="locations">
                    <Flex>
                      Opt in to{' '}
                      <Box css={{ color: 'red', ml: 5 }}>
                        {selectedLocations.locations.length < 1
                          ? '(at least one channel is required)'
                          : ''}
                      </Box>
                    </Flex>
                  </Label>
                  {channels.length > 0 && (
                    <MultiSelect
                      defaultPlaceholder="Channels"
                      defaultSelectedItems={selectedLocations.locations}
                      isDropdown={true}
                      options={channels.map((location: any) => ({
                        type: location?.name || '',
                        value: location.id,
                      }))}
                      parentSelectedItems={selectedLocations}
                      setParentSelectedItems={setSelectedLocations}
                      isCampaigns={true}
                    />
                  )}
                </Box>
              </VStack>
              <BulkImport addImportedDataToAudience={props.addPastedDataToAudience}>
                <Button
                  variant="gray"
                  size={4}
                  as="div"
                  onClick={() => closeDialog(false)}
                  css={{ width: '100%' }}
                >
                  <HiCloudUpload size={16} /> Click to Upload a File
                </Button>
              </BulkImport>
              <Flex align="center">
                <Divider />
                <Box css={{ px: 10, fontWeight: 500 }}>OR</Box>
                <Divider />
              </Flex>
              <Flex
                css={{
                  width: '100%',
                  height: 100,
                  border: '2px dotted $slate8',
                  borderRadius: '6px',
                }}
                align="center"
                justify="center"
              >
                <VStack gap={2}>
                  <Flex
                    align="center"
                    justify="center"
                    css={{ width: '100%', fontSize: 13 }}
                  >
                    Paste Numbers from Clipboard
                  </Flex>
                  <HStack
                    align="center"
                    css={{ width: '100%', justifyContent: 'center' }}
                  >
                    <Kbd>CMD ⌘</Kbd>
                    <Kbd>+</Kbd>
                    <Kbd>V</Kbd>
                  </HStack>
                </VStack>
              </Flex>
            </VStack>
            <DialogClose asChild>
              <DialogCloseIcon onClick={() => closeDialog(true)} size="2">
                <HiX size="15px" />
              </DialogCloseIcon>
            </DialogClose>
          </DialogContent>
        </DialogPortal>
      </Dialog>
    </>
  );
};

const renderIconButton = (onOpen: () => void, shouldRenderAsIconButton?: boolean) => {
  if (!shouldRenderAsIconButton) {
    return (
      <Box
        onClick={onOpen}
        css={{ cursor: 'pointer' }}
        data-testid="create-upload-button"
      >
        <HiUserAdd />
      </Box>
    );
  }
  return (
    <IconButton
      size={2}
      onClick={onOpen}
      data-testid={'create-upload-button'}
      css={{ cursor: 'pointer' }}
    >
      <HiUserAdd />
    </IconButton>
  );
};
