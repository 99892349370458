import React from 'react';
import { HiReply } from 'react-icons/hi';

import { Box, Flex, Text } from '@/shared/ui';

interface TemplateButton {
  id: string;
  type: 'QUICK_REPLY';
  text: string;
}

interface WhatsAppPreviewProps {
  headerText?: string;
  bodyText: string;
  footerText?: string;
  buttons: TemplateButton[];
}

export default function WhatsAppPreview({
  headerText,
  bodyText,
  footerText,
  buttons,
}: WhatsAppPreviewProps): JSX.Element {
  const hasButtons = buttons.length > 0;
  const showAllButtonsText = 'See all options';

  // Format time for the message
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes().toString().padStart(2, '0');
  const displayHours = hours === 0 ? 12 : hours > 12 ? hours - 12 : hours;
  const timeString = `${displayHours}:${minutes} ${hours >= 12 ? 'PM' : 'AM'}`;

  return (
    <Box
      css={{
        width: '100%',
        maxWidth: '400px',
        margin: '0 auto',
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
      }}
    >
      {/* WhatsApp-style message container */}
      <Box
        css={{
          backgroundColor: '#e5ddd5',
          backgroundImage: 'url("/whatsapp-bg.png")',
          backgroundSize: 'cover',
          padding: '20px',
          minHeight: '300px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          css={{
            backgroundColor: 'white',
            borderRadius: '8px',
            width: '100%',
            overflow: 'hidden',
            boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
          }}
        >
          <Box css={{ padding: '12px 16px' }}>
            {headerText && (
              <Text
                css={{
                  fontWeight: 'bold',
                  fontSize: '15px',
                  marginBottom: '8px',
                }}
              >
                {headerText}
              </Text>
            )}

            <Text css={{ fontSize: '14px' }}>{bodyText}</Text>

            {footerText && (
              <Text
                css={{
                  fontSize: '12px',
                  color: '#667781',
                  marginTop: '8px',
                }}
              >
                {footerText}
              </Text>
            )}

            <Text
              css={{
                fontSize: '12px',
                color: '#667781',
                textAlign: 'right',
                marginTop: '4px',
              }}
            >
              {timeString}
            </Text>
          </Box>

          {hasButtons && (
            <Box
              css={{
                borderTop: '1px solid #e0e0e0',
              }}
            >
              {buttons
                .slice(0, Math.min(buttons.length, buttons.length > 3 ? 2 : 3))
                .map((button, index) => (
                  <Box
                    key={button.id}
                    css={{
                      borderBottom:
                        index < Math.min(buttons.length, buttons.length > 3 ? 2 : 3) - 1
                          ? '1px solid #e0e0e0'
                          : 'none',
                      padding: '10px 16px',
                      textAlign: 'center',
                      color: '#0277bd',
                      fontSize: '14px',
                      cursor: 'pointer',
                    }}
                  >
                    {button.type === 'QUICK_REPLY' && (
                      <Flex
                        css={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '6px',
                        }}
                      >
                        <HiReply style={{ transform: 'scaleX(-1)' }} />
                        <span>{button.text || `Reply ${index + 1}`}</span>
                      </Flex>
                    )}
                  </Box>
                ))}

              {buttons.length > 3 && (
                <Box
                  css={{
                    padding: '10px 16px',
                    textAlign: 'center',
                    color: '#0277bd',
                    fontSize: '14px',
                    cursor: 'pointer',
                  }}
                >
                  <span>{showAllButtonsText}</span>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
