import React from 'react';

import { ContactView } from '@/pages/data/contact';
import { ContactErrorBoundary } from '@/pages/data/contact/ContactErrorBoundary';
import { ContactPanelHeader } from '@/pages/data/contact/ContactPanelHeader';
import { Contact } from '@/shared/types/contacts';

type ContactPanelProps = {
  contact?: Contact;
  onClose?: () => void;
};

export const ContactPanel = ({ contact, onClose }: ContactPanelProps) => {
  return (
    <ContactErrorBoundary feature="Contact">
      <ContactPanelHeader contact={contact} onClose={onClose} isInbox />
      <ContactView contactId={contact?.id} onClose={onClose} isInbox />
    </ContactErrorBoundary>
  );
};
