import React, { useState } from 'react';

import { Flex } from '@/shared/ui';

export type CampaignCardStatisticProps = {
  label: string;
  value: string | number;
  rate: number | string;
};

const CampaignCardStatistic = (props: CampaignCardStatisticProps) => {
  const { label, value, rate } = props;

  const [showRate, setShowRate] = useState(false);

  const onMouseEnter = () => {
    setShowRate(true);
  };

  const onMouseLeave = () => {
    setShowRate(false);
  };

  return (
    <Flex
      direction="column"
      css={{ mx: 12 }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Flex css={{ fontSize: 16, fontColor: '#2B2A34', fontWeight: 600 }}>
        {showRate && typeof value === 'number' && value > 0
          ? `${Math.round(rate as number)}%`
          : value}
      </Flex>
      <Flex css={{ fontSize: 12, fontColor: '#7F7F86', mt: 4 }}>{label}</Flex>
    </Flex>
  );
};

export default CampaignCardStatistic;
