import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { HiChevronLeft, HiSearch, HiX } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';

import { DrawerHeaderContainer } from '@/pages/data/contacts/CreateContact';
import { Campaign } from '@/shared/types/campaigns';
import {
  Box,
  Button,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerPortal,
  DrawerTrigger,
  Flex,
  HStack,
  IconButton,
  Input,
  Skeleton,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

import { renderCampaignDate } from '../../contacts/utils';
import { useContacts } from '../context/ContactContext';
import { AccordionValue } from './';
import { ContactAccordion } from './ContactAccordion';

type ContactCampaignsProps = {
  contactId: string;
  loading: boolean;
  accordionValue: AccordionValue;
};

const LIMIT = 20;

export const ContactCampaigns = ({
  contactId,
  loading,
  accordionValue,
}: ContactCampaignsProps) => {
  // contacts context
  const {
    contactState: {
      currentContactCampaigns,
      currentContactCampaignsTotal,
      loadingContactCampaigns,
    },
  } = useContacts();

  // render campaign item function
  const renderItem = useCallback(
    (item: Campaign, i: number) => (
      <CampaignItem
        data={item}
        isLast={i === Math.min(currentContactCampaigns?.length - 1, 4)}
        isFirst={i === 0}
      />
    ),
    [currentContactCampaigns?.length]
  );

  return (
    <ContactAccordion
      title={'Campaigns'}
      accordionValue={accordionValue}
      defaultValue={accordionValue}
    >
      {loadingContactCampaigns || loading ? (
        <Flex css={{ flexWrap: 'wrap', position: 'relative' }}>
          <Skeleton
            css={{ height: 125, width: 2, position: 'absolute', top: 4, left: 71 }}
          />
          {Array.from({ length: 5 }, (_: any, k: React.Key | null | undefined) => (
            <Flex align="center" key={k}>
              <Skeleton css={{ height: 20, width: 56, mr: 12, my: 6 }} />
              <Skeleton css={{ height: 8, width: 8, mr: 12, my: 6, borderRadius: 8 }} />
              <Skeleton css={{ height: 20, width: 160, mr: 68, my: 6 }} />
              <Skeleton css={{ height: 20, width: 56, my: 6 }} />
            </Flex>
          ))}
        </Flex>
      ) : currentContactCampaignsTotal > 0 ? (
        <Flex direction="column">
          {currentContactCampaigns?.slice(0, 5).map(renderItem)}
        </Flex>
      ) : (
        <Flex direction="column" align="center">
          <Box css={{ fontSize: 16, fontWeight: '700', marginBottom: 8 }}>
            No Campaigns Yet
          </Box>
          <Box css={{ fontSize: 16 }}>This user is not involved in any campaign</Box>
        </Flex>
      )}
      {!(loading || loadingContactCampaigns) && currentContactCampaignsTotal > 5 && (
        <CampaignsDrawer contactId={contactId || ''}>
          <Button
            ghost
            size={1}
            css={{
              color: '#60646C',
              mt: 8,
              fontSize: 14,
              boxShadow: 'none',
              '&:focus': {
                boxShadow: 'none',
              },
            }}
          >
            {`See All Campaigns`}
          </Button>
        </CampaignsDrawer>
      )}
    </ContactAccordion>
  );
};

const CampaignItem = ({
  data,
  isFirst,
  isLast,
}: {
  data: Campaign;
  isFirst: boolean;
  isLast: boolean;
}) => {
  return (
    <StyledCampaignItem align="center">
      <CampaignDate>
        {renderCampaignDate(data?.updated_at || data?.inserted_at || '')}
      </CampaignDate>
      <CircleDivider isFirst={isFirst} isLast={isLast} />
      <CampaignTitle to={`/campaigns/${data.id}`}>{data.title}</CampaignTitle>
    </StyledCampaignItem>
  );
};

type CampaignsDrawerProps = {
  contactId: string;
  children: React.ReactNode;
};

const CampaignsDrawer = ({ contactId, children }: CampaignsDrawerProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  // contact context
  const {
    contactState: {
      currentContactCampaigns,
      searchedCurrentContactCampaigns,
      currentContactCampaignsTotal,
      searchedCurrentContactCampaignsTotal,
    },
    getContactCampaigns,
  } = useContacts();

  // local context
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState('');

  // handle search campaigns
  const handleSearch = useCallback(
    debounce(async (e: React.ChangeEvent<HTMLInputElement>) => {
      await getContactCampaigns(
        contactId,
        { offset: 0, limit: LIMIT },
        e.target.value,
        false
      );
      setSearch(e.target.value);
      setOffset(0);
    }, 500),
    [contactId]
  );

  // handle clear search
  const handleClearSearch = useCallback(() => {
    if (inputRef?.current) {
      inputRef.current.value = '';
    }
    setSearch('');
    setOffset(0);
  }, []);

  // handle bottom state change to load more campaigns
  const handleBottomStateChange = useCallback(
    async (atBottom: boolean) => {
      const total = search
        ? searchedCurrentContactCampaignsTotal
        : currentContactCampaignsTotal;
      const length = search
        ? searchedCurrentContactCampaigns?.length
        : currentContactCampaigns?.length;
      if (atBottom && total > length) {
        await getContactCampaigns(
          contactId,
          { offset: offset + LIMIT, limit: LIMIT },
          search,
          false
        );
        setOffset(offset + LIMIT);
      }
    },
    [
      offset,
      search,
      currentContactCampaignsTotal,
      searchedCurrentContactCampaignsTotal,
      currentContactCampaigns?.length,
      searchedCurrentContactCampaigns?.length,
      contactId,
    ]
  );

  // get contacts campaign on first mount
  useEffect(() => {
    contactId && getContactCampaigns(contactId, { offset: 0, limit: LIMIT }, '', false);
  }, [contactId]);

  // reset local state when unmount
  useEffect(() => {
    return () => {
      setOffset(0);
      setSearch('');
    };
  }, []);

  return (
    <Drawer>
      <DrawerTrigger asChild>{children}</DrawerTrigger>
      <DrawerPortal>
        <DrawerContent
          aria-describedby={`Contact Campaigns`}
          css={{
            maxWidth: '420px',
            minWidth: '360px',
            top: 0,
            width: '100%',
            height: '100%',
            zIndex: 99,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Flex>
            <DrawerHeaderContainer css={{ px: 20 }}>
              <HStack>
                <DrawerClose>
                  <HiChevronLeft />
                </DrawerClose>
                <Heading css={{ textTransform: 'capitalize', ml: 20 }}>Campaigns</Heading>
              </HStack>
            </DrawerHeaderContainer>
          </Flex>
          <SearchContainer>
            <SearchIconContainer>
              <HiSearch />
            </SearchIconContainer>
            <SearchInput
              ref={inputRef}
              placeholder={`Search Campaigns`}
              css={{ pl: 38 }}
              onChange={handleSearch}
            />
            {search.length > 0 && (
              <SearchControlsContainer css={{ right: 16 }}>
                <IconButton onClick={handleClearSearch}>
                  <HiX />
                </IconButton>
              </SearchControlsContainer>
            )}
          </SearchContainer>
          <DrawerContentContainer>
            <Virtuoso
              style={{ width: '100%' }}
              data={search ? searchedCurrentContactCampaigns : currentContactCampaigns}
              atBottomStateChange={handleBottomStateChange}
              itemContent={(i: number, item: Campaign) => (
                <Flex css={{ paddingLeft: 24, paddingRight: 24 }}>
                  <CampaignItem
                    key={item?.id || i}
                    data={item}
                    isLast={
                      (search ? searchedCurrentContactCampaigns : currentContactCampaigns)
                        ?.length -
                        1 ===
                      i
                    }
                    isFirst={i === 0}
                  />
                </Flex>
              )}
              components={{
                EmptyPlaceholder: () => {
                  if (search) {
                    return (
                      <Box css={{ textAlign: 'center', fontSize: 14 }}>
                        No search results
                      </Box>
                    );
                  }
                  return null;
                },
                Header: () => <Box css={{ pb: 18 }} />,
                Footer: () => <Box css={{ pb: 18 }} />,
              }}
            />
          </DrawerContentContainer>
        </DrawerContent>
      </DrawerPortal>
    </Drawer>
  );
};

const StyledCampaignItem = styled(Flex, {
  width: '100%',
  flex: 1,
  py: 6,
});

const CampaignTitle = styled(Link, {
  fontSize: 14,
  color: '$primaryButtonColor',
  textDecoration: 'underline',
  marginLeft: 12,
  '&:hover': {
    textDecoration: 'none',
  },
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});

const CampaignDate = styled(Box, {
  width: 105,
  textAlign: 'right',
  fontSize: 14,
  color: '#1C2024',
  marginRight: 12,
  flex: 'none',
});

const CircleDivider = styled(Box, {
  width: 8,
  height: 8,
  borderRadius: 8,
  backgroundColor: '$slate5',
  position: 'relative',
  '&:after': {
    content: '',
    position: 'absolute',
    top: -12,
    left: 3,
    width: 2,
    height: 33,
    backgroundColor: '$slate5',
  },
  variants: {
    isFirst: {
      true: {
        '&:after': {
          top: 0,
          height: 22,
        },
      },
    },
    isLast: {
      true: {
        '&:after': {
          height: 17,
        },
      },
    },
  },
});

export const DrawerContentContainer = styled(Flex, {
  position: 'relative',
  width: '100%',
  height: '100%',
  flex: 1,
});

export const Heading = styled(Flex, {
  flex: 'initial 0 initial',
  fontSize: 17,
  fontWeight: 800,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const SearchContainer = styled(Box, {
  position: 'relative',
  width: '100%',
  px: 16,
  py: 12,
  borderBottom: 'thin solid var(--colors-gray4)',
});

const SearchInput = styled(Input, {
  boxShadow: 'none',
  height: 32,
  '&:focus': {
    boxShadow: 'none',
  },
});

const SearchIconContainer = styled(Box, {
  position: 'absolute',
  top: 29,
  transform: 'translateY(-50%)',
  pointerEvents: 'none',
  left: 20,
});

const SearchControlsContainer = styled(Box, {
  position: 'absolute',
  top: 29,
  transform: 'translateY(-50%)',
  right: 16,
});
