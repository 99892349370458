import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import React from 'react';
import { ResponsiveContainer } from 'recharts';

import { Card, ReportCard } from '@/pages/links/analytics/Card';
import { Chart } from '@/pages/links/analytics/Chart';
import { ReportData } from '@/shared/api/reports';
import { DAY } from '@/shared/components/reports/constants';
import { ReportsCollapsible } from '@/shared/components/reports/ReportsCollapsible';
import { EstimatedSavingsData } from '@/shared/types';
import { Grid } from '@/shared/ui';

import { EstimatedSavingsDialog } from './EstimatedSavingsDialog';
import { Heatmap } from './Heatmap';

export const initialDates = {
  startDate: moment().add(-4, 'week'),
  endDate: moment(),
};

const chart_config = [
  {
    dataKey: 'count',
    fill: '#3E54CF',
    name: 'Call',
  },
];

const chart_config2 = [
  {
    dataKey: 'negative',
    fill: 'red',
    name: 'Negative',
    stackId: 'bar',
  },
  {
    dataKey: 'neutral',
    fill: '#FBE32D',
    name: 'Neutral',
    stackId: 'bar',
  },
  {
    dataKey: 'positive',
    fill: 'green',
    name: 'Positive',
    stackId: 'bar',
  },
];

const agentCalls = [
  {
    label: 'Total AI Calls',
    type: 'total',
    description:
      'The total number of agent calls created during the selected time period.',
  },
  {
    label: 'Complete AI Calls',
    type: 'total',
    description:
      'The total number of complete agent calls created during the selected time period.',
  },
  {
    label: 'Incomplete AI Calls',
    type: 'total',
    description:
      'The total number of incomplete agent calls created during the selected time period.',
  },
  {
    label: 'Average Completed AI Call Duration',
    type: 'total',
    description: 'Average Completed AI Call Duration',
    unit: 'min',
  },
  {
    label: 'Hours Automated',
    type: 'total',
    description: 'Hours Automated',
    unit: 'h',
  },
];

const agentCallSentiments = [
  {
    label: 'Positive Sentiment AI Calls',
    type: 'total',
    description:
      'The total number of agent calls with positive sentiment created during the selected time period.',
  },
  {
    label: 'Negative Sentiment AI Calls',
    type: 'total',
    description:
      'The total number of agent calls with negative sentiment created during the selected time period.',
  },
  {
    label: 'Neutral Sentiment AI Calls',
    type: 'total',
    description:
      'The total number of agent calls with neutral sentiment created during the selected time period.',
  },
];

export const AgentsAnalytics = ({
  id,
  data,
  reportBar,
  stackedReportBar,
  isLoading,
  timeBucket = DAY,
  error,
}: {
  data: Array<ReportData>;
  reportBar: any;
  stackedReportBar: any;
  isLoading: boolean;
  error: boolean;
  id?: string;
  timeBucket?: string;
}) => {
  const [estimatedSavingsData, setEstimatedSavingsData] = useState<EstimatedSavingsData>({
    timePerCompleteCall: 15,
    timePerIncompleteCall: 10,
    rate: 25,
  });

  const estimatedSavings = useMemo(() => {
    const completeCalls = data.find(
      (d) => d.chart === 'total' && d.label == 'Complete AI Calls'
    )?.values[0];
    const incompleteCalls = data.find(
      (d) => d.chart === 'total' && d.label == 'Incomplete AI Calls'
    )?.values[0];
    if (
      (completeCalls?.x || completeCalls?.x === 0) &&
      (incompleteCalls?.x || incompleteCalls?.x === 0) &&
      estimatedSavingsData.rate &&
      estimatedSavingsData.timePerCompleteCall &&
      estimatedSavingsData.timePerIncompleteCall
    ) {
      const result =
        (((completeCalls.x as number) * estimatedSavingsData.timePerCompleteCall +
          (incompleteCalls.x as number) * estimatedSavingsData.timePerIncompleteCall) /
          60) *
        estimatedSavingsData.rate;

      const previousValue =
        (((completeCalls.prev_x ?? 0) * estimatedSavingsData.timePerCompleteCall +
          (incompleteCalls.prev_x ?? 0) * estimatedSavingsData.timePerIncompleteCall) /
          60) *
        estimatedSavingsData.rate;
      return {
        value: Math.round(result * 100) / 100,
        previousValue: Math.round(previousValue * 100) / 100,
      };
    } else {
      return { value: 0, previousValue: 0 };
    }
  }, [data, estimatedSavingsData]);

  useEffect(() => {
    if (id) {
      const data = localStorage.getItem(`estimatedSavingsData-${id}`);
      if (data) {
        setEstimatedSavingsData(JSON.parse(data));
      }
    }
  }, [id]);

  const updateEstimatedSavingsData = (data: EstimatedSavingsData) => {
    localStorage.setItem(`estimatedSavingsData-${id}`, JSON.stringify(data));
    setEstimatedSavingsData(data);
  };

  return (
    <>
      <Grid columns="3" gap="5" data-testid="agents-analytics">
        {agentCalls.map((call) => (
          <ReportCard key={call.label} data={data} item={call} isLoading={isLoading} />
        ))}
        <Card
          title={'Estimated Savings'}
          actionComponent={
            <EstimatedSavingsDialog
              estimatedSavingsData={estimatedSavingsData}
              setEstimatedSavingsData={updateEstimatedSavingsData}
            />
          }
          value={estimatedSavings.value as number}
          previousValue={estimatedSavings.previousValue as number}
          loading={isLoading}
          unit="USD"
        />
      </Grid>
      <Chart
        error={error}
        title="Calls by Day"
        chartConfig={chart_config}
        data={reportBar || []}
        loading={isLoading}
        barSize={56}
        time_bucket={timeBucket}
      />
      <ReportsCollapsible title="Call Heatmap" description="">
        <ResponsiveContainer>
          <Heatmap error={error} data={data} loading={isLoading} />
        </ResponsiveContainer>
      </ReportsCollapsible>
      <Grid columns="3" gap="5">
        {agentCallSentiments.map((call) => (
          <ReportCard key={call.label} data={data} item={call} isLoading={isLoading} />
        ))}
      </Grid>
      <Chart
        error={error}
        title="Sentiment of Calls by Day"
        chartConfig={chart_config2}
        data={stackedReportBar || []}
        loading={isLoading}
        barSize={56}
        time_bucket={timeBucket}
      />
    </>
  );
};
