/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/exhaustive-deps */
import Fuse from 'fuse.js';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { HiX } from 'react-icons/hi';

import { useContacts } from '@/contacts/context/ContactContext';
import { useGroups } from '@/contacts/groups/context/GroupContext';
import { useUploads } from '@/contacts/uploads/context/UploadContext';
import { ContactTagLayout } from '@/pages/data/contacts/tags/ContactTag';
import { useTags } from '@/pages/settings/organization/tags/context/TagsContext';
import { searchContacts } from '@/shared/api/contacts/v1';
import { CircleIcon } from '@/shared/components/Icons';
import { Contact } from '@/shared/types/campaigns';
import { Tag } from '@/shared/types/tags';
import { Box, Flex, HStack, IconButton } from '@/shared/ui';
import {
  formatPhoneNumber,
  phoneFormatting,
  phoneRegex,
} from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { useCampaignsContext } from '../context/CampaignsContext';
import { PreSelectedAudience } from '../quick';
import { AudienceFilterDropdown } from './AudienceFilterDropdown';
import { AudienceItem } from './AudienceItem';
import { AudienceSearchInput } from './AudienceSearchInput';
import { ContactTags } from './render/ContactsAudience';
import { GroupTags } from './render/GroupsAudience';
import { Tags } from './render/TagsAudience';
import { UploadTags } from './render/UploadsAudience';
import {
  getCampaignsContactTypeString,
  getCampaignsContactTypeStringFromKeys,
  removeEmptyFields,
} from './utils';

export const CONTACTS = 'contacts';
export const GROUPS = 'groups';
export const TAGS = 'tags';
export const UPLOADS = 'uploads';
export const CAMPAIGN_TAB = 'wholeCampaignTab';
export const SELECTED_CONTACTS = 'selectedContacts';

type AudienceSelectionProps = {
  /** location id */
  locationId: string;
  /** the currently selected audience from the parent component */
  selectedAudience: AudienceState;
  /** change the currently selected audience from the parent component */
  setSelectedAudience: (selectedItem: AudienceState) => void;
  /** if we are on the campaigns page and a whole tabs is selected we use this */
  audienceFromCampaign?: { campaign_id?: string; campaign_contacts_type?: string };
  /** true if we are on the campaigns page */
  isCampaignsPage: boolean;
  /** true if we are editing the quick campaign */
  isCampaignsEditPage: boolean;
  /** true if we are on the contacts page */
  isContactsPage: boolean;
  /** true if we are on the groups page */
  isGroupsPage: boolean;
  /** true if we are on the uploads page */
  isUploadsPage: boolean;
  /** true if we are on the inbox page */
  isInbox: boolean;
  /** set the QuickCampaignDialogState of the parent */
  setQuickCampaignDialogState?: (state: boolean) => void;
  /** preselected audience e.g. tags, uploads, contacts */
  preSelectedAudience?: PreSelectedAudience;
  /** set the preselected audience */
  setPreSelectedAudience?: (preSelectedAudience: PreSelectedAudience) => void;
  /** clears states on unmount if true */
  clearStatesOnUnmount: boolean;
  /** is the selection disabled? */
  disabled?: boolean;
  /** is the selection disabled? */
  setDisableAudienceCalculation?: Dispatch<SetStateAction<boolean>>;
};

export type AudienceState = {
  /** array of contact ids */
  contacts: Array<string>;
  /** array of upload audience items */
  uploads: Array<AudienceItemType>;
  /** array of group audience items */
  groups: Array<AudienceItemType>;
  /** array of tag audience items */
  tags: Array<AudienceItemType>;
  /** array of contact audience items */
  manuallyAddedContacts: Array<AudienceItemType>;
  /** campaign id and campaign contacts type */
  wholeCampaignTab: { campaign_id?: string; campaign_contacts_type?: string };
};

export type AudienceItemType = {
  /** id of the audience item */
  id: string;
  /** name of the audience item (if present) */
  name?: string;
  /** phone of the audience item (if present) */
  phone?: string;
};

export const AudienceSelection = (props: AudienceSelectionProps) => {
  const {
    selectedAudience,
    setSelectedAudience,
    isCampaignsPage,
    isCampaignsEditPage,
    isContactsPage,
    isGroupsPage,
    isUploadsPage,
    isInbox,
    setQuickCampaignDialogState,
    preSelectedAudience,
    setPreSelectedAudience,
  } = props;

  const campaignsContext = useCampaignsContext();
  const { campaignsState } = campaignsContext;
  const { allSelected } = campaignsState;

  const uploadsContext = useUploads();
  const { searchUploads } = uploadsContext;
  const { uploads } = uploadsContext.uploadsState;

  const groupsContext = useGroups();
  const { searchGroups } = groupsContext;
  const { groups } = groupsContext.groupsState;

  const tagsContext = useTags();
  const { searchTags } = tagsContext;
  const { allTags } = tagsContext.tagsState;

  const contactsContext = useContacts();
  const { allContactsChecked, contacts } = contactsContext.contactState;

  const everyContactSelectedOnContactsPage = isContactsPage && allContactsChecked;

  const [audienceSearch, setAudienceSearch] = useState<
    'contacts' | 'groups' | 'tags' | 'uploads'
  >(CONTACTS);

  const [searchedItems, setSearchedItems] = useState<Array<Contact>>([]);

  const clearAllStates = () => {
    setAudienceSearch(CONTACTS);
    setSearchedItems([]);
    setSelectedAudience({
      contacts: [],
      uploads: [],
      groups: [],
      tags: [],
      manuallyAddedContacts: [],
      wholeCampaignTab: {},
    });
  };

  // clean states on unmount
  useEffect(() => {
    if (props.clearStatesOnUnmount) {
      return () => {
        clearAllStates();
      };
    }
  }, []);

  useEffect(() => {
    // handle the case when all contacts are selected in the campaigns page e.g. all "delivered" contacts
    if (allSelected && isCampaignsPage) {
      setSelectedAudience({
        ...selectedAudience,
        wholeCampaignTab: props.audienceFromCampaign || {},
        contacts: [],
      });
    } else if (!allSelected && isCampaignsPage) {
      setSelectedAudience({
        ...selectedAudience,
        wholeCampaignTab: {},
      });
    }
  }, [allSelected, isCampaignsPage]);

  // search for a value on the backend
  const remoteSearch = async (value: string, request: (value: string) => void) => {
    if (value === '') {
      return [];
    }
    if (value.length > 1) {
      const searchedTags = await request(value);
      return searchedTags;
    }
  };

  // make an API request by search value for contacts
  const onContactsSearch = async (value: string) => {
    // is this a phone number?
    const isPhoneNumber = phoneRegex.containsNumber.test(value);

    if (value === '') {
      return [];
    }

    if (value.length > 1) {
      if (isPhoneNumber) {
        // if value is phone, format it and search for it
        const data = await searchContacts('', phoneFormatting(value));

        const fuse = new Fuse(data, {
          keys: ['name', 'phone'],
        });

        const results = fuse.search(value).map((r) => r.item);

        return results as Array<Contact>;
      } else {
        // if value is name
        const data = await searchContacts(value, '');

        const fuse = new Fuse(data, {
          keys: ['name', 'phone'],
        });

        const results = fuse.search(value).map((r) => r.item);

        return results as Array<Contact>;
      }
    }
  };

  // show the first five items from the selected type on audience search
  const onAudienceSearch = async (value: string) => {
    if (value.length > 1) {
      switch (audienceSearch) {
        case UPLOADS: {
          const searchedUploads: any = await remoteSearch(value, searchUploads);
          if (searchedUploads) {
            setSearchedItems(searchedUploads);
          }
          break;
        }
        case GROUPS: {
          const searchedGroups: any = await remoteSearch(value, searchGroups);
          if (searchedGroups) {
            setSearchedItems(searchedGroups);
          }
          break;
        }
        case TAGS: {
          const searchedTags: any = await remoteSearch(value, searchTags);
          if (searchedTags) {
            setSearchedItems(searchedTags);
          }
          break;
        }
        case CONTACTS: {
          const searchedContacts = await onContactsSearch(value);
          if (searchedContacts) {
            setSearchedItems(searchedContacts);
          }
          break;
        }
        default:
          break;
      }
    } else {
      setSearchedItems([]);
    }
  };

  // add the selected item to the audience if it is not already there or
  // if its already in the preselected audience
  const onSearchedItemClick = (id: string, name: string) => {
    // any id that is not a string e.g. null or undefined
    // prevent adding it to the audience
    if (typeof id !== 'string') {
      return;
    }

    switch (audienceSearch) {
      case UPLOADS:
        if (
          selectedAudience.uploads.map((upload) => upload.id).includes(id) ||
          preSelectedAudience?.upload_tag_ids?.includes(id)
        ) {
          break;
        }
        setSelectedAudience({
          ...selectedAudience,
          uploads: [...selectedAudience.uploads, { id, name }],
        });
        break;
      case GROUPS:
        if (
          selectedAudience.groups.map((group) => group.id).includes(id) ||
          preSelectedAudience?.dynamic_group_ids?.includes(id)
        ) {
          break;
        }
        setSelectedAudience({
          ...selectedAudience,
          groups: [...selectedAudience.groups, { id, name }],
        });
        break;
      case TAGS:
        if (
          selectedAudience.tags.map((tag) => tag.id).includes(id) ||
          preSelectedAudience?.tag_ids?.includes(id)
        ) {
          break;
        }
        setSelectedAudience({
          ...selectedAudience,
          tags: [...selectedAudience.tags, { id, name }],
        });
        break;
      case CONTACTS:
        if (
          selectedAudience.manuallyAddedContacts
            .map((contact) => contact.id)
            .includes(id) ||
          preSelectedAudience?.contacts?.includes(id)
        ) {
          break;
        }
        setSelectedAudience({
          ...selectedAudience,
          manuallyAddedContacts: [
            ...selectedAudience.manuallyAddedContacts,
            { id, name },
          ],
        });
        break;
      default:
        break;
    }
    setSearchedItems([]);
  };

  // remove the selected item from the audience
  const onItemDelete = (deletedItemType: string, itemId: string) => {
    switch (deletedItemType) {
      case CONTACTS:
        if (!itemId) {
          setSelectedAudience({ ...selectedAudience, contacts: [] });
        } else {
          setSelectedAudience({
            ...selectedAudience,
            manuallyAddedContacts: selectedAudience.manuallyAddedContacts.filter(
              (c) => c.id !== itemId
            ),
          });
        }
        break;
      case UPLOADS:
        setSelectedAudience({
          ...selectedAudience,
          uploads: selectedAudience.uploads.filter((u) => u.id !== itemId),
        });
        break;
      case GROUPS:
        setSelectedAudience({
          ...selectedAudience,
          groups: selectedAudience.groups.filter((g) => g.id !== itemId),
        });
        break;
      case TAGS:
        setSelectedAudience({
          ...selectedAudience,
          tags: selectedAudience.tags.filter((t) => t.id !== itemId),
        });
        break;
      case CAMPAIGN_TAB:
        setSelectedAudience({ ...selectedAudience, contacts: [], wholeCampaignTab: {} });
        break;
      default:
        break;
    }
  };

  const onPreselectedItemDelete = (type: string, id?: string) => {
    // if itemId is not a string e.g. null or undefined,
    // then remove it from the selected audience
    // we are trying to avoid e.g tag_ids: [null, 'tag_id']
    if (typeof id !== 'string') {
      setPreSelectedAudience &&
        setPreSelectedAudience({
          ...preSelectedAudience,
          upload_tag_ids: preSelectedAudience?.tag_ids?.map((id) => id),
          tag_ids: preSelectedAudience?.tag_ids?.map((id) => id),
          dynamic_group_ids: preSelectedAudience?.dynamic_group_ids?.map((id) => id),
          contacts: preSelectedAudience?.contacts?.map((id) => id),
        });
      return;
    }

    switch (type) {
      case SELECTED_CONTACTS:
        setPreSelectedAudience &&
          setPreSelectedAudience({
            ...preSelectedAudience,
            contacts: [],
          });
        break;
      case CONTACTS:
        setPreSelectedAudience &&
          setPreSelectedAudience({
            ...preSelectedAudience,
            contacts: preSelectedAudience?.contacts?.filter((u: string) => u !== id),
          });
        break;
      case UPLOADS:
        setPreSelectedAudience &&
          setPreSelectedAudience({
            ...preSelectedAudience,
            upload_tag_ids: preSelectedAudience?.upload_tag_ids?.filter(
              (u: string) => u !== id
            ),
          });
        break;
      case GROUPS:
        setPreSelectedAudience &&
          setPreSelectedAudience({
            ...preSelectedAudience,
            dynamic_group_ids: preSelectedAudience?.dynamic_group_ids?.filter(
              (g: string) => g !== id
            ),
          });
        break;
      case TAGS:
        setPreSelectedAudience &&
          setPreSelectedAudience({
            ...preSelectedAudience,
            tag_ids: preSelectedAudience?.tag_ids?.filter((t: string) => t !== id),
          });
        break;
      case CAMPAIGN_TAB:
        setPreSelectedAudience &&
          setPreSelectedAudience(
            removeEmptyFields({
              ...preSelectedAudience,
              campaign_delivered: [],
              campaign_not_delivered: [],
              campaign_responded: [],
              campaign_not_responded: [],
              campaign_link_clicked: [],
              campaign_link_not_clicked: [],
              campaign_unfulfilled: [],
            })
          );
        break;
      default:
        break;
    }
  };

  // show the first five items depending on the type if user hasn't entered a search value yet
  const getDefaultSearchItems = () => {
    switch (audienceSearch) {
      case UPLOADS:
        return uploads.slice(0, 100);
      case GROUPS:
        return groups.slice(0, 100);
      case TAGS:
        return allTags.slice(0, 100);
      case CONTACTS:
        return contacts.slice(0, 100);
      default:
        return [];
    }
  };

  // adds the pasted upload to the audience
  const addPastedDataToAudience = (upload: Tag) => {
    // updated the selected audience
    setSelectedAudience({
      ...selectedAudience,
      uploads: [...selectedAudience.uploads, upload],
    });
    // if this is a large upload that is processing then lets
    // disable the audience calculation because it will be wrong
    // until the upload is processed
    if (
      props?.setDisableAudienceCalculation &&
      upload &&
      upload?.import_status === 'pending'
    ) {
      props.setDisableAudienceCalculation(true);
    }
  };

  // if we are on the contacts page and all contacts are selected
  // render All Contacts, if not all are selected render Selected contacts,
  // if a whole campaign is selected render e.g Delivered
  const renderInitiallyCheckedContactsInAudience = () => {
    if (
      selectedAudience?.wholeCampaignTab &&
      Object.keys(selectedAudience?.wholeCampaignTab).length === 0
    ) {
      return everyContactSelectedOnContactsPage ? 'All Contacts' : 'Selected Contacts';
    } else {
      return getCampaignsContactTypeString(
        selectedAudience?.wholeCampaignTab?.campaign_contacts_type || ''
      );
    }
  };

  return (
    <SelectedAudienceContainer justify="center" align="start" direction="column">
      <Flex wrap="wrap">
        {/* render initially selected contacts e.g All Contacts, Selected Contacts, Delivered */}
        {(selectedAudience.contacts.length > 0 ||
          (preSelectedAudience?.contacts && preSelectedAudience?.contacts?.length > 10) ||
          Object.keys(selectedAudience?.wholeCampaignTab).length > 0) && (
          <ContactTagLayout css={{ height: 30, ml: 0 }}>
            <HStack gap={1}>
              <CircleIcon color={'black'} />
              <Box css={{ whiteSpace: 'nowrap', cursor: 'default' }}>
                {renderInitiallyCheckedContactsInAudience()}
              </Box>
              <IconButton
                size={0}
                onClick={() => {
                  if (isCampaignsEditPage) {
                    onPreselectedItemDelete(SELECTED_CONTACTS, '');
                  } else {
                    onItemDelete(
                      Object.keys(selectedAudience?.wholeCampaignTab).length === 0
                        ? CONTACTS
                        : CAMPAIGN_TAB,
                      ''
                    );
                  }
                }}
              >
                <HiX size={15} />
              </IconButton>
            </HStack>
          </ContactTagLayout>
        )}
        {/* map through the selectedAudience */}
        {selectedAudience.manuallyAddedContacts
          .filter((contact) => contact)
          .map((contact) => (
            <AudienceItem
              key={`${contact?.id}`}
              item={contact}
              onItemDelete={() => onItemDelete(CONTACTS, contact.id)}
              color="black"
              disabled={props.disabled}
            />
          ))}
        {selectedAudience.groups.map((group) => (
          <AudienceItem
            key={`${group?.id}`}
            item={group}
            onItemDelete={() => onItemDelete(GROUPS, group.id)}
            color="blue"
            disabled={props.disabled}
          />
        ))}
        {selectedAudience.uploads
          .filter((upload) => upload)
          .map((upload) => (
            <AudienceItem
              key={`${upload?.id}`}
              item={upload}
              onItemDelete={() => onItemDelete(UPLOADS, upload.id)}
              color="red"
              disabled={props.disabled}
            />
          ))}
        {selectedAudience.tags.map((tag) => (
          <AudienceItem
            key={`${tag?.id}`}
            item={tag}
            onItemDelete={() => onItemDelete(TAGS, tag.id)}
            color="green"
            disabled={props.disabled}
          />
        ))}
        {preSelectedAudience ? (
          <Flex wrap="wrap">
            {preSelectedAudience?.contacts &&
              preSelectedAudience?.contacts?.length < 10 &&
              preSelectedAudience?.contacts?.length > 0 && (
                <ContactTags
                  contact_ids={preSelectedAudience?.contacts || []}
                  onItemDelete={onPreselectedItemDelete}
                  disabled={props.disabled}
                />
              )}
            {preSelectedAudience.upload_tag_ids && (
              <UploadTags
                upload_ids={preSelectedAudience.upload_tag_ids || []}
                onItemDelete={onPreselectedItemDelete}
                disabled={props.disabled}
              />
            )}{' '}
            {preSelectedAudience.dynamic_group_ids && (
              <GroupTags
                group_ids={preSelectedAudience.dynamic_group_ids || []}
                onItemDelete={onPreselectedItemDelete}
                disabled={props.disabled}
              />
            )}{' '}
            {preSelectedAudience.tag_ids && (
              <Tags
                tag_ids={preSelectedAudience.tag_ids || []}
                onItemDelete={onPreselectedItemDelete}
                disabled={props.disabled}
              />
            )}
            {preSelectedAudience &&
              Object.keys(preSelectedAudience).join('').includes('campaign_') && (
                <ContactTagLayout css={{ height: 30, ml: 0 }}>
                  <HStack gap={1}>
                    <CircleIcon color={'black'} />
                    <Box css={{ whiteSpace: 'nowrap', cursor: 'default' }}>
                      {preSelectedAudience &&
                        getCampaignsContactTypeStringFromKeys(
                          Object.keys(preSelectedAudience).find((key) =>
                            key.startsWith('campaign')
                          ) as string
                        )}
                    </Box>
                    <IconButton
                      size={0}
                      onClick={() => {
                        onPreselectedItemDelete(CAMPAIGN_TAB, '');
                      }}
                    >
                      <HiX size={15} />
                    </IconButton>
                  </HStack>
                </ContactTagLayout>
              )}
          </Flex>
        ) : null}
      </Flex>
      <Flex css={{ position: 'relative', width: '100%', minWidth: '150px' }}>
        <Box css={{ width: '100%' }}>
          <AudienceSearchInput
            defaultPlaceholder={`Search for audience by ${audienceSearch}`}
            isDropdown={true}
            onChange={onAudienceSearch}
            closeOnClick={true}
            onSearchedItemClick={
              onSearchedItemClick as (value: string | number, name: string) => void
            }
            disabled={
              (isContactsPage &&
                !(
                  !everyContactSelectedOnContactsPage ||
                  selectedAudience.contacts.length === 0
                )) ||
              props.disabled
                ? true
                : false
            }
            // if there are not searched items show the default one
            options={
              searchedItems.length > 0
                ? searchedItems.map((searchedItem) => ({
                    type:
                      (searchedItem?.name
                        ? `${searchedItem?.name} ${
                            (searchedItem as Contact)?.phone
                              ? formatPhoneNumber((searchedItem as Contact)?.phone)
                              : ''
                          }`
                        : searchedItem?.phone) || '',
                    value: searchedItem?.id,
                  }))
                : getDefaultSearchItems().map((searchedItem) => ({
                    // @ts-ignore
                    type:
                      (searchedItem?.name
                        ? `${searchedItem?.name} ${
                            (searchedItem as Contact)?.phone
                              ? formatPhoneNumber((searchedItem as Contact)?.phone)
                              : ''
                          }`
                        : (searchedItem as Contact)?.phone) || '',
                    value: searchedItem?.id,
                  }))
            }
          />
        </Box>
        {!props.disabled && (
          <AudienceFilterDropdown
            audienceSearch={audienceSearch}
            setAudienceSearch={setAudienceSearch as Dispatch<SetStateAction<string>>}
            showContacts={
              !everyContactSelectedOnContactsPage ||
              selectedAudience.contacts.length === 0
            }
            isCampaignsPage={!!isCampaignsPage}
            isContactsPage={!!isContactsPage}
            isGroupsPage={!!isGroupsPage}
            isUploadsPage={!!isUploadsPage}
            isInbox={!!isInbox}
            addPastedDataToAudience={addPastedDataToAudience}
            setQuickCampaignDialogState={setQuickCampaignDialogState}
          />
        )}
      </Flex>
    </SelectedAudienceContainer>
  );
};

const SelectedAudienceContainer = styled(Flex, {
  position: 'relative',
  border: '1px solid #D7DBDF',
  borderRadius: '$1',
  px: 8,
});
