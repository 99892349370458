import { debounce, isEmpty, set, unset } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { Virtuoso } from 'react-virtuoso';

import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import { MultiSelect } from '@/shared/components/MultiSelect';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { FilterItem } from '@/shared/types/filter';
import { Link } from '@/shared/types/links';
import { User } from '@/shared/types/users';
import { Box, Button, Flex, HStack, Input, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { useLinks } from '../context/LinksContext';
import { LinkCard, LinkLoadingCard } from './LinkCard';
import { ListEmptyState, SearchedListEmptyState } from './ListEmptyState';

type LinksFilters = {
  /* array of user ids */
  users: number[];
};

const LIMIT = 20;

const getKeyPath = (length: number) => {
  return ['or', ...Array.from({ length }, () => '[0].or')].join('');
};

export const LinksList = () => {
  const history = useHistory();

  const {
    getLinks,
    setCurrentLink,
    setCurrentLinkAnalytics,
    linksState: { links, loading, totalCount },
  } = useLinks();

  const isLargeScreen = useMedia('(min-width: 1600px)');

  const isListComplete = links?.length >= totalCount;

  const {
    userState: { users },
  } = useUsers();

  const [query, setQuery] = useState<{ search: string; filters: LinksFilters }>({
    search: '',
    filters: { users: [] },
  });
  const [offset, setOffset] = useState(0);
  const [isLoadingNext, setIsLoadingNext] = useState(false);

  // get filters query with search and users params
  const filtersQuery = useMemo(() => {
    return [
      {
        resource: 'shareable_link',
        column: 'short_url',
        comparison: 'ilike',
        value: `%${query.search}%`,
        or: [
          {
            resource: 'link_setting',
            column: 'url',
            comparison: 'ilike',
            value: `%${query.search}%`,
          },
        ],
      },
      query.filters.users.length > 0 && {
        ...query.filters.users.reduce((acc, current, index) => {
          const userFilter = {
            resource: 'created_by',
            column: 'id',
            comparison: '==',
            value: current,
            or: [
              {
                resource: 'updated_by',
                column: 'id',
                comparison: '==',
                value: current,
                or: [],
              },
            ],
          } as FilterItem;
          if (isEmpty(acc)) {
            acc = userFilter;
          } else {
            acc = set(acc, getKeyPath(index * 2 - 1), [userFilter]);
          }
          return acc;
        }, {}),
      },
    ]
      .filter((item) => !!item)
      .map((item) => {
        query.filters.users.length &&
          unset(item, getKeyPath(query.filters.users.length * 2 - 1));
        return item as FilterItem;
      });
  }, [query]);

  // handle get links with debounce
  const getLinksDebounced = useCallback(
    debounce(() => {
      getLinks({
        filter: filtersQuery,
        sort: [
          {
            resource: 'link_setting',
            column: 'inserted_at',
            order: 'desc',
          },
        ],
        limit: LIMIT,
        offset: 0,
      });
    }, 300), // 300ms debounce time
    [filtersQuery]
  );

  useEffect(() => {
    setOffset(0);
    getLinksDebounced();
  }, [getLinksDebounced]);

  // fetch next set of campaign templates to add the the list
  const fetchNextSequences = async (offset: number) => {
    setIsLoadingNext(true);
    await getLinks({
      filter: filtersQuery,
      sort: [
        {
          resource: 'link_setting',
          column: 'inserted_at',
          order: 'desc',
        },
      ],
      limit: LIMIT,
      offset,
    });
    setIsLoadingNext(false);
  };

  useEffect(() => {
    if (offset > 0 && !loading) {
      fetchNextSequences(offset);
    }
  }, [offset, loading]);

  const increaseOffset = () => {
    setOffset((prevCoefficient) => prevCoefficient + LIMIT);
  };

  const enabledLoadingNext =
    // If the list is not already complete
    !isListComplete &&
    // Is next sequences list loading
    !isLoadingNext;

  const handleCreateLink = useCallback(async () => {
    // reset the current link state
    setCurrentLink(null);
    setCurrentLinkAnalytics(null);
    // navigate to the link builder
    history.push('/campaigns/links/create/link');
  }, []);

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Campaigns', path: '/campaigns/links' },
        { title: 'Links', path: '/campaigns/links' },
      ]}
      actions={<Button onClick={handleCreateLink}>Create Link</Button>}
    >
      <ListContainer gap={1}>
        <SearchRow css={{ backgroundColor: 'rgb(250, 250, 250)', px: 30, mb: 20 }}>
          <HStack gap={1} css={{ width: '100%', justifyContent: 'space-between' }}>
            <Flex align="center" css={{ width: isLargeScreen ? '100%' : '40%' }}>
              <Input
                value={query?.search}
                placeholder="Search Links"
                onChange={(e) =>
                  setQuery((prev) => ({ ...prev, search: e.target.value }))
                }
                css={{ width: '409px' }}
              />
            </Flex>
            {users.length > 0 && (
              <Box css={{ width: '30%' }}>
                <MultiSelect
                  defaultPlaceholder="Users"
                  defaultSelectedItems={[]}
                  isDropdown={true}
                  options={users.map((user: User) => ({
                    type: user.name || user.email || '',
                    value: user.id,
                  }))}
                  parentSelectedItems={query.filters}
                  setParentSelectedItems={(filters: LinksFilters) =>
                    setQuery((prev) => ({ ...prev, filters }))
                  }
                  isCampaigns={true}
                />
              </Box>
            )}
          </HStack>
        </SearchRow>
        {loading &&
          Array.from({ length: 10 }, (_: never, k: React.Key | null | undefined) => (
            <ItemContainer key={k} data-testid={`link-loading-card-${k}`}>
              <LinkLoadingCard key={k} />
            </ItemContainer>
          ))}
        {!loading && links?.length > 0 && (
          <Virtuoso
            data={links as Link[]}
            atBottomStateChange={(isAtBottom) => {
              if (isAtBottom && enabledLoadingNext) {
                increaseOffset();
              }
            }}
            totalCount={links.length}
            itemContent={(index: number, link: Link) => (
              <ItemContainer key={link.id}>
                <LinkCard key={link.id} link={link} />
              </ItemContainer>
            )}
            components={{
              Footer: () =>
                enabledLoadingNext ? (
                  <ItemContainer>
                    <LinkLoadingCard />
                  </ItemContainer>
                ) : (
                  <Box css={{ pb: 10 }} />
                ),
            }}
          />
        )}
        {/* this box below is specially for empty state layout */}
        {!loading && links?.length === 0 && (
          <Box css={{ p: 30, minHeight: '80%', height: 'fit-content', overflow: 'auto' }}>
            {query.search === '' ? <ListEmptyState /> : <SearchedListEmptyState />}
          </Box>
        )}
      </ListContainer>
    </PageLayout>
  );
};

export const ListContainer = styled(VStack, {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  minHeight: '100%',
  overflow: 'auto',
  maxWidth: '100%',
  pt: 30,
  pb: 0,
});

export const SearchRow = styled(Flex, {
  height: 35,
});

export const ItemContainer = styled(Box, {
  px: 30,
  pb: 10,
});
