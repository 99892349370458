import { FormikValues, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAuth } from '@/auth/context/AuthProvider';
import {
  helpMessages,
  industryList,
  listOfUseCases,
} from '@/settings/organization/trust/campaigns/lists';
import { useTenDlc } from '@/settings/organization/trust/context/TenDlcContext';
import {
  FormFieldWrapper,
  helpers,
  SelectCombobox,
  TextAreaInput,
} from '@/shared/components/forms';
import { Box, Flex } from '@/shared/ui';
import { ComboboxMultiselectItem } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectItem';
import { ComboboxMultiselectTrigger } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectTrigger';
import { ComboboxMultiselectInput } from '@/shared/v2/components/forms/comboboxMultiselect/ComboboxMultiselectInput';

export const StepOne = () => {
  const tenDlcContext = useTenDlc();
  const { tenDlcState, getAllBrands } = tenDlcContext;
  const { brands } = tenDlcState;
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    !id && getAllBrands();
  }, [id]);

  const { values, setValues, setFieldTouched } = useFormikContext<FormikValues>();
  const { organizationName } = useAuth();

  useEffect(() => {
    if (values.industry !== 'none') {
      const preFieldValues = helpMessages(organizationName, values.industry);
      if (preFieldValues) {
        setValues({
          ...values,
          ...preFieldValues,
        });

        Object.keys(preFieldValues).forEach((field) => setFieldTouched(field, true));
      }
    }
  }, [values.industry]);

  return (
    <Flex css={{ width: '100%' }} direction="column">
      <Box>
        {!id && (
          <FormFieldWrapper name="brand" label="Brand">
            <SelectCombobox
              placeholder={helpers.displaySelectedItem}
              isDropdown={true}
              options={brands?.map((brand) => ({
                type: brand.display_name,
                value: brand.id,
              }))}
            />
          </FormFieldWrapper>
        )}
        <FormFieldWrapper name="industry" label="Industry">
          <SelectCombobox
            placeholder={helpers.displaySelectedItem}
            isDropdown={true}
            options={industryList}
          />
        </FormFieldWrapper>
        <FormFieldWrapper name="description" label="Description">
          <TextAreaInput
            name="description"
            placeholder="Write short description of your campaign"
          />
        </FormFieldWrapper>
        <FormFieldWrapper name="use_case" label="Use Case">
          <SelectCombobox
            placeholder={helpers.displaySelectedItem}
            isDropdown={true}
            options={listOfUseCases}
          />
        </FormFieldWrapper>
        <FormFieldWrapper name="sub_use_cases" label="Sub Use Case">
          <ComboboxMultiselectInput
            options={listOfUseCases?.map((useCases) => ({
              label: useCases.type,
              value: useCases.value,
            }))}
            searchLabel="Search use cases"
            Trigger={ComboboxMultiselectTrigger}
            Option={ComboboxMultiselectItem}
            disabled={false}
            selectAll
          />
        </FormFieldWrapper>
      </Box>
    </Flex>
  );
};
