import * as SelectPrimitive from '@radix-ui/react-select';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';

import { Channel, ChannelTypes, ProviderTypes } from '@/shared/types/channels';
import {
  Fieldset,
  Flex,
  HStack,
  Input,
  Label,
  RadioGroup,
  RadioGroupIndicator,
  RadioGroupRadio,
  Select,
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
  VStack,
} from '@/shared/ui';

import { calculateDefaultChannelProviderId } from '../utils';
import { WhatsAppSignup } from './WhatsAppSignup';

export type ProviderTypeID =
  | 'whippy-phone'
  | 'whippy-email'
  | 'custom'
  | 'twilio-sms'
  | 'meta-whatsapp'
  | 'vonage-sms'
  | 'telnyx-sms'
  | 'sinch-sms'
  | 'bandwidth-sms'
  | 'mailgun-email';
type ChannelProvider = {
  id: ProviderTypeID;
  provider: ProviderTypes;
  supports_phone: boolean;
  support_phone_number_purchase: boolean;
  supports_email: boolean;
  supports_whatsapp: boolean;
  supports_custom_auth: boolean;
  label: string;
  provider_auth: Record<string, string>;
};
/*
 * This is used by the Channel Sender component
 * */
export const channel_providers: ChannelProvider[] = [
  {
    id: 'whippy-phone',
    provider: ProviderTypes.TWILIO,
    supports_phone: true,
    support_phone_number_purchase: true,
    supports_email: false,
    supports_whatsapp: false,
    supports_custom_auth: false,
    label: 'Whippy Phone (Voice, SMS, MMS)',
    provider_auth: {},
  },
  {
    id: 'whippy-email',
    provider: ProviderTypes.MAILGUN,
    supports_phone: false,
    support_phone_number_purchase: false,
    supports_email: true,
    supports_whatsapp: false,
    supports_custom_auth: false,
    label: 'Default',
    provider_auth: {},
  },
  {
    id: 'twilio-sms',
    provider: ProviderTypes.TWILIO,
    supports_phone: true,
    support_phone_number_purchase: true,
    supports_email: false,
    supports_whatsapp: true,
    supports_custom_auth: true,
    label: 'Twilio',
    provider_auth: {
      account_sid: '',
      auth_token: '',
    },
  },
  {
    id: 'meta-whatsapp',
    provider: ProviderTypes.META,
    supports_phone: false,
    support_phone_number_purchase: false,
    supports_email: false,
    supports_whatsapp: true,
    supports_custom_auth: false,
    label: 'Meta Whatsapp',
    provider_auth: {},
  },
  {
    id: 'vonage-sms',
    provider: ProviderTypes.VONAGE,
    support_phone_number_purchase: false,
    supports_phone: true,
    supports_email: false,
    supports_whatsapp: false,
    supports_custom_auth: true,
    label: 'Vonage',
    provider_auth: {
      application_id: '',
      private_key: '',
    },
  },
  {
    id: 'telnyx-sms',
    provider: ProviderTypes.TELNYX,
    support_phone_number_purchase: false,
    supports_phone: true,
    supports_email: false,
    supports_whatsapp: false,
    supports_custom_auth: true,
    label: 'Telnyx',
    provider_auth: {
      api_key: '',
      public_key: '',
    },
  },
  {
    id: 'sinch-sms',
    provider: ProviderTypes.SINCH,
    support_phone_number_purchase: false,
    supports_phone: true,
    supports_email: false,
    supports_whatsapp: false,
    supports_custom_auth: true,
    label: 'Sinch',
    provider_auth: {
      app_id: '',
      project_id: '',
      region: '',
      username: '',
      password: '',
    },
  },
  {
    id: 'bandwidth-sms',
    provider: ProviderTypes.BANDWIDTH,
    support_phone_number_purchase: false,
    supports_phone: true,
    supports_email: false,
    supports_whatsapp: false,
    supports_custom_auth: true,
    label: 'Bandwidth',
    provider_auth: {
      username: '',
      user_password: '',
      account_id: '',
      application_id: '',
      sub_account_number: '',
      location_number: '',
      callback_id: '',
      callback_password: '',
    },
  },
  {
    id: 'mailgun-email',
    provider: ProviderTypes.MAILGUN,
    support_phone_number_purchase: false,
    supports_phone: false,
    supports_email: true,
    supports_whatsapp: false,
    supports_custom_auth: true,
    label: 'Custom',
    provider_auth: {
      base_domain: '',
      api_key: '',
      webhook_signing_key: '',
    },
  },
];

/***
 * We use this component to select the communication provider for a channel.
 * when creating a channel in the MultiStepForm.
 */
export const SelectChannelProvider = ({
  channel,
  setChannel,
}: {
  channel: Partial<Channel>;
  setChannel: Dispatch<SetStateAction<Partial<Channel>>>;
}) => {
  const [channelProviderId, setChannelProviderId] = useState<ProviderTypeID>(
    calculateDefaultChannelProviderId(channel?.type)
  );

  // update the provider and/or channelProviderId based on the channel type
  useEffect(() => {
    if (channel.type === ChannelTypes.EMAIL) {
      setChannel((prevChannel) => ({
        ...prevChannel,
        provider: ProviderTypes.MAILGUN,
        channelProviderId: 'whippy-email',
      }));
    }
    // if type is phone, set default provider to whippy-phone
    else if (channel.type === ChannelTypes.PHONE) {
      setChannel((prevChannel) => ({
        ...prevChannel,
        provider: ProviderTypes.TWILIO,
      }));
    }
    // if type is whatsapp, set default provider to whippy-whatsapp
    else if (channel.type === ChannelTypes.WHATSAPP) {
      setChannel((prevChannel) => ({
        ...prevChannel,
        provider: ProviderTypes.TWILIO,
      }));
    }
  }, []);

  // set default provider if channel type is email

  // filter providers based on channel type, only show providers that support the channel type
  const filteredProviders = channel_providers.filter((provider) => {
    if (channel.type === ChannelTypes.PHONE) {
      return provider.supports_phone;
    } else if (channel.type === ChannelTypes.EMAIL) {
      return provider.supports_email;
    } else if (channel.type === ChannelTypes.WHATSAPP) {
      return provider.supports_whatsapp;
    }
    return false;
  });

  // auth type set state default or custom provider auth
  type EmailProviderAuth = 'custom' | 'mailgun-email';
  const [providerAuthType, setProviderAuthType] =
    useState<EmailProviderAuth>('mailgun-email');

  // handle provider auth change for custom providers
  const handleProviderAuthChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setChannel((prevChannel) => ({
      ...prevChannel,
      provider_auth: {
        ...prevChannel.provider_auth,
        [name]: value,
      } as typeof prevChannel.provider_auth,
    }));
  };

  const handleMetaAuth = (metaParams: {
    code: string;
    phone_pin: string;
    phone_number_id: string;
    waba_id: string;
  }) => {
    setChannel((prevChannel) => ({
      ...prevChannel,
      meta_onboarding: metaParams,
    }));
  };

  const selectedProvider = channel_providers.find(
    (provider) => provider.id === channelProviderId
  );

  return (
    <Flex direction="column" css={{ width: '100%' }}>
      <VStack gap="2">
        <Fieldset>
          <Label>Communication Provider</Label>
          <Select
            defaultValue={'whippy-phone'}
            value={channelProviderId || ''}
            onValueChange={(id: string) => {
              const selectedProvider = channel_providers.find(
                (provider) => provider.id === id
              );
              setChannelProviderId(id as ProviderTypeID);
              if (selectedProvider) {
                setChannel((prevChannel) => ({
                  ...prevChannel,
                  provider: selectedProvider.provider,
                  channelProviderId: id as ProviderTypeID,
                }));
              }
            }}
          >
            <SelectTrigger aria-label="action-select-trigger">
              <SelectValue />
              <SelectIcon>
                <HiChevronDown />
              </SelectIcon>
            </SelectTrigger>
            <SelectPrimitive.Portal>
              <SelectContent css={{ zIndex: '9999' }}>
                <SelectScrollUpButton>
                  <HiChevronUp />
                </SelectScrollUpButton>
                <SelectViewport>
                  <SelectGroup>
                    <SelectLabel>Communication Providers</SelectLabel>
                    {filteredProviders.map((provider, index) => (
                      <SelectItem key={index} value={provider.id}>
                        <SelectItemIndicator />
                        <SelectItemText>{provider.label}</SelectItemText>
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectViewport>
              </SelectContent>
            </SelectPrimitive.Portal>
          </Select>
        </Fieldset>

        {selectedProvider?.provider === ProviderTypes.META ? (
          <>
            <WhatsAppSignup onMetaAuth={handleMetaAuth} />
          </>
        ) : (
          <>
            {selectedProvider &&
              selectedProvider.supports_custom_auth &&
              selectedProvider.id != 'mailgun-email' && (
                <Fieldset>
                  <Label>Select Authentication Type</Label>
                  <RadioGroup
                    value={providerAuthType}
                    onValueChange={(value) => {
                      setProviderAuthType(value as EmailProviderAuth);
                      setChannel((prevChannel) => ({
                        ...prevChannel,
                        provider: ProviderTypes.MAILGUN,
                        channelProviderId: value,
                      }));
                    }}
                  >
                    <Flex align="center" css={{ mt: 10 }}>
                      <Flex justify="center" align="center" css={{ mr: 10 }}>
                        <RadioGroupRadio value="mailgun-email">
                          <RadioGroupIndicator />
                        </RadioGroupRadio>
                        <Label css={{ ml: 5, mb: 0 }}>Managed by Whippy</Label>
                      </Flex>
                      <HStack align="center">
                        <RadioGroupRadio value="custom">
                          <RadioGroupIndicator />
                        </RadioGroupRadio>
                        <Label css={{ ml: 5, mb: 0 }}>Custom</Label>
                      </HStack>
                    </Flex>
                  </RadioGroup>
                </Fieldset>
              )}
            {providerAuthType === 'custom' && selectedProvider && (
              <Fieldset>
                <Label css={{ mb: 20 }}>Custom Provider Authentication</Label>
                {Object.entries(selectedProvider.provider_auth).map(([key]) => (
                  <Fieldset key={key}>
                    <Label>
                      {key
                        .replace(/_/g, ' ')
                        .replace(/\b\w/g, (char) => char.toUpperCase())}
                    </Label>
                    <Input
                      name={key}
                      placeholder={key
                        .replace(/_/g, ' ')
                        .replace(/\b\w/g, (char) => char.toUpperCase())}
                      value={
                        (channel.provider_auth as Record<string, string>)?.[key] || ''
                      }
                      onChange={handleProviderAuthChange}
                    />
                  </Fieldset>
                ))}
              </Fieldset>
            )}
          </>
        )}
      </VStack>
    </Flex>
  );
};
