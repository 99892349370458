import React from 'react';
import { HiX } from 'react-icons/hi';

import { useDisclosure } from '@/shared/hooks';
import { RetellProviderSettings } from '@/shared/types/ai/agents';
import {
  Box,
  Button,
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  HStack,
  Text,
} from '@/shared/ui';

import { sounds } from '../utils/sounds';

interface AmbientSoundSelectorProps {
  agentSettings?: RetellProviderSettings;
  onSoundSelect: (soundValue: string | null) => void;
  onSave: () => void;
  disabled?: boolean;
}

export const AmbientSoundSelector = ({
  agentSettings,
  onSoundSelect,
  onSave,
  disabled = false,
}: AmbientSoundSelectorProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSave = () => {
    onSave();
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpen}>
      <DialogTrigger asChild>
        <Button
          css={{ width: '100%', align: 'start', mb: 12 }}
          variant="gray"
          disabled={disabled}
        >
          <HStack>
            <img
              src={
                sounds.find((s) => s.value === agentSettings?.ambient_sound)?.image || ''
              }
              alt={
                sounds.find((s) => s.value === agentSettings?.ambient_sound)?.label ||
                'Default sound'
              }
              style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                objectFit: 'cover',
                display: agentSettings?.ambient_sound ? 'block' : 'none',
              }}
            />
            <Box>
              {agentSettings?.ambient_sound
                ? sounds.find((s) => s.value === agentSettings.ambient_sound)?.label
                : 'No ambient sound'}
            </Box>
          </HStack>
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div" css={{ zIndex: 1000 }}>
          <DialogContent css={{ width: '90vw', height: '80vh', overflow: 'auto' }}>
            <DialogTitle>Select Ambient Sound</DialogTitle>
            <Box
              css={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
                gap: '20px',
                p: 20,
              }}
            >
              <Box
                css={{
                  border: !agentSettings?.ambient_sound
                    ? '2px solid #0077cc'
                    : '1px solid #e6e6e6',
                  backgroundColor: !agentSettings?.ambient_sound
                    ? '#f5f5f5'
                    : 'transparent',
                  borderRadius: '8px',
                  padding: '10px',
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: '#f0f0f0' },
                }}
                onClick={() => onSoundSelect(null)}
              >
                <Text css={{ textAlign: 'center', my: '10px', fontSize: '15px' }}>
                  No Ambient Sound
                </Text>
              </Box>
              {sounds.map((sound) => (
                <Box
                  key={sound.id}
                  css={{
                    border:
                      agentSettings?.ambient_sound === sound.value
                        ? '2px solid #0077cc'
                        : '1px solid #e6e6e6',
                    backgroundColor:
                      agentSettings?.ambient_sound === sound.value
                        ? '#f5f5f5'
                        : 'transparent',
                    borderRadius: '8px',
                    padding: '10px',
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: '#f0f0f0' },
                  }}
                  onClick={() => onSoundSelect(sound.value)}
                >
                  <img
                    src={sound.image}
                    alt={sound.label}
                    style={{
                      display: 'block',
                      margin: '0 auto',
                      width: '80px',
                      height: '80px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                  />
                  <Text css={{ textAlign: 'center', my: '10px', fontSize: '15px' }}>
                    {sound.label}
                  </Text>
                  <audio
                    controls
                    src={sound.audio}
                    style={{ width: '100%', marginTop: '10px' }}
                  >
                    <track default kind="captions" src="" srcLang="en" label="Captions" />
                  </audio>
                </Box>
              ))}
            </Box>
            <HStack
              css={{
                justifyContent: 'flex-end',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                padding: '20px',
                backgroundColor: 'white',
                borderTop: '1px solid #e6e6e6',
              }}
            >
              <Button variant="gray" onClick={onClose}>
                Cancel
              </Button>
              <Button onClick={handleSave} disabled={disabled}>
                Save
              </Button>
            </HStack>
            <DialogCloseIcon size="2" onClick={onClose}>
              <HiX size="15px" />
            </DialogCloseIcon>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};
