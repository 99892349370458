import { PropertyType } from '@/shared/types/data';

export const default_custom_property_object = {
  id: 'unique-id-for-contact',
  label: 'Custom Property',
  key: 'property',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'custom_property-name',
      label: 'Name',
      type: 'text',
      key: 'label',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: '',
      id: 'custom_property-type',
      label: 'Type',
      type: 'combobox',
      key: 'type',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact-tag',
      options: [
        { label: 'Text', value: PropertyType.TEXT },
        { label: 'Number', value: PropertyType.NUMBER },
        { label: 'Float', value: PropertyType.FLOAT },
        { label: 'Boolean', value: PropertyType.BOOLEAN },
        { label: 'Date', value: PropertyType.DATE },
        { label: 'List', value: PropertyType.LIST },
        { label: 'Map', value: PropertyType.MAP },
      ],
    },
    {
      default: 'current-timestamp',
      id: 'custom_property-inserted_at',
      label: 'Inserted At',
      type: 'date',
      key: 'inserted_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: 'current-timestamp',
      id: 'custom_property-updated_at',
      label: 'Updated At',
      type: 'date',
      key: 'updated_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
  ],
};
