export const relativeDateOptions = [
  {
    label: 'Today',
    value: '+0d',
  },
  {
    label: 'Yesterday',
    value: '-1d',
  },
  {
    label: 'Tomorrow',
    value: '+1d',
  },
  {
    label: 'Next Week',
    value: '+1Mon',
  },
  {
    label: 'Next Month',
    value: '+1M D1',
  },
  {
    label: 'Next Year',
    value: '+1y',
  },
  {
    label: 'Last Week',
    value: '-1Mon',
  },
  {
    label: 'Last Month',
    value: '-1M D1',
  },
  {
    label: '3 Months Ago',
    value: '-3M',
  },
  {
    label: '6 Months Ago',
    value: '-6M',
  },
  {
    label: 'Last Year',
    value: '-1y',
  },
  {
    label: 'Specific Date',
    value: 'date',
  },
];

export const relativeBirthDateOptions = [
  {
    label: 'Today',
    value: '+0d',
    birth_date_value: {
      resource: 'contact',
      column: 'birth_date.day',
      comparison: '==',
      value: '+0d',
      cast: { from: 'relative_datetime', to: 'day_number' },
      and: [
        {
          resource: 'contact',
          column: 'birth_date.month',
          comparison: '==',
          value: '+0d',
          cast: { from: 'relative_datetime', to: 'month_number' },
        },
      ],
    },
  },
  {
    label: 'Yesterday',
    value: '-1d',
    birth_date_value: {
      resource: 'contact',
      column: 'birth_date.day',
      comparison: '==',
      value: '-1d',
      cast: { from: 'relative_datetime', to: 'day_number' },
      and: [
        {
          resource: 'contact',
          column: 'birth_date.month',
          comparison: '==',
          value: '-1d',
          cast: { from: 'relative_datetime', to: 'month_number' },
        },
      ],
    },
  },
  {
    label: 'Tomorrow',
    value: '+1d',
    birth_date_value: {
      resource: 'contact',
      column: 'birth_date.day',
      comparison: '==',
      value: '+1d',
      cast: { from: 'relative_datetime', to: 'day_number' },
      and: [
        {
          resource: 'contact',
          column: 'birth_date.month',
          comparison: '==',
          value: '+1d',
          cast: { from: 'relative_datetime', to: 'month_number' },
        },
      ],
    },
  },
];
