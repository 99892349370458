import { Helmet } from 'react-helmet-async';

import ErrorBoundary from '@/shared/components/ErrorBoundary';

import { Integration as IntegrationPage } from './Integration';
import { IntegrationProvider } from './IntegrationProvider';
import { Integrations as IntegrationsList } from './Integrations';

export const Integrations = () => {
  return (
    <ErrorBoundary
      fallback={<div>Something went wrong loading the integrations list.</div>}
    >
      <IntegrationProvider>
        <Helmet>
          <title>Whippy | Integrations</title>
        </Helmet>
        <IntegrationsList />
      </IntegrationProvider>
    </ErrorBoundary>
  );
};

export const Integration = () => {
  return (
    <ErrorBoundary
      fallback={<div>Something went wrong loading the integration details.</div>}
    >
      <IntegrationProvider>
        <Helmet>
          <title>Whippy | Integrations</title>
        </Helmet>
        <IntegrationPage />
      </IntegrationProvider>
    </ErrorBoundary>
  );
};
