import React from 'react';

import ErrorBoundary from '@/shared/components/ErrorBoundary';
import { Box, Text, VStack } from '@/shared/ui';

type SettingsErrorBoundaryProps = {
  feature: string;
  children: React.ReactNode;
};

const ErrorFallback = ({ feature }: { feature: string }) => (
  <Box
    css={{
      p: '$4',
      border: '1px solid $colors$slate6',
      borderRadius: '$md',
      bg: '$slate1',
    }}
  >
    <VStack gap={2}>
      <Text variant="bold">Something went wrong with {feature}</Text>
      <Text>
        The rest of your settings are still available. Please try again later or contact
        support.
      </Text>
    </VStack>
  </Box>
);

export const SettingsErrorBoundary: React.FC<SettingsErrorBoundaryProps> = ({
  feature,
  children,
}) => {
  if (!feature || !children) return null;

  // Use the custom error fallback with the feature name
  return (
    <ErrorBoundary fallback={<ErrorFallback feature={feature} />}>
      {children}
    </ErrorBoundary>
  );
};
