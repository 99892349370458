import { IconButton, Tooltip, TooltipContent, TooltipTrigger } from '@/shared/ui';

type ContactButtonProps = {
  icon?: React.ReactElement;
  text?: string;
  backgroundColor?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};
export const ContactButton = (props: ContactButtonProps) => {
  const sanitizedText = props?.text?.toLowerCase()?.replace(/\s/g, '');
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <IconButton
          onClick={props.onClick}
          size={3}
          variant="outline"
          css={{ width: 45, height: 45 }}
          data-testid={`contact-button-${sanitizedText}`}
        >
          {props.icon}
        </IconButton>
      </TooltipTrigger>
      <TooltipContent>{props.text}</TooltipContent>
    </Tooltip>
  );
};
