/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import { HiChat, HiMail, HiPencilAlt, HiX } from 'react-icons/hi';
import { useHistory, useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';

import { CampaignCardStatisticProps } from '@/pages/campaigns/list/CampaignCardStatistic';
import { Divider } from '@/pages/campaigns/list/styled';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { ToolTipIconButton } from '@/shared/components/attachments/previewer';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { TooltipIconButton } from '@/shared/components/TooltipIconButton';
import { AddTrigger } from '@/shared/components/triggers/AddTrigger';
import { ChannelTypes } from '@/shared/types/channels';
import {
  Sequence,
  SequenceAccessLevel,
  SequenceStep,
  SequenceStepScheduleOptions,
  SequenceStepTrigger,
} from '@/shared/types/sequences';
import { Trigger } from '@/shared/types/triggers';
import { Box, Flex, HStack, Skeleton, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import * as SequenceAPI from '../../../../shared/api/sequences';
import { useSequences } from '../../context/SequenceContext';
import { SequenceTriggers } from '.';
import { StepTriggers, StepTriggersPreview } from './StepTriggers';

type StepCardProps = {
  /* sequence uuid */
  sequence_id: string;
  /* sequence object */
  sequence?: Sequence | null;
  /* sequence step object */
  step: SequenceStep | null;
  /* is under Global Templates Preview */
  disableEdit?: boolean;
  sequenceTrigger?: SequenceTriggers;
};

const getChannelIcon = (step: SequenceStep | null, allChannels: any[]) => {
  if (!step?.channel_id) return <HiChat style={{ color: '#7F7F86' }} />;

  const channel = allChannels.find((channel) => channel.id === step.channel_id);

  switch (channel?.type) {
    case ChannelTypes.EMAIL:
      return <HiMail style={{ color: '#7F7F86' }} />;
    case ChannelTypes.WHATSAPP:
      return <FaWhatsapp style={{ color: '#7F7F86' }} />;
    default:
      return <HiChat style={{ color: '#7F7F86' }} />;
  }
};

export const StepCard = (props: StepCardProps) => {
  const isLargeScreen = useMedia('(min-width: 1490px)');
  const { step, sequence_id, disableEdit, sequenceTrigger } = props;
  const history = useHistory();
  const location = useLocation();

  const sequences = useSequences();
  const { sequencesState, deleteSequenceStep } = sequences;
  const { current } = sequencesState;

  const channelContext = useChannels();
  const { channelsState } = channelContext;
  const { allChannels } = channelsState;

  // is the step scheduled?
  const isScheduled =
    step?.schedule_options.days !== '0' ||
    step?.schedule_options.hours !== '0' ||
    step?.schedule_options.minutes !== '0';

  // add 1 to the position of the step
  const position = step ? step.position! + 1 : 1;

  // sequence step triggers and actions
  const [triggers, setTriggers] = useState<Array<SequenceStepTrigger>>([]);

  useEffect(() => {
    if (sequenceTrigger) {
      setTriggers(sequenceTrigger.triggers as SequenceStepTrigger[]);
    }
  }, [sequenceTrigger]);

  // create a sequence step trigger
  const createTrigger = async (trigger: Trigger) => {
    if (step && step?.id) {
      try {
        const res = await SequenceAPI.createSequenceStepTrigger(
          sequence_id,
          step?.id,
          trigger
        );
        if (res) {
          setTriggers([...triggers, res]);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  // is the sequence access_level global?
  // if so, show the triggers preview, so the user can see what the triggers will look like
  // but not be able to edit them
  const isGlobal = props.sequence?.access_level === SequenceAccessLevel.GLOBAL;

  const handleDelete = () => {
    if (current && current.id) {
      deleteSequenceStep(current.id, step?.id ?? '');
    }
  };

  return (
    <Flex css={{ width: '100%' }}>
      <Flex direction="column" css={{ width: '100%' }}>
        <VStack gap="2">
          <Flex css={{ fontWeight: 500, fontSize: 16, mb: 10 }}>
            <Box css={{ mr: 5 }}>
              Step {position} {step?.title ? `(${step.title})` : null} - Send{' '}
              {isScheduled && 'After'}
            </Box>
            <Flex align="center" css={{ color: '#7F7F86' }}>
              <Box>
                {step && isScheduled
                  ? formattedScheduleTime(step.schedule_options)
                  : 'Immediately'}
              </Box>
            </Flex>
          </Flex>
          <StepBoxContainer align={isLargeScreen ? 'center' : 'start'}>
            <Flex
              direction={isLargeScreen ? 'row' : 'column'}
              align={isLargeScreen ? 'center' : 'start'}
              justify="between"
              gap={4}
              css={{ flex: 1 }}
            >
              <Flex align="center" gap={2}>
                {getChannelIcon(step, allChannels)}
                <StepBody>
                  {step &&
                    (step.email_metadata?.email_body_json
                      ? step.email_metadata.subject
                      : step.body)}
                </StepBody>
              </Flex>
              <Flex align="center">
                {isLargeScreen && <Divider css={{ mx: 24 }} />}
                <Flex justify="between" gap={3} css={{ mr: 24 }}>
                  <StepCardStatistic
                    label="Sent"
                    value={step?.analytics?.sent_messages || '-'}
                    rate={step?.analytics?.sent_messages ? 100 : '-'}
                  />
                  <StepCardStatistic
                    label="Delivered"
                    value={
                      Math.round(
                        Number(step?.analytics?.sent_messages || 0) *
                          (Number(step?.analytics?.delivery_rate || 0) / 100)
                      ) || '-'
                    }
                    rate={step?.analytics?.delivery_rate || '-'}
                  />
                  <StepCardStatistic
                    label="Failed"
                    value={step?.analytics?.failed_deliveries || '-'}
                    rate={
                      Math.round(
                        (Number(step?.analytics?.failed_deliveries || 0) /
                          Number(step?.analytics?.sent_messages || 0)) *
                          100
                      ) || '-'
                    }
                  />
                  <StepCardStatistic
                    label="Responded"
                    value={step?.analytics?.responses || '-'}
                    rate={step?.analytics?.response_rate || '-'}
                  />
                  <StepCardStatistic
                    label="Clicked"
                    value={step?.analytics?.link_clicks || '-'}
                    rate={step?.analytics?.link_clicks_rate || '-'}
                  />
                  <StepCardStatistic
                    label="Un-subscribed"
                    value={step?.analytics?.unsubscribes || '-'}
                    rate={step?.analytics?.unsubscribe_rate || '-'}
                  />
                </Flex>
              </Flex>
            </Flex>
            {!disableEdit && (
              <HStack gap="2" align="center">
                <ConfirmationDialog
                  title="Delete Sequence Step"
                  description="You can only delete steps when there are no active contacts in the sequence. Are you sure you want to delete this sequence step?"
                  onConfirm={handleDelete}
                  confirmButtonTitle="Yes, Delete Sequence Step"
                  testID="delete-sequence-step-button"
                >
                  <TooltipIconButton
                    size={2}
                    text="Delete Step"
                    icon={<HiX size="22px" style={{ color: '#7F7F86' }} />}
                  />
                </ConfirmationDialog>
                <ToolTipIconButton
                  onClick={() => history.push(`${location.pathname}/${step?.id}`)}
                  description="Edit Step"
                  size={2}
                  icon={<HiPencilAlt size="22px" style={{ color: '#7F7F86' }} />}
                />
              </HStack>
            )}
          </StepBoxContainer>
          {!disableEdit && (
            <Box>
              <AddTrigger
                addTrigger={createTrigger}
                disabled={false}
                variant="gray"
                sequenceType={current?.type}
                isSequence
              />
            </Box>
          )}
          <Box>
            {sequence_id && step && step.id && !isGlobal && (
              <StepTriggers
                sequence_id={sequence_id}
                step_id={step.id}
                triggers={triggers || []}
                setTriggers={setTriggers}
              />
            )}
            {isGlobal && sequence_id && step && step.id && (
              <StepTriggersPreview
                sequence_id={sequence_id}
                step_id={step.id}
                triggers={triggers || []}
                setTriggers={setTriggers}
              />
            )}
          </Box>
        </VStack>
      </Flex>
    </Flex>
  );
};

export const StepLoadingCard = () => {
  return (
    <Flex css={{ width: '100%' }}>
      <Flex direction="column" css={{ width: '100%' }}>
        <Flex css={{ fontWeight: 500, fontSize: 16, mb: 10 }}>
          <Skeleton css={{ width: 100, height: 20, mt: 0, mb: 0 }} />
        </Flex>
        <StepBoxContainer align="center" justify="between">
          <Flex gap={2}>
            <Skeleton css={{ width: 200, height: 20, mt: 0, mb: 0 }} />
          </Flex>
          <HStack gap="2" align="center">
            <Skeleton
              css={{
                width: 30,
                height: 30,
                mt: 0,
                mb: 0,
              }}
            />
            <Skeleton
              css={{
                width: 30,
                height: 30,
                mt: 0,
                mb: 0,
              }}
            />
          </HStack>
        </StepBoxContainer>
      </Flex>
    </Flex>
  );
};

export const SequenceChatIcon = () => {
  return (
    <ChatIconContainer align="center" justify="center" css={{ mr: 10 }}>
      <HiChat style={{ color: '#FFFFFF' }} />
      <VerticalLine />
    </ChatIconContainer>
  );
};

// show the time offset from the previous step
const formattedScheduleTime = (schedule: SequenceStepScheduleOptions) => {
  // Get the days, hours, and minutes from the step object
  const days = schedule.days;
  const hours = schedule.hours;
  const minutes = schedule.minutes;

  // Format the time to show the days, hours, and minutes
  const daysString = days === '0' || days === '' ? '' : `${days} day`;
  const hoursString = hours === '0' || hours === '' ? '' : `${hours} hour`;
  const minutesString = minutes === '0' || minutes === '' ? '' : `${minutes}m`;

  const mergedStrings = [daysString, hoursString, minutesString].join(' ');

  return mergedStrings;
};

const StepBoxContainer = styled(Flex, {
  backgroundColor: '#FFFFFF',
  border: '1px solid #DFE3EB',
  borderRadius: 8,
  boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.06)',
  padding: '20px 24px',
});

const StepBody = styled(Box, {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '500px',
});

const ChatIconContainer = styled(Flex, {
  backgroundColor: '#6A74CF',
  borderRadius: '50%',
  width: 28,
  height: 28,
});

const VerticalLine = styled(Box, {
  position: 'absolute',
  borderLeft: '1px solid #DFE3EB',
  height: 90,
  mt: 130,
});

const StepCardStatistic = (props: CampaignCardStatisticProps) => {
  const { label, value, rate } = props;

  const [showRate, setShowRate] = useState(false);

  const onMouseEnter = () => {
    setShowRate(true);
  };

  const onMouseLeave = () => {
    setShowRate(false);
  };

  return (
    <Flex
      direction="column"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      css={{ minWidth: 85 }}
    >
      <Flex css={{ fontSize: 16, fontColor: '#2B2A34', fontWeight: 600 }}>
        {showRate && typeof value === 'number' && value > 0
          ? `${Math.round(rate as number)}%`
          : value}
      </Flex>
      <Flex css={{ fontSize: 12, fontColor: '#7F7F86', mt: 4 }}>{label}</Flex>
    </Flex>
  );
};
