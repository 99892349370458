import React, { useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';

import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuItemWarning,
  DropdownMenuTrigger,
  Flex,
  Label,
} from '@/shared/ui';

import { BulkAction } from '../FiltersTable';

type BulkActionDropdownProps = {
  bulkActions: BulkAction[];
  handleClick: (bulkAction: BulkAction) => void;
};

export const BulkActionDropdown = ({
  handleClick,
  bulkActions,
}: BulkActionDropdownProps) => {
  // if we have no bulk actions don't display a dropdown
  const [isChevronDown, setChevronDown] = useState(false);

  if (bulkActions.length == 0) return <></>;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          onMouseOver={() => setChevronDown(false)}
          onMouseOut={() => setChevronDown(true)}
          size="2"
          css={{
            textAlign: 'start',
            color: '#00259ECB',
            paddingRight: '8px',
            paddingLeft: '8px',
            borderRadius: '4px',
            background: 'white',
            backgroundColor: isChevronDown ? '#0144FF0F' : 'white',
            boxShadow: 'none',
            '&:focus': {
              boxShadow: 'none',
            },
            '&:hover': {
              backgroundColor: '#0144FF0F',
              boxShadow: 'none',
            },
            '&:active': {
              backgroundColor: '#0144FF0F',
              boxShadow: 'none',
            },
          }}
        >
          <Flex align="center" css={{ gap: '8px', cursor: 'pointer' }}>
            <Label css={{ padding: '0px', margin: '0px', cursor: 'pointer' }}>More</Label>
            {isChevronDown ? <HiChevronDown /> : <HiChevronUp />}
          </Flex>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent css={{ zIndex: 1 }}>
        {bulkActions?.map((bulkAction) => (
          <>
            {bulkAction.type == 'destructive' ? (
              <DropdownMenuItemWarning
                key={bulkAction.label}
                onClick={() => handleClick(bulkAction)}
              >
                {bulkAction.label}
              </DropdownMenuItemWarning>
            ) : (
              <DropdownMenuItem
                key={bulkAction.label}
                onClick={() => handleClick(bulkAction)}
              >
                {bulkAction.label}
              </DropdownMenuItem>
            )}
          </>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
