import React from 'react';

import { PageLayout } from '@/shared/layouts/PageLayout';
import { Flex } from '@/shared/ui';

export const AITemplates = () => {
  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Agents', path: '/agents' },
        { title: 'Templates', path: '/agents/templates' },
      ]}
    >
      <Flex direction="column" css={{ flex: '1 1 auto', p: 30 }}></Flex>
    </PageLayout>
  );
};
