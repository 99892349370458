import { FilterItem, FilterType } from '@/shared/types/filter';

import { formatValue } from './formatValue';
import { relativeBirthDateOptions } from './relativeDateOptions';

export const deepCopyObject = (obj: Record<string, any>) =>
  JSON.parse(JSON.stringify(obj));

export const cleanFilters = (filters: FilterType[] | FilterItem[]) => {
  if (!Array.isArray(filters)) {
    console.error('Invalid input to cleanFilters:', filters);
    return []; // Return an empty array or handle as appropriate
  }

  // Function to check if an object is empty
  const isEmpty = (obj: Record<string, any>) =>
    Object.keys(obj).length === 0 && obj.constructor === Object;

  // Recursive function to clean each filter object
  const clean = (obj: Record<string, any>) => {
    let copy = deepCopyObject(obj); // Create a deep copy of the object

    // Format value and comparison if type is 'text'
    if (copy.type === 'text') {
      const { comparison, value } = formatValue(copy.comparison, copy.value);
      copy.comparison = comparison;
      copy.value = value;
    }

    if (copy.comparison === 'null' || copy.comparison === '!null') {
      copy.comparison = copy.comparison === 'null' ? '==' : '!=';
      copy.value = null;
    }

    if (
      copy.column === 'birth_date' &&
      copy.comparison !== 'null' &&
      copy.comparison !== '!null' &&
      copy.value
    ) {
      const relativeBirthDate = {
        ...relativeBirthDateOptions.find((option) => option.value === copy.value)
          ?.birth_date_value,
      };
      if (relativeBirthDate && copy.and.length) {
        relativeBirthDate.and = [
          ...(relativeBirthDate.and ?? []),
          ...copy.and.filter(
            (filter: Record<string, any>) =>
              filter.resource &&
              filter.column &&
              filter.comparison &&
              filter.value !== undefined &&
              filter.value !== ''
          ),
        ];
      }
      copy = { ...relativeBirthDate };
    }

    // Remove 'id' and type key
    delete copy.id;
    delete copy.type;

    // Recursively clean and/or/on arrays if they exist and are not undefined or null
    ['and', 'or', 'on', 'cast'].forEach((key) => {
      if (copy[key] && Array.isArray(copy[key])) {
        copy[key] = copy[key]
          .map(clean)
          .filter((item: Record<string, any>) => !isEmpty(item));
        if (copy[key].length === 0) {
          delete copy[key];
        }
      } else if (copy[key] === null) {
        delete copy[key];
      }
    });

    return copy;
  };

  // Apply the cleaning process to each filter in the array
  return filters.map(clean);
};
