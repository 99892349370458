/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { VirtuosoHandle } from 'react-virtuoso';

import { compareFiltersList } from '@/pages/campaigns/editor/utils';
import { QuickCampaignDialog } from '@/pages/campaigns/v2/quick';
import { createQuickCampaign } from '@/pages/campaigns/v2/quick/api';
import { useGroups } from '@/pages/contacts/groups/context/GroupContext';
import { CreateUpload } from '@/pages/contacts/uploads/CreateUpload';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import {
  initialCustomDataState,
  useCustomData,
} from '@/pages/settings/organization/data/context/CustomDataContext';
import { useSettings } from '@/pages/settings/organization/general/context/SettingsContext';
import { OptOutOptions } from '@/pages/settings/organization/general/context/types';
import { ConfirmationDialogDescription } from '@/pages/settings/organization/users/UsersTable';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { DownloadContacts, getContactList } from '@/shared/components/DownloadContacts';
import { Attachments } from '@/shared/components/editor/v2/constants';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { BulkTagModal } from '@/shared/components/filters/bulkAction/modals/BulkTagModal';
import { RemoveFromSequenceModal } from '@/shared/components/filters/bulkAction/modals/RemoveFromSequenceModal';
import { UnsubscribeContactsFromCampaignModal } from '@/shared/components/filters/bulkAction/modals/UnsubscribeContactsFromCampaignModal';
import { BulkAction, FilteredTable } from '@/shared/components/filters/FiltersTable';
import { prepareFilter } from '@/shared/components/filters/utils';
import { AddToSequenceDialog } from '@/shared/components/modals/AddToSequenceDialog/AddToSequenceDialog';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { ScheduleOptions } from '@/shared/types/campaigns';
import { Channel } from '@/shared/types/channels';
import { SearchFilters } from '@/shared/types/contacts';
import {
  Group,
  SegmentV1Filters,
  SegmentV2Filters,
} from '@/shared/types/contacts/groups';
import { FilterType, Sort } from '@/shared/types/filter';
import { TagBulkActionType } from '@/shared/types/tags';
import {
  Box,
  Button,
  DropdownMenuItem,
  DropdownMenuItemWarning,
  HStack,
} from '@/shared/ui';
import { TableActionMenu } from '@/shared/v2/components/table/TableActionMenu';

import { areFiltersValid } from '../../../shared/components/filterBuilder/utils/areValidFilters';
import { cleanFilters } from '../../../shared/components/filterBuilder/utils/cleanFilters';
import { rebuildFilters } from '../../../shared/components/filterBuilder/utils/rebuildFilters';
import { ITEMS_COUNT } from '../contacts';
import { CreateContact } from '../contacts/CreateContact';
import { initialState, useData } from '../context/DataContext';
import { applySort } from '../utils/applySort';
import { ContactsTableColumns } from '../utils/ContactsTable';
import {
  defaultSort,
  handleFilterChange,
  handleInfiniteScroll,
  handleQuickSearch,
  handleSortChange,
} from '../utils/filterActions';
import { sortConfig } from '../utils/filterConfig';
import { prepareFilters } from '../utils/prepareFilters';
import { EditAction } from './EditAction';
import { RenameDialog } from './RenameDialog';

export const Segment = (): JSX.Element => {
  const params = useParams<{ id: string }>();
  const history = useHistory();

  const contacts = useData();

  const { updateContactFilters, createContact } = contacts;
  const loading = contacts.dataState.loading || false;
  const loadingMore = contacts.dataState.loadingMore || false;

  const { settingsState } = useSettings();
  const { settings } = settingsState;

  const data = useMemo(() => {
    const sortField = contacts.dataState.contactsFilters.sort[0]?.column || 'updated_at';
    const sortOrder = contacts.dataState.contactsFilters.sort[0]?.order || 'desc';
    return applySort(contacts.dataState.contacts, sortField, sortOrder);
  }, [contacts.dataState.contacts]);

  const [quickSearchValue, setQuickSearchValue] = useState<string>('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);
  // the state needed for Quick Campaign
  const location = useChannels();
  const { channelsState } = location;
  const { channels } = channelsState;
  const [currentLocation, setCurrentLocation] = useState<Channel | null>(null);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedContactIds, setSelectedContactIds] = useState([] as string[]);
  const [totalContactsSelected, setTotalContactsSelected] = useState(0);
  const [isQuickCampaignOpen, setQuickCampaignOpen] = useState(false);
  const [isAddToSequenceModalOpen, setAddToSequenceModalOpen] = useState(false);
  const [isBulkTagModalOpen, setBulkTagModalOpen] = useState(false);
  const [isRemoveFromSequenceModalOpen, setRemoveFromSequenceModalOpen] = useState(false);
  const [isUnsubscribeContactsModalOpen, setUnsubscribeContactsModalOpen] =
    useState(false);

  const [downloading, setDownloading] = useState(false);
  const [tagModalAction, setTagModalAction] = useState<TagBulkActionType>('unassign');

  const custom = useCustomData();
  const { getCustomObjects, customDataState } = custom;
  const { customObjects } = customDataState;

  useEffect(() => {
    const fetchCustomObjects = async () => {
      await getCustomObjects({
        ...initialCustomDataState.filterParams,
        limit: 100,
        offset: 0,
      });
    };

    if (customObjects.length === 0) fetchCustomObjects();

    // on unmount set the current group to null
    return () => {
      setCurrentGroup(null);
    };
  }, []);

  const segments = useGroups();
  const { getGroup, updateGroup, deleteGroup, setCurrentGroup, groupsState } = segments;
  const { current } = groupsState;

  const getSegment = async () => {
    try {
      const segment = await getGroup(params.id);
      if (segment?.filters_version === 'v2' && 'filter' in segment.filters) {
        const filters = rebuildFilters(customObjects, segment.filters.filter || []);
        setActiveFilters(filters ?? []);
        updateContactFilters({
          ...initialState.contactsFilters,
          filter: cleanFilters(filters),
          sort: defaultSort,
        });
      }
    } catch (error) {
      history.push('/data/segments');
    }
  };

  useEffect(() => {
    // Reset filters and contacts data when navigating to a different segment
    setActiveFilters([]);
    // Then, fetch the new segment data
    if (!current || current.id !== params.id) {
      getSegment();
    } else {
      if (current?.filters_version === 'v2' && 'filter' in current.filters) {
        const filters = rebuildFilters(customObjects, current.filters.filter || []);
        setActiveFilters(filters ?? []);
      }
    }
  }, [params.id, current]);

  const debouncedUpdate = useCallback(
    debounce((props: SearchFilters) => updateContactFilters(props), 1000),
    []
  );

  const tableRef = useRef<VirtuosoHandle>(null);
  const columns = ContactsTableColumns();

  const handleUpdateSegmentFilters = (segment: Group) => {
    try {
      const new_filters = cleanFilters([
        ...contacts.dataState.contactsFilters.filter,
        ...(contacts.dataState.contactsFilters.defaultFilters ?? []),
      ]);
      segment.filters = {
        filter: new_filters,
      };
      updateGroup(segment);
    } catch (error) {
      console.error(error);
    }
  };

  const areFiltersEqual = (filtersA: FilterType[], filtersB: FilterType[]): boolean => {
    if (filtersA.length !== filtersB.length) return false;
    for (let i = 0; i < filtersA.length; i++) {
      if (JSON.stringify(filtersA[i]) !== JSON.stringify(filtersB[i])) return false;
    }
    return true;
  };

  function handleConfirm(
    message: string,
    attachments: Attachments,
    location: Channel,
    scheduleOptions: ScheduleOptions | null
  ) {
    if (!current || !current.id) return;
    const filter = prepareSegmentFilter({
      segment: current,
      originalSearchFilter: contacts.dataState.contactsFilters,
      selectedContactIds,
      isAllSelected,
    });
    createQuickCampaign({
      message,
      attachments,
      location,
      scheduleOptions,
      includedAudienceFilter: filter.filter,
    });
    setQuickCampaignOpen(false);
  }

  const bulkActions: BulkAction[] = [
    {
      label: 'Add to Sequence',
      action: 'addToSequenceV2',
      title: 'Add to Sequence',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to add **${selected} contact(s)** to an existing sequence?`,
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setAddToSequenceModalOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setAddToSequenceModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(contacts.dataState.totalCount);
      },
    },
    {
      action: 'createQuickCampaignV2',
      label: 'Create Campaign',
      title: 'Create Quick Campaign',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to add **${selected} contact(s)** to a Quick Campaign?`,
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setQuickCampaignOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setQuickCampaignOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(contacts.dataState.totalCount);
      },
    },
    {
      label: 'Assign Tags',
      action: 'assignTagsV2',
      title: 'Assign Tags',
      getConfirmationMessage: (selected: number) => {
        setTagModalAction('assign');
        return `Are you sure you want to assign tags from **${selected} contact(s)**`;
      },
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setBulkTagModalOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setBulkTagModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(contacts.dataState.totalCount);
      },
    },
    {
      label: 'Overwrite Tags',
      action: 'overwriteTagsV2',
      title: 'Overwrite Tags',
      type: 'destructive',
      getConfirmationMessage: (selected: number) => {
        setTagModalAction('assign.overwrite');
        return `Are you sure you want to assign tags from **${selected} contact(s)**`;
      },
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setBulkTagModalOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setBulkTagModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(contacts.dataState.totalCount);
      },
    },
    {
      label: 'Un-assign Tags',
      action: 'unassignTagsV2',
      title: 'Un-assign Tags',
      type: 'destructive',
      getConfirmationMessage: (selected: number) => {
        setTagModalAction('unassign');
        return `Are you sure you want to unassign tags from **${selected} contact(s)**`;
      },
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setBulkTagModalOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setBulkTagModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(contacts.dataState.totalCount);
      },
    },
    {
      label: 'Remove from Sequence',
      action: 'removeFromSequenceV2',
      title: 'Remove from Sequence',
      type: 'destructive',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to remove **${selected} contact(s)** from an existing sequence`,
      onManualSelectedSubmit: (selectedContactIds: string[]) => {
        setRemoveFromSequenceModalOpen(true);
        setSelectedContactIds(selectedContactIds);
        setIsAllSelected(false);
        setTotalContactsSelected(selectedContactIds.length);
      },
      onAllSelectedSubmit: () => {
        setRemoveFromSequenceModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(contacts.dataState.totalCount);
      },
    },
    {
      action: 'unsubscribeFromCampaignV2',
      label: 'Un-subscribe',
      title: 'Un-subscribe Contacts from Campaign',
      type: 'destructive',
      getConfirmationMessage: (selected: number) =>
        `Are you sure you want to unsubscribe **${selected} contact(s)** from an existing Campaign?`,
      onAllSelectedSubmit: () => {
        if (settings.contact_opt_out_default == OptOutOptions.LOCATION)
          setUnsubscribeContactsModalOpen(true);
        setIsAllSelected(true);
        setTotalContactsSelected(contacts.dataState.totalCount);
      },
      onManualSelectedSubmit(selectedContactIds: string[]) {
        if (settings.contact_opt_out_default == OptOutOptions.LOCATION)
          setUnsubscribeContactsModalOpen(true);
        setIsAllSelected(false);
        setSelectedContactIds(selectedContactIds);
        setTotalContactsSelected(selectedContactIds.length);
      },
    },
  ];

  const getContacts = async (isDownloadAll: boolean) => {
    const params = isDownloadAll
      ? prepareFilters({
          ...initialState.contactsFilters,
          defaultFilters: contacts.dataState.contactsFilters.defaultFilters,
          sort: defaultSort,
        })
      : prepareFilters(contacts.dataState.contactsFilters);

    return getContactList(params.filter, params.sort, ITEMS_COUNT);
  };

  return (
    <>
      <PageLayout
        breadcrumbs={[
          { title: 'Data', path: '/data/contacts' },
          { title: 'Segments', path: '/data/segments' },
          { title: `${current?.name || 'Segment'}`, path: `/data/segments/${params.id}` },
        ]}
        actions={
          <HStack>
            <TableActionMenu width={200}>
              <>
                {current && current.id && (
                  <>
                    <DownloadContacts
                      isSubmenu={true}
                      getData={getContacts}
                      setDownloading={setDownloading}
                      fileName="contacts"
                      title={downloading ? 'Downloading Data...' : 'Download CSV'}
                    />
                    <RenameDialog
                      title="Rename Segment"
                      description="Rename Segment"
                      value={current}
                      action={updateGroup}
                    />
                    <EditAction segment={current} updateSegment={updateGroup}>
                      <DropdownMenuItem
                        data-testid="edit-action-option"
                        onClick={(e) => e.preventDefault()}
                      >
                        Edit Action
                      </DropdownMenuItem>
                    </EditAction>
                  </>
                )}
                <CreateUpload navigateTo="/data/lists/">
                  <DropdownMenuItem
                    data-testid="create-contacts-list-option"
                    onClick={(e) => e.preventDefault()}
                  >
                    Create Contacts List
                  </DropdownMenuItem>
                </CreateUpload>
                <CreateContact handleCreateContact={createContact}>
                  <DropdownMenuItem
                    data-testid="add-contact-option"
                    onClick={(e) => e.preventDefault()}
                  >
                    Add Contact
                  </DropdownMenuItem>
                </CreateContact>
                {current && current.id && (
                  <ConfirmationDialog
                    width="432px"
                    title="Delete this segment?"
                    description={
                      <ConfirmationDialogDescription
                        value={current.name}
                        description="segment will be permanently deleted."
                      />
                    }
                    onConfirm={() => deleteGroup(current.id)}
                    confirmButtonTitle="Confirm"
                    cancelButtonTitle="Cancel"
                    confirmButtonVariant="redBackground"
                    cancelButtonVariant="grayBackground"
                  >
                    <DropdownMenuItemWarning
                      data-testid="delete-segment-option"
                      onClick={(e) => e.preventDefault()}
                    >
                      Delete Segment
                    </DropdownMenuItemWarning>
                  </ConfirmationDialog>
                )}
              </>
            </TableActionMenu>
          </HStack>
        }
      >
        <Box css={{ backgroundColor: 'white', height: '100%', overflowY: 'hidden' }}>
          <Box css={{ padding: 24 }}>
            <CombinedFilters
              quickSearchPlaceholder="Search Contacts"
              quickSearchValue={quickSearchValue}
              setQuickSearchValue={(value: string) => {
                setQuickSearchValue(value);
                handleQuickSearch(
                  debouncedUpdate,
                  contacts.dataState.contactsFilters,
                  value
                );
              }}
              customObjects={customObjects}
              activeFilters={activeFilters}
              setFilters={(value: Array<FilterType>) => {
                setActiveFilters(value);
                handleFilterChange(
                  debouncedUpdate,
                  contacts.dataState.contactsFilters,
                  value
                );
              }}
              sortConfig={sortConfig}
              activeSort={contacts.dataState.contactsFilters.sort}
              defaultIsQuickFilter={false}
              onSortUpdate={(value: Array<Sort>) =>
                handleSortChange(
                  updateContactFilters,
                  contacts.dataState.contactsFilters,
                  value
                )
              }
            >
              <Box>
                <Button
                  css={{ width: 140 }}
                  variant="gray"
                  size="2"
                  onClick={() => handleUpdateSegmentFilters(current as Group)}
                  disabled={
                    activeFilters.length === 0 ||
                    !areFiltersValid(activeFilters) ||
                    cleanFilters(activeFilters).length === 0 ||
                    areFiltersEqual(
                      cleanFilters(activeFilters),
                      (current?.filters as SegmentV2Filters)?.filter || []
                    )
                  }
                >
                  Update Segment
                </Button>
              </Box>
            </CombinedFilters>
          </Box>
          <Box>
            <FilteredTable
              columns={columns}
              bulkActions={maybeGetBulkActions(current, bulkActions)}
              data={data}
              isLoading={loading}
              totalCount={contacts.dataState.totalCount}
              onEndReached={() =>
                handleInfiniteScroll(
                  updateContactFilters,
                  contacts.dataState.contactsFilters,
                  data,
                  contacts.dataState.totalCount,
                  loadingMore
                )
              }
              emptyStateElement={<Box>No records found</Box>}
              tableRef={tableRef}
              showTotalCount
            />
          </Box>
        </Box>
      </PageLayout>
      {current && current.id && (
        <div>
          {isQuickCampaignOpen && (
            <QuickCampaignDialog
              type="sms"
              currentLocation={currentLocation}
              setLocation={setCurrentLocation}
              locations={channels}
              isOpen={isQuickCampaignOpen}
              showTimeZonePicker={false}
              setIsOpen={setQuickCampaignOpen}
              handleConfirm={handleConfirm}
              totalContacts={totalContactsSelected}
            />
          )}
          <AddToSequenceDialog
            isOpen={isAddToSequenceModalOpen}
            showTimeZonePicker={false}
            locations={channels}
            totalContacts={totalContactsSelected}
            setIsOpen={setAddToSequenceModalOpen}
            filter={prepareSegmentFilter({
              segment: current,
              originalSearchFilter: contacts.dataState.contactsFilters,
              selectedContactIds,
              isAllSelected,
            })}
          />
          <BulkTagModal
            action={tagModalAction}
            open={isBulkTagModalOpen}
            totalContacts={totalContactsSelected}
            onOpenChange={setBulkTagModalOpen}
            filter={
              prepareSegmentFilter({
                segment: current,
                originalSearchFilter: contacts.dataState.contactsFilters,
                selectedContactIds,
                isAllSelected,
              }).filter ?? []
            }
          />
          <RemoveFromSequenceModal
            open={isRemoveFromSequenceModalOpen}
            totalContacts={totalContactsSelected}
            onOpenChange={setRemoveFromSequenceModalOpen}
            filter={
              prepareSegmentFilter({
                segment: current,
                originalSearchFilter: contacts.dataState.contactsFilters,
                selectedContactIds,
                isAllSelected,
              }).filter ?? []
            }
          />
          <UnsubscribeContactsFromCampaignModal
            onOpenChange={setUnsubscribeContactsModalOpen}
            open={isUnsubscribeContactsModalOpen}
            totalContacts={totalContactsSelected}
            filter={
              prepareSegmentFilter({
                segment: current,
                originalSearchFilter: contacts.dataState.contactsFilters,
                selectedContactIds,
                isAllSelected,
              }).filter ?? []
            }
          />
        </div>
      )}
    </>
  );
};

function maybeGetBulkActions(segment: Group | null, bulkActions: BulkAction[]) {
  if (segment && segment.filters_version === 'v2' && 'filter' in segment.filters) {
    return bulkActions;
  }
  return [];
}

type PrepareSegmentFilterOptions = {
  segment: Group;
  originalSearchFilter: SearchFilters;
  selectedContactIds: string[];
  isAllSelected: boolean;
};
/*
 * When working with Segments and the V2 audience, we're able to pass the filter to
 * just include the segment's id. This makes it easier to handle with the Campaign Audience
 * */
function prepareSegmentFilter(prepareSegmentFilterOptions: PrepareSegmentFilterOptions) {
  const { segment, originalSearchFilter, selectedContactIds, isAllSelected } =
    prepareSegmentFilterOptions;
  let searchFilter = originalSearchFilter;
  // if the there is no additional filters, then we can replace the full filter with just the segment id.
  // In the case there are more filters, we'll leave things as is
  if (
    isV2Segment(segment, segment.filters) &&
    compareFiltersList(originalSearchFilter.filter, segment.filters.filter)
  ) {
    searchFilter = {
      ...originalSearchFilter,
      filter: [
        {
          column: 'id',
          resource: 'segment',
          comparison: '==',
          value: segment.id,
        },
      ],
    };
  }
  const filterItems = prepareFilter(
    searchFilter,
    selectedContactIds,
    isAllSelected,
    'contact'
  );
  return { filter: filterItems };
}

function isV2Segment(
  segment: Group,
  filters: SegmentV1Filters | SegmentV2Filters
): filters is SegmentV2Filters {
  return segment.filters_version == 'v2';
}
