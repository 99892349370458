import React from 'react';

import { CheckboxFormFieldWrapper, CheckboxInput } from '@/shared/components/forms';
import { Card, Flex } from '@/shared/ui';
import { styled } from '@/stitches.config';

export const features = [
  {
    label: 'Affiliate Marketing',
    name: 'affiliate_marketing',
    tooltip: 'Is the message controlled by affiliate marketing other than the brand?',
  },
  {
    label: 'Number Pool',
    name: 'number_pool',
    tooltip: 'Does campaign utilize pool of phone numbers?',
  },
  {
    label: 'Age Gated',
    name: 'age_gated',
    tooltip: 'Is there age gated message content in the campaign?',
  },
  {
    label: 'Subscriber Opt-In',
    name: 'subscriber_opt_in',
    tooltip:
      'Does the campaign require subscriber to opt-in before SMS is sent to subscriber?',
  },
  {
    label: 'Auto Renewal',
    name: 'auto_renewal',
    tooltip:
      'Should the campaign be renewed automatically at the end of the billing cycle?.',
  },
  {
    label: 'Subscriber Opt-Out',
    name: 'subscriber_opt_out',
    tooltip: 'Does the campaign support subscriber opt-out keyword(s)?',
  },
  {
    label: 'Embedded Link',
    name: 'embedded_link',
    tooltip: 'Does the campaign message contain a URL?',
  },
  {
    label: 'Subscriber Help',
    name: 'subscriber_help',
    tooltip: 'Does campaign responds to keyword(s)?',
  },
  {
    label: 'Embedded Phone',
    name: 'embedded_phone',
    tooltip: 'Does the campaign message contain a phone number?',
  },

  {
    label: 'Direct Lending',
    name: 'direct_lending',
    tooltip: 'Is it direct landing or loan arrangement?.',
  },
];

export const StepFive = () => {
  return (
    <Flex css={{ width: '100%' }} direction="column">
      <Card css={{ marginTop: '8px' }} variant={'noShadow'}>
        <Container>
          {features.map(({ label, name, tooltip }) => (
            <FeatureItem key={label}>
              <CheckboxFormFieldWrapper label={label} name={name} tooltip={tooltip}>
                <CheckboxInput />
              </CheckboxFormFieldWrapper>
            </FeatureItem>
          ))}
        </Container>
      </Card>
    </Flex>
  );
};

const Container = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '16px',
});

const FeatureItem = styled('div', {
  display: 'flex',
  alignItems: 'baseline',
});
