/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import * as API from '@/shared/api/contacts/v1';
import { Contact } from '@/shared/types';
import { toE164 } from '@/shared/utils/validations/validations';

export enum ClickType {
  // the user click a single phone number on their website
  SINGLE = 'single',
  // the user clicked the whippy icon on their website, which
  // opens the drawer and passes a list of phone numbers from the website
  LIST = 'list',
  // we don't know what the user clicked
  NONE = 'none',
}

export const EmbedContext = createContext<{
  /* array of phone numbers from the website - user clicked the side Whippy icon on chrome extension */
  phoneNumbers: Array<{
    id: string;
    number: string;
  }>;
  /* set the array of phone numbers from the website */
  setPhoneNumbers: Dispatch<
    SetStateAction<
      Array<{
        id: string;
        number: string;
      }>
    >
  >;
  /* single phone number from the website - used clicked just one phone number */
  phone: string;
  /* set the single phone number from the website */
  setPhone: Dispatch<SetStateAction<string>>;
  clickType: ClickType;
  setClickType: Dispatch<SetStateAction<ClickType>>;
  contact: Contact | null;
  setContact: Dispatch<SetStateAction<Contact | null>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  searchContacts: (phone: string) => void;
  clearContacts: () => void;
}>({
  phoneNumbers: [],
  setPhoneNumbers: () => {},
  phone: '',
  setPhone: () => {},
  clickType: ClickType.NONE,
  setClickType: () => {},
  contact: null,
  setContact: () => {},
  loading: false,
  setLoading: () => {},
  searchContacts: () => {},
  clearContacts: () => {},
});

export const useEmbed = () => useContext(EmbedContext);

const EmbedState = ({ children }: { children: React.ReactNode }) => {
  const history = useHistory();

  const [clickType, setClickType] = useState<ClickType>(ClickType.NONE);

  const [phone, setPhone] = useState('');

  const [phoneNumbers, setPhoneNumbers] = useState<
    {
      id: string;
      number: string;
    }[]
  >([]);

  const [contact, setContact] = useState<Contact | null>(null);

  const [loading, setLoading] = useState(false);

  const searchContacts = async (phone: string) => {
    setContact(null);
    setLoading(true);
    try {
      // search for the contact by phone number
      const data = await API.searchContacts('', toE164(phone));
      // get the first contact
      const singleContact = data[0];
      if (singleContact) {
        setContact(singleContact);
        setLoading(false);
      } else {
        setLoading(false);
        history.push(`/inbox/all/open/new?phone=${phone}`);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Setup listener for postMessage events
    // this event listener needs to be higher up (possibly to global provider)
    window.addEventListener('message', handleMessage);

    // Get the URL parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Get the phone parameter
    const phoneParam = urlParams.get('phone');

    if (phoneParam) {
      // Try parsing the phone parameter
      try {
        const phoneData = parsePhoneNumbers(phoneParam);

        if (Array.isArray(phoneData)) {
          setPhoneNumbers(phoneData);
          setClickType(ClickType.LIST);
        } else {
          setPhone(phoneData);
          setClickType(ClickType.SINGLE);
        }
      } catch (err) {
        setClickType(ClickType.NONE);
      }
    }

    // Make sure to cleanup listener on component unmount
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const handleMessage = async (event: { data: string }) => {
    console.log('handleMessage', event);
    if (typeof event.data === 'string') {
      // parse the data
      const data = JSON.parse(event.data);
      // check if the data is from the extension
      // if its not then the event is not from the extension
      if (data.isExtension === true) {
        // redirect to the embed page when the extension is clicked
        history.push('/embed');
        try {
          // parse the entire message
          const data = JSON.parse(event.data);

          // check if the message has a phone number
          if (data.whippy_phone) {
            // parse the phone number
            const whippyPhone = parsePhoneNumbers(data.whippy_phone);

            // check if the phone number is an array
            if (Array.isArray(whippyPhone)) {
              // set the phone numbers and click type to render the list component
              setPhoneNumbers(whippyPhone);
              setClickType(ClickType.LIST);
            } else {
              // set the phone number and click type to render the single component
              // this will then trigger a redirect to the conversation if the contact exists
              // or to the new conversation page if the contact does not exist
              setPhone(whippyPhone);
              setClickType(ClickType.SINGLE);
            }
          } else {
            // if the message does not have a phone number then set the click type to none
            setClickType(ClickType.NONE);
          }
        } catch (err) {
          // catch any errors and set the click type to none
          setClickType(ClickType.NONE);
        }
      }
    } else if (typeof event.data === 'object') {
      // if the event data is an object then set the click type to none
      setClickType(ClickType.NONE);
    }
  };

  useEffect(() => {
    if (clickType == ClickType.SINGLE && phone) {
      searchContacts(phone);
    }
  }, [phone, clickType]);

  const clearContacts = () => {
    setPhoneNumbers([]);
    setPhone('');
    setContact(null);
    // Send message to extension to clear contacts
    window.parent.postMessage({ type: 'whippy-clear-contacts' }, '*');
  };

  return (
    <EmbedContext.Provider
      value={{
        phoneNumbers,
        setPhoneNumbers,
        phone,
        setPhone,
        clickType,
        setClickType,
        contact,
        setContact,
        loading,
        setLoading,
        searchContacts,
        clearContacts,
      }}
    >
      {children}
    </EmbedContext.Provider>
  );
};

const parsePhoneNumbers = (data: string) => {
  // decode the data
  const decodedData = decodeURIComponent(data);

  // decode the data again in case it was encoded twice
  const doubleDecodedData = decodeURIComponent(decodedData);

  // check if the decoded data looks like this - ""(323) 991-8069""
  // with double quotes at the beginning and end then we will have a parsing error
  const doesDoubleDecodedDataLookLikePhoneNumber = /^""(.*)""$/;

  // if we have the above case then we need to remove the double quotes
  if (doesDoubleDecodedDataLookLikePhoneNumber.test(doubleDecodedData)) {
    // remove the first and last quotes
    const cleanedData = doubleDecodedData.replace(/^"(.*)"$/, '$1');

    // parse the cleaned data
    const whippyPhone = JSON.parse(cleanedData);

    // return the parsed data
    return whippyPhone || '';
  }

  // parse the decoded data
  const whippyPhone = JSON.parse(decodedData);

  return whippyPhone || '';
};

export default EmbedState;
