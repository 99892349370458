import { FormikValues, useFormikContext } from 'formik';
import { CountryCode } from 'libphonenumber-js';
import { get } from 'lodash';
import { useEffect, useMemo } from 'react';

import { TextInput } from '@/shared/components/forms';
import { PhoneNumberInput } from '@/shared/components/forms/PhoneNumberInput';
import { Flex } from '@/shared/ui';

import { TextInputProps } from '../types';
import { CountrySelect } from './CountrySelect';

type PhoneInputWithCountryProps = TextInputProps & {
  countryCode: CountryCode | 'other';
  setCountryCode: (countryCode: CountryCode) => void;
  placeholder?: string;
  onValidateOnChange?: (name: string, value: string) => void;
};

export const PhoneInputWithCountry = ({
  placeholder,
  countryCode = 'US',
  setCountryCode,
  onValidateOnChange,
  ...props
}: PhoneInputWithCountryProps) => {
  const formik = useFormikContext<FormikValues>();

  const value = useMemo(() => get(formik.values, props.name as string), [formik]);

  useEffect(() => {
    if (props?.name && value && onValidateOnChange) {
      onValidateOnChange(props?.name || '', value);
    }
  }, [onValidateOnChange, value]);

  return (
    <Flex direction="row" align="center">
      <CountrySelect value={countryCode} onChange={setCountryCode} />
      {countryCode !== 'other' ? (
        <PhoneNumberInput
          {...props}
          placeholder={placeholder}
          countryCode={countryCode}
          css={{
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
          }}
        />
      ) : (
        <TextInput
          {...props}
          placeholder={placeholder}
          css={{
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
          }}
        />
      )}
    </Flex>
  );
};
