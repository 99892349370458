import React from 'react';
import { useParams } from 'react-router-dom';

import { Flex } from '@/shared/ui';

import { AgentEditor } from '../EditAgent';

export const AgentVersion = () => {
  const { id, version_id } = useParams<{ id: string; version_id: string }>();
  return (
    <Flex direction="column" css={{ height: '100%', overflow: 'hidden' }}>
      <AgentEditor id={id} version_id={version_id} disabled={true} />
    </Flex>
  );
};
