import * as React from 'react';
import * as Yup from 'yup';

import {
  StepFive,
  StepFour,
  StepOne,
  StepThree,
  StepTwo,
} from '@/settings/organization/trust/brands/createBrandView/createBrandSteps';
import { isValidPhoneNumber } from '@/shared/utils/validations/validations';

export const InitialBrandFormData = {
  company_name: '',
  display_name: '',
  description: '',
  email: '',
  phone: '',
  street: '',
  city: '',
  state: 'CA',
  postal_code: '',
  website: '',
  universal_ein: '',
  entity_type: 'PRIVATE_PROFIT',
  business_employee_count: '50',
  business_registration_number: '',
  brand_relationship: 'BASIC_ACCOUNT',
  business_registration_type: 'EIN',
  alt_business_registration_number: '',
  alt_business_registration_type: 'EIN',
  average_business_day_call_volume: '50000',
  business_identity: 'DIRECT_CUSTOMER',
  business_regions_of_operation: 'USA_AND_CANADA',
  stock_symbol: '',
  stock_exchange: '',
  government_entity: false,
  business_type: 'CORPORATION',
  representative_job_position: 'CEO',
  representative_business_title: '',
  representative_phone: '',
  representative_first_name: '',
  representative_last_name: '',
  representative_email: '',
  business_use_case: 'Identify & Verification',
};

export const BrandFormValidation = Yup.object({
  company_name: Yup.string().required('Required'),
  display_name: Yup.string().required('Required'),
  description: Yup.string(),
  email: Yup.string().required('Required').email('Please provide a valid email'),
  representative_phone: Yup.string()
    .required('Required')
    .test(
      'phone',
      'Please provide a valid phone number',
      (value) => !!(value && isValidPhoneNumber(value))
    ),
  phone: Yup.string()
    .required('Required')
    .test(
      'phone',
      'Please provide a valid phone number',
      (value) => !!(value && isValidPhoneNumber(value))
    ),
  street: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  postal_code: Yup.string().required('Required'),
  website: Yup.string().required('Required'),
  universal_ein: Yup.string().required('Required'),
  entity_type: Yup.string(),
  brand_relationship: Yup.string(),
  business_employee_count: Yup.string().required('Required'),
  business_use_case: Yup.string().required('Required'),
  business_registration_number: Yup.string().required('Required'),
  business_registration_type: Yup.string().required('Required'),
  alt_business_registration_number: Yup.string(),
  alt_business_registration_type: Yup.string(),
  average_business_day_call_volume: Yup.string().required('Required'),
  business_identity: Yup.string().required('Required'),
  business_regions_of_operation: Yup.string().required('Required'),
  stock_symbol: Yup.string().when('entity_type', {
    is: 'PUBLIC_PROFIT',
    then: () => Yup.string().required('Stock symbol is required for PUBLIC_PROFIT'),
    otherwise: () => Yup.string().notRequired(),
  }),

  stock_exchange: Yup.string().when('entity_type', {
    is: 'PUBLIC_PROFIT',
    then: () => Yup.string().required('Stock exchange is required for PUBLIC_PROFIT'),
    otherwise: () => Yup.string().notRequired(),
  }),
  government_entity: Yup.boolean(),
  business_type: Yup.string().required('Required'),
  representative_job_position: Yup.string().required('Required'),
  representative_business_title: Yup.string().required('Required'),
  representative_email: Yup.string()
    .required('Required')
    .email('Please provide a valid email'),
  representative_first_name: Yup.string().required('Required'),
  representative_last_name: Yup.string().required('Required'),
});

export const steps = [
  {
    title: 'Create 10DLC Brand',
    description: 'Fill in the essential details below',
    children: <StepOne />,
  },
  {
    title: 'Address Details',
    description: 'Fill in the essential details below',
    children: <StepTwo />,
  },
  {
    title: 'Business Type and Registration Selection',
    description: 'Fill in the essential details below',
    children: <StepThree />,
  },
  {
    title: 'Business Details and Operational Region',
    description: 'Fill in the essential details below',
    children: <StepFour />,
  },
  {
    title: 'Brand Representative Details',
    description: 'Choose applicable campaign features',
    children: <StepFive />,
  },
];

export const editSteps = [
  {
    title: 'Edit 10DLC Brand',
    description: '',
    children: <StepOne />,
  },
  {
    title: 'Address Details',
    description: '',
    children: <StepTwo />,
  },
  {
    title: 'Business Type and Registration Selection',
    description: '',
    children: <StepThree />,
  },
  {
    title: 'Business Details and Operational Region',
    description: '',
    children: <StepFour />,
  },
  {
    title: 'Brand Representative Details',
    description: '',
    children: <StepFive />,
  },
];
