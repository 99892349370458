import moment from 'moment';
import { useEffect, useState } from 'react';
import React from 'react';

import { AgentsAnalytics } from '@/pages/agents/analytics/AgentsAnalytics';
import { ReportData } from '@/shared/api/reports';
import { DatepickerDates } from '@/shared/components/datepicker/DateSelector';
import {
  AnalyticFilterItem,
  AnalyticFilters,
  filterAll,
  FilterOption,
} from '@/shared/components/reports/AnalyticFilters';
import { useReportData } from '@/shared/components/reports/useReportData';
import { ConversationItemSourceTypes } from '@/shared/types/conversations';
import { Box, Flex, VStack } from '@/shared/ui';

import { ReportsContentContainer } from '../layout/ReportsContentContainer';

export const initialDates = {
  startDate: moment().add(-4, 'week'),
  endDate: moment(),
};

const filterOptions: Array<FilterOption> = [
  {
    label: 'Agent',
    key: 'agent_id',
    type: 'agent',
    options: [],
  },
  {
    label: 'AgentVersion',
    key: 'agent_version_id',
    type: 'agent_version',
    options: [],
  },
  {
    label: 'Channel',
    key: 'channel_id',
    type: 'channel',
    options: [],
  },
  {
    label: 'Call Completion Rating',
    key: 'call_completion_rating',
    type: 'select',
    options: [
      filterAll,
      { label: 'Complete', value: 'Complete' },
      { label: 'Incomplete', value: 'Incomplete' },
    ],
  },
  {
    label: 'Direction',
    key: 'source_type',
    type: 'select',
    options: [
      filterAll,
      { label: 'Inbound', value: ConversationItemSourceTypes.INBOUND },
      { label: 'Outbound', value: ConversationItemSourceTypes.OUTBOUND },
    ],
  },
  {
    label: 'Call Duration',
    key: 'call_duration',
    type: 'call_duration',
    options: [
      { label: 'More than 5 minutes', value: '>300' },
      { label: 'Less than 5 minutes', value: '<300' },
      { label: 'More than 10 minutes', value: '>600' },
      { label: 'Less than 10 minutes', value: '<600' },
      { label: 'More than 15 minutes', value: '>900' },
      { label: 'Less than 15 minutes', value: '<900' },
      { label: 'Less than 30 minutes', value: '<1800' },
      { label: 'More than 30 minutes', value: '>1800' },
      { label: 'Less than 60 minutes', value: '<3600' },
      { label: 'More than 60 minutes', value: '>3600' },
      { label: 'Less than 90 minutes', value: '<5400' },
      { label: 'More than 90 minutes', value: '>5400' },
      { label: 'More than 120 minutes', value: '>7200' },
      { label: 'Less than 120 minutes', value: '<7200' },
    ],
  },
];

export const AgentsReport = () => {
  const [reportBar, setReportBar] = useState<any>([]);
  const [stackedReportBar, setStackedReportBar] = useState<any>([]);
  const [filters, setFilters] = useState<Array<AnalyticFilterItem>>([]);
  const [dates, setDates] = useState<DatepickerDates>(initialDates);
  const [timeBucket, setTimeBucket] = useState<string>('day');

  const { data, isLoading, error } = useReportData(filters, dates, timeBucket, 'agents');

  useEffect(() => {
    setReportBar(
      data
        .find((d) => d.chart === 'bar' && d.label === 'Calls by Day')
        ?.values.map((d) => {
          return { key: d.y, count: d.x };
        })
    );

    setStackedReportBar(mapStakedAgentChart(data));
  }, [data]);

  return (
    <ReportsContentContainer title="Agents" hideDatepicker>
      <Flex direction="column">
        <VStack gap={5}>
          <Box>
            <AnalyticFilters
              filterOptions={filterOptions}
              filters={filters}
              setFilters={setFilters}
              dates={dates}
              setDates={setDates}
              timeBucket={timeBucket}
              setTimeBucket={setTimeBucket}
            />
          </Box>
          <AgentsAnalytics
            error={error}
            timeBucket={timeBucket}
            data={data}
            stackedReportBar={stackedReportBar}
            reportBar={reportBar}
            isLoading={isLoading}
          />
        </VStack>
      </Flex>
    </ReportsContentContainer>
  );
};

export const mapStakedAgentChart = (data: Array<ReportData>) =>
  data
    .find((d) => d.chart === 'bar' && d.label === 'Sentiment of Calls by Day')
    ?.values.map((d) => {
      return {
        key: d.y,
        positive: d.groups?.find((group) => group.key === 'Positive')?.x ?? 0,
        negative: d.groups?.find((group) => group.key === 'Negative')?.x ?? 0,
        neutral: d.groups?.find((group) => group.key === 'Neutral')?.x ?? 0,
      };
    });
