import { useEffect, useMemo, useState } from 'react';

import * as customDataAPI from '@/shared/api/data';
import { AdvancedFilterBuilder } from '@/shared/components/filterBuilder/AdvancedFilterItem';
import {
  campaign_contact_campaign_id,
  default_campaign_contact_object,
  default_campaign_message_object,
  default_communication_preference_object,
  default_contact_list_object,
  default_contact_object,
  default_contact_tag_object,
  default_conversation_object,
  default_segments_object,
  default_sequence_run_object,
  join_custom_data_object,
} from '@/shared/components/filterBuilder/objects';
import { default_campaign_contact_message_object } from '@/shared/components/filterBuilder/objects/contact_messages';
import { areFiltersValid } from '@/shared/components/filterBuilder/utils/areValidFilters';
import { cleanFilters } from '@/shared/components/filterBuilder/utils/cleanFilters';
import { rebuildFilters } from '@/shared/components/filterBuilder/utils/rebuildFilters';
import { CustomObject } from '@/shared/types/data';
import { FilterItem, FilterType } from '@/shared/types/filter';
import { Box, Button, Fieldset, Flex, Label, VStack } from '@/shared/ui';

import AudienceQuickFilter from '../editor/components/AudienceQuickFilter';
import { isValidQuickFilter, translateToAdvancedFilter } from '../editor/utils';

export type QuickFilterAlias = {
  label: string;
  filter: FilterItem;
  type: 'included' | 'excluded';
};

type CampaignAudienceProps = {
  channelId: string | null;
  /**
   * There are times where we want to be able to "hide" a filter from the audience builder.
   * This would include things like the system default filters, or filters that are not
   * created through the audience builder like the exclude open conversation filter which
   * is done through a switch component.
   */
  exclude: {
    fullExcludeAudienceFilter: FilterItem[];
    removeFromFilter: (filterItems: FilterItem[]) => FilterItem[];
    appendToFilter: (filterItems: FilterItem[]) => FilterItem[];
  };
  quickFilterAliases?: QuickFilterAlias[];
  includedAudienceFilter: FilterItem[] | null;
  audienceTabValue: 'advanced-filter' | 'quick-filter';
  onAudienceTabValueChanged: (
    audienceTabValue: 'advanced-filter' | 'quick-filter'
  ) => void;
  onIncludedAudienceFilterChanged: (filterItems: FilterItem[]) => void;
  /**
   * This function is called when the excluded audience filter and is valid. Meaning that for the advanced filter
   * you won't get a notification when you're at an intermediate step.
   *
   * NOTE: It's very important that you only pass in the user selected filters here. For example, system default filters
   * should probably not be passed in here since they would be shown to the user.
   *
   */
  onExcludedAudienceFilterChanged: (filterItems: FilterItem[]) => void;
};
const CampaignAudience = ({
  includedAudienceFilter,
  exclude,
  audienceTabValue,
  quickFilterAliases,
  onAudienceTabValueChanged,
  onIncludedAudienceFilterChanged,
  onExcludedAudienceFilterChanged,
  channelId,
}: CampaignAudienceProps) => {
  const customDefaultObjects = useMemo(() => {
    return getWQLResources();
  }, []);
  const { fullExcludeAudienceFilter, removeFromFilter, appendToFilter } = exclude;

  const excludedAudienceQuickFilter = useMemo(() => {
    return removeFromFilter(fullExcludeAudienceFilter);
  }, [fullExcludeAudienceFilter]);

  const isExcludedFilterValidQuickFilter = useMemo(() => {
    const cleanedExcludeQuickFilter = removeFromFilter(fullExcludeAudienceFilter);
    return isValidQuickFilter(cleanedExcludeQuickFilter, 'excluded', quickFilterAliases);
  }, [fullExcludeAudienceFilter, removeFromFilter]);

  const [includedFilterType, setIncludedFilterType] = useState<FilterType[]>(() => {
    const advancedFilter = translateToAdvancedFilter(
      includedAudienceFilter ?? []
    ) as FilterType[];
    return rebuildFilters([], advancedFilter, customDefaultObjects);
  });

  const [excludedFilterType, setExcludedFilterType] = useState<FilterType[]>(() => {
    const cleanedExcludeAdvancedFilter = removeFromFilter(fullExcludeAudienceFilter);
    const advancedFilter = translateToAdvancedFilter(
      cleanedExcludeAdvancedFilter ?? []
    ) as FilterType[];
    return rebuildFilters([], advancedFilter, customDefaultObjects);
  });

  const [customObjects, setCustomObjects] = useState<CustomObject[]>([]);

  const isIncludedFilterValidQuickFilter = useMemo(() => {
    return isValidQuickFilter(includedAudienceFilter, 'included', quickFilterAliases);
  }, [includedAudienceFilter]);

  useEffect(() => {
    customDataAPI
      .getCustomObjects({
        filter: [],
        sort: [],
        limit: 100,
        offset: 0,
      })
      .then((data) => {
        setCustomObjects(data.data);
      });
  }, []);

  /*
   * The goal of this function is to
   * 1. Add the system default rules back to the excluded audience filter (the quick filter interacts
   * with the excluded audience filter without the system default rules)
   * 2. Keep the quick filter and the advanced filter in sync
   */
  const updateExcludedAudienceFilters = (
    items: FilterItem[],
    updateFilterType: boolean
  ) => {
    const fullFilter = appendToFilter(items);
    onExcludedAudienceFilterChanged(fullFilter);

    if (!updateFilterType) return;
    // also update the Filter Types for the advanced filter
    // we should use the version without the system default rules appended.
    // This is because this is for the local state of the excluded audience
    const advancedExcludeFilter = translateToAdvancedFilter(items ?? []) as FilterType[];
    setExcludedFilterType(
      rebuildFilters([], advancedExcludeFilter, customDefaultObjects)
    );
  };

  return (
    <VStack gap="2">
      <Flex gap="1" css={{ mb: '$2' }}>
        <Button
          type="button"
          data-testid="quick-filters-btn"
          variant="grayBackground"
          disabled={
            !isIncludedFilterValidQuickFilter || !isExcludedFilterValidQuickFilter
          }
          css={
            audienceTabValue === 'advanced-filter' ? { background: 'transparent' } : {}
          }
          onClick={() => onAudienceTabValueChanged('quick-filter')}
        >
          Quick Filters
        </Button>
        <Button
          type="button"
          data-testid="advanced-filters-btn"
          variant="grayBackground"
          css={audienceTabValue === 'quick-filter' ? { background: 'transparent' } : {}}
          onClick={() => onAudienceTabValueChanged('advanced-filter')}
        >
          Advanced Filters
        </Button>
      </Flex>
      <Flex direction="column">
        <Label>Include Contacts</Label>
        {audienceTabValue == 'quick-filter' ? (
          <Fieldset data-testid="field-include-audience">
            <Box
              css={{
                p: 0,
                m: 0,
                background: 'white',
              }}
            >
              <AudienceQuickFilter
                data-testid="included_audience_filter"
                channelId={channelId}
                aliases={quickFilterAliases}
                onSelectedItemsChanged={(filterItems) => {
                  onIncludedAudienceFilterChanged(filterItems);
                  const advancedIncludeFilter = translateToAdvancedFilter(
                    filterItems ?? []
                  ) as FilterType[];
                  setIncludedFilterType(
                    rebuildFilters([], advancedIncludeFilter, customDefaultObjects)
                  );
                }}
                selectedItems={includedAudienceFilter}
              />
            </Box>
          </Fieldset>
        ) : (
          <Fieldset>
            <AdvancedFilterBuilder
              defaultResourceObjects={customDefaultObjects}
              customDataObjects={customObjects}
              filters={includedFilterType}
              setFilters={(filters) => {
                setIncludedFilterType(filters);
                // If we're at an intermediate step, we need to make sure that the filters are valid
                // before we update notify that we have a valid filter
                if (!areFiltersValid(filters)) return;
                const cleanedFilters = cleanFilters(filters);
                onIncludedAudienceFilterChanged(cleanedFilters);
              }}
            />
          </Fieldset>
        )}
      </Flex>
      <Flex direction="column">
        <Label>Exclude Contacts</Label>
        {audienceTabValue == 'quick-filter' ? (
          <Fieldset data-testid="field-exclude-audience">
            <Box
              css={{
                p: 0,
                m: 0,
                background: 'white',
              }}
            >
              <AudienceQuickFilter
                data-testid="excluded_audience_filter"
                channelId={channelId}
                onSelectedItemsChanged={async (filterItems) => {
                  if (!channelId) return;
                  // update the filter type as well
                  updateExcludedAudienceFilters(filterItems, true);
                }}
                selectedItems={excludedAudienceQuickFilter}
              />
            </Box>
          </Fieldset>
        ) : (
          <Fieldset>
            <AdvancedFilterBuilder
              defaultResourceObjects={customDefaultObjects}
              customDataObjects={customObjects}
              filters={excludedFilterType}
              setFilters={(filters) => {
                setExcludedFilterType(filters);
                // If we're at an intermediate step, we need to make sure that the filters are valid
                // before we update notify that we have a valid filter
                if (!areFiltersValid(filters) || !channelId) return;
                const cleanedFilters = cleanFilters(filters);
                updateExcludedAudienceFilters(cleanedFilters, false);
              }}
            />
          </Fieldset>
        )}
      </Flex>
    </VStack>
  );
};

function getWQLResources() {
  const customContactObject = structuredClone(default_contact_object);
  customContactObject.custom_properties.unshift({
    default: '',
    id: 'contact-id',
    label: 'ID',
    type: 'text',
    key: 'id',
    required: true,
    inserted_at: 'current-timestamp',
    updated_at: 'current-timestamp',
    custom_object_id: 'unique-id-for-contact',
  });
  return [
    customContactObject,
    {
      ...default_campaign_contact_message_object,
      custom_properties: [
        ...default_campaign_contact_message_object.custom_properties,
        {
          default: '',
          id: 'contact_message-message',
          label: 'Message',
          type: 'text',
          key: 'id',
          required: true,
          inserted_at: 'current-timestamp',
          updated_at: 'current-timestamp',
          custom_object_id: 'unique-id-for-contact-message',
        },
      ],
    },
    default_contact_tag_object,
    default_contact_list_object,
    default_campaign_message_object,
    {
      ...default_campaign_contact_object,
      custom_properties: [
        campaign_contact_campaign_id,
        ...default_campaign_contact_object.custom_properties,
      ],
    },
    default_sequence_run_object,
    default_conversation_object,
    default_communication_preference_object,
    join_custom_data_object,
    {
      ...default_segments_object,
      custom_properties: [
        ...default_segments_object.custom_properties,
        {
          default: '',
          id: 'segment-segment',
          label: 'Segment',
          type: 'segment',
          key: 'id',
          required: true,
          inserted_at: 'current-timestamp',
          updated_at: 'current-timestamp',
          custom_object_id: 'unique-id-for-segment',
        },
      ],
    },
  ];
}

export default CampaignAudience;
