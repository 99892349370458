import dayjs from 'dayjs';
import { Dispatch, useEffect, useState } from 'react';
import React from 'react';
import { HiX } from 'react-icons/hi';

import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { useUsers } from '@/pages/settings/organization/users/context/UserContext';
import { DatepickerDates } from '@/shared/components/datepicker/DateSelector';
import {
  ComboboxOption,
  ValueCombobox,
} from '@/shared/components/filterBuilder/values/Combobox';
import {
  handleUpdateDates,
  TimeBucketSelector,
} from '@/shared/components/reports/TimeBucketSelector';
import { Channel } from '@/shared/types/channels';
import { User } from '@/shared/types/users';
import { Box, Button, Flex, IconButton, Input } from '@/shared/ui';
import { ComboboxMultiselect } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselect';
import { ComboboxMultiselectItem } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectItem';

import { DateSelector } from '../datepicker/DateSelector';
import { AgentVersionFilterCombobox } from './AgentVersionFilterCombobox';
import {
  AnalyticFilterCombobox,
  ComboboxMultiselectTrigger,
} from './AnalyticFilterCombobox';

export const filterAll = { label: 'All', value: 'All' };

export type FilterOption = {
  label: string;
  key: string;
  type: string;
  options: Array<{
    label: string;
    value: string;
  }>;
};

const defaultFilter: AnalyticFilterItem = {
  column: '',
  comparison: 'in',
  value: [],
};
export type AnalyticFilterItem = {
  column: string;
  comparison: string | null;
  value: Array<string | number> | string | number;
};

export const AnalyticFilters = ({
  filterOptions,
  filters,
  setFilters,
  dates,
  setDates,
  timeBucket,
  setTimeBucket,
  defaultFilters,
}: {
  filterOptions: Array<FilterOption>;
  filters: Array<AnalyticFilterItem>;
  setFilters: Dispatch<React.SetStateAction<Array<AnalyticFilterItem>>>;
  dates: DatepickerDates;
  setDates: Dispatch<React.SetStateAction<DatepickerDates>>;
  timeBucket?: string;
  setTimeBucket?: Dispatch<React.SetStateAction<string>>;
  defaultFilters?: Array<AnalyticFilterItem>;
}) => {
  const selectFilter = (key: string, value: string | number | Array<string | number>) => {
    const newFilter: AnalyticFilterItem = {
      ...defaultFilter,
      column: key,
      value: Array.isArray(value) ? value : [value],
    };
    if (key === 'call_duration' && !Array.isArray(value)) {
      newFilter.comparison = value.toString().includes('<') ? '<=' : '>=';
      newFilter.value = Number(value.toString().replace(/[^0-9]/g, ''));
    }

    if (filters.length) {
      const filterIndex = filters.findIndex((f) => f.column === key);
      const newFilters = [...filters];
      if (filterIndex >= 0) {
        if (Array.isArray(value) && !value.length) {
          newFilters.splice(filterIndex, 1);
        } else if (value === filterAll.value) {
          newFilters.splice(filterIndex, 1);
        } else {
          newFilters[filterIndex] = newFilter;
        }
      } else {
        if (value !== filterAll.value) {
          newFilters.push(newFilter);
        }
      }
      setFilters(newFilters);
    } else {
      if (value !== filterAll.value) {
        setFilters([newFilter]);
      }
    }
  };

  const cleanFilters = () => {
    setFilters(defaultFilters ?? []);
    if (setTimeBucket) {
      setTimeBucket('day');
    }
  };

  return (
    <Flex gap={2} wrap="wrap" data-testid="analytic-filters">
      <DateSelector
        dates={dates}
        setDates={(value: DatepickerDates) =>
          handleUpdateDates(value, setDates, timeBucket, setTimeBucket)
        }
        maxDate={dayjs(new Date()).format('YYYY-MM-DD')}
      />
      {filterOptions.map((filter) => (
        <React.Fragment key={filter.key}>
          {renderFilter(filter, filters, selectFilter)}
        </React.Fragment>
      ))}
      {!!timeBucket && !!setTimeBucket && (
        <TimeBucketSelector
          dates={dates}
          timeBucket={timeBucket}
          setTimeBucket={setTimeBucket}
        />
      )}
      {(!!filters.length || (timeBucket && timeBucket !== 'day')) && (
        <IconButton
          onClick={() => cleanFilters()}
          css={{
            border: '1px solid $slate7',
            height: 40,
            width: 40,
            background: 'white',
          }}
        >
          <HiX size="15px" />
        </IconButton>
      )}
    </Flex>
  );
};

const renderFilter = (
  filter: FilterOption,
  filters: Array<AnalyticFilterItem>,
  selectFilter: (key: string, value: string | number | Array<string | number>) => void
) => {
  const getFilter = (key: string, options: Array<ComboboxOption>, isArray?: boolean) => {
    const filter = filters.find((f) => f.column === key);
    if (filter && filter?.column === 'call_duration') {
      const value = `${filter.comparison === '>=' ? '>' : '<'}${Number(filter.value)}`;
      return filter && value;
    }

    return (
      filter &&
      (isArray
        ? filter.value
        : options.find((o) =>
            Array.isArray(filter?.value)
              ? o.value == filter?.value[0]
              : o.value == filter?.value
          ))
    );
  };
  switch (filter.type) {
    case 'call_duration':
      return (
        <CallDurationCombobox
          filter={filter}
          onSelect={(option) => {
            selectFilter(filter.key, option.value);
          }}
          selected={getFilter(filter.key, filter.options)?.toString()}
        />
      );
    case 'agent':
      return (
        <Box css={{ maxWidth: 200 }}>
          <AnalyticFilterCombobox
            selectedItems={(getFilter(filter.key, [], true) as Array<string>) ?? []}
            setSelectedItems={(value: Array<string>) => selectFilter(filter.key, value)}
          />
        </Box>
      );
    case 'agent_version':
      return (
        <Box css={{ maxWidth: 300 }}>
          <AgentVersionFilterCombobox
            selectedItems={(getFilter(filter.key, [], true) as Array<string>) ?? []}
            setSelectedItems={(value: Array<string>) => selectFilter(filter.key, value)}
          />
        </Box>
      );
    case 'channel':
      return (
        <Box css={{ maxWidth: 300 }}>
          <ChannelsFilterCombobox
            selectedItems={(getFilter(filter.key, [], true) as Array<string>) ?? []}
            setSelectedItems={(value: Array<string>) => selectFilter(filter.key, value)}
          />
        </Box>
      );
    case 'user':
      return (
        <Box css={{ maxWidth: 300 }}>
          <UsersFilterCombobox
            selectedItems={
              (getFilter(filter.key, [], true) as Array<string>)?.map((v) =>
                v.toString()
              ) ?? []
            }
            setSelectedItems={(value: Array<string | number>) =>
              selectFilter(filter.key, value)
            }
          />
        </Box>
      );
    default:
      return (
        <ValueCombobox
          key={filter.key}
          hideChevron
          selectLabel={filter.label}
          options={filter.options}
          onSelect={(option) => {
            selectFilter(filter.key, option.value);
          }}
          selected={getFilter(filter.key, filter.options) as ComboboxOption}
          withSearch={false}
          css={{ minWidth: 'auto', height: 40 }}
          renderOptionsIfOpen
        />
      );
  }
};

export const ChannelsFilterCombobox = ({
  selectedItems,
  setSelectedItems,
}: {
  selectedItems: Array<string>;
  setSelectedItems: (value: Array<string>) => void;
}) => {
  const locationsContext = useChannels();
  const { channelsState } = locationsContext;
  return (
    <Box css={{ maxWidth: 300 }}>
      <ComboboxMultiselect
        options={channelsState.allChannels.map((channel: Channel) => ({
          label: channel.name || '',
          value: channel.id || '',
        }))}
        width="auto"
        selected={selectedItems}
        onSelect={setSelectedItems}
        searchLabel="Search"
        selectLabel="Channels"
        Trigger={ComboboxMultiselectTrigger}
        Option={ComboboxMultiselectItem}
        visualized
      />
    </Box>
  );
};

export const UsersFilterCombobox = ({
  selectedItems,
  setSelectedItems,
}: {
  selectedItems: Array<string>;
  setSelectedItems: (value: Array<string | number>) => void;
}) => {
  const usersContext = useUsers();
  const { userState } = usersContext;
  return (
    <Box css={{ maxWidth: 300 }}>
      <ComboboxMultiselect
        options={userState.users.map((user: User) => ({
          label: user.name || user.email || '',
          value: user.id.toString() || '',
        }))}
        width="auto"
        selected={selectedItems}
        onSelect={(values: Array<string>) =>
          setSelectedItems(values.map((v) => Number(v)))
        }
        searchLabel="Search"
        selectLabel="Users"
        Trigger={ComboboxMultiselectTrigger}
        Option={ComboboxMultiselectItem}
        visualized
      />
    </Box>
  );
};

export const CallDurationCombobox = ({
  filter,
  selected,
  onSelect,
}: {
  filter: FilterOption;
  selected?: string;
  onSelect: (option: { label: string; value: string }) => void;
}) => {
  const [value, setValue] = useState<string>();
  const [comparison, setComparison] = useState<string>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const options = [
    { label: 'More than', value: '>=' },
    { label: 'Less than', value: '<=' },
  ];
  const [selectedOption, setSelectedOption] = useState<ComboboxOption>();

  useEffect(() => {
    if (selected) {
      const data = filter.options.find((o) => o.value == selected);
      if (data) {
        setSelectedOption(data);
        setComparison(undefined);
        setValue(undefined);
      } else {
        setSelectedOption(undefined);
        setComparison(selected.toString().includes('<') ? '<=' : '>=');
        setValue(selected.toString().replace(/[^0-9]/g, ''));
      }
    } else {
      setSelectedOption(undefined);
      setComparison(undefined);
      setValue(undefined);
    }
  }, [selected]);

  return (
    <ValueCombobox
      renderOptionsIfOpen
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      key={filter.key}
      hideChevron
      selectLabel={filter.label}
      options={filter.options}
      onSelect={onSelect}
      selected={
        selectedOption ??
        (selected
          ? {
              label:
                comparison == '>='
                  ? `More than ${value} seconds`
                  : `Less than ${value} seconds`,
              value: selected ?? '',
            }
          : undefined)
      }
      withSearch={false}
      css={{ minWidth: 'auto', height: 40 }}
      customItem={
        <Flex align="center" gap={1} css={{ marginBottom: 10 }}>
          <ValueCombobox
            key={filter.key}
            selectLabel="Comparison"
            options={options}
            onSelect={(options) => {
              setComparison(options.value);
            }}
            selected={options.find((o) => o.value == comparison) as ComboboxOption}
            withSearch={false}
            css={{ minWidth: 'auto' }}
            renderOptionsIfOpen
          />
          <Input
            type="number"
            placeholder="Call duration in seconds"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <Button
            disabled={!comparison || !value}
            onClick={() => {
              setIsOpen(false);
              onSelect({ label: '', value: `${comparison}${value}` });
            }}
          >
            Apply
          </Button>
        </Flex>
      }
    />
  );
};
