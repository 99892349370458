import { CheckedState } from '@radix-ui/react-checkbox';
import React from 'react';
import { HiPencil } from 'react-icons/hi';
import { useMedia } from 'react-use';

import {
  ContactSourceColor,
  ContactSourceCopy,
} from '@/pages/contacts/context/constants';
import { Contact } from '@/shared/types';
import { Checkbox, Flex, IconButton, Skeleton } from '@/shared/ui';
import { formatPhoneNumber, isValidUuid } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { ConversationDrawer } from '../inbox/drawer';
import { useChannels } from '../settings/organization/channels/context/ChannelContext';
import { useUserPreferences } from '../settings/user/preferences/context/PreferencesContext';
import { ContactSourceTag } from './ContactSourceTag';
import { useContacts } from './context/ContactContext';

type ContactRowProps = {
  /** the contact to render in the row */
  contact: Contact;
  /** an array of checked contacts */
  checkedContacts: Array<Contact>;
  /** the function to add the contact in the row to the check contacts  */
  checkContact: (array: Array<Contact>) => void;
  /** the function to select all contacts in the row to the check contacts  */
  setAllContactsChecked: (checked: boolean) => void;
  /** boolean that tells if select all is clicked  */
  allChecked?: boolean;
  /** location id for conversation drawer  */
  location_id?: string;
};

export const ContactRow = (props: ContactRowProps): JSX.Element => {
  const { contact, checkedContacts, checkContact, setAllContactsChecked } = props;

  const contactContext = useContacts();
  const { onOpen, getAndSetCurrent } = contactContext;

  const isDesktop = useMedia('(min-width: 912px)');

  // set the contact in contact panel on click
  const onContactClick = (contact: Contact) => {
    if (isDesktop) {
      getAndSetCurrent(contact.id);
    } else {
      // set contact and open the contact panel on mobile
      getAndSetCurrent(contact.id);
      onOpen();
    }
  };

  // toggle if the contact is checked
  const onContactCheck = (checked: CheckedState) => {
    if (checked) {
      checkContact([...checkedContacts, contact]);
    } else {
      const arrayWithoutUncheckedItem = checkedContacts.filter(
        (i: Contact) => i.id !== contact.id
      );
      setAllContactsChecked ? setAllContactsChecked(false) : null;
      checkContact(arrayWithoutUncheckedItem);
    }
  };

  // channels state
  const location = useChannels();
  const { channelsState } = location;
  const { channels } = channelsState;

  const isLocationIdValid = (id: string) => {
    return isValidUuid(id) && channels.some((location) => location.id === id);
  };

  // if the location id is passed in, use that
  // otherwise use the preferred location id if it is valid
  // else use the first location in the list
  // fallback to empty string
  const getDrawerLocationId = () => {
    if (props.location_id && isLocationIdValid(props.location_id)) {
      return props.location_id;
    } else if (
      preferences &&
      preferences.inbox &&
      preferences.inbox.preferred_location_id &&
      isLocationIdValid(preferences.inbox.preferred_location_id)
    ) {
      return preferences.inbox.preferred_location_id;
    } else if (channels.length > 0) {
      return channels[0].id;
    } else {
      return '';
    }
  };

  // if no prop is given for location id, use the preferred location id
  // if it is set, or else user the first location in the list
  const user_preferences = useUserPreferences();
  const { preferences } = user_preferences;

  return (
    <ContactRowContainer align="center">
      <Flex
        align="center"
        justify="between"
        onClick={() => onContactClick(contact)}
        css={{ flexGrow: 1, width: '100%', height: '100%' }}
      >
        <Flex>
          {isDesktop && (
            <Flex align="center" css={{ pr: 10 }}>
              <Checkbox
                onCheckedChange={onContactCheck}
                checked={checkedContacts
                  .map((contact: Contact) => contact.id)
                  .includes(contact.id)}
              />
            </Flex>
          )}
          {contact.name || formatPhoneNumber(contact?.phone || '')}
        </Flex>
        <Flex css={{ pr: 10 }}>
          {contact.source && isDesktop && (
            <ContactSourceTag
              iconColor={ContactSourceColor[contact.source] || '#2A2D8A'}
              tagText={ContactSourceCopy[contact.source] || contact.source}
            />
          )}
        </Flex>
      </Flex>
      {isDesktop && (props.location_id || channels[0]?.id) && (
        <ConversationDrawer
          contact_id={contact.id}
          contact_name={contact?.name || formatPhoneNumber(contact?.phone || '') || ''}
          contact_phone={contact?.phone || ''}
          contact_email={contact?.email || ''}
          location_id={getDrawerLocationId()}
        >
          <IconButton>
            <HiPencil />
          </IconButton>
        </ConversationDrawer>
      )}
    </ContactRowContainer>
  );
};

export const ContactRowContainer = styled(Flex, {
  height: '45px',
  px: 15,
  fontWeight: 400,
  fontSize: 13,
  borderBottom: 'thin solid $gray4',
  alignItems: 'center',
  align: 'center',
  justify: 'space-between',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '$slate2',
  },
  variants: {
    variant: {
      search: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      selection: {
        backgroundColor: '$gray2',
        '&:hover': {
          backgroundColor: '$gray2',
        },
      },
    },
  },
});

export const LoadingContactRow = (): JSX.Element => {
  return (
    <ContactRowContainer align="center" justify="between">
      <Skeleton variant="avatar" />
      <Skeleton variant="heading" css={{ width: '100%', ml: '$2' }} />
    </ContactRowContainer>
  );
};
