import { useKBar } from 'kbar';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo, useState } from 'react';
import {
  HiBell,
  HiCode,
  HiCog,
  HiOutlineChevronDown,
  HiPhoneOutgoing,
  HiPlus,
  HiPuzzle,
  HiQuestionMarkCircle,
  HiSearch,
} from 'react-icons/hi';
import { PiCommand } from 'react-icons/pi';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { QuickCampaignDialog } from '@/campaigns/quick';
import { CreateUpload } from '@/contacts/uploads/CreateUpload';
import { useSocket } from '@/pages/auth/context/socket/SocketProvider';
import { CreateContact } from '@/pages/data/contacts/CreateContact';
import { useData } from '@/pages/data/context/DataContext';
import { SequenceAudience } from '@/pages/sequences/sequence/audience/AddAudienceToSequence';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { useUserPreferences } from '@/pages/settings/user/preferences/context/PreferencesContext';
import { useVoIP } from '@/pages/voip/context/VoIPContext';
import { useUserWebNotifications } from '@/shared/components/notifications/context/UserWebNotificationsContext';
import { KbdStyled } from '@/shared/globalSearch/utils';
import { Channel, ChannelTypes, ProviderTypes } from '@/shared/types/channels';
import { isDev, isPreview } from '@/shared/utils/config';
import { styled } from '@/stitches.config';

import {
  Box,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
  HStack,
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '../../../ui';
import { WhippyLogoNew } from '../../Icons';
import { useReconnectToast } from '../hooks/useReconnectToast';
import OrganizationUserMenu from '../OrganizationUserMenu';
import { navigationData, tabDataType } from './constants';

const HeaderContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  padding: '8px 20px',
  // background: '#131621',
  background: '#171922',
  // #171922
});

const NavigationMenuContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#1E212D',
  padding: '0 10px',
});

const HeaderButtonContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'nowrap',
  gap: '20px',
});

const List = styled('ul', {
  display: 'block',
  margin: '0',
  listStyle: 'none',
  width: 'auto',
  minWidth: '120px',
  padding: '0',
});

const NavItem = styled(Flex, {
  px: 10,
  py: 8,
  borderRadius: 4,
  transition: 'color 250ms',
  alignContent: 'center',
  cursor: 'pointer',
  fontSize: 13,
  position: 'relative',
  color: 'white',
  variants: {
    outlined: {
      true: {
        backgroundColor: '#EFF2FF',
        color: '$primaryColor !important',
        fontWeight: 600,
      },
    },
    shape: {
      round: {
        p: 3,
        borderRadius: 1000,
      },
    },
  },
});

const NotificationDot = styled('span', {
  position: 'absolute',
  top: 0,
  right: 0,
  width: '14px',
  height: '14px',
  borderRadius: '50%',
  backgroundColor: 'red',
  border: '2px solid white',
});

const SearchIconContainer = styled(Box, {
  position: 'absolute',
  top: '14px',
  transform: 'translateY(-50%)',
  pointerEvents: 'none',
  padding: '0 5px',
});

const NavigationDropdownMenuLink = styled(NavigationMenuLink, {
  padding: '12px 16px',
  '&:focus': {
    backgroundColor: '#1E212D',
  },
  '&:hover': {
    backgroundColor: '#1E212D',
  },
  '&[data-active]': {
    backgroundColor: '#1E212D',
  },
});

export function TopNavigation(): JSX.Element {
  const location = useLocation();
  const history = useHistory();
  const voip = useVoIP();
  const isDeveloper = location.pathname.startsWith('/developer');
  const isSettings = location.pathname.startsWith('/settings');
  const isIntegrations = location.pathname.startsWith('/integrations');
  const { hasUnreadNotifications, openNotificationsDrawer } = useUserWebNotifications();
  const { showContactsV2, enableVoip } = useFlags();

  const { channelsState } = useChannels();
  const { channels } = channelsState;

  const { preferences } = useUserPreferences();

  const { createContact } = useData();

  // states of dialogs for quick actions dropdown
  const [addContactOpenState, setAddContactOpenState] = useState(false);
  const [addUploadOpenState, setAddUploadOpenState] = useState(false);
  const [quickCampaignDialogState, setQuickCampaignDialogState] = useState(false);

  const quickCampaignDialogStateChange = () => {
    setQuickCampaignDialogState(!quickCampaignDialogState);
  };

  //Returns the user's preferred channel or a fallback default channel.
  const getChannel = (id = '') => {
    //Look for a channel in the `channels` array that matches the provided `id`
    const preferredChannel = channels?.find((c: Channel) => c.id === id);
    //If no preferred channel is found, return the first channel in the list
    return preferredChannel ?? channels[0];
  };

  const [schedule, setSchedule] = useState(false);

  const tabs = navigationData
    .tabsData()
    .filter((tab: tabDataType) => {
      // If the tab title is "Data", only include it if showContactsV2 is true
      if (tab.title === 'Data') {
        if (!isDev && !isPreview) {
          return showContactsV2;
        } else {
          return true;
        }
      }
      // Include all other tabs
      return true;
    })
    .reduce((tabs: Array<tabDataType>, tab: tabDataType) => {
      if (tab.hidden) return tabs;
      // make sure to hide dropdown items that are hidden
      if (tab.dropdown?.length) {
        tab.dropdown = tab.dropdown.filter(
          (dropdownItem: tabDataType) => !dropdownItem.hidden
        );
      }
      tabs.push(tab);
      return tabs;
    }, []);

  // opens and closes the intercom messenger
  // when the user clicks the ? icon
  let isIntercomOpen = false;
  const openIntercom = () => {
    if (isIntercomOpen) {
      window.Intercom('hide');
      isIntercomOpen = false;
    } else {
      window.Intercom('show');
      isIntercomOpen = true;
    }
  };

  // This hook is used to manage the display of a toast notification during reconnection attempts.
  const socket = useSocket();
  const { reconnectingIn, reconnectAttempts } = socket;

  useReconnectToast(reconnectAttempts, reconnectingIn);

  const { query } = useKBar();

  const isMac = navigator.userAgent.includes('Mac');
  const shortcutAction = isMac ? (
    <div style={{ display: 'flex', alignItems: 'center', gap: 3 }}>
      <PiCommand />
      {`+ K`}
    </div>
  ) : (
    'Ctrl + K'
  );

  const defaultVoIPChannel = useMemo(() => {
    const userDefaultChannel = channels?.find(
      (c: Channel) => c.id === preferences?.inbox?.preferred_location_id
    );
    if (
      !!userDefaultChannel &&
      userDefaultChannel?.type === ChannelTypes.PHONE &&
      userDefaultChannel?.provider === ProviderTypes.TWILIO &&
      !!userDefaultChannel?.provider_account_id
    ) {
      return preferences?.inbox?.preferred_location_id;
    }
    return '';
  }, [channels, preferences?.inbox?.preferred_location_id]);

  const isVoIPEnabled = useMemo(() => {
    const voIPChannels = channels?.filter(
      (c: Channel) =>
        c.provider === ProviderTypes.TWILIO &&
        c.type === ChannelTypes.PHONE &&
        !!c?.provider_account_id
    );

    return enableVoip && !!voIPChannels?.length;
  }, [enableVoip, channels]);

  return (
    <>
      <HeaderContainer data-testid="header-container">
        <Link to="/inbox/all/open">
          <WhippyLogoNew />
        </Link>
        <HeaderButtonContainer>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Flex
                align="center"
                justify="center"
                css={{
                  cursor: 'pointer',
                  background: '$primaryColor',
                  color: 'white',
                  p: 4,
                  mr: '3px',
                  borderRadius: '$round',
                }}
              >
                <HiPlus size={12} />
              </Flex>
            </DropdownMenuTrigger>
            <DropdownMenuContent sideOffset={10} css={{ zIndex: 999 }}>
              <DropdownMenuItem
                onSelect={() => history.replace({ pathname: '/inbox/all/open/new' })}
              >
                New Message
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={() => history.replace({ pathname: '/campaigns/sms/create' })}
              >
                New Campaign
              </DropdownMenuItem>
              <DropdownMenuItem onSelect={() => setQuickCampaignDialogState(true)}>
                New Quick Campaign
              </DropdownMenuItem>
              <DropdownMenuItem onSelect={() => setAddUploadOpenState(true)}>
                New Upload
              </DropdownMenuItem>
              <SequenceAudience
                preSelectedAudience={{
                  contacts: undefined,
                  dynamic_group_ids: undefined,
                }}
                setPreSelectedAudience={() => console.log()}
              >
                <DropdownMenuItem // prevent default behavior of the dropdown menu
                  onClick={(e) => e.preventDefault()}
                >
                  <Box>Add Contact to Sequence</Box>
                </DropdownMenuItem>
              </SequenceAudience>
            </DropdownMenuContent>
          </DropdownMenu>
          <Box css={{ display: 'none' }}>
            <QuickCampaignDialog
              quickCampaignDialogState={quickCampaignDialogState}
              setQuickCampaignDialogState={setQuickCampaignDialogState}
              quickCampaignDialogStateChange={quickCampaignDialogStateChange}
              showSchedule={schedule}
              toggleSchedule={() => setSchedule(!schedule)}
              checkedContacts={[]}
              setCheckedContacts={() => console.log()}
              isContactsPage={false}
              isGroupsPage={false}
              isUploadsPage={false}
              isInbox={true}
              location={getChannel(preferences?.inbox?.preferred_location_id)}
            />
            <CreateContact
              open={addContactOpenState}
              setOpen={setAddContactOpenState}
              handleCreateContact={createContact}
            />
            <CreateUpload open={addUploadOpenState} setOpen={setAddUploadOpenState} />
          </Box>
          {isVoIPEnabled && (
            <NavItem
              shape="round"
              onClick={() => setTimeout(() => voip.newCall(defaultVoIPChannel || ''), 0)}
            >
              <HiPhoneOutgoing size={12} />
            </NavItem>
          )}
          <Link to="/developer/keys">
            <NavItem outlined={isDeveloper} shape="round">
              <HiCode size={14} />
            </NavItem>
          </Link>
          <Link to="/integrations">
            <NavItem outlined={isIntegrations} shape="round">
              <HiPuzzle size={14} />
            </NavItem>
          </Link>
          <NavItem outlined={isIntercomOpen} shape="round" onClick={openIntercom}>
            <HiQuestionMarkCircle size={14} />
          </NavItem>
          <Link to="/settings/profile">
            <NavItem
              outlined={isSettings}
              shape="round"
              data-intercom-target="settings-navigation"
            >
              {' '}
              <HiCog size={14} />
            </NavItem>
          </Link>
          <NavItem shape="round" onClick={openNotificationsDrawer}>
            {hasUnreadNotifications && <NotificationDot />}
            <HiBell size={14} />
          </NavItem>
          <OrganizationUserMenu isNew />
        </HeaderButtonContainer>
      </HeaderContainer>
      <NavigationMenuContainer>
        <NavigationMenu data-testid="navigation-menu">
          <NavigationMenuList>
            {tabs.map((tab: tabDataType) => (
              <NavigationMenuItem key={tab.path}>
                {tab.dropdown?.length ? (
                  <>
                    <NavigationMenuTrigger data-testid="navigation-menu-trigger">
                      <HStack>
                        <Box>{tab.icon}</Box>
                        <Box>{tab.title}</Box>
                      </HStack>
                      <HiOutlineChevronDown size={15} />
                    </NavigationMenuTrigger>
                    <NavigationMenuContent data-testid="navigation-menu-content">
                      <List>
                        {tab.dropdown.map((dropdownItem: tabDataType) => (
                          <NavigationDropdownMenuLink
                            asChild
                            data-testid="dropdown-menu-link"
                            key={dropdownItem.path}
                          >
                            <Link to={dropdownItem.path}>
                              <HStack>
                                <Box>{dropdownItem.icon}</Box>
                                <Box style={{ color: dropdownItem.textColor }}>
                                  {dropdownItem.title}
                                </Box>
                              </HStack>
                            </Link>
                          </NavigationDropdownMenuLink>
                        ))}
                      </List>
                    </NavigationMenuContent>
                  </>
                ) : (
                  <NavigationMenuLink
                    asChild
                    data-testid="navigation-menu-link"
                    active={location.pathname.startsWith(tab.path)}
                  >
                    <Link to={tab.path}>
                      <HStack>
                        <Box>{tab.icon}</Box>
                        <Box style={{ color: tab.textColor }}>{tab.title}</Box>
                      </HStack>
                    </Link>
                  </NavigationMenuLink>
                )}
              </NavigationMenuItem>
            ))}
          </NavigationMenuList>
        </NavigationMenu>
        {navigationData.showSearch && (
          <Box
            css={{
              position: 'relative',
              width: '400px',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              backgroundColor: '#4a4e69',
              padding: 4,
              border: '1px solid #1A1E26',
              borderRadius: 4,
              '&:hover': {
                backgroundColor: '#374151',
              },
            }}
            onClick={() => {
              query.toggle();
            }}
          >
            <SearchIconContainer>
              <HiSearch color="#AFAFB3" />
            </SearchIconContainer>
            <div
              data-testid="styled-input"
              style={{
                paddingLeft: 30,
                fontWeight: 500,
                boxShadow: 'none',
                cursor: 'pointer',
                fontSize: '13px',
                color: '#AFAFB3',
              }}
            >
              Search for anything
            </div>
            <Box
              as="span"
              css={{
                position: 'absolute',
                right: 1,
                top: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <KbdStyled
                style={{
                  height: '1.3rem',
                  backgroundColor: '#242735',
                  border: '1px solid #1A1E26',
                  color: '#AFAFB3',
                }}
              >
                {shortcutAction}
              </KbdStyled>
            </Box>
          </Box>
        )}
      </NavigationMenuContainer>
    </>
  );
}
