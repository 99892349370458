import React from 'react';

import {
  brandRelationship,
  businessIdentities,
  businessRegionsOfOperation,
} from '@/settings/organization/trust/brands/lists';
import {
  FormFieldWrapper,
  helpers,
  SelectCombobox,
  TextInput,
} from '@/shared/components/forms';
import { Box, Flex } from '@/shared/ui';

export const StepFour = () => {
  return (
    <Flex css={{ width: '100%' }} direction="column">
      <Box>
        <FormFieldWrapper
          label="Business Employee Count"
          tooltip="Specify the total number of employees working for the business."
          name="business_employee_count"
        >
          <TextInput placeholder="50" />
        </FormFieldWrapper>
        <FormFieldWrapper
          label="Brand Relationship"
          tooltip="Select the type of relationship the business has with the brand"
          name="brand_relationship"
        >
          <SelectCombobox
            placeholder={helpers.displaySelectedItem}
            isDropdown={true}
            options={brandRelationship}
          />
        </FormFieldWrapper>

        <FormFieldWrapper
          label="Business Identity"
          tooltip="The business identity - Reseller or Direct Customer."
          name="business_identity"
        >
          <SelectCombobox
            placeholder={helpers.displaySelectedItem}
            isDropdown={true}
            options={businessIdentities}
          />
        </FormFieldWrapper>
        <FormFieldWrapper
          label="Business Regions of Operation"
          tooltip="The region where the business operates"
          name="business_regions_of_operation"
        >
          <SelectCombobox
            placeholder={helpers.displaySelectedItem}
            isDropdown={true}
            options={businessRegionsOfOperation}
          />
        </FormFieldWrapper>
        <FormFieldWrapper
          label="Stock Symbol"
          tooltip="The stock symbol of the company, if the company is public."
          name="stock_symbol"
        >
          <TextInput placeholder="GOOG" />
        </FormFieldWrapper>
        <FormFieldWrapper
          label="Stock Exchange"
          tooltip="The exchange where the stock is listed."
          name="stock_exchange"
        >
          <TextInput placeholder="NYSE" />
        </FormFieldWrapper>
      </Box>
    </Flex>
  );
};
