import { BrandsTypes } from '@/shared/types/trust/brands';

export const validateBrandStep = async (stepIndex: number, formData: BrandsTypes) => {
  switch (stepIndex) {
    case 0:
      return Boolean(
        formData.company_name && formData.display_name && formData.email && formData.phone
      );

    case 1:
      return Boolean(
        formData.city && formData.state && formData.postal_code && formData.street
      );
    case 2:
      return Boolean(
        formData.business_type &&
          formData.website &&
          formData.business_registration_type &&
          formData.universal_ein &&
          formData.business_use_case &&
          formData.average_business_day_call_volume
      );
    case 3:
      return Boolean(
        formData.business_identity && formData.business_regions_of_operation
      );
    case 4:
      return Boolean(
        formData.representative_job_position &&
          formData.representative_business_title &&
          formData.representative_phone &&
          formData.representative_email &&
          formData.representative_first_name &&
          formData.representative_last_name
      );
    default:
      return true;
  }
};
