import {
  Channel,
  ChannelTypes,
  MailgunAuthParams,
  ProviderTypes,
} from '@/shared/types/channels';

import { ProviderTypeID } from '../create/SelectChannelProvider';

export function calculateDefaultChannelProviderId(
  channelType: ChannelTypes | null | undefined
) {
  if (!channelType) return 'whippy-phone';
  // if type is email, set default provider to whippy-email
  if (channelType === ChannelTypes.EMAIL) {
    return 'whippy-email';
  }
  // if type is phone, set default provider to whippy-phone
  else if (channelType === ChannelTypes.PHONE) {
    return 'whippy-phone';
  }
  // if type is whatsapp, set default provider to whippy-whatsapp
  else if (channelType === ChannelTypes.WHATSAPP) {
    return 'twilio-sms';
  }
  return 'whippy-phone';
}

export function calculateChannelProviderId(channel: Channel | null): ProviderTypeID {
  if (!channel) return 'whippy-phone';
  if (!channel.provider) return calculateDefaultChannelProviderId(channel.type);
  if (channel.type == ChannelTypes.WHATSAPP) {
    return channel.provider === ProviderTypes.TWILIO ? 'twilio-sms' : 'meta-whatsapp';
  } else if (channel.type == ChannelTypes.EMAIL) {
    return isMailgunAuth(channel.provider_auth) ? 'mailgun-email' : 'whippy-email';
  } else {
    switch (channel.provider) {
      case ProviderTypes.TELNYX:
        return 'telnyx-sms';
      case ProviderTypes.TWILIO:
        return 'twilio-sms';
      case ProviderTypes.VONAGE:
        return 'vonage-sms';
      case ProviderTypes.BANDWIDTH:
        return 'bandwidth-sms';
      case ProviderTypes.MAILGUN:
        return 'mailgun-email';
      case ProviderTypes.SINCH:
        return 'sinch-sms';
      case ProviderTypes.META:
        return 'meta-whatsapp';
      default:
        return 'whippy-phone';
    }
  }
}

function isMailgunAuth(providerAuth: any): providerAuth is MailgunAuthParams {
  return (
    typeof providerAuth === 'object' &&
    providerAuth.base_domain &&
    providerAuth.api_key &&
    providerAuth.webhook_signing_key
  );
}
