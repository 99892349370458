import { Formik, FormikProps } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { HiX } from 'react-icons/hi';
import * as Yup from 'yup';

import { useAuth } from '@/auth/context/AuthProvider';
import { verticalList } from '@/settings/organization/trust/brands/lists';
import { useTenDlc } from '@/settings/organization/trust/context/TenDlcContext';
import { getProviderAccounts } from '@/shared/api/channels/accounts';
import { FormFieldWrapper, helpers, SelectCombobox } from '@/shared/components/forms';
import { useDisclosure } from '@/shared/hooks';
import { ProviderAccountItem } from '@/shared/types/channels/accounts';
import { BrandsTypes } from '@/shared/types/trust/brands';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  VStack,
} from '@/shared/ui';

type AddBrandProviderProps = {
  brand?: BrandsTypes;
  children: React.ReactNode;
};

type FormikTypes = {
  vertical: string;
  provider: string;
};

export const SendToProvider = (props: AddBrandProviderProps) => {
  const tenDlcContext = useTenDlc();
  const authContext = useAuth();
  const { createProvideBrand } = tenDlcContext;
  const { organizationId } = authContext;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [providers, setProviders] = useState<ProviderAccountItem[] | null>(null);

  const handleClose = (formik: FormikProps<FormikTypes>) => {
    formik.handleReset();
    onClose();
  };

  useEffect(() => {
    const params = {
      filter: [
        {
          column: 'id',
          comparison: '==',
          resource: 'organization',
          value: organizationId,
        },
      ],
      sort: [],
      limit: 1000,
      offset: 0,
    };

    getProviderAccounts(params).then((data) => setProviders(data));
  }, [organizationId]);

  const onSubmit = async ({ vertical, provider }: FormikTypes) => {
    const data = {
      brand_id: props?.brand?.id || '',
      provider: 'twilio',
      provider_account_id: provider,
      vertical,
    };
    createProvideBrand(data);
    onClose();
  };

  const filteredProviders = useMemo(() => {
    const providerBrands = props?.brand?.provider_brands ?? [];

    return providers?.reduce(
      (acc, provider) => {
        if (!providerBrands.includes(provider.id)) {
          acc.push({
            type: provider.name,
            value: provider.id,
          });
        }
        return acc;
      },
      [] as Array<{ type: string; value: string }>
    );
  }, [props.brand?.provider_brands, providers]);

  return (
    <Dialog open={isOpen}>
      <Box>
        <DialogTrigger asChild onClick={() => onOpen()}>
          {props.children}
        </DialogTrigger>
      </Box>
      <DialogPortal>
        <DialogOverlay css={{ zIndex: 999999 }}>
          <Formik
            initialValues={{
              vertical: props?.brand?.vertical ?? '',
              provider: '',
            }}
            validationSchema={Yup.object({
              vertical: Yup.string()
                .required('Required')
                .oneOf((verticalList || [])?.map((vertical) => vertical.value)),
              provider: Yup.string().required('Required'),
            })}
            onSubmit={async (values) => onSubmit(values)}
          >
            {(formik) => (
              <DialogContent
                onEscapeKeyDown={() => handleClose(formik)}
                onPointerDownOutside={() => handleClose(formik)}
                css={{ overflow: 'hidden', zIndex: 999999 }}
              >
                <DialogTitle variant="bold" css={{ marginBottom: 8 }}>
                  Create a Provider Brand
                </DialogTitle>
                <form onSubmit={formik.handleSubmit}>
                  <VStack gap={2}>
                    <FormFieldWrapper name="provider" label="Provider Brand">
                      <SelectCombobox
                        placeholder={helpers.displaySelectedItem}
                        isDropdown={true}
                        options={filteredProviders || []}
                      />
                    </FormFieldWrapper>
                    <FormFieldWrapper label="Choose vertical" name="vertical">
                      <SelectCombobox
                        placeholder={helpers.displaySelectedItem}
                        isDropdown={true}
                        options={verticalList}
                      />
                    </FormFieldWrapper>
                  </VStack>
                  <DialogFooter justify="end" css={{ mt: 10 }}>
                    <DialogClose asChild>
                      <Button
                        variant="grayBackground"
                        css={{ mr: 8 }}
                        onClick={() => handleClose(formik)}
                      >
                        Cancel
                      </Button>
                    </DialogClose>
                    <Button type="submit" disabled={!formik.isValid}>
                      Create
                    </Button>
                  </DialogFooter>
                  <DialogCloseIcon onClick={() => handleClose(formik)} size="2">
                    <HiX size="15px" />
                  </DialogCloseIcon>
                </form>
              </DialogContent>
            )}
          </Formik>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};
