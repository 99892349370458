/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/display-name */
import dayjs from 'dayjs';
import { debounce, DebouncedFunc } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HiClock, HiReply, HiStar, HiTemplate } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import { handleFilterChange, handleSortChange } from '@/pages/data/utils/filterActions';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import {
  default_created_by_object,
  default_message_template_object,
  default_updated_by_object,
} from '@/shared/components/filterBuilder/objects/message_template';
import { WhatsAppIcon } from '@/shared/components/Icons';
import { useResizeObserver } from '@/shared/hooks/useResizeObserver';
import { SearchFilters } from '@/shared/types/contacts';
import { FilterType, Sort } from '@/shared/types/filter';
import { Template, TemplateContentType } from '@/shared/types/templates';
import { Box, DropdownMenuItem, DropdownMenuItemWarning, Flex } from '@/shared/ui';
import { TooltipAvatar } from '@/shared/ui/TooltipAvatar';
import { Table, TableColumn } from '@/shared/v2/components/table/Table';
import { TableActionMenu } from '@/shared/v2/components/table/TableActionMenu';
import { styled } from '@/stitches.config';

import { ConfirmationDialogDescription } from '../users/UsersTable';
import { AddTemplate } from './AddTemplate';
import {
  initialTemplateState,
  ITEMS_COUNT,
  useTemplates,
} from './context/TemplatesContext';
import { sortConfig } from './filterConfig';
import { UpdateTemplate } from './UpdateTemplate';

function TemplatesTable(): JSX.Element {
  const [quickSearchValue, setQuickSearchValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);

  const templatesContext = useTemplates();
  const { templatesState, updateFilterParams, deleteTemplate } = templatesContext;
  const { templates, loading, filterParams } = templatesState;

  const containerRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const defaultTemplatePreviewWidth = 300;
  const offsetWidth = 900;
  const [editorWidth, setEditorWidth] = useState<number>(defaultTemplatePreviewWidth);

  const history = useHistory();

  useResizeObserver((resizeObserverData: ResizeObserverEntry[]) => {
    const width = resizeObserverData[0].contentRect.width - offsetWidth;
    setEditorWidth(
      width < defaultTemplatePreviewWidth ? defaultTemplatePreviewWidth : width
    );
  }, containerRef);

  useEffect(() => {
    updateFilterParams({
      ...initialTemplateState.filterParams,
    });
  }, []);

  const renderTemplateIcon = (template: Template) => {
    if (template?.is_scheduled_template) {
      return <HiClock size={16} />;
    }
    if (template?.is_whatsapp_template) {
      return <WhatsAppIcon width={16} height={16} />;
    }
    switch (template?.content_type) {
      case TemplateContentType.REVIEW_REQUEST:
        return <HiStar size={16} />;
      case TemplateContentType.REVIEW_RESPONSE:
        return <HiReply size={16} />;
      case TemplateContentType.GENERAL:
        return <HiTemplate size={16} />;
      default:
        return <HiTemplate size={16} />;
    }
  };

  const handleEditTemplate = (template: Template) => {
    if (template.is_whatsapp_template) {
      history.push(`/settings/templates/whatsapp/${template.id}/edit`);
    } else {
      return null;
    }
  };

  const columns: Array<TableColumn<Template>> = useMemo(
    () => [
      {
        Header: 'Templates',
        colWidth: '100%',
        accessor: 'name',
        Cell: (props: { row: { original: Template } }) => (
          <Flex direction="column">
            <Flex align="center">
              {renderTemplateIcon(props.row.original)}
              <TemplateTitle>{props.row.original.title}</TemplateTitle>
            </Flex>
            <TemplatePreview css={{ maxWidth: editorWidth }}>
              {props.row.original.message || 'This template contains attachments only.'}
            </TemplatePreview>
          </Flex>
        ),
      },
      {
        Header: 'Created by',
        accessor: 'created_b',
        Cell: (props: { row: { original: Template } }) => (
          <>
            {!!props.row.original.created_by && (
              <TooltipAvatar user={props.row.original.created_by} />
            )}
          </>
        ),
      },
      {
        Header: 'Updated by',
        accessor: 'updated_by',
        Cell: (props: { row: { original: Template } }) => (
          <>
            {!!props.row.original.updated_by && (
              <TooltipAvatar user={props.row.original.updated_by} />
            )}
          </>
        ),
      },
      {
        Header: 'Updated At',
        id: 'updated_at',
        accessor: 'updated_at',
        colWidth: '100%',
        Cell: (props: { row: { original: Template } }) => (
          <Flex align="center" css={{ minWidth: 125 }}>
            <Box>{dayjs(props.row.original.updated_at).format('MMM D YYYY')}</Box>
          </Flex>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (props: { row: { original: Template } }) => {
          if (!props.row.original.status) {
            if (props.row.original.is_whatsapp_template) {
              return <StatusBadge status="notSynchronized">Not Synchronized</StatusBadge>;
            }
            return <Box css={{ textAlign: 'center' }}>-</Box>;
          }
          return (
            <StatusBadge status={props.row.original.status}>
              {props.row.original.status}
            </StatusBadge>
          );
        },
      },
      {
        Header: '',
        accessor: 'id',
        Cell: (props: { row: { original: Template } }) => (
          <>
            <TableActionMenu>
              <>
                {props.row.original.is_whatsapp_template ? (
                  <DropdownMenuItem
                    data-testid="edit-template-option"
                    onClick={(e) => {
                      e.preventDefault();
                      handleEditTemplate(props.row.original);
                    }}
                  >
                    Edit Template
                  </DropdownMenuItem>
                ) : (
                  <UpdateTemplate template={props.row.original}>
                    <DropdownMenuItem
                      data-testid="edit-template-option"
                      onClick={(e) => e.preventDefault()}
                    >
                      Edit Template
                    </DropdownMenuItem>
                  </UpdateTemplate>
                )}
                <ConfirmationDialog
                  width="432px"
                  title="Delete Template?"
                  description={
                    <ConfirmationDialogDescription
                      value={props.row.original?.title ?? ''}
                      description="will be deleted."
                    />
                  }
                  onConfirm={() => deleteTemplate(props.row.original)}
                  confirmButtonTitle="Confirm"
                  cancelButtonTitle="Cancel"
                  confirmButtonVariant="redBackground"
                  cancelButtonVariant="grayBackground"
                >
                  <DropdownMenuItemWarning
                    data-testid="delete-template-option"
                    onClick={(e) => e.preventDefault()}
                  >
                    Delete Template
                  </DropdownMenuItemWarning>
                </ConfirmationDialog>
              </>
            </TableActionMenu>
          </>
        ),
      },
    ],
    [loading, editorWidth]
  );

  const handleQuickSearch = (
    debouncedUpdate: DebouncedFunc<(props: SearchFilters) => void>,
    filters: SearchFilters,
    value: string
  ) => {
    const formattedValue = value.trim();
    const quickFilters = formattedValue
      ? [
          {
            column: 'title',
            comparison: 'ilike',
            value: `%${formattedValue}%`,
            resource: 'message_template',
            or: [
              {
                column: 'message',
                comparison: 'ilike',
                value: `%${formattedValue}%`,
                resource: 'message_template',
              },
            ],
          },
        ]
      : [];
    debouncedUpdate({
      ...filters,
      searchFilter: quickFilters,
      offset: 0,
    });
  };

  const debouncedUpdate = useCallback(
    debounce((props: SearchFilters) => {
      updateFilterParams(props);
    }, 1000),
    []
  );

  return (
    <Flex direction="column" css={{ flex: '1 1 auto' }}>
      <Box css={{ paddingBottom: 24 }}>
        <CombinedFilters
          quickSearchPlaceholder="Search Templates"
          quickSearchValue={quickSearchValue}
          setQuickSearchValue={(value: string) => {
            setQuickSearchValue(value);
            handleQuickSearch(debouncedUpdate, filterParams, value);
          }}
          defaultObjects={[
            default_message_template_object,
            default_created_by_object,
            default_updated_by_object,
          ]}
          customObjects={[]}
          activeFilters={activeFilters}
          setFilters={(value: Array<FilterType>) => {
            setActiveFilters(value);
            handleFilterChange(debouncedUpdate, filterParams, value);
          }}
          sortConfig={sortConfig}
          activeSort={filterParams.sort}
          onSortUpdate={(value: Array<Sort>) =>
            handleSortChange(debouncedUpdate, filterParams, value)
          }
        />
      </Box>
      <div ref={containerRef}>
        <Table
          data={templates}
          columns={columns}
          caption="Templates Table"
          totalCount={templatesState.totalCount}
          emptyTitle="No Templates Match Search"
          setOffset={(offset) => {
            updateFilterParams({
              ...filterParams,
              offset,
            });
          }}
          isLoading={loading}
          pageSize={ITEMS_COUNT}
          empty={
            <Box>
              <Box
                css={{
                  width: 250,
                  fontSize: 16,
                  marginTop: 8,
                  marginBottom: 16,
                  fontWeight: 400,
                }}
              >
                <Box>
                  <AddTemplate />
                </Box>
              </Box>
            </Box>
          }
        />
      </div>
    </Flex>
  );
}

const TemplatePreview = styled(Box, {
  color: '#718196',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: 300,
  textOverflow: 'ellipsis',
});

const TemplateTitle = styled(Box, {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: 200,
  textOverflow: 'ellipsis',
  marginLeft: 5,
});

const StatusBadge = styled(Box, {
  padding: '4px 8px',
  borderRadius: '4px',
  fontSize: '12px',
  fontWeight: 500,
  textAlign: 'center',
  width: 'fit-content',
  '&::first-letter': {
    textTransform: 'uppercase',
  },
  variants: {
    status: {
      approved: {
        backgroundColor: 'rgba(0, 200, 83, 0.1)',
        color: '#00C853',
      },
      pending: {
        backgroundColor: 'rgba(255, 152, 0, 0.1)',
        color: '#FF9800',
      },
      rejected: {
        backgroundColor: 'rgba(244, 67, 54, 0.1)',
        color: '#F44336',
      },
      notSynchronized: {
        backgroundColor: 'rgba(97, 97, 97, 0.1)',
        color: '#616161',
      },
    },
  },
  defaultVariants: {
    status: 'pending',
  },
});

export default TemplatesTable;
