import React from 'react';

import { representativeJobPositions } from '@/settings/organization/trust/brands/lists';
import {
  CheckboxFormFieldWrapper,
  CheckboxInput,
  FormFieldWrapper,
  helpers,
  PhoneNumberInput,
  SelectCombobox,
  TextInput,
} from '@/shared/components/forms';
import { Box, Flex } from '@/shared/ui';

export const StepFive = () => {
  return (
    <Flex css={{ width: '100%' }} direction="column">
      <Box>
        <FormFieldWrapper
          label="Representative First Name"
          tooltip="The first name of the brand representative."
          name="representative_first_name"
        >
          <TextInput placeholder="John" />
        </FormFieldWrapper>
        <FormFieldWrapper
          label="Representative Last Name"
          tooltip="The  last name of the brand representative."
          name="representative_last_name"
        >
          <TextInput placeholder="Doe" />
        </FormFieldWrapper>

        <FormFieldWrapper
          label="Representative Phone Number"
          tooltip="Valid phone number of brand support contact."
          name="representative_phone"
        >
          <PhoneNumberInput placeholder="+13259390512" />
        </FormFieldWrapper>

        <FormFieldWrapper
          label="Representative Email"
          tooltip="Valid email address of brand support contact."
          name="representative_email"
        >
          <TextInput placeholder="representative@email.com" />
        </FormFieldWrapper>

        <FormFieldWrapper
          label="Representative Job Position"
          tooltip="The job position of the brand representative."
          name="representative_job_position"
        >
          <SelectCombobox
            placeholder={helpers.displaySelectedItem}
            isDropdown={true}
            options={representativeJobPositions}
          />
        </FormFieldWrapper>
        <FormFieldWrapper
          label="Representative Business Title"
          tooltip="The exact job title of the brand representative in free form."
          name="representative_business_title"
        >
          <TextInput placeholder="Marketing Coordinator" />
        </FormFieldWrapper>
        <CheckboxFormFieldWrapper
          label="Government Entity"
          tooltip="Is the entity described as a government one?"
          name="government_entity"
        >
          <CheckboxInput />
        </CheckboxFormFieldWrapper>
      </Box>
    </Flex>
  );
};
