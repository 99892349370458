import React from 'react';

import {
  helpKeywords,
  optinKeywords,
  optOutKeywords,
} from '@/settings/organization/trust/campaigns/lists';
import { FormFieldWrapper } from '@/shared/components/forms';
import { Box, Flex } from '@/shared/ui';
import { ComboboxMultiselectItem } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectItem';
import { ComboboxMultiselectTrigger } from '@/shared/v2/components/comboboxMultiselect/ComboboxMultiselectTrigger';
import { ComboboxMultiselectInput } from '@/shared/v2/components/forms/comboboxMultiselect/ComboboxMultiselectInput';

export const StepTwo = () => {
  return (
    <Flex css={{ width: '100%' }} direction="column">
      <Box>
        <FormFieldWrapper name="opt_in_keywords" label="Opt-In Keywords">
          <ComboboxMultiselectInput
            options={optinKeywords.map((el) => ({
              label: el.type || '',
              value: el.value,
            }))}
            Trigger={ComboboxMultiselectTrigger}
            Option={ComboboxMultiselectItem}
            disabled={false}
            selectAll
          />
        </FormFieldWrapper>
        <FormFieldWrapper name="opt_out_keywords" label="Opt-Out Keywords">
          <ComboboxMultiselectInput
            options={optOutKeywords.map((el) => ({
              label: el.type || '',
              value: el.value,
            }))}
            Trigger={ComboboxMultiselectTrigger}
            Option={ComboboxMultiselectItem}
            disabled={false}
            selectAll
          />
        </FormFieldWrapper>
        <FormFieldWrapper name="help_keywords" label="Help Keywords">
          <ComboboxMultiselectInput
            options={helpKeywords.map((el) => ({
              label: el.type || '',
              value: el.value,
            }))}
            Trigger={ComboboxMultiselectTrigger}
            Option={ComboboxMultiselectItem}
            disabled={false}
            selectAll
          />
        </FormFieldWrapper>
      </Box>
    </Flex>
  );
};
