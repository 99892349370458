/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { toast } from 'sonner';

import * as SequencesAPI from '@/shared/api/sequences';
import {
  KeywordTriggerType,
  LinkClickTriggerType,
  ReplyTriggerType,
  TriggerType,
} from '@/shared/components/triggers/AddTrigger';
import { KeywordAutomations } from '@/shared/components/triggers/KeywordAutomations';
import { LinkClickAutomations } from '@/shared/components/triggers/LinkClickAutomations';
import { NoReplyAutomations } from '@/shared/components/triggers/NoReplyAutomations';
import { KeywordAutomationsPreview } from '@/shared/components/triggers/previews/KeywordAutomationsPreview';
import { LinkClickAutomationsPreview } from '@/shared/components/triggers/previews/LinkClickAutomationsPreview';
import { ReplyAutomationsPreview } from '@/shared/components/triggers/previews/ReplyAutomationsPreview';
import { ReplyAutomations } from '@/shared/components/triggers/ReplyAutomations';
import { SequenceStepTrigger } from '@/shared/types/sequences';
import { TriggerTypes } from '@/shared/types/triggers';
import { Box } from '@/shared/ui';

type StepsTriggersProps = {
  /* sequence uuid */
  sequence_id: string;
  /* sequence step uuid */
  step_id: string;
  /* array of sequence step triggers */
  triggers: Array<SequenceStepTrigger>;
  /* set triggers state */
  setTriggers: React.Dispatch<React.SetStateAction<Array<SequenceStepTrigger>>>;
};

export const StepTriggers = (props: StepsTriggersProps) => {
  const { triggers, setTriggers, sequence_id, step_id } = props;

  const handleDeleteTrigger = async (trigger_id: string) => {
    const sequence_trigger_id =
      triggers?.find((trigger) => trigger.trigger.id === trigger_id)?.id || '';

    const res = await SequencesAPI.deleteSequenceStepTrigger(
      sequence_id,
      step_id,
      sequence_trigger_id
    );

    if (res.status === 204) {
      toast.success('Trigger deleted');
      return setTriggers(
        triggers?.filter((trigger) => trigger.id !== sequence_trigger_id)
      );
    }
  };

  const handleUpdateTrigger = async (trigger_id: string, trigger_params: TriggerType) => {
    const sequence_trigger_id =
      triggers?.find((trigger) => trigger.trigger.id === trigger_id)?.id || '';

    const res = await SequencesAPI.updateSequenceStepTrigger(
      sequence_id,
      step_id,
      sequence_trigger_id,
      trigger_params
    );

    if (res) {
      toast.success('Trigger updated');
      return setTriggers(
        triggers?.map((trigger) => {
          if (trigger.id === sequence_trigger_id) {
            return res;
          }
          return trigger;
        })
      );
    }
  };

  // take the array of triggers and reduce it so that the nested trigger key is the top level key
  // this is so that we can pass the triggers to the KeywordAutomations and ReplyAutomations components
  // and they can be rendered in the same way as the campaign and keyword automations
  const triggersWithNestedTriggerKey = triggers?.map((trigger: SequenceStepTrigger) => {
    return {
      ...trigger,
      ...trigger?.trigger,
    };
  });

  return (
    <Box>
      <Box>
        <KeywordAutomations
          keyword_triggers={
            triggersWithNestedTriggerKey?.filter(
              (trigger: { type: string }) => trigger?.type === TriggerTypes.KEYWORD
            ) as Array<KeywordTriggerType>
          }
          updateTrigger={handleUpdateTrigger}
          deleteTrigger={handleDeleteTrigger}
        />
      </Box>
      <Box>
        <ReplyAutomations
          reply_triggers={
            triggersWithNestedTriggerKey?.filter(
              (trigger: { type: string }) => trigger.type === TriggerTypes.REPLY
            ) as Array<ReplyTriggerType>
          }
          updateTrigger={handleUpdateTrigger}
          deleteTrigger={handleDeleteTrigger}
        />
      </Box>
      <Box>
        <LinkClickAutomations
          link_click_triggers={
            triggersWithNestedTriggerKey?.filter(
              (trigger: { type: string }) => trigger.type === TriggerTypes.LINK_CLICK
            ) as Array<LinkClickTriggerType>
          }
          updateTrigger={handleUpdateTrigger}
          deleteTrigger={handleDeleteTrigger}
        />
      </Box>
      <Box>
        <NoReplyAutomations
          no_reply_triggers={
            triggersWithNestedTriggerKey?.filter(
              (trigger: { type: string }) => trigger.type === TriggerTypes.NO_REPLY
            ) as Array<LinkClickTriggerType>
          }
          updateTrigger={handleUpdateTrigger}
          deleteTrigger={handleDeleteTrigger}
        />
      </Box>
    </Box>
  );
};

type StepsTriggersPreviewProps = {
  /* sequence uuid */
  sequence_id: string;
  /* sequence step uuid */
  step_id: string;
  /* array of sequence step triggers */
  triggers: Array<SequenceStepTrigger>;
  /* set triggers state */
  setTriggers: React.Dispatch<React.SetStateAction<Array<SequenceStepTrigger>>>;
};

export const StepTriggersPreview = (props: StepsTriggersPreviewProps) => {
  const { triggers } = props;

  // take the array of triggers and reduce it so that the nested trigger key is the top level key
  // this is so that we can pass the triggers to the KeywordAutomations and ReplyAutomations components
  // and they can be rendered in the same way as the campaign and keyword automations
  const triggersWithNestedTriggerKey = triggers?.map((trigger: SequenceStepTrigger) => {
    return {
      ...trigger,
      ...trigger?.trigger,
    };
  });

  return (
    <Box>
      <Box>
        <KeywordAutomationsPreview
          keyword_triggers={
            triggersWithNestedTriggerKey?.filter(
              (trigger: { type: string }) => trigger?.type === TriggerTypes.KEYWORD
            ) as Array<KeywordTriggerType>
          }
        />
      </Box>
      <Box>
        <ReplyAutomationsPreview
          reply_triggers={
            triggersWithNestedTriggerKey?.filter(
              (trigger: { type: string }) => trigger.type === TriggerTypes.REPLY
            ) as Array<ReplyTriggerType>
          }
        />
      </Box>
      <Box>
        <LinkClickAutomationsPreview
          link_click_triggers={
            triggersWithNestedTriggerKey?.filter(
              (trigger: { type: string }) => trigger.type === TriggerTypes.LINK_CLICK
            ) as Array<LinkClickTriggerType>
          }
        />
      </Box>
    </Box>
  );
};
