import moment from 'moment';
import React from 'react';
import { ResponsiveContainer } from 'recharts';

import { Heatmap } from '@/pages/agents/analytics/Heatmap';
import { ReportCard } from '@/pages/links/analytics/Card';
import { Chart } from '@/pages/links/analytics/Chart';
import {
  conversationsBarFilters,
  ReportData,
  ReportDataValue,
} from '@/shared/api/reports';
import { ReportsCollapsible } from '@/shared/components/reports/ReportsCollapsible';
import { ReportsTable } from '@/shared/components/reports/ReportsTable';
import { Grid } from '@/shared/ui';

export const initialDates = {
  startDate: moment().add(-4, 'week'),
  endDate: moment(),
};

const chart_config = [
  {
    dataKey: 'count',
    fill: '#3E54CF',
    name: 'Conversations',
  },
];
const opened_chart_config = [
  {
    dataKey: 'count',
    fill: '#8E4EC6',
    name: 'Conversations',
  },
];

const closed_chart_config = [
  {
    dataKey: 'count',
    fill: '#D6409F',
    name: 'Conversations',
  },
];

const reportCards = [
  {
    label: 'New Conversations',
    type: 'total',
    description:
      'The total number of new conversations created during the selected time period.',
  },
  {
    label: 'Opened Conversations',
    type: 'total',
    description:
      'The total number of opened conversations created during the selected time period.',
  },
  {
    label: 'Closed Conversations',
    type: 'total',
    description:
      'The total number of closed conversations created during the selected time period.',
  },
  {
    label: 'Inbound Conversations',
    type: 'total',
    description:
      'The total number of inbound conversations created during the selected time period.',
  },
  {
    label: 'Conversations Replied',
    type: 'total',
    description:
      'The total number of replied conversations created during the selected time period.',
  },
  {
    label: 'Median Response Time',
    type: 'total',
    skipFormatting: true,
    description: '',
  },
];

export type BarData = {
  key: string;
  count: number;
}[];

export const ConversationsAnalytics = ({
  data,
  isLoading,
  timeBucket,
  error,
}: {
  data: Array<ReportData>;
  isLoading: boolean;
  timeBucket: string;
  error: boolean;
}) => {
  const getConfig = (chatType: string) => {
    switch (chatType) {
      case 'closed_conversations':
        return closed_chart_config;
      case 'open_conversations':
        return opened_chart_config;
      default:
        return chart_config;
    }
  };
  return (
    <>
      <Grid columns="3" gap="5" data-testid="conversations-analytics">
        {reportCards.map((card) => (
          <ReportCard
            key={card.label}
            data={data}
            item={card}
            isLoading={isLoading}
            skipFormatting={card.skipFormatting}
          />
        ))}
      </Grid>
      <ReportsCollapsible title="Conversations Heatmap" description="">
        <ResponsiveContainer>
          <Heatmap error={error} data={data} loading={isLoading} />
        </ResponsiveContainer>
      </ReportsCollapsible>
      {conversationsBarFilters.map((c) => (
        <Chart
          error={error}
          time_bucket={timeBucket}
          key={c.type}
          title={c.label}
          chartConfig={getConfig(c.type)}
          data={
            data
              .find((d) => d.label === c.label)
              ?.values.map((d) => {
                return { key: String(d.y), count: Number(d.x) };
              }) ?? []
          }
          loading={isLoading}
          barSize={56}
        />
      ))}
      <ReportsCollapsible title="Median Response Time by User" description="">
        <ReportsTable
          data={
            data
              .find((d) => d.label === 'Median Response Time by User')
              ?.values.filter((result: any) => !!result.user_id) ?? []
          }
          config={[
            {
              header: 'User',
              key: 'user',
            },
            {
              header: 'Response Time',
              key: 'x_seconds',
              format: (value: ReportDataValue) => {
                return value.x;
              },
            },
          ]}
          sortable
          error={false}
          tableTitle="Median Response Time by User"
          loading={isLoading}
        />
      </ReportsCollapsible>
    </>
  );
};
