export type Domain = {
  apex_domain_id: string;
  apex_domain_name: string;
  bought_at?: string;
  bought_by_id: string;
  created_by_id: number;
  created_by_organization_id: string;
  deleted_by_id: string;
  id: string;
  inserted_at: string;
  name: string;
  expires_at?: string;
  periods_in_years?: string;
  prices?: string;
  renew?: string;
  setup_state?: DomainSetupState;
  setup_type: 'buy' | 'add' | 'none';
  status: DomainStatusType;
  links_status?: DomainStatusType;
  emails_status?: DomainStatusType;
  links_setup_state?: DomainSetupState;
  emails_setup_state?: DomainSetupState;
  updated_at: string;
  verification?: DNSRecord;
  created_by?: CreatedByUserType;
  is_domain_shared?: boolean;
  required_dns_records?: DNSRecord[];
  emails_required_dns_records?: EmailsVerification;
  emails_verification?: EmailsVerification;
  links_required_dns_records?: DNSRecord[];
  links_verification?: DNSRecord;
  setup_for: Array<DomainSetupType>;
};

export type EmailsVerification = {
  cname_params: DNSRecord;
  dkim_params: DNSRecord;
  mxa_params: DNSRecord;
  mxb_params: DNSRecord;
  spf_params: DNSRecord;
};

export type CreatedByUserType = {
  id?: number;
  name?: string;
  email?: string;
  attachment?: {
    content_type: string;
    url: string;
  };
};

export type Availability = {
  available?: boolean;
  period_in_years?: string;
  price?: number;
  error?: string;
};

export enum DomainStatusType {
  CANCELLED = 'cancelled',
  PENDING = 'pending',
  ACTIVE = 'active',
  DELETED = 'deleted',
  EXPIRED = 'expired',
}
export enum DomainSetupState {
  AWAITING_SETUP = 'awaiting_setup',
  CHECKING_RATE_LIMIT = 'checking_rate_limit',
  CHECKED_RATE_LIMIT = 'checked_rate_limit',
  CHECKING_PRICE = 'checking_price',
  CHECKED_PRICE = 'checked_price',
  PURCHASING_DOMAIN = 'purchasing_domain',
  PURCHASING_DOMAIN_PENDING = 'purchasing_domain_pending',
  PURCHASED_DOMAIN = 'purchased_domain',
  ADDING_PROJECT_DOMAIN = 'adding_project_domain',
  ADDED_PROJECT_DOMAIN = 'added_project_domain',
  SETTING_UP_DOMAIN_FOR_EMAILS = 'setting_up_domain_for_emails',
  SET_UP_DOMAIN_FOR_EMAILS = 'set_up_domain_for_emails',
  CHECKING_VERIFIED = 'checking_verified',
  CHECKED_VERIFIED = 'checked_verified',
  CHECKING_MISCONFIGURED = 'checking_misconfigured',
  CHECKED_MISCONFIGURED = 'checked_misconfigured',
  CHECKING_EMAILS_MISCONFIGURED = 'checking_emails_misconfigured',
  CHECKED_EMAILS_MISCONFIGURED = 'checked_emails_misconfigured',

  UPDATING_SUBDOMAINS_APEX_DOMAIN = 'updating_subdomains_apex_domain',
  UPDATED_SUBDOMAINS_APEX_DOMAIN = 'updated_subdomains_apex_domain',
  COMPLETED_SETUP = 'completed_setup',
}

export enum DomainSetupType {
  LINKS = 'links',
  EMAILS = 'emails',
}

export type DNSRecord = {
  type: string;
  name: string;
  value: string;
};
