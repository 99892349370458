import { ContactEvent, NoteType } from '..';
import { CustomObjectRecord } from '../data';
import { ExtendedFilterItem, FilterItem, Sort } from '../filter';
import { ContactSequence } from '../sequences';
import { Tag } from '../tags';

export enum UnsubscribePreferenceActionTypes {
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_IS_EMPTY = 'SET_IS_EMPTY',
  SET_ALL_LOCATIONS_TO_SHOW = 'SET_ALL_LOCATIONS_TO_SHOW',
  SET_ACTIVE_LOCATIONS = 'SET_ACTIVE_LOCATIONS',
  SET_PAUSED_LOCATIONS = 'SET_PAUSED_LOCATIONS',
  SET_OPTED_OUT_LOCATIONS = 'SET_OPTED_OUT_LOCATIONS',
  SET_SELECTED_LOCATIONS = 'SET_SELECTED_LOCATIONS',
  SET_SELECTED_ACTIVE_LOCATIONS = 'SET_SELECTED_ACTIVE_LOCATIONS',
  SET_SELECTED_PAUSED_LOCATIONS = 'SET_SELECTED_PAUSED_LOCATIONS',
  SET_CONFIRMED_PAUSE_DURATION = 'SET_CONFIRMED_PAUSE_DURATION',
  SET_PAUSED_PREFERENCES = 'SET_PAUSED_PREFERENCES',
}

export type Contact = {
  id: string;
  name: string | null;
  email: string | null;
  phone: string | null;
  source: string | null;
  opt_in_sms?: boolean;
  opt_in_email?: boolean;
  opt_out_of_all?: boolean;
  blocked: boolean;
  external_id: string | null;
  default_channel_id: string | null;
  language: string | null;
  birth_date: { year: number; month: number; day: number } | null;
  address: ContactAddress | null;
  inserted_at: string;
  updated_at: string;
  contact_tags?: Array<ContactTagItem> | null;
  contact_lists?: Array<ContactTagItem> | null;
  contact_events?: Array<ContactEvent> | null;
  notes?: Array<NoteType> | null;
  communication_preferences?: CommunicationPreference[] | null;
};

export type CommunicationPreference = {
  id: string;
  channel_id: string;
  contact_id: string;
  created_at: string;
  last_campaign_date?: string | null;
  opt_in: boolean;
  opt_in_date: string | null;
  opt_out_date: string | null;
  paused_until?: string | null;
  updated_at?: string | null;
};

export type ContactAddress = {
  address_line_one: string | null;
  address_line_two: string | null;
  city: string | null;
  state: string | null;
  post_code: string | null;
  country: string | null;
};

export type ContactTagItem = {
  contact_id: string;
  id: string;
  inserted_at: string;
  tag: Tag;
  tag_id: string;
  updated_at: string;
};

export enum ContactStateType {
  OPEN = 'OPEN',
  ARCHIVED = 'ARCHIVED',
}

export enum DataActionTypes {
  UPDATE_CONTACT_FILTERS = 'UPDATE_CONTACT_FILTERS',
  GET_CONTACTS = 'GET_CONTACTS',
  GET_CONTACTS_COUNT = 'GET_CONTACTS_COUNT',
  CREATE_CONTACT = 'CREATE_CONTACT',
  UPDATE_CONTACT = 'UPDATE_CONTACT',
  BLOCK_CONTACT = 'BLOCK_CONTACT',
  DELETE_CONTACT = 'DELETE_CONTACT',
  BULK_DELETE_CONTACTS = 'BULK_DELETE_CONTACTS',
  UPDATE_OBJECT_RECORD_FILTERS = 'UPDATE_OBJECT_RECORD_FILTERS',
  GET_CUSTOM_OBJECT_RECORDS = 'GET_CUSTOM_OBJECT_RECORDS',
  GET_CUSTOM_OBJECT_RECORDS_COUNT = 'GET_CUSTOM_OBJECT_RECORDS_COUNT',
}

export enum ContactActionTypes {
  SET_CURRENT = 'SET_CURRENT',
  CLEAR_CURRENT = 'CLEAR_CURRENT',
  UPDATE_CONTACTS_NOTES = 'UPDATE_CONTACTS_NOTES',
  SET_LOADING_CONTACT = 'SET_LOADING_CONTACT',
  GET_CONTACT_CAMPAIGNS = 'GET_CONTACT_CAMPAIGNS',
  SET_LOADING_CONTACT_CAMPAIGNS = 'SET_LOADING_CONTACT_CAMPAIGNS',
  SEARCH_CONTACT_CAMPAIGNS = 'SEARCH_CONTACT_CAMPAIGNS',
  GET_CONTACT_SEQUENCES = 'GET_CONTACT_SEQUENCES',
  SET_LOADING_CONTACT_SEQUENCES = 'SET_LOADING_CONTACT_SEQUENCES',
  GET_CONTACT_CONVERSATIONS = 'GET_CONTACT_CONVERSATIONS',
  SEARCH_CONTACT_CONVERSATIONS = 'SEARCH_CONTACT_CONVERSATIONS',
  SET_LOADING_CONTACT_CONVERSATIONS = 'SET_LOADING_CONTACT_CONVERSATIONS',
}

export type ContactsType = {
  data: Array<Contact>;
  isLoadMore?: boolean;
};

export type CustomObjectRecordsType = {
  data: Array<CustomObjectRecord>;
  isLoadMore?: boolean;
};

export type DataActions =
  | {
      type: DataActionTypes.GET_CONTACTS;
      payload: ContactsType;
    }
  | {
      type: DataActionTypes.GET_CONTACTS_COUNT;
      payload: { total: number };
    }
  | {
      type: DataActionTypes.CREATE_CONTACT;
      payload: Contact;
    }
  | {
      type: DataActionTypes.UPDATE_CONTACT;
      payload: Contact;
    }
  | {
      type: DataActionTypes.UPDATE_CONTACT_FILTERS;
      payload: { filters: SearchFilters; is_load_more: boolean };
    }
  | { type: DataActionTypes.BLOCK_CONTACT; payload: Contact }
  | { type: DataActionTypes.DELETE_CONTACT; payload: { id: string } }
  | { type: DataActionTypes.BULK_DELETE_CONTACTS; payload: string[] }
  | {
      type: DataActionTypes.UPDATE_OBJECT_RECORD_FILTERS;
      payload: { filters: SearchFilters; is_load_more: boolean };
    }
  | {
      type: DataActionTypes.GET_CUSTOM_OBJECT_RECORDS;
      payload: CustomObjectRecordsType;
    }
  | {
      type: DataActionTypes.GET_CUSTOM_OBJECT_RECORDS_COUNT;
      payload: { total: number };
    };

export type ContactActions =
  | { type: ContactActionTypes.SET_CURRENT; payload: any }
  | { type: ContactActionTypes.UPDATE_CONTACTS_NOTES; payload: any | null }
  | { type: ContactActionTypes.SET_LOADING_CONTACT; payload: boolean }
  | { type: ContactActionTypes.GET_CONTACT_CAMPAIGNS; payload: any }
  | { type: ContactActionTypes.SET_LOADING_CONTACT_CAMPAIGNS; payload: any }
  | { type: ContactActionTypes.SEARCH_CONTACT_CAMPAIGNS; payload: any }
  | { type: ContactActionTypes.GET_CONTACT_SEQUENCES; payload: Array<ContactSequence> }
  | { type: ContactActionTypes.SET_LOADING_CONTACT_SEQUENCES; payload: boolean }
  | { type: ContactActionTypes.CLEAR_CURRENT }
  | { type: ContactActionTypes.GET_CONTACT_CONVERSATIONS; payload: any }
  | { type: ContactActionTypes.SEARCH_CONTACT_CONVERSATIONS; payload: any }
  | { type: ContactActionTypes.SET_LOADING_CONTACT_CONVERSATIONS; payload: boolean };

export type SearchFilters = {
  filter: Array<FilterItem>;
  searchFilter: Array<FilterItem>;
  sort: Array<Sort>;
  limit: number;
  offset: number;
  defaultFilter?: FilterItem;
  defaultFilters?: Array<FilterItem>;
  extended_filter?: ExtendedFilterItem[];
};

export type DataStateType = {
  contacts: Array<Contact>;
  customObjectRecords: Array<CustomObjectRecord>;
  totalCount: number;
  loading: boolean;
  loadingCustomObjects: boolean;
  loadingMore: boolean;
  error: Error | null;
  contactsFilters: SearchFilters;
  customObjectsFilters: SearchFilters;
};
