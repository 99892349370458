import { useCallback } from 'react';

import { ConversationPreview } from '@/pages/inbox/list/ConversationPreview';
import {
  ConversationFilterTypes,
  ConversationStatusTypes,
  ConversationType,
} from '@/shared/types/conversations';
import { Box, Button, Flex, Skeleton } from '@/shared/ui';

import { useContacts } from '../../context/ContactContext';
import { AccordionValue } from '../';
import { ContactAccordion } from '../ContactAccordion';
import { ConversationsDrawer } from './ConversationsDrawer';

type ContactConversationsProps = {
  contactId: string;
  loading: boolean;
  accordionValue: AccordionValue;
  isInbox?: boolean;
  onClose?: () => void;
};

export const ContactConversations = ({
  contactId,
  loading,
  accordionValue,
  isInbox,
  onClose,
}: ContactConversationsProps) => {
  // contacts context
  const {
    contactState: {
      currentContactConversations,
      currentContactConversationsTotal,
      loadingContactConversations,
    },
  } = useContacts();

  // render conversation item function
  const renderItem = useCallback(
    (item: ConversationType, index: number) => (
      <ConversationPreview
        key={`${index}-${item?.id}-${item?.status}`}
        conversation={item}
        isActive={false}
        tab={item.status || ConversationStatusTypes.OPEN}
        filter={ConversationFilterTypes.ALL}
        searchType={'none'}
        isContactPanel
      />
    ),
    [isInbox]
  );

  return (
    <ContactAccordion
      title={'Conversations'}
      accordionValue={accordionValue}
      defaultValue={accordionValue}
    >
      {loadingContactConversations || loading ? (
        <Flex direction="column">
          {Array.from({ length: 1 }, (_: any, k: React.Key | null | undefined) => (
            <Flex align="center" key={k} css={{ py: 8, mb: 8 }}>
              <Skeleton css={{ height: 32, width: 32, borderRadius: 32, m: 0 }} />
              <Flex direction="column" css={{ flex: 1, ml: 8 }}>
                <Flex align="center" justify="between" css={{ width: '100%', mb: 2 }}>
                  <Skeleton css={{ height: 18, width: 120, m: 0 }} />
                  <Skeleton css={{ height: 18, width: 100, m: 0 }} />
                </Flex>
                <Flex align="center" justify="between" css={{ width: '100%' }}>
                  <Skeleton css={{ height: 18, width: 'calc(100% - 30px)', m: 0 }} />
                  <Skeleton css={{ height: 18, width: 22, m: 0, ml: 8 }} />
                </Flex>
              </Flex>
            </Flex>
          ))}
        </Flex>
      ) : currentContactConversationsTotal > 0 ? (
        <Flex direction="column" css={{ mx: -20 }}>
          {currentContactConversations?.slice(0, 5).map(renderItem)}
        </Flex>
      ) : (
        <Flex direction="column" align="center">
          <Box css={{ fontSize: 16, fontWeight: '700', marginBottom: 8 }}>
            No Conversations Yet
          </Box>
          <Box
            css={{ fontSize: 16, textAlign: 'center' }}
          >{`This user has no conversations \n available to display`}</Box>
        </Flex>
      )}
      {!(loading || loadingContactConversations) &&
        currentContactConversationsTotal > 5 && (
          <ConversationsDrawer
            contactId={contactId || ''}
            isInbox={isInbox}
            onClose={onClose}
          >
            <Button
              ghost
              size={1}
              css={{
                color: '#60646C',
                mt: 4,
                fontSize: 14,
                boxShadow: 'none',
                '&:focus': {
                  boxShadow: 'none',
                },
              }}
            >
              {`See All Conversations`}
            </Button>
          </ConversationsDrawer>
        )}
    </ContactAccordion>
  );
};
