import React, { useEffect, useRef, useState } from 'react';
import { HiDotsHorizontal, HiPlus } from 'react-icons/hi';
import { toast } from 'sonner';

import { languages } from '@/shared/components/editor/v2/utils';
import { CustomObject, DefaultObject } from '@/shared/types/data';
import { FilterType, FilterTypeInputType, JoinType } from '@/shared/types/filter';
import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuItemWarning,
  DropdownMenuTrigger,
  Flex,
  HStack,
  IconButton,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

import { QuickSearchFilter } from '../filters/TableFilters';
import { calculateFilterCount } from './utils/calculateFilterCount';
import { cleanFilters } from './utils/cleanFilters';
import { comparisonOperatorsMapping } from './utils/comparisonOperatorsMapping';
import { generateAllResourcesAndProperties } from './utils/generateAllResourcesAndProperties';
import { generateCustomResourcesAndProperties } from './utils/generateCustomResourcesAndProperties';
import { rebuildFilters } from './utils/rebuildFilters';
import { BirthDateValue } from './values/BirthDateValue';
import { CampaignValuesCombobox } from './values/CampaignsValuesCombobox';
import { ChannelValuesCombobox } from './values/ChannelValuesCombobox';
import { ValueCombobox } from './values/Combobox';
import { ContactListValuesCombobox } from './values/ContactListValuesCombobox';
import { ContactTagValuesCombobox } from './values/ContactTagValuesCombobox';
import { DateValue } from './values/DateValue';
import { SequenceValuesCombobox } from './values/SequenceValuesCombobox';
import { UsersValuesCombobox } from './values/UsersValuesCombobox';

const initialJoin = () => ({
  parent: '',
  parent_column: '',
  child: '',
  child_column: '',
});

const conditionTypes = [
  {
    label: 'And',
    value: 'and',
  },
  {
    label: 'Or',
    value: 'or',
  },
];

export const initialFilter: () => FilterType = () => ({
  id: Math.random().toString(36).substring(2, 9),
  resource: '',
  column: '',
  comparison: '==',
  value: '',
  type: 'text',
  and: [],
  or: [],
  on: [], // Used for join conditions
  cast: null,
});

export type PropertyType = {
  key: string;
  // this determines how we render the
  // - combobox input
  // - the comparison dropdown
  type: FilterTypeInputType;
  label: string;
  options?: Array<{ label: string; value: string | number; disabled?: boolean }>;
};

export type ResourcePropertiesMapping = {
  [key: string]: PropertyType[];
};

function getInputType(type: string): FilterTypeInputType {
  switch (type) {
    case 'number':
    case 'float':
      return 'number';
    case 'boolean':
      return 'boolean';
    case 'campaign':
      return 'campaign';
    case 'sequence':
      return 'sequence';
    case 'tag':
      return 'tag';
    case 'list':
      return 'list';
    case 'channel':
      return 'channel';
    case 'team':
      return 'team';
    case 'user':
      return 'user';
    case 'conversation_status':
      return 'conversation_status';
    case 'date':
      return 'date';
    case 'language':
      return 'language';
    case 'combobox':
      return 'combobox';
    case 'birth-date':
      return 'birth-date';
    default:
      return 'text';
  }
}

export type FilterBuilderProps = {
  customObjects: Array<CustomObject> | [];
  filters: FilterType[];
  setFilters: (filters: FilterType[]) => void;
  defaultObjects?: Array<DefaultObject> | [];
  isQuickFilter: boolean;
  setIsQuickFilter: React.Dispatch<React.SetStateAction<boolean>>;
  quickSearchPlaceholder?: string;
  quickSearchValue?: string;
  setQuickSearchValue?: (value: string) => void;
  disableAutoFocus?: boolean;
};

export const FilterBuilder = (props: FilterBuilderProps) => {
  const {
    isQuickFilter,
    setIsQuickFilter,
    defaultObjects,
    customObjects,
    filters,
    setFilters,
    disableAutoFocus,
  } = props;
  const { resources, resourcePropertiesMapping, resourcesAndPropertiesMapping } =
    generateAllResourcesAndProperties(customObjects, defaultObjects);

  const { customResources } = generateCustomResourcesAndProperties(customObjects);

  const updateFilterType = (
    currentItem: FilterType,
    value: 'and' | 'or',
    parentFilter: FilterType,
    conditionType: 'and' | 'or' | 'on' | null = null
  ) => {
    if (parentFilter && conditionType) {
      if (conditionType === 'and' || conditionType === 'or') {
        // Asserting as FilterType[] since 'and' and 'or' are arrays of FilterType
        parentFilter[conditionType] = (
          parentFilter[conditionType] as FilterType[]
        ).filter((item) => item !== currentItem);
        parentFilter[value].push(currentItem);
      }
    }
    setFilters([...filters]);
  };

  const addCondition = (
    parentFilter: FilterType | null = null,
    type: 'and' | 'or' | 'on' | 'join' | null = null
  ) => {
    const newCondition = initialFilter();
    if (parentFilter) {
      if (type === 'join') {
        (parentFilter as FilterType).on.push(initialJoin()); // Adding join directly to 'on'
      } else {
        if (type === 'and') {
          parentFilter.and.push(newCondition);
        } else if (type === 'or') {
          parentFilter.or.push(newCondition);
        }
      }
    } else {
      setFilters([...filters, newCondition]);
    }
    setFilters([...filters]);
  };

  const duplicateCondition = (
    currentItem: FilterType,
    parentFilter: FilterType | null,
    type: 'and' | 'or'
  ) => {
    if (parentFilter) {
      parentFilter[type].push({ ...duplicatedFilter(currentItem) });
      setFilters([...filters]);
    } else {
      filters[0].and.push({ ...duplicatedFilter(currentItem) });
      setFilters([...filters]);
    }
  };

  const duplicatedFilter = (currentFilter: FilterType) => {
    const filter: FilterType = {
      ...currentFilter,
      id: Math.random().toString(36).substring(2, 9),
      and: [],
      or: [],
    };
    if (currentFilter.and.length) {
      filter.and = currentFilter.and.map((filter) => duplicatedFilter(filter));
    }

    if (currentFilter.or.length) {
      filter.or = currentFilter.or.map((f) => duplicatedFilter(f));
    }

    return filter;
  };

  const updateFilterOrJoin = <T extends FilterType | JoinType>(
    item: T,
    field: keyof T,
    value: T[keyof T]
  ) => {
    // If the field being updated is 'resource', reset 'comparison' to null
    if (field === 'resource' && 'comparison' in item) {
      (item as FilterType).column = '';
      (item as FilterType).comparison = '';
      (item as FilterType).value = '';
    }
    item[field] = value;

    if (field === 'cast' && !value) {
      delete item[field];
    }

    // Automatically update 'type' when 'column' is selected
    if (field === 'column' && 'type' in item) {
      const resource = (item as FilterType).resource;
      const property = resourcePropertiesMapping[resource]?.find((p) => p.key === value);
      if (property) {
        (item as FilterType).type = property.type as FilterTypeInputType;
        (item as FilterType).comparison = '';
        (item as FilterType).value = property.type === 'text' ? null : '';
      }
    }

    setFilters([...filters]);
  };

  const removeCondition = (
    itemToRemove: FilterType | JoinType,
    parentFilter: FilterType | null = null,
    conditionType: 'and' | 'or' | 'on' | null = null
  ) => {
    if (parentFilter && conditionType) {
      if (conditionType === 'and' || conditionType === 'or') {
        // Asserting as FilterType[] since 'and' and 'or' are arrays of FilterType
        parentFilter[conditionType] = (
          parentFilter[conditionType] as FilterType[]
        ).filter((item) => item !== itemToRemove);
      } else if (conditionType === 'on') {
        // Asserting as JoinType[] since 'on' is an array of JoinType
        parentFilter[conditionType] = (parentFilter[conditionType] as JoinType[]).filter(
          (item) => item !== itemToRemove
        );
      }
    }
    setFilters([...filters]);
  };

  const addTopLevelFilter = () => {
    if (filters.length) {
      addCondition(filters[0], 'and');
    } else {
      setFilters([...filters, initialFilter()]);
    }
  };

  const removeTopLevelFilter = (filterId: string) => {
    const filter = filters.find((f) => (f.id = filterId));
    if (isQuickFilter && filter && filter.and.length) {
      setFilters([
        {
          ...filter.and[0],
          and: [...filter.and.filter((f) => f.id !== filter.and[0].id)],
        },
      ]);
    } else {
      setFilters([...filters.filter((filter) => filter.id !== filterId)]);
      setIsQuickFilter(true);
    }
  };

  const [filterInput, setFilterInput] = useState(''); // State to hold the input JSON string

  // Function to handle parsing and rebuilding the input filter
  const handleParseAndRebuildFilter = () => {
    try {
      const parsedFilters = JSON.parse(filterInput); // Parse the input JSON string
      const rebuiltFilters = rebuildFilters(customObjects, parsedFilters, defaultObjects); // Rebuild the filters
      setFilters(rebuiltFilters); // Update the state to render the rebuilt filters
    } catch (error) {
      console.error('Error parsing input:', error);
    }
  };

  const handleCopyRawFilterJSON = () => {
    const cleanedFilters = JSON.stringify(filters, null, 2);
    navigator.clipboard.writeText(cleanedFilters);
    toast.success('Copied to clipboard');
  };

  const handleCopyCleanFilterJSON = () => {
    const cleanedFilters = cleanFilters(filters);
    const cleanedFiltersString = JSON.stringify(cleanedFilters, null, 2);
    navigator.clipboard.writeText(cleanedFiltersString);
    toast.success('Copied to clipboard');
  };

  const renderJoin = (filter: FilterType) =>
    filter.on.map((join, index) => (
      <Flex key={index} css={{ paddingTop: 12, flex: 1 }}>
        <HStack css={{ flex: '1 auto' }}>
          <FilterContainer gap="2" align="center">
            <ConditionLabel>Join On</ConditionLabel>
            <ValueCombobox
              selectLabel="Join Parent"
              options={resources.map((resource) => ({
                label: resource.label,
                value: resource.key,
              }))}
              selected={{ label: join.parent, value: join.parent }}
              onSelect={(option) =>
                updateFilterOrJoin(join, 'parent', option.value as string)
              }
            />
            <ValueCombobox
              options={
                join.parent && join.parent !== 'join_custom_data'
                  ? resourcePropertiesMapping[join.parent]?.map((property) => ({
                      label: property.label,
                      value: property.key,
                    }))
                  : [{ label: 'key', value: 'key' }]
              }
              selected={{ label: join.parent_column, value: join.parent_column }}
              onSelect={(option) =>
                updateFilterOrJoin(join, 'parent_column', option.value as string)
              }
            />
            <ValueCombobox
              options={resources.map((resource) => ({
                label: resource.label,
                value: resource.key,
              }))}
              selected={{ label: join.child, value: join.child }}
              onSelect={(option) =>
                updateFilterOrJoin(join, 'child', option.value as string)
              }
            />
            <ValueCombobox
              options={
                join.child && join.child !== 'join_custom_data'
                  ? resourcePropertiesMapping[join.child]?.map((property) => ({
                      label: property.label,
                      value: property.key,
                    }))
                  : [{ label: 'key', value: 'key' }]
              }
              selected={{ label: join.child_column, value: join.child_column }}
              onSelect={(option) =>
                updateFilterOrJoin(join, 'child_column', option.value as string)
              }
            />
          </FilterContainer>
          <DropdownMenu>
            <DropdownMenuTrigger>
              <IconButton size="2">
                <HiDotsHorizontal />
              </IconButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent sideOffset={5}>
              <DropdownMenuItemWarning
                onClick={() => removeCondition(join, filter, 'on')}
              >
                Remove Join
              </DropdownMenuItemWarning>
            </DropdownMenuContent>
          </DropdownMenu>
        </HStack>
      </Flex>
    ));

  const renderValueInput = (
    filter: FilterType,
    customResources: { key: string; label: string }[],
    isQuickFilter?: boolean
  ) => {
    const selectorStyles = isQuickFilter ? { borderRightWidth: 0, borderRadius: 0 } : {};
    if (filter.comparison === 'null' || filter.comparison === '!null') {
      return null;
    } else if (filter.resource === 'join_custom_data') {
      return (
        <ValueCombobox
          isOpen={isQuickFilter}
          selectorStyles={selectorStyles}
          options={customResources.map((resource) => ({
            label: resource.label,
            value: resource.key,
          }))}
          selected={{ label: String(filter.value), value: filter.value ?? '' }}
          onSelect={(option) => updateFilterOrJoin(filter, 'value', option.value)}
          selectLabel="Select a value"
        />
      );
    } else {
      const propertyDetails = resourcePropertiesMapping[filter.resource]?.find(
        (prop) => prop.key === filter.column
      );
      const inputType = getInputType(propertyDetails?.type || 'text');
      switch (inputType) {
        case 'campaign':
          // Assuming you have a CampaignValuesCombobox component for campaign types
          return (
            <CampaignValuesCombobox
              isOpen={isQuickFilter}
              selectorStyles={selectorStyles}
              selected={{ label: String(filter.value), value: filter.value ?? '' }}
              onSelect={(option) => updateFilterOrJoin(filter, 'value', option.value)}
              selectLabel="Select a Campaign"
              searchLabel="Search for a campaign"
            />
          );
        case 'tag':
          return (
            <ContactTagValuesCombobox
              isOpen={isQuickFilter}
              selectorStyles={selectorStyles}
              selected={{ label: String(filter.value), value: filter.value ?? '' }}
              onSelect={(option) => updateFilterOrJoin(filter, 'value', option.value)}
              selectLabel="Select a tag"
              searchLabel="Search for a tag"
            />
          );
        case 'list':
          return (
            <ContactListValuesCombobox
              isOpen={isQuickFilter}
              selectorStyles={selectorStyles}
              selected={{ label: String(filter.value), value: filter.value ?? '' }}
              onSelect={(option) => updateFilterOrJoin(filter, 'value', option.value)}
              selectLabel="Select a list"
              searchLabel="Search for a list"
            />
          );
        case 'sequence':
          return (
            <SequenceValuesCombobox
              isOpen={isQuickFilter}
              selectorStyles={selectorStyles}
              selected={{ label: String(filter.value), value: filter.value ?? '' }}
              onSelect={(option) => updateFilterOrJoin(filter, 'value', option.value)}
              selectLabel="Select a sequence"
              searchLabel="Search for a sequence"
            />
          );
        case 'channel':
          return (
            <ChannelValuesCombobox
              isOpen={isQuickFilter}
              selectorStyles={selectorStyles}
              selected={{ label: String(filter.value), value: filter.value ?? '' }}
              onSelect={(option) => updateFilterOrJoin(filter, 'value', option.value)}
              selectLabel="Select a channel"
              searchLabel="Search for a channel"
            />
          );
        case 'conversation_status':
          return (
            <ValueCombobox
              isOpen={isQuickFilter}
              selectorStyles={selectorStyles}
              options={[
                { label: 'Open', value: 'open' },
                { label: 'Automated', value: 'automated' },
                { label: 'Closed', value: 'closed' },
                { label: 'Spam', value: 'spam' },
              ]}
              selected={{ label: String(filter.value), value: filter.value ?? '' }}
              onSelect={(option) => updateFilterOrJoin(filter, 'value', option.value)}
              selectLabel="Select a status"
            />
          );
        case 'boolean':
          return (
            <ValueCombobox
              isOpen={isQuickFilter}
              selectorStyles={selectorStyles}
              options={[
                { label: 'True', value: 'true' },
                { label: 'False', value: 'false' },
              ]}
              selected={{ label: String(filter.value), value: filter.value ?? '' }}
              onSelect={(option) => updateFilterOrJoin(filter, 'value', option.value)}
              selectLabel="Select a value"
            />
          );
        case 'user':
          return (
            <UsersValuesCombobox
              isOpen={isQuickFilter}
              selectorStyles={selectorStyles}
              selected={{ label: String(filter.value), value: filter.value ?? '' }}
              onSelect={(option) => updateFilterOrJoin(filter, 'value', option.value)}
              selectLabel="Select a user"
            />
          );
        case 'language':
          return (
            <ValueCombobox
              isOpen={isQuickFilter}
              selectorStyles={selectorStyles}
              options={languages.map((language) => ({
                label: language.label,
                value: language.value,
              }))}
              selected={{ label: String(filter.value), value: filter.value ?? '' }}
              onSelect={(option) => updateFilterOrJoin(filter, 'value', option.value)}
              selectLabel="Select a value"
            />
          );
        case 'combobox':
          return (
            <ValueCombobox
              isOpen={isQuickFilter}
              selectorStyles={selectorStyles}
              options={
                resourcePropertiesMapping[filter.resource].find(
                  (s) => s.key === filter.column
                )?.options ?? []
              }
              selected={{ label: String(filter.value), value: filter.value ?? '' }}
              onSelect={(option) => updateFilterOrJoin(filter, 'value', option.value)}
              selectLabel="Select a value"
            />
          );
        case 'date':
          if (Array.isArray(filter.value)) return;
          return (
            <DateValue
              selectorStyles={selectorStyles}
              isQuickFilter={isQuickFilter}
              value={filter.value ?? ''}
              onChange={(field, value) => updateFilterOrJoin(filter, field, value)}
            />
          );
        case 'birth-date':
          if (Array.isArray(filter.value)) return;
          return (
            <BirthDateValue
              selectorStyles={selectorStyles}
              value={filter.value ?? ''}
              onChange={(field, value) => updateFilterOrJoin(filter, field, value)}
            />
          );
        default: {
          if (Array.isArray(filter.value)) return;
          return (
            <SearchInput
              isQuickFilter={isQuickFilter}
              value={filter.value ?? ''}
              inputType={inputType}
              onChange={(value) => updateFilterOrJoin(filter, 'value', value)}
            />
          );
        }
      }
    }
  };

  const renderFilter = (
    filter: FilterType,
    parentFilter: FilterType | null = null,
    conditionType: 'and' | 'or' | null = null,
    conditionLabel = '',
    isEven?: boolean
  ) => {
    // Determine the property details based on the current filter's resource and column
    const propertyDetails = resourcePropertiesMapping[filter.resource]?.find(
      (prop) => prop.key === filter.column
    );
    // Determine the input type and available comparisons based on the property type
    const availableComparisons = comparisonOperatorsMapping[
      (propertyDetails?.type as keyof typeof comparisonOperatorsMapping) || 'text'
    ] || [
      { label: 'Equals', value: '==' },
      { label: 'Does not equal', value: '!=' },
    ];

    const filterType = conditionTypes.find((type) => type.label === conditionLabel);
    const isGroup = !!parentFilter && !!(filter.and.length || filter.or.length);

    return (
      <Flex
        data-testid="filter"
        key={filter.id}
        direction="column"
        css={{
          paddingTop: !parentFilter ? 1 : 12,
          borderRadius: '4px',
        }}
      >
        <VStack gap="1" align="stretch">
          <HStack align="start">
            <FilterContainer gap="2" align="center">
              {!parentFilter && <ConditionLabel>{conditionLabel}</ConditionLabel>}
              {parentFilter && (
                <ValueCombobox
                  css={{ minWidth: '70px', width: '70px', maxWidth: '70px !important' }}
                  selectLabel="Select condition type"
                  options={
                    parentFilter.id === filters[0].id && parentFilter.or.length
                      ? [conditionTypes[0], { ...conditionTypes[1], disabled: true }]
                      : conditionTypes
                  }
                  selected={filterType}
                  onSelect={(option) =>
                    updateFilterType(
                      filter,
                      option.value as 'and' | 'or',
                      parentFilter,
                      filterType?.value as 'and' | 'or'
                    )
                  }
                />
              )}
              <ValueCombobox
                selectLabel="Select a resource"
                options={resources.map((resource) => ({
                  label: resource.label,
                  value: resource.key,
                }))}
                selected={{
                  label: resources.find((resource) => resource.key === filter.resource)
                    ?.label as string,
                  value: filter.resource,
                }}
                onSelect={(option) =>
                  updateFilterOrJoin(filter, 'resource', option.value as string)
                }
              />
              <ValueCombobox
                selectLabel="Select a property"
                options={
                  filter.resource && filter.resource !== 'join_custom_data'
                    ? resourcePropertiesMapping[filter.resource]?.map((property) => ({
                        label: property.label,
                        value: property.key,
                      }))
                    : [{ label: 'key', value: 'key' }]
                }
                selected={{
                  label:
                    resourcePropertiesMapping[filter.resource]?.find(
                      (p) => p.key === filter.column
                    )?.label || 'Select a property',
                  value: filter.column,
                }}
                onSelect={(option) =>
                  updateFilterOrJoin(filter, 'column', option.value as string)
                }
              />
              <ValueCombobox
                selectLabel="Select a comparison"
                options={availableComparisons.map((op) => ({
                  label: op.label,
                  value: op.value,
                }))}
                selected={{
                  label: availableComparisons.find((op) => op.value === filter.comparison)
                    ?.label as string,
                  value: filter.comparison,
                }}
                onSelect={(option) =>
                  updateFilterOrJoin(filter, 'comparison', option.value as string)
                }
              />
              {renderValueInput(filter, customResources)}
            </FilterContainer>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <IconButton
                  css={{ marginRight: 2 }}
                  data-testid="filter-actions-trigger"
                  size="2"
                >
                  <HiDotsHorizontal />
                </IconButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                sideOffset={5}
                css={{ padding: 8, minWidth: 'max-content' }}
              >
                <DropdownMenuItem
                  data-testid="add-condition-action"
                  onClick={() => addCondition(filter, 'and')}
                >
                  Add Condition
                </DropdownMenuItem>
                <DropdownMenuItem
                  data-testid="add-join-condition-action"
                  onClick={() => addCondition(filter, 'join')}
                  disabled={filter.resource !== 'join_custom_data'}
                >
                  Add Join Condition
                </DropdownMenuItem>
                <DropdownMenuItem
                  data-testid="duplicate-condition-action"
                  onClick={() =>
                    duplicateCondition(
                      filter,
                      parentFilter,
                      filterType?.value as 'and' | 'or'
                    )
                  }
                >
                  Duplicate
                </DropdownMenuItem>
                {parentFilter && (
                  <DropdownMenuItemWarning
                    data-testid="remove-child-condition-action"
                    onClick={() => removeCondition(filter, parentFilter, conditionType)}
                  >
                    Remove
                  </DropdownMenuItemWarning>
                )}
                {!parentFilter && (
                  <DropdownMenuItemWarning
                    data-testid="remove-parent-condition-action"
                    onClick={() => removeTopLevelFilter(filter.id)}
                  >
                    Remove
                  </DropdownMenuItemWarning>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </HStack>
          <div
            style={
              filter.and.length || filter.or.length
                ? {
                    padding: isGroup ? '0 12px 12px 12px' : 0,
                    borderRadius: '4px',
                    border: isGroup ? '1px solid #00003B0D' : 'none',
                    marginTop: isGroup ? 12 : 0,
                    marginLeft: isGroup ? 80 : 0,
                    background: isGroup ? (isEven ? '#F9F9FB' : 'white') : 'transparent',
                  }
                : { padding: 0 }
            }
          >
            {filter.resource === 'join_custom_data' && <>{renderJoin(filter)}</>}
            {filter.and.map((f) =>
              renderFilter(f, filter, 'and', 'And', isGroup && isEven ? false : true)
            )}
            {filter.or.map((f) =>
              renderFilter(f, filter, 'or', 'Or', isGroup && isEven ? false : true)
            )}
          </div>
        </VStack>
      </Flex>
    );
  };

  const renderFilterQuickFilter = (
    filter: FilterType,
    parentFilter: FilterType | null = null,
    conditionType: 'and' | 'or' | null = null,
    isEven?: boolean
  ): JSX.Element => {
    // Determine the property details based on the current filter's resource and column
    const propertyDetails = resourcePropertiesMapping[filter.resource]?.find(
      (prop) => prop.key === filter.column
    );
    // Determine the input type and available comparisons based on the property type
    const availableComparisons = comparisonOperatorsMapping[
      (propertyDetails?.type as keyof typeof comparisonOperatorsMapping) || 'text'
    ] || [
      { label: 'Equals', value: '==' },
      { label: 'Does not equal', value: '!=' },
    ];

    const isGroup = !!parentFilter && !!(filter.and.length || filter.or.length);

    return (
      <>
        <HStack align="start">
          <FilterContainer align="center">
            <ValueCombobox
              isOpen={!disableAutoFocus}
              selectorStyles={{
                borderRightWidth: 0,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
              selectLabel="Select a resource"
              options={resourcesAndPropertiesMapping}
              selected={resourcesAndPropertiesMapping.find(
                (resource) =>
                  resource.resource === filter.resource &&
                  resource.column === filter.column
              )}
              onSelect={(option) => {
                const property = resourcesAndPropertiesMapping.find(
                  (r) => r.value === option.value
                );
                updateFilterOrJoin(filter, 'resource', property.resource as string);
                updateFilterOrJoin(filter, 'column', property.column as string);
              }}
            />

            {!!filter.resource && !!filter.column && (
              <ValueCombobox
                isOpen={!disableAutoFocus}
                selectorStyles={{ borderRightWidth: 0, borderRadius: 0 }}
                selectLabel="Select a comparison"
                options={availableComparisons.map((op) => ({
                  label: op.label,
                  value: op.value,
                }))}
                selected={{
                  label: availableComparisons.find((op) => op.value === filter.comparison)
                    ?.label as string,
                  value: filter.comparison,
                }}
                onSelect={(option) =>
                  updateFilterOrJoin(filter, 'comparison', option.value as string)
                }
              />
            )}

            {!!filter.column &&
              !!filter.comparison &&
              renderValueInput(filter, customResources, true)}
          </FilterContainer>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <IconButton
                css={{
                  marginLeft: 0,
                  marginRight: 2,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                variant="outline"
                data-testid="quick-filter-actions-trigger"
                size="2"
              >
                <HiDotsHorizontal />
              </IconButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              sideOffset={5}
              css={{ padding: 8, minWidth: 'max-content' }}
            >
              <DropdownMenuItem
                data-testid="convert-to-advanced-filter-action"
                onClick={() => setIsQuickFilter(false)}
              >
                Convert to advanced filter
              </DropdownMenuItem>
              {parentFilter && (
                <DropdownMenuItemWarning
                  data-testid="remove-child-condition-action"
                  onClick={() => removeCondition(filter, parentFilter, conditionType)}
                >
                  Delete quick filter
                </DropdownMenuItemWarning>
              )}
              {!parentFilter && (
                <DropdownMenuItemWarning
                  data-testid="remove-parent-condition-action"
                  onClick={() => removeTopLevelFilter(filter.id)}
                >
                  Delete quick filter
                </DropdownMenuItemWarning>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </HStack>
        {filter.resource === 'join_custom_data' && <>{renderJoin(filter)}</>}
        {filter.and.map((f) =>
          renderFilterQuickFilter(f, filter, 'and', isGroup && isEven ? false : true)
        )}
        {filter.or.map((f) =>
          renderFilterQuickFilter(f, filter, 'or', isGroup && isEven ? false : true)
        )}
      </>
    );
  };

  return (
    <Flex data-testid="filters" direction="column" justify="start" align="start">
      <VStack gap={3}>
        <Flex gap="2" wrap="wrap">
          {props.quickSearchPlaceholder && props.setQuickSearchValue && (
            <QuickSearchFilter
              quickSearchPlaceholder={props.quickSearchPlaceholder}
              quickSearchValue={props.quickSearchValue}
              setQuickSearchValue={props.setQuickSearchValue}
            />
          )}
          {isQuickFilter ? (
            <>
              {filters.length > 0 && (
                <>
                  {renderFilterQuickFilter(filters[0], null, null)}
                  <IconButton
                    variant="outline"
                    onClick={() => addTopLevelFilter()}
                    size="2"
                    css={{
                      color: '#60646C',
                    }}
                  >
                    <HiPlus />
                  </IconButton>
                </>
              )}
              {!filters.length && (
                <HStack>
                  <Button
                    data-testid="add-filter-button"
                    variant="gray"
                    onClick={() => addTopLevelFilter()}
                    css={{
                      color: '#60646C',
                      width: 'auto',
                      height: 35,
                      borderRadius: 4,
                    }}
                  >
                    <HStack>
                      <HiPlus />
                      <div>Add Filter</div>
                    </HStack>
                  </Button>
                </HStack>
              )}
            </>
          ) : (
            <Popover>
              <PopoverTrigger>
                <Button
                  css={{ position: 'relative' }}
                  data-testid="filters-popover-trigger"
                  variant="gray"
                >
                  <span>Advanced filter</span>
                  {filters.length > 0 && (
                    <Badge
                      css={{ position: 'absolute', top: '-10px', right: '-10px' }}
                      variant="darkBlue"
                      data-testid="filters-menu-badge"
                    >
                      {calculateFilterCount(filters)}
                    </Badge>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent
                sideOffset={10}
                css={{
                  width: 'auto',
                  maxWidth: 980,
                  minWidth: '528px',
                  padding: '20px 16px 20px 16px',
                  backgroundColor: 'white',
                  borderRadius: '6px',
                  zIndex: 100,
                  cursor: 'default',
                  boxShadow:
                    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;',
                }}
              >
                <VStack gap={3}>
                  {filters.length > 0 && (
                    <Box
                      css={{
                        maxWidth: '800',
                        maxHeight: 'calc(100vh - 300px)',
                        overflow: 'auto',
                      }}
                    >
                      <Box css={{ width: 'fit-content' }}>
                        {filters.map((filter) =>
                          renderFilter(filter, null, null, 'Where')
                        )}
                      </Box>
                    </Box>
                  )}
                  <HStack>
                    <Button
                      data-testid="add-filter-button"
                      ghost
                      variant="gray"
                      onClick={() => addTopLevelFilter()}
                      css={{
                        color: '#60646C',
                      }}
                    >
                      <HStack>
                        <HiPlus />
                        <div>Add Filter</div>
                      </HStack>
                    </Button>
                    <Box>
                      <DropdownMenu>
                        <DropdownMenuTrigger>
                          <IconButton
                            size="2"
                            css={{
                              color: '#60646C',
                            }}
                          >
                            <HiDotsHorizontal />
                          </IconButton>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent sideOffset={5}>
                          <DropdownMenuItem onClick={handleCopyRawFilterJSON}>
                            Copy Raw Filter JSON
                          </DropdownMenuItem>
                          <DropdownMenuItem onClick={handleCopyCleanFilterJSON}>
                            Copy Clean Filter JSON
                          </DropdownMenuItem>
                          <Dialog>
                            <DialogTrigger asChild>
                              <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                                Import Filter JSON{' '}
                              </DropdownMenuItem>
                            </DialogTrigger>
                            <DialogPortal>
                              <DialogOverlay css={{ zIndex: 99999999 }} />
                              <DialogContent
                                style={{ overflow: 'auto', zIndex: 999999999 }}
                              >
                                <DialogTitle variant="bold">
                                  Paste Filter JSON
                                </DialogTitle>
                                <VStack gap={2}>
                                  <Box>
                                    <textarea
                                      style={{
                                        width: '100%',
                                        height: '100px',
                                        border: '1px solid #ccc',
                                        padding: '5px',
                                        fontSize: '14px',
                                      }}
                                      placeholder="Paste your filter JSON here"
                                      value={filterInput}
                                      onChange={(e) => setFilterInput(e.target.value)}
                                    />
                                  </Box>
                                </VStack>
                                <DialogFooter justify="end">
                                  <DialogClose asChild>
                                    <Button variant="gray" css={{ mr: '$1' }}>
                                      Cancel
                                    </Button>
                                  </DialogClose>
                                  <DialogClose asChild>
                                    <Button
                                      variant="gray"
                                      onClick={handleParseAndRebuildFilter}
                                    >
                                      Render Filter
                                    </Button>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </DialogPortal>
                          </Dialog>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </Box>
                  </HStack>
                </VStack>
              </PopoverContent>
            </Popover>
          )}
        </Flex>
      </VStack>
    </Flex>
  );
};

export const SearchInput = ({
  onChange,
  inputType,
  value,
  isQuickFilter,
}: {
  onChange: (value: string) => void;
  inputType: string;
  value: string | number;
  isQuickFilter?: boolean;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef?.current) {
      setTimeout(() => {
        inputRef?.current?.focus();
      });
    }
  }, []);
  return (
    <Flex align="center" css={{ minWidth: 127, height: 35 }}>
      <Input
        ref={inputRef}
        css={
          isQuickFilter
            ? {
                boxShadow: 'none',
                border: '1px solid #cdced6',
                borderRadius: 0,
                borderRightWidth: 0,
                '&:focus': {
                  boxShadow: 'none',
                },
              }
            : {}
        }
        placeholder="Enter a value"
        type={inputType}
        value={value ?? ''}
        onChange={(e) => onChange(e.target.value)}
      />
    </Flex>
  );
};

export const ConditionLabel = styled(Box, {
  fontSize: 14,
  width: '70px',
  maxWidth: '70px!important',
  textAlign: 'right',
  color: '#1C2024',
});

export const FilterContainer = styled(Flex, {
  flex: '1 auto',
  '> div': {
    flex: '1 auto',
  },
});
