import React from 'react';
import { HiX } from 'react-icons/hi';

import { languages } from '@/shared/components/editor/v2/utils';
import { SingleSelect } from '@/shared/components/SingleSelect';
import {
  Button,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogTitle,
  Fieldset,
  Label,
  VStack,
} from '@/shared/ui';

interface TranslateMessageModalProps {
  onDialogClose: () => void;
  sourceLanguage: string;
  setSourceLanguage: (language: string) => void;
  translationLanguage: string;
  setTranslationLanguage: (language: string) => void;
  handleTranslateMessage: () => void;
}

export const TranslateMessageModal = ({
  onDialogClose,
  sourceLanguage,
  setSourceLanguage,
  translationLanguage,
  setTranslationLanguage,
  handleTranslateMessage,
}: TranslateMessageModalProps) => {
  const getLanguageLabel = (value: string) =>
    languages.find((language) => language.value === value)?.label;

  return (
    <DialogOverlay as="div" css={{ zIndex: 99999999 }}>
      <DialogContent
        onEscapeKeyDown={() => onDialogClose()}
        onPointerDownOutside={() => onDialogClose()}
        css={{ zIndex: 99999999 }}
      >
        <DialogTitle variant="bold">Translate Message</DialogTitle>
        <VStack gap={1}>
          <Fieldset css={{ zIndex: 99999999 }}>
            <Label>Translate Message From</Label>
            <VStack gap={1} css={{ zIndex: 99999999 }}>
              <SingleSelect
                selectItem={sourceLanguage}
                setSelectItem={setSourceLanguage}
                closeOnClick={true}
                options={languages.map((language: { label: string; value: string }) => ({
                  type: language.label,
                  value: language.value,
                }))}
                defaultPlaceholder={
                  getLanguageLabel(sourceLanguage) || 'Select source language'
                }
                isDropdown={true}
                css={{ zIndex: 99999999 }}
              />
            </VStack>
          </Fieldset>
          <Fieldset>
            <Label>Translation Message To</Label>
            <VStack gap={1}>
              <SingleSelect
                selectItem={translationLanguage}
                setSelectItem={setTranslationLanguage}
                closeOnClick={true}
                options={languages.map((language: { label: string; value: string }) => ({
                  type: language.label,
                  value: language.value,
                }))}
                defaultPlaceholder={
                  getLanguageLabel(translationLanguage) || 'Select translation language'
                }
                isDropdown={true}
                css={{ zIndex: 99999999 }}
              />
            </VStack>
          </Fieldset>
        </VStack>
        <DialogFooter justify="end" css={{ mt: 15 }}>
          <DialogClose asChild>
            <Button
              aria-label="Close"
              variant="gray"
              css={{ mr: 5 }}
              type="button"
              onClick={() => onDialogClose()}
            >
              Cancel
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button variant="send" onClick={() => handleTranslateMessage()}>
              Translated Message
            </Button>
          </DialogClose>
        </DialogFooter>
        <DialogCloseIcon onClick={() => onDialogClose()} size="2">
          <HiX size="15px" />
        </DialogCloseIcon>
      </DialogContent>
    </DialogOverlay>
  );
};
