import { useCallback, useEffect, useState } from 'react';
import { toast } from 'sonner';

import { CreateRecordingModal } from '@/shared/components/CreateRecordingModal';
import { useDisclosure } from '@/shared/hooks';
import { CallSettings as CallSettingsType } from '@/shared/types/channels';
import {
  Box,
  Button,
  ControlGroup,
  Fieldset,
  Flex,
  Input,
  Label,
  RadioGroup,
  RadioGroupIndicator,
  RadioGroupRadio as Radio,
  StyledRadioIndicator,
  Switch,
  SwitchThumb,
  Text,
  TextArea,
  VStack,
} from '@/shared/ui';
import i18next from '@/shared/utils/translation';

import { useSettings } from './context/SettingsContext';

type GreetingType = {
  content_type: 'text' | 'recording_url';
  content: string;
};

const STYLES = {
  audio: {
    container: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: '#E2E8F0',
      borderRadius: '8px',
      padding: '16px 16px',
      width: '100%',
      display: 'flex',
      backgroundColor: 'white',
      position: 'relative',
      minHeight: '88px',
    } as const,
    box: {
      flex: 1,
      background: '$gray100',
      p: '$2',
      borderRadius: '$md',
      height: '56px',
      overflow: 'hidden',
    } as const,
    element: {
      width: '100%',
      height: '56px',
      marginTop: '-8px',
    } as const,
  },
  radio: {
    label: {
      mb: 0,
      ml: '8px',
      fontSize: 12,
    } as const,
    indicator: {
      boxShadow: 'none',
      py: '4px !important',
    } as const,
  },
  voicemail: {
    textArea: {
      minHeight: '88px',
    } as const,
    characterCount: {
      color: '$gray600',
      fontSize: '75%',
      position: 'relative',
      top: '-$4',
    } as const,
    container: {
      mb: '$3',
    } as const,
  },
} as const;

const AudioPlayer = ({ src }: { src?: string }) => (
  <Box css={STYLES.audio.container}>
    <Box
      css={{
        ...STYLES.audio.box,
        ...(src ? {} : { opacity: 0.5, pointerEvents: 'none' }),
      }}
    >
      <audio
        key={src}
        controls
        style={STYLES.audio.element}
        data-testid="organization-voicemail-greeting-audio"
      >
        <source src={src || ''} type="audio/mpeg" />
        <track kind="captions" />
      </audio>
    </Box>
  </Box>
);

const MAX_TEXT_GREETING_LENGTH = 255;
const MAX_RING_TIMEOUT = 600;
const MIN_RING_TIMEOUT = 0;

const handleVoicemailGreetingTypeChange = (
  value: 'text' | 'recording_url',
  callSettings: CallSettingsType,
  setTempGreeting: React.Dispatch<React.SetStateAction<GreetingType>>
) => {
  const missedCallGreeting = callSettings?.voicemail_greetings?.find(
    (greeting) => greeting.greeting_type === 'missed_call'
  );
  setTempGreeting({
    content_type: value,
    content:
      value === missedCallGreeting?.content_type ? missedCallGreeting?.content || '' : '',
  });
};

const handleTextGreetingChange = (
  e: React.ChangeEvent<HTMLTextAreaElement>,
  setTempGreeting: React.Dispatch<React.SetStateAction<GreetingType>>
) => {
  setTempGreeting((prev) => ({ ...prev, content: e.target.value }));
};

const handleRecordingModalSave = async (
  recordingUrl: string,
  handleCallSettingsUpdate: (
    field: keyof CallSettingsType,
    value: CallSettingsType[keyof CallSettingsType]
  ) => Promise<CallSettingsType>,
  setTempGreeting: React.Dispatch<React.SetStateAction<GreetingType>>,
  onClose: () => void
) => {
  try {
    await handleCallSettingsUpdate('voicemail_greetings', [
      {
        greeting_type: 'missed_call',
        content_type: 'recording_url',
        content: recordingUrl,
      },
    ]);

    setTempGreeting({
      content_type: 'recording_url',
      content: recordingUrl,
    });

    onClose();
  } catch (error) {
    console.error('Error saving recording:', error);
  }
};

const VoicemailGreetings = ({
  callSettings,
  tempGreeting,
  setTempGreeting,
  handleCallSettingsUpdate,
  isUpdating,
}: {
  callSettings: CallSettingsType;
  tempGreeting: GreetingType;
  setTempGreeting: React.Dispatch<React.SetStateAction<GreetingType>>;
  handleCallSettingsUpdate: (
    field: keyof CallSettingsType,
    value: CallSettingsType[keyof CallSettingsType]
  ) => Promise<CallSettingsType>;
  isUpdating: boolean;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Fieldset css={{ mt: 0 }}>
      <VStack gap={3}>
        <Box>
          <Label>Voicemail Greetings</Label>
          <Text>Configure how your voicemail greeting is delivered.</Text>
        </Box>

        <RadioGroup
          value={tempGreeting.content_type}
          onValueChange={(value: 'text' | 'recording_url') =>
            handleVoicemailGreetingTypeChange(value, callSettings, setTempGreeting)
          }
        >
          <Flex gap={4}>
            <StyledRadioIndicator align="center" css={STYLES.radio.indicator}>
              <Radio value="text" data-testid="organization-call-greetings-text-radio">
                <RadioGroupIndicator />
              </Radio>
              <Label css={STYLES.radio.label}>Text</Label>
            </StyledRadioIndicator>

            <StyledRadioIndicator align="center" css={STYLES.radio.indicator}>
              <Radio
                value="recording_url"
                data-testid="organization-call-greetings-recording-radio"
              >
                <RadioGroupIndicator />
              </Radio>
              <Label css={STYLES.radio.label}>Recording</Label>
            </StyledRadioIndicator>
          </Flex>
        </RadioGroup>

        {tempGreeting.content_type === 'text' ? (
          <>
            <TextArea
              value={
                typeof tempGreeting.content === 'string' &&
                !tempGreeting.content.startsWith('http')
                  ? tempGreeting.content
                  : ''
              }
              onChange={(e) => handleTextGreetingChange(e, setTempGreeting)}
              maxLength={MAX_TEXT_GREETING_LENGTH}
              placeholder="Type your greetings message here, it will be converted to audio using our text-to-speech AI technology."
              css={STYLES.voicemail.textArea}
              rows={4}
            />
            <Flex justify="between" align="center" css={STYLES.voicemail.container}>
              <Text css={STYLES.voicemail.characterCount}>
                {`${tempGreeting.content.length}/${MAX_TEXT_GREETING_LENGTH} characters`}
              </Text>
              <Button
                onClick={() => {
                  handleCallSettingsUpdate('voicemail_greetings', [
                    {
                      greeting_type: 'missed_call',
                      ...tempGreeting,
                    },
                  ]);
                }}
                variant="primary"
                disabled={tempGreeting.content.length === 0}
              >
                Save Changes
              </Button>
            </Flex>
          </>
        ) : (
          <>
            <Box>
              <AudioPlayer
                src={
                  tempGreeting.content_type === 'recording_url' &&
                  tempGreeting.content_type ===
                    callSettings?.voicemail_greetings?.find(
                      (greeting) => greeting?.greeting_type === 'missed_call'
                    )?.content_type
                    ? tempGreeting.content
                    : undefined
                }
              />
            </Box>
            <Flex justify="end" gap={2}>
              <Button onClick={onOpen} variant="primary" disabled={isUpdating}>
                Record new Greeting
              </Button>
            </Flex>
            <CreateRecordingModal
              isOpen={isOpen}
              onClose={onClose}
              currentRecordingUrl={
                tempGreeting.content_type === 'recording_url'
                  ? tempGreeting.content
                  : undefined
              }
              onSave={(recordingUrl) =>
                handleRecordingModalSave(
                  recordingUrl,
                  handleCallSettingsUpdate,
                  setTempGreeting,
                  onClose
                )
              }
            />
          </>
        )}
      </VStack>
    </Fieldset>
  );
};

const CallSettingSwitch = ({
  label,
  checked,
  onChange,
  testId,
  description,
  children,
}: {
  label: string;
  checked?: boolean;
  onChange?: (value: boolean) => void;
  testId: string;
  description?: string;
  children?: React.ReactNode;
}) => (
  <VStack gap={2}>
    <Flex align="center" justify="between" data-testid={testId}>
      <VStack gap={1}>
        <Label>{label}</Label>
        {description && <Text>{description}</Text>}
      </VStack>
      {children || (
        <Switch checked={checked} onCheckedChange={onChange}>
          <SwitchThumb />
        </Switch>
      )}
    </Flex>
  </VStack>
);

export const CallSettings = ({ callSettings }: { callSettings: CallSettingsType }) => {
  const { updateSettings } = useSettings();

  const [tempGreeting, setTempGreeting] = useState<GreetingType>({
    content_type:
      (callSettings?.voicemail_greetings?.find(
        (greeting) => greeting?.greeting_type === 'missed_call'
      )?.content_type as 'text' | 'recording_url') || 'text',
    content:
      callSettings?.voicemail_greetings?.find(
        (greeting) => greeting?.greeting_type === 'missed_call'
      )?.content || '',
  });

  const [isUpdating, setIsUpdating] = useState(false);
  const [ringTimeoutInput, setRingTimeoutInput] = useState(
    callSettings?.ring_timeout_secs?.toString() || ''
  );

  useEffect(() => {
    const currentGreeting = callSettings?.voicemail_greetings?.find(
      (greeting) => greeting.greeting_type === 'missed_call'
    );

    if (currentGreeting) {
      setTempGreeting({
        content_type:
          (currentGreeting.content_type as 'text' | 'recording_url') || 'text',
        content: currentGreeting.content || '',
      });
    }

    if (callSettings?.ring_timeout_secs !== undefined) {
      setRingTimeoutInput(callSettings.ring_timeout_secs.toString());
    }
  }, [callSettings?.voicemail_greetings, callSettings?.ring_timeout_secs]);

  const handleCallSettingsUpdate = useCallback(
    async <T extends CallSettingsType[keyof CallSettingsType]>(
      field: keyof CallSettingsType,
      value: T
    ): Promise<CallSettingsType> => {
      setIsUpdating(true);
      try {
        const updatedSettings = {
          ...callSettings,
          [field]: value,
        };

        if (field === 'voicemail_greetings') {
          const otherGreetings =
            callSettings?.voicemail_greetings?.filter(
              (greeting) => greeting?.greeting_type !== 'missed_call'
            ) || [];

          // Safely access the array value with a type guard
          const newGreeting = (value as CallSettingsType['voicemail_greetings'])?.[0];

          updatedSettings.voicemail_greetings = [
            ...otherGreetings,
            {
              ...(newGreeting || {}),
            },
          ];
        }

        await updateSettings({
          settings: {
            call: updatedSettings,
          },
        });

        toast.success(i18next.t('organization_call_settings_update_success') as string);
        return updatedSettings;
      } catch (err) {
        console.error('Settings Update Error:', err);
        toast.error(i18next.t('organization_call_settings_update_failure') as string);
        throw err;
      } finally {
        setIsUpdating(false);
      }
    },
    [callSettings, updateSettings]
  );

  return (
    <VStack css={{ mt: '$3' }}>
      <Fieldset css={{ mb: 24 }}>
        <VStack gap={3}>
          <Box>
            <Label>Organization Call Settings</Label>
            <Text>Update call settings for your entire organization.</Text>
          </Box>
          <CallSettingSwitch
            label="Conferences"
            checked={callSettings?.conferences}
            onChange={(value) => handleCallSettingsUpdate('conferences', value)}
            testId="organization-call-conference-toggle"
          />
          <CallSettingSwitch
            label="Recordings"
            checked={callSettings?.recordings}
            onChange={(value) => handleCallSettingsUpdate('recordings', value)}
            testId="organization-call-recording-toggle"
          />
          <CallSettingSwitch
            label="Transcriptions"
            checked={callSettings?.transcriptions}
            onChange={(value) => handleCallSettingsUpdate('transcriptions', value)}
            testId="organization-call-transcription-toggle"
          />
          <CallSettingSwitch
            label="Voicemail for Missed Calls"
            checked={callSettings?.voicemail_for_missed_call}
            onChange={(value) =>
              handleCallSettingsUpdate('voicemail_for_missed_call', value)
            }
            testId="organization-call-voicemail-for-missed-call-toggle"
          />
        </VStack>
      </Fieldset>

      {callSettings?.voicemail_for_missed_call && (
        <VoicemailGreetings
          callSettings={callSettings}
          tempGreeting={tempGreeting}
          setTempGreeting={setTempGreeting}
          handleCallSettingsUpdate={handleCallSettingsUpdate}
          isUpdating={isUpdating}
        />
      )}

      <Fieldset css={{ mt: 10, mb: 50 }}>
        <VStack gap={2}>
          <Box>
            <Label>Incoming Call Ring Timeout</Label>
            <Text>Set the duration (in seconds) before a call is considered missed</Text>
          </Box>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const value = parseInt(ringTimeoutInput);
              if (
                !isNaN(value) &&
                value >= MIN_RING_TIMEOUT &&
                value <= MAX_RING_TIMEOUT
              ) {
                handleCallSettingsUpdate('ring_timeout_secs', value);
              }
            }}
            data-testid="ring-timeout-form"
          >
            <VStack gap={2}>
              <ControlGroup>
                <Input
                  id="ring_timeout_secs"
                  type="number"
                  placeholder="30"
                  name="settings.ring_timeout_secs"
                  value={ringTimeoutInput}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    const numValue = parseInt(inputValue);
                    if (inputValue === '' || (!isNaN(numValue) && numValue >= 0)) {
                      setRingTimeoutInput(inputValue);
                    }
                  }}
                  min={MIN_RING_TIMEOUT}
                  max={MAX_RING_TIMEOUT}
                  data-testid="ring-timeout-input"
                />
                <Flex
                  align="center"
                  css={{
                    fontSize: 13,
                    px: '$3',
                    backgroundColor: '$gray100',
                  }}
                >
                  Seconds
                </Flex>
              </ControlGroup>
              <Box css={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  type="submit"
                  disabled={
                    !ringTimeoutInput ||
                    isNaN(parseInt(ringTimeoutInput)) ||
                    parseInt(ringTimeoutInput) < MIN_RING_TIMEOUT ||
                    parseInt(ringTimeoutInput) > MAX_RING_TIMEOUT
                  }
                  data-testid="update-ring-timeout-button"
                >
                  Update
                </Button>
              </Box>
            </VStack>
          </form>
        </VStack>
      </Fieldset>
    </VStack>
  );
};
