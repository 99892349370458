import { AgentActions, AgentActionTypes, AgentStateType } from '@/shared/types/ai';
import { Agent } from '@/shared/types/ai/agents';
import { LLM } from '@/shared/types/ai/llms';

export const AgentReducer = (
  state: AgentStateType,
  action: AgentActions
): AgentStateType => {
  switch (action.type) {
    case AgentActionTypes.SET_LOADING:
      return { ...state, loading: action.payload };
    case AgentActionTypes.GET_LLMS:
      return { ...state, llms: action.payload, loading: false };
    case AgentActionTypes.GET_AGENTS:
      return { ...state, agents: action.payload, loading: false };
    case AgentActionTypes.CREATE_LLM:
      return {
        ...state,
        llms: {
          data: [action.payload, ...state.llms.data].filter(
            (item) => item !== null
          ) as LLM[],
          total: state.llms.total + 1,
        },
        loading: false,
      };
    case AgentActionTypes.UPDATE_LLM:
      return {
        ...state,
        llms: {
          data: state.llms.data
            .filter((llm): llm is LLM => llm !== null)
            .map((llm) => (llm.id === action.payload.id ? action.payload : llm)),
          total: state.llms.total,
        },
        loading: false,
      };
    case AgentActionTypes.DELETE_LLM:
      return {
        ...state,
        llms: {
          data: state.llms.data.filter((llm): llm is LLM => llm !== null),
          total: state.llms.total - 1,
        },
        loading: false,
      };
    case AgentActionTypes.CREATE_AGENT:
      return {
        ...state,
        agents: {
          data: [action.payload, ...state.agents.data].filter(
            (item) => item !== null
          ) as Agent[],
          total: state.agents.total + 1,
        },
        loading: false,
      };
    case AgentActionTypes.UPDATE_AGENT:
      return {
        ...state,
        agents: {
          data: state.agents.data
            .filter((agent): agent is Agent => agent !== null)
            .map((agent) => (agent.id === action.payload.id ? action.payload : agent)),
          total: state.agents.total,
        },
        loading: false,
      };
    case AgentActionTypes.DELETE_AGENT:
      return {
        ...state,
        agents: {
          data: state.agents.data.filter((agent): agent is Agent => agent !== null),
          total: state.agents.total - 1,
        },
        loading: false,
      };
    case AgentActionTypes.SET_CURRENT_AGENT:
      return { ...state, currentAgent: action.payload };
    case AgentActionTypes.SET_CURRENT_LLM:
      return { ...state, currentLLM: action.payload };
    case AgentActionTypes.UPDATE_AGENT_FILTERS:
      return {
        ...state,
        agentsFilters: action.payload.filters,
      };
    case AgentActionTypes.GET_CALLS:
      return { ...state, calls: action.payload, loading: false };
    case AgentActionTypes.UPDATE_CALL_FILTERS:
      return {
        ...state,
        callsFilters: action.payload.filters,
      };
    default:
      return state;
  }
};
