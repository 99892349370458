import { Contact, ContactAddress } from '@/shared/types/contacts';
import { FilterItem, Sort } from '@/shared/types/filter';
import { TagBulkActionType } from '@/shared/types/tags';

import { api } from '../../api';
import { CampaignSourceTuple, SequenceSourceTuple } from '../../sequences';

export const searchContacts = async (
  filter: Array<FilterItem> = [],
  sort: Array<Sort> = [],
  limit = 100,
  offset = 0,
  signal?: AbortSignal
) => {
  const results = await api.post(
    'v2/contacts/search/results',
    {
      filter,
      sort,
      limit,
      offset,
    },
    { signal }
  );

  return { data: results.data.data } as { data: Array<Contact> };
};

export const searchContactsCount = async (
  filter: Array<FilterItem> = [],
  sort: Array<Sort> = [],
  limit = 100,
  offset = 0,
  signal?: AbortSignal
) => {
  const { data } = await api.post(
    'v2/contacts/search/count',
    {
      filter,
      sort,
      limit,
      offset,
    },
    { signal }
  );
  return { total: data.total } as { total: number };
};

export const bulkUpdateTags = async (
  action: TagBulkActionType,
  tagIds: string[],
  filter: FilterItem[],
  filterSource?: CampaignSourceTuple | SequenceSourceTuple
) => {
  let selection_args = {};
  if (filterSource && filterSource[0] == 'campaign') {
    selection_args = {
      args: {
        campaign_id: filterSource[1],
      },
      selection: {
        campaign_contact_selection: {
          filter,
        },
      },
    };
  } else if (filterSource && filterSource[0] == 'sequence') {
    selection_args = {
      args: {
        sequence_id: filterSource[1],
      },
      selection: {
        step_contact_selection: {
          filter,
        },
      },
    };
  } else {
    selection_args = {
      selection: {
        contact_selection: {
          filter,
        },
      },
    };
  }
  const body = {
    ...selection_args,
    bulk_action: 'tags.' + action,
    resource: {
      //tag ids
      ids: tagIds,
    },
  };
  try {
    await api.post<{ data: { message: string } }>('/v2/bulk_actions', body);
  } catch (err) {
    console.error(err);
  }
};

export type NewContactParams = {
  email?: string | null;
  name?: string | null;
  phone?: string | null;
  blocked?: boolean | null;
  address?: ContactAddress | null;
  contact_tags?: string[] | null;
  communication_preferences?: string[] | null;
  default_channel_id?: string | null;
  external_id?: string | null;
  language?: string | null;
  birth_date?: { year: number; month: number; day: number } | null;
};

export type ContactParams = {
  id: string;
  email?: string | null;
  name?: string | null;
  phone?: string | null;
  blocked?: boolean | null;
  address?: ContactAddress | null;
  contact_tags?: string[] | null;
  communication_preferences?: string[] | null;
  default_channel_id?: string | null;
  external_id?: string | null;
  language?: string | null;
  birth_date?: { year: number; month: number; day: number } | null;
};

export const createContact = (contactParams: NewContactParams) =>
  api.post(`/v2/contacts`, contactParams).then((res) => {
    return res.data.data;
  });

export const getContact = (contactId: string) =>
  api.get(`/v2/contacts/${contactId}`).then((res) => {
    return res.data.data;
  });

export const updateContact = (contactParams: ContactParams, signal?: AbortSignal) =>
  api.put(`/v2/contacts/${contactParams.id}`, contactParams, { signal }).then((res) => {
    return res.data.data;
  });
