/* eslint-disable react-hooks/exhaustive-deps */
import { styled } from '@stitches/react';
import dayjs from 'dayjs';
import { debounce, DebouncedFunc } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { handleFilterChange, handleSortChange } from '@/pages/data/utils/filterActions';
import { setDefaultSignature } from '@/shared/api/signatures';
import * as API from '@/shared/api/users';
import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import {
  default_created_by_object,
  default_updated_by_object,
} from '@/shared/components/filterBuilder/objects/message_template';
import { default_signature_object } from '@/shared/components/filterBuilder/objects/signature';
import { Signature } from '@/shared/types';
import { SearchFilters } from '@/shared/types/contacts';
import { FilterType, Sort } from '@/shared/types/filter';
import { User } from '@/shared/types/users';
import {
  Box,
  Button,
  DropdownMenuItem,
  DropdownMenuItemWarning,
  Flex,
  Switch,
  SwitchThumb,
} from '@/shared/ui';
import { TooltipAvatar } from '@/shared/ui/TooltipAvatar';
import { Table, TableColumn } from '@/shared/v2/components/table/Table';
import { TableActionMenu } from '@/shared/v2/components/table/TableActionMenu';

import { ConfirmationDialogDescription } from '../users/UsersTable';
import { AddSignature } from './AddSignature';
import {
  initialSignaturesState,
  ITEMS_COUNT,
  useSignatures,
} from './context/SignaturesContext';
import { sortConfig } from './filterConfig';

const SignaturesTable = () => {
  const [quickSearchValue, setQuickSearchValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);
  const signaturesContext = useSignatures();
  const {
    setCurrentSignature,
    deleteSignature,
    updateDefaultSignature,
    updateFilterParams,
    signaturesState,
  } = signaturesContext;
  const { signatures, totalCount, filterParams, loading } = signaturesState;

  const authContext = useAuth();
  const { tokens } = authContext;

  const [currentUser, setCurrentUser] = useState<User | null>(null);

  useEffect(() => {
    updateFilterParams({
      ...initialSignaturesState.filterParams,
    });
  }, []);

  const onSwitchClick = async (switchState: boolean, signatureId: string) => {
    if (currentUser) {
      await setDefaultSignature(switchState ? signatureId : null, currentUser?.id);
      setCurrentUser({
        ...currentUser,
        default_signature_id: switchState ? signatureId : null,
      });
      updateDefaultSignature({
        ...currentUser,
        default_signature_id: switchState ? signatureId : null,
      });
    }
  };

  useEffect(() => {
    const getUser = async () => {
      if (tokens && tokens?.user_id) {
        const data = await API.getUser(tokens?.user_id);

        setCurrentUser(data);
      }
    };

    if (!currentUser) {
      getUser();
    }
  }, [tokens]);

  const columns: Array<TableColumn<Signature>> = useMemo(
    () => [
      {
        Header: 'Signatures',
        colWidth: '100%',
        accessor: 'body',
        Cell: (props: { row: { original: Signature } }) => (
          <Flex align="center" onClick={() => setCurrentSignature(props.row.original)}>
            <Flex direction="column">
              <Box dangerouslySetInnerHTML={{ __html: props.row.original.name }} />
              <SignatureBodyWrapper>{props.row.original.body}</SignatureBodyWrapper>
            </Flex>
          </Flex>
        ),
      },
      {
        Header: 'Created by',
        accessor: 'created_b',
        Cell: (props: { row: { original: Signature } }) => (
          <>
            {!!props.row.original.created_by && (
              <TooltipAvatar user={props.row.original.created_by} />
            )}
          </>
        ),
      },
      {
        Header: 'Updated by',
        accessor: 'updated_by',
        Cell: (props: { row: { original: Signature } }) => (
          <>
            {!!props.row.original.updated_by && (
              <TooltipAvatar user={props.row.original.updated_by} />
            )}
          </>
        ),
      },
      {
        Header: 'Created At',
        id: 'inserted_at',
        accessor: 'inserted_at',
        colWidth: '100%',
        Cell: (props: { row: { original: Signature } }) => (
          <Flex align="center" css={{ minWidth: 125 }}>
            <Box>{dayjs(props.row.original.inserted_at).format('MMM D YYYY')}</Box>
          </Flex>
        ),
      },
      {
        Header: 'Updated At',
        id: 'updated_at',
        accessor: 'updated_at',
        colWidth: '100%',
        Cell: (props: { row: { original: Signature } }) => (
          <Flex align="center" css={{ minWidth: 125 }}>
            <Box>{dayjs(props.row.original.updated_at).format('MMM D YYYY')}</Box>
          </Flex>
        ),
      },
      {
        Header: 'Default',
        accessor: 'default',
        id: 'default',
        Cell: (props: { row: { original: Signature } }) => (
          <Switch
            color="blue"
            checked={currentUser?.default_signature_id === props.row.original.id}
            onCheckedChange={(state) => onSwitchClick(state, props.row.original.id)}
          >
            <SwitchThumb />
          </Switch>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        Cell: (props: { row: { original: Signature } }) => (
          <>
            <TableActionMenu>
              <>
                <AddSignature signature={props.row.original}>
                  <DropdownMenuItem
                    data-testid="edit-signature-option"
                    onClick={(e) => e.preventDefault()}
                  >
                    Edit Signature
                  </DropdownMenuItem>
                </AddSignature>
                <ConfirmationDialog
                  width="432px"
                  title="Delete Signature?"
                  description={
                    <ConfirmationDialogDescription
                      value={props.row.original?.name ?? ''}
                      description="Signature will be deleted."
                    />
                  }
                  onConfirm={() => deleteSignature(props.row.original.id)}
                  confirmButtonTitle="Confirm"
                  cancelButtonTitle="Cancel"
                  confirmButtonVariant="redBackground"
                  cancelButtonVariant="grayBackground"
                >
                  <DropdownMenuItemWarning
                    data-testid="delete-signature-option"
                    onClick={(e) => e.preventDefault()}
                  >
                    Delete Signature
                  </DropdownMenuItemWarning>
                </ConfirmationDialog>
              </>
            </TableActionMenu>
          </>
        ),
      },
    ],
    [loading, currentUser]
  );

  const handleQuickSearch = (
    debouncedUpdate: DebouncedFunc<(props: SearchFilters) => void>,
    filters: SearchFilters,
    value: string
  ) => {
    const formattedValue = value.trim();
    const quickFilters = formattedValue
      ? [
          {
            column: 'body',
            comparison: 'ilike',
            value: `%${formattedValue}%`,
            resource: 'signature',
            or: [
              {
                column: 'name',
                comparison: 'ilike',
                value: `%${formattedValue}%`,
                resource: 'signature',
              },
            ],
          },
        ]
      : [];
    debouncedUpdate({
      ...filters,
      searchFilter: quickFilters,
      offset: 0,
    });
  };

  const debouncedUpdate = useCallback(
    debounce((props: SearchFilters) => {
      updateFilterParams(props);
    }, 1000),
    []
  );

  return (
    <Flex direction="column" css={{ flex: '1 1 auto' }}>
      <Box css={{ paddingBottom: 24 }}>
        <CombinedFilters
          quickSearchPlaceholder="Search Signatures"
          quickSearchValue={quickSearchValue}
          setQuickSearchValue={(value: string) => {
            setQuickSearchValue(value);
            handleQuickSearch(debouncedUpdate, filterParams, value);
          }}
          defaultObjects={[
            default_signature_object,
            default_created_by_object,
            default_updated_by_object,
          ]}
          customObjects={[]}
          activeFilters={activeFilters}
          setFilters={(value: Array<FilterType>) => {
            setActiveFilters(value);
            handleFilterChange(debouncedUpdate, filterParams, value);
          }}
          sortConfig={sortConfig}
          activeSort={filterParams.sort}
          onSortUpdate={(value: Array<Sort>) => {
            handleSortChange(debouncedUpdate, filterParams, value);
          }}
        />
      </Box>
      <Table
        data={signatures}
        columns={columns}
        caption="Signatures Table"
        totalCount={totalCount}
        emptyTitle="No Signatures Match Search"
        setOffset={(offset) => {
          updateFilterParams({
            ...filterParams,
            offset,
          });
        }}
        isLoading={loading}
        pageSize={ITEMS_COUNT}
        empty={
          <Box>
            <Box
              css={{
                width: 250,
                fontSize: 16,
                marginTop: 8,
                marginBottom: 16,
                fontWeight: 400,
              }}
            >
              <Box>
                <AddSignature>
                  <Button onClick={(e) => e.preventDefault()} css={{ minWidth: '120px' }}>
                    Add Signature
                  </Button>
                </AddSignature>
              </Box>
            </Box>
          </Box>
        }
      />
    </Flex>
  );
};

const SignatureBodyWrapper = styled(Box, {
  color: '#718196',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: 300,
  textOverflow: 'ellipsis',
});

export default SignaturesTable;
