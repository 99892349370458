import * as React from 'react';
import { HiX } from 'react-icons/hi';

import { useDisclosure } from '@/shared/hooks';
import {
  Box,
  Button,
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  HStack,
  Text,
} from '@/shared/ui';

import { voices } from '../utils/voices';

interface VoiceSelectionDialogProps {
  selectedVoiceId?: string;
  onVoiceSelect: (voiceId: string) => void;
  onSave: () => void;
  disabled?: boolean;
}

export const VoiceSelectionDialog: React.FC<VoiceSelectionDialogProps> = ({
  selectedVoiceId,
  onVoiceSelect,
  onSave,
  disabled = false,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // Filter out voices with provider 'play' or 'playht'
  const filteredVoices = voices.filter(
    (voice) => voice.provider !== 'play' && voice.provider !== 'playht'
  );

  const handleSave = () => {
    onSave();
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpen}>
      <DialogTrigger asChild>
        <Button
          css={{ width: '100%', mt: 10, align: 'start', mb: 12 }}
          variant="gray"
          disabled={disabled}
        >
          <HStack>
            <img
              src={voices.find((v) => v.voice_id === selectedVoiceId)?.avatar_url || ''}
              alt={
                voices.find((v) => v.voice_id === selectedVoiceId)?.voice_name ||
                'Default voice'
              }
              style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                objectFit: 'cover',
                display: selectedVoiceId ? 'block' : 'none',
              }}
            />
            <Box>
              {selectedVoiceId
                ? voices.find((v) => v.voice_id === selectedVoiceId)?.voice_name
                : 'Select a voice'}
            </Box>
          </HStack>
        </Button>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay as="div" css={{ zIndex: 1000 }}>
          <DialogContent
            css={{
              width: '90vw',
              height: '80vh',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <DialogTitle>Select a Voice</DialogTitle>
            <Box
              css={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
                gap: '20px',
                overflow: 'auto',
                flex: '1',
                pb: '80px',
              }}
            >
              {filteredVoices.map((voice) => (
                <Box
                  key={voice.voice_id}
                  css={{
                    border:
                      selectedVoiceId === voice.voice_id
                        ? '2px solid #0077cc'
                        : '1px solid #e6e6e6',
                    backgroundColor:
                      selectedVoiceId === voice.voice_id ? '#f5f5f5' : 'transparent',
                    borderRadius: '8px',
                    padding: '10px',
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: '#f0f0f0' },
                  }}
                  onClick={() => onVoiceSelect(voice.voice_id)}
                >
                  <img
                    src={voice.avatar_url}
                    alt={voice.voice_name}
                    style={{
                      display: 'block',
                      margin: '0 auto',
                      width: '80px',
                      height: '80px',
                      borderRadius: '50%',
                      objectFit: 'cover',
                    }}
                  />
                  <Text css={{ textAlign: 'center', my: '10px', fontSize: '15px' }}>
                    {voice.voice_name}
                  </Text>
                  <Text
                    css={{
                      textAlign: 'center',
                      fontSize: '12px',
                      color: 'gray',
                    }}
                  >
                    {voice.accent} • {voice.gender} • {voice.age || 'N/A'}
                  </Text>
                  <audio
                    controls
                    src={voice.preview_audio_url}
                    style={{ width: '100%', marginTop: '10px' }}
                  >
                    <track default kind="captions" src="" srcLang="en" label="Captions" />
                  </audio>
                </Box>
              ))}
            </Box>
            <HStack
              css={{
                justifyContent: 'flex-end',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                padding: '20px',
                backgroundColor: 'white',
                borderTop: '1px solid #e6e6e6',
              }}
            >
              <Button variant="gray" onClick={onClose}>
                Cancel
              </Button>
              <Button onClick={handleSave}>Save</Button>
            </HStack>
            <DialogCloseIcon size="2" onClick={onClose}>
              <HiX size="15px" />
            </DialogCloseIcon>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};
