import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';

import { usePageView } from '@/shared/hooks';
import { SettingsLayout } from '@/shared/layouts';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  Flex,
  Text,
} from '@/shared/ui';

import { useChannels } from '../channels/context/ChannelContext';
import { AddTemplate } from './AddTemplate';
import TemplatesBulkImport from './TemplatesBulkImport';
import TemplatesView from './TemplatesView';

export function Templates(): JSX.Element {
  usePageView();
  const isDesktop = useMedia('(min-width: 912px)');
  const history = useHistory();
  const [isTemplateTypeModalOpen, setIsTemplateTypeModalOpen] = useState(false);
  const [selectedTemplateType, setSelectedTemplateType] = useState<'sms' | 'whatsapp'>(
    'sms'
  );

  const { channelsState } = useChannels();
  const { allChannels } = channelsState;

  const hasWhatsAppChannel = React.useMemo(() => {
    return allChannels.some((channel) => channel.type?.toLowerCase() === 'whatsapp');
  }, [allChannels]);

  const handleAddTemplateClick = () => {
    if (hasWhatsAppChannel) {
      setIsTemplateTypeModalOpen(true);
    } else {
      (
        document.querySelector(
          '[data-intercom-target="add-template-button"]'
        ) as HTMLElement
      )?.click();
    }
  };

  const handleTemplateTypeSelect = (type: 'sms' | 'whatsapp') => {
    setSelectedTemplateType(type);
  };

  const handleNextClick = () => {
    setIsTemplateTypeModalOpen(false);
    if (selectedTemplateType === 'whatsapp') {
      history.push('/settings/templates/whatsapp/new');
    } else {
      // Open the regular AddTemplate modal
      (
        document.querySelector(
          '[data-intercom-target="add-template-button"]'
        ) as HTMLElement
      )?.click();
    }
  };

  return (
    <>
      <Helmet>
        <title>Whippy | Message Templates</title>
      </Helmet>
      <SettingsLayout
        background="#fafafa"
        width="100%"
        breadcrumbs={[
          { title: 'Settings', path: '/settings/templates' },
          { title: 'Message Templates', path: `/settings/templates` },
        ]}
        actions={
          <Flex gap={2}>
            {isDesktop && (
              <TemplatesBulkImport>
                <Button variant="gray" type="button">
                  Import Templates
                </Button>
              </TemplatesBulkImport>
            )}
            <Button onClick={handleAddTemplateClick}>Add Template</Button>
          </Flex>
        }
      >
        <TemplatesView />
      </SettingsLayout>

      <AddTemplate />

      {/* Template Type Selection Modal */}
      <Dialog open={isTemplateTypeModalOpen} onOpenChange={setIsTemplateTypeModalOpen}>
        <DialogPortal>
          <DialogOverlay>
            <DialogContent>
              <DialogTitle variant="bold">Select Template Type</DialogTitle>
              <Text css={{ mb: '$4' }}>
                Configure your message template type eg. SMS, WhatsApp etc.
              </Text>
              <Box>
                <Text css={{ mb: '$3', fontWeight: 500 }}>Template type:</Text>
                <Flex direction="column" gap={3}>
                  <Flex align="center">
                    <input
                      type="radio"
                      id="sms-option"
                      name="template-type"
                      checked={selectedTemplateType === 'sms'}
                      onChange={() => handleTemplateTypeSelect('sms')}
                      style={{ marginRight: '12px' }}
                    />
                    <label htmlFor="sms-option">SMS</label>
                  </Flex>
                  <Flex align="center">
                    <input
                      type="radio"
                      id="whatsapp-option"
                      name="template-type"
                      checked={selectedTemplateType === 'whatsapp'}
                      onChange={() => handleTemplateTypeSelect('whatsapp')}
                      style={{ marginRight: '12px' }}
                    />
                    <label htmlFor="whatsapp-option">WhatsApp</label>
                  </Flex>
                </Flex>
              </Box>
              <Flex justify="end" gap={2}>
                <DialogClose asChild>
                  <Button variant="gray">Cancel</Button>
                </DialogClose>
                <Button onClick={handleNextClick}>Next</Button>
              </Flex>
            </DialogContent>
          </DialogOverlay>
        </DialogPortal>
      </Dialog>
    </>
  );
}
