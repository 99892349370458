import { ErrorBoundary, Scope } from '@sentry/react';
import { CSS, styled } from '@stitches/react';
import React from 'react';
import { HiOutlineExclamation } from 'react-icons/hi';

import { Button, Text, VStack } from '@/shared/ui';

// Fallback Component to be rendered in case of an error
const ErrorFallback = ({
  feature,
  reload,
  css,
}: {
  feature: string;
  css?: CSS;
  reload?: boolean;
}) => (
  <ErrorContainer css={css}>
    {reload ? (
      <VStack gap="3" align="center">
        <Text variant="bold" size="3">
          {`Something went wrong with ${feature}`}
        </Text>
        <Text size="2">
          Our team has been notified, click Reload to try again or contact support if the
          issue persists.
        </Text>
        <Button variant="gray" size="1" onClick={() => window.location.reload()}>
          Reload
        </Button>
      </VStack>
    ) : (
      <>
        <HiOutlineExclamation color="red" size={24} />
        <Text css={{ pt: 10 }}>{`There is an error with showing the ${feature}.`}</Text>
      </>
    )}
  </ErrorContainer>
);

type ErrorBoundaryProps = {
  feature: string;
  children: React.ReactNode;
  css?: CSS;
  reload?: boolean;
};

export const ContactErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  feature,
  children,
  css,
  reload,
}) => {
  // need to have a feature signal
  if (!feature || feature.length < 1) return null;
  // need to have children to render
  if (!children) return null;

  return (
    <ErrorBoundary
      fallback={<ErrorFallback feature={feature} reload={reload} css={css} />}
      beforeCapture={(scope: Scope) => {
        scope.setTag('ErrorLocation', feature);
      }}
      showDialog={false}
    >
      {children}
    </ErrorBoundary>
  );
};

const ErrorContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 30,
  textAlign: 'center',
  width: '100%',
  height: '100%',
  borderBottom: '1px solid $colors$slate6',
});
