import React from 'react';

import { FormFieldWrapper, TextAreaInput } from '@/shared/components/forms';
import { Box, Flex } from '@/shared/ui';

export const StepThree = () => {
  return (
    <Flex css={{ width: '100%' }} direction="column">
      <Box>
        <FormFieldWrapper name="help_message" label="Help Message">
          <TextAreaInput
            name="optInKeywords"
            placeholder="E.g. The Campaign is regarding X, Y and Z. To unsubscribe send STOP!"
          />
        </FormFieldWrapper>
        <FormFieldWrapper name="opt_in_message" label="Opt-In Message">
          <TextAreaInput
            name="optOutKeywords"
            placeholder="E.g. You have subscribed to receive campaign messages."
          />
        </FormFieldWrapper>
        <FormFieldWrapper name="opt_out_message" label="Opt-Out Message">
          <TextAreaInput
            name="helpKeywords"
            placeholder="E.g. You have unsubscribed from receiving campaign messages."
          />
        </FormFieldWrapper>
      </Box>
    </Flex>
  );
};
