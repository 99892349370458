import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { SequenceSubNavigationItem } from '@/sequences/templates/SequenceTemplateContainer';
import { StepsContainer } from '@/settings/organization/trust/brands';
import { TrustLayout } from '@/settings/organization/trust/TrustLayout';
import { SettingsLayout } from '@/shared/layouts';
import { CampaignData } from '@/shared/types/trust/campaigns';
import { Box, Button, DropdownMenuItem, Flex, HStack } from '@/shared/ui';
import { Table, TableColumn } from '@/shared/v2/components/table/Table';
import { TableActionMenu } from '@/shared/v2/components/table/TableActionMenu';
import { styled } from '@/stitches.config';

import { PAGE_SIZE, useTenDlc } from '../context/TenDlcContext';

export const TenDlcCampaignsList = () => {
  const tenDlcContext = useTenDlc();
  const { tenDlcState, getAllCampaigns } = tenDlcContext;
  const { campaigns, loading } = tenDlcState;
  const history = useHistory();

  useEffect(() => {
    getAllCampaigns();
  }, []);

  const columns: Array<TableColumn<CampaignData>> = useMemo(
    () => [
      {
        Header: 'Campaign Description',
        colWidth: '100%',
        accessor: 'description',
        Cell: (props: { row: { original: CampaignData } }) => (
          <Flex direction="row" align="center">
            <Flex direction="column">
              <Box>{props?.row?.original?.description || ''}</Box>
            </Flex>
          </Flex>
        ),
      },
      {
        Header: 'Use Case',
        accessor: 'use_case',
        Cell: (props: { row: { original: CampaignData } }) => (
          <HStack css={{ minWidth: 100 }} gap="2">
            <Box>{props?.row?.original?.use_case || ''}</Box>
          </HStack>
        ),
      },
      {
        Header: 'Sub Use Cases',
        accessor: 'sub_use_cases',
        Cell: (props: { row: { original: CampaignData } }) => (
          <HStack css={{ minWidth: 100 }} gap="2">
            {props?.row?.original?.sub_use_cases &&
              props?.row?.original?.sub_use_cases?.map((el, idx) => (
                <Box key={idx}>{el}</Box>
              ))}
          </HStack>
        ),
      },
      {
        Header: '',
        accessor: 'id',
        Cell: (props: { row: { original: CampaignData } }) => (
          <>
            <TableActionMenu width={200}>
              <>
                <DropdownMenuItem
                  onClick={() =>
                    navigateToEditCampaignPage(
                      props?.row?.original?.id,
                      props?.row?.original?.brand_id
                    )
                  }
                >
                  Edit Campaign
                </DropdownMenuItem>
                <></>
              </>
            </TableActionMenu>
          </>
        ),
      },
    ],
    []
  );

  const currentBreadcrumbs = [
    { title: 'Settings', path: '/settings/trust/campaigns' },
    { title: 'Trust Center', path: `/settings/trust/campaigns` },
    { title: 'Campaigns', path: `/settings/trust/campaigns` },
  ];

  const navigateToCreatePage = () => {
    history.push('/settings/trust/campaigns/create');
  };

  const navigateToEditCampaignPage = (campaignId: string, brandId: string) => {
    history.push({
      pathname: `/settings/trust/campaigns/${campaignId}`,
      state: { brandId },
    });
  };

  return (
    <TrustLayout>
      <SettingsLayout
        background="white"
        padding="0"
        width="100%"
        breadcrumbs={currentBreadcrumbs}
        actions={
          <HStack>
            <Button
              size="2"
              onClick={navigateToCreatePage}
              data-testid="create-campaign-btn"
            >
              Create Campaign
            </Button>
          </HStack>
        }
      >
        <Box>
          <TabsContainer align="center">
            <HStack>
              <SequenceSubNavigationItem
                selected={false}
                onClick={() => history.push('/settings/trust/brands')}
              >
                Brands
              </SequenceSubNavigationItem>
            </HStack>
            <SequenceSubNavigationItem selected={true} css={{ ml: 5 }}>
              Campaigns
            </SequenceSubNavigationItem>
          </TabsContainer>
        </Box>
        <StepsContainer
          direction="column"
          css={{
            padding: 24,
            height: 'calc(100vh - 205px)',
          }}
        >
          {campaigns && (
            <Table
              data={campaigns}
              columns={columns}
              caption="Campaigns Table"
              totalCount={campaigns?.length}
              pageSize={PAGE_SIZE}
              emptyTitle="No campaigns have been created."
              isLoading={loading}
              colHeight={43}
              setOffset={(offset) => offset}
            />
          )}
        </StepsContainer>
      </SettingsLayout>
    </TrustLayout>
  );
};

export const TabsContainer = styled(Flex, {
  width: '100%',
  height: 60,
  px: 20,
  borderBottom: 'thin solid $gray4',
  backgroundColor: 'white',
});
