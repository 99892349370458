import { useEffect, useState } from 'react';

import { useSettings } from '@/pages/settings/organization/general/context/SettingsContext';
import { keyframes } from '@/stitches.config';

import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  Flex,
} from '../ui';
import { directUpload } from './attachments';
import { FileUpload } from './editor/v2/constants';
import { Spinner } from './Icons';

// Add blinking animation keyframe
const blink = keyframes({
  '0%': { opacity: 1 },
  '50%': { opacity: 0.5 },
  '100%': { opacity: 1 },
});

// Extract styles as constants like in CallSettings
const dialogContentStyle = {
  width: '600px',
  maxWidth: '90vw',
  p: '$6',
  borderRadius: '$3',
} as const;

const dialogTitleStyle = {
  fontSize: '$lg',
  fontWeight: 600,
  mb: '$6',
} as const;

const recordingContainerStyle = {
  backgroundColor: '$gray2',
  p: '$4',
  borderRadius: '$2',
  gap: '$6',
} as const;

const audioBoxStyle = {
  flex: 1,
  maxWidth: '350px',
} as const;

const audioElementStyle = {
  width: '100%',
  height: '40px',
} as const;

const footerStyle = {
  mt: '$6',
  gap: '$3',
} as const;

const AUDIO_SETTINGS = {
  channelCount: 1,
  sampleRate: 44100,
  sampleSize: 16,
  echoCancellation: true,
  noiseSuppression: true,
} as const;

const AUDIO_MIME_TYPE = 'audio/webm;codecs=opus';

type Props = {
  /**
   * Flag that shows/hides the modal dialog
   */
  isOpen: boolean;
  /**
   * Function that handles the closing of the modal dialog
   */
  onClose: () => void;
  /**
   * Function that handles saving the recording
   */
  onSave: (recordingUrl: string) => void;
  /**
   * Current recording URL
   */
  currentRecordingUrl?: string;
};

const convertToWav = async (audioBlob: Blob): Promise<Blob> => {
  const arrayBuffer = await audioBlob.arrayBuffer();
  const audioContext = new AudioContext();
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

  // Helper function used only in this context
  const writeString = (view: DataView, offset: number, string: string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };

  // Create WAV file
  const numOfChannels = 1; // Mono
  const sampleRate = audioBuffer.sampleRate;
  const bitsPerSample = 16;
  const bytesPerSample = bitsPerSample / 8;
  const blockAlign = numOfChannels * bytesPerSample;
  const byteRate = sampleRate * blockAlign;
  const dataSize = audioBuffer.length * blockAlign;

  // WAV header
  const buffer = new ArrayBuffer(44 + dataSize);
  const view = new DataView(buffer);

  // Write WAV header
  writeString(view, 0, 'RIFF');
  view.setUint32(4, 36 + dataSize, true);
  writeString(view, 8, 'WAVE');
  writeString(view, 12, 'fmt ');
  view.setUint32(16, 16, true);
  view.setUint16(20, 1, true); // PCM
  view.setUint16(22, numOfChannels, true);
  view.setUint32(24, sampleRate, true);
  view.setUint32(28, byteRate, true);
  view.setUint16(32, blockAlign, true);
  view.setUint16(34, bitsPerSample, true);
  writeString(view, 36, 'data');
  view.setUint32(40, dataSize, true);

  // Write audio data
  const samples = new Float32Array(audioBuffer.length);
  audioBuffer.copyFromChannel(samples, 0);

  let offset = 44;
  for (let i = 0; i < samples.length; i++) {
    const sample = Math.max(-1, Math.min(1, samples[i]));
    view.setInt16(offset, sample < 0 ? sample * 0x8000 : sample * 0x7fff, true);
    offset += 2;
  }

  return new Blob([buffer], { type: 'audio/wav' });
};

export const CreateRecordingModal = ({
  isOpen,
  onClose,
  onSave,
  currentRecordingUrl,
}: Props): JSX.Element => {
  const { settingsState } = useSettings();
  const [isRecording, setIsRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const voicemailGreeting = settingsState?.settings?.call?.voicemail_greetings?.find(
    (greeting) => greeting.greeting_type === 'missed_call'
  );

  useEffect(() => {
    if (
      isOpen &&
      voicemailGreeting?.content_type === 'recording_url' &&
      voicemailGreeting?.content
    ) {
      setAudioUrl(voicemailGreeting.content);
    }
  }, [isOpen, voicemailGreeting?.content, voicemailGreeting?.content_type]);

  useEffect(() => {
    return () => {
      if (mediaRecorder) {
        mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      }
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
    };
  }, [mediaRecorder, audioUrl]);

  const startRecording = async () => {
    try {
      setAudioUrl(null);

      setError(null);
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: AUDIO_SETTINGS,
      });

      if (!MediaRecorder.isTypeSupported(AUDIO_MIME_TYPE)) {
        throw new Error('Unsupported audio format');
      }

      const recorder = new MediaRecorder(stream, {
        mimeType: AUDIO_MIME_TYPE,
      });
      const chunks: Blob[] = [];

      recorder.ondataavailable = (e) => chunks.push(e.data);
      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/webm' });
        const url = URL.createObjectURL(blob);
        setAudioUrl(url);
      };

      setMediaRecorder(recorder);
      recorder.start();
      setIsRecording(true);
    } catch (err) {
      console.error('[CreateRecordingModal] Error:', err);
      setError(err instanceof Error ? err.message : 'Failed to start recording');
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && isRecording) {
      mediaRecorder.stop();
      setIsRecording(false);
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
    }
  };

  const handleClose = () => {
    if (mediaRecorder) {
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
    }
    setIsRecording(false);
    onClose();
  };

  const handleSave = async () => {
    if (!audioUrl) return;

    setLoading(true);
    try {
      const response = await fetch(audioUrl);

      const originalBlob = await response.blob();

      // Convert to WAV
      const wavBlob = await convertToWav(originalBlob);
      const file = new File([wavBlob], 'recording.wav', { type: 'audio/wav' });

      const uploads = await directUpload([file]);
      const data: FileUpload = uploads[0];

      console.log('[CreateRecordingModal] Uploaded file URL:', data.public_url);
      onSave(data.public_url);
      setLoading(false);
      handleClose();
    } catch (error) {
      console.error('[CreateRecordingModal] Error:', error);
      setLoading(false);
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogPortal>
        <DialogOverlay />
        <DialogContent css={dialogContentStyle}>
          <DialogTitle css={dialogTitleStyle}>Record Voicemail Greeting</DialogTitle>

          <Flex direction="column" css={{ position: 'relative', gap: '$6' }}>
            {error && <Flex css={{ color: '$red11' }}>{error}</Flex>}
            {loading ? (
              <Flex align="center" justify="center" css={{ py: '$6' }}>
                <Spinner size={2} />
              </Flex>
            ) : (
              <Flex align="center" justify="between" css={recordingContainerStyle}>
                <Button
                  onClick={isRecording ? stopRecording : startRecording}
                  variant={isRecording ? 'redBackground' : 'primary'}
                  css={{
                    width: '180px',
                    animation: isRecording
                      ? `${blink} 1.5s ease-in-out infinite`
                      : 'none',
                  }}
                >
                  {isRecording ? 'Stop Recording' : 'Start Recording'}
                </Button>

                <Box css={audioBoxStyle}>
                  <audio
                    controls
                    src={audioUrl || currentRecordingUrl}
                    style={{
                      ...audioElementStyle,
                      opacity: audioUrl || currentRecordingUrl ? 1 : 0.5,
                      cursor: audioUrl || currentRecordingUrl ? 'pointer' : 'not-allowed',
                    }}
                  >
                    <track kind="captions" />
                  </audio>
                </Box>
              </Flex>
            )}
          </Flex>

          <Flex justify="end" css={footerStyle}>
            <DialogClose asChild>
              <Button variant="gray" disabled={loading}>
                Cancel
              </Button>
            </DialogClose>
            <Button
              onClick={handleSave}
              disabled={!audioUrl || loading}
              variant="primary"
            >
              {loading ? 'Saving...' : 'Save'}
            </Button>
          </Flex>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
