import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { SequenceSubNavigationItem, TabsContainer } from '@/sequences';
import { TrustLayout } from '@/settings/organization/trust/TrustLayout';
import { SettingsLayout } from '@/shared/layouts';
import { Box, Button, HStack } from '@/shared/ui';

export const TrustCenter = () => {
  const history = useHistory();

  const [isCreateBrandTabActive, setIsCreateBrandTabActive] = useState(true);

  const navigateToCreatePage = () => {
    isCreateBrandTabActive
      ? history.push('/settings/trust/10dlc/brands/create')
      : history.push('/settings/trust/10dlc/campaigns/create');
  };

  const currentBreadcrumbs = [
    { title: 'Settings', path: '/settings/trust' },
    { title: 'Trust Center', path: `/settings/trust` },
    { title: 'Brands', path: `/settings/trust/10dlc/brands` },
  ];

  return (
    <TrustLayout>
      <SettingsLayout
        background="#fafafa"
        padding="0"
        width="100%"
        breadcrumbs={currentBreadcrumbs}
        actions={
          <HStack>
            <Button size="2" onClick={navigateToCreatePage}>
              {isCreateBrandTabActive ? 'Create Brand' : 'Create Campaign'}
            </Button>
          </HStack>
        }
      >
        <Box>
          <TabsContainer align="center">
            <HStack>
              <SequenceSubNavigationItem
                selected={isCreateBrandTabActive}
                onClick={() => {
                  setIsCreateBrandTabActive(true);
                  history.push('/settings/trust/10dlc/brands');
                }}
              >
                Brands
              </SequenceSubNavigationItem>
            </HStack>
            <SequenceSubNavigationItem
              selected={!isCreateBrandTabActive}
              onClick={() => {
                setIsCreateBrandTabActive(false);
                history.push('/settings/trust/10dlc/campaigns');
              }}
            >
              Campaigns
            </SequenceSubNavigationItem>
          </TabsContainer>
        </Box>
      </SettingsLayout>
    </TrustLayout>
  );
};
