/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router-dom';

import AgentProvider from '@/pages/agents/context/AgentsContext';
import { useAuth } from '@/pages/auth/context/AuthProvider';
import { usePageView } from '@/shared/hooks';
import { SettingsLayout } from '@/shared/layouts';
import { ChannelTypes, ProviderTypes } from '@/shared/types/channels';
import { VStack } from '@/shared/ui';

import { useChannels } from '../context/ChannelContext';
import { AgentInboundCall } from './AgentInboundCall';
import { BusinessHours } from './BusinessHours';
import { BusinessHoursAutoResponses } from './BusinessHoursAutoResponses';
import { CallSettings } from './CallSettings';
import { ChannelDetails } from './ChannelDetails';
import { ChannelSender } from './ChannelSender';
import { ChannelSettings } from './ChannelSettings';
import { ChannelWhatsappSettings } from './ChannelWhatsappSettings';
import { LinkTracking } from './LinkTracking';

type ParamTypes = {
  id: string;
};

export const Channel = () => {
  usePageView();

  const { channelsState, getUserChannels, setCurrent } = useChannels();
  const { channels, current } = channelsState;

  const auth = useAuth();

  const history = useHistory();
  const locationParams = useParams<ParamTypes>();

  useEffect(() => {
    if (!current && auth?.tokens?.user_id) {
      getUserChannels({
        user_id: auth?.tokens?.user_id,
      });
    }

    if (channels.length > 0) {
      const foundLocation = channels.find((l) => l.id === locationParams.id);

      if (foundLocation) {
        setCurrent(foundLocation);
      } else {
        history.push('/settings/channels');
      }
    }
  }, [channels, auth?.tokens?.user_id]);

  const { allowAddCustomChannelKeys } = useFlags();

  return (
    <>
      <Helmet>
        <title>Whippy | Channel</title>
      </Helmet>
      <AgentProvider>
        <SettingsLayout
          breadcrumbs={[
            { title: 'Settings', path: '/settings/channels' },
            { title: 'Channels', path: `/settings/channels` },
            { title: current?.name ?? '', path: `/settings/channels` },
          ]}
        >
          {current && current?.id && (
            <VStack gap={6}>
              <ChannelDetails />
              {allowAddCustomChannelKeys && <ChannelSender />}
              <BusinessHours />
              <BusinessHoursAutoResponses />
              <ChannelSettings />
              <ChannelWhatsappSettings />
              <LinkTracking />
              <AgentInboundCall />
              {current?.type === ChannelTypes.PHONE &&
                current?.provider === ProviderTypes.TWILIO && <CallSettings />}
            </VStack>
          )}
        </SettingsLayout>
      </AgentProvider>
    </>
  );
};
