import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { PreviewMessagePanel } from '@/pages/campaigns/create/panel/PreviewMessage';
import { SelectLocation } from '@/pages/campaigns/create/SelectLocation';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { MessageEditorV2 } from '@/shared/components/editor/v2';
import { Attachments } from '@/shared/components/editor/v2/constants';
import { EmailMessageEditor } from '@/shared/components/EmailMessageEditor';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { TimezonePicker } from '@/shared/components/timezonepicker/TimezonePicker';
import { PageFooterContainer } from '@/shared/layouts/PageLayout';
import { Channel, ChannelTypes } from '@/shared/types/channels';
import { SequenceStep } from '@/shared/types/sequences';
import {
  Box,
  Button,
  Checkbox,
  Fieldset,
  Flex,
  HStack,
  Input,
  Label,
  RadioGroup,
  RadioGroupIndicator,
  RadioGroupRadio,
  VStack,
} from '@/shared/ui';
import { isValidEmail } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { useSequences } from '../../context/SequenceContext';
import { EmailAddressesInput } from './EmailAddressesInput';

/**
 * This component is used to create/update a step in a sequence.
 * We call this component UpdateStepNew because it's a new version of the component.
 */
export const UpdateStepNew = () => {
  const { step_id } = useParams<{ step_id: string; id: string }>();
  const location = useLocation();
  const history = useHistory();
  const sequenceContext = useSequences();
  const channelContext = useChannels();
  const { channelsState } = channelContext;
  const { allChannels } = channelsState;
  const {
    updateSequenceStep,
    sequencesState,
    createSequenceStep,
    setCurrentSequenceStep,
  } = sequenceContext;
  const { current, currentSequenceSteps } = sequencesState;
  const step = currentSequenceSteps?.find((s) => s?.id && s.id === step_id);
  const isScheduled =
    step?.schedule_options.days !== '0' ||
    step?.schedule_options.hours !== '0' ||
    step?.schedule_options.minutes !== '0';

  const [messageType, setMessageType] = useState<'email' | 'phone' | 'whatsapp'>(() => {
    if (!step) return 'phone';
    if (isEmailStep(step, allChannels)) {
      return 'email';
    } else if (isWhatsAppStep(step, allChannels)) {
      return 'whatsapp';
    } else {
      return 'phone';
    }
  });
  const [title, setTitle] = useState(step?.title || '');
  const [replyTo, setReplyTo] = useState<string>(step?.email_metadata?.reply_to || '');
  const [channel, setChannel] = useState<string>(step?.channel_id || '');
  const [emailCampaignJSONBody, setEmailCampaignJSONBody] = useState(
    step?.email_metadata?.email_body_json
  );
  const [ccEmails, setCCEmails] = useState<Array<string>>(step?.email_metadata?.cc || []);
  const [bccEmails, setBCCEmails] = useState<Array<string>>(
    step?.email_metadata?.bcc || []
  );
  const [newThread, setNewThread] = useState<boolean>(step?.new_thread || false);
  const [subject, setSubject] = useState(
    determineEmailSubject(
      step,
      currentSequenceSteps as SequenceStep[],
      newThread,
      allChannels
    )
  );

  const [radioValue, setRadioValue] = useState(isScheduled ? 'schedule' : 'now');

  // message editor value
  const [message, setMessage] = useState(step?.body || '');

  // message editor ref
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // message attachments
  const [attachments, setAttachments] = useState<Attachments>({
    attachment_urls: step?.attachment_urls || [],
  });

  // loading state for attachments
  const [attachmentsLoading, setAttachmentsLoading] = useState(false);

  // scheduling params
  const [days, setDays] = useState(step?.schedule_options?.days || '1');
  const [hours, setHours] = useState(step?.schedule_options?.hours || '0');
  const [minutes, setMinutes] = useState(step?.schedule_options?.minutes || '0');
  const [timezone, setTimezone] = useState(
    step?.schedule_options?.timezone || 'America/Detroit'
  );

  useEffect(() => {
    // prevents states not updating when step is edited from
    // the ScheduleStep
    setRadioValue(isScheduled ? 'schedule' : 'now');
    setDays(step?.schedule_options.days || '1');
    setHours(step?.schedule_options.hours || '0');
    setMinutes(step?.schedule_options.minutes || '0');
    setTimezone(step?.schedule_options.timezone || 'America/Detroit');
  }, [isScheduled, step]);

  useEffect(() => {
    setCurrentSequenceStep(step?.id ?? null);
  }, [step]);

  const scheduleOptions =
    radioValue === 'schedule'
      ? {
          days: days ? days : '1',
          hours: hours ? hours : '0',
          minutes: minutes ? minutes : '0',
          timezone: timezone,
        }
      : {
          days: '0',
          hours: '0',
          minutes: '0',
          timezone: timezone,
        };

  const onUpdate = async () => {
    if (step) {
      const params = {
        id: step.id,
        sequence_id: current?.id || '',
        title: title,
        body: message,
        attachment_urls: attachments.attachment_urls,
        position: step.position,
        schedule_options: scheduleOptions,
        location_id: channel ? channel : null,
        new_thread: sanitizedNewThread(
          newThread,
          messageType,
          currentSequenceSteps as SequenceStep[],
          allChannels,
          step
        ),
        email_metadata:
          messageType === 'email'
            ? {
                subject,
                email_body_json: emailCampaignJSONBody,
                cc: ccEmails,
                bcc: bccEmails,
                reply_to: replyTo,
              }
            : {},
      };

      await updateSequenceStep(params);
      backToSteps();
    }
  };

  const backToSteps = () => {
    history.push(`${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}`);
  };

  const clearStates = () => {
    setRadioValue('now');
    setTitle('');
    setMessage('');
    setAttachments({
      attachment_urls: [],
    });
    setDays('1');
    setHours('0');
    setMinutes('0');
  };

  const onCreate = async () => {
    const params = {
      sequence_id: current?.id || '',
      body: message,
      title: title,
      attachment_urls: attachments.attachment_urls,
      position: currentSequenceSteps.length,
      schedule_options: scheduleOptions,
      location_id: channel ? channel : null,
      // we want to enforce that the first step of the email
      // always be a new thread
      // probably should be enforced on the backend
      new_thread: sanitizedNewThread(
        newThread,
        messageType,
        currentSequenceSteps as SequenceStep[],
        allChannels,
        step
      ),
      email_metadata:
        messageType === 'email'
          ? {
              subject,
              email_body_json: emailCampaignJSONBody,
              cc: ccEmails,
              bcc: bccEmails,
              reply_to: replyTo,
            }
          : {},
    };
    await createSequenceStep(params);
    backToSteps();
    clearStates();
  };

  const handleSave = () => {
    if (step) {
      onUpdate();
    } else {
      onCreate();
    }
  };

  // are day, hours, minutes, and timezone filled out and valid numbers (string numbers)
  const isScheduleValid =
    !isNaN(Number(days)) &&
    !isNaN(Number(hours)) &&
    !isNaN(Number(minutes)) &&
    timezone !== '';

  return (
    <Box>
      <SequenceStepsContainer>
        <Flex>
          <VStack css={{ flex: 1 }} gap="2">
            <Fieldset>
              <Label>Step Title (optional)</Label>
              <Input
                placeholder="Title"
                value={title}
                onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                  setTitle(e.target.value)
                }
              />
            </Fieldset>
            <Fieldset>
              <Label>Select Message Type</Label>
              <SingleSelect
                selectItem={messageType}
                setSelectItem={(selectedItem) => {
                  setMessageType(
                    (selectedItem as 'phone' | 'email' | 'whatsapp') ?? 'phone'
                  );
                  if (
                    selectedItem === 'email' &&
                    !isFirstEmailStep(
                      selectedItem,
                      currentSequenceSteps as SequenceStep[],
                      allChannels,
                      step
                    ) &&
                    !newThread
                  ) {
                    const previousEmailStep = getPreviousEmailStep(
                      currentSequenceSteps as SequenceStep[],
                      allChannels,
                      step
                    );
                    setChannel(previousEmailStep?.channel_id || '');
                    setSubject(previousEmailStep?.email_metadata?.subject || '');
                  }
                }}
                options={[
                  { type: 'SMS', value: 'phone' },
                  { type: 'Email', value: 'email' },
                  { type: 'WhatsApp', value: 'whatsapp' },
                ]}
                defaultPlaceholder={getMessageTypeLabel(messageType)}
                isDropdown={true}
                closeOnClick
              />
            </Fieldset>
            <Fieldset>
              <Label>Select Channel</Label>
              <SelectLocation
                disabled={
                  messageType == 'email' &&
                  !isFirstEmailStep(
                    messageType,
                    currentSequenceSteps as SequenceStep[],
                    allChannels,
                    step
                  ) &&
                  !newThread
                }
                location={channel}
                setLocation={setChannel}
                channelType={[messageType]}
              />
            </Fieldset>
            {messageType === 'email' ? (
              <>
                <Fieldset>
                  <Label>Subject</Label>
                  <Input
                    placeholder="Enter the subject of the message"
                    value={subject}
                    disabled={
                      messageType == 'email' &&
                      !isFirstEmailStep(
                        messageType,
                        currentSequenceSteps as SequenceStep[],
                        allChannels,
                        step
                      ) &&
                      !newThread
                    }
                    onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                      setSubject(e.target.value)
                    }
                  />
                </Fieldset>
                <Fieldset>
                  <Label>CC</Label>
                  <EmailAddressesInput emails={ccEmails} setEmails={setCCEmails} />
                </Fieldset>
                <Fieldset>
                  <Label>BCC</Label>
                  <EmailAddressesInput emails={bccEmails} setEmails={setBCCEmails} />
                </Fieldset>
                <Fieldset>
                  <Label>Reply To</Label>
                  <Input
                    placeholder="abc@example.com"
                    value={replyTo}
                    onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
                      setReplyTo(e.target.value)
                    }
                  />
                </Fieldset>
                <EmailMessageEditor
                  id={step_id}
                  routeId={step_id}
                  message={message}
                  setMessage={setMessage}
                  attachments={attachments}
                  setAttachments={setAttachments}
                  subject={subject}
                  channel_type="email"
                  emailCampaignJSONBody={emailCampaignJSONBody}
                  setEmailCampaignJSONBody={(JSONBody: any) => {
                    setEmailCampaignJSONBody(JSONBody);
                  }}
                  isHTMLTemplate={!!step?.email_metadata?.email_body_json}
                />
                {/* only show for email steps if position != 0 */}
                {shouldShowNewThreadCheckbox(
                  messageType == 'email',
                  currentSequenceSteps as SequenceStep[],
                  allChannels,
                  step
                ) && (
                  <Fieldset css={{ display: 'flex' }}>
                    <HStack align="center">
                      <Checkbox
                        checked={newThread}
                        onCheckedChange={(newThread: boolean) => {
                          setNewThread(newThread);
                          if (!newThread) {
                            const previousEmailStep = getPreviousEmailStep(
                              currentSequenceSteps as SequenceStep[],
                              allChannels,
                              step
                            );
                            setSubject(previousEmailStep?.email_metadata?.subject || '');
                            setChannel(previousEmailStep?.channel_id || '');
                          } else {
                            setSubject('');
                          }
                        }}
                        css={{ mr: 5 }}
                      />
                      <Label css={{ m: 0, ml: 5 }}>Start new email thread</Label>
                    </HStack>
                  </Fieldset>
                )}
              </>
            ) : (
              <Fieldset>
                <Label>Enter Message</Label>
                <MessageEditorV2
                  message={message}
                  setMessage={setMessage}
                  attachments={attachments}
                  setAttachments={setAttachments}
                  attachmentLoading={attachmentsLoading}
                  setAttachmentLoading={setAttachmentsLoading}
                  textareaRef={textareaRef}
                  showAddVariable={true}
                  showAddTemplate={true}
                  showAddAttachment={true}
                  showAddEmoji={true}
                  enableAttachments={true}
                  showCharacterCount={true}
                />
              </Fieldset>
            )}
            <Fieldset>
              <Label>Select Timezone</Label>
              <TimezonePicker timezone={timezone} setTimezone={setTimezone} />
            </Fieldset>
            <Fieldset>
              <Label>Select Schedule</Label>
              <VStack gap="3">
                <RadioGroup value={radioValue} onValueChange={setRadioValue}>
                  <Flex gap={2}>
                    <HStack align="center">
                      <RadioGroupRadio value="now" id="r1">
                        <RadioGroupIndicator />
                      </RadioGroupRadio>
                      <Label htmlFor="r1" css={{ m: 0, ml: 5 }}>
                        Send immediately
                      </Label>
                    </HStack>
                    <HStack align="center">
                      <RadioGroupRadio value="schedule" id="r2">
                        <RadioGroupIndicator />
                      </RadioGroupRadio>
                      <Label htmlFor="r2" css={{ m: 0, ml: 5 }}>
                        Send with delay
                      </Label>
                    </HStack>
                  </Flex>
                </RadioGroup>
                {radioValue === 'schedule' && (
                  <HStack>
                    <ScheduleInput
                      value={days}
                      onChange={(e: {
                        target: { value: React.SetStateAction<string> };
                      }) => setDays(e.target.value)}
                    />
                    <ScheduleText>days</ScheduleText>
                    <ScheduleInput
                      value={hours}
                      onChange={(e: {
                        target: { value: React.SetStateAction<string> };
                      }) => setHours(e.target.value)}
                    />
                    <ScheduleText>hours</ScheduleText>
                    <ScheduleInput
                      value={minutes}
                      onChange={(e: {
                        target: { value: React.SetStateAction<string> };
                      }) => setMinutes(e.target.value)}
                    />
                    <ScheduleText>minutes</ScheduleText>
                  </HStack>
                )}
              </VStack>
            </Fieldset>
          </VStack>
          {!(messageType === 'email') && (
            <Box>
              <PreviewMessagePanel
                body={message}
                attachment_urls={attachments.attachment_urls}
                isFlex
                platform={messageType == 'whatsapp' ? 'whatsapp' : 'imessage'}
              />
            </Box>
          )}
        </Flex>
      </SequenceStepsContainer>
      <PageFooterContainer
        css={{
          width: '100%',
        }}
        border
      >
        <Flex justify="end" css={{ width: '100%' }} gap={2}>
          <Button variant="gray" onClick={() => backToSteps()}>
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            disabled={
              !message ||
              (radioValue === 'schedule' && !isScheduleValid) ||
              (replyTo != '' && !isValidEmail(replyTo))
            }
          >
            Save Step
          </Button>
        </Flex>
      </PageFooterContainer>
    </Box>
  );
};

const ScheduleInput = styled(Input, {
  maxHeight: 35,
  maxWidth: 55,
});

const ScheduleText = styled(Box, {
  fontWeight: 400,
  fontSize: 14,
  color: '#7F7F86',
});

export const SequenceStepsContainer = styled(Box, {
  p: 30,
  height: 'calc(100vh - 265px)',
  overflow: 'auto',
});

// position == 0 is a wrong check, we want the first email step
function shouldShowNewThreadCheckbox(
  isEmail: boolean,
  currentSequenceSteps: SequenceStep[],
  channels: Channel[],
  step?: SequenceStep | null
) {
  if (!isEmail || !currentSequenceSteps || currentSequenceSteps.length == 0) return false;
  const hasEmailSteps =
    currentSequenceSteps.length > 0 &&
    currentSequenceSteps.filter((step) => isEmailStep(step, channels)).length > 0;
  const firstEmailStep = currentSequenceSteps
    .filter((step) => isEmailStep(step, channels))
    .reduce<SequenceStep | null>((minStep, step) => {
      if (minStep == null) return step;
      if (minStep.position === undefined || step.position === undefined) return step;
      if (minStep.position < step.position) {
        return minStep;
      } else {
        return step;
      }
    }, null);

  if (!hasEmailSteps || firstEmailStep == null) return false;
  // never show the checkbox for non-email steps
  // if we're updating the first step of an email sequence, don't show the checkbox
  if (step && step.position && step.position == firstEmailStep.position) return false;
  // if we're creating the first email step, don't show the checkbox
  return true;
}

function isEmailStep(step: SequenceStep | null, channels: Channel[]) {
  if (channels.length === 0) return false;
  return channels.some(
    (channel) => channel.id === step?.channel_id && channel.type === ChannelTypes.EMAIL
  );
}

function isWhatsAppStep(step: SequenceStep | null | undefined, channels: Channel[]) {
  if (channels.length === 0 || !step) return false;
  return channels.some(
    (channel) => channel.id === step?.channel_id && channel.type === ChannelTypes.WHATSAPP
  );
}

function determineEmailSubject(
  step: SequenceStep | null | undefined,
  currentSequenceSteps: SequenceStep[],
  newThread: boolean,
  channels: Channel[]
) {
  // if we're updating step
  if (step && step.email_metadata && step.email_metadata.subject) {
    return step.email_metadata.subject;
  }
  // if we're creating email step that should be in the same thread
  // as the previous email step
  if (!newThread) {
    const previousEmailStep = getPreviousEmailStep(currentSequenceSteps, channels, step);
    return previousEmailStep?.email_metadata?.subject || '';
  }
  return '';
}

function getMessageTypeLabel(messageType: 'email' | 'phone' | 'whatsapp') {
  switch (messageType) {
    case 'email':
      return 'Email';
    case 'phone':
      return 'SMS';
    default:
      return 'Select Message Type';
  }
}

function isFirstEmailStep(
  messageType: 'email' | 'phone' | 'whatsapp',
  currentSequenceSteps: SequenceStep[],
  channels: Channel[],
  step?: SequenceStep | null
) {
  if (messageType !== 'email') return false;
  // if we're creating the first email step, don't show the checkbox
  const previousEmailStep = getPreviousEmailStep(currentSequenceSteps, channels, step);
  return previousEmailStep == null ? true : false;
}

/**
 * This will return the first email step in the sequence.
 * If we provide a specific step, we will return the previous email step
 * relative to the provided step.
 */
function getPreviousEmailStep(
  currentSequenceSteps: SequenceStep[],
  channels: Channel[],
  step?: SequenceStep | null
) {
  if (currentSequenceSteps.length === 0) return null;
  const emailStepsInDescendingOrder = currentSequenceSteps
    .filter((s) => isEmailStep(s, channels))
    .sort((a, b) => {
      if (a.position === undefined || b.position === undefined) return 0;
      return b.position - a.position;
    });

  // if the updating the step
  if (step && step.position) {
    return emailStepsInDescendingOrder.find((s) => {
      if (s.position === undefined || step.position === undefined) return false;
      return s.position < step.position;
    });
  } else {
    // if we're creating the step then the we'd want to use the most recent
    // email step information which is the first element when position is descending
    // order
    return emailStepsInDescendingOrder[0];
  }
}

function sanitizedNewThread(
  newThread: boolean,
  messageType: 'email' | 'phone' | 'whatsapp',
  currentSequenceSteps: SequenceStep[],
  allChannels: Channel[],
  step?: SequenceStep | null
) {
  if (messageType != 'email') return false;
  if (currentSequenceSteps.length == 0) return true;
  const previousEmailStep = getPreviousEmailStep(currentSequenceSteps, allChannels, step);
  if (previousEmailStep == null) return true;
  return newThread;
}
