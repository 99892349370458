/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { SequenceStep } from '@/shared/types/sequences';
import { Box, Button, Flex, Text, VStack } from '@/shared/ui';

import { useSequences } from '../context/SequenceContext';
import { fetchTrigger, SequenceTriggers } from '../sequence';
import { StepCard, StepLoadingCard } from '../sequence/steps/StepCard';

export const SequenceTemplate = () => {
  const sequenceContext = useSequences();
  const { sequencesState, getAndSetSequenceSteps, setCurrentSequenceStep } =
    sequenceContext;
  const { currentSequenceSteps, current, currentSequenceStep } = sequencesState;
  const itemRefs = useRef<HTMLDivElement[]>([]);

  const [loading, setLoading] = useState(true);
  const [sequenceTriggers, setSequenceTriggers] = useState<SequenceTriggers[]>([]);
  const history = useHistory();

  useEffect(() => {
    itemRefs.current = itemRefs.current.slice(0, currentSequenceSteps?.length);
    fetchTrigger(currentSequenceSteps, current, setSequenceTriggers, scrollToElement);
    scrollToElement(true);
  }, [currentSequenceSteps?.length]);

  const scrollToElement = (cleanCurrentSequenceStep?: boolean) => {
    if (currentSequenceSteps.length && currentSequenceStep) {
      const activeItemIndex = currentSequenceSteps.findIndex(
        (s) => s && s.id === currentSequenceStep
      );
      activeItemIndex !== -1 && itemRefs.current[activeItemIndex]?.scrollIntoView();
      cleanCurrentSequenceStep && setCurrentSequenceStep(null);
    }
  };

  useEffect(() => {
    const getSequencesSteps = async (id: string) => {
      try {
        await getAndSetSequenceSteps(id);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    if (current && current.id) {
      getSequencesSteps(current.id);
    }
  }, [current]);

  if (loading) {
    return (
      <Box css={{ p: 30 }}>
        <VStack gap={4} align="center" css={{ width: '100%' }}>
          {[...Array(3)].map((_, i) => (
            <Box key={i} css={{ width: '100%', mb: 20 }}>
              <StepLoadingCard />
            </Box>
          ))}
          <Box css={{ width: '100%' }}>
            <Button
              onClick={() =>
                history.push(`/sequences/templates/${current?.id}/steps/new`)
              }
              variant="gray"
            >
              Add New Step
            </Button>
          </Box>
        </VStack>
      </Box>
    );
  }

  if (currentSequenceSteps.length < 1 && !loading) {
    return (
      <Flex align="center" justify="center" css={{ height: '100%' }}>
        <VStack align="center" gap="4">
          <Text variant="bold" size={4} css={{ fontWeight: 600 }}>
            No Sequence Steps
          </Text>
          <Text variant="gray">Add the first step in your sequence to get started.</Text>
          <Button
            onClick={() => history.push(`/sequences/templates/${current?.id}/steps/new`)}
          >
            Add Step
          </Button>
        </VStack>
      </Flex>
    );
  }

  return (
    <Box css={{ p: 30 }}>
      <VStack gap={4} align="center" css={{ width: '100%' }}>
        {currentSequenceSteps &&
          currentSequenceSteps.length > 0 &&
          currentSequenceSteps.map((step: SequenceStep | null, index: number) => (
            <Box
              ref={(el) => el && (itemRefs.current[index] = el)}
              key={index}
              css={{ width: '100%' }}
            >
              <StepCard
                sequenceTrigger={sequenceTriggers.find((s) => s.id === step?.id)}
                sequence={current}
                sequence_id={current?.id || ''}
                step={step}
              />
            </Box>
          ))}
        <Box css={{ width: '100%' }}>
          <Button
            variant="gray"
            onClick={() => history.push(`/sequences/templates/${current?.id}/steps/new`)}
          >
            Add New Step
          </Button>
        </Box>
      </VStack>
    </Box>
  );
};
