import { FilterType, JoinType } from '@/shared/types/filter';

export const areFiltersValid = (filters: FilterType[]) => {
  // Function to validate a single join
  const isValidJoin = (join: JoinType) => {
    return (
      join.parent &&
      join.parent_column &&
      join.child &&
      join.child_column &&
      join.parent !== '' &&
      join.parent_column !== '' &&
      join.child !== '' &&
      join.child_column !== ''
    );
  };

  // Function to validate a single filter
  const isValidFilter = (filter: FilterType): boolean => {
    // Check the base filter validity, including non-empty checks
    const isValidBase =
      filter.resource &&
      filter.column &&
      filter.comparison &&
      filter.value !== undefined &&
      (filter.value !== '' ||
        (filter.value == '' &&
          (filter.comparison == '!null' || filter.comparison == 'null')));

    // Initialize isValidAndOr here
    const isValidAndOr = (['and', 'or'] as (keyof FilterType)[]).every(
      (key) =>
        !Array.isArray(filter[key]) || (filter[key] as FilterType[]).every(isValidFilter)
    );

    // Check 'on' (joins) validity
    const isValidOn = filter.on ? filter.on.every(isValidJoin) : true;

    return Boolean(isValidBase && isValidAndOr && isValidOn);
  };

  // Check all filters
  return filters.every(isValidFilter);
};
