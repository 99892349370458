import React from 'react';
import { useMedia } from 'react-use';

import { styled } from '@/stitches.config';

import { Box, Flex } from '../../ui';
import NavigationList from './MobileSideNavigation';
import OrganizationUserMenu from './OrganizationUserMenu';
import SettingsNavigationList from './SettingsNavigation';
import { HelpButton } from './SideNavigationContainer';

function NavbarContainer({ children }: any) {
  return (
    <Flex
      direction="column"
      justify="between"
      css={{
        flexShrink: 0,
        position: 'relative',
        width: '100%',
        maxWidth: '50vh',
        height: '100%',
        borderRight: 'thin solid $gray4',
        zIndex: '2',
        '@lg': {
          width: '180px',
        },
        '@xl': {
          width: '200px',
        },
        '@xxl': {
          width: '230px',
        },
      }}
    >
      {children}
    </Flex>
  );
}

export function MainNavigation() {
  return (
    <NavbarContainer>
      <Box>
        <Flex
          align="center"
          css={{
            height: '56px',
            flex: 'initial 0 initial',
            paddingLeft: '20px',
          }}
        >
          <OrganizationUserMenu />
        </Flex>
        <Flex
          direction="column"
          css={{
            overflowY: 'auto',
            flex: '1 initial initial',
            marginTop: 5,
          }}
        >
          <NavigationList />
        </Flex>
      </Box>
      <HelpButton expanded />
    </NavbarContainer>
  );
}

export const MobileNavbarContainer = styled(NavbarContainer, {
  display: 'none',
  '@lg': { display: 'flex' },
});

export function SettingsNavigation() {
  const isDesktop = useMedia('(min-width: 912px)');
  if (isDesktop) {
    return <SettingsNavigationList isDesktop />;
  } else {
    return (
      <MobileNavbarContainer>
        <Flex
          direction="column"
          justify="between"
          css={{
            height: '100%',
          }}
        >
          <SettingsNavigationList />
          <HelpButton expanded />
        </Flex>
      </MobileNavbarContainer>
    );
  }
}
