import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { HiDotsHorizontal } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { areFiltersValid } from '@/shared/components/filterBuilder/utils/areValidFilters';
import { cleanFilters } from '@/shared/components/filterBuilder/utils/cleanFilters';
import { DeveloperApplication } from '@/shared/types/developers';
import { FilterType, Sort } from '@/shared/types/filter';
import {
  Box,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
  HStack,
  IconButton,
  Text,
  VStack,
} from '@/shared/ui';
import { Table } from '@/shared/v2/components/table/Table';

import { useDeveloperContext } from '../context/DevelopersContext';
import { AddDeveloperApplication } from './AddApplication';
import { default_application_object } from './config/filterConfig';
import { sortConfig } from './config/sortConfig';
import RenameConfirmationDialog from './RenameConfirmationDialog';

export const WebhookEndpointsList = () => {
  const history = useHistory();

  const apiKeysContext = useDeveloperContext();
  const { developerState, searchDeveloperApplications, updateDeveloperApplication } =
    apiKeysContext;
  const { loading, developerApplications, developerApplicationsTotal } = developerState;

  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);
  const [activeSort, setActiveSort] = useState<Sort[]>([
    {
      label: 'Updated At',
      column: 'updated_at',
      resource: 'application',
      order: 'desc',
      id: 1,
    },
  ]);
  const limit = 10;

  const updateFilters = useCallback((filter: Array<FilterType>) => {
    setActiveFilters(filter);
  }, []);

  const [quickSearchValue, setQuickSearchValue] = useState('');

  const [offset, setOffset] = useState(0);

  const mergeQuickFilter = useCallback(
    (filters: Array<FilterType>, quickValue: string) => {
      const quickFilter = quickValue
        ? {
            resource: 'application',
            column: 'name',
            comparison: 'ilike',
            value: `%${quickValue}%`,
          }
        : null;

      return quickFilter ? [...filters, quickFilter] : [...filters];
    },
    []
  );

  const debouncedSearchApplications = useCallback(
    debounce(async (filters: Array<FilterType>, quickValue: string) => {
      await searchDeveloperApplications(
        mergeQuickFilter(cleanFilters(filters), quickValue),
        activeSort,
        limit,
        offset
      );
    }, 500),
    [searchDeveloperApplications, mergeQuickFilter, activeSort, limit, offset]
  );

  // Initial data fetch
  useEffect(() => {
    debouncedSearchApplications(activeFilters, quickSearchValue);
  }, []);

  const handleFilterChange = useCallback(
    (filters: Array<FilterType>) => {
      updateFilters(filters);
      if (areFiltersValid(cleanFilters(filters))) {
        debouncedSearchApplications(filters, quickSearchValue);
        setOffset(0);
      }
    },
    [updateFilters, quickSearchValue, debouncedSearchApplications]
  );

  const handleSortChange = useCallback(
    (sort: Array<Sort>) => {
      setActiveSort(sort);
      searchDeveloperApplications(
        mergeQuickFilter(cleanFilters(activeFilters), quickSearchValue),
        sort,
        limit,
        0
      );
      setOffset(0);
    },
    [
      activeFilters,
      quickSearchValue,
      limit,
      searchDeveloperApplications,
      mergeQuickFilter,
    ]
  );

  const handleQuickSearchChange = useCallback(
    (value: string) => {
      setQuickSearchValue(value);
      debouncedSearchApplications(activeFilters, value);
      setOffset(0);
    },
    [activeFilters, debouncedSearchApplications]
  );

  const handleOffsetChange = useCallback(
    (newOffset: number) => {
      setOffset(newOffset);
      searchDeveloperApplications(
        mergeQuickFilter(cleanFilters(activeFilters), quickSearchValue),
        activeSort,
        limit,
        newOffset
      );
    },
    [
      activeFilters,
      activeSort,
      limit,
      quickSearchValue,
      searchDeveloperApplications,
      mergeQuickFilter,
    ]
  );

  // Handle disable/enable confirmation
  const handleDisableConfirm = async (application: DeveloperApplication | null) => {
    if (application) {
      try {
        await updateDeveloperApplication({
          ...application,
          active: !application.active,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        colWidth: '30%',
        Cell: (props: { row: { original: DeveloperApplication } }) => (
          <HStack gap="2">
            <Text>{props.row.original?.name || 'No Application Name'}</Text>
          </HStack>
        ),
      },
      {
        Header: 'Status',
        colWidth: '50%',
        disableSortBy: true,
        id: 'status',
        Cell: (props: { row: { original: DeveloperApplication } }) => (
          <HStack gap="2">
            <Text>{props.row.original.active ? 'Active' : 'Disabled'}</Text>
          </HStack>
        ),
      },
      {
        Header: 'Edit',
        colWidth: '5%',
        disableSortBy: true,
        id: 'expander',
        Cell: (props: { row: { original: DeveloperApplication } }) => (
          <DropdownMenu>
            <DropdownMenuTrigger asChild style={{ alignContent: 'end' }}>
              <IconButton aria-label="open dropdown" variant="outline" size="2">
                <HiDotsHorizontal size="15px" />
              </IconButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent sideOffset={10}>
              <DropdownMenuItem
                onSelect={(e) => {
                  e.preventDefault();
                  history.push(`/developer/webhooks/${props.row.original.id}`);
                }}
                css={{ zIndex: 1 }}
                disabled={!props.row.original.active}
              >
                Edit Endpoint
              </DropdownMenuItem>
              <RenameConfirmationDialog application={props.row.original}>
                <DropdownMenuItem
                  onSelect={(e) => e.preventDefault()}
                  css={{ zIndex: 1 }}
                >
                  Rename Application
                </DropdownMenuItem>
              </RenameConfirmationDialog>
              <ConfirmationDialog
                title={`${
                  props.row.original?.active ? 'Disable' : 'Enable'
                } API Application`}
                description={`Are you sure you want to ${
                  props.row.original?.active ? 'disable' : 'enable'
                } this API
                      Key?`}
                onConfirm={() => handleDisableConfirm(props.row.original)}
                confirmButtonTitle="Confirm"
                testID="delete-application-button"
              >
                <DropdownMenuItem
                  onSelect={(e) => e.preventDefault()}
                  css={{ zIndex: 1 }}
                >
                  {props.row.original.active
                    ? 'Disable Application'
                    : 'Enable Application'}
                </DropdownMenuItem>
              </ConfirmationDialog>
            </DropdownMenuContent>
          </DropdownMenu>
        ),
      },
    ],
    [developerApplications]
  );

  return (
    <Box css={{ p: 30 }}>
      <Box css={{ paddingBottom: 24 }}>
        <CombinedFilters
          quickSearchPlaceholder="Search Webhook Endpoints"
          quickSearchValue={quickSearchValue}
          setQuickSearchValue={handleQuickSearchChange}
          defaultObjects={[default_application_object]}
          customObjects={[]}
          activeFilters={activeFilters}
          setFilters={handleFilterChange}
          activeSort={activeSort}
          sortConfig={sortConfig}
          onSortUpdate={handleSortChange}
        />
      </Box>
      <Table
        data={loading ? Array(5).fill({}) : developerApplications}
        columns={columns}
        isLoading={loading}
        caption="Webhook Endpoints Table"
        emptyTitle="No Webhook Endpoints found"
        pageSize={limit}
        setOffset={handleOffsetChange}
        totalCount={developerApplicationsTotal}
        empty={
          <Flex css={{ width: '100%' }} direction="column">
            <VStack gap={3}>
              <Box>Create your first Webhook Endpoint.</Box>
              <Box>
                <AddDeveloperApplication />
              </Box>
            </VStack>
          </Flex>
        }
      />
    </Box>
  );
};
