import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Prompt, useHistory, useRouteMatch } from 'react-router-dom';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { usePageView } from '@/shared/hooks';
import { SettingsLayout } from '@/shared/layouts';
import { PermissionActions, PermissionResources } from '@/shared/types/users';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Flex,
  HStack,
  Skeleton,
  VStack,
} from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import i18next from '@/shared/utils/translation';
import { styled } from '@/stitches.config';

import { PermissionAction, PermissionResource, permissionResources } from './constants';
import { useUsers } from './context/UserContext';
import { Accordion, PermissionAccordion } from './PermissionAccordion';
import { PermissionActionItem } from './PermissionActionItem';

const normalizeKey = (key: string) => {
  return key?.split('_').join(' ');
};

export const UserPermissions = () => {
  usePageView();

  const { organizationId } = useAuth();
  const {
    userState: { current, allPermissions, loadingPermissions },
    clearCurrent,
    getUser,
    getUserPermissions,
    updateUserPermissions,
  } = useUsers();

  const [accordions, setAccordions] = useState<PermissionResources[]>(
    allPermissions ? (Object.keys(allPermissions) as PermissionResources[]) : []
  );

  // create temporary list of permissions for smoothing updating of checkboxes
  const [temporaryPermissions, setTemporaryPermissions] = useState<
    Record<PermissionResources, PermissionActions[]> | Record<string, never>
  >({});

  const history = useHistory();
  const match = useRouteMatch<{ id: string }>('/settings/users/:id/permissions');

  useEffect(() => {
    return () => {
      clearCurrent();
    };
  }, []);

  useEffect(() => {
    if (!current) {
      match?.params?.id && getUser(Number(match?.params?.id));
    }
  }, [current, match]);

  useEffect(() => {
    if (organizationId && current) {
      if (!current?.permissions) {
        getUserPermissions(Number(match?.params?.id), organizationId);
      } else {
        setTemporaryPermissions(current?.permissions);
      }
    }
  }, [current, organizationId]);

  const ActionButtons = ({
    onUpdate,
    onCancel,
  }: {
    onUpdate: () => void;
    onCancel: () => void;
  }) => {
    return (
      <Flex justify="end" css={{ width: '100%' }}>
        <ActionButton ghost onClick={onCancel} variant="outline">
          Cancel
        </ActionButton>
        <ActionButton
          variant="primary"
          onClick={onUpdate}
          disabled={
            isEqual(temporaryPermissions, current?.permissions) || loadingPermissions
          }
          css={{ ml: 8 }}
        >
          Save Changes
        </ActionButton>
      </Flex>
    );
  };

  const handleUpdate = useCallback(async () => {
    current?.id &&
      (await updateUserPermissions(current?.id, organizationId, temporaryPermissions));
    history.push(`/settings/users`);
  }, [temporaryPermissions, current?.id, organizationId]);

  const handleCancel = useCallback(() => {
    history.push(`/settings/users`);
  }, []);

  // handle the opening/closing of permission resource
  const handleSelectResource = useCallback((value: PermissionResources) => {
    setAccordions((prev) =>
      prev?.find((item) => item === value)
        ? prev?.filter((item) => item !== value)
        : [...prev, value]
    );
  }, []);

  const handleSelectAll = useCallback(
    (checked: boolean) => {
      if (checked) {
        allPermissions && setTemporaryPermissions(allPermissions);
      } else {
        setTemporaryPermissions({});
      }
    },
    [allPermissions]
  );

  const handleSelectAllInResource = useCallback(
    (checked: boolean, resource: PermissionResources) => {
      if (checked) {
        setTemporaryPermissions(
          (prevPermissions: Record<PermissionResources, PermissionActions[]>) => ({
            ...prevPermissions,
            [resource]: allPermissions?.[resource],
          })
        );
      } else {
        setTemporaryPermissions(
          (prevPermissions: Record<PermissionResources, PermissionActions[]>) => {
            const { [resource]: _, ...newObj } = prevPermissions;
            return newObj;
          }
        );
      }
    },
    [allPermissions]
  );

  const isActionChecked = (resource: PermissionResources, action: PermissionActions) => {
    return (temporaryPermissions as Record<PermissionResources, PermissionActions[]>)?.[
      resource
    ]?.includes(action);
  };

  const togglePermission = useCallback(
    (resource: PermissionResources, action: PermissionActions, checked: boolean) => {
      if (checked) {
        setTemporaryPermissions(
          (prevPermissions: Record<PermissionResources, PermissionActions[]>) => ({
            ...prevPermissions,
            [resource]: prevPermissions?.[resource]
              ? [...prevPermissions[resource], action]
              : [action],
          })
        );
      } else {
        setTemporaryPermissions(
          (prevPermissions: Record<PermissionResources, PermissionActions[]>) => ({
            ...prevPermissions,
            [resource]: prevPermissions?.[resource]?.filter(
              (item: PermissionActions) => item !== action
            ),
          })
        );
      }
    },
    []
  );

  const getActionItem = (resource: PermissionResources, action: PermissionActions) => {
    return permissionResources
      .find((per: PermissionResource) => per.key === resource)
      ?.items?.find((item: PermissionAction) => item.value === action);
  };

  const getResourceTitle = (resource: PermissionResources) => {
    return permissionResources.find((per: PermissionResource) => per.key === resource)
      ?.title;
  };

  const getActionDescription = (resource: string, permission: string) =>
    i18next.t('user_permissions', { resource, permission });

  const orderedAllPermissions = useMemo(() => {
    const sortedKeys = permissionResources.map(
      (resource: PermissionResource) => resource.key
    );
    const arrayToBeSorted =
      (allPermissions &&
        (Object.keys(allPermissions) as PermissionResources[])?.filter((p) =>
          sortedKeys.includes(p)
        )) ||
      [];
    const newPermissions =
      (allPermissions &&
        (Object.keys(allPermissions) as PermissionResources[])?.filter(
          (p) => !sortedKeys.includes(p)
        )) ||
      [];
    return [
      ...arrayToBeSorted.sort((a, b) => {
        const aIndex = sortedKeys?.indexOf(a);
        const bIndex = sortedKeys?.indexOf(b);
        if (aIndex >= 0 && bIndex >= 0) {
          return aIndex - bIndex;
        }
        return 0;
      }),
      ...newPermissions,
    ];
  }, [allPermissions]);

  return (
    <>
      <Prompt
        when={!isEqual(temporaryPermissions, current?.permissions)}
        message="You’ve got unsaved changes. Are you sure you want to navigate away from this page?"
      />
      <Helmet>
        <title>Whippy | Users</title>
      </Helmet>
      <SettingsLayout
        background="#fafafa"
        padding="0"
        breadcrumbs={[
          { title: 'Settings', path: '/settings/users' },
          { title: 'Users', path: `/settings/users` },
          { title: 'Permissions', path: `/settings/users/${current?.id}/permissions` },
        ]}
        footer={<ActionButtons onUpdate={handleUpdate} onCancel={handleCancel} />}
        width="100%"
      >
        <Container>
          <Card>
            <Header justify="between" align="center">
              <HStack gap={2}>
                <Avatar
                  size={'48'}
                  src={current?.attachment?.url}
                  fallback={initials(current?.name || current?.email)}
                />

                <VStack gap="1">
                  {loadingPermissions ? (
                    <>
                      <Skeleton
                        variant="heading"
                        css={{ height: 24, width: 100, m: 0 }}
                      />
                      <Skeleton
                        variant="subheading"
                        css={{ height: 20, width: 200, mb: 0 }}
                      />
                    </>
                  ) : (
                    <>
                      <Title>{current?.name}</Title>
                      <StyledText>{current?.email}</StyledText>
                    </>
                  )}
                </VStack>
              </HStack>
              <Checkbox
                css={{
                  minWidth: '15px',
                }}
                checked={isEqual(temporaryPermissions, allPermissions)}
                onCheckedChange={handleSelectAll}
                aria-label={`select-all-checkbox`}
              />
            </Header>
            <Accordion type="multiple" value={accordions}>
              <Flex direction="column">
                {allPermissions &&
                  orderedAllPermissions?.map((resource) => (
                    <PermissionAccordion
                      handleToggleAll={(checked: boolean) =>
                        handleSelectAllInResource(checked, resource)
                      }
                      title={getResourceTitle(resource) || normalizeKey(resource)}
                      itemValue={resource}
                      setAccordions={() => handleSelectResource(resource)}
                      isAllChecked={isEqual(
                        (
                          temporaryPermissions as Record<
                            PermissionResources,
                            PermissionActions[]
                          >
                        )?.[resource],
                        allPermissions?.[resource]
                      )}
                      key={resource}
                    >
                      <Flex direction="column">
                        {allPermissions?.[resource]?.map((item, index) => (
                          <PermissionActionItem
                            checked={!!isActionChecked(resource, item)}
                            onToggle={(checked: boolean) =>
                              togglePermission(resource, item, checked)
                            }
                            title={getActionItem(resource, item)?.title || item}
                            description={
                              getActionItem(resource, item)?.description ||
                              getActionDescription(resource, item)
                            }
                            key={`${resource}-${item}`}
                            index={index}
                          />
                        ))}
                      </Flex>
                    </PermissionAccordion>
                  ))}
              </Flex>
            </Accordion>
          </Card>
        </Container>
      </SettingsLayout>
    </>
  );
};

const ActionButton = styled(Button, {
  fontSize: 16,
  fontWeight: '500',
  height: 40,
});

const Container = styled(Box, {
  p: 24,
});

const Header = styled(Flex, {
  p: 24,
});

const Title = styled(Box, {
  fontSize: 16,
  lineHeight: '20px',
  fontWeight: '700',
  color: '#1C2024',
});

const StyledText = styled(Box, {
  fontSize: 14,
  lineHeight: '20px',
  fontWeight: '500',
  color: '#60646C',
  mt: 4,
});
