import { PopoverPortal } from '@radix-ui/react-popover';
import { FormikValues, useFormikContext } from 'formik';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { FormFieldWrapper, TextInput } from '@/shared/components/forms';
import { useDisclosure } from '@/shared/hooks';
import { Popover, PopoverContent, PopoverTrigger, VStack } from '@/shared/ui';
import { countries } from '@/shared/utils/countries';
import { SelectComboboxField } from '@/shared/v2/components/forms/selectCombobox/SelectComboboxField';

import { Address, AddressInput } from './AddressInput';

type ContactAddressProps = {
  name: string;
};

type addressFieldType = {
  name: string;
  label: string;
  placeholder: string;
};

const addressFields = [
  {
    name: 'address_line_one',
    label: 'Address Line 1',
    placeholder: "Add contact's address line 1",
  },
  {
    name: 'address_line_two',
    label: 'Address Line 2',
    placeholder: "Add contact's address line 2",
  },
  {
    name: 'city',
    label: 'City',
    placeholder: "Add contact's city",
  },
  {
    name: 'state',
    label: 'State',
    placeholder: "Add contact's state",
  },
  {
    name: 'country',
    label: 'Country',
    placeholder: "Select contact's country",
  },
  {
    name: 'post_code',
    label: 'Post Code',
    placeholder: "Add contact's post code",
  },
];

export const ContactAddress = ({ name }: ContactAddressProps) => {
  const inputRef = useRef() as React.MutableRefObject<HTMLButtonElement>;

  const formik = useFormikContext<FormikValues>();

  const value = useMemo(() => _.get(formik.values, name as string), [formik.values]);

  const [address, setAddress] = useState<Address>({});

  const renderField = useCallback(
    ({ label, name, placeholder }: addressFieldType) => (
      <FormFieldWrapper label={label} name={`address.${name}`} key={name}>
        {name === 'country' ? (
          <SelectComboboxField
            options={countries.map((country) => ({
              label: country.name,
              value: country.cca2,
            }))}
            name={`address.${name}`}
            searchLabel="Search"
            selectLabel={placeholder}
          />
        ) : (
          <TextInput placeholder={placeholder} />
        )}
      </FormFieldWrapper>
    ),
    []
  );

  const { isOpen, onClose, onOpen } = useDisclosure();

  const handlePointerDownOutside = (e: Event) => {
    if (e.currentTarget !== inputRef.current) {
      onClose();
    }
  };

  useEffect(() => {
    Object.keys(address).forEach((key: string) => {
      if (addressFields?.find((field) => field.name === key)) {
        formik.setFieldValue(`address.${key}`, address?.[key as keyof Address]);
      }
    });
  }, [
    address?.address_line_one,
    address?.city,
    address?.country,
    address?.state,
    address?.post_code,
  ]);

  useEffect(() => {
    const values = addressFields?.reduce((result, current) => {
      return value?.[current.name]
        ? { ...result, [current.name]: value?.[current.name] }
        : result;
    }, {});
    const fields = Object.values(values);
    setAddress((prevAddress) => ({
      ...prevAddress,
      fullAddress: fields.join(', '),
    }));
  }, [JSON.stringify(value)]);

  return (
    <Popover open={isOpen}>
      <PopoverTrigger
        onClick={onOpen}
        ref={inputRef}
        data-testid="contact-address-trigger"
      >
        <AddressInput
          placeholder="Add contact’s address"
          address={address}
          setAddress={setAddress}
        />
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent
          side="bottom"
          sideOffset={4}
          css={{
            width: 'auto',
            maxWidth: 980,
            minWidth: 372,
            padding: '12px 8px 0',
            backgroundColor: 'white',
            borderRadius: 4,
            zIndex: 9999999,
            cursor: 'default',
            boxShadow: '#0000000E 0px 12px 60px 0px, #0000301B 0px 12px 32px -16px;',
            border: '1px solid #00003B0D',
            pointerEvents: 'auto',
          }}
          onOpenAutoFocus={(e: { preventDefault: () => any }) => e.preventDefault()}
          onPointerDownOutside={handlePointerDownOutside}
        >
          <VStack gap={1} data-testid="contact-address-fields">
            {addressFields.map(renderField)}
          </VStack>
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
};
