/* eslint-disable react-hooks/exhaustive-deps */
import * as SelectPrimitive from '@radix-ui/react-select';
import { ChangeEvent, useEffect, useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';

import { useDomainsContext } from '@/pages/domains/context/DomainsContext';
import { Channel, ChannelTypes, ProviderTypes } from '@/shared/types/channels';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Box,
  Button,
  Divider,
  Fieldset,
  Flex,
  HStack,
  Input,
  Label,
  RadioGroup,
  RadioGroupIndicator,
  RadioGroupRadio as Radio,
  Select,
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
  Text,
  VStack,
} from '@/shared/ui';
import { isValidEmail, toE164 } from '@/shared/utils/validations/validations';

import {
  EmailInputWithPreview,
  getApexDomain,
  isCustomApexDomain,
  isEmailProvider,
} from '../components/EmailInputWithPreview';
import { useChannels } from '../context/ChannelContext';
import { channel_providers, ProviderTypeID } from '../create/SelectChannelProvider';
import { calculateChannelProviderId, calculateDefaultChannelProviderId } from '../utils';

type ChannelForm = Partial<Channel> & {
  whippyManagedDomain?: string | null;
};

export const ChannelSender = () => {
  const { updateChannel, channelsState } = useChannels();
  const { current } = channelsState;

  const [channel, setChannel] = useState<ChannelForm | null>(current);
  // message per period
  const [messagesPerPeriod, setMessagesPerPeriod] = useState(
    current?.delivery_options?.messages_per_period || 100
  );
  // period in seconds
  const [periodInSeconds, setPeriodInSeconds] = useState(
    current?.delivery_options?.period || 30
  );
  const [channelProviderId, setChannelProviderId] = useState<ProviderTypeID>(() =>
    calculateDefaultChannelProviderId(channel?.type)
  );

  const [providerAuthType, setProviderAuthType] = useState('default');
  useEffect(() => {
    // in some cased the current channel would be updated
    // to the same channel as the channel in the state
    // but it would remove a few fields like sending_email_address
    if (current && current.id == channel?.id) return;
    setChannel(current as Channel);
    setChannelProviderId(calculateChannelProviderId(channel as Channel));
  }, [current]);

  // set the provider auth type on mount if the provider_auth is not null or undefined
  useEffect(() => {
    if (channel && channel.provider_auth) {
      setProviderAuthType('custom');
    }
  }, [current]);

  // on mount set the period and messages per period
  useEffect(() => {
    setPeriodInSeconds(channel?.delivery_options?.period || 30);
    setMessagesPerPeriod(channel?.delivery_options?.messages_per_period || 100);
  }, [current]);

  // set default provider if channel type is email
  useEffect(() => {
    // if type is email, set default provider to whippy-email
    if (channel && channel.type === ChannelTypes.EMAIL) {
      if (
        isCustomApexDomain(
          channelProviderId,
          getApexDomain(channel.sending_email_address),
          allDomains
        )
      ) {
        setChannelProviderId('mailgun-email');
      } else {
        setChannelProviderId('whippy-email');
      }

      setChannel((prevChannel) =>
        prevChannel
          ? {
              ...prevChannel,
              provider: ProviderTypes.MAILGUN,
            }
          : null
      );
    }

    // if type is phone, maintain current provider if it supports phone, otherwise use whippy-phone
    else if (channel && channel.type === ChannelTypes.PHONE) {
      const currentProvider = channel_providers.find(
        (provider) => provider.provider === channel.provider && provider.supports_phone
      );
      const providerId = currentProvider?.id || 'whippy-phone';
      setChannelProviderId(providerId);
      if (currentProvider) {
        setChannel((prevChannel) =>
          prevChannel
            ? {
                ...prevChannel,
                provider: currentProvider.provider,
              }
            : null
        );
      }
    }
    // if type is whatsapp, set default provider to whippy-whatsapp
    else if (channel && channel.type === ChannelTypes.WHATSAPP) {
      // use the current provider to find the provider id from channel_providers
      const providerId = calculateChannelProviderId(channel as Channel);
      setChannelProviderId(providerId || 'twilio-sms');
      setChannel((prevChannel) =>
        prevChannel
          ? {
              ...prevChannel,
              provider: channel.provider,
            }
          : null
      );
    }
  }, [channel?.type, channel?.provider]);

  const {
    domainsState: { domains: allDomains },
  } = useDomainsContext();

  // filter providers based on channel type, only show providers that support the channel type
  const filteredProviders = channel_providers.filter((provider) => {
    if (channel && channel.type === ChannelTypes.PHONE) {
      return provider.supports_phone;
    } else if (channel && channel.type === ChannelTypes.EMAIL) {
      return provider.supports_email;
    } else if (channel && channel.type === ChannelTypes.WHATSAPP) {
      return provider.supports_whatsapp;
    }
    return false;
  });

  const handleUpdateChannelSender = () => {
    if (channel) {
      const updatedChannel = {
        ...channel,
        type: channel.type,
        provider: channel.provider,
        delivery_options: {
          messages_per_period: messagesPerPeriod,
          period: periodInSeconds,
        },
      };
      // don't update the sending email address if it's the default email address
      if (channel.sending_email_address == channel.id + '@whippymail.com') {
        delete updatedChannel.sending_email_address;
      }
      updateChannel(updatedChannel as Channel);
    }
  };

  // handle provider auth change for custom providers
  const handleProviderAuthChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setChannel((prevChannel) => {
      if (!prevChannel) return null;
      return {
        ...prevChannel,
        provider_auth: {
          ...(prevChannel.provider_auth || {}),
          [name]: value,
        } as Channel['provider_auth'],
      };
    });
  };

  const selectedProvider = channel_providers.find(
    (provider) => provider.id === channelProviderId
  );

  return (
    <Accordion
      css={{ background: 'white' }}
      type="single"
      collapsible
      data-testid="phoneNumber-details"
    >
      <AccordionItem value="basic-information" variant="neumorphic">
        <Flex justify="between" align="center">
          <VStack gap={2} css={{ lineHeight: 1.5 }}>
            <Text css={{ fontWeight: 600 }}>Channel Sender</Text>
            <Text>
              Control how this channel sends {humanReadableChannelType(current?.type)}{' '}
              Messages
            </Text>
          </VStack>
          <AccordionTrigger />
        </Flex>
        <AccordionContent variant="neumorphic">
          <Divider css={{ mt: -20, mb: 20 }} />
          <VStack gap={2}>
            <Fieldset>
              <Label>Channel Type</Label>
              <Text>{current?.type?.toUpperCase()}</Text>
            </Fieldset>
            <Fieldset>
              <Label>Communication Provider</Label>
              <Select
                value={channelProviderId}
                onValueChange={(id: string) => {
                  const selectedProvider = channel_providers.find(
                    (provider) => provider.id === id
                  );
                  if (selectedProvider) {
                    setChannelProviderId(id as ProviderTypeID);
                    setChannel((prevChannel) =>
                      prevChannel
                        ? {
                            ...prevChannel,
                            provider: selectedProvider.provider,
                          }
                        : null
                    );
                  }
                }}
              >
                <SelectTrigger aria-label="action-select-trigger">
                  <SelectValue>
                    {selectedProvider && selectedProvider.supports_phone
                      ? `${selectedProvider.label} (Voice, SMS, MMS)`
                      : selectedProvider?.label}
                  </SelectValue>
                  <SelectIcon>
                    <HiChevronDown />
                  </SelectIcon>
                </SelectTrigger>
                <SelectPrimitive.Portal>
                  <SelectContent css={{ zIndex: '9999' }}>
                    <SelectScrollUpButton>
                      <HiChevronUp />
                    </SelectScrollUpButton>
                    <SelectViewport>
                      <SelectGroup>
                        <SelectLabel>Communication Providers</SelectLabel>
                        {filteredProviders.map((provider, index) => (
                          <SelectItem key={index} value={provider.id}>
                            <SelectItemIndicator />
                            <SelectItemText>
                              {provider.supports_phone
                                ? `${provider.label} (Voice, SMS, MMS)`
                                : provider.label}
                            </SelectItemText>
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectViewport>
                  </SelectContent>
                </SelectPrimitive.Portal>
              </Select>
            </Fieldset>
            {(channel?.type === ChannelTypes.PHONE ||
              channel?.type === ChannelTypes.WHATSAPP) && (
              <Fieldset>
                <Label>Phone Number</Label>
                <Input
                  disabled={
                    !!(
                      channel?.provider === ProviderTypes.TWILIO &&
                      channel?.provider_account_id
                    )
                  }
                  placeholder={
                    channel?.type === ChannelTypes.PHONE
                      ? 'Enter a phone number'
                      : 'Enter a WhatsApp number'
                  }
                  value={channel?.phone || ''}
                  onChange={(e) =>
                    setChannel((prevChannel) =>
                      prevChannel
                        ? ({
                            ...prevChannel,
                            phone: toE164(e.target.value),
                          } as Channel)
                        : null
                    )
                  }
                />
              </Fieldset>
            )}
            {channel?.type === ChannelTypes.EMAIL &&
              isEmailProvider(channelProviderId) && (
                <Fieldset>
                  <Label>Sending Email Address</Label>
                  <EmailInputWithPreview
                    channel={channel as Channel}
                    channelProviderId={channelProviderId}
                    onEmailAddressChange={(emailAddress) =>
                      setChannel(
                        (channel) =>
                          ({
                            ...channel,
                            sending_email_address: emailAddress,
                          }) as Channel
                      )
                    }
                  />
                </Fieldset>
              )}
            {channelProviderId !== 'whippy-phone' &&
              channelProviderId !== 'mailgun-email' &&
              channelProviderId !== 'whippy-email' && (
                <Fieldset>
                  <Label>Select Authentication Type</Label>
                  <RadioGroup
                    value={providerAuthType}
                    onValueChange={(value) => {
                      setProviderAuthType(value);
                      setChannelProviderId(
                        value == 'custom' ? 'mailgun-email' : (value as ProviderTypeID)
                      );
                    }}
                  >
                    <Flex align="center" css={{ mt: 10 }}>
                      <Flex justify="center" align="center" css={{ mr: 10 }}>
                        <Radio value="mailgun-email" data-testid="default-radio">
                          <RadioGroupIndicator />
                        </Radio>
                        <Label css={{ ml: 5, mb: 0 }}>Managed by Whippy</Label>
                      </Flex>
                      <HStack align="center">
                        <Radio value="custom" data-testid="custom-radio">
                          <RadioGroupIndicator />
                        </Radio>
                        <Label css={{ ml: 5, mb: 0 }}>Custom</Label>
                      </HStack>
                    </Flex>
                  </RadioGroup>
                </Fieldset>
              )}
            {selectedProvider &&
              channelProviderId !== 'whippy-phone' &&
              channelProviderId !== 'whippy-email' && (
                <Fieldset>
                  <Label css={{ mb: 20 }}>Custom Provider Authentication</Label>
                  {Object.entries(selectedProvider.provider_auth).map(([key]) => (
                    <Fieldset key={key}>
                      <Label>
                        {key
                          .replace(/_/g, ' ')
                          .replace(/\b\w/g, (char) => char.toUpperCase())}
                      </Label>
                      <Input
                        name={key}
                        placeholder={key
                          .replace(/_/g, ' ')
                          .replace(/\b\w/g, (char) => char.toUpperCase())}
                        value={
                          (channel?.provider_auth as Record<string, string>)?.[key] || ''
                        }
                        onChange={handleProviderAuthChange}
                      />
                    </Fieldset>
                  ))}
                </Fieldset>
              )}
            <Fieldset>
              <Label htmlFor="messages_per_period">Messages Per Period</Label>
              <Input
                id="messages_per_period"
                placeholder="100"
                name="messages_per_period"
                type="number"
                value={messagesPerPeriod}
                onChange={(e) => setMessagesPerPeriod(Number(e.target.value))}
              />
            </Fieldset>
            <Fieldset>
              <Label htmlFor="period_in_seconds">Period in Seconds</Label>
              <Input
                id="period_in_seconds"
                placeholder="30"
                name="period_in_seconds"
                type="number"
                value={periodInSeconds}
                onChange={(e) => setPeriodInSeconds(Number(e.target.value))}
              />
            </Fieldset>
            <Box>
              <Button
                data-testid="update-channel-sender"
                onClick={handleUpdateChannelSender}
                disabled={!isValidChannel(channel as Channel)}
              >
                Update Channel Sender
              </Button>
            </Box>
          </VStack>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

function humanReadableChannelType(channelType?: ChannelTypes) {
  switch (channelType) {
    case ChannelTypes.PHONE:
      return 'SMS';
    case ChannelTypes.EMAIL:
      return 'Email';
    case ChannelTypes.WHATSAPP:
      return 'WhatsApp';
    default:
      return 'SMS';
  }
}

function isValidChannel(channel: Channel | null) {
  if (!channel) return false;
  if (channel.type === ChannelTypes.EMAIL && channel.sending_email_address) {
    return isValidEmail(channel.sending_email_address);
  }
  return true;
}
