import React, { useEffect, useState } from 'react';
import { HiTrash, HiX } from 'react-icons/hi';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { createPreviewCall } from '@/shared/api/calls';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { useDisclosure } from '@/shared/hooks';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { Agent, AgentEditorPanelType } from '@/shared/types/ai/agents';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Fieldset,
  Flex,
  HStack,
  IconButton,
  Input,
  Label,
  Text,
  VStack,
} from '@/shared/ui';
import { toE164 } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { useLLMAgent } from '../context/AgentsContext';
import AgentEditorPanel from './panel/AgentEditorPanel';
import AgentEditorToolbar from './panel/AgentEditorToolbar';

type AgentContainerProps = {
  children: React.ReactNode;
};

export const AgentContainer = ({ children }: AgentContainerProps) => {
  const agents = useLLMAgent();
  const { getAgent, deleteAgent } = agents;

  const history = useHistory();

  const params = useParams<{ id: string }>();
  const { id } = params;

  const [currentAgent, setCurrentAgent] = useState<Agent>();

  useEffect(() => {
    fetchAndSetAgent(id);
  }, [id]);

  const fetchAndSetAgent = async (id: string) => {
    const agent: Agent = await getAgent(id);
    setCurrentAgent(agent);
  };

  const [agentName, setAgentName] = useState('');

  useEffect(() => {
    if (currentAgent) {
      setAgentName(currentAgent?.current_version?.name || '');
    }
  }, [currentAgent]);

  const handleDeleteAgent = async () => {
    if (!currentAgent) return;
    await deleteAgent(currentAgent.id, currentAgent);
    history.push('/agents');
  };

  const {
    isOpen: previewCallIsOpen,
    onOpen: previewCallOnOpen,
    onClose: previewCallOnClose,
  } = useDisclosure();

  const [previewCallTo, setPreviewCallTo] = useState('');
  const [previewCallChannelId, setPreviewCallChannelId] = useState('');
  const [loading, setLoading] = useState(false);

  const locationsContext = useChannels();
  const { channels } = locationsContext.channelsState;

  const handlePreviewCall = async () => {
    if (!currentAgent) return;

    try {
      setLoading(true);
      await createPreviewCall({
        agent_id: currentAgent.id,
        channel_id: previewCallChannelId,
        to: toE164(previewCallTo),
      });
      toast.success('Triggering preview call');
      previewCallOnClose();
    } catch (error) {
      toast.error('Failed to trigger call');
    } finally {
      setLoading(false);
    }
  };

  const getChannelName = (id: string) =>
    channels.find((channel) => channel.id === id)?.name;

  const [open, setOpen] = useState(false);
  const [panel, setPanel] = useState<AgentEditorPanelType>(AgentEditorPanelType.CHAT);

  const handlePanelClick = (panel: AgentEditorPanelType) => {
    setPanel(panel);
    setOpen(!open);
  };

  // filter the channels to only show type phone, provider twilio, and provider_account_id is not null
  const filteredChannels = channels.filter(
    (channel) =>
      channel.type === 'phone' &&
      channel.provider === 'twilio' &&
      channel.provider_account_id
  );

  return (
    <Flex css={{ height: '100%' }}>
      <PageLayout
        breadcrumbs={[
          { title: 'Agents', path: '/agents' },
          { title: `${agentName || 'Editor'}`, path: `/agents/${id}/editor` },
          {
            title: history.location.pathname.includes('editor')
              ? 'Editor'
              : history.location.pathname.includes('versions')
                ? 'Versions'
                : history.location.pathname.includes('calls')
                  ? 'Calls'
                  : history.location.pathname.includes('analytics')
                    ? 'Analytics'
                    : '',
            path: history.location.pathname,
          },
        ]}
        actions={
          <HStack>
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <IconButton size="2" variant="outline">
                  <HiTrash />
                </IconButton>
              </AlertDialogTrigger>
              <AlertDialogPortal>
                <AlertDialogOverlay />
                <AlertDialogContent>
                  <AlertDialogTitle>Delete Agent</AlertDialogTitle>
                  <Text>
                    Are you sure you want to delete this agent? You cannot undo this
                    action.
                  </Text>
                  <HStack css={{ marginTop: 20, justifyContent: 'flex-end' }}>
                    <AlertDialogCancel>
                      <Button variant={'gray'}>Cancel</Button>
                    </AlertDialogCancel>
                    <Button variant={'red'} onClick={handleDeleteAgent}>
                      Confirm
                    </Button>
                  </HStack>
                </AlertDialogContent>
              </AlertDialogPortal>
            </AlertDialog>
            <Dialog open={previewCallIsOpen}>
              <DialogTrigger asChild onClick={previewCallOnOpen}>
                <Button variant="outline" onClick={previewCallOnOpen}>
                  Preview Agent
                </Button>
              </DialogTrigger>
              <DialogPortal>
                <DialogOverlay />
                <DialogContent
                  onEscapeKeyDown={previewCallOnClose}
                  onPointerDownOutside={previewCallOnClose}
                  css={{ overflow: 'auto' }}
                >
                  <DialogTitle variant="bold">Preview Agent</DialogTitle>
                  <VStack css={{ width: '100%' }} gap="1">
                    <Fieldset>
                      <Label>To</Label>
                      <Input
                        placeholder="Phone number"
                        value={previewCallTo}
                        onChange={(e) => setPreviewCallTo(e.target.value)}
                      />
                    </Fieldset>
                    <Fieldset>
                      <Label>Select Channel</Label>
                      <SingleSelect
                        defaultPlaceholder={
                          getChannelName(previewCallChannelId || '') || 'Select a channel'
                        }
                        isDropdown={true}
                        selectItem={previewCallChannelId || ''}
                        setSelectItem={setPreviewCallChannelId}
                        options={filteredChannels.map((channel) => ({
                          type: channel.name || channel.address || '',
                          value: channel?.id,
                        }))}
                      />
                    </Fieldset>
                    <Flex justify="end">
                      <HStack>
                        <Button variant="gray" onClick={previewCallOnClose}>
                          Cancel
                        </Button>
                        <Button onClick={handlePreviewCall} disabled={loading}>
                          {loading ? 'Triggering...' : 'Trigger Call'}
                        </Button>
                      </HStack>
                    </Flex>
                  </VStack>
                  <DialogCloseIcon onClick={previewCallOnClose} size="2">
                    <HiX size="15px" />
                  </DialogCloseIcon>
                </DialogContent>
              </DialogPortal>
            </Dialog>
          </HStack>
        }
      >
        <TabsContainer align="center" justify="between">
          <HStack>
            <AgentSubNavigationItem
              selected={history.location.pathname.includes('editor')}
              onClick={() => history.push(`/agents/${id}/editor`)}
            >
              Editor
            </AgentSubNavigationItem>
            <AgentSubNavigationItem
              selected={history.location.pathname.includes('versions')}
              onClick={() => history.push(`/agents/${id}/versions`)}
            >
              Versions
            </AgentSubNavigationItem>
            <AgentSubNavigationItem
              selected={history.location.pathname.includes('calls')}
              onClick={() => history.push(`/agents/${id}/calls`)}
            >
              Calls
            </AgentSubNavigationItem>
            <AgentSubNavigationItem
              selected={history.location.pathname.includes('analytics')}
              onClick={() => history.push(`/agents/${id}/analytics`)}
            >
              Analytics
            </AgentSubNavigationItem>
          </HStack>
        </TabsContainer>
        <Container direction="column">{children}</Container>
      </PageLayout>
      {open && <AgentEditorPanel setShowPanel={setOpen} panel={panel} />}
      <AgentEditorToolbar open={open} handlePanelClick={handlePanelClick} />
    </Flex>
  );
};

export const AgentSubNavigationItem = styled(Flex, {
  px: 10,
  py: 8,
  borderRadius: 4,
  fontWeight: 500,
  alignContent: 'center',
  cursor: 'pointer',
  fontSize: 13,
  position: 'relative',
  '&:hover': {
    backgroundColor: '$slate3',
  },
  variants: {
    selected: {
      true: {
        backgroundColor: '$slate3',
      },
    },
  },
});

export const TabsContainer = styled(Flex, {
  width: '100%',
  height: 60,
  px: 20,
  borderBottom: 'thin solid $gray4',
  backgroundColor: 'white',
});

export const Container = styled(Flex, {
  position: 'relative',
  height: '100%',
  flex: 1,
  overflow: 'auto',
  backgroundColor: '#fafafa',
});
