/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { HiPencil, HiPlus, HiRefresh, HiSpeakerphone, HiUpload } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import * as API from '@/shared/api/contacts/v1';
import { ToolTipIconButton } from '@/shared/components/attachments/previewer';
import { Spinner } from '@/shared/components/Icons';
import { Contact } from '@/shared/types';
import { Campaign, CampaignStatus } from '@/shared/types/campaigns';
import { Box, Checkbox, Flex, HStack, IconButton, Text } from '@/shared/ui';
import { formatPhoneNumber, toE164 } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { useCampaignsContext } from '../campaigns/context/CampaignsContext';
import { PreSelectedAudience } from '../campaigns/quick';
import { useUploads } from '../contacts/uploads/context/UploadContext';
import { SequenceAudience } from '../sequences/sequence/audience/AddAudienceToSequence';
import { ClickType, useEmbed } from './context/EmbedContext';
import { CreateUpload } from './CreateUpload';

export const ContactsList = () => {
  // embed context
  const { clickType, phoneNumbers, setClickType, setPhone, clearContacts } = useEmbed();

  // take all the phone numbers and format them to E164
  // if there are duplicates, keep only one
  const uniquePhoneNumbers = useMemo(() => {
    return phoneNumbers.reduce(
      (acc, phoneNumber) => {
        const formattedNumber = toE164(phoneNumber.number);
        const found = acc.find((item) => item.number === formattedNumber);
        if (!found) {
          acc.push({ id: formattedNumber, number: formattedNumber });
        }
        return acc;
      },
      [] as { id: string; number: string }[]
    );
  }, [phoneNumbers]);

  // create a state for the search results
  // we need to know if the phone number is a contact or not
  // if it is a contact, we need to save the contact data
  // if it is not a contact, we need to save the phone number
  // we also need to know if the search is loading or not
  const [searchedContacts, setSearchedContacts] = useState<EmbedContactObject[]>([]);

  // this keeps track of the select phone numbers in the list
  // we need these use these as the phone numbers to trigger bulk actions
  // e.g. create upload, create campaign, add to sequence
  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);

  const handleCheckboxClick = (phoneNumber: string) => {
    if (selectedContacts.includes(phoneNumber)) {
      setSelectedContacts((prev) => prev.filter((item) => item !== phoneNumber));
    } else {
      setSelectedContacts((prev) => [...prev, phoneNumber]);
    }
  };

  // this is the function that is called when the user clicks on the pencil icon
  // it acts as if one of the phone numbers was clicked on the web page
  // this trigger a request to the server to search for the contact
  // then it will open the conversation with the contact or a new conversation with
  // the phone number filled out by navigating to the inbox page
  const handlePencilClick = (phoneNumber: string) => {
    setClickType(ClickType.SINGLE);
    setPhone(phoneNumber);
  };

  // now that you have unique phone numbers, search for the contacts one by one
  // add a 1/2 second delay between each search and update the state
  useEffect(() => {
    // set all selected contacts to the unique phone numbers
    setSelectedContacts(uniquePhoneNumbers.map((phoneNumber) => phoneNumber.number));

    const contacts: EmbedContactObject[] = uniquePhoneNumbers.map((phoneNumber) => ({
      isContact: false,
      phoneNumber: phoneNumber.number,
      contact: null,
      loading: true,
    }));

    setSearchedContacts(contacts);

    searchForContactsAsync();
  }, [uniquePhoneNumbers]);

  // this is a helper function to add a delay between each search
  // this is to prevent the server from getting overloaded
  const delay = (interval: number) =>
    new Promise((resolve) => setTimeout(resolve, interval));

  // this is the function that searches for the contacts one by one
  // it updates the state as it goes along so that the UI can update
  // it also adds a delay between each search to prevent the server from getting overloaded
  const searchForContactsAsync = async () => {
    for (const phoneNumber of uniquePhoneNumbers) {
      try {
        await delay(500);
        const data = await API.searchContacts('', phoneNumber.number);
        const contact = data[0];

        setSearchedContacts((prevContacts) =>
          prevContacts.map((prevContact) =>
            prevContact.phoneNumber === phoneNumber.number
              ? {
                  isContact: !!contact,
                  phoneNumber: phoneNumber.number,
                  contact,
                  loading: false,
                }
              : prevContact
          )
        );
      } catch (err) {
        setSearchedContacts((prevContacts) =>
          prevContacts.map((prevContact) =>
            prevContact.phoneNumber === phoneNumber.number
              ? {
                  isContact: false,
                  phoneNumber: phoneNumber.number,
                  contact: null,
                  loading: false,
                }
              : prevContact
          )
        );
      }
    }
  };

  const uploads = useUploads();
  const { smallUploadContacts, largeUploadContacts } = uploads;

  const [showCreateUpload, setShowCreateUpload] = useState(false);

  const handleCreateUpload = async () => {
    // take the selected contacts and create an upload object { phone: string }[]
    // then call the smallUploadContacts function
    const contacts = selectedContacts.map((phoneNumber) => ({ phone: phoneNumber }));

    // if contacts is greater than 60, then call largeUploadContacts
    if (contacts.length > 60) {
      await largeUploadContacts(contacts);
    } else {
      await smallUploadContacts(contacts);
    }

    // close the modal
    setShowCreateUpload(false);
  };

  const campaigns = useCampaignsContext();
  const { createOneCampaign } = campaigns;

  const history = useHistory();

  const createAudienceUpload = async () => {
    // take the selected contacts and create an upload object { phone: string }[]
    // get the returned upload id and use that to create a campaign audience
    const contacts = selectedContacts.map((phoneNumber) => ({ phone: phoneNumber }));

    if (contacts.length > 60) {
      const upload = await largeUploadContacts(contacts);
      return upload;
    } else {
      const upload = await smallUploadContacts(contacts);
      return upload;
    }
  };

  const handleCreateCampaign = async () => {
    // create the campaign as a draft and wait for the campaign to be created
    // then navigate to the campaign page
    const draft: Campaign = {
      title: `extension-campaign-${Date.now()}`,
      body: '',
      attachment_urls: [],
      status: CampaignStatus.DRAFT,
      audience: null,
      delivery_options: null,
      schedule_options: null,
      settings: null,
    };

    const upload = await createAudienceUpload();

    // update the draft audience with the upload id
    draft.audience = {
      upload_tag_ids: [upload.id],
    };

    // create the campaign
    const campaign = await createOneCampaign(draft);

    // navigate to the campaign page
    if (campaign.id) {
      history.push(`/campaigns/${campaign.id}`);
    }
  };

  const [preSelectedAudience, setPreSelectedAudience] = useState<PreSelectedAudience>({
    contacts: [],
    dynamic_group_ids: [],
    tag_ids: [],
    upload_tag_ids: [],
  });

  const handleAddToSequence = async () => {
    const upload = await createAudienceUpload();

    // update the draft audience with the upload id
    const pre: PreSelectedAudience = {
      ...preSelectedAudience,
      upload_tag_ids: [upload.id],
    };

    setPreSelectedAudience(pre);
  };

  return (
    <>
      {clickType === ClickType.LIST && (
        <ListWrapper direction="column">
          <ListContainer direction="column">
            <Flex
              align="center"
              justify="center"
              css={{
                alignItems: 'center',
                width: '100%',
                height: '45px',
                minHeight: '45px',
                backgroundColor: '#E5F3FB',
              }}
            >
              <Text css={{ fontWeight: 500 }}>
                {searchedContacts.length} Contacts Found on This Page
              </Text>
            </Flex>
            <RowContainer
              justify="between"
              align="center"
              css={{
                height: '60px',
              }}
            >
              <HStack gap={3}>
                <Checkbox
                  color="green"
                  checked={selectedContacts.length === searchedContacts.length}
                  onCheckedChange={() => {
                    if (selectedContacts.length === searchedContacts.length) {
                      setSelectedContacts([]);
                    } else {
                      setSelectedContacts(
                        searchedContacts.map((contact) => contact.phoneNumber)
                      );
                    }
                  }}
                />
                <Text css={{ fontWeight: 500, fontSize: 14 }}>
                  {selectedContacts.length === searchedContacts.length
                    ? 'Unselect All'
                    : 'Select All'}
                </Text>
              </HStack>
              <HStack gap="3">
                <ToolTipIconButton
                  size={2}
                  icon={<HiRefresh />}
                  onClick={clearContacts}
                  description="Refresh Contacts on Current Page"
                />
                <CreateUpload
                  open={showCreateUpload}
                  setOpen={setShowCreateUpload}
                  onUpload={handleCreateUpload}
                >
                  <ToolTipIconButton
                    size={2}
                    icon={<HiUpload />}
                    onClick={() => setShowCreateUpload(true)}
                    description="Create List of Contacts"
                  />
                </CreateUpload>
                <SequenceAudience
                  preSelectedAudience={preSelectedAudience}
                  setPreSelectedAudience={setPreSelectedAudience}
                >
                  <ToolTipIconButton
                    size={2}
                    icon={<HiPlus />}
                    onClick={handleAddToSequence}
                    description="Add Contacts to Sequence"
                  />
                </SequenceAudience>
                <ToolTipIconButton
                  size={2}
                  icon={<HiSpeakerphone />}
                  onClick={handleCreateCampaign}
                  description="Create Campaign"
                />
              </HStack>
            </RowContainer>
            {searchedContacts.map((contact) => (
              <ContactRow
                key={contact.phoneNumber}
                number={contact}
                isSelectedContact={selectedContacts.includes(contact.phoneNumber)}
                handlePencilClick={handlePencilClick}
                handleCheckboxClick={handleCheckboxClick}
              />
            ))}
          </ListContainer>
        </ListWrapper>
      )}
    </>
  );
};

type EmbedContactObject = {
  isContact: boolean;
  phoneNumber: string;
  contact: Contact | null;
  loading: boolean;
};

type ContactRowProps = {
  number: EmbedContactObject;
  isSelectedContact: boolean;
  handlePencilClick: (phoneNumber: string) => void;
  handleCheckboxClick: (phoneNumber: string) => void;
};

const ContactRow = (props: ContactRowProps) => {
  const { number, isSelectedContact, handlePencilClick, handleCheckboxClick } = props;
  const { phoneNumber, isContact, loading, contact } = number;

  return (
    <RowContainer
      key={phoneNumber}
      justify="between"
      align="center"
      selected={isSelectedContact}
    >
      <HStack gap={3}>
        <Checkbox
          color="green"
          checked={isSelectedContact}
          onCheckedChange={() => handleCheckboxClick(phoneNumber)}
        />
        {loading ? <Spinner /> : !isContact ? <BlueDot /> : null}
        {contact && (
          <Box>
            <StyledContactText>{contact.name}</StyledContactText>
          </Box>
        )}
        <StyledContactText>{formatPhoneNumber(phoneNumber)}</StyledContactText>
      </HStack>
      <HStack gap="3">
        <IconButton onClick={() => handlePencilClick(phoneNumber)}>
          <HiPencil />
        </IconButton>
      </HStack>
    </RowContainer>
  );
};

const RowContainer = styled(Flex, {
  px: '16px',
  py: '12px',
  borderBottom: 'thin solid $gray4',
  variants: {
    selected: {
      true: {
        backgroundColor: '$gray1',
      },
    },
  },
});

const ListContainer = styled(Flex, {
  width: '100%',
  position: 'relative',
  height: '100%',
});

const ListWrapper = styled(Flex, {
  flex: 1,
  height: 'calc(100vh - 80px)',
  top: 58,
  bottom: 0,
  position: 'fixed',
  width: '100%',
  overflowY: 'scroll',
});

const StyledContactText = styled(Box, {
  fontSize: '$2',
  fontWeight: '$2',
  color: '$gray12',
});

const BlueDot = styled(Box, {
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: '$blue9',
});
