/* eslint-disable react-hooks/exhaustive-deps */
import { AxiosError } from 'axios';
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { HiTranslate, HiX } from 'react-icons/hi';
import { toast } from 'sonner';

import { useConversation } from '@/pages/inbox/context/ConversationContext';
import { translateMessage, TranslateMessageResponse } from '@/shared/api/translation';
import { useDisclosure } from '@/shared/hooks';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  Fieldset,
  HStack,
  IconButton,
  Label,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
  VStack,
} from '@/shared/ui';

import { SingleSelect } from '../../SingleSelect';
import { MessageEditorV2 } from './';
import { Attachments, ICON_SIZE } from './constants';
import { useFocusedContext } from './context/FocusedContext';
import { languages } from './utils';

type TranslateMessageProps = {
  /** The original message that needs to be translated */
  message: string;

  /** Function to set the original message */
  setMessage: Dispatch<SetStateAction<string>>;

  /** Optional attachments associated with the message */
  attachments?: Attachments;

  /** Function to set the attachments */
  setAttachments?: Dispatch<SetStateAction<Attachments>>;

  /** Flag to indicate if the message is being sent from the translation dialog. If false, it will swap out the source text from the parent editor */
  sendMessageFromTranslationDialog?: boolean;

  /** Optional function to handle the translation action. If provided, it will be called instead of the local implementation of translate */
  translateMessageAction?: (
    originalMessage: string,
    translatedMessage: string,
    attachmentUrls: string[],
    sourceLanguage?: string,
    translationLanguage?: string
  ) => void;
};

const TranslateMessageV2 = (props: TranslateMessageProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { message, setMessage, translateMessageAction, attachments, setAttachments } =
    props;

  const { addMessage, conversationState, getAndSetCurrent, toggleForwardState } =
    useConversation();
  const { current } = conversationState;

  const focused = useFocusedContext();
  const { setTemplatesModalActive } = focused;

  const [sourceLanguage, setSourceLanguage] = useState(
    current?.location?.language || 'en'
  );
  const [translationLanguage, setTranslationLanguage] = useState(
    current?.contact?.language || 'es'
  );
  const [messageTranslated, setMessageTranslated] = useState('');

  // using this to display the correct placeholder
  const getLanguageLabel = (value: string) => {
    return languages.find((language) => language.value === value)?.label;
  };

  // TBD: clear all states on dialog close
  const onDialogClose = () => {
    onClose();
    setTemplatesModalActive(false);
  };

  const onDialogOpen = () => {
    onOpen();
    setTemplatesModalActive(false);
  };

  /** Translation Encoding & Decoding
   * Each variable is exchanged for an incremented integer before being sent
   * to Google Translate. Sometimes the variables were being translated into
   * the language eg {{last_name}} -> {{ apellido }}.
   *
   * Now the variables are sent as numbers eg {{last_name}} -> {{1}}
   */
  const encodeVariables = (text: string) => {
    const variableRegex = /{{(.*?)}}/g;
    const newVariableMappings = new Map<string, string>();
    let count = 0;

    const newEncodedText = text.replace(variableRegex, (_: string, variable: string) => {
      if (!newVariableMappings.has(variable)) {
        newVariableMappings.set(variable, `{{${count}}}`);
        count++;
      }
      return newVariableMappings.get(variable) || '';
    });

    return { encodedText: newEncodedText, variableMappings: newVariableMappings };
  };

  const decodeVariables = (text: string, variableMappings: Map<string, string>) => {
    const variableRegex = /{{(.*?)}}/g;

    const newDecodedText = text.replace(variableRegex, (_, encodedVariable) => {
      for (const [key, value] of variableMappings.entries()) {
        if (value === `{{${encodedVariable}}}`) {
          return `{{${key}}}`;
        }
      }
      return _;
    });

    return newDecodedText;
  };

  const translate = async () => {
    try {
      if (!message || message === '') return;
      if (sourceLanguage === translationLanguage) return;
      const { encodedText, variableMappings } = encodeVariables(message);

      const onSendParams = {
        source_language: sourceLanguage,
        target_language: translationLanguage,
        text: encodedText || '',
        ...(current?.id && { conversation_id: current?.id }),
      };

      const onSaveParams = {
        source_language: sourceLanguage,
        target_language: translationLanguage,
        text: encodedText || '',
      };

      const response: TranslateMessageResponse = await translateMessage(
        props.sendMessageFromTranslationDialog ? onSendParams : onSaveParams
      );

      const translatedText = response?.translated_text || '';
      const decodedMessage = decodeVariables(translatedText, variableMappings);
      setMessageTranslated(decodedMessage);
    } catch (e) {
      if (e instanceof AxiosError)
        toast.error(`Translation failed: ${e.response?.data?.errors?.detail}`);

      console.error(e);
    }
  };

  useEffect(() => {
    if (!isOpen) return;
    if (message == messageTranslated) setMessageTranslated('');

    const debounceTimeout = setTimeout(translate, 1000);
    return () => clearTimeout(debounceTimeout);
  }, [message, sourceLanguage, translationLanguage, isOpen]);

  // use contact_language (if set) as default translationLanguage, use language (if set) as default sourceLanguage
  useEffect(() => {
    if (sourceLanguage !== current?.location?.language && current?.location?.language) {
      setSourceLanguage(current?.location?.language);
    }
    if (
      translationLanguage !== current?.contact?.language &&
      current?.contact?.language
    ) {
      setTranslationLanguage(current?.contact?.language);
    }
  }, [isOpen]);

  // on messageSource send
  const onSend = () => {
    // Check if the message is not being sent from the translation dialog
    if (!props.sendMessageFromTranslationDialog) {
      // Set the translated message as the current message
      setMessage(messageTranslated);

      // If there is a function to set attachments, use it to set the current attachments
      if (setAttachments)
        setAttachments({ attachment_urls: attachments?.attachment_urls || [] });
      // Close the dialog
      return onDialogClose();
    }

    if (translateMessageAction) {
      onDialogClose();
      return translateMessageAction(
        message,
        messageTranslated,
        [],
        sourceLanguage,
        translationLanguage
      );
    }

    const payload = {
      body: messageTranslated,
      translated_body: message,
      attachment_urls: attachments?.attachment_urls || [],
      source_language: sourceLanguage,
      target_language: translationLanguage,
    };

    if (payload.translated_body !== undefined) {
      addMessage(payload);
      getAndSetCurrent(current?.id || '');
      setMessage('');
      if (setAttachments) setAttachments({ attachment_urls: [] });
      // Reset forward state as it has been used
      toggleForwardState(false, { message: '', attachment_urls: [], signature: null });
    }

    onDialogClose();
  };

  // translatedMessageLoading returns true when the translated message is empty, indicating that the translation is still loading.
  // This is needed to control the display of loading states in the UI, such as showing attachments loading while translating the message.
  const translatedMessageLoading = useMemo(() => {
    return messageTranslated === '';
  }, [messageTranslated]);

  // messageEmpty returns true when the original message is empty, indicating that there is no content to translate.
  // This is needed to prevent unnecessary translation requests and to control UI elements, such as not showing the translated message if the original message is empty.
  const messageEmpty = useMemo(() => {
    return message === '';
  }, [message]);

  // hasAttachments returns true when there are attachments, indicating that the attachments loading state should be shown while translating the message.
  const hasAttachments = useMemo(() => {
    if (!attachments) return false;
    return attachments?.attachment_urls.length > 0;
  }, [attachments]);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  return (
    <Dialog open={isOpen} modal={false}>
      <Tooltip>
        <TooltipTrigger asChild>
          <IconButton
            size={2}
            type="button"
            onClick={onDialogOpen}
            aria-label="translate-button"
          >
            <HiTranslate type="button" size={ICON_SIZE} />
          </IconButton>
        </TooltipTrigger>
        <TooltipContent side="top">
          {'Translate Message'}
          <TooltipArrow />
        </TooltipContent>
      </Tooltip>
      <DialogPortal>
        <DialogOverlay as="div" css={{ zIndex: 9999 }}>
          <DialogContent
            onEscapeKeyDown={() => onDialogClose()}
            onPointerDownOutside={() => onDialogClose()}
            css={{ zIndex: 99999999 }}
          >
            <DialogTitle variant="bold">Translate Message</DialogTitle>
            <VStack gap={1}>
              <Fieldset>
                <Label>Source Language</Label>
                <VStack gap={1}>
                  <SingleSelect
                    selectItem={sourceLanguage}
                    setSelectItem={setSourceLanguage}
                    closeOnClick={true}
                    options={languages.map(
                      (language: { label: string; value: string }) => ({
                        type: language.label,
                        value: language.value,
                      })
                    )}
                    defaultPlaceholder={
                      getLanguageLabel(sourceLanguage) || 'Select source language'
                    }
                    isDropdown={true}
                  />
                  <Box>
                    <Label css={{ mt: 5 }}>Source Message</Label>
                    <MessageEditorV2
                      textareaRef={textareaRef}
                      message={message}
                      setMessage={setMessage}
                      showAddTemplate={true}
                      showCharacterCount={true}
                      showAddEmoji={true}
                      showAutoComplete={true}
                    />
                  </Box>
                </VStack>
              </Fieldset>
              <Fieldset>
                <Label>Translation Language</Label>
                <VStack gap={1}>
                  <SingleSelect
                    selectItem={translationLanguage}
                    setSelectItem={setTranslationLanguage}
                    closeOnClick={true}
                    options={languages.map(
                      (language: { label: string; value: string }) => ({
                        type: language.label,
                        value: language.value,
                      })
                    )}
                    defaultPlaceholder={
                      getLanguageLabel(translationLanguage) ||
                      'Select translation language'
                    }
                    isDropdown={true}
                  />
                  <Box>
                    <HStack align="center">
                      <Label css={{ mt: 5 }}>Translated Message</Label>
                    </HStack>
                    <MessageEditorV2
                      textareaRef={textareaRef}
                      setMessage={setMessageTranslated}
                      // If message is empty, do not show a translated message
                      message={messageEmpty ? '' : messageTranslated}
                      showAddTemplate={true}
                      showCharacterCount={true}
                      showAddEmoji={true}
                      isDraftDisabled={true}
                      attachments={
                        translatedMessageLoading ? { attachment_urls: [] } : attachments
                      }
                      setAttachments={setAttachments}
                      attachmentLoading={
                        // if there are attachments, show attachments loading while translating the message
                        // otherwise just show the message loading
                        // attachments?.attachment_urls ? translatedMessageLoading : false
                        hasAttachments ? translatedMessageLoading : false
                      }
                      enableAttachments={true}
                    />
                  </Box>
                </VStack>
              </Fieldset>
            </VStack>
            <DialogFooter justify="end" css={{ mt: 15 }}>
              <DialogClose asChild>
                <Button
                  aria-label="Close"
                  variant="gray"
                  css={{ mr: 5 }}
                  type="button"
                  onClick={() => onDialogClose()}
                >
                  Cancel
                </Button>
              </DialogClose>
              <DialogClose asChild>
                {props.sendMessageFromTranslationDialog ? (
                  <Button onClick={onSend} disabled={!messageTranslated}>
                    Send Translated Message
                  </Button>
                ) : (
                  <Button onClick={onSend} disabled={!messageTranslated}>
                    Save Translated Message
                  </Button>
                )}
              </DialogClose>
            </DialogFooter>
            <DialogCloseIcon onClick={() => onDialogClose()} size="2">
              <HiX size="15px" />
            </DialogCloseIcon>
          </DialogContent>
        </DialogOverlay>
      </DialogPortal>
    </Dialog>
  );
};

export default TranslateMessageV2;
