import { useEffect, useMemo, useRef, useState } from 'react';

import { useDomainsContext } from '@/pages/domains/context/DomainsContext';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { Channel } from '@/shared/types/channels';
import { Domain, DomainSetupType } from '@/shared/types/domains';
import { ControlGroup, Input } from '@/shared/ui';
import { isValidEmail } from '@/shared/utils/validations/validations';

import { ProviderTypeID } from '../create/SelectChannelProvider';

type Props = {
  channel: Channel;
  channelProviderId: ProviderTypeID;
  onEmailAddressChange: (localPart: string | null) => void;
};
/**
 * This component is used to generate the email address input field for the channel.
 * This helps us limit what the user can enter in the case that we want to use
 * a whippy email or a domain managed by Whippy.
 *
 * This is used when creating or updating a channel.
 */
export const EmailInputWithPreview = ({
  channel,
  channelProviderId,
  onEmailAddressChange,
}: Props) => {
  const prevChannelProviderId = useRef<string | null>(null);
  const [fullEmailAddress, setFullEmailAddress] = useState<string>(() => {
    const defaultEmailAddress = channel?.sending_email_address;
    // if the channel has a sending email address, use that
    if (defaultEmailAddress && isValidEmail(defaultEmailAddress)) {
      return defaultEmailAddress;
    }
    if (channel?.id) {
      return channel?.id + '@whippymail.com';
    }
    return '@whippymail.com';
  });

  const localPart = fullEmailAddress == '' ? '' : fullEmailAddress.split('@')[0];
  // NOTE: apexDomain in this component includes the @ symbol
  const apexDomain = '@' + getApexDomain(fullEmailAddress);

  const {
    domainsState: { domains: allDomains },
    getDomains,
  } = useDomainsContext();

  const domains = useMemo(() => {
    return allDomains.filter((domain) =>
      domain.setup_for.includes(DomainSetupType.EMAILS)
    );
    // .filter((domain) => domain.setup_type == 'buy')
    // .filter((domain) => domain.emails_status == DomainStatusType.ACTIVE)
  }, [allDomains]);

  const domainOptions = useMemo(() => {
    const whippyManagedDomain = domains
      .filter((domain) => domain.setup_for.includes(DomainSetupType.EMAILS))
      // .filter((domain) => domain.setup_type == 'buy')
      // .filter((domain) => domain.emails_status == DomainStatusType.ACTIVE)
      .map((domain) => ({
        type: '@' + domain.name,
        value: '@' + domain.name,
      }));
    return [
      ...whippyManagedDomain,
      {
        type: '@whippymail.com',
        value: '@whippymail.com',
      },
    ];
  }, [domains]);

  const isCustomDomain = isCustomApexDomain(channelProviderId, apexDomain, domains);
  useEffect(() => {
    getDomains({
      filter: [],
      searchFilter: [],
      sort: [],
      limit: 50,
      offset: 0,
    });
  }, []);

  useEffect(() => {
    if (
      channelProviderId == 'whippy-email' &&
      prevChannelProviderId.current == 'mailgun-email' &&
      isCustomDomain
    ) {
      setFullEmailAddress('');
    }
    prevChannelProviderId.current = channelProviderId;
  }, [channelProviderId]);

  if (!isEmailProvider(channelProviderId)) {
    return null;
  }

  if (channelProviderId == 'whippy-email') {
    return (
      <div>
        <ControlGroup css={{ width: '100%' }}>
          <Input
            placeholder="Sending Email Address"
            value={localPart}
            onChange={(e) => {
              const localPart = e.target.value.trim();
              const newEmailAddress = updateLocalPart(fullEmailAddress, localPart);
              if (newEmailAddress == null) return;
              setFullEmailAddress(newEmailAddress);
              onEmailAddressChange(newEmailAddress);
            }}
          />
          <SingleSelect
            defaultPlaceholder={defaultDomainPlaceholder(
              apexDomain,
              domainOptions,
              channel,
              channelProviderId,
              domains
            )}
            inputStyles={{
              maxHeight: '100%',
              width: '200px',
            }}
            avoidCapitalize={true}
            anchorWidth={300}
            selectItem={apexDomain || ''}
            options={domainOptions}
            setSelectItem={(domain) => {
              const newEmailAddress = updateApexDomain(domain, fullEmailAddress);
              if (newEmailAddress == null) return;
              setFullEmailAddress(newEmailAddress);
              onEmailAddressChange(newEmailAddress);
            }}
            isDropdown={true}
          />
        </ControlGroup>
      </div>
    );
  }

  return (
    <Input
      placeholder="Sending Email Address"
      value={fullEmailAddress}
      onChange={(e) => {
        const newEmailAddress = e.target.value.trim();
        setFullEmailAddress(newEmailAddress);
        onEmailAddressChange(newEmailAddress);
      }}
    />
  );
};

type EmailProvider = 'whippy-email' | 'mailgun-email';
export function isEmailProvider(value?: string | null): value is EmailProvider {
  if (!value) return false;
  return ['whippy-email', 'mailgun-email', 'custom'].includes(value);
}

// type ChannelForm = Partial<Channel> & {
//   whippyManagedDomain?: string | null;
// };

function defaultDomainPlaceholder(
  domain: string | null,
  domainOptions: Array<{ type: string; value: string }>,
  channel: Channel,
  channelProviderId: string,
  domains: Domain[]
) {
  const isCustomDomain = isCustomApexDomain(channelProviderId, domain, domains);
  if (domain && !isCustomDomain) {
    return domain;
  }
  if (domainOptions.length == 0) {
    return 'No Domains Found';
  }
  if (channel && channel.id && !isCustomDomain) {
    return channel.id + '@whippymail.com';
  }
  return 'Select a Domain';
}

function maybeGetWhippyManagedDomain(domains: Domain[], domainName: string | null) {
  if (!domains || !domainName) return null;
  return domains.find((d) => d.name === domainName);
}

function updateApexDomain(domain: string, fullEmailAddress: string) {
  const localPart = fullEmailAddress.split('@')[0] ?? '';
  const newEmailAddress = localPart + domain;
  return newEmailAddress;
}

function updateLocalPart(fullEmailAddress: string, localPart: string) {
  const apexDomain = fullEmailAddress.split('@')[1];
  if (!apexDomain) return localPart + '@whippymail.com';
  const newEmailAddress = localPart + '@' + apexDomain;
  return newEmailAddress;
}

export function isWhippyMail(domain: Domain | null) {
  if (!domain) return false;
  return domain.name == 'whippymail.com';
}

export function isCustomApexDomain(
  _channelProviderId: string,
  apexDomain: string | null,
  domains: Domain[]
) {
  if (!apexDomain) {
    return false;
  }
  const apexDomainWithoutAt = apexDomain.replace('@', '');
  const isWhippyMail = apexDomainWithoutAt == 'whippymail.com';
  const whippyManagedDomain = maybeGetWhippyManagedDomain(domains, apexDomainWithoutAt);
  const isWhippyManagedDomain = whippyManagedDomain != null;
  return !isWhippyMail && !isWhippyManagedDomain;
}

export function getApexDomain(emailAddress: string | null | undefined) {
  if (!emailAddress) return null;
  return emailAddress.split('@')[1];
}

export function getLocalPart(emailAddress: string | null | undefined) {
  if (!emailAddress) return null;
  return emailAddress.split('@')[0];
}
