/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import Confetti from 'react-confetti';
import { useHistory } from 'react-router-dom';
import { useWindowSize } from 'react-use';

import { DEFAULT_CAMPAIGNS_LIMIT } from '@/campaigns/context/constants';
import useFilterCampaigns from '@/pages/campaigns/hooks/useFilterCampaigns';
import { usePageView } from '@/shared/hooks';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { ChannelTypes } from '@/shared/types/channels';
import { Button, Flex } from '@/shared/ui';

import { CampaignsState, useCampaignsContext } from '../context/CampaignsContext';
import { ListContainer } from '../list';
import { CampaignSearch } from '../list/CampaignSearch';
import ListWhatsAppCampaignCards from './ListWhatsAppCampaignCards';

type CampaignStatus = 'draft' | 'scheduled' | 'complete' | 'archive' | '';
type WhatsAppCampaignsListPageProps = {
  status: CampaignStatus;
  title?: string;
};

export const WhatsAppCampaignsListPage = ({
  status = '',
  title,
}: WhatsAppCampaignsListPageProps): JSX.Element => {
  usePageView();

  const { campaignsState, showConfetti } = useCampaignsContext();
  const { loading: isLoading } = campaignsState;
  const filteredCampaigns = getFilteredCampaigns(status, campaignsState);
  const whatsappCampaigns = filteredCampaigns;

  const { width, height } = useWindowSize();

  const campaignsContext = useCampaignsContext();
  const {
    setCurrentCampaign,
    campaignsState: {
      isUpdating: isCampaignsListUpdating,
      totalCount,
      isCampaignsInitialized,
    },
  } = campaignsContext;

  const [didFiltersChange, setDidFiltersChange] = useState(false);
  const {
    filters,
    changeFilters,
    increaseOffsetCoefficient,
    offsetCoefficient,
    campaignsRef,
  } = useFilterCampaigns({
    status,
    type: 'whatsapp',
  });

  const history = useHistory();

  const isListComplete = (offsetCoefficient + 1) * DEFAULT_CAMPAIGNS_LIMIT < totalCount;

  const handleCreateCampaign = () => {
    setCurrentCampaign({} as any);
    history.push('/campaigns/whatsapp/create');
  };

  const enabledLoadingNext =
    !isCampaignsListUpdating && isListComplete && isCampaignsInitialized;

  useEffect(() => {
    if (!isLoading) {
      setDidFiltersChange(false);
    }
  }, [isLoading]);

  const filteredWhatsAppCampaigns = useMemo(
    () =>
      whatsappCampaigns.filter((campaign, index, self) => {
        return index === self.findIndex((t) => t.id === campaign.id);
      }),
    [whatsappCampaigns]
  );

  const breadcrumbs = [
    { title: 'Campaigns', path: '/campaigns/whatsapp' },
    { title: 'WhatsApp', path: `/campaigns/whatsapp` },
  ];

  return (
    <PageLayout
      breadcrumbs={
        title
          ? [
              ...breadcrumbs,
              {
                title,
                path: `/campaigns/whatsapp/${status === 'draft' ? 'drafts' : status}`,
              },
            ]
          : breadcrumbs
      }
      actions={<Button onClick={handleCreateCampaign}>Create Campaign</Button>}
      css={{}}
    >
      {showConfetti && <Confetti width={width} height={height} />}
      <ListContainer ref={campaignsRef} gap={1}>
        <CampaignSearch
          campaigns={whatsappCampaigns}
          filters={filters}
          changeFilters={changeFilters}
          setDidFiltersChange={setDidFiltersChange}
          channelsType={ChannelTypes.WHATSAPP}
        />

        <Flex css={{ flexGrow: 1 }}>
          <ListWhatsAppCampaignCards
            campaigns={whatsappCampaigns}
            filteredCampaigns={filteredWhatsAppCampaigns}
            isLoading={isLoading}
            filters={filters}
            campaignsRef={campaignsRef}
            didFiltersChange={didFiltersChange}
            isCampaignsListUpdating={isCampaignsListUpdating}
            showLoadingMore={enabledLoadingNext}
            totalCount={totalCount}
            hasMoreData={isListComplete}
            increaseOffsetCoefficient={increaseOffsetCoefficient}
          />
        </Flex>
      </ListContainer>
    </PageLayout>
  );
};

function getFilteredCampaigns(status: CampaignStatus, campaignsState: CampaignsState) {
  switch (status) {
    case '':
      return campaignsState.campaigns;
    case 'draft':
      return campaignsState.draftCampaigns;
    case 'complete':
      return campaignsState.completeCampaigns;
    case 'scheduled':
      return campaignsState.scheduledCampaigns;
    case 'archive':
      return campaignsState.archivedCampaigns;
  }
}
