import { HiClock } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import { MessagePreviewDialog } from '@/pages/inbox/conversation/items/email/ConversationEmail';
import { renderText } from '@/shared/components/markdown/MarkdownRenderer';
import { Sequence, SequenceStep } from '@/shared/types/sequences';
import { SequenceAccessLevel } from '@/shared/types/sequences';
import { Box, Button, Flex, HStack, VStack } from '@/shared/ui';

import { useSequences } from '../../context/SequenceContext';
import { renderDelayText } from '../renderDelayText';
import {
  TemplateCard,
  TemplateCardBottom,
  TemplateCardTop,
  TemplateMessage,
  TemplateTitle,
} from './index';

type TemplatePreviewProps = {
  template: Sequence | null;
};

export const TemplatePreview = ({ template }: TemplatePreviewProps) => {
  const { duplicateSequence } = useSequences();

  const history = useHistory();

  const handleUpdateTemplate = (template: Sequence) => {
    if (template.access_level === SequenceAccessLevel.GLOBAL) {
      history.push(`/sequences/templates/browse/${template.id}/steps`);
    } else {
      history.push(`/sequences/templates/${template.id}/steps`);
    }
  };

  const handleDuplicateTemplate = async (template: Sequence) => {
    if (template && template.id) {
      const sequence = await duplicateSequence(template.id);
      if (sequence) {
        history.push(`/sequences/${sequence.id}/steps`);
      }
    }
  };

  return (
    template && (
      <TemplateCard key={template.id}>
        <TemplateCardTop>
          {template && template.id && (
            <VStack css={{ py: 10, mb: 10 }}>
              {template?.steps?.map((step: SequenceStep) => (
                <VStack key={step.id}>
                  <HStack
                    gap="5"
                    css={{
                      color: '$gray900',
                      fontSize: '$2',
                    }}
                  >
                    <HiClock
                      style={{
                        marginRight: '5px',
                      }}
                    />
                    {renderDelayText(step.schedule_options)}
                  </HStack>
                  {!!step.body && step.body?.startsWith('<!DOCTYPE HTML') ? (
                    <TemplateMessage>
                      <Box>
                        <Flex
                          css={{
                            borderBottom: '1px solid',
                            paddingBottom: 10,
                            marginBottom: 10,
                          }}
                        >
                          <Box css={{ ml: 12 }}>
                            <Box
                              css={{
                                fontSize: '12px',
                                color: '#1C2024',
                                fontWeight: 500,
                                marginBottom: 4,
                              }}
                            >
                              {`Whippy <${step.channel_id}@whippymail.com>`}
                            </Box>
                            <Box
                              css={{
                                fontSize: 12,
                                fontWeight: 500,
                                color: '#60646C',
                              }}
                            >
                              <Box css={{ marginBottom: 4 }}>To: {`test@test.com`}</Box>
                              <Box>
                                <span style={{ color: '#1C2024' }}>Subject: </span>
                                {step?.email_metadata?.subject}
                              </Box>
                            </Box>
                          </Box>
                        </Flex>
                        <MessagePreviewDialog message={step.body} />
                      </Box>
                    </TemplateMessage>
                  ) : (
                    <TemplateMessage>{renderText(step.body || '')}</TemplateMessage>
                  )}
                  {template.steps?.length === Number(step?.position) + 1 && <Box />}
                </VStack>
              ))}
            </VStack>
          )}
        </TemplateCardTop>
        <TemplateCardBottom direction="column">
          <TemplateTitle>{template.title}</TemplateTitle>
          <Flex align="center" css={{ pt: 15 }}>
            <Box css={{ flexGrow: 1, marginRight: '$1' }}>
              <Button
                variant="outline"
                css={{ width: '100%' }}
                onClick={() => handleDuplicateTemplate(template)}
              >
                Use Template
              </Button>
            </Box>
            <Box css={{ flexGrow: 1, marginLeft: '$1' }}>
              <Button
                variant="gray"
                css={{ width: '100%' }}
                onClick={() => handleUpdateTemplate(template)}
              >
                {template.access_level === SequenceAccessLevel.GLOBAL
                  ? 'Preview Template'
                  : 'Edit Template'}
              </Button>
            </Box>
          </Flex>
        </TemplateCardBottom>
      </TemplateCard>
    )
  );
};
