import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { HiDotsHorizontal } from 'react-icons/hi';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { useCampaignsContext } from '@/campaigns/context/CampaignsContext';
import { prepareFilters } from '@/pages/data/utils/prepareFilters';
import { getCampaignContactsV2 } from '@/shared/api/campaigns';
import { DownloadContacts } from '@/shared/components/DownloadContacts';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { CampaignContact, CampaignStatus } from '@/shared/types/campaigns';
import {
  Box,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuItemWarning,
  DropdownMenuTrigger,
  Flex,
  HStack,
  IconButton,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

import { EditCampaignName } from '../../analytics/EditCampaignName';
import { CampaignActions } from './CampaignActions';

type CampaignParamType = {
  id: string;
};

type Contact = {
  name: string;
  email: string;
  phone: string;
  response: string;
  responded_at: string;
};

// Number of contacts to fetch at a time
const DATA_LIMIT = 50;

const getCampaignTypeTitle = (type?: string): string => {
  switch (type) {
    case 'email':
      return 'Email';
    case 'whatsapp':
      return 'WhatsApp';
    case 'sms':
    default:
      return 'SMS';
  }
};

export const Campaign = () => {
  const [downloading, setDownloading] = useState(false);
  const campaigns = useCampaignsContext();
  const {
    campaignsState,
    getCampaign,
    duplicateOneCampaign,
    updateOneCampaign,
    pauseOneCampaign,
  } = campaigns;
  const { current } = campaignsState;

  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const campaignParams = useParams<CampaignParamType>();

  const getContacts = async (isDownloadAll: boolean) => {
    let allContacts: Contact[] = [];
    if (current && current.id) {
      let offset = 0;
      let keepLoading = true;

      // Define default params for download all contacts option
      const defaultParams = {
        filter: [],
        sort: [
          {
            column: 'inserted_at',
            order: 'asc',
            resource: 'contact_message',
          },
        ],
        limit: DATA_LIMIT,
      };

      // Determine whether to default params or filtered params
      const params = isDownloadAll
        ? defaultParams
        : prepareFilters(campaigns.campaignsState.campaignContactsParams);

      while (keepLoading) {
        const contacts = await getCampaignContactsV2(
          current.id,
          {
            ...params,
            offset,
            limit: DATA_LIMIT,
          },
          null
        );
        const maxMessagesCount = Math.max(
          ...contacts.data.map((a: CampaignContact) => a.contact_messages.length)
        );
        // Format the contacts data for export
        const formattedExportContacts = contacts.data.map((contact: CampaignContact) => {
          return {
            name: contact.contact.name || '',
            phone: contact.contact.phone || '',
            email: contact.contact.email || '',
            responded_at: contact.contact_messages[0]?.inserted_at || '',
            ...getResponses(contact, maxMessagesCount),
          };
        });

        // Add the formatted contacts to the allContacts array
        allContacts = [...allContacts, ...formattedExportContacts];

        // If all data has been fetched, stop the loop
        if (allContacts.length >= contacts.total) {
          keepLoading = false;
        }

        // Increment the offset
        offset += DATA_LIMIT;
      }
    }
    return allContacts;
  };

  const getResponses = (
    contact: CampaignContact,
    maxMessagesCount: number
  ): { [key: string]: string } => {
    const result: { [key: string]: string } = {};
    if (maxMessagesCount > 1) {
      for (let i = 0; i < maxMessagesCount; i++) {
        result[`response_${i + 1}`] = contact.contact_messages[i]?.body || '';
      }
    } else {
      result.response = contact.contact_messages[0]?.body || '';
    }
    return result;
  };

  const onDuplicateClick = async () => {
    if (current && current.id) {
      const data = await duplicateOneCampaign(current.id);
      data && history.push(`/campaigns/${current.type ?? 'sms'}/all`);
    }
  };

  const onShareClick = () => {
    if (current && current.id) {
      navigator.clipboard.writeText(
        `https://app.whippy.co/campaigns/${current.type ?? 'sms'}/${current.id}/contacts` ||
          ''
      );
      toast.success(i18next.t('copy_campaign_link_success'));
    }
  };

  const onArchiveClick = () => {
    if (current && current.id) {
      updateOneCampaign(
        current?.id,
        { ...current, status: CampaignStatus.ARCHIVED },
        false
      );
      history.replace(`/campaigns/${current.type ?? 'sms'}/archive`);
      toast.success(i18next.t('campaign_archive_success') as string);
    }
  };

  useEffect(() => {
    async function fetchCampaign() {
      getCampaign(campaignParams.id);
    }
    // if no campaign is set, or the set campaign
    // is complete, there load the campaign and
    // the campaign results if they exist
    if (
      !current ||
      current.id !== campaignParams.id ||
      (current.status === CampaignStatus.COMPLETE && !current.analytics) ||
      (current.status === CampaignStatus.IN_PROGRESS && !current.analytics)
    ) {
      fetchCampaign();
    }
  }, []);

  return (
    <div>
      <PageLayout
        border={false}
        breadcrumbs={[
          { title: 'Campaigns', path: `/campaigns/${current?.type ?? 'sms'}` },
          {
            title: getCampaignTypeTitle(current?.type),
            path: `/campaigns/${current?.type ?? 'sms'}`,
          },
          {
            title: `${current?.title || 'Create New Campaign'}`,
            path: `/campaigns/${current?.type ?? 'sms'}/${current?.id || 'create'}`,
          },
        ]}
        actions={
          <HStack gap={2}>
            {downloading && <Box>Downloading Data...</Box>}
            <CampaignActions current={current} pauseOneCampaign={pauseOneCampaign} />
            {current?.id && (
              <DownloadContacts
                getData={getContacts}
                setDownloading={setDownloading}
                fileName="contacts"
                title="Download CSV"
              />
            )}
            <DropdownMenu>
              <DropdownMenuTrigger>
                <IconButton variant="outline" size="2">
                  <HiDotsHorizontal />
                </IconButton>
              </DropdownMenuTrigger>
              <DropdownMenuContent sideOffset={4} align="end">
                <DropdownMenuItem onClick={onDuplicateClick}>
                  Duplicate Campaign
                </DropdownMenuItem>
                <DropdownMenuItem onClick={onShareClick}>Share Campaign</DropdownMenuItem>
                <EditCampaignName initialCampaignName={current?.title || ''}>
                  <DropdownMenuItem onClick={(e) => e.preventDefault()}>
                    Edit Campaign Name
                  </DropdownMenuItem>
                </EditCampaignName>
                {current?.status === CampaignStatus.COMPLETE && (
                  <DropdownMenuItemWarning onClick={onArchiveClick}>
                    Archive Campaign
                  </DropdownMenuItemWarning>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </HStack>
        }
      >
        <TabsContainer align="center">
          <HStack>
            <CampaignSubNavigationItem
              selected={history?.location?.pathname.includes('contacts')}
              onClick={() =>
                history.push(`/campaigns/${current?.type ?? 'sms'}/${id}/contacts`)
              }
            >
              Contacts
            </CampaignSubNavigationItem>
            <CampaignSubNavigationItem
              selected={history?.location?.pathname.includes('analytics')}
              onClick={() =>
                history.push(`/campaigns/${current?.type ?? 'sms'}/${id}/analytics`)
              }
            >
              Analytics
            </CampaignSubNavigationItem>
            <CampaignSubNavigationItem
              selected={history?.location?.pathname.includes('overview')}
              onClick={() =>
                history.push(`/campaigns/${current?.type ?? 'sms'}/${id}/overview`)
              }
            >
              Overview
            </CampaignSubNavigationItem>
          </HStack>
        </TabsContainer>
      </PageLayout>
    </div>
  );
};

const CampaignSubNavigationItem = styled(Flex, {
  px: 10,
  py: 8,
  borderRadius: 4,
  fontWeight: 500,
  alignContent: 'center',
  cursor: 'pointer',
  fontSize: 13,
  position: 'relative',
  '&:hover': {
    backgroundColor: '$slate3',
  },
  variants: {
    selected: {
      true: {
        backgroundColor: '$slate3',
      },
    },
  },
});

const TabsContainer = styled(Flex, {
  width: '100%',
  height: 60,
  px: 20,
  borderBottom: 'thin solid $gray4',
  backgroundColor: 'white',
});
