import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

import { getGroupsV2 } from '@/shared/api/contacts/groups';

import { ValueCombobox } from './Combobox';

type SegmentValuesComboboxProps = {
  selected: { label?: string; value: string | string[] | number } | null | undefined;
  onSelect: (option: { label: string; value: string | string[] | number }) => void;
  selectLabel?: string;
  searchLabel?: string;
  selectorStyles?: { [key: string]: any };
  isOpen?: boolean;
  disabled?: boolean;
};

export const SegmentValuesCombobox = (props: SegmentValuesComboboxProps): JSX.Element => {
  const { selected, onSelect, selectLabel, searchLabel } = props;

  const [searchValue, setSearchValue] = useState('');
  const [segmentOptions, setSegmentOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const handleSearchValue = (value: string) => {
    setSearchValue(value);
    debounceSegments(value);
  };

  const searchSegments = async (value: string) => {
    if (value) {
      const segments = await getGroupsV2(
        [
          {
            resource: 'segment',
            column: 'id',
            comparison: '==',
            value,
          },
        ],
        undefined
      );
      const options = segments.data.map((tag: { name: string; id: string }) => ({
        label: tag.name,
        value: tag.id,
      }));
      // merge with existing options
      setSegmentOptions((prevOptions) => {
        const newOptions = options.filter(
          (option: { value: string }) =>
            !prevOptions.some((prevOption) => prevOption.value === option.value)
        );
        return [...prevOptions, ...newOptions];
      });
    }
  };

  const debounceSegments = debounce(searchSegments, 500);

  const getSegments = async () => {
    try {
      const segments = await getGroupsV2(
        {
          filter: [],
          limit: 10,
          offset: 0,
        },
        undefined
      );

      const options = segments.data.map((tag: { name: string; id: string }) => ({
        label: tag.name,
        value: tag.id,
      }));
      setSegmentOptions(options);
    } catch (error) {
      console.error('Error fetching segments:', error);
      // Consider showing a user-friendly error message
    }
  };

  useEffect(() => {
    getSegments();
  }, []);

  return (
    <ValueCombobox
      disabled={props.disabled}
      isOpen={props.isOpen}
      selectorStyles={props.selectorStyles}
      options={segmentOptions}
      selected={{ label: selected?.label || '', value: selected?.value || '' }}
      onSelect={onSelect}
      searchValue={searchValue}
      handleSearchValue={handleSearchValue}
      selectLabel={selectLabel}
      searchLabel={searchLabel}
    />
  );
};
