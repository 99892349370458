import { useEffect, useState } from 'react';

import { reportAnalytics, ReportData } from '@/shared/api/reports';

import { DatepickerDates } from '../datepicker/DateSelector';
import { AnalyticFilterItem } from './AnalyticFilters';

export const useReportData = (
  filters: Array<AnalyticFilterItem>,
  dates: DatepickerDates,
  timeBucket: string,
  type: 'agents' | 'conversations' | 'campaign'
) => {
  const [data, setData] = useState<Array<ReportData>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    fetchReports(filters, dates, timeBucket);
  }, [filters, dates, timeBucket]);

  const fetchReports = async (
    filters: Array<AnalyticFilterItem>,
    dates: DatepickerDates,
    timeBucket: string
  ) => {
    try {
      setIsLoading(true);
      const data = await reportAnalytics(filters, dates, type, timeBucket);
      setData(data);
      setIsLoading(false);
      setError(false);
    } catch (error) {
      setIsLoading(false);
      setError(true);
      console.error(error);
    }
  };

  return {
    data,
    isLoading,
    error,
  };
};
