import {
  Contact,
  DataActions,
  DataActionTypes,
  DataStateType,
} from '@/shared/types/contacts';
import { removeDuplicates } from '@/shared/utils/removeDuplicates';

const addOrUpdate = (contacts: Array<Contact>, contact: Contact) => {
  const inState = contacts.find((c) => c.id === contact.id);
  if (inState) {
    return contacts.map((c) => (c.id === contact.id ? contact : c));
  }
  return [...contacts, contact];
};

const DataReducer = (state: DataStateType, action: DataActions) => {
  switch (action.type) {
    case DataActionTypes.GET_CONTACTS:
      return {
        ...state,
        contacts: action.payload.isLoadMore
          ? removeDuplicates([...state.contacts, ...action.payload.data])
          : action.payload.data,
        loading: false,
        loadingMore: false,
      };
    case DataActionTypes.GET_CONTACTS_COUNT:
      return {
        ...state,
        totalCount: action.payload.total,
      };
    case DataActionTypes.CREATE_CONTACT:
      return {
        ...state,
        contacts: [action.payload, ...state.contacts],
        totalCount: state.totalCount + 1,
      };
    case DataActionTypes.UPDATE_CONTACT:
      return {
        ...state,
        contacts: state.contacts.map((c) =>
          c.id === action.payload.id ? { ...c, ...action.payload } : c
        ),
      };
    case DataActionTypes.UPDATE_CONTACT_FILTERS:
      return {
        ...state,
        contactsFilters: action.payload.filters,
        loadingMore: action.payload.is_load_more,
        loading: !action.payload.is_load_more,
      };
    case DataActionTypes.BLOCK_CONTACT:
      return {
        ...state,
        contacts: addOrUpdate(state.contacts, action.payload),
      };
    case DataActionTypes.DELETE_CONTACT:
      return {
        ...state,
        contacts: state.contacts.filter((contact) => contact.id !== action.payload.id),
        loading: false,
        totalCount: state.totalCount + 1,
      };
    case DataActionTypes.BULK_DELETE_CONTACTS:
      return {
        ...state,
        contacts: state.contacts.filter((el) => -1 === action.payload.indexOf(el.id)),
        loading: false,
      };
    case DataActionTypes.UPDATE_OBJECT_RECORD_FILTERS:
      return {
        ...state,
        customObjectsFilters: action.payload.filters,
        loadingMore: action.payload.is_load_more,
        loadingCustomObjects: !action.payload.is_load_more,
      };
    case DataActionTypes.GET_CUSTOM_OBJECT_RECORDS:
      return {
        ...state,
        customObjectRecords: action.payload.isLoadMore
          ? [...state.customObjectRecords, ...action.payload.data]
          : action.payload.data,
        loadingCustomObjects: false,
        loadingMore: false,
      };
    case DataActionTypes.GET_CUSTOM_OBJECT_RECORDS_COUNT:
      return {
        ...state,
        totalCount: action.payload.total,
      };
    default:
      return state;
  }
};

export default DataReducer;
