/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'sonner';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { purchasePhoneNumber } from '@/shared/api/channels/phones';
import { Spinner } from '@/shared/components/Icons';
import { SettingsLayout } from '@/shared/layouts';
import { Channel, ChannelTypes, ProviderTypes } from '@/shared/types/channels';
import { Box, Flex, VStack } from '@/shared/ui';
import { formatPhoneNumber, isValidEmail } from '@/shared/utils/validations/validations';

import { useChannels } from '../context/ChannelContext';
import { MultiStepForm } from '../utils/MultiStepForm';
import { SelectChannelDetails } from './SelectChannelDetails';
import { SelectChannelProvider } from './SelectChannelProvider';
import { SelectChannelSender } from './SelectChannelSender';
import { channel_type_config, SelectChannelType } from './SelectChannelType';

export const CreateNewChannel = () => {
  return (
    <SettingsLayout
      background="white"
      padding="0"
      width="100%"
      breadcrumbs={[
        { title: 'Settings', path: '/settings/channels' },
        { title: 'Create New Channel', path: `/settings/channels/create` },
      ]}
    >
      <Flex css={{ p: 30 }} direction="column">
        <AddChannelForm />
      </Flex>
    </SettingsLayout>
  );
};

export const AddChannelForm = () => {
  const auth = useAuth();
  const channelsState = useChannels();
  const { addChannel, metaOnboarding } = channelsState;
  const widget_id = auth?.organizationInfo?.organization?.widget_settings?.id;

  const [isMetaLoading, setIsMetaLoading] = useState(false);

  const [channel, setChannel] = useState<
    Partial<
      Channel & {
        channelProviderId:
          | null
          | 'whippy-email'
          | 'whippy-managed-email'
          | 'mailgun-email';
        whippyManagedDomain: string | null;
      }
    >
  >({
    name: '',
    emoji: '',
    description: '',
    address: '',
    google_place_id: '',
    phone: '',
    sending_email_address: '',
    type: ChannelTypes.PHONE,
    widget_setting_id: widget_id,
    provider: ProviderTypes.TWILIO,
    provider_account_id: '',
    provider_auth: null,
    /* we need this field to distinguish between whippy-email and mailgun-email */
    channelProviderId: null,
    whippyManagedDomain: null,
    provider_metadata: null,
  });

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setChannel((channel) => ({
      ...channel,
      ...{ [e.target.name]: e.target.value },
    }));
  };

  const steps = [
    {
      title: 'Add Channel Details',
      description: 'Fill in the essential channel details, name, description, etc.',
      children: (
        <SelectChannelDetails
          channel={channel}
          setChannel={setChannel}
          onChange={onChange}
        />
      ),
    },
    {
      title: 'Select Channel Type',
      description: 'Configure your channel type e.g. Phone, Email, WhatsApp, etc.',
      children: <SelectChannelType channel={channel} setChannel={setChannel} />,
    },
    {
      title: 'Select Channel Provider',
      description: 'Select your channel provider e.g. Twilio, Mailgun, etc.',
      children: <SelectChannelProvider channel={channel} setChannel={setChannel} />,
    },
    ...(channel.provider !== ProviderTypes.META
      ? [
          {
            title: 'Configure Channel Sender',
            description: 'Configure your channel sender settings',
            children: <SelectChannelSender channel={channel} setChannel={setChannel} />,
          },
        ]
      : []),
    {
      title: 'Review & Save',
      description: 'Review and confirm your settings',
      children: <ReviewChannel channel={channel} isMetaLoading={isMetaLoading} />,
    },
  ];

  const handleComplete = async () => {
    try {
      // Check conditions before buying the phone number
      if (
        channel.type === ChannelTypes.PHONE &&
        channel.provider_account_id &&
        isValidE164(channel.phone) &&
        channel.provider === ProviderTypes.TWILIO &&
        !channel.provider_auth
      ) {
        // 1. Buy the phone number
        await handleBuyPhoneNumber();

        // 2. Create the channel
        await handleCreateChannel();

        // 3. Navigate to the channels page
        handleNavigateToChannels();
      } else {
        try {
          // 1. Create the channel
          await handleCreateChannel();

          // 2. Navigate to the channels page
          handleNavigateToChannels();
        } catch (error) {
          console.error('Error creating channel:', error);
          // Optionally, display an error message to the user
        }
      }
    } catch (error) {
      console.error('Error completing channel creation:', error);
      // Optionally, display an error message to the user
    }
  };

  // Function to validate E.164 phone number format
  const isValidE164 = (phone: string | undefined | null): boolean => {
    const e164Regex = /^\+?[1-9]\d{1,14}$/;
    return phone ? e164Regex.test(phone) : false;
  };

  const handleBuyPhoneNumber = async () => {
    if (!channel.provider_account_id || !channel.phone) {
      throw new Error('Provider account ID or phone number is missing');
    }
    try {
      await purchasePhoneNumber(channel.provider_account_id, channel.phone);
    } catch (error) {
      toast.error('Error purchasing phone number');
      console.error('Error purchasing phone number:', error);
    }
  };

  const handleCreateChannel = async () => {
    try {
      await addChannel(channel);
    } catch (error) {
      console.error('Error creating channel:', error);
      const errorMessage =
        error.response?.data?.errors?.[0]?.description || 'Error creating channel';
      toast.error('Error creating channel', { description: errorMessage });
      throw error; // Re-throw the error so it can be caught by the caller
    }
  };

  const history = useHistory();

  const handleNavigateToChannels = () => {
    history.push('/settings/channels');
  };

  const handleMetaOnboarding = async () => {
    if (
      channel.provider === ProviderTypes.META &&
      channel.meta_onboarding &&
      !channel.phone
    ) {
      setIsMetaLoading(true);
      try {
        const data = await metaOnboarding(channel.meta_onboarding);

        setChannel((prev) => ({
          ...prev,
          phone: data!.phone_number,
          provider_auth: data!.provider_auth,
          provider_metadata: {
            waba_id: data!.provider_auth.waba_id,
          },
        }));

        return true;
      } catch (error) {
        console.error('Error during meta onboarding:', error);
        toast.error('Meta onboarding failed', {
          description: error.response?.data?.errors?.[0]?.description || 'Unknown error',
        });
        return false;
      } finally {
        setIsMetaLoading(false);
      }
    }
    return true;
  };

  const validateStep = async (stepIndex: number): Promise<boolean> => {
    switch (stepIndex) {
      case 0:
        return Boolean(channel.name && channel.address);
      case 1:
        return Boolean(
          channel.type && channel_type_config.some((type) => type.value === channel.type)
        );
      case 2:
        if (channel.provider === ProviderTypes.META) {
          return Boolean(channel.provider && channel.meta_onboarding);
        } else if (channel.type === ChannelTypes.EMAIL) {
          if (channel.channelProviderId === 'whippy-managed-email') {
            // in the case that we're trying to select a whippy managed domain
            // we should make sure that a domain is selected
            return Boolean(channel.whippyManagedDomain);
          }
          return true;
        }
        return Boolean(channel.provider);
      // on the fourth step validate that the sending email address is valid or that the phone number is valid
      case 3:
        return validateChannelSender(
          channel,
          handleMetaOnboarding,
          isValidE164,
          validEmail
        );
      default:
        return true;
    }
  };

  return (
    <MultiStepForm
      steps={steps}
      onComplete={handleComplete}
      onStepValidation={validateStep}
      formData={channel}
    />
  );
};

export const ReviewChannel = ({
  channel,
  isMetaLoading,
}: {
  channel: Partial<Channel>;
  isMetaLoading: boolean;
}) => {
  if (isMetaLoading) {
    return (
      <Flex direction="column" css={{ width: '100%' }} align="center" justify="center">
        <Spinner size={3} color="primary" />
        <Box css={{ mt: 2, fontSize: '14px' }}>
          Registering your Meta account to Whippy...
        </Box>
      </Flex>
    );
  }

  return (
    <Flex direction="column" css={{ width: '100%' }}>
      <VStack gap="2">
        <Box css={{ fontSize: '14px', fontWeight: '500' }}>Channel Name</Box>
        <Box css={{ fontSize: '14px' }}>{channel.name}</Box>
        <Box css={{ fontSize: '14px', fontWeight: '500' }}>Symbol</Box>
        <Box css={{ fontSize: '14px' }}>{channel.emoji}</Box>
        <Box css={{ fontSize: '14px', fontWeight: '500' }}>Description</Box>
        <Box css={{ fontSize: '14px' }}>{channel.description}</Box>
        <Box css={{ fontSize: '14px', fontWeight: '500' }}>Channel Address</Box>
        <Box css={{ fontSize: '14px' }}>{channel.address}</Box>
        <Box css={{ fontSize: '14px', fontWeight: '500' }}>Sender</Box>
        <Box css={{ fontSize: '14px' }}>{displayChannelInfo(channel)}</Box>
      </VStack>
    </Flex>
  );
};

function validEmail(email?: string | null) {
  if (!email) return true;
  return isValidEmail(email);
}

function formatEmail(email?: string | null) {
  if (!email) return 'channel_id@whippymail.com';
  return email;
}

function displayChannelInfo(channel: Partial<Channel>) {
  if (channel.type === ChannelTypes.EMAIL) {
    return formatEmail(channel.sending_email_address);
  } else if (
    channel.type === ChannelTypes.PHONE ||
    channel.type === ChannelTypes.WHATSAPP
  ) {
    return formatPhoneNumber(channel.phone);
  } else {
    return '-';
  }
}

function validateChannelSender(
  channel: Partial<Channel>,
  handleMetaOnboarding: (channel: Partial<Channel>) => Promise<boolean>,
  isValidE164: (phone: string | null | undefined) => boolean,
  validEmail: (email: string | null | undefined) => boolean
) {
  if (channel.provider === ProviderTypes.META) {
    return handleMetaOnboarding(channel);
  }
  if (channel.type === ChannelTypes.EMAIL) {
    return validEmail(channel.sending_email_address);
  }
  return isValidE164(channel.phone);
}
