import React from 'react';
import { Helmet } from 'react-helmet-async';

import ErrorBoundary from '@/shared/components/ErrorBoundary';

type TrustLayoutProps = {
  children: React.ReactNode;
};

export const TrustLayout = (props: TrustLayoutProps) => {
  const { children } = props;
  return (
    <>
      <Helmet>
        <title>Whippy | Trust Center</title>
      </Helmet>
      <ErrorBoundary>{children}</ErrorBoundary>
    </>
  );
};
