import {
  BLOCK_CONTACT,
  CLEAR_CURRENT,
  CLEAR_SEARCHED_CONTACTS,
  CONTACT_ERROR,
  GET_CONTACT_CAMPAIGNS,
  GET_CONTACT_CONVERSATIONS,
  GET_CONTACT_SEQUENCES,
  SEARCH_CONTACT_CAMPAIGNS,
  SEARCH_CONTACT_CONVERSATIONS,
  SEARCH_CONTACTS,
  SET_CURRENT,
  SET_IS_SEARCHED,
  SET_LAST_ADDED_CONTACT,
  SET_LOADING_CONTACT,
  SET_LOADING_CONTACT_CAMPAIGNS,
  SET_LOADING_CONTACT_CONVERSATIONS,
  SET_LOADING_CONTACT_SEQUENCES,
  SET_LOADING_TAGS,
  SET_VIEW,
  UPDATE_CONTACT,
  UPDATE_CONTACT_LANGUAGE,
  UPDATE_CONTACTS_NOTES,
  UPDATE_CONTACTS_TAGS,
} from '@/pages/contacts/context/types';
import { Contact } from '@/shared/types';
import { ContactSequence } from '@/shared/types/sequences';
import { removeDuplicates } from '@/shared/utils/removeDuplicates';

import { ContactsState } from './ContactContext';

type ContactsAction =
  | { type: 'SEARCH_CONTACTS'; payload: any }
  | { type: 'CLEAR_SEARCHED_CONTACTS'; payload: any }
  | { type: 'GET_CONTACTS_TAGS'; payload: any }
  | { type: 'UPDATE_CONTACTS_TAGS'; payload: any }
  | { type: 'UPDATE_CONTACT_LANGUAGE'; payload: any }
  | { type: 'DELETE_CONTACT_TAG'; payload: any }
  | { type: 'ADD_CONTACT'; payload: any }
  | { type: 'BLOCK_CONTACT'; payload: any }
  | { type: 'ARCHIVE_CONTACT'; payload: any }
  | { type: 'ARCHIVE_MULTIPLE_CONTACTS'; payload: any }
  | { type: 'UPDATE_CONTACT'; payload: any }
  | { type: 'CONTACT_ERROR'; payload: any }
  | { type: 'UPDATE_CONTACTS_NOTES'; payload: any }
  | { type: 'SET_VIEW'; payload: any }
  | { type: 'SET_CURRENT'; payload: any }
  | { type: 'SET_LAST_ADDED_CONTACT'; payload: any }
  | { type: 'SET_CONTACTS_CHECKED'; payload: any }
  | { type: 'SET_IS_SEARCHED'; payload: any }
  | { type: 'SET_LOADING_TAGS'; payload: any }
  | { type: 'CONTACT_ERROR'; payload: any | null }
  | { type: 'SET_LOADING_CONTACT'; payload: boolean } // TODO: delete
  | { type: 'GET_CONTACT_CAMPAIGNS'; payload: any } // TODO: delete
  | { type: 'SET_LOADING_CONTACT_CAMPAIGNS'; payload: any } // TODO: delete
  | { type: 'SEARCH_CONTACT_CAMPAIGNS'; payload: any } // TODO: delete
  | { type: 'GET_CONTACT_SEQUENCES'; payload: Array<ContactSequence> } // TODO: delete
  | { type: 'SET_LOADING_CONTACT_SEQUENCES'; payload: boolean } // TODO: delete
  | { type: 'CLEAR_CURRENT' }
  | { type: 'GET_CONTACT_CONVERSATIONS'; payload: any } // TODO: delete
  | { type: 'SEARCH_CONTACT_CONVERSATIONS'; payload: any } // TODO: delete
  | { type: 'SET_LOADING_CONTACT_CONVERSATIONS'; payload: boolean }; // TODO: delete

const ContactReducer = (state: ContactsState, action: ContactsAction): ContactsState => {
  switch (action.type) {
    case SEARCH_CONTACTS:
      return {
        ...state,
        searchedContacts: action.payload,
      };
    case CLEAR_SEARCHED_CONTACTS:
      return {
        ...state,
        searchedContacts: action.payload,
      };
    case SET_LOADING_TAGS:
      return {
        ...state,
        loadingTags: action.payload,
      };
    case UPDATE_CONTACT:
      return {
        ...state,
        current: state.current?.id === action.payload.id ? action.payload : state.current,
        error: null,
      };
    case UPDATE_CONTACTS_TAGS:
    case UPDATE_CONTACT_LANGUAGE:
      return {
        ...state,
        current:
          state.current?.id === action.payload.id
            ? { ...state.current, ...action.payload }
            : state.current,
        error: null,
      };
    case UPDATE_CONTACTS_NOTES:
      return {
        ...state,
        current:
          state.current?.id === action.payload.id
            ? { ...state.current, ...action.payload }
            : state.current,
      };
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case SET_LAST_ADDED_CONTACT:
      return {
        ...state,
        lastAddedContact: action.payload,
      };
    case SET_IS_SEARCHED:
      return {
        ...state,
        isSearched: action.payload,
      };
    case CLEAR_CURRENT:
      return {
        ...state,
        current: null,
      };
    case CONTACT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_VIEW:
      return {
        ...state,
        viewSettings: action.payload,
      };
    case BLOCK_CONTACT:
      return {
        ...state,
        current: action.payload,
      };
    case SET_LOADING_CONTACT:
      return {
        ...state,
        loadingContact: action.payload,
      };
    case GET_CONTACT_CAMPAIGNS:
      return {
        ...state,
        currentContactCampaigns:
          action.payload.offset === 0
            ? action.payload.data
            : removeDuplicates([
                ...state.currentContactCampaigns,
                ...action.payload.data,
              ]),
        currentContactCampaignsTotal: action.payload.total,
        loadingContactCampaigns: false,
      };
    case SEARCH_CONTACT_CAMPAIGNS:
      return {
        ...state,
        searchedCurrentContactCampaigns:
          action.payload.offset === 0
            ? action.payload.data
            : removeDuplicates([
                ...state.searchedCurrentContactCampaigns,
                ...action.payload.data,
              ]),
        searchedCurrentContactCampaignsTotal: action.payload.total,
        loadingContactCampaigns: false,
      };
    case SET_LOADING_CONTACT_CAMPAIGNS:
      return {
        ...state,
        loadingContactCampaigns: action.payload,
      };
    case GET_CONTACT_SEQUENCES:
      return {
        ...state,
        currentContactSequences: action.payload,
        loadingContactSequences: false,
      };
    case SET_LOADING_CONTACT_SEQUENCES:
      return {
        ...state,
        loadingContactSequences: action.payload,
      };
    case GET_CONTACT_CONVERSATIONS:
      return {
        ...state,
        currentContactConversations:
          action.payload.offset === 0
            ? action.payload.data
            : removeDuplicates([
                ...state.currentContactConversations,
                ...action.payload.data,
              ]),
        currentContactConversationsTotal: action.payload.total,
        loadingContactConversations: false,
      };
    case SEARCH_CONTACT_CONVERSATIONS:
      return {
        ...state,
        searchedCurrentContactConversations:
          action.payload.offset === 0 && action.payload.search
            ? action.payload.data
            : removeDuplicates([
                ...state.searchedCurrentContactConversations,
                ...action.payload.data,
              ]),
        searchedCurrentContactConversationsTotal: action.payload.search
          ? action.payload.total
          : state.searchedCurrentContactConversationsTotal,
        loadingContactConversations: false,
      };
    case SET_LOADING_CONTACT_CONVERSATIONS:
      return {
        ...state,
        loadingContactConversations: action.payload,
      };
    default:
      return state;
  }
};

export const mergeContacts = (
  stateContacts: Array<Contact>,
  newContacts: Array<Contact>
) => {
  const currentContacts = stateContacts || [];
  const ids = new Set(currentContacts.map((c) => c.id));
  return [...currentContacts, ...newContacts.filter((c) => !ids.has(c.id))];
};

export default ContactReducer;
