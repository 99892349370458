/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Attachments } from '@/shared/components/editor/v2/constants';
import { EmailMessageEditor } from '@/shared/components/EmailMessageEditor';
import { AccordionValue, JSONObject } from '@/shared/types/campaigns';
import { Box, Button, Fieldset, HStack, Input, Label, VStack } from '@/shared/ui';
import { isValidEmail } from '@/shared/utils/validations/validations';

import { CampaignAccordion } from './CampaignAccordion';

type CampaignMessageEditorProps = {
  campaignId: string | null;
  /* message */
  message: string;
  /* set message */
  setMessage: Dispatch<SetStateAction<string>>;
  /* message attachments */
  attachments: Attachments;
  /* set message attachments */
  setAttachments: Dispatch<SetStateAction<Attachments>>;
  /* current accordion value */
  accordion: AccordionValue;
  /* set current accordion value */
  setAccordion: Dispatch<SetStateAction<AccordionValue>>;
  /* set current slate message editor */
  setCurrentEditor?: (editor: any) => void;
  /* handle save step */
  handleSave: (accordion: AccordionValue) => void;
  /* is this component used under GlobalTemplatePreview */
  showSaveButton?: boolean;
  onSubjectChange: (subject: string) => void;
  replyTo: string;
  onReplyToChange: (replyTo: string) => void;
  subject: string;
  /* channel type */
  channel_type?: 'phone' | 'email' | 'whatsapp';
  /* html template body in json format necessary for Email Editor component */
  emailCampaignJSONBody?: JSONObject;
  setEmailCampaignJSONBody: (JSONBody?: JSONObject | null) => void;
  isHTMLTemplate: boolean;
};

export const CampaignEmailMessageEditor = (
  props: CampaignMessageEditorProps
): JSX.Element => {
  const {
    message,
    setMessage,
    campaignId,
    attachments,
    setAttachments,
    accordion,
    setAccordion,
    handleSave,
    subject,
    replyTo,
    onSubjectChange,
    onReplyToChange,
    showSaveButton = true,
    channel_type = 'phone',
    emailCampaignJSONBody,
    setEmailCampaignJSONBody,
    isHTMLTemplate,
  } = props;
  const [isHTMLMessage, setIsHTMLMessage] = useState(!!emailCampaignJSONBody);
  const campaignRouteParams = useParams<{ id?: string }>();

  useEffect(() => {
    if (!isHTMLMessage && isHTMLTemplate && emailCampaignJSONBody) {
      setIsHTMLMessage(true);
    }
  }, [isHTMLTemplate]);

  const clearDraftFromLocalStorage = (campaignId: string | null) => {
    if (!campaignId) return null;
    const key = `email-campaign-${campaignId}-body`;
    return localStorage.removeItem(key);
  };

  return (
    <CampaignAccordion
      // if this component used under GlobalTemplatePreview, showSaveButton === false, then the index should be 2
      index={!showSaveButton ? 2 : 3}
      title="Message"
      description="Campaign Message Body & Attachments"
      currentAccordionValue={accordion}
      itemValue={AccordionValue.CREATE_MESSAGE}
      setItemValue={setAccordion}
      isValid={
        isMessageTextValid(message, subject) || attachments?.attachment_urls?.length > 0
      }
      // if showSaveButton === false, then the buttonCopy should be 'Expand', otherwise, it should be 'Edit'
      buttonCopy={!showSaveButton ? 'Expand' : 'Edit'}
      forceMountContent
      contentStyle={{ padding: accordion == AccordionValue.CREATE_MESSAGE ? '' : '0px' }}
    >
      <VStack
        gap="2"
        css={{
          display: accordion == AccordionValue.CREATE_MESSAGE ? 'flex' : 'none',
          alignItems: 'stretch',
        }}
      >
        <Fieldset>
          <Label>Subject</Label>
          <Box>
            <Input
              value={subject}
              placeholder="Subject"
              onChange={(e) => {
                onSubjectChange(e.target.value);
              }}
            />
          </Box>
        </Fieldset>
        <Fieldset>
          <Label>Reply To</Label>
          <Box>
            <Input
              value={replyTo}
              placeholder="example@example.co"
              onChange={(e) => {
                onReplyToChange(e.target.value);
              }}
            />
          </Box>
        </Fieldset>
        <EmailMessageEditor
          id={campaignId}
          message={message}
          routeId={campaignRouteParams.id}
          setMessage={setMessage}
          attachments={attachments}
          setAttachments={setAttachments}
          subject={subject}
          channel_type={channel_type}
          emailCampaignJSONBody={emailCampaignJSONBody}
          setEmailCampaignJSONBody={setEmailCampaignJSONBody}
          isHTMLTemplate={isHTMLTemplate}
        />
        <HStack>
          {(showSaveButton === true || showSaveButton === undefined) && (
            <Button
              onClick={() => {
                clearDraftFromLocalStorage(campaignId);
                handleSave(AccordionValue.SELECT_SCHEDULE);
              }}
              disabled={
                (!canSaveMessage(message, subject) &&
                  attachments?.attachment_urls?.length < 1) ||
                (replyTo != '' && !isValidEmail(replyTo))
              }
            >
              Save
            </Button>
          )}

          <Button
            variant="gray"
            ghost={true}
            onClick={() => setAccordion(AccordionValue.DEFAULT_VALUE)}
          >
            Cancel
          </Button>
        </HStack>
      </VStack>
    </CampaignAccordion>
  );
};

function isMessageTextValid(message: string | null, subject: string): boolean {
  if (!message) return false;
  return message.length >= 5 && subject.length >= 2;
}

function canSaveMessage(message: string | null, subject: string): boolean {
  if (!message) return false;
  return subject.length >= 5;
}
