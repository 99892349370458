/* eslint-disable react-hooks/exhaustive-deps */
import { Call, Device } from '@twilio/voice-sdk';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Channel } from 'phoenix';
import React, { useEffect, useState } from 'react';
import { createContext, useContext, useReducer } from 'react';
import { toast } from 'sonner';
import { v7 as uuidv7 } from 'uuid';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { useSocket } from '@/pages/auth/context/socket/SocketProvider';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { api } from '@/shared/api/api';
import {
  addCallParticipant,
  deleteCallParticipant,
  holdCallParticipant,
  startCallRecording,
  stopCallRecording,
} from '@/shared/api/calls';
import { searchContacts } from '@/shared/api/contacts/v2';
import {
  Channel as ChannelType,
  ChannelsStates,
  ChannelTypes,
} from '@/shared/types/channels';
import {
  CallStatusTypes,
  CallType,
  VoIPActionTypes,
  VoIPStateType,
  VoIPWebsocketEventTypes,
} from '@/shared/types/voip';
import { isValidUuid } from '@/shared/utils/validations/validations';

import VoIPReducer from './VoIPReducer';

export const initialState: VoIPStateType = {
  device: null,
  incomingCalls: [],
  calls: [],
  current: null,
  callStatus: null,
  defaultChannel: '',
  onlineUsers: {
    joins: {},
    leaves: {},
  },
};

export const VoIPContext = createContext<{
  voipState: VoIPStateType;
  initDevice: () => Promise<void>;
  makeOutgoingCall: (
    channelPhone: string,
    contactPhone: string,
    userId: string,
    contactName?: string
  ) => void;
  acceptIncomingCall: (callSid: string) => void;
  rejectCall: (callSid: string) => void;
  ignoreIncomingCall: (callSid: string) => void;
  hangUp: (callSid: string) => void;
  newCall: (defaultChannel: string) => void;
  clearCallStatus: () => void;
  startCallRecord: (callSid: string, noToast?: boolean) => Promise<void>;
  stopCallRecord: (callSid: string, recording_id: string) => Promise<void>;
  addParticipantToCall: (
    callSid: string,
    phone?: string,
    userId?: number
  ) => Promise<void>;
  deleteParticipantFromCall: (
    callSid: string,
    phone?: string,
    userId?: number
  ) => Promise<void>;
  holdParticipant: (
    callSid: string,
    hold: boolean,
    phone?: string,
    userId?: number
  ) => Promise<void>;
}>({
  voipState: initialState,
  initDevice: () => Promise.resolve(),
  makeOutgoingCall: () => Promise.resolve(),
  acceptIncomingCall: () => Promise.resolve(),
  rejectCall: () => Promise.resolve(),
  ignoreIncomingCall: () => Promise.resolve(),
  hangUp: () => Promise.resolve(),
  newCall: () => Promise.resolve(),
  clearCallStatus: () => Promise.resolve(),
  startCallRecord: () => Promise.resolve(),
  stopCallRecord: () => Promise.resolve(),
  addParticipantToCall: () => Promise.resolve(),
  deleteParticipantFromCall: () => Promise.resolve(),
  holdParticipant: () => Promise.resolve(),
});

export const useVoIP = () => useContext(VoIPContext);

const VoIPState = ({ children }: { children: React.ReactNode }) => {
  const [voipState, dispatch] = useReducer(VoIPReducer, initialState);
  const [twilioToken, setTwilioToken] = useState('');
  const { enableVoip } = useFlags();
  const auth = useAuth();
  const { socket, joinChannel, handleConnect } = useSocket();
  const {
    channelsState: { channels },
  } = useChannels();

  useEffect(() => {
    if (enableVoip) {
      if (!socket) {
        handleConnect();
      }
      initDevice();
    }
  }, [enableVoip, socket]);

  useEffect(() => {
    if (voipState.device && socket) {
      channels
        .filter(
          (l: ChannelType) =>
            isValidUuid(l.id) &&
            l.state === ChannelsStates.ENABLED &&
            !!l.provider_account_id &&
            l.type === ChannelTypes.PHONE
        )
        .map((l: ChannelType) => {
          // join the voice channel for each voip enabled location channel
          const channel: Channel | null = joinChannel(`voice:${l.id}`);
          console.log('channel voice', channel);

          // Subscribe on events
          if (channel) {
            channel.on(VoIPWebsocketEventTypes.ONLINE_USERS, (payload) => {
              console.log('ONLINE_USERS payload', payload);
              dispatch({
                type: VoIPActionTypes.SET_USER_ONLINE,
                payload: {
                  joins: payload,
                },
              });
            });

            channel.on(VoIPWebsocketEventTypes.PRESENCE_DIFF, (payload) => {
              console.log('PRESENCE_DIFF payload', payload);
              dispatch({
                type: VoIPActionTypes.SET_USER_ONLINE,
                payload,
              });
            });
          }
        });
    }
  }, [auth, JSON.stringify(channels), socket, voipState.device]);

  useEffect(() => {
    return () => {
      if (voipState.device) {
        voipState.device.destroy();
      }
    };
  }, []);

  const getToken = async () => {
    const response = await api.get('/v2/calls/twilio/token');
    return response.data.data.token;
  };

  const initDevice = async () => {
    const token = await getToken();
    setTwilioToken(token);
    const device = new Device(token, { logLevel: 'info', allowIncomingWhileBusy: true });
    await device.register();
    device.on('tokenWillExpire', () => {
      return getToken().then((token) => device.updateToken(token));
    });
    device.on('error', (deviceError) => {
      console.log('deviceError ', deviceError);
      // the following table describes how deviceError will change with this feature flag
    });
    dispatch({
      type: VoIPActionTypes.ADD_DEVICE,
      payload: device,
    });
    device.on('incoming', handleIncomingCall(token));
  };

  const handleIncomingCall = (token: string) => (incomingCall: Call) => {
    console.log(incomingCall, 'incoming call');
    const uuid = uuidv7();

    dispatch({
      type: VoIPActionTypes.ADD_INCOMING_CALL,
      payload: {
        id: uuid,
        call: incomingCall,
        callSid: incomingCall?.parameters?.CallSid,
      },
    });

    incomingCall.on('accept', () => {
      dispatch({
        type: VoIPActionTypes.ADD_CALL_STATUS,
        payload: CallStatusTypes.INCOMING_ACCEPTED,
      });

      // Forward this call to a new Device instance using the call.connectToken string.
      incomingCall?.connectToken && forwardCall(incomingCall?.connectToken, token, uuid);

      console.log('The call was accepted. Media session is set up.');
    });

    incomingCall.on('cancel', () => {
      dispatch({
        type: VoIPActionTypes.DESTROY_INCOMING_CALL,
        payload: incomingCall?.parameters?.CallSid,
      });
      dispatch({
        type: VoIPActionTypes.ADD_CALL_STATUS,
        payload: null,
      });
      console.log('The incoming call has been canceled.');
    });

    incomingCall.on('reject', () => {
      dispatch({
        type: VoIPActionTypes.DESTROY_INCOMING_CALL,
        payload: incomingCall?.parameters?.CallSid,
      });
      console.log('The incoming call has been rejected.');
    });

    incomingCall.on('error', (callError: Error) => {
      dispatch({
        type: VoIPActionTypes.DESTROY_INCOMING_CALL,
        payload: incomingCall?.parameters?.CallSid,
      });
      console.error('Call error:', callError);
    });

    incomingCall.on('disconnect', () => {
      dispatch({
        type: VoIPActionTypes.DESTROY_INCOMING_CALL,
        payload: incomingCall?.parameters?.CallSid,
      });
      console.log('The incoming call was disconnected.');
    });
  };

  // The forwardCall function may look something like the following.
  async function forwardCall(connectToken: string, token: string, id: string) {
    // For each incoming call, create a new Device instance for interaction
    const device = new Device(token, { logLevel: 'info', allowIncomingWhileBusy: true });
    const call = await device.connect({ connectToken });

    // Destroy the Device after the call is completed
    handleOutgoingCall(id, call, '', '', device);
  }

  const makeOutgoingCall = async (
    channelPhone: string,
    contactPhone: string,
    userId: string,
    contactName?: string
  ) => {
    // return, If twilio hasn't registered user device.
    if (!voipState.device) {
      console.error('Device is not initialized.');
      return;
    }

    try {
      if (channelPhone && contactPhone && userId) {
        const uuid = uuidv7();
        const outgoingCall = await voipState.device.connect({
          params: { From: channelPhone, To: contactPhone, user_id: userId },
        });

        dispatch({
          type: VoIPActionTypes.ADD_OUTGOING_CALL,
          payload: {
            id: uuid,
            call: outgoingCall,
            callSid: outgoingCall?.parameters?.CallSid,
            channelPhone,
            participants: [
              {
                callSid: outgoingCall?.parameters?.CallSid,
                phone: contactPhone,
                name: contactName,
                isOnHold: false,
                isMute: false,
              },
            ],
          },
        });

        handleOutgoingCall(uuid, outgoingCall, channelPhone, contactName);
      }
    } catch (error) {
      console.error('Error making the call:', error);
      error && toast.error(`${error}`);
    }
  };

  const handleOutgoingCall = (
    id: string,
    outgoingCall: Call,
    channelPhone: string,
    contactName?: string,
    device?: Device
  ) => {
    outgoingCall.on('accept', (outgoingCall) => {
      console.log('accept');
      let participantName = contactName || '';
      const participantPhone =
        outgoingCall?.parameters?.From || outgoingCall?.customParameters?.get('To');
      dispatch({
        type: VoIPActionTypes.ACCEPT_OUTGOING_CALL,
        payload: {
          id,
          call: outgoingCall,
          callSid: outgoingCall?.parameters?.CallSid,
          channelPhone,
          participants: [
            {
              callSid: outgoingCall?.parameters?.CallSid,
              phone: participantPhone,
              name: participantName,
              isOnHold: false,
              isMute: false,
            },
          ],
        },
      });
      if (!participantName && participantPhone) {
        searchContacts(
          [
            {
              column: 'phone',
              comparison: '==',
              resource: 'contact',
              value: participantPhone,
            },
          ],
          [],
          5,
          0
        ).then((res) => {
          participantName = res?.data?.[0]?.name || '';
          dispatch({
            type: VoIPActionTypes.UPDATE_PARTICIPANT,
            payload: {
              callSid: outgoingCall?.parameters?.CallSid,
              phone: participantPhone,
              name: participantName,
            },
          });
        });
      }
      console.log('The call was accepted. Media session is set up.');
    });

    outgoingCall.on('cancel', () => {
      dispatch({
        type: VoIPActionTypes.DESTROY_OUTGOING_CALL,
        payload: outgoingCall?.parameters?.CallSid,
      });
      console.log('The outgoing call has been canceled.');
    });

    outgoingCall.on('error', (callError: Error) => {
      dispatch({
        type: VoIPActionTypes.DESTROY_OUTGOING_CALL,
        payload: outgoingCall?.parameters?.CallSid,
      });
      console.error('Call error:', callError);
    });

    outgoingCall.on('disconnect', () => {
      dispatch({
        type: VoIPActionTypes.DESTROY_OUTGOING_CALL,
        payload: outgoingCall?.parameters?.CallSid,
      });
      console.log('The outgoing call was disconnected.');
      device?.destroy();
    });

    outgoingCall.on('reject', () => {
      dispatch({
        type: VoIPActionTypes.DESTROY_OUTGOING_CALL,
        payload: outgoingCall?.parameters?.CallSid,
      });
      console.log('The call was rejected.');
    });
    console.log(outgoingCall, 'outgoingCall');
  };

  const acceptIncomingCall = (callSid: string) => {
    const uuid = uuidv7();
    const callData = voipState.incomingCalls?.find(
      (c: CallType) => c?.callSid === callSid
    );
    console.log('call accepted', callData);
    dispatch({
      type: VoIPActionTypes.ADD_CALL_STATUS,
      payload: CallStatusTypes.INCOMING_ACCEPTED,
    });

    // Forward this call to a new Device instance using the call.connectToken string.
    if (callData?.call?.connectToken && twilioToken) {
      forwardCall(callData?.call?.connectToken, twilioToken, uuid);
    } else {
      // use accept the call method if no call.connectToken
      callData?.call?.accept();
    }
  };

  const rejectCall = (callSid: string) => {
    const callData = voipState.incomingCalls?.find(
      (c: CallType) => c?.callSid === callSid
    );
    console.log('call reject', callData);
    console.log('call reject callSid', callSid);
    callData?.call?.reject();
  };

  const ignoreIncomingCall = (callSid: string) => {
    const callData = voipState.incomingCalls?.find(
      (c: CallType) => c?.call?.parameters?.CallSid === callSid
    );
    callData?.call?.ignore();
  };

  const hangUp = (callSid: string) => {
    const callData = voipState.calls?.find((c: CallType) => c?.callSid === callSid);
    callData?.call?.disconnect();
  };

  const newCall = (defaultChannel: string) => {
    dispatch({
      type: VoIPActionTypes.SET_DEFAULT_VOIP_CHANNEL,
      payload: defaultChannel,
    });
    dispatch({
      type: VoIPActionTypes.ADD_CALL_STATUS,
      payload: CallStatusTypes.NEW_OUTGOING,
    });
  };

  const clearCallStatus = () => {
    dispatch({
      type: VoIPActionTypes.ADD_CALL_STATUS,
      payload: null,
    });
  };

  const startCallRecord = async (callSid: string, noToast?: boolean) => {
    try {
      const data = await startCallRecording(callSid);

      if (data) {
        dispatch({
          type: VoIPActionTypes.START_CALL_RECORDING,
          payload: { ...data, callSid },
        });

        !noToast && toast.success('Call Recording is started');
      }
    } catch (error) {
      console.log('Call start recording error', error);
      !noToast && toast.error('Start Call Recording failure');
    }
  };

  const stopCallRecord = async (callSid: string, recording_id: string) => {
    try {
      await stopCallRecording(callSid, recording_id);

      dispatch({
        type: VoIPActionTypes.STOP_CALL_RECORDING,
        payload: callSid,
      });

      toast.success('Call Recording is stopped');
    } catch (error) {
      console.log('Call stop recording error', error);
      toast.error('Stop Call Recording failure');
    }
  };

  const addParticipantToCall = async (
    callSid: string,
    phone?: string,
    userId?: number
  ) => {
    try {
      await addCallParticipant(callSid, phone, userId);

      dispatch({
        type: VoIPActionTypes.ADD_PARTICIPANT,
        payload: { callSid, phone, userId },
      });
    } catch (error) {
      console.log('Add participant error', error);
      if (error?.response?.data?.errors) {
        toast.error(error.response?.data.errors.message);
      }
    }
  };

  const deleteParticipantFromCall = async (
    callSid: string,
    phone?: string,
    userId?: number
  ) => {
    try {
      console.log('phone', phone);
      await deleteCallParticipant(callSid, phone, userId);

      dispatch({
        type: VoIPActionTypes.DELETE_PARTICIPANT,
        payload: { callSid, phone },
      });
    } catch (error) {
      console.log('Delete participant error', error);
      if (error?.response?.data?.errors) {
        toast.error(error.response?.data.errors.message);
      }
    }
  };

  const holdParticipant = async (
    callSid: string,
    hold: boolean,
    phone?: string,
    userId?: number
  ) => {
    try {
      await holdCallParticipant(callSid, hold, phone, userId);

      dispatch({
        type: VoIPActionTypes.UPDATE_PARTICIPANT,
        payload: { callSid, isOnHold: hold, phone, userId },
      });
    } catch (error) {
      console.log('Add participant error', error);
      if (error?.response?.data?.errors) {
        toast.error(error.response?.data.errors.message);
      }
    }
  };

  return (
    <VoIPContext.Provider
      value={{
        voipState,
        initDevice,
        makeOutgoingCall,
        acceptIncomingCall,
        rejectCall,
        ignoreIncomingCall,
        hangUp,
        newCall,
        clearCallStatus,
        startCallRecord,
        stopCallRecord,
        addParticipantToCall,
        deleteParticipantFromCall,
        holdParticipant,
      }}
    >
      {children}
    </VoIPContext.Provider>
  );
};

export default VoIPState;
