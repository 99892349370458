export const listOfUseCases = [
  {
    type: '2FA',
    value: '2FA',
  },
  {
    type: 'Account Notification',
    value: 'ACCOUNT_NOTIFICATION',
  },
  {
    type: 'Agents; franchise; local branches',
    value: 'AGENTS_FRANCHISES',
  },
  {
    type: 'Charity / 501(c)(3) Nonprofit',
    value: 'CHARITY',
  },
  {
    type: 'Proxy',
    value: 'PROXY',
  },
  {
    type: 'Customer Care',
    value: 'CUSTOMER_CARE',
  },
  {
    type: 'Delivery Notification',
    value: 'DELIVERY_NOTIFICATION',
  },
  {
    type: 'Emergency',
    value: 'EMERGENCY',
  },
  {
    type: 'Fraud Alert Messaging',
    value: 'FRAUD_ALERT',
  },
  {
    type: 'Higher Education',
    value: 'HIGHER_EDUCATION',
  },
  {
    type: 'K-12 Education',
    value: 'K12_EDUCATION',
  },
  {
    type: 'Low Volume Mixed',
    value: 'LOW_VOLUME',
  },
  {
    type: 'Marketing',
    value: 'MARKETING',
  },
  {
    type: 'Mixed',
    value: 'MIXED',
  },
  {
    type: 'Political',
    value: 'POLITICAL',
  },
  {
    type: 'Polling and voting',
    value: 'POLLING_VOTING',
  },
  {
    type: 'Public Service Announcement',
    value: 'PUBLIC_SERVICE_ANNOUNCEMENT',
  },
  {
    type: 'Security Alert',
    value: 'SECURITY_ALERT',
  },
  {
    type: 'Social',
    value: 'SOCIAL',
  },
  {
    type: 'Sweepstake',
    value: 'SWEEPSTAKE',
  },
];

export const optinKeywords = [
  {
    type: 'START',
    value: 'START',
  },
  {
    type: 'BEGIN',
    value: 'BEGIN',
  },
];

export const optOutKeywords = [
  {
    type: 'STOP',
    value: 'STOP',
  },
  {
    type: 'STOPALL',
    value: 'STOPALL',
  },
  {
    type: 'UNSUBSCRIBE',
    value: 'UNSUBSCRIBE',
  },
  {
    type: 'CANCEL',
    value: 'CANCEL',
  },
  {
    type: 'END',
    value: 'END',
  },
  {
    type: 'QUIT',
    value: 'QUIT',
  },
];

export const helpKeywords = [
  {
    type: 'HELP',
    value: 'HELP',
  },
  {
    type: 'INFO',
    value: 'INFO',
  },
];

export const industryList = [
  {
    type: 'None',
    value: 'none',
  },
  {
    type: 'Real State',
    value: 'real_state',
  },
  {
    type: 'Staffing',
    value: 'staffing',
  },
  {
    type: 'Legal',
    value: 'legal',
  },
  {
    type: 'Pharmacy',
    value: 'pharmacy',
  },
  {
    type: 'Floral E-Commerce',
    value: 'floral_e_commerce',
  },
];

export const helpMessages = (name: string, industry: string) => {
  switch (industry) {
    case 'staffing':
      return {
        help_message: `${name}: Please reach out to us at [insert here phone number of company] for help.`,
        opt_in_message: `${name}: You've subscribed to receive messages from ${name} Msg & Data Rates May Apply. Message frequency varies. Reply HELP for help. Reply STOP to unsubscribe.`,
        opt_out_message: `${name}: You have successfully been unsubscribed, you will not receive any more messages from this number. Reply START to re-subscribe.`,
        opt_in_keywords: ['START'],
        opt_out_keywords: ['STOP'],
        help_keywords: ['HELP'],
        messageSample1:
          'Hi, please reply with your name to confirm that you are on your way to your shift today. Text stop to opt out',
        messageSample2:
          'Hi, we have jobs available today. Please reply with your name to confirm that you are interested in this position and a member of our team will call you. Text stop to opt out',
        messageSample3:
          'Hi, thank you for messaging, a member of our team will get in touch with you shortly. Thank you for your patience. Text stop to opt out',
        messageSample4:
          'Hi there! To log your hours for today, please reply with your name followed by your shift start time. Text stop to opt out.',
        messageSample5:
          'Hello! Interested in joining our team? Reply with your name to express your interest in available positions. A member of our team will reach out to discuss opportunities. Text stop to opt out',
        subscriber_opt_in: true,
        subscriber_opt_out: true,
        subscriber_help: true,
        embedded_link: true,
        auto_renewal: false,
        description:
          'This staffing agency hires people who want to receive opportunities for temporary placements into job contracts. Messaging for local staffing agency for communicating with current associates including marketing messages of temporary placements will be sent.',
        message_flow:
          'Subscribers are opted in when they are hired in person, and sign an optional agreement agreeing to receive text messages about temporary job placements, it looks like the following: [insert form link]',
      };
    case 'pharmacy':
      return {
        help_message: `${name}: For assistance with our messaging service, please contact us at [insert phone number]. Reply STOP to unsubscribe.`,
        opt_in_message: `${name}: You have successfully subscribed to receive messages. Msg & Data Rates May Apply. Reply STOP to unsubscribe.`,
        opt_out_message: `${name}: Please reach out to us at [insert here phone number of pharmacy] for help.`,
        opt_in_keywords: ['START'],
        opt_out_keywords: ['STOP'],
        help_keywords: ['HELP'],
        messageSample1: `Hello [First Name], your prescription from ${name} is ready for pickup. Text STOP to opt out`,
        messageSample2: `Hello [First Name], your prescription is being prepared by ${name}. Would you like to ship the prescription or pick up in person when it is ready? Text STOP to opt out`,
        messageSample3: `Hello [First Name], your prescription refills are about to expire. Please let us know if we at ${name} should contact your medical provider to get additional refills. Text STOP to opt out`,
        messageSample4: `[First Name], ${name} is offering up to 15% off on shipping this month. Reply SHIP for more info. Text STOP to opt out`,
        subscriber_opt_in: true,
        subscriber_opt_out: true,
        subscriber_help: true,
        embedded_link: true,
        direct_lending_or_loan_arrangement: false,
        number_pooling: false,
        embedded_phone_number: false,
        affiliate_marketing: false,
        age_gated_content: false,
        description:
          'Messaging for local compounding pharmacy for communicating with existing customers and responding to text inquiries from potential new customers sending notifications and marketing messages.',
        message_flow: `Customers can opt in to receive text messages about their prescriptions being filled, appointments, marketing, and new services through an online form or as well by email subscription: [insert form link]`,
      };

    default:
      return null;
  }
};
