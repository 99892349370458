import moment, { Moment } from 'moment';
import { Dispatch, useState } from 'react';
import React from 'react';
import { HiArrowRight } from 'react-icons/hi';

import { DatepickerController } from '@/shared/components/datepicker/DatepickerController';
import {
  Box,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
} from '@/shared/ui';
import { styled } from '@/stitches.config';

export type DatepickerDates = {
  startDate: Moment;
  endDate: Moment;
  time_bucket?: string;
};

export const dateOption = [
  {
    label: 'Today',
    value: {
      startDate: moment(),
      endDate: moment(),
    },
  },
];

const pastDateOption = [
  {
    label: 'Yesterday',
    value: {
      startDate: moment().add(-1, 'day'),
      endDate: moment().add(-1, 'day'),
    },
  },
  {
    label: 'Past week',
    value: {
      startDate: moment().add(-1, 'week'),
      endDate: moment(),
    },
  },
  {
    label: 'Past 4 weeks',
    value: {
      startDate: moment().add(-4, 'week'),
      endDate: moment(),
    },
  },
  {
    label: 'Past 12 weeks',
    value: {
      startDate: moment().add(-12, 'week'),
      endDate: moment(),
    },
  },
  {
    label: 'Past 6 months',
    value: {
      startDate: moment().add(-6, 'month'),
      endDate: moment(),
    },
  },
  {
    label: 'Past 12 months',
    value: {
      startDate: moment().add(-12, 'month'),
      endDate: moment(),
    },
  },
];

const nextDateOption = [
  {
    label: 'Next week',
    value: {
      startDate: moment(),
      endDate: moment().add(1, 'week'),
    },
  },
  {
    label: 'Next 4 weeks',
    value: {
      startDate: moment(),
      endDate: moment().add(4, 'week'),
    },
  },
  {
    label: 'Next 12 weeks',
    value: {
      startDate: moment(),
      endDate: moment().add(12, 'week'),
    },
  },
  {
    label: 'Next 6 months',
    value: {
      startDate: moment(),
      endDate: moment().add(6, 'month'),
    },
  },
  {
    label: 'Next 12 months',
    value: {
      startDate: moment(),
      endDate: moment().add(12, 'month'),
    },
  },
];

type setDatesFunction = (date: DatepickerDates) => void;

export const DateSelector = ({
  dates,
  setDates,
  minDate,
  maxDate,
  minDaysRange,
  align = 'start',
}: {
  dates: DatepickerDates;
  setDates: Dispatch<React.SetStateAction<DatepickerDates>> | setDatesFunction;
  minDate?: string;
  maxDate?: string;
  minDaysRange?: number;
  align?: 'start' | 'center' | 'end';
}) => {
  const [open, setOpen] = useState(false);
  return (
    <DropdownMenu open={open} onOpenChange={(value) => setOpen(value)}>
      <DropdownMenuTrigger asChild>
        <DropdownTrigger
          data-testid="date-selector-trigger"
          align="center"
          justify="center"
          gap={2}
        >
          <Box>{dates.startDate?.format('MMM D, YYYY')}</Box>
          <HiArrowRight />
          <Box>{dates.endDate?.format('MMM D, YYYY')}</Box>
        </DropdownTrigger>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        css={{ padding: 0, paddingTop: 2 }}
        sideOffset={10}
        align={align}
      >
        <Flex>
          <Box data-testid="datepicker">
            <DatepickerController
              closeDateSelector={() => setOpen(false)}
              minDaysRange={minDaysRange ?? 0}
              setParentDates={(value) => {
                setDates(value);
              }}
              initialDates={dates}
              minDate={minDate}
              maxDate={maxDate}
            />
          </Box>
          <Box
            css={{
              padding: '22px 20px 8px 20px',
            }}
          >
            {[...dateOption, ...(minDate ? nextDateOption : pastDateOption)].map(
              (option) => {
                return (
                  <DropdownMenuItem
                    data-testid="date-selector-item"
                    css={{
                      '&:hover': {
                        backgroundColor: '#3A5CCC',
                        color: '#fff',
                      },
                      margin: '0 4px',
                      height: '32px',
                      padding: '0 12px',
                      borderRadius: 4,
                      ...(option.value.startDate === dates.startDate &&
                      option.value.endDate === dates.endDate
                        ? {
                            background: '#00003B0D',
                          }
                        : {}),
                    }}
                    key={option.label}
                    onSelect={() => {
                      setDates(option.value);
                    }}
                  >
                    <Flex css={{ fontSize: 14, padding: '8px 0px', cursor: 'pointer' }}>
                      {option.label}
                    </Flex>
                  </DropdownMenuItem>
                );
              }
            )}
          </Box>
        </Flex>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const DropdownTrigger = styled(Flex, {
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'center',
  backgroundColor: 'white',
  justifyContent: 'space-between',
  borderRadius: 4,
  color: 'hsl(204 10% 10%)',
  border: '1px solid $slate7',
  padding: '$space$1 $space$3',
  height: 40,
  fontSize: '14px',
  minWidth: 'max-content',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
