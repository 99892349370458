import { Formik } from 'formik';
import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { MultiStepForm } from '@/settings/organization/channels/utils/MultiStepForm';
import {
  BrandFormValidation,
  InitialBrandFormData,
  steps,
} from '@/settings/organization/trust/brands/createBrandView/CreateBrandFormData';
import { validateBrandStep } from '@/settings/organization/trust/brands/createBrandView/utils';
import { createBrand } from '@/shared/api/tendlc';
import { SettingsLayout } from '@/shared/layouts';
import { CreateBrandType } from '@/shared/types/trust/brands';
import { Flex } from '@/shared/ui';
import { formatEIN, toE164 } from '@/shared/utils/validations/validations';

import { TrustLayout } from '../../TrustLayout';

const breadcrumbs = [
  { title: 'Settings', path: '/settings/trust/brands' },
  { title: 'Trust Center', path: `/settings/trust/brands` },
  { title: 'Brands', path: `/settings/trust/brands` },
  { title: 'Create Brand', path: `/settings/trust/brands/create` },
];

export const CreateBrandView = () => {
  const history = useHistory();
  const handleComplete = async (values: CreateBrandType) => {
    const params = {
      ...values,
      country: 'US',
      phone: toE164(values.phone),
      alt_business_registration_type:
        values.alt_business_registration_type !== 'NONE'
          ? values.alt_business_registration_type
          : '',
      representative_phone: toE164(values.representative_phone),
      representative_name:
        values.representative_first_name + ' ' + values.representative_last_name,
      universal_ein: formatEIN(values.universal_ein),
      business_registration_number: formatEIN(values.business_registration_number),
      alt_business_registration_number:
        values.alt_business_registration_number !== ''
          ? formatEIN(values.alt_business_registration_number)
          : '',
    };
    try {
      await createBrand(params);
      history.push('/settings/trust/brands');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <TrustLayout>
      <SettingsLayout
        background="white"
        padding="0"
        width="100%"
        breadcrumbs={breadcrumbs}
      >
        <Flex css={{ p: 30 }} direction="column">
          <Formik
            initialValues={InitialBrandFormData}
            validationSchema={BrandFormValidation}
            onSubmit={(values) => handleComplete(values)}
          >
            {(formikProps) => (
              <form onSubmit={(e) => e.preventDefault()}>
                <MultiStepForm
                  steps={steps}
                  onComplete={formikProps.handleSubmit}
                  formData={formikProps.values}
                  onStepValidation={validateBrandStep}
                  onCompleteText="Create Brand"
                />
              </form>
            )}
          </Formik>
        </Flex>
      </SettingsLayout>
    </TrustLayout>
  );
};
