/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { HiPaperAirplane, HiPencil, HiTag, HiUserRemove } from 'react-icons/hi';

import { useCampaignsContext } from '@/campaigns/context/CampaignsContext';
import { QuickCampaignDialog } from '@/campaigns/quick';
import { ConversationDrawer } from '@/inbox/drawer';
import { UnsubscribePreferenceErrorBoundary } from '@/pages/data/contact/preferences/boundaries/UnsubscribePreferenceErrorBoundaries';
import { UnsubscribePreference } from '@/pages/data/contact/preferences/UnsubscribePreference';
import { AddContactTags } from '@/pages/data/contacts/tags/AddContactTags';
import { AddSingleContactTags } from '@/pages/data/contacts/tags/AddSingleContactTags';
import { useTags } from '@/pages/settings/organization/tags/context/TagsContext';
import { Contact } from '@/shared/types/campaigns';
import { Box, Button, Flex, HStack, IconButton } from '@/shared/ui';

import { TooltipButton } from '../v2/analytics/CampaignActions';

type IndividualCampaignContactsActionsProps = {
  /** the contact object */
  contact: Contact;
  /** the location of the campaign */
  location_id: string;
};

export const IndividualCampaignContactsActions = (
  props: IndividualCampaignContactsActionsProps
) => {
  const { contact, location_id } = props;

  const tagsContext = useTags();
  const { allTags } = tagsContext.tagsState;

  return (
    <Flex css={{ width: '100%', pr: 15 }} justify="end">
      <HStack gap={3}>
        <Box>
          <UnsubscribePreferenceErrorBoundary feature="UnsubscribePreference">
            <UnsubscribePreference contact={contact}>
              <TooltipButton text="Un-subscribe Contact">
                <IconButton size={2} data-testid="unsubscribe-button">
                  <HiUserRemove />
                </IconButton>
              </TooltipButton>
            </UnsubscribePreference>
          </UnsubscribePreferenceErrorBoundary>
        </Box>
        {allTags.length > 0 && (
          <Box>
            <AddSingleContactTags contactId={contact.id} isCampaignsPage>
              <TooltipButton text="Add Tags">
                <IconButton size={2} data-testid="add-tags-button">
                  <HiTag />
                </IconButton>
              </TooltipButton>
            </AddSingleContactTags>
          </Box>
        )}
        <Box>
          <ConversationDrawer
            contact_id={contact.id}
            contact_name={contact.name || '-'}
            contact_phone={contact.phone || ''}
            location_id={location_id || ''}
          >
            <IconButton size={2} data-testid="conversation-button">
              <HiPencil />
            </IconButton>
          </ConversationDrawer>
        </Box>
      </HStack>
    </Flex>
  );
};

export const BulkCampaignContactsActions = () => {
  const campaigns = useCampaignsContext();
  const { campaignsState, setSelectedContacts, currentTab } = campaigns;
  const { current, allSelected, selectedContacts } = campaignsState;

  // tags state
  const tagsContext = useTags();
  const { allTags } = tagsContext.tagsState;

  // quick dialog state
  // show bulk message dialog state
  const [quickCampaignDialogState, setQuickCampaignDialogState] = useState(false);

  const quickCampaignDialogStateChange = () => {
    setQuickCampaignDialogState(!quickCampaignDialogState);
    setShowSchedule(false);
  };

  // show bulk message schedule state
  const [showSchedule, setShowSchedule] = useState(false);

  const toggleSchedule = () => {
    setShowSchedule(!showSchedule);
  };

  return (
    <HStack gap={2}>
      {allTags.length > 1 && (
        <Box>
          <AddContactTags
            tags={allTags}
            isBulkAdd={true}
            isCampaignsPage={true}
            checkedContacts={selectedContacts as unknown as string[]}
            setCheckedContacts={(contacts: Contact[] | string[]) => {
              if (typeof contacts[0] === 'string') {
                // Handle the case where contacts is a string array
                // You need to convert the strings to Contact objects
                setSelectedContacts(selectedContacts);
                return;
              } else {
                // Handle the case where contacts is a Contact array
              }
            }}
            campaignId={current?.id}
            currentTab={currentTab}
          >
            <IconButton variant="outline" size="2" data-testid="add-tags-button">
              <HiTag />
            </IconButton>
          </AddContactTags>
        </Box>
      )}
      <QuickCampaignDialog
        quickCampaignDialogState={quickCampaignDialogState}
        setQuickCampaignDialogState={setQuickCampaignDialogState}
        quickCampaignDialogStateChange={quickCampaignDialogStateChange}
        showSchedule={showSchedule}
        toggleSchedule={toggleSchedule}
        checkedContacts={selectedContacts}
        setCheckedContacts={setSelectedContacts}
        allContactsChecked={allSelected}
        audience={{
          campaign_id: current?.id,
          campaign_contacts_type: currentTab,
        }}
        isCampaignsPage={true}
        currentCampaign={current}
        isContactsPage={false}
        isGroupsPage={false}
        isUploadsPage={false}
      >
        <Button as="div" variant="send" data-testid="send-message-button">
          Send Message <HiPaperAirplane style={{ transform: 'rotate(90deg)' }} />
        </Button>
      </QuickCampaignDialog>
    </HStack>
  );
};
