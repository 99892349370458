/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { MessageEditorV2 } from '@/shared/components/editor/v2';
import { Attachments } from '@/shared/components/editor/v2/constants';
import { renderText } from '@/shared/components/markdown/MarkdownRenderer';
import { Signature } from '@/shared/types';
import { JSONObject } from '@/shared/types/campaigns';
import { Box, Flex } from '@/shared/ui';

import { Img } from './panel/PreviewMessage';

export type CampaignTextEmailEditorProps = {
  subject: string;
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  attachments: Attachments;
  setAttachments: Dispatch<SetStateAction<Attachments>>;
  emailCampaignJSONBody?: JSONObject;
  isHTMLMessage: boolean;
  channel_type?: 'phone' | 'email' | 'whatsapp';
  updateTemplate?: boolean;
  height?: string | number;
};

export const CampaignTextEmailEditor = (
  props: CampaignTextEmailEditorProps
): JSX.Element => {
  const {
    subject,
    message,
    setMessage,
    attachments,
    setAttachments,
    channel_type = 'phone',
    emailCampaignJSONBody,
    isHTMLMessage,
    updateTemplate,
    height,
  } = props;
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [attachmentLoading, setAttachmentLoading] = useState<boolean>(false);
  const [textMessage, setTextMessage] = useState(!emailCampaignJSONBody ? message : '');
  const [signature, setSignature] = useState<Signature | null>(null);

  useEffect(() => {
    if (!isHTMLMessage) {
      setMessage(textMessage ?? '');
    }
  }, [isHTMLMessage]);

  useEffect(() => {
    if (updateTemplate && !isHTMLMessage) {
      setTextMessage(message);
    }
  }, [updateTemplate]);

  return (
    <Flex
      css={{
        minHeight: height ?? 320,
        maxHeight: height ?? 700,
        display: isHTMLMessage ? 'none' : 'flex',
      }}
    >
      <Flex
        css={{
          width: '50%',
          marginRight: 8,
          position: 'relative',
        }}
      >
        <MessageEditorV2
          placeholder="Enter email text here"
          textareaRef={textareaRef}
          message={textMessage}
          setMessage={(v) => {
            setTextMessage(v);
            setMessage(v);
          }}
          attachments={attachments}
          setAttachments={setAttachments}
          enableAttachments={true}
          attachmentLoading={attachmentLoading}
          setAttachmentLoading={setAttachmentLoading}
          isForm={true}
          signature={signature}
          setSignature={setSignature}
          showAddTemplate={true}
          showAddSignature={true}
          showAddAttachment={true}
          showAddVariable={true}
          showAddEmoji={true}
          showCharacterCount={true}
          showAddTranslate={true}
          sendMessageFromTranslationDialog={false}
          channel_type={channel_type}
          maxHeight={height ?? 700}
          minHeight={height ?? 320}
          isCampaignTemplate
        />
      </Flex>
      <Box
        css={{
          width: '50%',
          border: '1px solid #0000301B',
          marginLeft: 8,
          borderRadius: 6,
          padding: '12px 15px',
          overflow: 'auto',
          background: '#FFF',
        }}
      >
        {attachments?.attachment_urls.map((url, index) => (
          <Img
            css={{ height: 'auto', maxWidth: '100%' }}
            key={index}
            src={url}
            alt="image-preview"
          />
        ))}
        <Box>
          <span style={{ fontWeight: 700 }}>To:</span>{' '}
          {'Example Contact <example@example.com>'}
        </Box>
        <Box>
          <span style={{ fontWeight: 700 }}>Subject:</span> {subject}
        </Box>
        <Box css={{ mt: 16 }}>
          {renderText(`${textMessage}\n${signature?.body ?? ''}`)}
        </Box>
      </Box>
    </Flex>
  );
};
