import i18next from 'i18next';

i18next.init(
  {
    supportedLngs: ['en'],
    interpolation: {
      escapeValue: false,
      defaultVariables: {
        user_name: 'A user',
        prev_location_name: 'Unknown Channel',
        current_location_name: 'Unknown Channel',
        contact_name: 'The contact',
        sequence_name: 'The sequence',
        assigned_user_name: 'The user',
      },
    },
    lng: 'en-US',
    fallbackLng: 'en-US',
    resources: {
      en: {
        translation: {
          // Translations for notification types
          new_lead: 'An inbound lead from an external source.',
          assigned_user: 'You are assigned to a conversation.',
          new_message: 'A conversation you are assigned to received a message',
          new_message_for_all_conversation: 'Any new message, regardless of assignee.',
          new_message_for_unassigned_conversation:
            'Any new message, in a conversation with no assignee.',
          no_response_short:
            'An open conversation you are assigned to is waiting 30 minutes.',
          mention: 'You are mentioned in a conversation.',
          no_response_long: 'An open conversation you are assigned is waiting hours.',
          import_status: 'An import you have uploaded has been processed.',
          keyword_triggered: 'A keyword you are following has been triggered.',
          billing_usage: "Organization's billing usage exceeds 75%, 100%, or 150%.",
          link_click: 'A contact clicked on a link from one of your messages.',
          link_click_for_all_messages: 'A contact clicked on a link from any message.',
          call_started: 'A call has started in a location you have access to.',
          call_ended: 'A call has ended in a location you have access to.',
          call_missed: 'A call was missed in a location(channel) you have access to.',
          incoming_calls:
            'An incoming call for a VOIP Call to a channel you have access to',
          // Translations for sms error messages
          message_exceeds_organization_maximum_credits:
            'Message Exceeds Admin Defined Max Length',
          request_failed: 'Request Failed',
          unreachable_destination_handset: 'Unreachable Destination Handset',
          invalid_phone: 'Phone Number is Invalid',
          inactive_phone: 'Phone Number is Inactive',
          unexpected_error: 'Unexpected Error',
          request_timeout: 'The Request Timed Out',
          authentication_failed: 'Authentication Failed',
          authorization_failed: 'Authorization Failed',
          too_many_requests: 'Phone Number is Invalid',
          unsupported_media_type: 'Unsupported Attachment Type',
          service_unavailable: 'Messaging Service is Unavailable',
          insufficient_funds: 'Insufficient Funds',
          combined_size_of_media_too_large: 'Message or Attachment Too Large',
          undeliverable: 'Message Rejected by Recipient Carrier',
          invalid_message_body: 'Message Rejected by Recipient Carrier',
          ['at&t_10dlc_sending_limit_reached']: 'AT&T 10DLC Sending Limit Reached',
          ['at&t_10dlc_spam_message_rejected']: 'AT&T 10DLC Spam Message Rejected',
          ['t-mobile_10dlc_sending_limit_reached']:
            'T-Mobile 10DLC Sending Limit Reached',
          ['10DLC_approval_needed']: '10DLC Approval Needed',
          ['Invalid sender: The `from` number is unsupported.']: 'Invalid Sender',
          ["invalid_'to'_address"]: 'Invalid Recipient Phone Number',
          blocked_due_to_stop_message: 'Message blocked due to STOP message',
          blocked_as_spam_temporary: 'Message Flagged as SPAM',
          blocked_as_spam_permanent: 'Message Flagged as SPAM.',
          blocked_as_spam_internal: 'Message Flagged as SPAM',
          no_content_provided_for_message: 'No Content Provided for Message',
          invalid_mms_content: 'Invalid MMS Content',
          message_too_large: 'Message Too Large',
          number_not_messaging_enabled: 'Sender Number Cannot Send Messages',
          not_routable: 'Destination Number is Landline or Non-routable.',
          landline_or_unreachable_carrier: 'Unreachable Landline or Carrier',
          invalid_parameter_type: 'Invalid Parameter Type',
          invalid_messaging_source_number: 'Carrier Deemed Source Number as Invalid',
          invalid_messaging_destination_number:
            'Carrier Deemed Destination Number as Invalid',
          permission_to_send_an_sms_has_not_been_enabled_for_the_region_indicated_by_the_to_number_xxxx:
            'SMS Not Enabled For This Region',
          // Translations for Bandwidth error messages
          volume_violation_att: 'AT&T 10DLC Sending Limit Reached',
          volume_violation_tmo: 'T-Mobile 10DLC Sending Limit Reached',
          destination_rejected_due_to_volume_violation_tmo:
            'T-Mobile 10DLC Sending Limit Reached',
          destination_rejected_due_to_volume_violation_att:
            'AT&T 10DLC Sending Limit Reached',
          volume_violation_verizon: 'Verizon 10DLC Sending Limit Reached',
          destination_rejected_due_to_spam_detection: 'Message Flagged as SPAM',
          invalid_service_type: 'Invalid Service Type',
          receipt_expired: 'Receipt Expired',
          rejected_unallocated_from_number: 'Invalid Sender',
          impossible_to_route: 'Destination Number is Landline or Non-routable.',
          invalid_destination_address: 'Invalid Recipient Phone Number',
          invalid_destination: 'Invalid Recipient Phone Number',
          unallocated_to: 'Invalid Recipient Phone Number',
          destination_rejected: 'Message Rejected by Recipient Carrier',
          malformed_invalid_to_number: 'Invalid Recipient Phone Number',
          no_route_to_destination_carrier: 'No Route to Destination Carrier',
          destination_tmo_rejected_due_to_p2p_volumetric_violation:
            'T-Mobile Sending 10DLC Sending Limit Reached',
          rejected_forbidden_country: 'Forbidden Country',
          recipient_server_unavailable: 'Recipient Cannot Accept Messages',
          destination_rejected_message: 'Message Rejected by Recipient Carrier',
          destination_service_unavailable: 'Recipient Cannot Accept Messages',
          destination_subscriber_unavailable:
            'Recipient Cannot Accept Messages at this Time',
          destination_rejected_message_size_invalid: 'Message Too Large',
          rejected_message_size_limit_exceeded: 'Message Too Large',
          media_content_invalid: 'Invalid MMS Content',
          destination_tn_deactivated: 'Destination Number is Inactive',
          destination_spam_detected: 'Message Flagged as SPAM',
          rejected_shortened_url: 'Message Flagged as SPAM for Shortened URL',
          rejected_tn_blocked: 'Message Blocked by Recipient Carrier',
          inactive_campaign: '10DLC Campaign is Inactive - Contact Support',
          destination_rejected_due_to_user_opt_out: 'Message Blocked by STOP Message',
          rejected_spam_detected: 'Message Flagged as SPAM',
          // Twilio error messages
          unsupported_content_type: 'Unsupported Attachment Type',
          unknown_destination_handset: 'Unknown Destination Handset',
          blocked_as_spam_twilio: 'Message Flagged as SPAM',
          // translations for toast messages
          socket_error: 'Socket error',
          email_required: 'Email is required',
          network_error: 'Network is offline. Check your connection',
          server_error: 'Server Error. Try Again Shortly',
          // webchat
          install_webchat_email_success: 'Email Sent',
          install_webchat_email_failure: 'Invalid Email',
          // contacts
          enter_valid_phone: 'Enter a valid phone number',
          enter_valid_email: 'Invalid email address',
          contacts_added_to_group: 'Contacts added to group',
          // onboarding, users, and settings
          enter_valid_organization_name: 'Enter a valid organization name',
          account_creation_failure: 'We could not create your new account',
          password_reset_email_sent: 'Password reset email sent. Check your email.',
          invite_already_accepted: 'Invalid invite code',
          invite_error: 'Error while accepting invite',
          incorrect_email_password: 'Incorrect email or password',
          no_access_to_organizations_error: 'You do not have access to any accounts',
          change_organization_error: 'Could not change organization',
          first_name_required: 'First name required',
          last_name_required: 'Last name required',
          name_too_short: 'Name must be at least 3 characters',
          phone_required: 'Phone is required',
          company_name_required: 'Company name required',
          password_too_short: 'Password must be at least 8 characters',
          password_required: 'A password is required',
          password_mismatch: 'Passwords do not match',
          organization_update_success: 'Organization updated',
          organization_update_failure: 'Organization update failed',
          organization_opt_out_preferences_success: 'Opt-out preferences updated',
          organization_opt_out_preferences_failure: 'Opt-out preferences updated failed',
          organization_link_tracking_settings_update_success:
            'Link Tracking Settings updated',
          organization_link_tracking_settings_update_failure:
            'Link Tracking Settings updated failed',
          organization_call_settings_update_success: 'Call Settings updated',
          organization_call_settings_update_failure: 'Call Settings updated failed',
          invite_sent: 'Invite sent',
          user_enabled: 'Existing user enabled',
          invite_delete_success: 'Invite deleted successfully',
          invite_delete_failure: 'Failed to delete invite',
          create_invite_error: 'Invite failed to send',
          user_update: 'User update successful',
          user_update_error: 'User update failed',
          user_disable: 'User disabled successful',
          user_disable_error: 'Failed to disable user',
          user_enable: 'User enabled successful',
          user_enable_error: 'Failed to enable user',
          invalid_phone_number: 'Phone number is being used for Channel',
          user_update_unselect_error: 'At least 1 location required',
          invite_invalid_email: 'Email does not match invite email',
          invite_invalid_token: 'Invalid invite token',
          brand_created_success: 'Brand created',
          brand_created_failure: 'Failed to create brand',
          tendlc_campaign_created_success: 'Campaign created',
          a_provider_brand_created_success: 'A Provider Brand created',
          a_provider_brand_created_failure: 'Failed to create a brand provider',
          a_provider_campaign_created_success: 'A Provider Campaign created',
          a_provider_campaign_created_failure: 'Failed to create a campaign provider',
          tendlc_campaign_created_failure: 'Failed to create campaign',
          // locations
          working_hours_not_set: 'You need to set working hours',
          location_update_success: 'Channel updated',
          location_update_failure: 'Channel update failed',
          location_creation_success: 'Channel created',
          location_creation_failure: 'Failed to create location',
          // tags
          tag_created_success: 'Tag created',
          tag_too_long: 'Tag too long',
          tag_updated_success: 'Tag updated',
          tag_updated_failure: 'Tag update failed',
          tags_updated_success: 'Tags updated',
          tags_updated_failure: 'Tags update failed',
          // signatures
          signature_created_success: 'Signature created',
          signature_too_long: 'Signature too long',
          signature_updated_success: 'Signature updated',
          signature_updated_failure: 'Signature update failed',
          signatures_updated_success: 'Signatures updated',
          signatures_updated_failure: 'Signatures update failed',
          signatures_updated_remove: 'Signature removed',
          signature_deleted_success: 'Signature deleted',
          signature_deleted_failure: 'Signature delete failed',
          // messages
          max_attachments: 'Only 10 attachments are allowed',
          file_size_limit: 'File size is too large',
          no_location: 'Select at least once location',
          upload_loading: 'Uploading file...',
          upload_success: 'File uploaded',
          adding_uploaded_file_to_template: 'Adding file to Template',
          upload_error: 'File upload failed',
          delete_success: 'File removed',
          // quick campaigns
          quick_campaign_sent: 'Quick campaign sent',
          quick_campaign_scheduled: 'Quick campaign scheduled',
          quick_campaign_scheduled_error: "Quick campaign wasn't scheduled",
          // templates
          template_created_success: 'Template created',
          template_updated_success: 'Template updated',
          template_updated_loading: 'Updating Template',
          template_updated_failure: 'Template update failed',
          template_deleted_success: 'Template deleted',
          template_deleted_failure: 'Template deletion failed',
          template_import_success: 'Templates imported',
          template_import_failure: 'Failed to import templates',
          // message editor - errors with features
          translate_error:
            'The Translation feature for message has an issue and is not available',
          schedule_error:
            'The Schedule message feature has an issue and is not available',
          variable_error: 'The Variable feature has an issue and is not available',
          attachment_error: 'The Attachment feature has an issue and is not available',
          template_error: 'The Template feature has an issue and is not available',
          review_link_error:
            'The Review Invitation Link feature has an issue and is not available',
          character_count_error:
            'The Character Count feature has an issue and is not available',
          emoji_error: 'The Emoji feature has an issue and is not available',
          signature_error: 'The Signature feature has an issue and is not available',
          send_message_error:
            'The Send Message feature has an issue and is not available',
          keyboard_shortcuts_error:
            'The Keyboard Shortcuts feature has an issue and is not available',
          show_scheduled_messages_error:
            'The Show Scheduled Messages feature has an issue and is not available',
          emoji_picker_button_error:
            'Could not load Search Templates [Button Reference Not Found]',
          template_picker_button_error:
            'Could not load Search Templates [Button Reference Not Found]',
          add_signature_error: 'Could not add signature',
          // automations - question bot
          question_created_success: 'Automated response created',
          question_created_failure: 'Failed to create question',
          question_updated_success: 'Automated response updated',
          question_updated_failure: 'Automated response update failed',
          question_deleted_success: 'Automated question deleted',
          question_deleted_failure: 'Failed to create question',
          question_enabled_success: 'Automated response enabled',
          question_enabled_failure: 'Failed to enable question',
          question_disabled_success: 'Automated response disabled',
          question_disabled_failure: 'Failed to disable question',
          // automations - keywords
          keyword_updated_success: 'Keyword updated',
          keyword_updated_failure: 'Keyword update failed',
          keyword_created_success: 'Keyword created',
          keyword_created_failure: 'Failed to create keyword',
          keyword_deleted_success: 'Keyword deleted',
          keyword_deleted_failure: 'Failed to delete keyword',
          // campaigns
          campaign_creation_success: 'Campaign created',
          campaign_creation_failure: 'Campaign was not created',
          campaign_update_success: 'Campaign updated',
          campaign_update_failure: 'Campaign was not updated',
          campaign_delete_success: 'Campaign deleted ',
          campaign_delete_failure: 'Campaign was not deleted',
          campaign_auto_saved: 'Auto-saving campaign',
          campaign_scheduled: 'Campaign scheduled',
          campaign_sent: 'Campaign in-progress',
          campaign_preview_sent: 'Campaign preview sent',
          campaign_preview_failure: 'Add phone number in settings',
          campaign_preview_empty: 'Message cannot be blank',
          campaign_set_draft: 'Campaign moved to drafts',
          campaign_duplicate_success: 'Campaign duplicated',
          campaign_duplicate_failure: 'Campaign duplication failed',
          campaign_archive_success: 'Campaign archived successfully',
          campaign_message_body: 'Campaign message updated',
          campaign_trigger_created_success: 'Campaign trigger created',
          campaign_trigger_created_failure: 'Failed to create campaign trigger',
          campaign_trigger_updated_success: 'Campaign trigger updated',
          campaign_trigger_updated_failure: 'Failed to update campaign trigger',
          campaign_trigger_deleted_success: 'Campaign trigger deleted',
          campaign_trigger_deleted_failure: 'Failed to delete campaign trigger',
          campaign_pause_success: 'Campaign progress updated',
          campaign_pause_failure: 'Failed to update campaign',
          // campaign templates
          campaign_template_created_success: 'Campaign template created',
          campaign_template_created_failure: 'Failed to create campaign template',
          campaign_template_updated_success: 'Campaign template updated',
          campaign_template_updated_failure: 'Failed to update campaign template',
          campaign_template_deleted_success: 'Campaign template deleted',
          campaign_template_deleted_failure: 'Failed to delete campaign template',
          campaign_template_duplicate_success: 'Campaign template duplicated',
          campaign_template_duplicate_failure: 'Failed to duplicate campaign template',
          // sequences
          sequence_updated_success: 'Sequence updated',
          sequence_updated_failure: 'Sequence update failed',
          sequence_created_success: 'Sequence created',
          sequence_created_failure: 'Failed to create sequence',
          sequence_step_updated_success: 'Sequence step updated',
          sequence_step_updated_failure: 'Sequence step update failed',
          sequence_step_created_success: 'Sequence step created',
          sequence_step_deleted_success: 'Sequence step deleted',
          sequence_step_deleted_failure: 'Failed to delete sequence step',
          sequence_step_created_failure: 'Failed to create sequence step',
          contact_added_to_sequence_success: 'Contacts added to sequence',
          contact_added_to_sequence_failure: 'Failed to add contacts to sequence',
          bulk_add_contacts_to_sequence_success: 'Contacts added to sequence',
          bulk_add_contacts_to_sequence_failure: 'Failed to add contacts to sequence',
          sequence_deleted_success: 'Sequence deleted',
          sequence_deleted_failure: 'Failed to delete sequence',
          sequence_duplicated_success: 'Sequence duplicated',
          sequence_duplicated_failure: 'Failed to duplicate sequence',
          sequence_template_created_success: 'Sequence template created',
          sequence_template_created_failure: 'Failed to create sequence template',
          sequence_template_updated_success: 'Sequence template updated',
          sequence_template_updated_failure: 'Failed to update sequence template',
          sequence_template_deleted_success: 'Sequence template deleted',
          sequence_template_deleted_failure: 'Failed to delete sequence template',
          // communication preferences
          contact_opted_out_success: 'Contact un-subscribed',
          contact_opted_out_failure: 'Failed to un-subscribe contact',
          contact_blocked_success: 'Blocked status updated',
          contact_blocked_failure: 'Failed to update blocked status',
          sequence_contact_inactive_success: 'Contact marked inactive',
          sequence_contact_inactive_failure: 'Failed to mark contact inactive',
          contacts_removed_from_sequence_success: 'Contacts removed from sequence',
          contacts_removed_from_sequence_failure:
            'Failed to remove contacts from sequence',
          // Translations for Events
          conversation_auto_closed: '{{user_name}} automatically closed the conversation',
          conversation_closed: '{{user_name}} closed the conversation',
          conversation_open: '{{user_name}} opened the conversation',
          conversation_transfer_location:
            '{{user_name}} moved the conversation from {{prev_location_name}} to {{current_location_name}}',
          conversation_review_request: '{{user_name}} generated a review invitation',
          conversation_link_click: '{{contact_name}} clicked on a link',
          contact_review_click: '{{contact_name}} clicked on a review invitation',
          contact_link_click: '{{contact_name}} clicked on a link',
          contact_review_submission:
            '{{contact_name}} submitted feedback: {{review.body}}',
          contact_webchat_lead: 'New contact from Whippy Webchat',
          contact_zapier_lead: 'New contact from Zapier Integration',
          contact_webflow_lead: 'New contact from Website Integration',
          contact_api_lead: 'New contact from API Integration',
          contact_tagging: '{{user_name}} added tag "{{tag_name}}"',
          contact_untagging: '{{user_name}} removed tag "{{tag_name}}"',
          conversation_assign_user:
            '{{assigned_by_user_name}} assigned the conversation to {{assigned_to_user_name}}',
          conversation_unassign_user:
            '{{assigned_by_user_name}} unassigned the conversation',
          conversation_assign_team:
            '{{assigned_by_user_name}} assigned the conversation to {{assigned_to_team_name}}',
          conversation_unassign_team:
            '{{assigned_by_user_name}} unassigned the conversation',
          conversation_remove_from_sequence:
            '{{user_name}} removed contact from sequence {{sequence_name}}',
          conversation_add_to_sequence:
            '{{user_name}} added contact to sequence {{sequence_name}}',
          conversation_keyword_trigger: 'Keyword "{{keyword_body}}" was triggered',
          conversation_update_conversation_languages:
            "{{user_name}} changed the contact's language to {{current_contact_language}} and your language to {{current_language}}",
          contact_update_contact_language:
            "{{user_name}} changed the contact's language to {{language}}",
          conversation_update_conversation_languages_contact:
            "{{user_name}} changed the contact's language to {{current_contact_language}}",
          conversation_update_conversation_languages_conversation:
            '{{user_name}} changed the your language to {{current_language}}',
          // Translations for Automations
          automation_search_failed: 'No automation found',
          // Translations for Reviews
          review_response_success: 'Review response sent',
          review_response_failure: 'Review response failed to send',
          // Translations for contacts & groups
          contact_added: 'New contact created',
          contact_updated: 'Contact updated',
          contact_added_failure: 'Failed to create contact',
          contact_deleted: 'Contact deleted',
          contact_deleted_failure: 'Failed to delete contact',
          fetch_contacts_failure: 'Failed to Fetch Contacts',
          contacts_import_success: 'Contacts imported',
          contacts_import_failure: 'List name must be unique',
          contacts_bulk_opt_in_import_success: 'Contacts are being added',
          contacts_bulk_opt_out_import_success: 'Contacts are being un-subscribed',
          contacts_bulk_opt_out_success: 'Contacts un-subscribed',
          contacts_bulk_opt_out_failure: 'Failed to un-subscribe contacts',
          conversations_bulk_import_success: 'Conversations import in progress',
          conversations_bulk_import_failure: 'Failed import conversations',
          group_created_success: 'Segment created',
          group_created_failure: 'Failed to create segment',
          group_deleted_success: 'Segment deleted',
          group_deleted_failure: 'Failed to delete segment',
          group_updated_success: 'Segment updated',
          group_updated_failure: 'Failed to update segment',
          upload_created_success: 'Upload created',
          upload_created_failure: 'Failed to create upload',
          upload_deleted_success: 'List deleted',
          upload_deleted_failure: 'Failed to delete list',
          upload_updated_success: 'List updated',
          upload_updated_failure: 'Failed to update list',
          scheduled_message_created_failure: 'Failed to schedule message',
          scheduled_message_created_success: 'Message scheduled',
          scheduled_message_updated_failure: 'Failed to update scheduled message',
          scheduled_message_updated_success: 'Scheduled message updated',
          scheduled_message_sent_success: 'Scheduled message sent',
          scheduled_message_sent_failure: 'Failed to send scheduled message',
          scheduled_message_deleted_success: 'Scheduled message deleted',
          scheduled_message_deleted_failure: 'Failed to delete scheduled message',
          draft_message_deleted_success: 'Draft message deleted',
          draft_message_deleted_failure: 'Failed to delete draft message',
          message_copied: 'Message copied to clipboard',
          audience_not_selected: 'Audience is required',
          contact_phone_copied: 'Phone number copied to clipboard',
          api_key_copied: 'API key copied to clipboard',
          copy_campaign_link_success: 'Campaign link copied',
          number_of_contacts_error: 'Invalid campaign confirmation',
          // automation templates
          automation_template_created_success: 'Automation template created',
          automation_template_created_failure: 'Failed to create automation template',
          automation_template_updated_success: 'Automation template updated',
          automation_template_updated_failure: 'Failed to update automation template',
          automation_template_trigger_created_success: 'Automation template created',
          automation_template_trigger_created_failure:
            'Failed to create automation template',
          automation_template_trigger_updated_success: 'Automation template updated',
          automation_template_trigger_updated_failure:
            'Failed to update automation template',
          automation_template_trigger_deleted_success: 'Automation template deleted',
          automation_template_trigger_deleted_failure:
            'Failed to delete automation template',
          // SSO
          sso_no_user_found: 'Username or password is incorrect',
          sso_no_auth_for_organization: 'Failed to sign in with SSO',
          workos_error: 'Failed to sign in with SSO',
          sso_no_organization_id_found: 'Failed to sign in with SSO',
          // teams translations
          team_update_success: 'Team updated',
          team_update_failure: 'Team update failed',
          team_delete_success: 'Team deleted',
          team_delete_failure: 'Team delete failed',
          team_member_create_success: 'Team Member created',
          team_member_create_failure: 'Team Member create failed',
          team_member_delete_success: 'Team Member deleted',
          team_member_delete_failure: 'Team Member delete failed',
          team_work_schedule_create_success: 'Team Work Schedule enabled',
          team_work_schedule_create_failure: 'Team Work Schedule enable failed',
          team_work_schedule_update_success: 'Team Work Schedule updated',
          team_work_schedule_update_failure: 'Team Work Schedule update failed',
          team_work_schedule_delete_success: 'Team Work Schedule deleted',
          team_work_schedule_delete_failure: 'Team Work Schedule delete failed',
          team_member_work_schedule_create_success: 'Team Member Work Schedule enabled',
          team_member_work_schedule_create_failure:
            'Team Member Work Schedule enable failed',
          team_member_work_schedule_update_success: 'Team Member Work Schedule updated',
          team_member_work_schedule_update_failure:
            'Team Member Work Schedule update failed',
          team_member_work_schedule_delete_success: 'Team Member Work Schedule deleted',
          team_member_work_schedule_delete_failure:
            'Team Member Work Schedule delete failed',
          custom_object_create_success: 'Custom Object created',
          custom_object_create_failure: 'Custom Object create failed',
          custom_object_update_success: 'Custom Object updated',
          custom_object_update_failure: 'Custom Object update failed',
          custom_object_delete_success: 'Custom Object deleted',
          custom_object_delete_failure: 'Custom Object delete failure',
          custom_property_create_success: 'Custom Property created',
          custom_property_create_failure: 'Custom Property create failed',
          custom_property_update_success: 'Custom Property updated',
          custom_property_update_failure: 'Custom Property update failure',
          custom_property_delete_success: 'Custom Property deleted',
          custom_property_delete_failure: 'Custom Property delete failure',
          // Translation for domains
          domain_name_copied: 'Domain name copied to clipboard',
          domain_add_success: 'Domain added',
          domain_add_failure: 'Failed to add domain',
          domain_buy_success: 'Domain bought successfully',
          domain_buy_failure: 'Failed to buy domain',
          domain_update_success: 'Domain updated',
          domain_update_failure: 'Failed to update domain',
          domain_delete_success: 'Domain deleted',
          domain_delete_failure: 'Domain deleted failure',
          link_created_success: 'Link Setting created',
          link_created_failure: 'Link Setting create failure',
          link_updated_success: 'Link Setting updated',
          link_updated_failure: 'Link Setting update failure',
          link_deleted_success: 'Link Setting deleted',
          link_deleted_failure: 'Link Setting delete failure',
          upload_file_should_be_image: 'Uploaded file should be an image',
          link_url_copied: 'Link url copied to clipboard',
          // Permissions
          user_permissions: 'Allows user to {{permission}} {{resource}}',
          update_permissions_success: 'User Permissions updated',
          update_permissions_failure: 'Failed to update User Permissions',
        },
      },
    },
    load: 'languageOnly',
    parseMissingKeyHandler: () => {
      return null;
    },
  },
  () => {
    console.log('i18next loaded');
  }
);

export default i18next;
