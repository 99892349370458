import React from 'react';

import { ContactAddressInput } from '@/settings/organization/trust/brands/createBrandView/CreateAddressInput';
import { Box, Flex } from '@/shared/ui';

export const StepTwo = () => {
  return (
    <Flex css={{ width: '100%' }} direction="column">
      <Box>
        <ContactAddressInput />
      </Box>
    </Flex>
  );
};
