/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  ChangeEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { HiSearch } from 'react-icons/hi';
import { useMedia } from 'react-use';
import { toast } from 'sonner';

import { useTags } from '@/pages/settings/organization/tags/context/TagsContext';
import { bulkAddTagsToContacts } from '@/shared/api/contacts/v1';
import { CircleIcon } from '@/shared/components/Icons';
import { useDisclosure } from '@/shared/hooks';
import { ContactTagItem } from '@/shared/types';
import { Tag as TagType } from '@/shared/types/tags';
import {
  Box,
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogPortal,
  DialogTrigger,
  Flex,
  HStack,
  Input,
  Label,
  ScrollArea,
  Skeleton,
  VStack,
} from '@/shared/ui';
import { equals } from '@/shared/utils/equals/equals';
import i18next from '@/shared/utils/translation';
import { styled } from '@/stitches.config';

import { useContacts } from '../../../data/context/ContactContext';
import { ContactTag } from './ContactTag';
import { ContactTagListItem } from './ContactTagListItem';

type AddSingleContactTagsProps = {
  /** contact id */
  contactId: string;
  /** children that will be used as a dialog trigger */
  children: React.ReactNode;
  /** true if on campaigns page */
  isCampaignsPage?: boolean;
};

export const AddSingleContactTags = memo(
  ({ contactId, children, isCampaignsPage }: AddSingleContactTagsProps): JSX.Element => {
    const isDesktop = useMedia('(min-width: 912px)');

    const inputRef = useRef<HTMLInputElement>(null);
    const scrollRef = useRef<HTMLDivElement>(null);

    // tags context
    const {
      createTag,
      tagsState: { allTags },
    } = useTags();

    // contact context
    const {
      contactState: { current },
      updateContactTags,
      setCurrent,
      getContactTags,
    } = useContacts();

    // dialog state
    const { isOpen, onOpen, onClose } = useDisclosure();

    // local state
    const [contactTags, setContactTags] = useState<Array<ContactTagItem>>([]);
    const [selectedTags, setSelectedTags] = useState<Array<string>>([]);
    const [searchedTags, setSearchedTags] = useState<Array<TagType>>([]);
    const [searchedValue, setSearchedValue] = useState('');
    const [creatingTag, setCreatingTag] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [loadingContactTags, setLoadingContactTags] = useState(false);

    // clear state function
    const clearState = () => {
      setSelectedTags([]);
      setSearchedTags([]);
      setSearchedValue('');
      setCreatingTag('');
    };

    // load contact tags by contactId when open dialog
    useEffect(() => {
      const getTags = async () => {
        try {
          setLoadingContactTags(true);
          const data = await getContactTags(contactId);
          if (data) {
            setContactTags(data as Array<ContactTagItem>);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoadingContactTags(false);
        }
      };
      if (contactId && isOpen) {
        if (current?.id && contactId === current?.id) {
          current?.contact_tags && setContactTags(current?.contact_tags);
        } else {
          getTags();
        }
      }
    }, [contactId, isOpen, current?.id, current?.contact_tags]);

    // on unmount, reset sate values
    useEffect(() => {
      return () => {
        clearState();
      };
    }, []);

    // tags list of the current contact
    const arrayOfCurrentContactTags = useMemo(
      () =>
        contactTags?.length
          ? contactTags.filter(
              (contact_tag: ContactTagItem) => contact_tag.tag.type === 'standard'
            )
          : [],
      [contactTags]
    );

    // filtered tags list of only standard tags and without already added
    const tagsList = useMemo(() => {
      const currentContactTagsIds = arrayOfCurrentContactTags.map(
        (contact_tag: ContactTagItem) => contact_tag.tag.id
      );
      return allTags
        .filter((tag: TagType) => tag.type === 'standard')
        .filter((tag: TagType) => !currentContactTagsIds.includes(tag.id));
    }, [allTags, arrayOfCurrentContactTags]);

    // This function is triggered when a user types in the tag search bar.
    const onTagSearch = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const value = e?.target?.value.trim();
        // If the search bar is not empty
        if (value) {
          // Set the searchedValue state, indicating that a search is in progress
          setSearchedValue(value);

          // Filter the tagsList to only include tags whose name includes the search value
          // The search is case-insensitive as both the tag name and search value are converted to lower case
          const searchedTagsArray =
            tagsList.filter((tag: TagType) =>
              tag.name.toLowerCase().includes(value.toLowerCase())
            ) || [];

          // check if searched value matches with the existing tags
          const isExist =
            searchedTagsArray.find(
              (tag: TagType) => tag.name.toLowerCase() === value.toLowerCase()
            ) ||
            arrayOfCurrentContactTags.find(
              (tag: ContactTagItem) => tag.tag.name.toLowerCase() === value.toLowerCase()
            );

          // if not exist update the state with searched value to creating in line
          if (value.length > 1 && !isExist) {
            setCreatingTag(value);
          } else {
            setCreatingTag('');
          }

          // Update the state with the filtered tags
          setSearchedTags(searchedTagsArray);
        } else {
          // If the search bar is empty, reset the searchedValue state to empty string and clear the searchedTags array
          setSearchedValue('');
          setSearchedTags([]);
        }
      },
      [tagsList]
    );

    // Function to handle the click event on a tag
    const onTagClick = useCallback(
      (id: string) => {
        // If the clicked tag is 'select-all'
        if (id === 'select-all') {
          // If all tags are already selected (either in the searched tags or in the full tags list)
          if (
            searchedValue
              ? selectedTags.length === searchedTags.length
              : selectedTags.length === tagsList.length
          ) {
            // Deselect all tags
            setSelectedTags([]);
          } else {
            // If not all tags are selected, select all tags (either in the searched tags or in the full tags list)
            setSelectedTags(
              searchedValue
                ? searchedTags.map((tag: TagType) => tag.id)
                : tagsList.map((tag: TagType) => tag.id)
            );
          }
          return null;
        } else {
          // If the clicked tag is not already selected, add it to the selected tags
          if (!selectedTags.includes(id)) {
            setSelectedTags([...selectedTags, id]);
          } else {
            // If the clicked tag is already selected, remove it from the selected tags
            setSelectedTags((selectedIds: Array<string>) =>
              selectedIds.filter((tagId: string) => tagId !== id)
            );
          }
        }
      },
      [searchedValue, searchedTags, tagsList, selectedTags]
    );

    // handle Add tags to the contact
    const onSubmit = useCallback(async () => {
      try {
        setSubmitting(true);
        // on Campaign Page we use bulkAddTagsToContacts for the adding tags
        if (isCampaignsPage) {
          const data = await bulkAddTagsToContacts({
            tags: selectedTags,
            selection: { contacts: [contactId] },
          });
          if (data) {
            // add new contact tags to current if we are have a current contact
            if (current) {
              const oldContactTags = current?.contact_tags || [];
              const newContactTags = data[current.id || ''] || [];
              setCurrent({
                ...current,
                contact_tags: [...oldContactTags, ...newContactTags],
              });
            }
            toast.success(i18next.t('tags_updated_success') as string);
          }
          handleClose();
        } else {
          // on Contact page
          for (const item of selectedTags) {
            updateContactTags(contactId || '', item);
          }
          handleClose();
          if (!equals(selectedTags, arrayOfCurrentContactTags)) {
            toast.success(i18next.t('tags_updated_success') as string);
          }
        }
      } catch (e) {
        toast.error(i18next.t('tags_updated_failed') as string);
        handleClose();
      } finally {
        setSubmitting(false);
      }
    }, [current?.contact_tags, selectedTags, arrayOfCurrentContactTags]);

    // handle create Tag inline
    const onCreateTag = useCallback(async () => {
      const data = await createTag(creatingTag, 'black', 'standard');
      if (data) {
        // select created tag
        setSelectedTags([...selectedTags, data.id]);

        // reset state
        setCreatingTag('');
        setSearchedValue('');
        if (inputRef.current) {
          inputRef.current.value = '';
        }

        // scroll to created tag
        scrollRef.current?.scrollTo({ top: scrollRef.current.scrollHeight });
      }
    }, [creatingTag]);

    // handle close
    const handleClose = useCallback(() => {
      clearState();
      onClose();
    }, []);

    return (
      <Dialog open={isOpen} onOpenChange={() => !isOpen}>
        <DialogTrigger asChild={true}>
          <Box onClick={onOpen}>{children}</Box>
        </DialogTrigger>
        <DialogPortal>
          <DialogContent
            onEscapeKeyDown={handleClose}
            onPointerDownOutside={handleClose}
            style={{
              minWidth: isDesktop ? '620px' : '350px',
              padding: 0,
              overflow: 'auto',
              zIndex: 999999999,
            }}
          >
            <VStack gap={2}>
              {/* tags search bar */}
              <Box css={{ position: 'relative' }}>
                <Box css={{ position: 'absolute', top: '32%', left: '1.5%' }}>
                  <HiSearch />
                </Box>
                <Input
                  css={{
                    minHeight: 45,
                    padding: '10px 10px 10px 30px',
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                  placeholder="Search Tags"
                  onChange={onTagSearch}
                  ref={inputRef}
                />
              </Box>
              {/* scroll area content */}
              <ScrollArea
                variant="combobox"
                css={{ maxHeight: 300, overflowY: 'auto', marginTop: 0 }}
                ref={scrollRef}
              >
                {/* creating tag inline row */}
                {creatingTag && (
                  <CreateTagInLine
                    gap={2}
                    align="center"
                    onClick={onCreateTag}
                    data-testid="test-creating-button"
                  >
                    <TagLabel>Create New Tag</TagLabel>{' '}
                    <HStack css={{ overflow: 'hidden' }} align="center">
                      <CircleIcon color="black" />
                      <Box>{creatingTag}</Box>
                    </HStack>
                  </CreateTagInLine>
                )}
                {/* show a message if the searched value matches the added tag */}
                {searchedValue.length > 1 && !creatingTag && searchedTags.length < 1 && (
                  <CreateTagInLine gap={2} align="center">
                    <TagLabel>Tag is already added</TagLabel>
                  </CreateTagInLine>
                )}
                {/* show a list of added contact tags */}
                <AddedTagsList wrap="wrap" align="center">
                  {loadingContactTags ? (
                    <>
                      <Skeleton
                        key="label"
                        variant="tag"
                        css={{ height: 30, width: 45 }}
                      />
                      {Array.from(
                        { length: 5 },
                        (_: any, k: React.Key | null | undefined) => (
                          <Skeleton key={k} variant="tag" css={{ height: 30 }} />
                        )
                      )}
                    </>
                  ) : arrayOfCurrentContactTags.length > 0 ? (
                    <>
                      <TagLabel css={{ m: 4 }}>Added:</TagLabel>{' '}
                      {arrayOfCurrentContactTags.map(
                        (contact_tag: ContactTagItem, index: number) => (
                          <ContactTag
                            key={contact_tag.id}
                            tagText={contact_tag.tag.name}
                            iconColor={contact_tag.tag.color}
                            index={index}
                            tagId={contact_tag.id}
                            contactId={contactId}
                            isClosable
                          />
                        )
                      )}
                    </>
                  ) : (
                    <TagLabel css={{ m: 4, py: 7.5 }}>No tags added yet</TagLabel>
                  )}
                </AddedTagsList>
                {/* show 'Select All Tags' option if there is more than one tag in the list */}
                {(searchedValue ? searchedTags.length > 1 : tagsList.length > 1) && (
                  <ContactTagListItem
                    key={'select-all-tag-option'}
                    tag={{ name: 'Select All Tags', color: 'black', id: 'select-all' }}
                    selectedTags={selectedTags}
                    tagsList={tagsList}
                    searchedTags={searchedTags}
                    isSearched={!!searchedValue}
                    onTagClick={onTagClick}
                    noDot
                  />
                )}
                {/* tags list to add to a contact */}
                {searchedValue
                  ? searchedTags.map((tag: TagType) => (
                      <ContactTagListItem
                        key={tag.id}
                        tag={tag}
                        selectedTags={selectedTags}
                        tagsList={tagsList}
                        searchedTags={searchedTags}
                        isSearched={!!searchedValue}
                        onTagClick={onTagClick}
                      />
                    ))
                  : tagsList.map((tag: TagType) => (
                      <ContactTagListItem
                        key={tag.id}
                        tag={tag}
                        selectedTags={selectedTags}
                        tagsList={tagsList}
                        searchedTags={searchedTags}
                        isSearched={!!searchedValue}
                        onTagClick={onTagClick}
                      />
                    ))}
              </ScrollArea>
            </VStack>
            <DialogFooter
              justify="end"
              css={{
                mt: 0,
                p: 20,
                borderTop: '1px solid $slate7',
              }}
            >
              <DialogClose asChild>
                <Button variant="gray" size={2} css={{ mr: '$1' }} onClick={handleClose}>
                  Cancel
                </Button>
              </DialogClose>
              <DialogClose asChild>
                <Button
                  onClick={onSubmit}
                  size={2}
                  disabled={!selectedTags.length || submitting || loadingContactTags}
                >
                  Add Tags
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </DialogPortal>
      </Dialog>
    );
  }
);

const AddedTagsList = styled(Flex, {
  width: '100%',
  minHeight: 52,
  px: '8px',
  py: '$2',
  borderBottom: 'thin solid $gray4',
  borderTop: 'thin solid $gray4',
});

const CreateTagInLine = styled(Flex, {
  fontSize: 13,
  width: '100%',
  minHeight: 52,
  px: '$2',
  py: '$2',
  '&:hover': {
    backgroundColor: '$slate2',
  },
  cursor: 'pointer',
});

const TagLabel = styled(Label, {
  color: '#687076',
  margin: 0,
});
