import { HiX } from 'react-icons/hi';
import { useRouteMatch } from 'react-router-dom';

import { Contact } from '@/shared/types/contacts';
import {
  ConversationFilterTypes,
  ConversationPanelTypes,
  ConversationStatusTypes,
} from '@/shared/types/conversations';
import { Flex, HStack, IconButton } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { MatchParams, useConversation } from '../../context/ConversationContext';
import { SideNavigationHeading } from '../../list/ConversationsFilter';
import { AttachmentsPanel } from './AttachmentsPanel';
import { CampaignsPanel } from './CampaignsPanel';
import { ContactPanel } from './ContactPanel';
import { ConversationDetailsPanel } from './ConversationDetailsPanel';
import { HelpPanel } from './HelpPanel';
import { ScheduledPanel } from './ScheduledPanel';
import { SearchPanelV2 } from './V2/SearchPanel';

type ConversationPanelsProps = {
  virtusoso: any;
  body: string;
  fullWidth?: boolean;
};

export const ConversationPanels = (props: ConversationPanelsProps) => {
  const { fullWidth } = props;
  const conversation = useConversation();
  const { toggleConversationPanel, conversationState } = conversation;
  const { conversationPanel, current } = conversationState;

  const match = useRouteMatch<MatchParams>('/inbox/:filter/:tab');
  const filter = match?.params.filter || ConversationFilterTypes.ALL;
  const tab = match?.params.tab || ConversationStatusTypes.OPEN;

  const handleClose = () => {
    toggleConversationPanel(false);
  };

  const renderPanel = (panel: string | null) => {
    switch (panel) {
      case ConversationPanelTypes.HELP:
        return <HelpPanel />;
      case ConversationPanelTypes.SEARCH:
        return (
          <SearchPanelV2
            conversation={current}
            filter={filter}
            tab={tab}
            toggleConversationPanel={toggleConversationPanel}
          />
        );
      case ConversationPanelTypes.CONTACT:
        return (
          <ContactPanel contact={current?.contact as Contact} onClose={handleClose} />
        );
      case ConversationPanelTypes.SCHEDULED:
        return <ScheduledPanel />;
      case ConversationPanelTypes.CAMPAIGNS:
        return <CampaignsPanel contactId={current?.contact.id} />;
      case ConversationPanelTypes.ATTACHMENTS:
        return <AttachmentsPanel conversationId={current?.id} />;
      case ConversationPanelTypes.CONVERSATION:
        return <ConversationDetailsPanel />;
      default:
        return null;
    }
  };

  return (
    <PanelContainer
      css={{
        height: '100%',
        width: fullWidth ? '100%' : '420px',
        minWidth: fullWidth ? '100%' : '420px',
      }}
      direction="column"
    >
      <PanelHeaderContainer align="center" justify="between">
        <SideNavigationHeading css={{ textTransform: 'capitalize' }}>
          {conversationPanel || ''}
        </SideNavigationHeading>
        <HStack>
          <IconButton size={2} onClick={handleClose}>
            <HiX size={16} />
          </IconButton>
        </HStack>
      </PanelHeaderContainer>
      <PanelContentContainer>
        <Flex css={{ width: '100%' }} direction="column">
          {renderPanel(conversationPanel)}
        </Flex>
      </PanelContentContainer>
    </PanelContainer>
  );
};

export const PanelContainer = styled(Flex, {
  position: 'relative',
  borderLeft: '1px solid $gray4',
});

export const PanelHeaderContainer = styled(Flex, {
  minHeight: 64,
  position: 'relative',
  height: 64,
  width: '100%',
  flexShrink: 0,
  borderBottom: 'thin solid $gray4',
  px: 12,
  '@lg': {
    px: 24,
  },
});

export const PanelContentContainer = styled(Flex, {
  position: 'relative',
  height: '100%',
  flex: 1,
  overflow: 'auto',
});
