/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo } from 'react';

import { ConfirmationDialog } from '@/shared/components/ConfirmationDialog';
import { Sort } from '@/shared/types/filter';
import { Box, DropdownMenuItemWarning, Flex } from '@/shared/ui';
import { Table, TableColumn } from '@/shared/v2/components/table/Table';
import { TableActionMenu } from '@/shared/v2/components/table/TableActionMenu';
import { styled } from '@/stitches.config';

import { CustomProperty, PropertyType } from '../../../../../shared/types/data';
import { propertyType } from '../constants';
import {
  initialCustomDataState,
  PAGE_SIZE,
  useCustomData,
} from '../context/CustomDataContext';
import { AddProperty } from './AddProperty';

const typeIcon = (type: PropertyType) => {
  const item = propertyType.find((p) => p.value === type);
  return item?.icon;
};

const defaultSort: Array<Sort> = [
  {
    label: 'Created At',
    resource: 'property',
    column: 'inserted_at',
    order: 'desc',
    id: null,
  },
];

const CustomPropertiesTable = () => {
  const {
    updateCustomPropertiesFilterParams,
    customDataState: {
      customPropertiesFilterParams,
      current,
      propertiesTotalCount,
      loadingProperties,
      properties,
    },
    deleteCustomProperty,
  } = useCustomData();

  useEffect(() => {
    current?.id &&
      updateCustomPropertiesFilterParams({
        ...initialCustomDataState.customPropertiesFilterParams,
        sort: defaultSort,
      });
  }, [current?.id]);

  const handleDeleteProperty = useCallback(
    (id: string) => {
      current?.id && deleteCustomProperty(current?.id, id);
    },
    [current?.id]
  );

  const columns: Array<TableColumn<CustomProperty>> = useMemo(
    () => [
      {
        Header: 'Name',
        colWidth: '55%',
        accessor: 'name',
        Cell: (props: { row: { original: CustomProperty } }) => (
          <DataTitle align="center">
            {typeIcon(props.row.original.type)}
            <TextTitle>{props.row.original.label}</TextTitle>
          </DataTitle>
        ),
      },
      {
        Header: 'Type',
        colWidth: '40%',
        accessor: 'type',
        Cell: (props: { row: { original: CustomProperty } }) => (
          <Flex direction="column">
            <DataText>{props.row.original.type}</DataText>
          </Flex>
        ),
      },
      {
        Header: 'Created At',
        id: 'inserted_at',
        accessor: 'inserted_at',
        colWidth: '100%',
        Cell: (props: { row: { original: CustomProperty } }) => (
          <Flex align="center" css={{ minWidth: 125 }}>
            <Box>{dayjs(props.row.original.inserted_at).format('MMM D YYYY')}</Box>
          </Flex>
        ),
      },
      {
        Header: 'Updated At',
        id: 'updated_at',
        accessor: 'updated_at',
        colWidth: '100%',
        Cell: (props: { row: { original: CustomProperty } }) => (
          <Flex align="center" css={{ minWidth: 125 }}>
            <Box>{dayjs(props.row.original.updated_at).format('MMM D YYYY')}</Box>
          </Flex>
        ),
      },
      {
        Header: '',
        colWidth: '10%',
        accessor: 'action',
        Cell: (props: { row: { original: CustomProperty } }) => (
          <TableActionMenu>
            <>
              <AddProperty
                currentObjectId={props.row.original.custom_object_id}
                data={props.row.original}
              />
              <ConfirmationDialog
                width="432px"
                title="Delete Property"
                description="Are you sure you want to delete this custom property?"
                onConfirm={() => handleDeleteProperty(props.row.original.id)}
                confirmButtonTitle="Yes, Delete Property"
                cancelButtonTitle="No"
                confirmButtonVariant="primary"
                cancelButtonVariant="grayBackground"
              >
                <DropdownMenuItemWarning
                  data-testid="delete-option"
                  onClick={(e) => e.preventDefault()}
                >
                  Delete Property
                </DropdownMenuItemWarning>
              </ConfirmationDialog>
            </>
          </TableActionMenu>
        ),
      },
    ],
    [properties]
  );

  return (
    <Table
      data={properties}
      columns={columns}
      caption="Custom Properties"
      totalCount={propertiesTotalCount}
      setOffset={(offset) => {
        updateCustomPropertiesFilterParams({
          ...customPropertiesFilterParams,
          offset,
        });
      }}
      pageSize={PAGE_SIZE}
      isLoading={loadingProperties}
      emptyTitle="No Custom Property Match Search"
      colHeight={43}
    />
  );
};

const DataTitle = styled(Flex, {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: 200,
  textOverflow: 'ellipsis',
  color: '$slate12',
});

const TextTitle = styled(Box, {
  fontWeight: '500',
  color: '$slate12',
  ml: 5,
  fontSize: 14,
});

const DataText = styled(Box, {
  fontWeight: '500',
  color: '$slate12',
  maxWidth: 200,
  textTransform: 'capitalize',
});

export default CustomPropertiesTable;
