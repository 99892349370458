import React from 'react';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';

import { styled } from '@/stitches.config';

import { PageHeader } from '../components/navigation/PageHeader';
import { Box, Flex, HStack, PageTitle, Text } from '../ui';

type PageLayoutProps = {
  /** an array of object with keys title and path */
  breadcrumbs?: BreadCrumb[];
  /** a component to be rendered on the right hand side of the page header */
  actions?: React.ReactNode;
  /** a component to be rendered as the child of the page */
  children: React.ReactNode;
  /** should we show the bottom border under the header? */
  border?: boolean;
  /** page background color */
  background?: string;
  /** page width */
  width?: string | number;
  /** css properties to append to the default */
  css?: React.CSSProperties;
  /** a component to be rendered on the bottom of the page */
  footer?: React.ReactNode;
};

export type BreadCrumb = {
  /** the title of the breadcrumb */
  title: string;
  /** the url path of the breadcrumb */
  path: string;
};

export const PageLayout = (props: PageLayoutProps) => {
  const { breadcrumbs, actions, children, border, background, width, css, footer } =
    props;
  const isSettings = location.pathname.startsWith('/settings');

  const history = useHistory();

  const isDesktop = useMedia('(min-width: 912px)');

  return (
    <Flex
      css={{
        flex: 1,
        height: '100%',
        minHeight: '100%',
        width: width || '100%',
        ...css,
      }}
      direction="column"
    >
      {/* if we are on mobile or in the chrome extension show the page header */}
      {!isDesktop && !isSettings && (
        <PageHeader>
          <Box>
            <Text variant="semibold">{breadcrumbs ? breadcrumbs[0]?.title : ''}</Text>
          </Box>
          <Box />
        </PageHeader>
      )}
      <PageHeaderContainer
        css={{ background: 'white' }}
        align="center"
        justify="between"
        border={border || true}
      >
        <HStack gap={2} align="center">
          {breadcrumbs?.map((breadcrumb, i) => (
            <HStack key={i} gap={2} css={{ cursor: 'pointer' }}>
              <PageTitle
                data-testid={`page-title-${i}`}
                onClick={() => history.push(breadcrumb.path)}
                css={{
                  maxWidth: 250,
                  display: '-webkit-box',
                  '-webkit-line-clamp': 1,
                  '-webkit-box-orient': 'vertical',
                  overflow: 'hidden !important',
                  textOverflow: 'ellipsis',
                  overflowWrap: 'break-word',
                }}
              >
                {breadcrumb.title}
              </PageTitle>
              {i + 1 < breadcrumbs.length && (
                <Box>
                  <HiOutlineChevronRight size="20px" />
                </Box>
              )}
            </HStack>
          ))}
        </HStack>
        <HStack gap={2}>{actions}</HStack>
      </PageHeaderContainer>
      <Flex
        direction="column"
        css={{
          position: 'relative',
          height: '100%',
          flex: 1,
          overflow: 'auto',
          backgroundColor: background ?? '#fafafa',
        }}
        data-testid="page-content-container"
      >
        {children}
      </Flex>
      {footer && <PageFooterContainer border>{footer}</PageFooterContainer>}
    </Flex>
  );
};

const PageHeaderContainer = styled(Flex, {
  minHeight: 64,
  position: 'relative',
  height: 64,
  width: '100%',
  flexShrink: 0,
  px: 24,
  variants: {
    border: {
      false: {
        borderBottom: 'none !important',
      },
      true: {
        borderBottom: 'thin solid $gray4',
      },
    },
  },
});

export const PageFooterContainer = styled(Flex, {
  backgroundColor: 'white',
  minHeight: 64,
  position: 'relative',
  height: 64,
  width: '100%',
  flexShrink: 0,
  py: 14,
  px: 24,
  variants: {
    border: {
      false: {
        borderTop: 'none !important',
      },
      true: {
        borderTop: 'thin solid $gray4',
      },
    },
  },
});
