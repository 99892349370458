export const sounds = [
  {
    id: 'call-center',
    label: 'Call Center',
    value: 'call-center',
    image: 'https://whippy-integrations.s3.us-west-2.amazonaws.com/agents/cc.jpg',
    audio:
      'https://whippy-integrations.s3.us-west-2.amazonaws.com/agents/call-center.wav',
  },
  {
    id: 'coffee-shop',
    label: 'Coffee Shop',
    value: 'coffee-shop',
    image: 'https://whippy-integrations.s3.us-west-2.amazonaws.com/agents/coffee.jpg',
    audio:
      'https://whippy-integrations.s3.us-west-2.amazonaws.com/agents/coffee-shop.wav',
  },
  {
    id: 'convention-hall',
    label: 'Convention Hall',
    value: 'convention-hall',
    image: 'https://whippy-integrations.s3.us-west-2.amazonaws.com/agents/conference.jpg',
    audio:
      'https://whippy-integrations.s3.us-west-2.amazonaws.com/agents/convention-hall.wav',
  },
  {
    id: 'summer-outdoor',
    label: 'Summer Outdoor',
    value: 'summer-outdoor',
    image: 'https://whippy-integrations.s3.us-west-2.amazonaws.com/agents/summer.jpg',
    audio:
      'https://whippy-integrations.s3.us-west-2.amazonaws.com/agents/summer-outdoor.wav',
  },
  {
    id: 'mountain-outdoor',
    label: 'Mountain Outdoor',
    value: 'mountain-outdoor',
    image: 'https://whippy-integrations.s3.us-west-2.amazonaws.com/agents/mountain.jpg',
    audio:
      'https://whippy-integrations.s3.us-west-2.amazonaws.com/agents/mountain-outdoor.wav',
  },
];
