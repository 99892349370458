/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { HiPaperAirplane, HiSave } from 'react-icons/hi';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { CampaignAutomations } from '@/pages/campaigns/automations';
import { useCampaignsContext } from '@/pages/campaigns/context/CampaignsContext';
import { Description, getIntervalValue } from '@/pages/campaigns/create';
import { Accordion, CampaignAccordion } from '@/pages/campaigns/create/CampaignAccordion';
import { CampaignBasicInfo } from '@/pages/campaigns/create/CampaignBasicInfo';
import { CampaignConfirmation } from '@/pages/campaigns/create/CampaignConfirmation';
import { CampaignMessageEditor } from '@/pages/campaigns/create/CampaignMessageEditor';
import { CampaignPreview } from '@/pages/campaigns/create/CampaignPreview';
import { CampaignSchedule } from '@/pages/campaigns/create/CampaignSchedule';
import { CampaignToolbar } from '@/pages/campaigns/create/CampaignToolbar';
import { V2CampaignPanel } from '@/pages/campaigns/create/panel/V2CampaignPanel';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { getAutomationTemplate } from '@/shared/api/automations/templates';
import { getAudienceV2 } from '@/shared/api/campaigns';
import { ToolTipIconButton } from '@/shared/components/attachments/previewer';
import { fillPipeFields } from '@/shared/components/editor/Pipes';
import { Attachments } from '@/shared/components/editor/v2/constants';
import {
  campaign_contact_campaign_id,
  default_campaign_contact_object,
} from '@/shared/components/filterBuilder/objects/campaign_contact';
import { default_campaign_message_object } from '@/shared/components/filterBuilder/objects/campaign_message';
import { default_communication_preference_object } from '@/shared/components/filterBuilder/objects/communication_preference';
import { default_contact_object } from '@/shared/components/filterBuilder/objects/contact';
import { default_contact_list_object } from '@/shared/components/filterBuilder/objects/contact_list';
import { default_campaign_contact_message_object } from '@/shared/components/filterBuilder/objects/contact_messages';
import { default_contact_tag_object } from '@/shared/components/filterBuilder/objects/contact_tag';
import { default_conversation_object } from '@/shared/components/filterBuilder/objects/conversation';
import { default_sequence_run_object } from '@/shared/components/filterBuilder/objects/sequence_run';
import { SidebarNavigationContainer } from '@/shared/components/navigation/SideNavigationContainer';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { AutomationTemplate } from '@/shared/types/automations';
import {
  AccordionValue,
  BatchDelayTimeUnit,
  Campaign,
  CampaignSettings,
  CampaignStatus,
  PanelType,
  ScheduleOptions,
} from '@/shared/types/campaigns';
import { ChannelTypes } from '@/shared/types/channels';
import { Contact } from '@/shared/types/contacts';
import { FilterItem } from '@/shared/types/filter';
import {
  Button,
  Drawer,
  DrawerContent,
  DrawerPortal,
  Fieldset,
  Flex,
  HStack,
  Label,
  Text,
  VStack,
} from '@/shared/ui';

import { CampaignAudienceAccordion } from '../../create/CampaignAudienceAccordion';
import { getAllFilterItemsByType, replaceFirstFilterItem } from '../components/utils';
import {
  appendFilterItemsToExcludedSystemDefault,
  appendOrCondition,
  isV2AudienceValid,
  removeExcludedSystemDefault,
} from '../utils';

const customContactObject = structuredClone(default_contact_object);
customContactObject.custom_properties.unshift({
  default: '',
  id: 'contact-id',
  label: 'ID',
  type: 'text',
  key: 'id',
  required: true,
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_object_id: 'unique-id-for-contact',
});

const CreateWhatsAppCampaign = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();
  const campaignRouteParams = useParams<{ id?: string }>();

  if (campaignRouteParams.id && !location.pathname.endsWith('/edit')) {
    history.replace(`${location.pathname}/edit`);
  }

  const [_loading, setLoading] = useState(true);
  const [currentEditor, setCurrentEditor] = useState();
  const {
    campaignsState: { current },
    setCurrentCampaign,
    setShowConfetti,
    createV2Campaign,
    updateV2Campaign,
  } = useCampaignsContext();

  // Campaign state
  const [campaignTitle, setCampaignTitle] = useState(current?.title || '');
  const [message, setMessage] = useState(current?.body || '');
  const [channelId, setChannelId] = useState<string>(current?.channel_id || '');
  const [isValidSchedule, setIsValidSchedule] = useState(false);
  const [attachments, setAttachments] = useState<Attachments>({
    attachment_urls: current?.attachment_urls || [],
  });
  const [isScheduled, setIsScheduled] = useState(!!current?.schedule_options?.day);
  const [date, setDate] = useState(
    current?.schedule_options?.day
      ? `${current.schedule_options?.month}/${current?.schedule_options.day}/${current?.schedule_options.year}`
      : dayjs(new Date()).format('MM/DD/YYYY')
  );
  const [time, setTime] = useState(
    current?.schedule_options?.hour && current?.schedule_options?.minute
      ? `${current?.schedule_options?.hour}:${current?.schedule_options?.minute}`
      : ''
  );
  const [time_zone, setTimezone] = useState(
    current?.schedule_options?.timezone ||
      Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  // Audience state
  const [includedContacts, setIncludedContacts] = useState<Contact[]>([]);
  const [excludedContacts, setExcludedContacts] = useState<Contact[]>([]);
  const [includedAudienceFilter, setIncludedAudienceFilter] = useState<
    FilterItem[] | null
  >(current?.included_audience_filter?.filter ?? null);
  const [excludedAudienceFilter, setExcludedAudienceFilter] = useState<FilterItem[]>(
    current?.excluded_audience_filter?.filter ?? []
  );
  const [messageCount, setMessageCount] = useState<number>(0);
  const [loadingAudience, setLoadingAudience] = useState<boolean>(false);

  // UI state
  const isLargeDesktop = useMedia('(min-width: 1350px)');
  const isSmallScreen = !useMedia('(min-width: 1130px)');
  const isMobile = !useMedia('(min-width: 968px)');
  const [showPanel, setShowPanel] = useState(false);
  const [panel, setPanel] = useState<PanelType>(PanelType.VIEW_AUDIENCE);
  const [accordion, setAccordion] = useState<AccordionValue>(
    campaignRouteParams.id
      ? AccordionValue.SELECT_AUDIENCE
      : AccordionValue.SELECT_LOCATION
  );

  // Campaign settings
  const [enableBatchSend, setEnableBatchSend] = useState(
    !!(
      current?.delivery_options?.period && current?.delivery_options?.messages_per_period
    )
  );
  const [batchSize, setBatchSize] = useState<number | null>(
    current?.delivery_options?.messages_per_period || 100
  );
  const [batchDelayTimeUnit, setBatchDelayTimeUnit] = useState(
    BatchDelayTimeUnit.MINUTES
  );
  const [batchDelay, setBatchDelay] = useState<number | null>(
    current?.delivery_options?.period ? current?.delivery_options?.period / 60 : 5
  );

  const { getChannelById } = useChannels();
  const locationObject = getChannelById(channelId);

  const auth = useAuth();
  const [previewName, setPreviewName] = useState(auth?.tokens?.name || 'John Doe');

  // show the user the campaign settings
  const [enableCampaignSettings, setEnableCampaignSettings] = useState(false);
  // show the user the campaign link tracking settings
  const [enableLinkTrackingSettings, setEnableLinkTrackingSettings] = useState(false);

  // campaign settings options for sending and scheduling messages
  const [campaignSettings, setCampaignSettings] = useState<CampaignSettings>({
    support_sms_quiet_hours: {
      enabled: current?.settings?.support_sms_quiet_hours?.enabled || false,
      use_contact_timezone:
        current?.settings?.support_sms_quiet_hours?.use_contact_timezone || false,
    },
    skip_sending_on_weekend: {
      enabled: current?.settings?.skip_sending_on_weekend?.enabled || false,
      use_contact_timezone:
        current?.settings?.skip_sending_on_weekend?.use_contact_timezone || false,
    },
    only_send_during_business_hours: {
      enabled: current?.settings?.only_send_during_business_hours?.enabled || false,
      use_contact_timezone:
        current?.settings?.only_send_during_business_hours?.use_contact_timezone || false,
    },
    link_tracking: {
      disabled: current?.settings?.link_tracking?.disabled || false,
      domain_id: current?.settings?.link_tracking?.domain_id || null,
    },
  });

  const customDefaultObjects = useMemo(() => {
    return [
      customContactObject,
      {
        ...default_campaign_contact_message_object,
        custom_properties: [
          ...default_campaign_contact_message_object.custom_properties,
          {
            default: '',
            id: 'contact_message-message',
            label: 'Message',
            type: 'text',
            key: 'id',
            required: true,
            inserted_at: 'current-timestamp',
            updated_at: 'current-timestamp',
            custom_object_id: 'unique-id-for-contact-message',
          },
        ],
      },
      default_contact_tag_object,
      default_contact_list_object,
      default_campaign_message_object,
      {
        ...default_campaign_contact_object,
        custom_properties: [
          campaign_contact_campaign_id,
          ...default_campaign_contact_object.custom_properties,
        ],
      },
      default_sequence_run_object,
      default_conversation_object,
      default_communication_preference_object,
    ];
  }, []);

  // Add state for automation templates
  const [existingAllAutomationTemplates, setExistingAllAutomationTemplates] = useState<
    Array<AutomationTemplate>
  >([]);

  // Add fetchTemplates function
  const fetchTemplates = async () => {
    const existingAssociatedAutomationTemplates =
      current?.associated_automation_templates || [];
    const existingAllAutomationTemplateIds = existingAssociatedAutomationTemplates.map(
      (template: {
        automation_template_id: string;
        campaign_automation_template_id: string;
      }) => template.automation_template_id
    );
    const existingAllAutomationTemplatesPromises: Array<Promise<AutomationTemplate>> =
      existingAllAutomationTemplateIds.map((id: string) => getAutomationTemplate(id));
    try {
      const templates = await Promise.all(existingAllAutomationTemplatesPromises);
      setExistingAllAutomationTemplates(templates);
    } catch (error) {
      console.error('Error fetching automation templates:', error);
    }
  };

  // Effects
  useEffect(() => {
    fetchTemplates();
    if (current) {
      setCampaignTitle(current.title ?? '');
      setChannelId(current.channel_id ?? '');
      setIncludedAudienceFilter(current.included_audience_filter?.filter ?? null);
      setExcludedAudienceFilter(current.excluded_audience_filter?.filter ?? []);
      setMessage(current.body ?? '');
    }
  }, [current]);

  useEffect(() => {
    if (!channelId) return;
    if (!includedAudienceFilter) return;

    setLoadingAudience(true);
    const totalIncludedAudienceFilter = structuredClone(includedAudienceFilter);

    debouncedGetAudienceV2(
      channelId,
      totalIncludedAudienceFilter ?? [],
      excludedAudienceFilter
    );
  }, [includedAudienceFilter, excludedAudienceFilter, channelId]);

  // Handlers
  const handlePanelClick = (panelType: PanelType): void => {
    if (panelType === panel) {
      setShowPanel(!showPanel);
    } else {
      setPanel(panelType);
      setShowPanel(true);
    }
  };

  const handleExcludeContactFromAudience = (contact: Contact) => {
    const id = contact.id;
    const excludeFilterWithNoSystemDefaults = removeExcludedSystemDefault(
      excludedAudienceFilter,
      []
    );
    // ... rest of exclude contact logic
  };

  const campaign_params: Campaign = {
    title:
      campaignTitle ||
      `untitled-campaign-${dayjs(new Date()).format('YYYY-MM-DD')}T${dayjs(new Date()).format('H:mm:ss')}`,
    status: CampaignStatus.DRAFT,
    body: message,
    attachment_urls: attachments.attachment_urls,
    type: 'whatsapp',
    channel_id: channelId,
    settings: campaignSettings,
    included_audience_filter: includedAudienceFilter
      ? { filter: includedAudienceFilter }
      : null,
    excluded_audience_filter: excludedAudienceFilter
      ? { filter: excludedAudienceFilter }
      : null,
    schedule_options: isScheduled
      ? {
          day: `${dayjs(date).date()}`,
          month: `${dayjs(date).month() + 1}`,
          year: `${dayjs(date).year()}`,
          hour: `${dayjs(`${date} ${time}`).hour()}`,
          minute: `${dayjs(`${date} ${time}`).format('mm')}`,
          timezone: time_zone,
        }
      : null,
    delivery_options: enableBatchSend
      ? {
          period: batchDelay
            ? batchDelay * getIntervalValue(batchDelayTimeUnit) * 60
            : 300,
          messages_per_period: batchSize,
        }
      : null,
  };

  const handleSave = async (accordion: AccordionValue) => {
    if (current?.id) {
      await updateV2Campaign(current.id, campaign_params, true);
      setAccordion(accordion);
    } else {
      const campaign = await createV2Campaign(campaign_params);
      setCurrentCampaign(campaign);
      setAccordion(accordion);
      if (campaign?.id) {
        history.push(`/campaigns/whatsapp/${campaign.id}/edit`);
      }
    }
  };

  // send or schedule the current campaign
  const handleSendCampaign = async () => {
    const send_campaign_params = {
      ...campaign_params,
      status: isScheduled ? CampaignStatus.SCHEDULED : CampaignStatus.IN_PROGRESS,
    };

    if (current && current.id) {
      const response = await updateV2Campaign(current.id, send_campaign_params, true);
      // if request does not fail show confetti and redirect
      if (response) {
        setShowConfetti();
        history.push(`/campaigns/whatsapp`);
      }
    }
  };

  const fillDemoMessage = () => {
    const organizationName = auth?.organizationName;

    // fill message pipe fields
    const filled_body = fillPipeFields(
      message,
      previewName || 'John Doe',
      locationObject?.name || '',
      locationObject?.address || '',
      locationObject?.google_place_id || '',
      organizationName || ''
    );

    return filled_body;
  };

  const debouncedGetAudienceV2 = useCallback(
    debounce(
      (
        channelId: string,
        includedAudienceFilter: FilterItem[],
        excludedAudienceFilter: FilterItem[]
      ) => {
        if (includedAudienceFilter.length == 0) return;
        getAudienceV2(
          channelId,
          includedAudienceFilter,
          excludedAudienceFilter,
          'whatsapp'
        ).then((data) => {
          setMessageCount(data.data.data.audience_count);
          setLoadingAudience(false);
          setIncludedContacts(data.data.data.audience);
          setExcludedContacts(data.data.data.excluded_contacts);
        });
      },
      500
    ),
    []
  );

  const appendUserExcludedFilter = (filterItems: FilterItem[]) => {
    const copyExcludedAudienceFilter = structuredClone(filterItems);
    return appendFilterItemsToExcludedSystemDefault(
      copyExcludedAudienceFilter,
      channelId,
      'whatsapp'
    );
  };

  const isValidAudience = useMemo(() => {
    return isV2AudienceValid(current, includedAudienceFilter);
  }, [current, includedAudienceFilter]);

  const removeUserExcludedFilter = (filterItems: FilterItem[]) => {
    const copyExcludedAudienceFilter = structuredClone(filterItems);
    return removeExcludedSystemDefault(copyExcludedAudienceFilter, []);
  };

  // Add hasTriggersOrTemplates check
  const hasTriggersOrTemplates =
    (current && current.triggers && current.triggers.length > 0) ||
    (current &&
      current.associated_automation_templates &&
      current.associated_automation_templates.length > 0)
      ? true
      : false;

  return (
    <Flex css={{ minHeight: '100%' }}>
      <PageLayout
        breadcrumbs={[
          { title: 'Campaigns', path: '/campaigns' },
          { title: 'WhatsApp', path: '/campaigns/whatsapp' },
          {
            title: `${current?.title || 'Create New Campaign WhatsApp'}`,
            path: `/campaigns/whatsapp/${current?.id || 'create'}`,
          },
        ]}
        actions={
          <HStack gap={2}>
            {messageCount > 0 && !loadingAudience && isValidAudience && (
              <Text css={{ mr: 10, display: isSmallScreen ? 'none' : undefined }}>
                {`${messageCount || 0} Contacts`}
              </Text>
            )}
            {!isMobile ? (
              <Button
                variant="gray"
                onClick={() => handleSave(AccordionValue.DEFAULT_VALUE)}
                disabled={campaignTitle.length < 3}
              >
                Save as Draft
              </Button>
            ) : (
              <ToolTipIconButton
                variant="outline"
                size={2}
                icon={<HiSave />}
                description="Save as Draft"
                onClick={() => handleSave(AccordionValue.DEFAULT_VALUE)}
              />
            )}
            <CampaignPreview
              body={message}
              attachments={attachments.attachment_urls}
              location_id={channelId}
              campaignTitle={campaignTitle}
              previewName={previewName}
              setPreviewName={setPreviewName}
              fillDemoMessage={fillDemoMessage}
            />
            <CampaignConfirmation
              onConfirm={handleSendCampaign}
              numberOfContacts={messageCount}
              buttonCopy={isScheduled ? 'Schedule Campaign' : 'Send Campaign'}
              isScheduled={isScheduled}
            >
              {!isMobile ? (
                <Button
                  variant="send"
                  disabled={
                    !isValidCampaign({
                      current,
                      includedAudienceFilter,
                      campaignTitle,
                      isScheduled,
                      isValidSchedule,
                      time_zone,
                      message,
                      attachments_urls: attachments.attachment_urls,
                    })
                  }
                >
                  {isScheduled ? 'Schedule Campaign' : 'Send Campaign'}
                </Button>
              ) : (
                <ToolTipIconButton
                  variant="send"
                  size={2}
                  disabled={
                    !isValidCampaign({
                      current,
                      includedAudienceFilter,
                      campaignTitle,
                      isScheduled,
                      isValidSchedule,
                      time_zone,
                      message,
                      attachments_urls: attachments.attachment_urls,
                    })
                  }
                  icon={
                    <HiPaperAirplane
                      style={{
                        transform: 'rotate(90deg)',
                      }}
                    />
                  }
                  description={isScheduled ? 'Schedule Campaign' : 'Send Campaign'}
                  onClick={() => handleSave(AccordionValue.DEFAULT_VALUE)}
                />
              )}
            </CampaignConfirmation>
          </HStack>
        }
        width={`calc(100% - ${showPanel && isLargeDesktop ? '550px' : '55px'})`}
      >
        <Flex direction="column" css={{ pb: 200 }}>
          <Accordion
            type="single"
            defaultValue={AccordionValue.SELECT_LOCATION}
            value={accordion}
          >
            <VStack css={{ p: 20 }} gap="2">
              <CampaignBasicInfo
                accordion={accordion}
                setAccordion={setAccordion}
                campaignTitle={campaignTitle}
                setCampaignTitle={setCampaignTitle}
                location={channelId}
                setLocation={setChannelId}
                channelType={['whatsapp']}
                handleSave={handleSave}
              />
              <CampaignAudienceAccordion
                handleSave={handleSave}
                accordion={accordion}
                setAccordion={setAccordion}
                exclude={{
                  fullExcludeAudienceFilter: excludedAudienceFilter,
                  removeFromFilter: removeUserExcludedFilter,
                  appendToFilter: appendUserExcludedFilter,
                }}
                setExcludedAudienceFilter={setExcludedAudienceFilter}
                includedAudienceFilter={includedAudienceFilter}
                setIncludedAudienceFilter={setIncludedAudienceFilter}
                isValidAudience={isValidAudience}
                customDefaultObjects={customDefaultObjects}
              />
              <CampaignMessageEditor
                message={message}
                setMessage={setMessage}
                attachments={attachments}
                setAttachments={setAttachments}
                accordion={accordion}
                setAccordion={setAccordion}
                setCurrentEditor={setCurrentEditor}
                handleSave={handleSave}
                channel_type="whatsapp"
              />
              <CampaignSchedule
                accordion={accordion}
                showSchedulePicker={true}
                setAccordion={setAccordion}
                isScheduled={isScheduled}
                setIsScheduled={setIsScheduled}
                date={date}
                setDate={setDate}
                time={time}
                setTime={setTime}
                time_zone={time_zone}
                setTimezone={setTimezone}
                isValidSchedule={isValidSchedule}
                handleSave={handleSave}
                enableBatchSend={enableBatchSend}
                setEnableBatchSend={setEnableBatchSend}
                batchSize={batchSize}
                setBatchSize={setBatchSize}
                batchDelay={batchDelay}
                setBatchDelay={setBatchDelay}
                batchDelayTimeUnit={batchDelayTimeUnit}
                setBatchDelayTimeUnit={setBatchDelayTimeUnit}
                enableCampaignSettings={enableCampaignSettings}
                setEnableCampaignSettings={setEnableCampaignSettings}
                campaignSettings={campaignSettings}
                setCampaignSettings={setCampaignSettings}
                enableLinkTrackingSettings={enableLinkTrackingSettings}
                setEnableLinkTrackingSettings={setEnableLinkTrackingSettings}
              />
              <CampaignAccordion
                index={5}
                title="Automations"
                description="Automate campaign follow up tasks."
                currentAccordionValue={accordion}
                itemValue={AccordionValue.CREATE_AUTOMATION}
                setItemValue={setAccordion}
                isValid={hasTriggersOrTemplates}
              >
                <VStack gap="2">
                  <Fieldset>
                    <Flex direction="column">
                      <Label>Create Campaign Automation (optional)</Label>
                      <Description>
                        Campaign automations let you take actions when contacts respond to
                        your campaigns.
                      </Description>
                    </Flex>
                  </Fieldset>
                  <CampaignAutomations
                    current={current}
                    setCurrentCampaign={setCurrentCampaign}
                    updateCurrentCampaign={() =>
                      updateV2Campaign(current?.id || '', campaign_params, true)
                    }
                    accordion={accordion}
                    setAccordion={setAccordion}
                    existingAllAutomationTemplates={existingAllAutomationTemplates}
                    setExistingAllAutomationTemplates={setExistingAllAutomationTemplates}
                  />
                </VStack>
              </CampaignAccordion>
            </VStack>
          </Accordion>
        </Flex>
      </PageLayout>
      {/* Side Panel */}
      <Flex css={{ height: '100%' }}>
        {showPanel && isLargeDesktop && (
          <SidebarNavigationContainer
            defaultWidth={360}
            minWidth={300}
            maxWidth={500}
            dragDirection="right"
            name="CREATE_CAMPAIGN"
            disableCollapse
          >
            <V2CampaignPanel
              isAudienceValid={isValidAudience}
              setShowPanel={setShowPanel}
              panel={panel}
              includedContacts={includedContacts}
              excludedContacts={excludedContacts}
              attachments={attachments.attachment_urls || []}
              preview={fillDemoMessage()}
              body={message}
              handleExclude={handleExcludeContactFromAudience}
              editor={currentEditor}
              isWhatsAppCampaign={true}
            />
          </SidebarNavigationContainer>
        )}
        {!isLargeDesktop && (
          <Drawer open={showPanel}>
            <DrawerPortal>
              <DrawerContent
                side="right"
                css={{ width: 360, overflowY: 'scroll' }}
                onEscapeKeyDown={() => setShowPanel(false)}
                onPointerDownOutside={() => setShowPanel(false)}
              >
                <V2CampaignPanel
                  isAudienceValid={isValidAudience}
                  setShowPanel={setShowPanel}
                  panel={panel}
                  includedContacts={includedContacts}
                  excludedContacts={excludedContacts}
                  attachments={attachments.attachment_urls || []}
                  preview={fillDemoMessage()}
                  body={message}
                  handleExclude={handleExcludeContactFromAudience}
                  editor={currentEditor}
                  isWhatsAppCampaign={true}
                />
              </DrawerContent>
            </DrawerPortal>
          </Drawer>
        )}
        <CampaignToolbar handlePanelClick={handlePanelClick} open={showPanel} />
      </Flex>
    </Flex>
  );
};

type isValidCampaignProps = {
  current: Campaign | null;
  includedAudienceFilter: FilterItem[] | null;
  campaignTitle: string;
  isScheduled: boolean;
  isValidSchedule: boolean;
  time_zone: string;
  message: string;
  attachments_urls: string[];
};
function isValidCampaign({
  current,
  includedAudienceFilter,
  campaignTitle,
  isScheduled,
  isValidSchedule,
  time_zone,
  message,
  attachments_urls,
}: isValidCampaignProps) {
  const isValidAudience = isV2AudienceValid(current, includedAudienceFilter);
  const isValidMessage = message.length > 1 || attachments_urls.length > 0;
  const isValidTitle = campaignTitle.length > 1;
  const isValidTimezone = time_zone !== '';
  // const isValidAudienceCount = messageCount > 0;

  const validImmediateCampaign =
    isValidAudience && isValidMessage && isValidTitle && isValidTimezone;

  return isScheduled ? validImmediateCampaign && isValidSchedule : validImmediateCampaign;
}

export default CreateWhatsAppCampaign;
