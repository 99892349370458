/* eslint-disable @typescript-eslint/no-unused-vars */
import i18next from 'i18next';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { HiPlus } from 'react-icons/hi';
import { toast } from 'sonner';

import { CreateContact } from '@/pages/data/contacts/CreateContact';
import { prepareFilters } from '@/pages/data/utils/prepareFilters';
import { getGroupsV2 } from '@/shared/api/contacts/groups';
import { getUploadsV2 } from '@/shared/api/contacts/uploads';
import * as V1ContactsAPI from '@/shared/api/contacts/v1';
import { searchContacts, searchContactsCount } from '@/shared/api/contacts/v2';
import { getTagsV2 } from '@/shared/api/tags';
import { Contact } from '@/shared/types';
import { SearchFilters } from '@/shared/types/contacts';
import { Group } from '@/shared/types/contacts/groups';
import { FilterItem, Sort } from '@/shared/types/filter';
import { Tag } from '@/shared/types/tags';
import { Badge, Flex, Skeleton, Text } from '@/shared/ui';
import {
  isValidEmail,
  isValidPhoneNumber,
  phoneFormatting,
} from '@/shared/utils/validations/validations';
import TabbedCombobox from '@/shared/v2/components/tabbedCombobox/TabbedCombobox';

import { QuickFilterAlias } from '../../create/CampaignAudience';
import {
  appendFilterItemAsOrField,
  compareFilters,
  findFilterItem,
  removeFirstFilterItem,
} from '../utils';
import {
  appendNewFilterType,
  getQuickFilterItemByType,
  groupFiltersByType,
} from './utils';

const POPOVER_HEIGHT = 192;

export type QuickFilterResource = 'contact' | 'tag' | 'list' | 'segment';
export const quickFilterResourceTypes = ['contact', 'tag', 'list', 'segment'] as const;
type AudienceWithAliasType = QuickFilterResource | 'alias';
type Tab = 'contacts' | 'tags' | 'uploads' | 'segments';
export type AudienceItem = {
  data: Contact | Tag | Group;
  type: AudienceWithAliasType;
};

type SelectedComboboxItem = {
  id: string;
  value: string;
  type: AudienceWithAliasType;
};
type RemoveSystemDefaultFunction = (filters: FilterItem[]) => FilterItem[];

type AudienceQuickFilterProps = {
  /*
   * This function is used to notify the parent component that the selected items have changed.
   * NOTE: This function is only called when the component is not in readOnly mode.
   */
  onSelectedItemsChanged?: (filter: FilterItem[]) => void;
  /*
   * This list of FilterItems should be "simple" and related to contact, tag, and list ids.
   * It's important to filter out filter items not related.
   */
  selectedItems: FilterItem[] | null;
  /*
   * This function is used to filter out any filter items not related to the contact, tag, and list
   * ids. We use function to filter non relevant items before managing the state for this component.
   */
  removeSystemDefaultItems?: RemoveSystemDefaultFunction;
  channelId: string | null;
  /*
   * Readonly mode is useful to show this Audience component without allowing the user to edit the
   * filter items. By default, this is false.
   */
  readOnly?: boolean;
  aliases?: QuickFilterAlias[];
};

const AudienceQuickFilter = ({
  selectedItems: selectedItems,
  onSelectedItemsChanged,
  channelId,
  removeSystemDefaultItems,
  aliases,
  readOnly = false,
}: AudienceQuickFilterProps): JSX.Element => {
  /*
   * We want to wait until the filter is not null.
   * Null indicates that the audience is still potentially pending.
   * For example, when a user visits the campaign edit screen we only
   * have the campaign id at first. We'd need to wait until the
   * campaign object properly loads.
   */
  const [isContactLoading, setLoadingContacts] = useState(false);
  const [isTagLoading, setLoadingTags] = useState(false);
  const [isUploadListLoading, setLoadingUploadList] = useState(false);
  const [isSegmentLoading, setLoadingSegments] = useState(false);
  // We need some way to determine was the last form of requesting more search data.
  // The two options are we scrolled to the bottom and we're need more data OR
  // we typed some new text. This helps us prevent invalid states such as
  // requesting more data in the situation where searching causes us to hit the bottom reached
  // state.
  const [searchEvent, setSearchEvent] = useState<'scroll' | 'search' | null>();
  const [_loadedResultItems, setLoadedResultItems] = useState(false);
  const [comboboxInputValue, setComboboxInputValue] = useState('');
  const isValidInputAndChannel = useMemo(() => {
    if (comboboxInputValue && channelId) {
      return isInputValid(comboboxInputValue);
    }
    return false;
  }, [comboboxInputValue, channelId]);

  const [selectedComboboxItems, setSelectedComboboxItems] = useState<
    SelectedComboboxItem[]
  >([]);

  const [contacts, setContacts] = useState<Contact[]>([]);
  const [totalContacts, setTotalContacts] = useState<number>(0);
  const [contactsOffset, setContactsOffset] = useState(0);
  // in some cases we want to be able to partially load result items or
  // some items are partially removed to
  const [resultItemsToLoad, setResultItemsToLoad] = useState(0);

  const [tags, setTags] = useState<Tag[]>([]);
  const [totalTags, setTotalTags] = useState<number>(0);
  const [tagsOffset, setTagsOffset] = useState(0);

  const [uploadLists, setUploadLists] = useState<Tag[]>([]);
  const [totalUploadLists, setTotalUploadLists] = useState<number>(0);
  const [uploadListsOffset, setUploadListsOffset] = useState(0);

  const [segments, setSegments] = useState<Group[]>([]);
  const [totalSegments, setTotalSegments] = useState<number>(0);
  const [segmentOffset, setSegmentOffset] = useState(0);

  const [searchValue, setSearchValue] = useState('');

  const setAllLoading = (loading: boolean) => {
    setLoadingContacts(loading);
    setLoadingTags(loading);
    setLoadingUploadList(loading);
    setLoadingSegments(loading);
  };

  useEffect(() => {
    // If we selectedItems is still null, that means we have not yet
    // loaded the filter items so we should wait.
    // We know the filter items are properly loaded, we can proceed.
    // We only have selected items if we manually selected
    // items or if we have filters that we converted to selected items.
    // if (selectedComboboxItems.length > 0)
    // does the selected combo boxes know about
    // all the ides in our quick filter items
    // if (compare(selectedComboboxItems, selectedItems, removeSystemDefaultItems))
    const fetchInitiallySelectedItems = async (initialFilter: FilterItem[]) => {
      const { contactsFilter, tagsFilter, listsFilter, segmentsFilter } =
        getAllQuickFilterItemsByType(initialFilter);
      const currentComboboxItems: SelectedComboboxItem[] = [];
      if (aliases) {
        aliases
          .filter(
            (alias) =>
              findFilterItem(initialFilter, (filterItem) =>
                compareFilters(filterItem, alias.filter)
              ) != null
          )
          .forEach((alias) => {
            currentComboboxItems.push({
              type: 'alias',
              id: alias.label,
              value: alias.label,
            });
          });
      }
      if (contactsFilter.length > 0) {
        const contacts = await getContacts({
          filter: contactsFilter,
          limit: 100,
          offset: 0,
          sort: [],
          searchFilter: [],
        });
        contacts.data.forEach((contact) => {
          currentComboboxItems.push({
            type: 'contact',
            id: contact.id,
            value: contact.name || contact.phone || contact.email || '',
          });
        });
      }
      if (tagsFilter.length > 0) {
        const tags = await getTags({
          filter: tagsFilter,
          limit: 100,
          offset: 0,
          sort: [],
          searchFilter: [],
        });
        tags.data.forEach((tag) => {
          currentComboboxItems.push({
            type: 'tag',
            id: tag.id,
            value: tag.name ?? '',
          });
        });
      }
      if (listsFilter.length > 0) {
        const lists = await getUploadLists({
          filter: listsFilter,
          limit: 100,
          offset: 0,
          sort: [],
          searchFilter: [],
        });
        lists.data.forEach((listUpload) => {
          currentComboboxItems.push({
            type: 'list',
            id: listUpload.id,
            value: listUpload.name ?? '',
          });
        });
      }
      if (segmentsFilter.length > 0) {
        const segments = await getSegments({
          filter: segmentsFilter,
          limit: 100,
          offset: 0,
          sort: [],
          searchFilter: [],
        });
        segments.data.forEach((segmentUpload) => {
          currentComboboxItems.push({
            type: 'list',
            id: segmentUpload.id,
            value: segmentUpload.name ?? '',
          });
        });
      }
      setResultItemsToLoad(0);
      setSelectedComboboxItems(currentComboboxItems);
      setLoadedResultItems(true);
    };

    // if the selected items has not been loaded
    if (!selectedItems) return;
    // don't fetch more data if we're already loading
    // if (loadedResultItems) return;

    const difference = countDifference(
      selectedComboboxItems,
      selectedItems,
      removeSystemDefaultItems,
      aliases
    );

    // if the selected items is empty, then we don't need to fetch anymore
    // or if the difference is zero, then we don't need to fetch anymore
    if (
      !selectedItems ||
      (selectedItems.length == 0 && selectedComboboxItems.length == 0) ||
      difference == 0
    ) {
      // set the loading state false for all tabs
      setAllLoading(false);

      setLoadedResultItems(true);
    }
    // if the difference is positive, then we need to fetch more data
    else if (difference > 0) {
      // set the loading state to true for all tabs
      setAllLoading(true);

      setLoadedResultItems(false);
      setResultItemsToLoad(difference);
      const items = getUserSelectedItems(selectedItems, removeSystemDefaultItems);
      fetchInitiallySelectedItems(items);
    }
    // if the difference is negative, we already have all the items
    // and we need to remove the appropriate one from the list
    else {
      setAllLoading(true);
      setLoadedResultItems(false);
      setResultItemsToLoad(difference);
      const items = getUserSelectedItems(selectedItems, removeSystemDefaultItems);
      const newlySelectedComboboxItems = calculateDifferenceFromSelectedItems(
        items,
        selectedComboboxItems
      );
      setSelectedComboboxItems(newlySelectedComboboxItems);
    }
  }, [selectedItems]);

  // make data requests for the new data
  const onSearch = async (searchInput: string) => {
    if (!isInputValid(searchInput)) return;
    setSearchEvent('search');
    const baseSearchFilter = {
      filter: [] as FilterItem[],
      sort: [] as Sort[],
      limit: 100,
      offset: 0,
    };
    getContacts({
      ...baseSearchFilter,
      searchFilter: createSearchFilter('contact', searchInput),
    }).then((result) => {
      setContacts(result.data);
      setLoadingContacts(false);
    });

    getContactsCount({
      ...baseSearchFilter,
      searchFilter: createSearchFilter('contact', searchInput),
    }).then((result) => {
      setTotalContacts(result.total);
    });

    getTags({
      ...baseSearchFilter,
      searchFilter: createSearchFilter('tag', searchInput),
    }).then((result) => {
      setTags(result.data);
      setTotalTags(result.total);
      setLoadingTags(false);
    });
    getUploadLists({
      ...baseSearchFilter,
      searchFilter: createSearchFilter('list', searchInput),
    }).then((result) => {
      setUploadLists(result.data);
      setTotalUploadLists(result.total);
      setLoadingUploadList(false);
    });
    getSegments({
      ...baseSearchFilter,
      searchFilter: createSearchFilter('segment', searchInput),
    }).then((result) => {
      setSegments(result.data);
      setTotalSegments(result.total);
      setLoadingSegments(false);
    });
  };

  const debouncedOnSearch = useCallback(debounce(onSearch, 1000), []);

  const fetchData = async (tab: Tab, searchInput: string) => {
    const baseSearchFilter = {
      filter: [] as FilterItem[],
      sort: [] as Sort[],
      limit: 100,
    };
    switch (tab) {
      case 'contacts': {
        setLoadingContacts(true);
        const offset = calculateOffset(contactsOffset, totalContacts);
        getContacts({
          ...baseSearchFilter,
          searchFilter: createSearchFilter('contact', searchInput),
          sort: [],
          offset,
        }).then((result) => {
          updateStateAfterScrollLoad(tab, result.data);
          setLoadingContacts(false);
        });
        break;
      }
      case 'tags': {
        setLoadingTags(true);
        const offset = calculateOffset(tagsOffset, totalTags);
        getTags({
          ...baseSearchFilter,
          searchFilter: createSearchFilter('tag', searchInput),
          sort: [],
          offset,
        }).then((result) => {
          updateStateAfterScrollLoad(tab, result.data);
          setLoadingTags(false);
        });
        break;
      }
      case 'uploads': {
        setLoadingUploadList(true);
        const offset = calculateOffset(uploadListsOffset, totalUploadLists);
        getUploadLists({
          ...baseSearchFilter,
          searchFilter: createSearchFilter('list', searchInput),
          sort: [],
          offset,
        }).then((result) => {
          updateStateAfterScrollLoad(tab, result.data);
          setLoadingUploadList(false);
        });
        break;
      }
      case 'segments': {
        setLoadingSegments(true);
        const offset = calculateOffset(segmentOffset, totalUploadLists);
        getSegments({
          ...baseSearchFilter,
          searchFilter: createSearchFilter('segment', searchInput),
          sort: [],
          offset,
        }).then((result) => {
          updateStateAfterScrollLoad(tab, result.data);
          setLoadingSegments(false);
        });
        break;
      }
    }
  };

  const updateStateAfterScrollLoad = (tab: Tab, data: Contact[] | Tag[] | Group[]) => {
    switch (tab) {
      case 'contacts':
        setContacts((prev) => [...prev, ...(data as Contact[])]);
        setContactsOffset((prev) => Math.min(prev + 100, totalContacts));
        setLoadingContacts(false);
        break;
      case 'tags':
        setTags((prev) => [...prev, ...(data as Tag[])]);
        setTagsOffset((prev) => Math.min(prev + 100, totalTags));
        setLoadingTags(false);
        break;
      case 'uploads':
        setUploadLists((prev) => [...prev, ...(data as Tag[])]);
        setUploadListsOffset((prev) => Math.min(prev + 100, totalUploadLists));
        setLoadingUploadList(false);
        break;
      case 'segments':
        setSegments((prev) => [...prev, ...(data as Group[])]);
        setSegmentOffset((prev) => Math.min(prev + 100, totalUploadLists));
        setLoadingSegments(false);
        break;
    }
  };

  return (
    <Flex css={{ width: '100%', position: 'relative' }}>
      <TabbedCombobox
        readOnly={readOnly}
        searchValue={searchValue}
        defaultTab="contacts"
        onEndReached={(tab, searchInput) => {
          if (searchEvent == 'scroll') {
            fetchData(tab as Tab, searchInput);
          }
        }}
        isScrolling={(isScrolling) =>
          setSearchEvent((prev) => {
            if (isScrolling) {
              return 'scroll';
            }
            return prev;
          })
        }
        selectedItems={selectedComboboxItems}
        setValue={(value) => setComboboxInputValue(value)}
        setSelectedItems={(selectedItems) => {
          const newFilter = convertSelectedComboboxItems(
            selectedItems as {
              id: string;
              type: AudienceWithAliasType;
              value: string;
              data: Contact | Group | Tag;
            }[],
            aliases
          );
          // NOTE: We must update the combobox items before calling the onSelectedItemsChanged callback
          setSelectedComboboxItems(
            selectedItems as {
              id: string;
              value: string;
              type: AudienceWithAliasType;
              data: Contact | Tag | Group;
            }[]
          );
          if (!readOnly && onSelectedItemsChanged) {
            onSelectedItemsChanged(newFilter);
          }
        }}
        onSearch={(input: string) => {
          setSearchValue(input);
          if (channelId && isInputValid(input)) {
            setAllLoading(true);
            debouncedOnSearch(input);
          } else {
            setAllLoading(false);
          }
        }}
      >
        <Flex
          direction="column"
          css={{
            border: '1px solid #0134DB72 ',
            borderRadius: '3px',
            width: '100%',
            padding: '4px 4px',
          }}
        >
          <TabbedCombobox.ResultsList
            style={{
              flexWrap: 'wrap',
              gap: '8px',
              height: readOnly && selectedComboboxItems.length == 0 ? '24px' : 'auto',
            }}
          >
            <ResultItemsSkeleton count={resultItemsToLoad} />
            {selectedComboboxItems.map((value) => (
              <TabbedCombobox.ResultItem
                key={value.id}
                value={value.value}
                id={value.id}
              />
            ))}
          </TabbedCombobox.ResultsList>
          <TabbedCombobox.Input placeholder="Search" />
        </Flex>
        <TabbedCombobox.Popover
          style={{
            background: '#FFF',
            borderRadius: '8px',
            backgroundColor: '$panel',
            border: '1px solid var(--colors-slate4)',
            boxShadow:
              '0px 10px 38px -10px rgba(22, 23, 24, 0), var(--colors-shadowDark) 0px 10px 20px -15px',
            marginTop: '16px',
            // hardcoded negative margins to align the popover with the input
            marginLeft: '-6px',
            marginRight: '-80px',
            zIndex: '999999',
            position: 'relative',
          }}
        >
          <TabbedCombobox.Tabs>
            <TabbedCombobox.TabsList style={{ width: '100%' }}>
              <Flex justify="between" align="center" css={{ width: '100%' }}>
                <Flex css={{ width: '100%', whiteSpace: 'nowrap' }}>
                  <TabTrigger
                    isLoading={isContactLoading}
                    isValid={isValidInputAndChannel}
                    label="Contacts"
                    total={totalContacts}
                    id="contacts"
                  />
                  <TabTrigger
                    isLoading={isUploadListLoading}
                    isValid={isValidInputAndChannel}
                    label="Lists"
                    total={totalUploadLists}
                    id="uploads"
                  />
                  <TabTrigger
                    isLoading={isTagLoading}
                    isValid={isValidInputAndChannel}
                    label="Tags"
                    total={totalTags}
                    id="tags"
                  />
                  <TabTrigger
                    isLoading={isSegmentLoading}
                    isValid={isValidInputAndChannel}
                    label="Segments"
                    total={totalSegments}
                    id="segments"
                  />
                </Flex>
                <AddNewContactButton
                  name={comboboxInputValue}
                  onSubmit={async (campaignParams) => {
                    try {
                      const contact = await V1ContactsAPI.createContact(campaignParams);
                      // reset the combobox value
                      setComboboxInputValue('');
                      setSearchValue('');
                      toast.success(i18next.t('contact_added') as string);
                      const newSelectedComboboxItem = {
                        id: contact.id,
                        type: 'contact',
                        value: contact.name ?? '',
                      } as SelectedComboboxItem;
                      const newFilter = convertSelectedComboboxItems(
                        [...selectedComboboxItems, newSelectedComboboxItem],
                        aliases
                      );

                      if (!readOnly && onSelectedItemsChanged) {
                        onSelectedItemsChanged(newFilter);
                      }
                      setSelectedComboboxItems((prev) => [
                        ...prev,
                        newSelectedComboboxItem,
                      ]);
                    } catch (error) {
                      console.error(error);
                      toast.error(i18next.t('contact_added_failure') as string);
                    }
                  }}
                />
              </Flex>
            </TabbedCombobox.TabsList>
            {channelId == null && <NoChannelState />}
            {channelId != null && !isInputValid(comboboxInputValue) && (
              <InvalidInputState />
            )}
            {channelId != null && isInputValid(comboboxInputValue) && (
              <Flex>
                <TabbedCombobox.TabsContent
                  value="contacts"
                  isLoading={isContactLoading}
                  items={contacts}
                  totalItems={totalContacts}
                />
                <TabbedCombobox.TabsContent
                  value="tags"
                  isLoading={isTagLoading}
                  items={tags}
                  totalItems={totalTags}
                />
                <TabbedCombobox.TabsContent
                  value="uploads"
                  isLoading={isUploadListLoading}
                  items={uploadLists}
                  totalItems={totalUploadLists}
                />
                <TabbedCombobox.TabsContent
                  value="segments"
                  isLoading={isSegmentLoading}
                  items={segments}
                  totalItems={totalSegments}
                />
              </Flex>
            )}
          </TabbedCombobox.Tabs>
        </TabbedCombobox.Popover>
      </TabbedCombobox>
    </Flex>
  );
};

const ResultItemsSkeleton = ({ count }: { count: number }) => {
  if (count <= 0) {
    return <></>;
  }
  return (
    <Flex
      css={{
        flexWrap: 'wrap',
        gap: '8px',
        alignItems: 'center',
      }}
    >
      {Array.from({ length: count }, (_, index) => (
        <Skeleton
          key={index}
          variant="tag"
          css={{ height: 24, width: 95, margin: '0px', border: '0px' }}
        />
      ))}
    </Flex>
  );
};

// serves as a wrapper component around the existing CreateContact
const AddNewContactButton = ({
  onSubmit,
  name,
}: {
  name: string;
  onSubmit: (params: any) => void;
}) => {
  const defaultEmailValue = useMemo(
    () => (name && isValidEmail(name) ? name : undefined),
    [name]
  );

  const defaultPhoneValue = useMemo(
    () => (name && (isValidPhoneNumber(name) || !isNaN(Number(name))) ? name : undefined),
    [name]
  );

  return (
    <CreateContact
      handleCreateContact={onSubmit}
      name={name && !defaultPhoneValue && !defaultEmailValue ? name : ''}
      phone={defaultPhoneValue}
      email={defaultEmailValue}
    >
      <Flex
        align="center"
        css={{
          cursor: 'pointer',
          color: '#00259ECB',
          paddingLeft: '12px',
          paddingRight: '12px',
          gap: '8px',
        }}
      >
        <HiPlus />
        <button
          data-testid="audience-quick-filter-add-contact"
          style={{ whiteSpace: 'nowrap' }}
        >
          Add New Contact
        </button>
      </Flex>
    </CreateContact>
  );
};

const NoChannelState = () => {
  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      css={{
        width: '100%',
        height: POPOVER_HEIGHT,
      }}
    >
      <Text variant="bold" size="3">
        {' '}
        No Channel Selected{' '}
      </Text>
      <p>
        Please choose a channel for the campaign before searching through your contacts.
      </p>
    </Flex>
  );
};

const InvalidInputState = () => {
  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      css={{
        width: '100%',
        height: POPOVER_HEIGHT,
      }}
    >
      <Text variant="bold" size="3">
        Enter 2 or more characters to search...
      </Text>
    </Flex>
  );
};

async function getContacts(params: SearchFilters) {
  const filters = prepareFilters(params);
  const data = await searchContacts(
    filters.filter,
    [
      ...filters.sort,
      {
        resource: 'contact',
        column: 'id',
        order: 'asc',
      } as Sort,
    ],
    filters.limit,
    filters.offset
  );
  return { data: data.data };
}

async function getContactsCount(params: SearchFilters) {
  const filters = prepareFilters(params);
  const count = await searchContactsCount(
    filters.filter,
    [
      ...filters.sort,
      {
        resource: 'contact',
        column: 'updated_at',
        order: 'desc',
      } as Sort,
    ],
    filters.limit,
    filters.offset
  );
  return { total: count?.total };
}

async function getTags(params: SearchFilters) {
  const defaultSort = {
    resource: 'tag',
    column: 'updated_at',
    order: 'desc',
  } as Sort;
  const filters = prepareFilters({
    ...params,
    sort: [...params.sort, defaultSort],
    filter: [...params.filter, ...defaultTagFilter],
  });
  return await getTagsV2(filters);
}

async function getUploadLists(params: SearchFilters) {
  const defaultSort = {
    resource: 'list',
    column: 'updated_at',
    order: 'desc',
  } as Sort;
  const filters = prepareFilters({
    ...params,
    sort: [...params.sort, defaultSort],
    filter: [...params.filter, ...defaultUploadListFilter],
  });
  return await getUploadsV2(filters);
}

async function getSegments(params: SearchFilters) {
  const filters = prepareFilters({
    ...params,
    sort: [...params.sort],
    filter: [...params.filter],
  });
  return await getGroupsV2(filters, undefined);
}

const defaultUploadListFilter: FilterItem[] = [
  { column: 'type', comparison: '==', resource: 'list', value: 'upload' },
  { column: 'state', comparison: '==', resource: 'list', value: 'active' },
];

const defaultTagFilter: FilterItem[] = [
  { column: 'type', comparison: '==', resource: 'tag', value: 'standard' },
  { column: 'state', comparison: '==', resource: 'tag', value: 'active' },
];

/***
 * Converts the selected items from the combobox into filter items
 * @param selectedComboboxItems
 * @returns
 */
function convertSelectedComboboxItems(
  selectedComboboxItems: {
    id: string;
    value: string;
    type: AudienceWithAliasType;
  }[],
  aliases?: QuickFilterAlias[]
): FilterItem[] {
  let newFilter = [] as FilterItem[];
  ['contact', 'tag', 'list', 'segment'].forEach((type) => {
    // get each filter by type in a simplistic format
    const filtersByType = selectedComboboxItems
      .filter((item) => item.type == type)
      .map((newlySelectedItem) => {
        return {
          resource: type,
          column: 'id',
          comparison: '==',
          value: newlySelectedItem.id,
        };
      });
    // takes the simplistic format and reasonably groups the filters together
    const groupedByType = groupFiltersByType(filtersByType);
    appendNewFilterType(newFilter, groupedByType);
  });
  if (aliases) {
    selectedComboboxItems
      .filter((item) => item.type == 'alias')
      .forEach((item) => {
        // get the alias filter by its label
        const alias = aliases.find((a) => a.label == item.id);
        if (!alias) return;
        newFilter = appendFilterItemAsOrField(newFilter, alias.filter);
      });
  }
  return newFilter;
}

/**
 * This function will determine what the newly selected items are.
 * This is used to handle the situation when a user removed a filter item.
 * For example, if we select a contact in the quick filter but then remove it,
 * from the advanced filter, we will have a situation where the selected combobox
 * items are not the same as the filter items.
 * @example
 * const newFilterItems = [
 *   {
 *     resource: 'contact',
 *     column: 'id',
 *     comparison: '==',
 *     value: '2'
 * ]
 * const previousSelectedComboboxItems = [
 *   {
 *     id: '1',
 *     value: 'John Doe',
 *     type: 'contact',
 *   },
 *   {
 *     id: '2',
 *     value: 'Jane Doe',
 *     type: 'contact',
 *   },
 * ];
 * const calculateDifferenceFromSelectedItems(newlySelectedComboboxItems, previousSelectedComboboxItems);
 * only Jane Doe should be in the list now
 * // returns [{ id: '2', value: 'Jane Doe', type: 'contact' }]
 */
function calculateDifferenceFromSelectedItems(
  items: FilterItem[],
  selectedComboboxItems: SelectedComboboxItem[]
) {
  const { contactsFilter, tagsFilter, listsFilter, segmentsFilter } =
    getAllQuickFilterItemsByType(items);
  const itemsIds = [
    ...contactsFilter,
    ...tagsFilter,
    ...listsFilter,
    ...segmentsFilter,
  ].reduce((acc, item) => {
    if (Array.isArray(item.value)) {
      return acc.concat(item.value);
    }
    return acc.concat(item.value as string);
  }, [] as string[]);
  // ignore all the selected combobox items that we could not find in the items
  return selectedComboboxItems.filter((i) => itemsIds.includes(i.id));
}

function createSearchFilter(
  tab: AudienceWithAliasType,
  searchInput: string
): FilterItem[] {
  if (searchInput == '') return [];
  if (tab == 'contact') {
    return [
      {
        column: 'name',
        comparison: 'ilike',
        resource: 'contact',
        value: `%${searchInput}%`,
        or: [
          {
            column: 'email',
            comparison: 'ilike',
            resource: 'contact',
            value: `%${searchInput}%`,
            or: [
              {
                column: 'phone',
                comparison: 'ilike',
                resource: 'contact',
                value: `%${phoneFormatting(searchInput)}%`,
              },
            ],
          },
        ],
      },
    ];
  } else {
    return [
      {
        column: 'name',
        comparison: 'ilike',
        resource: tab,
        value: `%${searchInput}%`,
      },
    ];
  }
}

function getNumberOfTags(
  filters: FilterItem[] | null,
  removeExcludedSystemDefault?: RemoveSystemDefaultFunction
): number {
  if (!filters) return 0;
  let count = 0;
  function countIds(item: FilterItem) {
    if (item.comparison == '==') {
      count += 1;
    } else if (item.comparison == 'in' && Array.isArray(item.value)) {
      count += item.value.length;
    }
    if (item.or && item.or.length === 1) {
      countIds(item.or[0]);
    }
  }

  // remove system default if possible, before starting the count
  const items = getUserSelectedItems(filters, removeExcludedSystemDefault);
  items.forEach(countIds);
  return count;
}

function calculateOffset(currentOffset: number, totalCount: number) {
  // cap the offset to be below the total count
  const offset = Math.min(currentOffset + 100, totalCount - 100);
  // don't let the offset be negative
  return Math.max(offset, 0);
}

function getUserSelectedItems(
  items: FilterItem[],
  removeExcludedSystemDefault?: RemoveSystemDefaultFunction
): FilterItem[] {
  if (!removeExcludedSystemDefault) return items;
  return removeExcludedSystemDefault(items);
}

function isInputValid(input: string): boolean {
  if (input.length >= 2) return true;
  return false;
}

/***
 * This determines the difference between the number of items in the combobox and the number of items in the filter items.
 * This becomes relevant when determining how many items we want to show as loading for the result items.
 * When we initially load an audience we would want to load all associated contacts so there could be
 * many items. However, if we already loaded all the contacts and select a new person to add
 * then we would only want to show one loading result item.
 *
 * NOTE: This function expects that the combobox Items are up to date. This is because
 * in some cases the TabbedCombobox component will be the one who updates the selected
 * combobox items in the UI. In these situations we won't need to load any result items.
 *
 * @returns the number of items in the filter items that are not in the combobox state.
 * - A negative number means that we removed a selected item from the result list
 * - 0 means that there was no change
 * - A positive number means that we've added a selected item to the result list
 */
function countDifference(
  comboboxItems: SelectedComboboxItem[],
  filterItems: FilterItem[],
  removeExcludedSystemDefault?: RemoveSystemDefaultFunction,
  aliases?: QuickFilterAlias[]
): number {
  const comboboxesWithoutAlias = comboboxItems.filter((alias) => alias.type != 'alias');
  const aliasesItems = aliases ?? [];
  // this represents the filter items that the alias represents
  const selectedAliasItemFilter = comboboxItems
    // find any of the aliases that are present
    .filter((selectedComboboxItem) => selectedComboboxItem.type == 'alias')
    //
    .map((selectedAliasItem) => {
      const alias = aliasesItems.find((a) => a.label == selectedAliasItem.value);
      return alias?.filter;
    });
  const filterItemsWithoutAliases = selectedAliasItemFilter.reduce(
    (acc, selectedAliasFilterItem) => {
      if (selectedAliasFilterItem === undefined) return acc;
      return removeFirstFilterItem(acc, (filterItem) =>
        compareFilters(filterItem, selectedAliasFilterItem)
      );
    },
    filterItems
  );
  const countOfFilterItems = getNumberOfTags(
    filterItemsWithoutAliases,
    removeExcludedSystemDefault
  );

  return countOfFilterItems - comboboxesWithoutAlias.length;
}

type TabsProps = {
  isLoading: boolean;
  isValid: boolean;
  label: string;
  total: number;
  id: 'contacts' | 'tags' | 'uploads' | 'segments';
};

function TabTrigger({ isLoading, isValid, label, total, id }: TabsProps) {
  // if we're loading and the input is valid, show the loading state
  if (isLoading && isValid) {
    return (
      <TabbedCombobox.TabsTrigger value={id} style={{ flexGrow: 0 }}>
        <Badge css={{ zIndex: 1, marginRight: 8, width: '20px' }} variant="blue" />
        {label}
      </TabbedCombobox.TabsTrigger>
    );
  }
  // if the input is invalid and we're not loading, show the error state
  else if (!isLoading && !isValid) {
    return (
      <TabbedCombobox.TabsTrigger value={id} style={{ flexGrow: 0 }}>
        <Badge css={{ zIndex: 1, marginRight: 8, width: '20px' }} variant="blue">
          {'-'}
        </Badge>
        {label}
      </TabbedCombobox.TabsTrigger>
    );
  } else if (!isLoading && isValid) {
    return (
      <TabbedCombobox.TabsTrigger value={id} style={{ flexGrow: 0 }}>
        <Badge css={{ zIndex: 1, marginRight: 8 }} variant="blue">
          {total}
        </Badge>
        {label}
      </TabbedCombobox.TabsTrigger>
    );
  }
}
function getAllQuickFilterItemsByType(fullFilter: FilterItem[]) {
  const contactsFilter = getQuickFilterItemByType('contact', fullFilter);
  const tagsFilter = getQuickFilterItemByType('tag', fullFilter);
  const listsFilter = getQuickFilterItemByType('list', fullFilter);
  const segmentsFilter = getQuickFilterItemByType('segment', fullFilter);
  return { contactsFilter, tagsFilter, listsFilter, segmentsFilter };
}

export default AudienceQuickFilter;
