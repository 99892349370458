import { Formik, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { HiChevronDown, HiX } from 'react-icons/hi';
import { useHistory, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { toast } from 'sonner';
import * as Yup from 'yup';

import {
  FormFieldWrapper,
  FormMessageEditorV2,
  TextInput,
} from '@/shared/components/forms';
import { usePageView } from '@/shared/hooks';
import { SettingsLayout } from '@/shared/layouts';
import { PageFooterContainer } from '@/shared/layouts/PageLayout';
import { TemplateContentType } from '@/shared/types/templates';
import {
  Box,
  Button,
  Flex,
  IconButton,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text,
} from '@/shared/ui';

import { useTemplates } from './context/TemplatesContext';
import { LanguageSelect } from './LanguageSelect';
import WhatsAppPreview from './WhatsAppPreview';
import { WhatsAppTemplateCategoryView } from './WhatsAppTemplateCategoryView';

const validationSchema = Yup.object({
  templateName: Yup.string().required('Template name is required'),
  language: Yup.string().required('Language is required'),
  bodyText: Yup.object().shape({
    body: Yup.string().required('Body is required'),
    attachment_urls: Yup.array(),
  }),
});

interface TemplateButton {
  id: string;
  type: 'QUICK_REPLY';
  text: string;
}

interface WhatsAppTemplateFormValues {
  templateName: string;
  language: string;
  headerType: string;
  headerText: string;
  bodyText: {
    body: string;
    attachment_urls: never[];
  };
  footerText: string;
  buttons: TemplateButton[];
}

export function AddWhatsAppTemplate(): JSX.Element {
  usePageView();
  const isDesktop = useMedia('(min-width: 768px)');
  const templatesContext = useTemplates();
  const { createTemplate, editTemplate, templatesState } = templatesContext;
  const history = useHistory();
  const [activeTab, setActiveTab] = React.useState<'setup' | 'edit'>('setup');
  const [selectedCategory, setSelectedCategory] = React.useState<'MARKETING' | 'UTILITY'>(
    'MARKETING'
  );
  const [selectedType, setSelectedType] = React.useState('custom');
  const [isEditing, setIsEditing] = useState(false);

  const params = useParams<{ id?: string }>();
  const templateId = params.id || null;

  const [formValues, setFormValues] = useState<WhatsAppTemplateFormValues>({
    templateName: '',
    language: 'en_US',
    headerType: 'Text',
    headerText: '',
    bodyText: {
      body: '',
      attachment_urls: [],
    },
    footerText: '',
    buttons: [] as TemplateButton[],
  });

  const formValuesRef = React.useRef(formValues);
  formValuesRef.current = formValues;

  React.useEffect(() => {
    if (!templateId) return;

    setIsEditing(true);
    setActiveTab('edit');

    const template = templatesState.templates.find((t) => t.id === templateId);
    if (template) {
      const metadata = template.template_metadata || {};

      const headerComponent = (metadata as any).components?.find(
        (c: { type: string }) => c.type === 'HEADER'
      );
      const bodyComponent = (metadata as any).components?.find(
        (c: { type: string }) => c.type === 'BODY'
      );
      const footerComponent = (metadata as any).components?.find(
        (c: { type: string }) => c.type === 'FOOTER'
      );
      const buttonsComponent = metadata.components?.find(
        (c: { type: string }) => c.type === 'BUTTONS'
      );

      setFormValues({
        templateName: template.title || '',
        language: metadata.language || 'en_US',
        headerType: headerComponent?.format || 'Text',
        headerText: headerComponent?.text || '',
        bodyText: {
          body: bodyComponent?.text || template.message || '',
          attachment_urls: [],
        },
        footerText: footerComponent?.text || '',
        buttons:
          buttonsComponent?.buttons?.map((b: { type?: string; text?: string }) => ({
            id: `button-${Date.now()}-${Math.random()}`,
            type: b.type || 'QUICK_REPLY',
            text: b.text || '',
          })) || [],
      });

      setSelectedCategory(metadata.category || 'MARKETING');
    }
  }, [templateId, templatesState.templates]);

  const handleSubmit = (
    values: WhatsAppTemplateFormValues,
    formikHelpers: FormikHelpers<WhatsAppTemplateFormValues>
  ) => {
    const components = [];

    if (values.headerText && values.headerType === 'Text') {
      components.push({
        type: 'HEADER',
        format: 'TEXT',
        text: values.headerText,
      });
    }

    components.push({
      type: 'BODY',
      text: values.bodyText.body,
    });

    if (values.footerText) {
      components.push({
        type: 'FOOTER',
        text: values.footerText,
      });
    }

    if (values.buttons.length > 0) {
      const buttonsComponent = {
        type: 'BUTTONS',
        buttons: values.buttons.map((button) => ({
          type: button.type,
          text: button.text,
        })),
      };
      components.push(buttonsComponent);
    }

    const whatsappTemplateData = {
      name: values.templateName.toLowerCase().replace(/\s+/g, '_'),
      language: values.language,
      category: selectedCategory,
      components: components,
    };

    try {
      const params = {
        title: values.templateName,
        message: values.bodyText.body,
        attachments: [],
        access_level: 'organization',
        content_type: TemplateContentType.GENERAL,
        is_scheduled_template: false,
        scheduled_template_params: {},
        is_whatsapp_template: true,
        template_metadata: whatsappTemplateData,
      };

      if (isEditing && templateId) {
        try {
          editTemplate({ ...params, id: templateId });
          history.push('/settings/templates');
        } catch (error) {
          console.error('Error updating template:', error);
          toast.error('Failed to update template');
        }
      } else {
        try {
          createTemplate(params);
          history.push('/settings/templates');
        } catch (error) {
          console.error('Error creating template:', error);
          toast.error('Failed to create template');
        }
      }

      formikHelpers.setSubmitting(false);
    } catch (error) {
      console.error('Error saving template:', error);
      formikHelpers.setSubmitting(false);
    }
  };

  const handleNext = () => {
    setActiveTab('edit');
  };

  return (
    <>
      <Helmet>
        <title>Whippy | {isEditing ? 'Edit' : 'Add'} WhatsApp Template</title>
      </Helmet>
      <SettingsLayout
        background="#f5f5f5"
        width="100%"
        breadcrumbs={[
          { title: 'Settings', path: '/settings/templates' },
          { title: 'Message Templates', path: '/settings/templates' },
          {
            title: `${isEditing ? 'Edit' : 'Add'} WhatsApp Template`,
            path: '/settings/templates/whatsapp',
          },
        ]}
      >
        <Box css={{ mb: '$6', backgroundColor: 'white', borderRadius: '$3', p: '$4' }}>
          <Box css={{ mb: '$4' }}>
            <Text
              as="h2"
              css={{
                fontSize: '$xl',
                fontWeight: 'bold',
                color: '$gray900',
                mb: '$3',
              }}
            >
              {activeTab === 'setup' ? 'Set Up Your Template' : 'Edit Template'}
            </Text>
          </Box>

          {activeTab === 'setup' && (
            <>
              <WhatsAppTemplateCategoryView
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
                onNext={handleNext}
              />
            </>
          )}

          {activeTab === 'edit' && (
            <Formik
              initialValues={formValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {(formik) => {
                // Update form values directly
                setFormValues(formik.values);
                return (
                  <form
                    id={isEditing ? 'edit-template-form' : 'create-template-form'}
                    onSubmit={formik.handleSubmit}
                  >
                    <Flex
                      css={{ gap: '$6', flexDirection: isDesktop ? 'row' : 'column' }}
                    >
                      {/* Left section - Template editor */}
                      <Box css={{ flex: '1', minWidth: 0 }}>
                        {/* Template Name and Language section */}
                        <Box
                          css={{
                            backgroundColor: '#FFFFFF',
                            p: '$6',
                            borderRadius: '$3',
                            mb: '$4',
                            boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                          }}
                        >
                          <Text
                            as="h2"
                            css={{
                              fontSize: '$xl',
                              fontWeight: 'bold',
                              mb: '$5',
                              color: '$gray900',
                            }}
                          >
                            Template Name and Language
                          </Text>

                          <Box css={{ mb: '$4' }}>
                            <FormFieldWrapper label="Template Name" name="templateName">
                              <TextInput
                                placeholder="Enter template name"
                                name="templateName"
                              />
                            </FormFieldWrapper>
                          </Box>

                          {/* Language Select */}
                          <Box css={{ mb: '$4' }}>
                            <LanguageSelect
                              label="Select Language"
                              value={formik.values.language}
                              onChange={(value) => {
                                if (value) {
                                  // Only update if value exists
                                  formik.setFieldValue('language', value);
                                }
                              }}
                            />
                          </Box>
                        </Box>

                        {/* Content section */}
                        <Box
                          css={{
                            backgroundColor: '#FFFFFF',
                            p: '$6',
                            borderRadius: '$3',
                            mb: '$4',
                            boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                          }}
                        >
                          <Text
                            as="h2"
                            css={{
                              fontSize: '$xl',
                              fontWeight: 'bold',
                              mb: '$3',
                              color: '$gray900',
                            }}
                          >
                            Content
                          </Text>
                          <Text css={{ mb: '$5', color: '$gray700' }}>
                            Fill out the header, body and footer sections of your template
                          </Text>

                          {/* Header section */}
                          <Box css={{ mb: '$5', position: 'relative', zIndex: 3 }}>
                            <Label
                              htmlFor="headerType"
                              css={{
                                display: 'flex',
                                alignItems: 'center',
                                mb: '$2',
                                fontWeight: '$medium',
                              }}
                            >
                              Header{' '}
                              <Text
                                as="span"
                                css={{
                                  color: '#999',
                                  fontWeight: 'normal',
                                  marginLeft: '4px',
                                }}
                              >
                                *Optional
                              </Text>
                            </Label>
                            <Select
                              name="headerType"
                              value="Text"
                              disabled={true}
                              onValueChange={(value) =>
                                formik.setFieldValue('headerType', value)
                              }
                            >
                              <SelectTrigger
                                id="headerType"
                                aria-label="Select header type"
                                css={{ width: '100%', height: '38px' }}
                              >
                                <SelectValue aria-label={formik.values.headerType}>
                                  {formik.values.headerType}
                                </SelectValue>
                                <HiChevronDown />
                              </SelectTrigger>
                              <SelectContent
                                position="popper"
                                sideOffset={5}
                                css={{ zIndex: 999 }}
                              >
                                <SelectItem value="Text">Text</SelectItem>
                              </SelectContent>
                            </Select>
                          </Box>

                          {/* Header Text input */}
                          <Box css={{ mb: '$5' }}>
                            <Label
                              htmlFor="headerText"
                              css={{
                                display: 'block',
                                mb: '$2',
                                fontWeight: '$medium',
                              }}
                            >
                              Header Text
                            </Label>
                            <TextInput
                              placeholder="Enter header text"
                              name="headerText"
                            />
                          </Box>

                          {/* Body section */}
                          <Box css={{ mb: '$5' }}>
                            <Flex gap={1} align="center">
                              <Label
                                htmlFor="bodyText"
                                css={{
                                  display: 'block',
                                  mb: '$2',
                                  fontWeight: '$medium',
                                }}
                              >
                                Body
                              </Label>
                              {formik.touched.bodyText?.body &&
                                formik.errors.bodyText?.body && (
                                  <Text variant="error">
                                    {formik.errors.bodyText.body as string}
                                  </Text>
                                )}
                            </Flex>
                            <FormMessageEditorV2
                              source="whatsapp-template"
                              placeholder="Type your message here..."
                              field="bodyText"
                              showAddAttachment={false}
                              enableAttachments={false}
                              showCharacterCount={true}
                              showAddEmoji={true}
                              showAddVariable={true}
                            />
                          </Box>

                          <Box css={{ mb: '$5' }}>
                            <Label
                              htmlFor="footerText"
                              css={{
                                display: 'flex',
                                alignItems: 'center',
                                mb: '$2',
                                fontWeight: '$medium',
                              }}
                            >
                              Footer{' '}
                              <Text
                                as="span"
                                css={{
                                  color: '#999',
                                  fontWeight: 'normal',
                                  marginLeft: '4px',
                                }}
                              >
                                *Optional
                              </Text>
                            </Label>
                            <TextInput placeholder="Enter text" name="footerText" />
                          </Box>

                          <Box css={{ mb: '$5' }}>
                            <Text css={{ mb: '$4', color: '$gray700' }}>
                              Create buttons and let customers respond to your message.
                              You can add up to 10 buttons. If you add more than 3
                              buttons, they will appear in a list.
                            </Text>

                            {formik.values.buttons.length > 0 && (
                              <Box css={{ mb: '$4' }}>
                                {formik.values.buttons.map((button, index) => (
                                  <Box
                                    key={button.id}
                                    css={{
                                      mb: '$3',
                                      p: '$3',
                                      border: '1px solid $gray200',
                                      borderRadius: '$2',
                                      backgroundColor: '$gray50',
                                    }}
                                  >
                                    <Box>
                                      <Label
                                        css={{
                                          display: 'block',
                                          mb: '$2',
                                          fontWeight: '$medium',
                                        }}
                                      >
                                        Button Text
                                      </Label>
                                      <Flex css={{ alignItems: 'center', gap: '$2' }}>
                                        <Box css={{ position: 'relative', flex: 1 }}>
                                          <TextInput
                                            name={`buttons[${index}].text`}
                                            placeholder="Enter button text"
                                            css={{ width: '100%' }}
                                            maxLength={25}
                                          />
                                          <Text
                                            css={{
                                              position: 'absolute',
                                              right: '8px',
                                              top: '50%',
                                              transform: 'translateY(-50%)',
                                              fontSize: '$xs',
                                              color: '$gray600',
                                            }}
                                          >
                                            {formik.values.buttons[index].text?.length ||
                                              0}
                                            /25
                                          </Text>
                                        </Box>
                                        <IconButton
                                          size={2}
                                          onClick={() => {
                                            const updatedButtons = [
                                              ...formik.values.buttons,
                                            ];
                                            updatedButtons.splice(index, 1);
                                            formik.setFieldValue(
                                              'buttons',
                                              updatedButtons
                                            );
                                          }}
                                        >
                                          <HiX size={16} />
                                        </IconButton>
                                      </Flex>
                                    </Box>
                                  </Box>
                                ))}
                              </Box>
                            )}

                            {/* Add Button button */}
                            <Button
                              variant="outline"
                              type="button"
                              css={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '$2',
                                height: '36px',
                                px: '$3',
                              }}
                              disabled={formik.values.buttons.length >= 10}
                              onClick={() => {
                                const newButton: TemplateButton = {
                                  id: `button-${Date.now()}`,
                                  type: 'QUICK_REPLY',
                                  text: '',
                                };
                                formik.setFieldValue('buttons', [
                                  ...formik.values.buttons,
                                  newButton,
                                ]);
                              }}
                            >
                              <span>+</span> Add Button
                            </Button>
                            {formik.values.buttons.length >= 10 && (
                              <Text
                                css={{ mt: '$2', fontSize: '$sm', color: '$gray600' }}
                              >
                                Maximum of 10 buttons reached
                              </Text>
                            )}
                          </Box>
                        </Box>
                      </Box>

                      {/* Right section - Template preview */}
                      {isDesktop && (
                        <Box css={{ width: '380px', flexShrink: 0 }}>
                          <Box
                            css={{
                              position: 'sticky',
                              top: '20px',
                              mb: '$6',
                            }}
                          >
                            <Text
                              as="h2"
                              css={{ fontSize: '$xl', fontWeight: '$semibold', mb: '$4' }}
                            >
                              Template Preview
                            </Text>
                            <WhatsAppPreview
                              headerText={
                                formik.values.headerType === 'Text'
                                  ? formik.values.headerText
                                  : ''
                              }
                              bodyText={formik.values.bodyText.body}
                              footerText={formik.values.footerText}
                              buttons={formik.values.buttons}
                            />
                          </Box>
                        </Box>
                      )}
                    </Flex>
                  </form>
                );
              }}
            </Formik>
          )}
        </Box>

        {/* Sticky footer buttons */}
        <PageFooterContainer
          css={{
            width: '100%',
            position: 'sticky',
            bottom: 0,
            zIndex: 10,
          }}
          border
        >
          {activeTab === 'setup' && (
            <Flex justify="between" css={{ width: '100%' }}>
              <Button
                variant="outline"
                type="button"
                css={{ height: '40px', px: '$4' }}
                onClick={() => history.push('/settings/templates')}
              >
                Discard
              </Button>
              <Button
                type="button"
                css={{ height: '40px', px: '$4' }}
                onClick={handleNext}
              >
                Next
              </Button>
            </Flex>
          )}

          {activeTab === 'edit' && (
            <Flex justify="between" css={{ width: '100%' }}>
              <Button
                variant="outline"
                type="button"
                css={{ height: '40px', px: '$4' }}
                onClick={() => setActiveTab('setup')}
              >
                Back
              </Button>
              <Button
                type="submit"
                form={isEditing ? 'edit-template-form' : 'create-template-form'}
                css={{ height: '40px', px: '$4' }}
              >
                {isEditing ? 'Update' : 'Submit'}
              </Button>
            </Flex>
          )}
        </PageFooterContainer>
      </SettingsLayout>
    </>
  );
}
