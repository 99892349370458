import { debounce, DebouncedFunc } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { HiLightBulb } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';

import { handleFilterChange, handleSortChange } from '@/pages/data/utils/filterActions';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { SearchFilters } from '@/shared/types/contacts';
import { FilterType, Sort } from '@/shared/types/filter';
import { Banner, Box, Flex } from '@/shared/ui';

import { defaultSort, initialState, useLLMAgent } from '../context/AgentsContext';
import { AgentsTable } from '../editor/AgentsTable';
import { CreateAgent } from '../editor/CreateAgent';
import {
  created_by,
  current_agent_version_object,
  default_agents_object,
  updated_by,
} from './config/filterConfig';
import { sortConfig } from './config/sortConfig';

export const Agents = () => {
  const [quickSearchValue, setQuickSearchValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);

  const agentContext = useLLMAgent();
  const { state, updateAgentsFilters } = agentContext;
  const { agents, loading } = state;

  useEffect(() => {
    resetFilters();
  }, []);

  const resetFilters = () => {
    setQuickSearchValue('');
    setActiveFilters([]);
    updateAgentsFilters({
      ...initialState.agentsFilters,
      sort: defaultSort,
    });
  };

  const history = useHistory();

  const onCardClick = (id: string) => {
    history.push(`/agents/${id}/editor`);
  };

  const debouncedUpdate = useCallback(
    debounce((props: SearchFilters) => {
      updateAgentsFilters(props);
    }, 1000),
    []
  );

  const handleOffsetChange = (offset: number) => {
    updateAgentsFilters({ ...state.agentsFilters, offset });
  };

  const handleQuickSearch = (
    debouncedUpdate: DebouncedFunc<(props: SearchFilters) => void>,
    filters: SearchFilters,
    value: string
  ) => {
    setQuickSearchValue(value);
    const formattedValue = value.trim();
    const quickFilters = [
      {
        resource: 'current_version',
        column: 'name',
        comparison: 'ilike',
        value: `%${formattedValue}%`,
      },
    ];

    debouncedUpdate({
      ...filters,
      searchFilter: quickFilters,
      offset: 0,
    });
  };

  const channel = useChannels();
  const { channels } = channel.channelsState;

  // has one compatible channel where type is phone, provider is twilio
  const hasCompatibleChannel = channels.some(
    (channel) => channel.type === 'phone' && channel.provider === 'twilio'
  );

  return (
    <PageLayout
      breadcrumbs={[{ title: 'Agents', path: '/agents' }]}
      actions={<CreateAgent />}
    >
      <Flex direction="column" css={{ flex: '1 1 auto', p: 30 }}>
        <Box css={{ paddingBottom: 24 }}>
          <CombinedFilters
            quickSearchPlaceholder="Search Agents"
            quickSearchValue={quickSearchValue}
            setQuickSearchValue={(value: string) =>
              handleQuickSearch(debouncedUpdate, state.agentsFilters, value)
            }
            defaultObjects={[
              default_agents_object,
              current_agent_version_object,
              updated_by,
              created_by,
            ]}
            customObjects={[]}
            activeFilters={activeFilters}
            setFilters={(value: Array<FilterType>) => {
              setActiveFilters(value);
              handleFilterChange(debouncedUpdate, state.agentsFilters, value);
            }}
            sortConfig={sortConfig}
            activeSort={state.agentsFilters.sort}
            onSortUpdate={(value: Array<Sort>) => {
              handleSortChange(updateAgentsFilters, state.agentsFilters, value);
            }}
          />
        </Box>
        {!hasCompatibleChannel && (
          <Banner
            css={{
              padding: 6,
              fontSize: 14,
              mb: 10,
              borderColor: '$yellow6',
              borderWidth: 1,
            }}
            variant="yellow"
          >
            <HiLightBulb /> Contact customer support to get a agent compatible phone
            number to automate your phone calls.
          </Banner>
        )}
        <AgentsTable
          data={agents.data}
          loading={loading}
          totalCount={agents.total}
          onCardClick={onCardClick}
          setOffset={handleOffsetChange}
        />
      </Flex>
    </PageLayout>
  );
};
