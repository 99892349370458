import * as React from 'react';
import * as Yup from 'yup';

import {
  StepFive,
  StepFour,
  StepOne,
  StepThree,
  StepTwo,
} from '@/settings/organization/trust/campaigns/createCampaignView/createCampaignSteps';

export const initialCampaignFormData = {
  brand: '',
  description: '',
  use_case: 'MIXED',
  sub_use_cases: ['MIXED'],
  opt_in_keywords: ['START'],
  opt_out_keywords: ['STOP'],
  help_keywords: ['HELP'],
  help_message: '',
  opt_in_message: '',
  opt_out_message: '',
  message_flow: '',
  messageSample1: '',
  messageSample2: '',
  messageSample3: '',
  messageSample4: '',
  messageSample5: '',
  affiliate_marketing: false,
  age_gated: false,
  auto_renewal: true,
  embedded_link: true,
  embedded_phone: false,
  number_pool: false,
  subscriber_opt_in: true,
  subscriber_opt_out: true,
  subscriber_help: true,
  direct_lending: false,
  industry: 'none',
};

export const campaignValidation = Yup.object({
  description: Yup.string().required('Required'),
  use_case: Yup.string().required('Required'),
  sub_use_cases: Yup.array(),
  opt_in_keywords: Yup.array(),
  opt_out_keywords: Yup.array(),
  help_keywords: Yup.array(),
  help_message: Yup.string().required('Required'),
  opt_in_message: Yup.string(),
  opt_out_message: Yup.string(),
  message_flow: Yup.string().required('Required'),
  messageSample1: Yup.string(),
  messageSample2: Yup.string(),
  messageSample3: Yup.string(),
  messageSample4: Yup.string(),
  messageSample5: Yup.string(),
  affiliate_marketing: Yup.boolean(),
  age_gated: Yup.boolean(),
  auto_renewal: Yup.boolean(),
  embedded_link: Yup.boolean(),
  embedded_phone: Yup.boolean(),
  number_pool: Yup.boolean(),
  subscriber_opt_in: Yup.boolean(),
  subscriber_opt_out: Yup.boolean(),
  subscriber_help: Yup.boolean(),
  direct_lending: Yup.boolean(),
});

export const createCampaignValidation = campaignValidation.concat(
  Yup.object({
    brand: Yup.string().required('Required'),
  })
);
export const steps = [
  {
    title: 'Create 10DLC Campaign',
    description: 'Fill in the essential details below',
    children: <StepOne />,
  },
  {
    title: 'Define Keywords',
    description: 'Fill in the essential details below',
    children: <StepTwo />,
  },
  {
    title: 'Help & Opt-In & Opt-Out Messages',
    description: 'Fill in the essential details below',
    children: <StepThree />,
  },
  {
    title: 'Flow & Sample Messages',
    description: 'Fill in the essential details below',
    children: <StepFour />,
  },
  {
    title: 'Select Campaign Attributes',
    description: 'Choose applicable campaign features',
    children: <StepFive />,
  },
];

export const editSteps = [
  {
    title: 'Edit 10DLC Campaign',
    description: '',
    children: <StepOne />,
  },
  {
    title: 'Define Keywords',
    description: '',
    children: <StepTwo />,
  },
  {
    title: 'Help & Opt-In & Opt-Out Messages',
    description: '',
    children: <StepThree />,
  },
  {
    title: 'Flow & Sample Messages',
    description: '',
    children: <StepFour />,
  },
  {
    title: 'Select Campaign Attributes',
    description: '',
    children: <StepFive />,
  },
];
