import { FormikValues, useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import {
  altBusinessRegistrationTypes,
  businessRegistrationTypes,
  businessTypes,
  businessUseCase,
} from '@/settings/organization/trust/brands/lists';
import {
  FormFieldWrapper,
  helpers,
  SelectCombobox,
  TextInput,
} from '@/shared/components/forms';
import { Box, Flex } from '@/shared/ui';

export const StepThree = () => {
  const formik = useFormikContext<FormikValues>();

  useEffect(() => {
    formik.setFieldValue('business_registration_number', formik.values.universal_ein);
  }, [formik.values.universal_ein]);

  return (
    <Flex css={{ width: '100%' }} direction="column">
      <Box>
        <FormFieldWrapper
          label="Business Type"
          tooltip="The form of the business organization."
          name="business_type"
        >
          <SelectCombobox
            placeholder={helpers.displaySelectedItem}
            isDropdown={true}
            options={businessTypes}
          />
        </FormFieldWrapper>
        <FormFieldWrapper
          label="Business Use Case"
          tooltip="Select the primary purpose or application for which the business intends to use this service or product"
          name="business_use_case"
        >
          <SelectCombobox
            placeholder={helpers.displaySelectedItem}
            isDropdown={true}
            options={businessUseCase.map((el) => ({ type: el, value: el }))}
          />
        </FormFieldWrapper>

        <FormFieldWrapper label="Website" tooltip="Brand website URL." name="website">
          <TextInput placeholder="www.examplecompany.com" />
        </FormFieldWrapper>
        <FormFieldWrapper
          label="Universal ein"
          tooltip="Enter the unique Employer Identification Number (EIN) assigned to the business"
          name="universal_ein"
        >
          <TextInput placeholder="91-1870309" type="ein" />
        </FormFieldWrapper>
        <FormFieldWrapper
          label="Business Registration Number"
          tooltip="Тhe value of the business registration number."
          name="business_registration_number"
        >
          <TextInput placeholder="91-1870309" type="ein" />
        </FormFieldWrapper>
        <FormFieldWrapper
          label="Business Registration Type"
          tooltip="Тhe type of the business registration number."
          name="business_registration_type"
        >
          <SelectCombobox
            placeholder={helpers.displaySelectedItem}
            isDropdown={true}
            options={businessRegistrationTypes}
          />
        </FormFieldWrapper>
        <FormFieldWrapper
          label="Alt Business Registration Number"
          tooltip="Тhe value an alternative business registration number."
          name="alt_business_registration_number"
        >
          <TextInput placeholder="91-1870309" type="ein" />
        </FormFieldWrapper>
        <FormFieldWrapper
          label="Alt Business Registration Type"
          tooltip="Тhe type of the alternative business registration number."
          name="alt_business_registration_type"
        >
          <SelectCombobox
            placeholder={helpers.displaySelectedItem}
            isDropdown={true}
            options={altBusinessRegistrationTypes}
          />
        </FormFieldWrapper>
        <FormFieldWrapper
          label="Average Business Day Call Volume"
          tooltip="Specify the estimated number of incoming or outgoing calls handled by the business on an average business day."
          name="average_business_day_call_volume"
        >
          <TextInput placeholder="50000" />
        </FormFieldWrapper>
      </Box>
    </Flex>
  );
};
