export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';

export enum NotificationPreferencesType {
  WEB = 'web',
  EMAIL = 'email',
  SMS = 'sms',
  PUSH = 'push',
  VOICE = 'voice',
}

export const NOTIFICATION_PREFERENCES = [
  { label: 'Email', value: NotificationPreferencesType.EMAIL },
  { label: 'SMS', value: NotificationPreferencesType.SMS },
  { label: 'Mobile Push', value: NotificationPreferencesType.PUSH },
  { label: 'Web', value: NotificationPreferencesType.WEB },
  { label: 'Voice', value: NotificationPreferencesType.VOICE },
];

export const NOTIFICATION_TYPES = [
  {
    title: 'Inbox',
    items: [
      'assigned_user',
      'new_message',
      'new_message_for_all_conversation',
      'new_message_for_unassigned_conversation',
      'no_response_short',
      'no_response_long',
      'keyword_triggered',
      'mention',
      'call_started',
      'call_ended',
      'call_missed',
    ],
    allowedMethods: [
      NotificationPreferencesType.EMAIL,
      NotificationPreferencesType.SMS,
      NotificationPreferencesType.PUSH,
      NotificationPreferencesType.WEB,
    ],
  },
  {
    title: 'Contacts',
    items: ['new_lead'],
    allowedMethods: [
      NotificationPreferencesType.EMAIL,
      NotificationPreferencesType.SMS,
      NotificationPreferencesType.PUSH,
      NotificationPreferencesType.WEB,
    ],
  },
  {
    title: 'Imports',
    items: ['import_status'],
    allowedMethods: [
      NotificationPreferencesType.EMAIL,
      NotificationPreferencesType.SMS,
      NotificationPreferencesType.PUSH,
      NotificationPreferencesType.WEB,
    ],
  },
  {
    title: 'Usage',
    items: ['billing_usage'],
    allowedMethods: [
      NotificationPreferencesType.EMAIL,
      NotificationPreferencesType.SMS,
      NotificationPreferencesType.PUSH,
      NotificationPreferencesType.WEB,
    ],
  },
  {
    title: 'Links',
    items: ['link_click', 'link_click_for_all_messages'],
    allowedMethods: [
      NotificationPreferencesType.EMAIL,
      NotificationPreferencesType.SMS,
      NotificationPreferencesType.PUSH,
      NotificationPreferencesType.WEB,
    ],
  },
  {
    title: 'Calls',
    items: ['incoming_calls'],
    allowedMethods: [NotificationPreferencesType.VOICE],
  },
];
