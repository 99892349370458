import { useCallback, useEffect, useState } from 'react';

import { CreateRecordingModal } from '@/shared/components/CreateRecordingModal';
import { useDisclosure } from '@/shared/hooks';
import { CallSettings as CallSettingsType } from '@/shared/types/channels';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Box,
  Button,
  ControlGroup,
  Divider,
  Fieldset,
  Flex,
  Input,
  Label,
  RadioGroup,
  RadioGroupIndicator,
  RadioGroupRadio as Radio,
  StyledRadioIndicator,
  Switch,
  SwitchThumb,
  Text,
  TextArea,
  VStack,
} from '@/shared/ui';

import { SettingsErrorBoundary } from '../../general/SettingsErrorBoundary';
import { defaultChannelSettings, useChannels } from '../context/ChannelContext';

type GreetingType = {
  content_type: 'text' | 'recording_url';
  content: string;
};

const MAX_TEXT_GREETING_LENGTH = 255;
const MAX_RING_TIMEOUT = 600;
const MIN_RING_TIMEOUT = 0;

const STYLES = {
  audio: {
    container: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: '#E2E8F0',
      borderRadius: '8px',
      padding: '16px 16px',
      width: '100%',
      display: 'flex',
      backgroundColor: 'white',
      position: 'relative',
      minHeight: '88px',
    } as const,
    box: {
      flex: 1,
      background: '$gray100',
      p: '$2',
      borderRadius: '$md',
      height: '56px',
      overflow: 'hidden',
    } as const,
    element: {
      width: '100%',
      height: '56px',
      marginTop: '-8px',
    } as const,
  },
  radio: {
    label: {
      mb: 0,
      ml: '8px',
      fontSize: 12,
    } as const,
    indicator: {
      boxShadow: 'none',
      py: '4px !important',
    } as const,
  },
  voicemail: {
    textArea: {
      minHeight: '88px',
    } as const,
    characterCount: {
      color: '$gray600',
      fontSize: '75%',
      position: 'relative',
      top: '-$5',
    } as const,
    container: {
      mb: '$3',
    } as const,
  },
} as const;

const AudioPlayer = ({ src }: { src?: string }) => (
  <Box css={STYLES.audio.container}>
    <Box
      css={{
        ...STYLES.audio.box,
        ...(src ? {} : { opacity: 0.5, pointerEvents: 'none' }),
      }}
    >
      <audio
        key={src}
        controls
        style={STYLES.audio.element}
        data-testid="channel-voicemail-greeting-audio"
      >
        <source src={src || ''} type="audio/mpeg" />
        <track kind="captions" />
      </audio>
    </Box>
  </Box>
);

export const CallSettings = () => {
  const {
    channelsState: { current },
    updateChannel,
  } = useChannels();

  useEffect(() => {
    if (current?.settings?.call?.ring_timeout_secs != null) {
      setRingTimeoutInput(current.settings.call.ring_timeout_secs.toString());
    } else if (current?.settings?.call) {
      // Set default value if ring_timeout_secs is null or undefined
      setRingTimeoutInput('30');
    }
  }, [current?.settings?.call?.ring_timeout_secs]);

  const [tempGreeting, setTempGreeting] = useState<GreetingType>({
    content_type: (current?.settings?.call?.voicemail_greetings?.find(
      (greeting) => greeting.greeting_type === 'missed_call'
    )?.content_type || 'text') as 'text' | 'recording_url',
    content:
      current?.settings?.call?.voicemail_greetings?.find(
        (greeting) => greeting.greeting_type === 'missed_call'
      )?.content || '',
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isUpdating, setIsUpdating] = useState(false);
  const [ringTimeoutInput, setRingTimeoutInput] = useState(
    (current?.settings?.call?.ring_timeout_secs ?? 30).toString()
  );

  const mergeAndUpdateCallSettings = useCallback(
    async (callSettings: Partial<CallSettingsType>) => {
      if (!current?.id) return;

      const updatedCallSettings = {
        ...defaultChannelSettings.call,
        ...(current?.settings?.call || {}),
        voicemail_greetings:
          callSettings.voicemail_greetings ||
          current?.settings?.call?.voicemail_greetings ||
          [],
        ...callSettings,
      } as CallSettingsType;

      return updateChannel({
        ...current,
        settings: {
          ...(current?.settings || defaultChannelSettings),
          call: updatedCallSettings,
        },
      });
    },
    [current, updateChannel]
  );

  const handleCallSettingsUpdate = useCallback(
    async <T extends CallSettingsType[keyof CallSettingsType]>(
      field: keyof CallSettingsType,
      value: T
    ): Promise<void> => {
      try {
        await mergeAndUpdateCallSettings({ [field]: value });
      } catch (error) {
        console.error(`Error updating ${field}:`, error);
      }
    },
    [mergeAndUpdateCallSettings]
  );

  const handleVoicemailGreetingSettings = useCallback(
    async (greeting: GreetingType) => {
      if (!current?.id) return;

      try {
        setIsUpdating(true);
        await mergeAndUpdateCallSettings({
          voicemail_greetings: [
            {
              greeting_type: 'missed_call',
              ...greeting,
            },
          ],
        });
      } catch (error) {
        console.error('Error updating voicemail greetings:', error);
      } finally {
        setIsUpdating(false);
      }
    },
    [current?.id, mergeAndUpdateCallSettings]
  );

  const findMissedCallGreeting = useCallback(() => {
    return current?.settings?.call?.voicemail_greetings?.find(
      (greeting) => greeting.greeting_type === 'missed_call'
    );
  }, [current?.settings?.call?.voicemail_greetings]);

  const isValidRingTimeout = (value: string): boolean => {
    if (value === '') return false;
    const numValue = parseInt(value);
    return (
      !Number.isNaN(numValue) &&
      Number.isFinite(numValue) &&
      numValue >= MIN_RING_TIMEOUT &&
      numValue <= MAX_RING_TIMEOUT
    );
  };

  const handleRingTimeoutSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevent form submission

    // Don't proceed with update if validation fails
    if (!isValidRingTimeout(ringTimeoutInput)) {
      return;
    }

    const value = parseInt(ringTimeoutInput);
    await handleCallSettingsUpdate('ring_timeout_secs', value);
  };

  return (
    <SettingsErrorBoundary feature="Call Settings">
      <Accordion
        css={{ background: 'white' }}
        type="single"
        collapsible
        data-testid="channel-call-settings"
      >
        <AccordionItem value="basic-information" variant="neumorphic">
          <Flex justify="between" align="center">
            <VStack gap={2} css={{ lineHeight: 1.5 }}>
              <Text css={{ fontWeight: 600 }}>Call Settings</Text>
              <Text>Update call settings that apply to this channel only.</Text>
            </VStack>
            <AccordionTrigger />
          </Flex>
          <AccordionContent variant="neumorphic">
            <Divider css={{ mt: -20, mb: 20 }} />
            <VStack gap={2}>
              <Flex
                align="center"
                justify="between"
                data-testid="channel-call-conference-toggle"
              >
                <Label>Conferences</Label>
                <Switch
                  checked={current?.settings?.call?.conferences}
                  onCheckedChange={(value) =>
                    handleCallSettingsUpdate('conferences', value)
                  }
                >
                  <SwitchThumb />
                </Switch>
              </Flex>
              <Flex
                align="center"
                justify="between"
                data-testid="channel-call-recording-toggle"
              >
                <Label>Recordings</Label>
                <Switch
                  checked={current?.settings?.call?.recordings}
                  onCheckedChange={(value) =>
                    handleCallSettingsUpdate('recordings', value)
                  }
                >
                  <SwitchThumb />
                </Switch>
              </Flex>
              <Flex
                align="center"
                justify="between"
                data-testid="channel-call-transcription-toggle"
              >
                <Label>Transcriptions</Label>
                <Switch
                  checked={current?.settings?.call?.transcriptions}
                  onCheckedChange={(value) =>
                    handleCallSettingsUpdate('transcriptions', value)
                  }
                >
                  <SwitchThumb />
                </Switch>
              </Flex>
              <Flex
                align="center"
                justify="between"
                data-testid="channel-call-voicemail-for-missed-call-toggle"
              >
                <Label>Voicemail for Missed Calls</Label>
                <Switch
                  checked={current?.settings?.call?.voicemail_for_missed_call}
                  onCheckedChange={(value) =>
                    handleCallSettingsUpdate('voicemail_for_missed_call', value)
                  }
                >
                  <SwitchThumb />
                </Switch>
              </Flex>

              {current?.settings?.call?.voicemail_for_missed_call && (
                <>
                  <Divider css={{ my: 15 }} />
                  <VStack gap={4}>
                    <Box>
                      <Label>Voicemail Greetings</Label>
                      <Text>
                        Configure how your voicemail greeting for this channel is
                        delivered.
                      </Text>
                    </Box>

                    <RadioGroup
                      value={tempGreeting.content_type}
                      onValueChange={(value: 'text' | 'recording_url') => {
                        const missedCallGreeting = findMissedCallGreeting();
                        setTempGreeting({
                          content_type: value,
                          content:
                            value === missedCallGreeting?.content_type
                              ? missedCallGreeting?.content || ''
                              : '',
                        });
                      }}
                    >
                      <Flex gap={4}>
                        <StyledRadioIndicator
                          align="center"
                          css={{ boxShadow: 'none', py: '4px !important' }}
                        >
                          <Radio
                            value="text"
                            data-testid="channel-call-greetings-text-radio"
                          >
                            <RadioGroupIndicator />
                          </Radio>
                          <Label css={{ mb: 0, ml: '8px', fontSize: 12 }}>Text</Label>
                        </StyledRadioIndicator>

                        <StyledRadioIndicator
                          align="center"
                          css={{
                            boxShadow: 'none',
                            py: '4px !important',
                          }}
                          title="Recording"
                        >
                          <Radio
                            value="recording_url"
                            data-testid="channel-call-greetings-recording-radio"
                          >
                            <RadioGroupIndicator />
                          </Radio>
                          <Label css={{ mb: 0, ml: '8px', fontSize: 12 }}>
                            Recording
                          </Label>
                        </StyledRadioIndicator>
                      </Flex>
                    </RadioGroup>

                    {tempGreeting.content_type === 'text' ? (
                      <>
                        <TextArea
                          value={
                            typeof tempGreeting.content === 'string' &&
                            !tempGreeting.content.startsWith('http')
                              ? tempGreeting.content
                              : ''
                          }
                          onChange={(e) => {
                            setTempGreeting((prev) => ({
                              ...prev,
                              content: e.target.value,
                            }));
                          }}
                          maxLength={MAX_TEXT_GREETING_LENGTH}
                          placeholder="Type your greetings message here, it will be converted to audio using our text-to-speech AI technology."
                          css={STYLES.voicemail.textArea}
                          rows={4}
                        />
                        <Flex
                          justify="between"
                          align="center"
                          css={STYLES.voicemail.container}
                        >
                          <Text css={STYLES.voicemail.characterCount}>
                            {`${tempGreeting.content.length}/${MAX_TEXT_GREETING_LENGTH} characters`}
                          </Text>
                          <Button
                            onClick={() => handleVoicemailGreetingSettings(tempGreeting)}
                            variant="primary"
                            disabled={isUpdating || tempGreeting.content.length === 0}
                          >
                            Save Changes
                          </Button>
                        </Flex>
                      </>
                    ) : (
                      <>
                        <Box>
                          <AudioPlayer
                            src={
                              tempGreeting.content_type === 'recording_url' &&
                              tempGreeting.content_type ===
                                current?.settings?.call?.voicemail_greetings?.find(
                                  (greeting) => greeting.greeting_type === 'missed_call'
                                )?.content_type
                                ? tempGreeting.content
                                : undefined
                            }
                          />
                        </Box>
                        <Flex justify="end" gap={2}>
                          <Button
                            onClick={onOpen}
                            variant="primary"
                            disabled={isUpdating}
                          >
                            Record new Greeting
                          </Button>
                        </Flex>
                        <CreateRecordingModal
                          isOpen={isOpen}
                          onClose={onClose}
                          currentRecordingUrl={
                            tempGreeting.content_type === 'recording_url'
                              ? tempGreeting.content
                              : undefined
                          }
                          onSave={async (recordingUrl) => {
                            const updatedGreeting: GreetingType = {
                              content_type: 'recording_url',
                              content: recordingUrl,
                            };

                            await handleVoicemailGreetingSettings(updatedGreeting);
                            setTempGreeting(updatedGreeting);
                            onClose();
                          }}
                        />
                      </>
                    )}
                  </VStack>

                  <Divider css={{ my: 15 }} />
                </>
              )}

              <form
                onSubmit={handleRingTimeoutSubmit}
                data-testid="ring-timeout-form"
                noValidate
              >
                <VStack gap={4}>
                  <Fieldset>
                    <VStack gap={1}>
                      <Label htmlFor="ring_timeout_secs">
                        Incoming Call Ring Timeout
                      </Label>
                      <Text>
                        Set the duration (in seconds) before a call is considered missed
                      </Text>
                      <ControlGroup css={{ mt: '$2' }}>
                        <Input
                          id="ring_timeout_secs"
                          type="number"
                          placeholder="30"
                          name="settings.ring_timeout_secs"
                          value={ringTimeoutInput}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            setRingTimeoutInput(inputValue); // Allow any input, validation happens on submit
                          }}
                          min={MIN_RING_TIMEOUT}
                          max={MAX_RING_TIMEOUT}
                          data-testid="ring-timeout-input"
                        />
                        <Flex
                          align="center"
                          css={{
                            fontSize: 13,
                            px: '$3',
                            backgroundColor: '$gray100',
                          }}
                        >
                          Seconds
                        </Flex>
                      </ControlGroup>
                    </VStack>
                  </Fieldset>
                  <Box css={{ mt: '$2', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      type="submit"
                      disabled={!isValidRingTimeout(ringTimeoutInput)}
                      data-testid="update-ring-timeout-button"
                    >
                      Update Settings
                    </Button>
                  </Box>
                </VStack>
              </form>
            </VStack>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </SettingsErrorBoundary>
  );
};
