import React, { useEffect, useMemo, useState } from 'react';
import { useTable } from 'react-table';
import { Column as ReactTableColumn } from 'react-table';
import { TableVirtuoso } from 'react-virtuoso';

import {
  ChartErrorDescription,
  ChartErrorTitle,
} from '@/pages/links/analytics/ErrorStateAnalytics';
import { ReportData, ReportDataValue } from '@/shared/api/reports';
import { Box, Flex, Tbody, VStack } from '@/shared/ui';
import { Loading } from '@/shared/v2/components/table/Table';
import { styled } from '@/stitches.config';

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const Heatmap = ({
  data,
  loading,
  error,
}: {
  loading: boolean;
  data: Array<ReportData>;
  error?: boolean;
}) => {
  const [reportHeatmap, setReportHeatmap] = useState<Array<Array<ReportDataValue>>>([]);
  const [maxValue, setMaxValue] = useState(1);

  const columns: ReactTableColumn[] = useMemo(
    () =>
      reportHeatmap.length
        ? [
            {
              Header: 'Day',
              accessor: `day`,
              colWidth: 42,
            },
            ...reportHeatmap[0].map((r) => {
              return {
                Header: r.y,
                accessor: `${r.y}.z`,
                minWidth: 26,
                colWidth: `calc( (100% - 42px) / ${reportHeatmap.length - 1} )`,
                Cell: (row: { value: string }) => row.value,
              };
            }),
          ]
        : [],
    [reportHeatmap]
  );

  useEffect(() => {
    const heatmap = data.find((d) => d.chart === 'heatmap');
    const report = heatmap
      ? heatmap.values.reduce(
          (group: Record<string | number, Array<ReportDataValue>>, day) => {
            const { x } = day;
            group[x] = group[x] ?? [];
            group[x].push(day);
            return group;
          },
          {}
        )
      : [];
    setReportHeatmap(Object.values(report));
    const maxZValue = heatmap ? Math.max(...heatmap.values.map((o) => o.z)) : 1;
    if (maxZValue) setMaxValue(maxZValue);
  }, [data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data: reportHeatmap,
  });

  if (!reportHeatmap?.length && !loading && !error) {
    return (
      <VStack data-testid="heatmap-empty-state" gap={2} css={{ height: 290 }}>
        <Box css={{ textAlign: 'center', m: 'auto', fontSize: 18, fontWeight: 700 }}>
          No data to display yet. Try again later
        </Box>
      </VStack>
    );
  }

  if (error) {
    return (
      <VStack gap={2} align="center">
        <ChartErrorTitle>Fail to Load Analytics</ChartErrorTitle>
        <ChartErrorDescription>
          An error occurred while loading this analytics
        </ChartErrorDescription>
      </VStack>
    );
  }

  return (
    <Flex direction="column" css={{ height: '100%', overflow: 'hidden' }}>
      {loading ? (
        <Table data-testid="heatmap-loading-table">
          <Tbody data-testid="table-loading-placeholder" {...getTableBodyProps()}>
            <Loading itemsCount={7} colSpan={10} colHeight={20} />
          </Tbody>
        </Table>
      ) : (
        <Table data-testid="heatmap-table">
          <TableVirtuoso
            style={{ height: 290, overflowY: 'hidden' }}
            data={reportHeatmap}
            initialItemCount={reportHeatmap.length}
            components={{
              Table: ({ style, ...props }) => <table {...getTableProps()} {...props} />,
              TableBody: React.forwardRef(({ style, ...props }, ref) => (
                <>
                  <tbody {...getTableBodyProps()} {...props} ref={ref} />
                </>
              )),
              TableRow: (props) => {
                const index = props['data-index'];
                const row = rows[index];
                return row ? (
                  <tr data-testid="filters-table-row" {...props} {...row.getRowProps()} />
                ) : null;
              },
              FillerRow: ({ height }) => {
                return (
                  <tr>
                    <td
                      colSpan={columns.length}
                      style={{ height: height, padding: 0, border: 0 }}
                    />
                  </tr>
                );
              },
            }}
            fixedHeaderContent={() => {
              return headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={`tr-${index}`}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      key={`th-${column.id}`}
                      style={{
                        maxHeight: 40,
                        width: column.colWidth || 'auto',
                        minWidth: '26px',
                        maxWidth: column.colWidth || 'auto',
                        paddingTop: 4,
                        paddingBottom: 3,
                        fontSize: 13,
                        justifyContent: 'center',
                      }}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ));
            }}
            itemContent={(index) => {
              const row = rows[index];
              if (!row) {
                return null; // or return a placeholder or loading indicator
              }
              prepareRow(row);
              const cells = row.cells.slice(0, -1).map((cell, index) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    key={`td-${cell.column.id}`}
                    style={{
                      height: 36,
                      maxHeight: 50,
                      minWidth: 26,
                      maxWidth: cell.column.colWidth || 'auto',
                      background: `rgba(141, 164, 239, ${row.values[`${index}.z`] / maxValue})`,
                    }}
                  >
                    {row.values[`${index}.z`]}
                  </td>
                );
              });
              return [
                <td
                  key="day"
                  style={{
                    textAlign: 'center',
                    background: 'white',
                    position: 'sticky',
                    width: 42,
                    fontWeight: 700,
                    zIndex: 1,
                    left: 0,
                  }}
                >
                  {days[index]}
                </td>,
                ...cells,
              ];
            }}
          />
        </Table>
      )}
    </Flex>
  );
};

const Table = styled('table', {
  boxSizing: 'border-box',
  fontSize: '14px',
  width: '100%',
  tableLayout: 'fixed',
  borderSpacing: 0,
  textAlign: 'center',
  backgroundColor: 'white',
  color: '#0007139F',

  '& table': {
    tableLayout: 'fixed',
    minWidth: '100%',
  },

  '& thead': {
    position: 'sticky',
    top: 0,
    'tr > th:first-child': {
      background: 'white',
      position: 'sticky',
      textAlign: 'center',
      left: 0,
      fontWeight: 700,
      zIndex: 2,
    },
  },

  '& tr': {
    height: 36,
    px: 8,
    boxSizing: 'border-box',
    fontSize: '13px',
    position: 'relative',
    backgroundColor: 'white',
  },

  '& td': {
    boxSizing: 'border-box',
    position: 'relative',
    margin: 0,
    px: 2,
    borderTop: 'none',
  },

  '.tooltipContainer span': {
    position: 'absolute',
    top: 10,
    left: 80,
    visibility: 'hidden',
    padding: '5px 10px',
    backgroundColor: '$gray12',
    color: '$slate1',
    fontWeight: 400,
    borderRadius: 6,
    wordWrap: 'break-word',
    maxWidth: '140px',
  },
  '.tooltipContainer:hover span': {
    visibility: 'visible',
  },

  '& th': {
    height: 36,
    position: 'relative',
    fontWeight: 700,
    boxSizing: 'border-box',
    margin: 0,
    px: 2,
    zIndex: 1,
    borderTop: 'none',
    textAlign: 'center',
  },
});
