import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './datepickerController.css';

import moment, { Moment } from 'moment';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { DayPickerRangeController, ModifiersShape } from 'react-dates';
import { HiArrowLeft, HiArrowRight } from 'react-icons/hi';

import { Box, Flex } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { ValueCombobox } from '../filterBuilder/values/Combobox';
import { DatepickerDates } from './DateSelector';

export type DatepickerControllerProps = {
  setParentDates: Dispatch<SetStateAction<any>>;
  minDate?: string;
  maxDate?: string;
  minDaysRange?: number;
  initialDates?: DatepickerDates;
  closeDateSelector?: () => void;
};

export const DatepickerController = (props: DatepickerControllerProps) => {
  const { setParentDates, minDate, maxDate, initialDates, minDaysRange = 1 } = props;
  const [focusedInput, setFocusedInput] = useState<any>();
  const [dates, setDates] = useState({
    startDate: initialDates?.startDate || moment().add(-30, 'day'),
    endDate: initialDates?.endDate || moment(),
  });

  const renderMonthElement = ({
    month,
    onMonthSelect,
    onYearSelect,
  }: {
    month: Moment;
    onMonthSelect: (currentMonth: Moment, newMonthVal: string) => void;
    onYearSelect: (currentMonth: Moment, newMonthVal: string) => void;
  }) => {
    const years = arrayRange(
      minDate ? moment(minDate).year() + 3 : moment().year(),
      minDate ? moment(minDate).year() : 2022
    );

    return (
      <Flex align="center" justify="center">
        <ValueCombobox
          selectorStyles={{
            border: 'none',
            fontSize: 16,
            padding: '6px 8px',
            '&:hover': { backgroundColor: '#E6EDFE', color: '#3E63DD' },
          }}
          popoverStyles={{ zIndex: 999999, padding: 0, border: '1px solid #00003B0D' }}
          minWidth="auto"
          comboboxItemStyles={{
            '&[data-active-item]': {
              backgroundColor: '#3A5CCC',
              color: '#fff',
            },
          }}
          selectLabel=""
          withSearch={false}
          options={moment.months().map((label, value) => ({
            label: label,
            value: value,
          }))}
          selected={{
            label: month.month().toString(),
            value: month.month(),
          }}
          sideOffset={0}
          onSelect={(option) => onMonthSelect(month, option.value.toString())}
          usePortal
        />
        <ValueCombobox
          selectorStyles={{
            border: 'none',
            fontSize: 16,
            padding: '6px 8px',
            '&:hover': { backgroundColor: '#E6EDFE', color: '#3E63DD' },
          }}
          popoverStyles={{ zIndex: 999999, padding: 0, border: '1px solid #00003B0D' }}
          minWidth="auto"
          comboboxItemStyles={{
            '&[data-active-item]': {
              backgroundColor: '#3A5CCC',
              color: '#fff',
            },
          }}
          selectLabel=""
          withSearch={false}
          options={years.map((year) => ({
            label: year.toString(),
            value: year,
          }))}
          selected={{
            label: month.year().toString(),
            value: month.year(),
          }}
          sideOffset={0}
          onSelect={(option) => onYearSelect(month, option.value.toString())}
          usePortal
        />
      </Flex>
    );
  };

  const renderDayContents = (day: moment.Moment, modifiers: ModifiersShape) => {
    const isStartOfMonth = day && moment(day).startOf('month').date() === day.date();
    const isEndOfMonth = day && moment(day).endOf('month').date() === day.date();

    let styles = {};
    if (modifiers.has('today')) {
      styles = {
        color: '#13171e',
        background: '#ffffff',
        border: 'none',
        boxShadow: 'inset 0 0 0 1px #3e54cf',
        borderRadius: 6,
      };
    }
    if (modifiers.has('hovered') && !modifiers.has('blocked')) {
      styles = {
        color: '#3E63DD',
        background: '#D9E2FC',
        border: 'none',
        borderRadius: 6,
      };
    } else if (modifiers.has('selected-span')) {
      styles = {
        boxShadow: 'none',
      };
    }

    if (modifiers.has('selected-end') || modifiers.has('selected-start')) {
      styles = {
        background: '#3e54cf',
        color: '#ffffff',
        border: 'none',
      };
    }

    return (
      <Box
        css={{
          height: 'calc(100% - 4px)',
          width: '100%',
          background: modifiers.has('selected-span') ? '#e6edfe' : '#fff',
          border:
            modifiers.has('selected-end') || modifiers.has('selected-start')
              ? '4px solid #e6edfe'
              : '4px solid transparent',
          borderLeft:
            modifiers.has('selected-end') &&
            !modifiers.has('first-day-of-week') &&
            !isStartOfMonth
              ? '4px solid #e6edfe'
              : '4px solid transparent',
          borderRight:
            modifiers.has('selected-start') &&
            !modifiers.has('last-day-of-week') &&
            !isEndOfMonth
              ? '4px solid #e6edfe'
              : '4px solid transparent',
          borderTopRightRadius:
            modifiers.has('selected-end') ||
            (modifiers.has('selected-start') &&
              (modifiers.has('last-day-of-week') || isEndOfMonth)) ||
            modifiers.has('last-day-of-week') ||
            isEndOfMonth
              ? 4
              : 0,
          borderBottomRightRadius:
            modifiers.has('selected-end') ||
            (modifiers.has('selected-start') &&
              (modifiers.has('last-day-of-week') || isEndOfMonth)) ||
            modifiers.has('last-day-of-week') ||
            isEndOfMonth
              ? 4
              : 0,
          borderTopLeftRadius:
            modifiers.has('selected-start') ||
            (modifiers.has('selected-end') &&
              (modifiers.has('first-day-of-week') || isStartOfMonth)) ||
            modifiers.has('first-day-of-week') ||
            isStartOfMonth
              ? 4
              : 0,
          borderBottomLeftRadius:
            modifiers.has('selected-start') ||
            (modifiers.has('selected-end') &&
              (modifiers.has('first-day-of-week') || isStartOfMonth)) ||
            modifiers.has('first-day-of-week') ||
            isStartOfMonth
              ? 4
              : 0,
          position: 'relative',
        }}
      >
        <Flex
          align="center"
          justify="center"
          css={{
            position: 'absolute',
            top: -4,
            bottom: -4,
            right: -4,
            left: -4,
            borderRadius: '6px',
            ...styles,
          }}
        >
          {day.date()}
        </Flex>
      </Box>
    );
  };

  return (
    <Box id="day-picker-range-controller">
      <Border css={{ left: 328 }} />
      <Border css={{ right: 170 }} />
      <DayPickerRangeController
        renderDayContents={renderDayContents}
        weekDayFormat="ddd"
        startDate={dates.startDate}
        endDate={dates.endDate}
        numberOfMonths={2}
        daySize={43}
        keepOpenOnDateSelect
        onDatesChange={({ startDate, endDate }: any) => {
          setDates({ startDate, endDate: endDate ?? dates.endDate });
          setParentDates({ startDate, endDate: endDate ?? dates.endDate });
          if (focusedInput === 'endDate' && props.closeDateSelector) {
            props.closeDateSelector();
          }
        }}
        focusedInput={focusedInput ?? 'startDate'}
        onFocusChange={(focusedInput) => {
          setFocusedInput(focusedInput);
        }}
        noBorder
        initialVisibleMonth={() => {
          return dates.startDate;
        }}
        minimumNights={minDaysRange}
        isOutsideRange={(day) => {
          if (minDate && maxDate) {
            return day.diff(maxDate, 'days') >= 1 || day.diff(minDate, 'days') <= 1;
          }
          if (maxDate) {
            return day.diff(maxDate, 'days') >= 1;
          }
          if (minDate) {
            return day.diff(minDate, 'days') <= 1;
          }
          return false;
        }}
        navPrev={
          <NavButton
            align="center"
            justify="center"
            css={{
              left: 21,
            }}
          >
            <HiArrowLeft />
          </NavButton>
        }
        navNext={
          <NavButton
            align="center"
            justify="center"
            css={{
              right: 41,
            }}
          >
            <HiArrowRight />
          </NavButton>
        }
        renderMonthElement={renderMonthElement}
      />
    </Box>
  );
};

const arrayRange = (start: number, stop: number) =>
  Array.from({ length: start - stop + 1 }, (value, index) => stop + index);

const NavButton = styled(Flex, {
  position: 'absolute',
  top: 22,
  width: 40,
  height: 40,
  color: '#60646C',
  borderRadius: 6,
  '&:hover': {
    background: '#D9E2FC',
    color: '#3E63DD',
  },
});

const Border = styled(Box, {
  position: 'absolute',
  right: 170,
  top: 0,
  bottom: 0,
  width: 1,
  background: '#00003b0d',
  zIndex: 5,
});
