/* eslint-disable react-hooks/exhaustive-deps */
import { CheckedState } from '@radix-ui/react-checkbox';
import dayjs from 'dayjs';
import React, { CSSProperties, useEffect, useState } from 'react';
import { HiExclamationCircle, HiRefresh, HiSearch } from 'react-icons/hi';

import { useCampaignsContext } from '@/campaigns/context/CampaignsContext';
import { CampaignAnalyticsTab } from '@/campaigns/context/types';
import { LoadingContactRow } from '@/contacts/ContactRow';
import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { useUserPreferences } from '@/pages/settings/user/preferences/context/PreferencesContext';
import { CampaignContact, Contact } from '@/shared/types/campaigns';
import { Box, Button, Checkbox, Flex, HStack, Input, Text, VStack } from '@/shared/ui';
import i18next from '@/shared/utils/translation';
import { formatPhoneNumber, isValidUuid } from '@/shared/utils/validations/validations';
import { styled } from '@/stitches.config';

import { VirtualisedContactsTable } from '../../../shared/components/VirtualisedContactsTable';
import { IndividualCampaignContactsActions } from './CampaignContactsActions';

type CampaignContactsListProps = {
  /** the list that will be rendered */
  campaign_contacts: Array<CampaignContact>;
  /** update contacts offset */
  setOffset: (offset: number) => void;
};

export const CampaignContacts = (props: CampaignContactsListProps) => {
  const { campaign_contacts, setOffset } = props;
  const campaigns = useCampaignsContext();
  const {
    campaignsState,
    getCampaignContacts,
    searchCampaignContacts,
    setLoading,
    currentTab,
    setSelectedContacts,
    setAllSelected,
    changeCurrentTabTotalContacts,
  } = campaigns;
  const {
    current,
    selectedContacts,
    allSelected,
    deliveredContacts,
    notDeliveredContacts,
    respondedContacts,
    unrespondedContacts,
    clickedContacts,
    notClickedContacts,
    unsubscribedContacts,
    unfulfilledContacts,
    isSearched,
    loading,
    currentTabTotalContacts,
  } = campaignsState;

  // channels state
  const location = useChannels();
  const { channelsState } = location;

  // the list of the users channels
  const { channels } = channelsState;
  // the user preferences
  const { preferences } = useUserPreferences();

  const [search, setSearch] = useState('');

  const handleSearch = (value: string) => {
    setSearch(value);

    if (current && current.id) {
      setLoading(true);
      if (value.length > 1) {
        searchCampaignContacts(current?.id, currentTab, value);
      } else {
        getCampaignContacts(current?.id, currentTab, 0, 50);
      }
      setOffset(0);
    }
  };

  useEffect(() => {
    handleSearch('');
    changeCurrentTabTotalContacts(null);
  }, [currentTab]);

  const onAudienceRefresh = () => {
    if (current && current.id) {
      setLoading(true);
      getCampaignContacts(current.id, currentTab, 0, 100);
    }
  };

  // sort by responded_at
  const data = campaign_contacts
    .map((item) => ({
      contact: item.contact,
      response: item.contact_messages[0]?.body || '',
      reason: item.campaign_message?.error || '',
      responded_at: item.contact_messages[0]?.inserted_at || '',
    }))
    .sort((a, b) => {
      if (a?.responded_at > b?.responded_at) {
        return -1;
      }
      if (a?.responded_at < b?.responded_at) {
        return 1;
      }
      return 0;
    });

  // if the contact is already selected then remove it from the selected contacts
  // if the contact is not selected then add it to the selected contacts
  const handleSelect = (checked: CheckedState, contact: Contact) => {
    if (checked) {
      setSelectedContacts([...selectedContacts, contact]);
    } else {
      const arrayWithoutUncheckedItem = selectedContacts.filter(
        (i: Contact) => i.id !== contact.id
      );
      allSelected ? setAllSelected(false) : null;
      setSelectedContacts(arrayWithoutUncheckedItem);
    }
  };

  const handleSelectAll = (e: any) => {
    if (e) {
      switch (currentTab) {
        case CampaignAnalyticsTab.DELIVERED:
          setSelectedContacts(
            deliveredContacts.map(
              (campaignContact: CampaignContact) => campaignContact.contact
            )
          );
          break;
        case CampaignAnalyticsTab.NOT_DELIVERED:
          setSelectedContacts(
            notDeliveredContacts.map(
              (campaignContact: CampaignContact) => campaignContact.contact
            )
          );
          break;
        case CampaignAnalyticsTab.RESPONDED:
          setSelectedContacts(
            respondedContacts.map(
              (campaignContact: CampaignContact) => campaignContact.contact
            )
          );
          break;
        case CampaignAnalyticsTab.UNRESPONDED:
          setSelectedContacts(
            unrespondedContacts.map(
              (campaignContact: CampaignContact) => campaignContact.contact
            )
          );
          break;
        case CampaignAnalyticsTab.CLICKS:
          setSelectedContacts(
            clickedContacts.map(
              (campaignContact: CampaignContact) => campaignContact.contact
            )
          );
          break;
        case CampaignAnalyticsTab.NOT_CLICKED:
          setSelectedContacts(
            notClickedContacts.map(
              (campaignContact: CampaignContact) => campaignContact.contact
            )
          );
          break;
        case CampaignAnalyticsTab.UNSUBSCRIBED:
          setSelectedContacts(
            unsubscribedContacts.map(
              (campaignContact: CampaignContact) => campaignContact.contact
            )
          );
          break;
        case CampaignAnalyticsTab.UNFULFILLED:
          setSelectedContacts(
            unfulfilledContacts.map(
              (campaignContact: CampaignContact) => campaignContact.contact
            )
          );
          break;
        default:
          break;
      }
      setAllSelected(true);
    } else {
      setSelectedContacts([]);
      setAllSelected(false);
    }
  };

  const isLocationIdValid = (id: string) => {
    return isValidUuid(id) && channels.some((location) => location.id === id);
  };

  // if the location id is passed in, use that
  // otherwise use the preferred location id if it is valid
  // else use the first location in the list
  // fallback to empty string
  const getDrawerLocationId = () => {
    if (current?.audience?.location) {
      return current?.audience?.location;
    }
    // Check if there's a preferred location and it's valid
    const preferredLocationId = preferences?.inbox?.preferred_location_id;
    if (preferredLocationId && isLocationIdValid(preferredLocationId)) {
      return preferredLocationId;
    }

    // Fallback to the first location if available
    if (channels.length > 0) {
      return channels[0]?.id || '';
    }

    return '';
  };

  const default_columns = [
    {
      id: 'selection',
      Header: () => (
        <Flex>
          <Checkbox checked={allSelected} onCheckedChange={handleSelectAll} />
        </Flex>
      ),
      Cell: (props: { row: { original: { contact: Contact } } }) => (
        <Flex>
          <Checkbox
            checked={selectedContacts
              .map((contact: Contact) => contact.id)
              .includes(props.row.original.contact.id)}
            onCheckedChange={(e: CheckedState) =>
              handleSelect(e, props.row.original.contact)
            }
          />
        </Flex>
      ),
    },
    {
      Header: 'Name',
      accessor: 'contact.name',
      Cell: (row: { value: string }) => <Cell align="center">{row.value || '-'}</Cell>,
    },
    {
      Header: 'Phone',
      accessor: 'contact.phone',
      Cell: (row: { value: string }) => <Cell>{formatPhoneNumber(row.value)}</Cell>,
    },
    {
      Header: 'Response',
      accessor: 'response',
      colWidth: '100%',
      Cell: (row: { value: string }) => (
        <MessagePreview>{row.value || '-'}</MessagePreview>
      ),
    },
    {
      Header: 'Responded At',
      accessor: 'responded_at',
      Cell: (row: { value: string }) => (
        <Cell>{row.value ? dayjs(row.value).format('ddd, MMM D, h:mm a') : ''}</Cell>
      ),
    },
    {
      Header: '',
      accessor: 'controls',
      Cell: (props: { row: { original: { contact: Contact } } }) => (
        <IndividualCampaignContactsActions
          contact={props.row.original.contact}
          location_id={getDrawerLocationId()}
        />
      ),
    },
  ];

  const failed_columns = [
    {
      id: 'selection',
      Header: () => (
        <Flex>
          <Checkbox checked={allSelected} onCheckedChange={handleSelectAll} />
        </Flex>
      ),
      Cell: (props: { row: { original: { contact: Contact } } }) => (
        <Flex>
          <Checkbox
            checked={selectedContacts
              .map((contact: Contact) => contact.id)
              .includes(props.row.original.contact.id)}
            onCheckedChange={(e: CheckedState) =>
              handleSelect(e, props.row.original.contact)
            }
          />
        </Flex>
      ),
    },
    {
      Header: 'Name',
      accessor: 'contact.name',
      Cell: (row: { value: string }) => <Cell align="center">{row.value || '-'}</Cell>,
    },
    {
      Header: 'Phone',
      accessor: 'contact.phone',
      Cell: (row: { value: string }) => <Cell>{formatPhoneNumber(row.value)}</Cell>,
    },
    {
      Header: 'Reason',
      accessor: 'reason',
      colWidth: '100%',
      Cell: (row: { value: string }) => (
        <MessagePreview css={{ color: 'red' }}>
          <HStack>
            <HiExclamationCircle />
            <Box>{(i18next.t(row.value) as string) || 'Message failed to send'}</Box>
          </HStack>
        </MessagePreview>
      ),
    },
    {
      Header: '',
      accessor: 'controls',
      Cell: (props: { row: { original: { contact: Contact } } }) => (
        <IndividualCampaignContactsActions
          contact={props.row.original.contact}
          location_id={getDrawerLocationId()}
        />
      ),
    },
  ];

  return (
    <Box css={{ minHeight: '100%', width: '100%' }}>
      <SearchInputContainer align="center" justify="center">
        <Flex align="center" justify="center" css={{ m: '0 20px 0 15px' }}>
          <HiSearch style={searchIconStyles as CSSProperties} />
        </Flex>
        <SearchInput
          placeholder="Search Contacts"
          value={search}
          onChange={(e: { target: { value: string } }) => handleSearch(e.target.value)}
        />
        <Box css={{ mr: 20, fontSize: 13, minWidth: 100, textAlign: 'end' }}>
          {allSelected || selectedContacts.length > 0
            ? allSelected
              ? `${currentTabTotalContacts || 0} Selected`
              : `${selectedContacts.length || 0} Selected`
            : `${currentTabTotalContacts || 0} Contacts`}
        </Box>
      </SearchInputContainer>
      {!loading && data.length > 0 && (
        <Box css={{ px: 8 }}>
          <VirtualisedContactsTable
            columns={
              currentTab === CampaignAnalyticsTab.NOT_DELIVERED
                ? failed_columns
                : default_columns
            }
            data={data}
            setOffset={setOffset}
            isSearched={isSearched}
            setSelectedContacts={setSelectedContacts}
            selectedContacts={selectedContacts}
          />
        </Box>
      )}
      {loading && (
        <Box css={{ px: 4 }}>
          {Array.from({ length: 20 }, (_: any, k: React.Key | null | undefined) => (
            <LoadingContactRow key={k} />
          ))}
        </Box>
      )}
      {!loading && data.length === 0 && (
        <Flex
          css={{ height: 'calc(100vh - 245px)', width: '100%' }}
          align="center"
          justify="center"
        >
          <VStack align="center">
            <Text size="3">
              No results yet. You can always refresh to get the latest analytics.
            </Text>
            <Box>
              <Button variant="gray" onClick={() => onAudienceRefresh()}>
                <HiRefresh /> Refresh Analytics
              </Button>
            </Box>
          </VStack>
        </Flex>
      )}
    </Box>
  );
};

const searchIconStyles = {
  position: 'absolute',
  top: '51%',
  transform: 'translateY(-50%)',
  left: '15px',
  pointerEvents: 'none',
  fontSize: '13px',
};

const MessagePreview = styled(Flex, {
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'left',
});

const Cell = styled(Flex, {
  width: 150,
  alignItems: 'center',
  justifyContent: 'center',
  display: '-webkit-box',
  '-webkit-line-clamp': 1,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'left',
  '@xl': {
    width: 200,
  },
});

const SearchInputContainer = styled(Flex, {
  position: 'relative',
  borderBottom: '1px solid #EDEDED',
  height: 50,
});

const SearchInput = styled(Input, {
  borderRadius: 0,
  boxShadow: 'none',
  padding: '22px 15px 22px 22px',
  '&:focus': {
    backgroundColor: '$loContrast',
    boxShadow: 'none',
  },
});
