import { useMemo } from 'react';
import { useMedia } from 'react-use';

import { ReportsCollapsible } from '@/shared/components/reports/ReportsCollapsible';
import { Box, Grid } from '@/shared/ui';

import { AnalyticsTableType } from '../context/types';
import { AnalyticsTable, ItemType } from './AnalyticsTable';
import { DeviceChart } from './DeviceChart';

export const COLORS = [
  { device: 'iOS', color: '#EA5757' },
  { device: 'Android', color: '#7BC2ED' },
  { device: 'desktop', color: '#5653DF' },
  { device: 'other', color: '#F8BE3E' },
];

export type DevicesSectionProps = {
  /** data to display */
  data: any;
  /** is dating loading */
  loading: boolean;
  /** is response failed */
  error: boolean;
  /** retry request */
  errorCallback?: () => void;
};

type itemDataType = ItemType & {
  unique: {
    count: number;
  };
  os?: ItemType[];
};

export const DevicesSection = ({
  data,
  loading,
  error,
  errorCallback,
}: DevicesSectionProps) => {
  const isDesktop = useMedia('(min-width: 1200px)');
  const table_config = [
    {
      header: 'Devices',
      tooltip: 'The countries where user click links',
      key: 'key',
    },
    {
      header: 'Total Clicks',
      tooltip: 'Total Clicks',
      key: 'count',
    },
    {
      header: 'Total Unique Clicks',
      tooltip: 'Total Unique Clicks',
      key: 'unique.count',
    },
  ];

  const tableData = useMemo(() => {
    const arr: any[] = [];
    data?.by_device?.map((item: itemDataType) => {
      if (item.key === 'smartphone') {
        item?.os?.map((osItem: ItemType) => {
          arr.push(osItem);
        });
      } else {
        if (item.key === 'unknown') {
          arr.push({ ...item, key: 'other' });
        } else {
          arr.push(item);
        }
      }
    });
    return arr;
  }, [data?.by_device]);

  return (
    <ReportsCollapsible title="Total Clicks by Device" description="">
      <Grid columns={!error && isDesktop ? '2' : '1'} gap="1">
        <AnalyticsTable
          data={tableData}
          config={table_config}
          sortable
          tableTitle="Total Clicks by Device"
          loading={loading}
          error={error}
          errorCallback={errorCallback}
          type={AnalyticsTableType.DEVICE}
        />
        {!error && (
          <Box css={{ minHeight: tableData.length ? 270 : 0 }}>
            <DeviceChart data={tableData} />
          </Box>
        )}
      </Grid>
    </ReportsCollapsible>
  );
};
