/* eslint-disable react-hooks/exhaustive-deps */
import { useCombobox } from 'downshift';
import { FormikValues, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { HiChevronDown } from 'react-icons/hi';

import { useDisclosure } from '@/shared/hooks';
import {
  Box,
  Checkbox,
  ComboboxItem,
  ComboboxItemText,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  HStack,
  Input,
  ScrollArea,
  ScrollAreaCorner,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport,
} from '@/shared/ui';

import { SelectComboboxProps } from './types';

// If props.condition is set to true, renders the custom component set
// via props.wrapper.custom Otherwise, return the base component set via
// props.wrapper.base
function ConditionalComponent({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  wrapper: {
    base: (children?: JSX.Element) => JSX.Element | null;
    custom: (children?: JSX.Element) => JSX.Element | null;
  };
  children?: JSX.Element;
}): JSX.Element | null {
  return condition ? wrapper.custom(children) : wrapper.base(children);
}

// A select combobox that can be used in conjunction with Formik, must be
// placed within a FormFieldWrapper
export function SelectCombobox(props: SelectComboboxProps): JSX.Element {
  const formik = useFormikContext<FormikValues>();

  const [inputItems, setInputItems] = useState(props.options);

  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const value = _.get(formik.values, props.name as string);
  const [selectItem, setSelectItem] = useState(value || '');

  const [anchorWidth, setAnchorWidth] = useState(0);

  const [placeholder, setPlaceholder] = useState('');

  const { getMenuProps, getInputProps, getItemProps, highlightedIndex } = useCombobox({
    itemToString: (item) => (item ? item.type : ''),
    items: inputItems,
    onSelectedItemChange: ({ selectedItem }) => {
      const index = selectItem.indexOf(selectedItem?.value);
      props.closeOnClick ? onClose() : null;
      if (index >= 0) {
        return setSelectItem('');
      } else {
        return setSelectItem(selectedItem?.value);
      }
    },
    selectedItem: null,
    stateReducer: (state, actionAndChanges) => {
      const { type, changes } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: '',
          };
        default:
          return {
            ...changes,
          };
      }
    },
    onInputValueChange: ({ inputValue }) => {
      if (inputValue) {
        const filteredOptions = props.options.filter((item) =>
          item.type.toLowerCase().startsWith(inputValue.toLowerCase())
        );
        setInputItems(filteredOptions);
      } else {
        setInputItems(props.options);
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue(props.name as string, selectItem);
  }, [props.name, selectItem, formik.setFieldValue]);

  const updateAnchorWidth = () => {
    setAnchorWidth(inputRef.current.offsetWidth);
  };

  useEffect(() => {
    setInputItems(props.options);
  }, [props.options]);

  useEffect(() => {
    if (typeof props.placeholder === 'function') {
      props.placeholder({ formik, props, setPlaceholder });
    } else if (typeof props.placeholder === 'string') {
      setPlaceholder(props.placeholder);
    }
    window.addEventListener('resize', updateAnchorWidth);
    return () => {
      window.removeEventListener('resize', updateAnchorWidth);
    };
  }, [props.placeholder]);

  const handleClick = () => {
    setPlaceholder('');
    if (!isOpen) {
      onOpen();
      updateAnchorWidth();
    }
  };

  const handlePointerDownOutside = (e: Event) => {
    if (e.currentTarget !== inputRef.current) {
      formik.setFieldTouched(props.name as string);
      if (typeof props.placeholder === 'function') {
        props.placeholder({ formik, props, setPlaceholder });
      } else if (typeof props.placeholder === 'string') {
        setPlaceholder(props.placeholder);
      }
      onClose();
    }
  };

  return (
    <ConditionalComponent
      condition={!!props.isDropdown}
      wrapper={{
        base: (children) => (children ? children : null),
        custom: (children) => (
          <DropdownMenu open={isOpen} onOpenChange={() => !isOpen} modal={false}>
            {children}
          </DropdownMenu>
        ),
      }}
    >
      <>
        <Box>
          <ConditionalComponent
            condition={!!props.isDropdown}
            wrapper={{
              base: (children) => (
                <Box
                  css={{
                    position: 'relative',
                  }}
                >
                  {children}
                </Box>
              ),
              custom: (children) => (
                <DropdownMenuTrigger
                  asChild={true}
                  onClick={handleClick}
                  data-testid={`select-btn-${props.name}`}
                >
                  <Box css={{ position: 'relative' }}>
                    <HiChevronDown
                      style={{
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        right: '10px',
                        pointerEvents: 'none',
                        fontSize: '16px',
                      }}
                    />
                    {children}
                  </Box>
                </DropdownMenuTrigger>
              ),
            }}
          >
            <ConditionalComponent
              condition={!!props.FieldComponent}
              wrapper={{
                base: () => (
                  <Input
                    {...getInputProps(
                      {
                        placeholder: placeholder,
                        autoComplete: 'chrome-off',
                        type: 'text',
                        ref: inputRef,
                        name: props.name,
                      },
                      { suppressRefError: true }
                    )}
                    data-intercom-target={props.intercomId}
                    aria-label={props.ariaLabel}
                    variant={props.isDropdown ? 'selectDropdown' : null}
                  />
                ),
                custom: () =>
                  props.FieldComponent
                    ? props.FieldComponent({
                        props,
                        getInputProps,
                        placeholder,
                        inputRef,
                      })
                    : null,
              }}
            />
          </ConditionalComponent>
        </Box>
        <Box {...getMenuProps({}, { suppressRefError: true })}>
          <ConditionalComponent
            condition={!!props.isDropdown}
            wrapper={{
              base: (children) => (children ? children : null),
              custom: (children) => (
                <DropdownMenuContent
                  side="bottom"
                  sideOffset={5}
                  variant="combobox"
                  onPointerDownOutside={(e: Event) => handlePointerDownOutside(e)}
                  onFocus={() => inputRef.current.focus()}
                  css={{ padding: 0 }}
                >
                  {children}
                </DropdownMenuContent>
              ),
            }}
          >
            <ConditionalComponent
              condition={!!props.ScrollAreaComponent}
              wrapper={{
                base: (children) => (
                  <ScrollArea variant="combobox">{children}</ScrollArea>
                ),
                custom: (children) =>
                  props.ScrollAreaComponent
                    ? props.ScrollAreaComponent({
                        children,
                      })
                    : null,
              }}
            >
              <>
                <ScrollAreaViewport variant="combobox">
                  {inputItems.length > 0 ? (
                    inputItems.map((item, index) => (
                      <Box
                        key={`${item.value}${index}`}
                        {...getItemProps({ item, index })}
                        css={{ width: props.isDropdown ? anchorWidth : 'auto' }}
                        data-testid={`combobox-item-${props.name}`}
                      >
                        <ConditionalComponent
                          condition={!!props.OptionComponent}
                          wrapper={{
                            base: () => (
                              <ComboboxItem
                                selected={selectItem === item.value}
                                css={{
                                  borderBottom: !item.value
                                    ? '1px solid $slate7'
                                    : 'auto',
                                }}
                              >
                                <HStack gap={2} css={{ overflow: 'hidden' }}>
                                  <Checkbox
                                    checked={selectItem === item.value}
                                    css={{ pointerEvents: 'none' }}
                                  />

                                  <ComboboxItemText>{item.type}</ComboboxItemText>
                                </HStack>
                              </ComboboxItem>
                            ),
                            custom: () =>
                              props.OptionComponent
                                ? props.OptionComponent({
                                    selected: highlightedIndex === index,
                                    item,
                                    checked: selectItem === item.value,
                                  })
                                : null,
                          }}
                        />
                      </Box>
                    ))
                  ) : (
                    <Box css={{ width: props.isDropdown ? anchorWidth : 'auto' }}>
                      <ConditionalComponent
                        condition={!!props.OptionNullComponent}
                        wrapper={{
                          base: () => (
                            <ComboboxItem
                              disabled="true"
                              justify="center"
                              align="center"
                              css={{ pointerEvent: 'none' }}
                            >
                              <ComboboxItemText>No Results Found</ComboboxItemText>
                            </ComboboxItem>
                          ),
                          custom: () =>
                            props.OptionNullComponent
                              ? props.OptionNullComponent()
                              : null,
                        }}
                      />
                    </Box>
                  )}
                </ScrollAreaViewport>
                <ScrollAreaScrollbar orientation="vertical" variant="combobox">
                  <ScrollAreaThumb />
                </ScrollAreaScrollbar>
                <ScrollAreaCorner />
              </>
            </ConditionalComponent>
          </ConditionalComponent>
        </Box>
      </>
    </ConditionalComponent>
  );
}
