import React, { createContext, useContext, useReducer } from 'react';
import { toast } from 'sonner';

import {
  createBrand,
  createCampaign,
  CreateCampaignParams,
  createProviderBrand,
  CreateProviderBrands,
  getBrandById,
  getBrands,
  getCampaignById,
  getCampaigns,
} from '@/shared/api/tendlc';
import { BrandsTypes, CreateBrandType } from '@/shared/types/trust/brands';
import { CampaignData } from '@/shared/types/trust/campaigns';
import i18next from '@/shared/utils/translation';

import TenDlcReducer from './TenDlcReducer';
import {
  CREATE_BRAND,
  CREATE_CAMPAIGN,
  CREATE_PROVIDER_BRAND,
  GET_BRAND,
  GET_BRANDS,
  GET_CAMPAIGN,
  GET_CAMPAIGNS,
  SET_LOADING,
} from './types';

export type TenDlcState = {
  brands: Array<BrandsTypes>;
  campaigns: Array<CampaignData>;
  loading: boolean;
  current: any | null | undefined;
  error: any;
  brand: BrandsTypes | null;
  campaign: CampaignData | null;
};

export const initialTenDlcState: TenDlcState = {
  brands: [],
  campaigns: [],
  current: null,
  error: null,
  loading: true,
  brand: null,
  campaign: null,
};

export const TenDlcContext = createContext<{
  tenDlcState: TenDlcState;
  getAllBrands: () => void;
  getAllCampaigns: () => void;
  createOneBrand: (params: CreateBrandType) => void;
  createOneCampaign: (brandId: string, params: CreateCampaignParams) => void;
  createProvideBrand: (params: CreateProviderBrands) => void;
  getBrand: (brandId: string) => void;
  getCampaign: (brandId: string, campaignId: string) => void;
}>({
  tenDlcState: initialTenDlcState,
  getAllBrands: () => Promise.resolve(),
  getAllCampaigns: () => Promise.resolve(),
  createOneBrand: () => Promise.resolve(),
  createOneCampaign: () => Promise.resolve(),
  createProvideBrand: () => Promise.resolve(),
  getBrand: () => Promise.resolve(),
  getCampaign: () => Promise.resolve(),
});

export const PAGE_SIZE = 100;

export const useTenDlc = () => useContext(TenDlcContext);

const TenDlcState = ({ children }: { children: React.ReactNode }) => {
  const [tenDlcState, dispatch] = useReducer(TenDlcReducer, initialTenDlcState);

  const getAllBrands = async () => {
    try {
      dispatch({
        type: SET_LOADING,
        payload: true,
      });
      const data = await getBrands();
      dispatch({
        type: GET_BRANDS,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getAllCampaigns = async () => {
    try {
      dispatch({
        type: SET_LOADING,
        payload: true,
      });
      const data = await getCampaigns();

      dispatch({
        type: GET_CAMPAIGNS,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const createOneBrand = async (params: CreateBrandType) => {
    try {
      const data = await createBrand(params);

      dispatch({
        type: CREATE_BRAND,
        payload: data,
      });

      toast.success(i18next.t('brand_created_success') as string);
    } catch (err) {
      toast.error(i18next.t('brand_created_failure') as string);
      console.error(err);
    }
  };

  const createProvideBrand = async (params: CreateProviderBrands) => {
    try {
      const data = await createProviderBrand(params);

      dispatch({
        type: CREATE_PROVIDER_BRAND,
        payload: data,
      });

      toast.success(i18next.t('a_provider_brand_created_success') as string);
    } catch (err) {
      toast.error(i18next.t('a_provider_brand_created_failure') as string);
      console.error(err);
    }
  };

  const createOneCampaign = async (brandId: string, params: CreateCampaignParams) => {
    try {
      const data = await createCampaign(brandId, params);

      dispatch({
        type: CREATE_CAMPAIGN,
        payload: data,
      });

      toast.success(i18next.t('tendlc_campaign_created_success') as string);
    } catch (err) {
      toast.error(i18next.t('tendlc_campaign_created_failure') as string);
      console.error(err);
    }
  };

  const getBrand = async (brandId: string) => {
    try {
      const data = await getBrandById(brandId);
      dispatch({
        type: GET_BRAND,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getCampaign = async (brandId: string, campaignId: string) => {
    try {
      const data = await getCampaignById(brandId, campaignId);
      dispatch({
        type: GET_CAMPAIGN,
        payload: data,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <TenDlcContext.Provider
      value={{
        tenDlcState,
        getAllBrands,
        getAllCampaigns,
        createOneBrand,
        createOneCampaign,
        createProvideBrand,
        getBrand,
        getCampaign,
      }}
    >
      {children}
    </TenDlcContext.Provider>
  );
};

export default TenDlcState;
