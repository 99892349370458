import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  HiCog,
  HiDotsHorizontal,
  HiMicrophone,
  HiPhone,
  HiUserAdd,
} from 'react-icons/hi';
import { HiUserMinus } from 'react-icons/hi2';

import {
  KeypadIcon,
  MutedMicIcon,
  PauseCallIcon,
  RecordIcon,
  TransferCallIcon,
} from '@/shared/components/Icons';
import { Channel, ChannelTypes } from '@/shared/types/channels';
import { User } from '@/shared/types/users';
import { CallType, Participant } from '@/shared/types/voip';
import {
  Avatar,
  Box,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Flex,
  IconButton,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@/shared/ui';
import { initials } from '@/shared/utils/initials/initials';
import { getAuthTokens } from '@/shared/utils/storage';
import { formatPhoneNumber } from '@/shared/utils/validations/validations';
import { keyframes, styled } from '@/stitches.config';

import { showContactIcon } from '../inbox/list/ConversationPreview';
import { useChannels } from '../settings/organization/channels/context/ChannelContext';
import { useUsers } from '../settings/organization/users/context/UserContext';
import { useVoIP } from './context/VoIPContext';
import { Timer } from './Timer';
import { CallHeader, VoIPDialog } from './VoIPDialog';
import { VoIPKeypad } from './VoIPKeypad';
import { VoIPParticipants } from './VoIPParticipants';
import { VoIPSettings } from './VoIPSettings';

type VoIPCallProps = {
  data?: CallType | null;
};

export const VoIPCall = ({ data }: VoIPCallProps) => {
  const {
    hangUp,
    startCallRecord,
    stopCallRecord,
    deleteParticipantFromCall,
    holdParticipant,
  } = useVoIP();
  console.log('data call', data);

  const {
    channelsState: { allChannels },
  } = useChannels();

  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [showKeypad, setShowKeypad] = useState<boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [addPeoplePanel, setAddPeoplePanel] = useState<boolean>(false);

  const tokens = getAuthTokens();

  const currentChannel = useMemo(() => {
    if (data?.channelPhone) {
      return allChannels?.find((c: Channel) => c.phone === data?.channelPhone);
    }
    return allChannels?.filter(
      (c: Channel) => c.type === ChannelTypes.PHONE && !!c.provider_account_id
    )?.[0];
  }, [allChannels, data?.channelPhone]);

  const muteCall = () => {
    data?.call?.mute(!isMuted);
    setIsMuted(!isMuted);
  };

  const openKeypad = () => {
    setShowSettings(false);
    setAddPeoplePanel(false);
    setShowKeypad(!showKeypad);
  };

  const openSettings = () => {
    setShowKeypad(false);
    setAddPeoplePanel(false);
    setShowSettings(!showSettings);
  };

  const handleStartRecording = useCallback(async () => {
    if (data?.call?.parameters?.CallSid) {
      await startCallRecord(data?.call?.parameters?.CallSid);
    }
  }, [data?.call?.parameters?.CallSid]);

  const handleStopRecording = useCallback(async () => {
    if (data?.call?.parameters?.CallSid && data?.recording_id) {
      await stopCallRecord(data?.call?.parameters?.CallSid, data?.recording_id);
    }
  }, [data?.call?.parameters?.CallSid, data?.recording_id]);

  useEffect(() => {
    if (data?.call?.parameters?.CallSid) {
      startCallRecord(data?.call?.parameters?.CallSid, true);
    }
  }, [data?.call?.parameters?.CallSid]);

  const handleAddPeoplePanelOpen = useCallback(() => {
    setShowSettings(false);
    setShowKeypad(false);
    setAddPeoplePanel(true);
  }, []);

  const handleAddPeoplePanelClose = useCallback(() => {
    setAddPeoplePanel(false);
  }, []);

  const handleDeleteParticipant = useCallback(
    async (phone?: string, userId?: number) => {
      data?.call?.parameters?.CallSid &&
        (await deleteParticipantFromCall(data?.call?.parameters?.CallSid, phone, userId));
    },
    [data?.call?.parameters?.CallSid]
  );

  const handleHoldParticipant = useCallback(
    async (hold: boolean, phone?: string, userId?: number) => {
      data?.call?.parameters?.CallSid &&
        (await holdParticipant(data?.call?.parameters?.CallSid, hold, phone, userId));
    },
    [data?.call?.parameters?.CallSid]
  );

  return (
    <VoIPDialog
      resizable={!showKeypad && !showSettings && !addPeoplePanel}
      defaultSize={{ width: 280, height: 275 }}
      defaultStyles={{
        maxWidth: 420,
        maxHeight: 360,
        minWidth: 270,
        minHeight: 190,
      }}
      header={
        <CallHeader justify="between">
          <Flex align="center" justify="between" css={{ width: '100%' }}>
            <Flex>
              <Box css={{ mr: 8 }}>{tokens?.name || tokens?.email}</Box>
              <Timer start={new Date()} />
            </Flex>
            {!addPeoplePanel ? (
              <>
                {currentChannel?.settings?.call?.conferences ? (
                  <TooltipButton text="Add people">
                    <IconButton
                      variant="darkGray"
                      css={{ background: 'transparent' }}
                      onClick={handleAddPeoplePanelOpen}
                    >
                      <HiUserAdd />
                    </IconButton>
                  </TooltipButton>
                ) : (
                  <TooltipButton text="Conference feature is not available">
                    <IconButton variant="darkGray" css={{ background: 'transparent' }}>
                      <HiUserAdd />
                    </IconButton>
                  </TooltipButton>
                )}
              </>
            ) : (
              <Box css={{ width: 32, height: 32 }} />
            )}
          </Flex>
        </CallHeader>
      }
      sidePanel={
        showKeypad ? (
          <VoIPKeypad call={data?.call} />
        ) : showSettings ? (
          <VoIPSettings />
        ) : addPeoplePanel ? (
          <VoIPParticipants
            data={data}
            currentChannel={currentChannel}
            onClose={handleAddPeoplePanelClose}
          />
        ) : null
      }
    >
      <Box
        data-testid="voip-call"
        css={{ width: '100%', height: '100%', py: 8, fontSize: 14, color: 'white' }}
      >
        <Box css={{ overflowY: 'scroll', height: 'calc(100% - 40px)' }}>
          <Box>
            <Flex align="center" css={{ px: 12, py: 8 }}>
              <Box style={{ position: 'relative' }}>
                <Avatar
                  data-testid="current-user-avatar"
                  size="2"
                  variant="lightGray"
                  src={tokens?.attachment?.url}
                  fallback={initials(tokens?.name || tokens?.email)}
                />
                <Box
                  style={{
                    position: 'absolute',
                    bottom: -2,
                    right: 0,
                    width: 8,
                    height: 8,
                    borderRadius: 8,
                    border: '2px solid #1B1B1F',
                    backgroundColor: '#30A46C',
                  }}
                />
              </Box>
              <Box data-testid="current-user-name" css={{ ml: 8 }}>
                {tokens?.name || tokens?.email}
              </Box>
              <Box css={{ color: '#ADB1B8', ml: 8 }}>You</Box>
            </Flex>
            {data?.participants?.map((p: Participant) => (
              <ParticipantItem
                key={p.phone}
                {...p}
                onDelete={handleDeleteParticipant}
                onHold={handleHoldParticipant}
              />
            ))}
            {isMuted && (
              <Flex
                align="center"
                css={{
                  color: '#FF8589',
                  fontSize: 12,
                  p: 12,
                  pb: data?.recording ? 0 : 12,
                }}
              >
                <HiMicrophone />
                <Box css={{ ml: 4 }}>Microphone muted</Box>
              </Flex>
            )}
            {data?.recording && (
              <Flex align="center" css={{ color: '#FF8589', fontSize: 12, p: 12 }}>
                <RecordingIcon size={12} />
                <Flex css={{ ml: 4 }}>
                  Recording (<Timer start={new Date()} />)
                </Flex>
              </Flex>
            )}
          </Box>
        </Box>
        <Flex gap="2" justify="between" css={{ px: 12, py: 4, fontSize: 16 }}>
          <IconButton data-testid="mute-call-btn" variant="green" onClick={muteCall}>
            {isMuted ? <MutedMicIcon fill="#fff" /> : <HiMicrophone />}
          </IconButton>
          <Flex gap="2">
            {data?.recording ? (
              <TooltipButton text="Stop call recording">
                <IconButton
                  data-testid="record-stop-call-btn"
                  variant="darkGray"
                  onClick={handleStopRecording}
                >
                  <Box>
                    <AnimatedCircle />
                    <Box css={{ color: '#E5484D', marginTop: 4, fontSize: 11 }}>Rec</Box>
                  </Box>
                </IconButton>
              </TooltipButton>
            ) : (
              <TooltipButton text="Record call">
                <IconButton
                  disabled={!data?.call?.parameters?.CallSid}
                  data-testid="record-start-call-btn"
                  variant="darkGray"
                  onClick={handleStartRecording}
                  css={{
                    '&:hover': {
                      '& > div > div:first-child': {
                        background: '#FF263C5A',
                      },
                      '& > div > div:last-child': {
                        background: '#E5484D',
                      },
                    },
                  }}
                >
                  <RecordingIcon color="#ADB1B8" />
                </IconButton>
              </TooltipButton>
            )}
            <IconButton data-testid="keypad-btn" variant="darkGray" onClick={openKeypad}>
              <KeypadIcon fill="#ADB1B8" />
            </IconButton>
            <ComingSoonTooltip>
              <IconButton data-testid="pause-call-btn" variant="darkGray">
                <PauseCallIcon fill="#ADB1B8" />
              </IconButton>
            </ComingSoonTooltip>
            <Flex align="center" direction="column" css={{ width: '100%' }}>
              <DropdownMenu modal={false}>
                <DropdownMenuTrigger asChild>
                  <IconButton
                    data-testid="actions-trigger"
                    variant="darkGray"
                    css={{ width: 32, height: 32, fontSize: 12 }}
                  >
                    <HiDotsHorizontal />
                  </IconButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  hideWhenDetached
                  css={{
                    padding: 8,
                    width: 200,
                    minWidth: 150,
                    boxShadow: '0px 12px 60px 0px #0000000E',
                    background: '#1B1B1F',
                    border: '1px solid #BDC8FF17',
                  }}
                  align="start"
                  side="left"
                  sideOffset={5}
                >
                  <ComingSoonTooltip>
                    <DropdownMenuItem
                      css={{ color: '#ADB1B8', fontSize: 16 }}
                      data-testid="put-call-on-hold-option"
                    >
                      <>
                        <PauseCallIcon fill="#ADB1B8" />
                        <Box style={{ marginLeft: 12, color: '#fff', fontSize: 14 }}>
                          Put call on hold
                        </Box>
                      </>
                    </DropdownMenuItem>
                  </ComingSoonTooltip>
                  {currentChannel?.settings?.call?.conferences && (
                    <DropdownMenuItem
                      css={{ color: '#ADB1B8', fontSize: 16 }}
                      data-testid="add-people-option"
                      onClick={handleAddPeoplePanelOpen}
                    >
                      <HiUserAdd />
                      <Box style={{ marginLeft: 12, color: '#fff', fontSize: 14 }}>
                        Add people
                      </Box>
                    </DropdownMenuItem>
                  )}
                  <ComingSoonTooltip>
                    <DropdownMenuItem
                      css={{ color: '#ADB1B8', fontSize: 16 }}
                      data-testid="transfer-call-option"
                    >
                      <TransferCallIcon fill="#ADB1B8" />
                      <Box style={{ marginLeft: 12, color: '#fff', fontSize: 14 }}>
                        Transfer call
                      </Box>
                    </DropdownMenuItem>
                  </ComingSoonTooltip>
                  <hr style={{ color: '#D2DFFF28', margin: 8 }} />
                  <DropdownMenuItem
                    css={{ color: '#ADB1B8', fontSize: 16 }}
                    data-testid="mute-option"
                  >
                    <MutedMicIcon fill="#ADB1B8" />
                    <Box style={{ marginLeft: 12, color: '#fff', fontSize: 14 }}>
                      {isMuted ? 'Unmute mic' : 'Mute mic'}
                    </Box>
                  </DropdownMenuItem>
                  {data?.call?.parameters?.CallSid && (
                    <>
                      {data?.recording ? (
                        <DropdownMenuItem
                          css={{ color: '#ADB1B8', fontSize: 16 }}
                          data-testid="stop-recording-option"
                          onClick={handleStopRecording}
                        >
                          <RecordIcon fill="#ADB1B8" />
                          <Box style={{ marginLeft: 12, color: '#fff', fontSize: 14 }}>
                            Stop recording
                          </Box>
                        </DropdownMenuItem>
                      ) : (
                        <DropdownMenuItem
                          css={{ color: '#ADB1B8', fontSize: 16 }}
                          data-testid="start-recording-option"
                          onClick={handleStartRecording}
                        >
                          <RecordIcon fill="#ADB1B8" />
                          <Box style={{ marginLeft: 12, color: '#fff', fontSize: 14 }}>
                            Record call
                          </Box>
                        </DropdownMenuItem>
                      )}
                    </>
                  )}
                  <DropdownMenuItem
                    css={{ color: '#ADB1B8', fontSize: 16 }}
                    data-testid="keypad-option"
                    onClick={openKeypad}
                  >
                    <KeypadIcon fill="#ADB1B8" />
                    <Box style={{ marginLeft: 12, color: '#fff', fontSize: 14 }}>
                      Show keypad
                    </Box>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    onClick={openSettings}
                    css={{ color: '#ADB1B8', fontSize: 16 }}
                    data-testid="settings-option"
                  >
                    <HiCog />
                    <Box style={{ marginLeft: 12, color: '#fff', fontSize: 14 }}>
                      Audio settings
                    </Box>
                  </DropdownMenuItem>
                  <hr style={{ color: '#D2DFFF28', margin: 8 }} />
                  <DropdownMenuItem
                    onClick={() => data?.callSid && hangUp(data?.callSid)}
                    css={{ color: '#FF858A', fontSize: 16 }}
                    data-testid="end-call-option"
                  >
                    <HiPhone style={{ transform: 'rotate(135deg)', margin: 0 }} />
                    <Box style={{ marginLeft: 12, fontSize: 14 }}>End call</Box>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </Flex>
          </Flex>
          <IconButton
            data-testid="end-call-btn"
            variant="red"
            onClick={() => data?.callSid && hangUp(data?.callSid)}
          >
            <HiPhone style={{ transform: 'rotate(135deg)', margin: 0 }} />
          </IconButton>
        </Flex>
      </Box>
    </VoIPDialog>
  );
};

export const pulse = keyframes({
  '0%': {
    transform: 'scale(0)',
  },
  '70%': {
    transform: 'scale(1)',
  },
  '100%': {
    transform: 'scale(0)',
  },
});

const AnimatedCircle = styled(Box, {
  width: 8,
  height: 8,
  borderRadius: 4,
  background: '#E5484D',
  animation: `${pulse} 2s infinite`,
});

const ComingSoonTooltip = ({ children }: { children: React.ReactNode }) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent side="bottom">
        Coming Soon
        <TooltipArrow />
      </TooltipContent>
    </Tooltip>
  );
};

const TooltipButton = ({
  children,
  text,
  side,
}: {
  children: React.ReactNode;
  text: string;
  side?: 'bottom' | 'left' | 'right' | 'top';
}) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent side={side || 'bottom'}>
        {text}
        <TooltipArrow />
      </TooltipContent>
    </Tooltip>
  );
};

const RecordingIcon = ({ color, size = 16 }: { color?: string; size?: number }) => (
  <Box
    css={{
      position: 'relative',
    }}
  >
    <Box
      css={{
        width: size,
        height: size,
        borderRadius: size / 2,
        background: `${color || '#FF263C'}5A`,
      }}
    ></Box>
    <Box
      css={{
        width: size / 2,
        height: size / 2,
        borderRadius: size / 4,
        background: color || '#E5484D',
        position: 'absolute',
        top: size / 4,
        left: size / 4,
      }}
    ></Box>
  </Box>
);

export const ParticipantItem = ({
  phone,
  name,
  userId,
  isMute,
  isOnHold,
  onDelete,
  onHold,
  onMute,
}: Participant) => {
  const {
    userState: { users },
  } = useUsers();

  const handleDelete = useCallback(async () => {
    await onDelete?.(phone, userId);
  }, [phone, userId]);

  const handleHold = useCallback(async () => {
    await onHold?.(!isOnHold, phone, userId);
  }, [phone, userId, isOnHold]);

  const handleMute = useCallback(async () => {
    await onMute?.(!isMute, phone, userId);
  }, [phone, userId, isMute]);

  const userObj = useMemo(() => {
    if (userId) {
      return users?.filter((user: User) => user.id === userId)?.[0];
    }
    if (phone?.startsWith('client')) {
      const array = phone?.split('_');
      const id = array?.[array?.length - 1];
      return users?.filter((user: User) => user.id === Number(id))?.[0];
    }
    return undefined;
  }, [userId, users]);

  return (
    <Flex align="center" css={{ px: 12, py: 8 }} justify="between">
      <Flex align="center">
        <Box style={{ position: 'relative' }}>
          {userObj ? (
            <Avatar
              data-testid="user-avatar"
              size="2"
              variant="lightGray"
              src={userObj?.attachment?.url}
              alt={userObj?.name || userObj?.email || 'No name'}
              fallback={initials(userObj?.name || userObj?.email || '')}
              css={isOnHold ? { opacity: 0.5 } : {}}
            />
          ) : (
            <Avatar
              data-testid="user-avatar"
              size="2"
              variant="lightGray"
              src={
                showContactIcon(name || '') ? `${window.location.origin}/outline.svg` : ''
              }
              alt={name || 'No name'}
              fallback={initials(name || '')}
              css={isOnHold ? { opacity: 0.5 } : {}}
            />
          )}
          <Box
            style={{
              position: 'absolute',
              bottom: -2,
              right: 0,
              width: 8,
              height: 8,
              borderRadius: 8,
              border: '2px solid #1B1B1F',
              backgroundColor: '#30A46C',
            }}
          />
        </Box>
        <Box
          data-testid="user-phone"
          css={{ ml: 8, color: isOnHold ? '#ADB1B8' : '#fff' }}
        >
          {(userObj
            ? userObj?.name || userObj?.email
            : name || formatPhoneNumber(phone || '')) || '-'}
        </Box>
        {isOnHold && (
          <Box css={{ ml: 8 }}>
            <PauseCallIcon fill="#99A2FF" />
          </Box>
        )}
      </Flex>
      <DropdownMenu>
        <TooltipButton text="Actions" side="top">
          <DropdownMenuTrigger asChild>
            <IconButton
              data-testid="participant-actions-trigger"
              variant="darkGray"
              css={{
                width: 24,
                height: 24,
                fontSize: 16,
                backgroundColor: 'transparent',
                '&:focus': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <HiDotsHorizontal />
            </IconButton>
          </DropdownMenuTrigger>
        </TooltipButton>
        <DropdownMenuContent
          hideWhenDetached
          css={{
            padding: 8,
            width: 200,
            minWidth: 150,
            boxShadow: '0px 12px 60px 0px #0000000E',
            background: '#1B1B1F',
            border: '1px solid #BDC8FF17',
          }}
          align="start"
          side="right"
          sideOffset={5}
        >
          <ComingSoonTooltip>
            <DropdownMenuItem css={{ px: 8 }} onClick={handleMute}>
              <Flex align="center" justify="center" css={{ width: 16 }}>
                <MutedMicIcon fill="#ADB1B8" />
              </Flex>
              <Box style={{ marginLeft: 12, color: '#fff', fontSize: 14 }}>
                {isMute ? 'Unmute' : 'Mute'}
              </Box>
            </DropdownMenuItem>
          </ComingSoonTooltip>
          <DropdownMenuItem css={{ px: 8 }} onClick={handleHold}>
            <Flex align="center" justify="center" css={{ width: 16 }}>
              <PauseCallIcon fill="#ADB1B8" />
            </Flex>
            <Box style={{ marginLeft: 12, color: '#fff', fontSize: 14 }}>
              {isOnHold ? 'Take off hold' : 'Put call on hold'}
            </Box>
          </DropdownMenuItem>
          <DropdownMenuItem css={{ px: 8 }} onClick={handleDelete}>
            <Flex align="center" justify="center" css={{ width: 16 }}>
              <HiUserMinus fill="#FF8589" size={16} />
            </Flex>
            <Box style={{ marginLeft: 12, color: '#FF858A', fontSize: 14 }}>
              Remove from the call
            </Box>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </Flex>
  );
};
