import React, { ReactNode, useState } from 'react';

import { Button, Flex, Text } from '@/shared/ui';
import { styled } from '@/stitches.config';

const fullText =
  'Provide key details to set up your new 10DLC campaign. Ensure all required fields are filled out.';

const truncateText = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength) + '...';
};

interface GuideProps {
  text?: string;
  icon?: ReactNode;
}

export const Guide = ({ text, icon }: GuideProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleGuide = () => setIsExpanded((prev) => !prev);

  const truncatedText = truncateText(text ?? fullText, 57);
  return (
    <Container>
      <Flex direction="row" align="baseline">
        <Icon>{icon ?? '💡'}</Icon>
        <Text
          size="2"
          variant="blue"
          css={{ fontWeight: 500, color: '$accentColor', fs: 14 }}
        >
          {isExpanded ? (text ?? fullText) : truncatedText}
        </Text>
      </Flex>
      <Button
        onClick={toggleGuide}
        css={{
          backgroundColor: 'rgba(1, 68, 255, 0.06)',
          color: 'rgba(0, 37, 158, 0.8)',
          border: 'none',
          boxShadow: 'none',
          fontWeight: 500,
        }}
      >
        {isExpanded ? 'Close Guide' : 'Open Guide'}
      </Button>
    </Container>
  );
};

const Container = styled('div', {
  padding: '16px',
  backgroundColor: '#F0F4FF',
  borderRadius: '$3',
  display: 'flex',
  marginBottom: '24px',
  justifyContent: 'space-between',
});

const Icon = styled('span', { marginRight: '8px', fontSize: '18px' });
