/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HiDotsHorizontal, HiDuplicate, HiEye, HiEyeOff } from 'react-icons/hi';
import { toast } from 'sonner';

import DeleteConfirmationDialog from '@/pages/developer/keys/DeleteConfirmationDialog';
import DisableConfirmationDialog from '@/pages/developer/keys/DisableConfirmationDialog';
import RenameConfirmationDialog from '@/pages/developer/keys/RenameConfirmationDialog';
import { CreatedByUser } from '@/pages/domains/list/CreatedByUser';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { areFiltersValid } from '@/shared/components/filterBuilder/utils/areValidFilters';
import { cleanFilters } from '@/shared/components/filterBuilder/utils/cleanFilters';
import { ApiKey } from '@/shared/types/developers';
import { FilterType, Sort } from '@/shared/types/filter';
import {
  Box,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuItemWarning,
  DropdownMenuTrigger,
  Flex,
  HStack,
  IconButton,
  Text,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  VStack,
} from '@/shared/ui';
import i18next from '@/shared/utils/translation';
import { Table, TableColumn } from '@/shared/v2/components/table/Table';

import { AddApiKeyErrorBoundaries } from '../boundaries/AddApiKeyErrorBoundaries';
import { useDeveloperContext } from '../context/DevelopersContext';
import { AddApiKey } from './AddApiKey';
import { created_by, default_api_key_object, updated_by } from './config/filterConfig';
import { sortConfig } from './config/sortConfig';

export const ApiKeysList = () => {
  const [quickSearchValue, setQuickSearchValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);
  const [activeSort, setActiveSort] = useState<Sort[]>([
    {
      label: 'Updated At',
      column: 'updated_at',
      resource: 'api_key',
      order: 'desc',
      id: 1,
    },
  ]);
  const limit = 10;

  const updateFilters = useCallback((filter: Array<FilterType>) => {
    setActiveFilters(filter);
  }, []);

  useEffect(() => {
    searchApiKeys(cleanFilters(activeFilters), [], limit, offset);
  }, []);

  const star = '*';
  const apiKeysContext = useDeveloperContext();
  const {
    developerState,
    searchApiKeys,
    enableApiKey,
    disableApiKey,
    deleteApiKey,
    renameApiKey,
  } = apiKeysContext;
  const { apiKeys, total, loading } = developerState;

  // Use an object for the showKeys state
  const [showKeys, setShowKeys] = useState<Record<string, boolean>>({});

  const showKeysRef = useRef<Record<string, boolean>>({});

  // state variables for confirmation dialogs
  const [isRenameConfirmationDialogOpen, setIsRenameConfirmationDialogOpen] =
    useState(false);
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
    useState(false);
  const [isDisableConfirmationDialogOpen, setIsDisableConfirmationDialogOpen] =
    useState(false);

  const [currentApiKeyId, setCurrentApiKeyId] = useState<string | null>(null);
  const [currentApiKeyName, setCurrentApiKeyName] = useState<string | null>(null);
  const [currentApiKeyStatus, setCurrentApiKeyStatus] = useState<boolean | null>(null);

  // offset is the number of keywords to skip
  const [offset, setOffset] = useState(0);

  const data = useMemo(() => (loading ? Array(5).fill({}) : apiKeys), [loading, apiKeys]);

  // Merge quick filters with regular filters
  const mergeQuickFilter = useCallback(
    (filter: Array<FilterType>, quickValue: string) => {
      const quickFilter = quickValue
        ? {
            resource: 'api_key',
            column: 'name',
            comparison: 'ilike',
            value: `%${quickValue}%`,
          }
        : null;

      return quickFilter ? [...filter, quickFilter] : [...filter];
    },
    []
  );

  // Debounced function using useCallback
  const debouncedApiKeys = useCallback(
    debounce(async (filter: Array<FilterType>, quickValue: string) => {
      await searchApiKeys(
        mergeQuickFilter(cleanFilters(filter), quickValue),
        activeSort,
        limit,
        offset
      );
    }, 500),
    // Dependencies that affect the debounced function
    [searchApiKeys, mergeQuickFilter, activeSort, limit, offset]
  );

  // Toggle individual key visibility
  const toggleKeyVisibility = (id: string) => {
    showKeysRef.current[id] = !showKeysRef.current[id];
    setShowKeys({ ...showKeysRef.current });
  };

  const handleRenameButtonClick = (currentApiKeyId: string, name: string) => {
    setCurrentApiKeyId(currentApiKeyId);
    setCurrentApiKeyName(name);
    setIsRenameConfirmationDialogOpen(true);
  };

  const handleDisableButtonClick = (id: string, currentStatus: boolean) => {
    setCurrentApiKeyId(id);
    setCurrentApiKeyStatus(currentStatus);
    setIsDisableConfirmationDialogOpen(true);
  };

  const handleDeleteButtonClick = (currentApiKeyId: string) => {
    setCurrentApiKeyId(currentApiKeyId);
    setIsDeleteConfirmationDialogOpen(true);
  };

  const handleFilterChange = useCallback(
    (filter: Array<FilterType>) => {
      updateFilters(filter);
      if (areFiltersValid(cleanFilters(filter))) {
        debouncedApiKeys(filter, quickSearchValue);
      }
    },
    [updateFilters, quickSearchValue, debouncedApiKeys]
  );

  const handleSortChange = useCallback(
    (sort: Array<Sort>) => {
      setActiveSort(sort);
      searchApiKeys(
        mergeQuickFilter(cleanFilters(activeFilters), quickSearchValue),
        sort,
        limit,
        0
      );
    },
    [activeFilters, quickSearchValue, limit, searchApiKeys, mergeQuickFilter]
  );

  const handleQuickSearchChange = useCallback(
    (value: string) => {
      setQuickSearchValue(value);
      searchApiKeys(
        mergeQuickFilter(cleanFilters(activeFilters), value),
        activeSort,
        limit,
        0
      );
    },
    [activeSort, activeFilters, limit, searchApiKeys, mergeQuickFilter]
  );

  const columns: Array<TableColumn<ApiKey>> = useMemo(
    () => [
      {
        Header: 'Name',
        colWidth: '30%',
        accessor: 'name',
        Cell: (props: { row: { original: ApiKey } }) => (
          <HStack gap="2">
            <Text>{props.row.original?.name || 'No API Key Name'}</Text>
          </HStack>
        ),
      },
      {
        Header: 'API Key',
        colWidth: '50%',
        disableSortBy: true,
        accessor: 'api_key',
        id: 'api_key',
        Cell: (props: { row: { original: ApiKey } }) => (
          <HStack gap="2">
            {/* For api key string */}
            <Text>
              {props.row.original.id && showKeysRef.current[props.row.original.id]
                ? props.row.original.key
                : `${star.repeat(
                    props.row.original.key ? props.row.original.key?.length * 1.25 : 10
                  )}`}
            </Text>
            {/* For hide / show key */}
            <IconButton
              onClick={() => {
                if (props.row.original.id) {
                  toggleKeyVisibility(props.row.original.id);
                }
              }}
            >
              {props.row.original.id && showKeysRef.current[props.row.original.id] ? (
                <Tooltip>
                  <TooltipTrigger>
                    <HiEyeOff />
                  </TooltipTrigger>
                  <TooltipContent css={{ marginBottom: 12 }}>Hide API Key</TooltipContent>
                </Tooltip>
              ) : (
                <Tooltip>
                  <TooltipTrigger>
                    <HiEye />
                  </TooltipTrigger>
                  <TooltipContent css={{ marginBottom: 12 }}>Show API Key</TooltipContent>
                </Tooltip>
              )}
            </IconButton>
            {/* For copy key */}
            <Tooltip>
              <TooltipTrigger>
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(props.row.original.key || '');
                    toast.success(i18next.t('api_key_copied') as string);
                  }}
                >
                  <HiDuplicate />
                </IconButton>
              </TooltipTrigger>
              <TooltipContent css={{ marginBottom: 8 }}>Copy API Key</TooltipContent>
            </Tooltip>
          </HStack>
        ),
      },
      {
        Header: 'Created by',
        colWidth: '5%',
        accessor: 'created',
        Cell: (props: { row: { original: ApiKey } }) => (
          <CreatedByUser isTable userId={props.row.original?.created_by?.id || 0} />
        ),
      },
      {
        Header: 'Updated by',
        colWidth: '5%',
        accessor: 'updated',
        Cell: (props: { row: { original: ApiKey } }) => (
          <CreatedByUser isTable userId={props.row.original?.updated_by?.id || 0} />
        ),
      },
      {
        Header: 'Status',
        colWidth: '15%',
        disableSortBy: true,
        accessor: 'active',
        id: 'status',
        Cell: (props: { row: { original: ApiKey } }) => (
          <HStack gap="2">
            <Text>{props.row.original.active ? 'Active' : 'Disabled'}</Text>
          </HStack>
        ),
      },
      {
        Header: 'Actions',
        colWidth: '5%',
        disableSortBy: true,
        accessor: 'actions',
        id: 'expander',
        Cell: (props: { row: { original: ApiKey } }) => (
          <DropdownMenu>
            <DropdownMenuTrigger asChild style={{ alignContent: 'end' }}>
              <IconButton aria-label="open dropdown" variant="outline" size="2">
                <HiDotsHorizontal size="15px" />
              </IconButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent sideOffset={10}>
              <DropdownMenuItem
                onClick={() =>
                  handleRenameButtonClick(
                    props.row.original.id || '',
                    props.row.original.name || ''
                  )
                }
              >
                Rename
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  if (props.row.original.id) {
                    handleDisableButtonClick(
                      props.row.original.id,
                      props.row.original.active ?? false
                    );
                  }
                }}
              >
                {props.row.original.active ? 'Disable' : 'Enable'}
              </DropdownMenuItem>
              <DropdownMenuItemWarning
                onClick={() => {
                  if (props.row.original?.id) {
                    handleDeleteButtonClick(props.row.original?.id);
                  }
                }}
              >
                Delete
              </DropdownMenuItemWarning>
            </DropdownMenuContent>
          </DropdownMenu>
        ),
      },
    ],
    [loading, apiKeys]
  );

  return (
    <>
      {isRenameConfirmationDialogOpen && (
        <RenameConfirmationDialog
          isRenameConfirmationDialogOpen={isRenameConfirmationDialogOpen}
          setIsRenameConfirmationDialogOpen={setIsRenameConfirmationDialogOpen}
          oldName={currentApiKeyName}
          renameApiKey={renameApiKey}
          currentApiKeyId={currentApiKeyId}
        />
      )}
      {isDeleteConfirmationDialogOpen && (
        <DeleteConfirmationDialog
          isDeleteConfirmationDialogOpen={isDeleteConfirmationDialogOpen}
          setIsDeleteConfirmationDialogOpen={setIsDeleteConfirmationDialogOpen}
          currentApiKeyId={currentApiKeyId}
          deleteApiKey={deleteApiKey}
        />
      )}
      {isDisableConfirmationDialogOpen && (
        <DisableConfirmationDialog
          isDisableConfirmationDialogOpen={isDisableConfirmationDialogOpen}
          setIsDisableConfirmationDialogOpen={setIsDisableConfirmationDialogOpen}
          currentApiKeyId={currentApiKeyId}
          currentApiKeyStatus={currentApiKeyStatus}
          disableApiKey={disableApiKey}
          enableApiKey={enableApiKey}
        />
      )}

      <Box css={{ p: 30 }}>
        <Box css={{ paddingBottom: 24 }}>
          <CombinedFilters
            quickSearchPlaceholder="Search API Keys"
            quickSearchValue={quickSearchValue}
            setQuickSearchValue={handleQuickSearchChange}
            defaultObjects={[default_api_key_object, updated_by, created_by]}
            customObjects={[]}
            activeFilters={activeFilters}
            setFilters={handleFilterChange}
            sortConfig={sortConfig}
            activeSort={activeSort}
            onSortUpdate={handleSortChange}
          />
        </Box>
        <Table
          data={data}
          columns={columns}
          isLoading={loading}
          caption="API Keys Table"
          emptyTitle="No API Keys found"
          pageSize={limit}
          setOffset={(offset) => {
            searchApiKeys(
              mergeQuickFilter(activeFilters, quickSearchValue),
              activeSort,
              limit,
              offset
            );
          }}
          totalCount={total}
          empty={
            <Flex css={{ width: '100%' }} direction="column">
              <VStack gap={3}>
                <Box>Create your first API Key.</Box>
                <Box>
                  <AddApiKeyErrorBoundaries feature="AddApiKey">
                    <AddApiKey />
                  </AddApiKeyErrorBoundaries>
                </Box>
              </VStack>
            </Flex>
          }
        />
      </Box>
    </>
  );
};
