import { capitalize } from 'lodash';
import { Dispatch } from 'react';
import React from 'react';

import { DatepickerDates } from '@/shared/components/datepicker/DateSelector';
import { ValueCombobox } from '@/shared/components/filterBuilder/values/Combobox';

export const TimeBucketSelector = ({
  dates,
  timeBucket,
  setTimeBucket,
}: {
  dates: DatepickerDates;
  timeBucket: string;
  setTimeBucket: Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <ValueCombobox
      hideChevron
      selectLabel="Time bucket"
      options={[
        {
          label: 'Hour',
          value: 'hour',
          disabled: dates.endDate.diff(dates.startDate, 'day') > 5,
        },
        { label: 'Day', value: 'day' },
        {
          label: 'Week',
          value: 'week',
          disabled: dates.endDate.diff(dates.startDate, 'week') < 2,
        },
        {
          label: 'Month',
          value: 'month',
          disabled: dates.endDate.diff(dates.startDate, 'month') < 2,
        },
      ]}
      onSelect={(option) => {
        setTimeBucket(option.value);
      }}
      selected={{ label: capitalize(timeBucket), value: timeBucket }}
      withSearch={false}
      css={{ minWidth: 'auto', height: 40 }}
      renderOptionsIfOpen
    />
  );
};

export const handleUpdateDates = (
  value: DatepickerDates,
  setDates: Dispatch<React.SetStateAction<DatepickerDates>>,
  timeBucket?: string,
  setTimeBucket?: Dispatch<React.SetStateAction<string>>
) => {
  setDates(value);
  if (
    setTimeBucket &&
    timeBucket === 'hour' &&
    value.endDate.diff(value.startDate, 'day') > 5
  ) {
    setTimeBucket('day');
  }
  if (
    setTimeBucket &&
    timeBucket === 'month' &&
    value.endDate.diff(value.startDate, 'month') < 2
  ) {
    setTimeBucket('week');
  }
  if (
    setTimeBucket &&
    timeBucket === 'week' &&
    value.endDate.diff(value.startDate, 'week') < 2
  ) {
    setTimeBucket('day');
  }
};
