/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { HiX } from 'react-icons/hi';

import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { getSequences } from '@/shared/api/sequences';
import { SingleSelect } from '@/shared/components/SingleSelect';
import { TimezonePicker } from '@/shared/components/timezonepicker/TimezonePicker';
import { useDisclosure } from '@/shared/hooks';
import { Group } from '@/shared/types/contacts/groups';
import { Sequence } from '@/shared/types/sequences';
import {
  Box,
  Button,
  Dialog,
  DialogCloseIcon,
  DialogContent,
  DialogFooter,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  DialogTrigger,
  Flex,
  Input,
  Label,
  VStack,
} from '@/shared/ui';

type EditActionProps = {
  segment: Group;
  updateSegment: (segment: Group) => void;
  children?: React.ReactNode;
};

export const EditAction = (props: EditActionProps) => {
  const { segment, updateSegment, children } = props;

  const [timezone, setTimezone] = useState('');
  const [sequence, setSequence] = useState('');

  const [channel, setChannel] = useState('');

  const [time, setTime] = useState('09:30');

  const handleSelectSequence = (option: string) => {
    setSequence(option);
  };

  const handleSelectChannel = (option: string) => {
    setChannel(option);
  };

  const generatePayload = () => {
    const [hour, minute] = time.split(':');
    return {
      enabled: true,
      type: 'bulk_add_to_sequence',
      params: {
        sequence_id: sequence,
        location_id: channel,
        schedule_options: {
          days: '0',
          hour: hour,
          minute: minute,
          timezone: timezone,
        },
      },
    };
  };

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [sequences, setSequences] = useState<Sequence[]>([]);

  useEffect(() => {
    getSequences().then((sequences) => {
      setSequences(
        sequences.data.filter((sequence): sequence is Sequence => sequence !== null)
      );
    });
  }, []);

  const locationsContext = useChannels();
  const { channelsState } = locationsContext;
  const { channels } = channelsState;

  const getLocationName = (id: string) =>
    channels.find((location) => location.id === id)?.name;

  const getSequenceTitle = (id: string) =>
    sequences
      .filter((sequence): sequence is Sequence => sequence !== null)
      .find((seq: Sequence) => seq.id === id)?.title;

  const sequenceOptions = sequences
    .filter((sequence): sequence is Sequence => sequence !== null)
    .map((sequence) => ({
      type: sequence?.title || '',
      value: sequence?.id || '',
    }));

  const handleSave = () => {
    // Generate the updated payload for the action
    const updatedPayload = generatePayload();

    // Create a new segment object with the updated action
    const updatedSegment = {
      ...segment,
      actions: [updatedPayload],
    };

    // Pass the updated segment back
    updateSegment(updatedSegment);

    handleClose();
  };

  const handleRemove = () => {
    // Create a new segment object without the action
    const updatedSegment = {
      ...segment,
      actions: [],
    };

    // Pass the updated segment back
    updateSegment(updatedSegment);

    handleClose();
  };

  useEffect(() => {
    setDefaultValues();
    return () => {
      handleClose();
    };
    // Include props.segment in the dependency array
    // This makes the useEffect responsive to changes in props.segment
  }, [props.segment]);

  const handleClose = () => {
    // Clear the values here
    setTimezone('');
    setSequence('');
    setChannel('');
    setTime('08:30'); // Assuming you want to reset to the default value
    onClose();
  };

  const setDefaultValues = () => {
    const actionParams = props.segment?.actions[0]?.params;
    if (actionParams && actionParams.schedule_options) {
      setTimezone(actionParams.schedule_options.timezone || '');
      setSequence(actionParams.sequence_id || '');
      setChannel(actionParams.location_id || '');

      // Combine hours and minutes to set the initial time
      const initialTime = `${actionParams.schedule_options.hour}:${actionParams.schedule_options.minute}`;
      setTime(initialTime || '08:30');
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogTrigger asChild onClick={onOpen}>
        <Box onClick={() => onOpen()}>{children}</Box>
      </DialogTrigger>
      <DialogPortal>
        <DialogOverlay css={{ zIndex: 999999 }} />
        <DialogContent css={{ overflow: 'auto', zIndex: 999999 }}>
          <DialogTitle variant="bold">Edit Segment Action</DialogTitle>
          <Box>
            <VStack gap="2">
              <Label css={{ mb: 0 }}>Every day at</Label>
              <Input type="time" value={time} onChange={(e) => setTime(e.target.value)} />
              <Label css={{ mb: 0 }}>in</Label>
              <TimezonePicker
                timezone={timezone}
                setTimezone={setTimezone}
                isSmall={false}
                disabled={false}
              />
              <Label css={{ mb: 0 }}>
                Add contacts that match these filters to sequence
              </Label>
              <SingleSelect
                defaultPlaceholder={
                  getSequenceTitle(sequence || '') || 'Select a Sequence'
                }
                selectItem={sequence}
                options={sequenceOptions}
                setSelectItem={handleSelectSequence}
                isDropdown={true}
              />
              <Label css={{ mb: 0 }}>
                If they do not have a default channel then use
              </Label>
              <SingleSelect
                defaultPlaceholder={getLocationName(channel || '') || 'Select a channel'}
                isDropdown={true}
                selectItem={channel || ''}
                setSelectItem={handleSelectChannel}
                options={channels.map((location) => ({
                  type: location.name || location.address || '',
                  value: location?.id,
                }))}
              />
            </VStack>
          </Box>
          <DialogFooter>
            <Flex css={{ width: '100%' }} justify="end">
              <Button variant="gray" css={{ mr: 5 }} onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="red"
                css={{ mr: 5 }}
                onClick={handleRemove}
                disabled={props.segment?.actions.length === 0}
              >
                Remove Segment Action
              </Button>
              <Button
                size="2"
                onClick={handleSave}
                disabled={!time || !timezone || !sequence || !channel}
              >
                Save Segment Action
              </Button>
            </Flex>
          </DialogFooter>
          <DialogCloseIcon size="2" onClick={onClose}>
            <HiX size="15px" />
          </DialogCloseIcon>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};
