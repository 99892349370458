import React from 'react';
import { useMedia } from 'react-use';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { renderText } from '@/shared/components/markdown/MarkdownRenderer';
import { Box, Flex, Text, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

type PreviewMessagePanelProps = {
  /** list of attachments urls */
  attachment_urls: Array<string | null>;
  /** the campaign message body */
  body: string;
  isFlex?: boolean;
  /** message platform type */
  platform?: 'imessage' | 'whatsapp';
};

export const PreviewMessagePanel = (props: PreviewMessagePanelProps) => {
  const { body, attachment_urls, isFlex, platform = 'imessage' } = props;
  const isDesktop = useMedia('(min-width: 1350px)');

  // get org name for preview
  const auth = useAuth();
  const organization_name = auth.organizationName || '';

  // get the first attachment to show as a preview
  const attachment = attachment_urls ? attachment_urls[0] : null;

  return (
    <VStack
      gap={isFlex && isDesktop ? 0 : 6}
      css={{
        fd: isFlex && isDesktop ? 'row' : 'column',
        position: 'relative',
        width: '100%',
        px: 20,
        pt: 20,
        pb: 100,
        height: '100%',
      }}
    >
      <Flex
        align="center"
        justify="center"
        css={{ position: 'relative', width: '100%', height: '100%' }}
      >
        <LockScreen align="end" justify="between" platform={platform}>
          <PreviewWrapper css={{ mb: 110 }}>
            {attachment && <Img src={attachment} alt="image-preview" />}
            <PreviewContainer
              css={{
                backgroundColor: platform === 'whatsapp' ? '#E7FFDB' : '#E9E9EB',
                mt: 5,
                width: '90%',
              }}
            >
              <MessagePreview>
                {renderText(body) || "We'll display your message here when it is ready."}
              </MessagePreview>
            </PreviewContainer>
          </PreviewWrapper>
        </LockScreen>
      </Flex>
      <Flex
        align="center"
        justify="center"
        css={{ position: 'relative', width: '100%', height: '100%' }}
      >
        <ChatScreen align="center" justify="between">
          <PreviewWrapper>
            <PreviewContainer css={{ width: '100%' }}>
              <VStack gap={1}>
                <Flex align="center" justify="between" css={{ pb: 3 }}>
                  <Flex align="center" justify="center">
                    <MessageLogo platform={platform} />
                    <Text css={{ color: 'black', pl: 8, fontSize: 11 }}>
                      {platform === 'whatsapp' ? 'WHATSAPP' : 'MESSAGES'}
                    </Text>
                  </Flex>
                  <Box>now</Box>
                </Flex>
                <Box css={{ fontWeight: 600, fontSize: 12 }}>{organization_name}</Box>
                <NotificationPreview>
                  {body || "We'll display your message here when it is ready."}
                </NotificationPreview>
              </VStack>
            </PreviewContainer>
          </PreviewWrapper>
        </ChatScreen>
      </Flex>
    </VStack>
  );
};

const ChatScreen = styled(Flex, {
  width: '287px',
  height: '582px',
  backgroundSize: 'cover',
  backgroundImage: `url(${window.location.origin}/lockscreen.jpg)`,
});

const LockScreen = styled(Flex, {
  width: '287px',
  height: '582px',
  backgroundSize: 'cover',
  variants: {
    platform: {
      imessage: {
        backgroundImage: `url(${window.location.origin}/iphone.jpg)`,
      },
      whatsapp: {
        backgroundImage: `url(${window.location.origin}/whatsapp.png)`,
      },
    },
  },
});

const PreviewWrapper = styled(Box, {
  paddingInline: '1.5rem',
  width: '100%',
  borderColor: '#dddee1',
  overflowWrap: 'break-word',
  fontSize: 12,
});

const PreviewContainer = styled(Box, {
  backgroundColor: 'rgba(245, 245, 245, 0.5)',
  backdropFilter: 'blur(80px)',
  p: 12,
  borderRadius: '12px',
});

const MessageLogo = styled(Flex, {
  width: '18px',
  height: '18px',
  backgroundSize: 'cover',
  variants: {
    platform: {
      imessage: {
        backgroundImage: `url(https://uploads-ssl.webflow.com/613733f41272124a63075176/619ca402a5b8a095a3b2d2dc_imessage.png)`,
      },
      whatsapp: {
        backgroundImage: `url(${window.location.origin}/whatsapp-logo.png)`,
      },
    },
  },
});

const NotificationPreview = styled(Box, {
  color: 'black',
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const MessagePreview = styled(Box, {
  color: 'black',
  display: '-webkit-box',
  '-webkit-line-clamp': 9,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: 11,
});

export const Img = styled('img', {
  maxWidth: '90%',
  height: '100%',
  maxHeight: '150px',
  objectFit: 'fit',
  borderRadius: '8px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#E9E9EB',
});
