/* eslint-disable react-hooks/exhaustive-deps */
import { Combobox, ComboboxItem, ComboboxList, ComboboxProvider } from '@ariakit/react';
import * as RadixSelect from '@radix-ui/react-select';
import { matchSorter } from 'match-sorter';
import { startTransition, useEffect, useMemo, useState } from 'react';
import { HiCheck, HiChevronDown, HiSearch } from 'react-icons/hi';

import { Flex } from '@/shared/ui';
import { styled } from '@/stitches.config';

export type ComboboxOption<OptionValue = string | number> = {
  label: string;
  value: OptionValue;
  disabled?: boolean;
};

type ValueComboboxProps<
  OptionValue = string | number,
  ComboboxValue = string | number | string[],
> = {
  options: Array<ComboboxOption<OptionValue>>;
  selected?: ComboboxOption<ComboboxValue> | null | undefined;
  onSelect: (option: { label: string; value: OptionValue }) => void;
  selectLabel?: string;
  searchLabel?: string;
  searchValue?: string;
  handleSearchValue?: (value: string) => void;
  selectorStyles?: { [key: string]: any };
  valueStyles?: { [key: string]: any };
  popoverStyles?: { [key: string]: any };
  comboboxStyles?: { [key: string]: any };
  comboboxItemStyles?: { [key: string]: any };
  comboboxWrapperStyles?: { [key: string]: any };
  css?: { [key: string]: any };
  isOpen?: boolean;
  setIsOpen?: (value: boolean) => void;
  SelectItemIndicatorComponent?: React.FC<RadixSelect.SelectItemIndicatorProps>;
  /**
   * this function when provided will be used to transform the value
   * before it is displayed in the combobox.
   * */
  transformValue?: (value?: ComboboxValue) => string | string[];
  withSearch?: boolean;
  /* hides the options when select is not open to avoid slows/blocks renders
    if it has more items "than usual". And use span instead of <SelectValue/> */
  renderOptionsIfOpen?: boolean;
  disabled?: boolean;
  hideChevron?: boolean;
  minWidth?: number | string;
  sideOffset?: number;
  showSelectItemIndicator?: boolean;
  usePortal?: boolean;
  customItem?: React.ReactNode;
};

/**
 * A customizable combobox that we use for the Advanced Filter Builder.
 *
 * @template OptionValue The type of the value in the options array. We can use this to properly
 * type what options we can provide to the select dropdown.
 * @template ComboboxValue The type of the selected value and onSelect callback. We can use this
 * to properly type what ultimate selected value type will be.
 *
 * @param  props The component props
 * @returns  The rendered ValueCombobox component
 *
 * @example
 * // Example usage where the value of the option is a string and the selected value is a string array
 * <ValueCombobox<string, string[]>
 *   options={[
 *     { label: "Option 1", value: "value1" },
 *     { label: "Option 2", value: "value2" }
 *   ]}
 *   selected={null}
 *   onSelect={(option) => console.log(option.value)}
 *   selectLabel="Choose an option"
 * />
 */
export const ValueCombobox = <
  OptionValue = string | number,
  ComboboxValue = string | string[] | number,
>(
  props: ValueComboboxProps<OptionValue, ComboboxValue>
) => {
  const {
    options,
    selected,
    onSelect,
    selectLabel,
    searchValue,
    handleSearchValue,
    selectorStyles,
    valueStyles,
    css,
    isOpen,
    setIsOpen,
    transformValue,
    renderOptionsIfOpen,
    disabled = false,
    hideChevron = false,
    minWidth = 127,
    usePortal = false,
    customItem,
  } = props;
  const [open, setOpen] = useState(isOpen);
  const [value, setValue] = useState('');
  // if searchValue is not provided, use value and handleSearchValue else use value and setValue
  const handleSearch = handleSearchValue ? handleSearchValue : setValue;
  const searchValueProp = handleSearchValue ? searchValue : value;

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const matches = useMemo(() => {
    if (!searchValueProp) return options;
    const keys = ['label', 'value'];
    const matches = matchSorter(options, searchValueProp, { keys });
    // Radix Select does not work if we don't render the selected item, so we
    // make sure to include it in the list of matches.
    const selectedLanguage = options?.find((lang) => lang.value === selected?.value);
    if (selectedLanguage && !matches?.includes(selectedLanguage)) {
      matches?.push(selectedLanguage);
    }
    return matches;
  }, [searchValueProp, selected?.value, options]);

  return (
    <Flex align="center" css={{ minWidth: minWidth, height: 35, ...css }}>
      <RadixSelect.Root
        disabled={disabled}
        value={selected?.value as string}
        onValueChange={(value) => {
          const option = options.find((lang) => lang.value === value);
          if (option) {
            onSelect(option);
          }
        }}
        open={open}
        onOpenChange={(v) => {
          setOpen(v);
          setIsOpen && setIsOpen(v);
        }}
      >
        <ComboboxProvider
          open={open}
          setOpen={(v) => {
            setOpen(v);
            setIsOpen && setIsOpen(v);
          }}
          resetValueOnHide
          includesBaseElement={false}
          setValue={(value) => {
            startTransition(() => {
              handleSearch(value);
            });
          }}
        >
          <StyledSelect css={selectorStyles} aria-label={selectLabel || 'Select a value'}>
            <span style={{ ...valueStyles }}>
              {transformValue ? (
                renderOptionsIfOpen ? (
                  <span>
                    {transformValue(selected?.value) || selectLabel || 'Select a value'}
                  </span>
                ) : (
                  <RadixSelect.Value>
                    {transformValue(selected?.value) || selectLabel || 'Select a value'}
                  </RadixSelect.Value>
                )
              ) : renderOptionsIfOpen ? (
                <span>{selected?.label || selectLabel || 'Select a value'}</span>
              ) : (
                <RadixSelect.Value placeholder={selectLabel || 'Select a value'} />
              )}
            </span>
            {!hideChevron && (
              <RadixSelect.Icon style={{ translate: '4px 0' }}>
                <HiChevronDown />
              </RadixSelect.Icon>
            )}
          </StyledSelect>
          {usePortal ? (
            <RadixSelect.Portal>
              <StyledPopoverComponent
                {...props}
                options={matches}
                isOpen={open}
                customItem={customItem}
              />
            </RadixSelect.Portal>
          ) : (
            <StyledPopoverComponent
              {...props}
              options={matches}
              isOpen={open}
              customItem={customItem}
            />
          )}
        </ComboboxProvider>
      </RadixSelect.Root>
    </Flex>
  );
};

const StyledPopoverComponent = <
  OptionValue = string | number,
  ComboboxValue = string | string[] | number,
>(
  props: ValueComboboxProps<OptionValue, ComboboxValue>
) => {
  const {
    searchLabel,
    popoverStyles,
    comboboxStyles,
    comboboxItemStyles,
    comboboxWrapperStyles,
    SelectItemIndicatorComponent,
    renderOptionsIfOpen,
    withSearch = true,
    sideOffset = 4,
    showSelectItemIndicator = true,
    options,
    isOpen,
    customItem,
  } = props;
  return (
    <StyledPopover
      css={popoverStyles}
      role="dialog"
      aria-label="Languages"
      position="popper"
      sideOffset={sideOffset}
    >
      {withSearch && (
        <ComboboxWrapper css={comboboxWrapperStyles}>
          <StyledComboboxIcon>
            <HiSearch />
          </StyledComboboxIcon>
          <StyledCombobox
            css={comboboxStyles}
            autoSelect
            placeholder={searchLabel || 'Search options'}
            // Ariakit's Combobox manually triggers a blur event on virtually
            // blurred items, making them work as if they had actual DOM
            // focus. These blur events might happen after the corresponding
            // focus events in the capture phase, leading Radix Select to
            // close the popover. This happens because Radix Select relies on
            // the order of these captured events to discern if the focus was
            // outside the element. Since we don't have access to the
            // onInteractOutside prop in the Radix SelectContent component to
            // stop this behavior, we can turn off Ariakit's behavior here.
            onBlurCapture={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
          />
        </ComboboxWrapper>
      )}
      {((isOpen && renderOptionsIfOpen) || !renderOptionsIfOpen) && (
        <StyledComboboxList>
          {customItem}
          {options?.map(({ label, value, disabled }) => (
            <StyledItem
              data-testid="combobox-item"
              css={comboboxItemStyles}
              disabled={disabled}
              key={value as React.Key}
              value={value as string}
              asChild
            >
              <ComboboxItem>
                <RadixSelect.ItemText>{label}</RadixSelect.ItemText>
                {showSelectItemIndicator && (
                  <>
                    {SelectItemIndicatorComponent ? (
                      <SelectItemIndicatorComponent />
                    ) : (
                      <StyledItemIndicator>
                        <HiCheck />
                      </StyledItemIndicator>
                    )}
                  </>
                )}
              </ComboboxItem>
            </StyledItem>
          )) || 'No matches found.'}
        </StyledComboboxList>
      )}
    </StyledPopover>
  );
};

export const StyledPopover = styled(RadixSelect.Content, {
  zIndex: 50,
  maxHeight: 'min(var(--radix-select-content-available-height), 336px)',
  borderRadius: '0.5rem',
  backgroundColor: 'hsl(204 20% 100%)',
  boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.25), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
  colorScheme: 'light',
});

export const StyledSelect = styled(RadixSelect.Trigger, {
  display: 'inline-flex',
  alignItems: 'center',
  backgroundColor: 'white',
  justifyContent: 'space-between',
  borderRadius: 4,
  color: 'hsl(204 10% 10%)',
  border: '1px solid $slate7',
  padding: '$space$1 $space$3',
  width: '100%',
  height: '100%',
  fontSize: '14px',
  minWidth: 'max-content',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const StyledComboboxList = styled(ComboboxList, {
  overflowY: 'auto',
  padding: '8px 8px 12px 8px',
  fontSize: '14px',
});

export const StyledComboboxIcon = styled('div', {
  pointerEvents: 'none',
  position: 'absolute',
  left: '14px',
  color: 'rgba(0, 7, 19, 0.62)',
});

export const StyledItem = styled(RadixSelect.Item, {
  position: 'relative',
  fontSize: '14px',
  pointer: 'cursor',
  display: 'flex',
  height: '2rem',
  cursor: 'default',
  scrollMarginTop: '0.25rem',
  scrollMarginBottom: '0.25rem',
  backgroundColor: 'white',
  alignItems: 'center',
  borderRadius: '0.25rem',
  padding: '6px 24px',
  color: '$textColor',
  outline: '2px solid transparent',
  outlineOffset: '2px',
  '&[data-active-item]': {
    backgroundColor: '$indigo9',
    color: '#fff',
  },
  '&[data-disabled]': {
    opacity: 0.5,
  },
});

export const StyledCombobox = styled(Combobox, {
  height: '2rem',
  appearance: 'none',
  borderRadius: '0.25rem',
  backgroundColor: 'rgba(1, 68, 255, 0.06)',
  paddingLeft: '25px',
  color: 'rgba(0, 4, 29, 0.46)',
  outline: '2px solid transparent',
  outlineOffset: '2px',
  width: '100%',
});

export const ComboboxWrapper = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px 8px 0 8px',
  fontSize: '14px',
});

export const StyledItemIndicator = styled(RadixSelect.ItemIndicator, {
  position: 'absolute',
  left: '5px',
});
