import {
  USER_WEB_NOTIFICATION_TYPES,
  UserWebNotification,
  UserWebNotificationTypes,
} from '@/shared/types/notifications';

export const generateNotificationTitle = (notification: UserWebNotification) => {
  // in the notification type is one of NEW_MESSAGE, NEW_MESSAGE_FOR_ALL_CONVERSATION, or NEW_MESSAGE_FOR_UNASSIGNED_CONVERSATION:
  // then return "New Message from notification.metadata.contact_name" else return the notification type mapped to the UserWebNotificationTitles object
  if (
    notification.notification_type === UserWebNotificationTypes.NEW_MESSAGE ||
    notification.notification_type ===
      UserWebNotificationTypes.NEW_MESSAGE_FOR_ALL_CONVERSATION ||
    notification.notification_type ===
      UserWebNotificationTypes.NEW_MESSAGE_FOR_UNASSIGNED_CONVERSATION
  ) {
    if (notification.metadata.campaign_title == null)
      return `New Message from ${notification.metadata.contact_name}`;
    else
      return `${notification.metadata.contact_name} responded to Campaign "${notification.metadata.campaign_title}"`;
  } else {
    return USER_WEB_NOTIFICATION_TYPES[
      notification.notification_type as keyof typeof USER_WEB_NOTIFICATION_TYPES
    ] as string;
  }
};
