import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
  HiArchive,
  HiCalendar,
  HiCheckCircle,
  HiDocument,
  HiSearch,
  HiSpeakerphone,
  HiTemplate,
} from 'react-icons/hi';
import { useRouteMatch } from 'react-router-dom';
import { useMedia } from 'react-use';

import LinksProvider from '@/pages/links/context/LinksContext';
import { useSidenavigationSettings } from '@/shared/components/navigation/context/SideNavigationContext';
import {
  SideNavigation,
  sideNavigationGroup,
} from '@/shared/components/navigation/SideNavigation';
import {
  SidebarNavigationContainer,
  SidebarNavigationWrapper,
} from '@/shared/components/navigation/SideNavigationContainer';
import { LayoutContent, MainLayout } from '@/shared/layouts';

export function CampaignsNavigation() {
  const { navigationSettings, toggleExpandedState } = useSidenavigationSettings();

  const isCampaigns = useRouteMatch([
    '/campaigns/sms',
    '/campaigns/email',
    '/campaigns/whatsapp',
    '/campaigns',
  ])?.isExact;
  const isDrafts = useRouteMatch([
    '/campaigns/draft',
    '/campaigns/sms/drafts',
    '/campaigns/email/drafts',
    '/campaigns/whatsapp/drafts',
  ])?.isExact;
  const isScheduled = useRouteMatch([
    '/campaigns/scheduled',
    '/campaigns/sms/scheduled',
    '/campaigns/email/scheduled',
    '/campaigns/whatsapp/scheduled',
  ])?.isExact;
  const isComplete = useRouteMatch([
    '/campaigns/complete',
    '/campaigns/sms/complete',
    '/campaigns/email/complete',
    '/campaigns/whatsapp/complete',
  ])?.isExact;
  const isArchived = useRouteMatch([
    '/campaigns/archive',
    '/campaigns/sms/archive',
    '/campaigns/email/archive',
    '/campaigns/whatsapp/archive',
  ])?.isExact;
  const isGlobalTemplates = useRouteMatch('/campaigns/sms/templates/browse')?.isExact;
  const isMyTemplates = useRouteMatch('/campaigns/sms/templates')?.isExact;

  const isEmailCampaigns = useRouteMatch('/campaigns/email*') !== null;
  const isNewSMSCampaigns = useRouteMatch('/campaigns/sms*') !== null;
  const isWhatsAppCampaigns = useRouteMatch('/campaigns/whatsapp*') !== null;

  const templates = [
    {
      title: 'My Templates',
      image: <HiTemplate size={18} />,
      isActive: !!isMyTemplates,
      link: '/campaigns/sms/templates',
    },
    {
      title: 'Browse Templates',
      image: <HiSearch size={18} />,
      isActive: !!isGlobalTemplates,
      link: '/campaigns/sms/templates/browse',
    },
  ];
  const campaignsSideNavigationTabs: Array<sideNavigationGroup> = [
    {
      sideNavigationGroup: [
        {
          title: 'All Campaigns',
          image: <HiSpeakerphone size={18} />,
          isActive: !!isCampaigns,
          link: getAllCampaignsRoute(
            isEmailCampaigns,
            isNewSMSCampaigns,
            isWhatsAppCampaigns
          ),
        },
        {
          title: 'Drafts',
          image: <HiDocument size={18} />,
          isActive: !!isDrafts,
          link: getDraftCampaignsRoute(
            isEmailCampaigns,
            isNewSMSCampaigns,
            isWhatsAppCampaigns
          ),
        },
        {
          title: 'Scheduled',
          image: <HiCalendar size={18} />,
          isActive: !!isScheduled,
          link: getScheduledCampaignsRoute(
            isEmailCampaigns,
            isNewSMSCampaigns,
            isWhatsAppCampaigns
          ),
        },
        {
          title: 'Complete',
          image: <HiCheckCircle size={18} />,
          isActive: !!isComplete,
          link: getCompletedCampaignsRoute(
            isEmailCampaigns,
            isNewSMSCampaigns,
            isWhatsAppCampaigns
          ),
        },
        {
          title: 'Archived',
          image: <HiArchive size={18} />,
          isActive: !!isArchived,
          link: getArchivedCampaignsRoute(
            isEmailCampaigns,
            isNewSMSCampaigns,
            isWhatsAppCampaigns
          ),
        },
      ],
    },
  ];
  return (
    <SidebarNavigationContainer>
      <SidebarNavigationWrapper>
        <SideNavigation
          expanded={navigationSettings.expanded}
          header="Campaigns"
          sideNavigationTabs={
            isEmailCampaigns || isWhatsAppCampaigns
              ? campaignsSideNavigationTabs
              : [
                  ...campaignsSideNavigationTabs,
                  {
                    sideNavigationGroup:
                      isEmailCampaigns || isWhatsAppCampaigns ? [] : templates,
                  },
                ]
          }
          toggleExpandedState={toggleExpandedState}
        />
      </SidebarNavigationWrapper>
    </SidebarNavigationContainer>
  );
}

export const CampaignsLayoutContainer = ({ children }: { children: React.ReactNode }) => {
  const isDesktop = useMedia('(min-width: 912px)');
  return (
    <MainLayout>
      {isDesktop && <CampaignsNavigation />}
      <LayoutContent>{children}</LayoutContent>
    </MainLayout>
  );
};

export const CampaignContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <LinksProvider>
      <Helmet>
        <title>Whippy | Campaigns</title>
      </Helmet>
      <CampaignsLayoutContainer>{children}</CampaignsLayoutContainer>
    </LinksProvider>
  );
};

function getAllCampaignsRoute(
  isEmailCampaigns?: boolean,
  isNewSMSCampaigns?: boolean,
  isWhatsAppCampaigns?: boolean
) {
  if (isEmailCampaigns) {
    return '/campaigns/email';
  } else if (isWhatsAppCampaigns) {
    return '/campaigns/whatsapp';
  } else if (isNewSMSCampaigns) {
    return '/campaigns/sms';
  }
  return '/campaigns/sms';
}

function getDraftCampaignsRoute(
  isEmailCampaigns?: boolean,
  isNewSMSCampaigns?: boolean,
  isWhatsAppCampaigns?: boolean
) {
  if (isEmailCampaigns) {
    return '/campaigns/email/drafts';
  } else if (isWhatsAppCampaigns) {
    return '/campaigns/whatsapp/drafts';
  } else if (isNewSMSCampaigns) {
    return '/campaigns/sms/drafts';
  }
  return '/campaigns/sms/drafts';
}

function getArchivedCampaignsRoute(
  isEmailCampaigns?: boolean,
  isNewSMSCampaigns?: boolean,
  isWhatsAppCampaigns?: boolean
) {
  if (isEmailCampaigns) {
    return '/campaigns/email/archive';
  } else if (isWhatsAppCampaigns) {
    return '/campaigns/whatsapp/archive';
  } else if (isNewSMSCampaigns) {
    return '/campaigns/sms/archive';
  }
  return '/campaigns/sms/archive';
}

function getCompletedCampaignsRoute(
  isEmailCampaigns?: boolean,
  isNewSMSCampaigns?: boolean,
  isWhatsAppCampaigns?: boolean
) {
  if (isEmailCampaigns) {
    return '/campaigns/email/complete';
  } else if (isWhatsAppCampaigns) {
    return '/campaigns/whatsapp/complete';
  } else if (isNewSMSCampaigns) {
    return '/campaigns/sms/complete';
  }
  return '/campaigns/sms/complete';
}

function getScheduledCampaignsRoute(
  isEmailCampaigns?: boolean,
  isNewSMSCampaigns?: boolean,
  isWhatsAppCampaigns?: boolean
) {
  if (isEmailCampaigns) {
    return '/campaigns/email/scheduled';
  } else if (isWhatsAppCampaigns) {
    return '/campaigns/whatsapp/scheduled';
  } else if (isNewSMSCampaigns) {
    return '/campaigns/sms/scheduled';
  }
  return '/campaigns/sms/scheduled';
}
