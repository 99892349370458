/* eslint-disable react-hooks/exhaustive-deps */
import * as SelectPrimitive from '@radix-ui/react-select';
import { debounce } from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';

import { useAuth } from '@/pages/auth/context/AuthProvider';
import { getProviderAccounts } from '@/shared/api/channels/accounts';
import { getPhoneNumbers, PhoneData } from '@/shared/api/channels/phones';
import { Channel, ChannelTypes, ProviderTypes } from '@/shared/types/channels';
import { ProviderAccountItem } from '@/shared/types/channels/accounts';
import {
  Box,
  Fieldset,
  Flex,
  HStack,
  Input,
  Label,
  RadioGroup,
  RadioGroupIndicator,
  RadioGroupRadio,
  Select,
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectItemIndicator,
  SelectItemText,
  SelectLabel,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport,
  VStack,
} from '@/shared/ui';
import { formatPhoneNumber, toE164 } from '@/shared/utils/validations/validations';

import { CreateProviderAccountForm } from '../../accounts';
import {
  EmailInputWithPreview,
  isEmailProvider,
} from '../components/EmailInputWithPreview';
import { channel_providers } from './SelectChannelProvider';

/***
 * We use this component to select the sender for a channel.
 * when creating a channel in the MultiStepForm.
 */
export const SelectChannelSender = ({
  channel,
  setChannel,
}: {
  channel: Partial<
    Channel & {
      channelProviderId: null | 'whippy-email' | 'whippy-managed-email' | 'mailgun-email';
      whippyManagedDomain: string | null;
    }
  >;
  setChannel: Dispatch<SetStateAction<Partial<Channel>>>;
}) => {
  const [supportsPhoneNumberPurchase, setSupportsPhoneNumberPurchase] = useState(false);
  const [phoneNumberPurchaseType, setPhoneNumberPurchaseType] = useState('manual');
  const [phoneNumbers, setPhoneNumbers] = useState<PhoneData[]>([]);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string>('');
  const [providerAccounts, setProviderAccounts] = useState<ProviderAccountItem[]>([]);
  const [selectedProviderAccountId, setSelectedProviderAccountId] = useState<string>('');
  const [isSearchingPhoneNumbers, setIsSearchingPhoneNumbers] = useState(false);
  const [createProviderAccount, setCreateProviderAccount] = useState(true);

  useEffect(() => {
    if (channel.type === ChannelTypes.PHONE) {
      const phoneNumberPurchase =
        channel_providers.find((provider) => provider.provider === channel.provider)
          ?.support_phone_number_purchase || false;

      setSupportsPhoneNumberPurchase(phoneNumberPurchase);
    }
  }, [channel.type]);

  useEffect(() => {
    // Define the parameters for the API request
    const params = {
      filter: [
        {
          column: 'id',
          comparison: '==',
          resource: 'organization',
          value: organizationId,
        },
      ],
      sort: [],
      limit: 10,
      offset: 0,
    };

    // Fetch providers data
    createProviderAccount &&
      getProviderAccounts(params).then((data) =>
        // filter out accounts that are not in the organization
        {
          setProviderAccounts(data);
          setCreateProviderAccount(false);
        }
      );
  }, [createProviderAccount]);

  const authContext = useAuth();
  const { organizationId } = authContext;

  const debouncedSearchPhoneNumbers = debounce(async (area_code: string) => {
    try {
      if (selectedProviderAccountId) {
        setPhoneNumbers([]);
        setIsSearchingPhoneNumbers(true);
        const res = await getPhoneNumbers(selectedProviderAccountId, {
          area_code,
        });

        setPhoneNumbers(res.data);
        setIsSearchingPhoneNumbers(false);
      }
    } catch (error) {
      console.error('Error fetching phone numbers:', error);
      setIsSearchingPhoneNumbers(false);
    }
  }, 300); // 300ms debounce delay

  const handleSearchPhoneNumbers = (area_code: string) => {
    if (area_code.length === 3) {
      debouncedSearchPhoneNumbers(area_code);
    }
  };

  // set the default provider account to the first provider account with the
  // same organization id on mount
  useEffect(() => {
    if (
      providerAccounts &&
      providerAccounts.length > 0 &&
      organizationId &&
      channel.provider === ProviderTypes.TWILIO
    ) {
      setSelectedProviderAccountId(
        providerAccounts.find((provider) => provider.organization.id === organizationId)
          ?.id || ''
      );
      setChannel((prevChannel) => ({
        ...prevChannel,
        provider_account_id:
          providerAccounts.find((provider) => provider.organization.id === organizationId)
            ?.id || '',
      }));
    }
  }, [providerAccounts, organizationId]);

  return (
    <Flex direction="column" css={{ width: '100%' }}>
      {channel.type === ChannelTypes.EMAIL &&
        channel.channelProviderId &&
        isEmailProvider(channel.channelProviderId) && (
          <Fieldset>
            <Label>Sending Email Address</Label>
            <EmailInputWithPreview
              channel={channel as Channel}
              channelProviderId={channel.channelProviderId ?? 'whippy-email'}
              onEmailAddressChange={(emailAddress) => {
                setChannel({
                  ...channel,
                  sending_email_address: emailAddress,
                });
              }}
            />
          </Fieldset>
        )}
      {channel.type === ChannelTypes.WHATSAPP && (
        <Fieldset>
          <Label>Sending Phone Number</Label>
          <Input
            placeholder="Sending Phone Number"
            value={channel.phone || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              setChannel((prevChannel) => ({
                ...prevChannel,
                phone: toE164(e.target.value),
              }))
            }
          />
        </Fieldset>
      )}
      {channel.type === ChannelTypes.PHONE && supportsPhoneNumberPurchase && (
        <Fieldset>
          <Label>Sending Phone Number</Label>
          <RadioGroup
            value={phoneNumberPurchaseType}
            onValueChange={setPhoneNumberPurchaseType}
          >
            <Flex align="center" css={{ mt: 10 }}>
              <Flex justify="center" align="center" css={{ mr: 10 }}>
                <RadioGroupRadio value="manual">
                  <RadioGroupIndicator />
                </RadioGroupRadio>
                <Label css={{ ml: 5, mb: 0 }}>Add manually</Label>
              </Flex>
              <HStack align="center">
                <RadioGroupRadio value="purchase">
                  <RadioGroupIndicator />
                </RadioGroupRadio>
                <Label css={{ ml: 5, mb: 0 }}>Buy from provider</Label>
              </HStack>
            </Flex>
          </RadioGroup>
        </Fieldset>
      )}
      {channel.type === ChannelTypes.PHONE && phoneNumberPurchaseType === 'manual' && (
        <Fieldset css={{ mt: 10 }}>
          <Label>Phone Number</Label>
          <Input
            type="tel"
            placeholder="(123) 456-7890"
            value={channel.phone || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              setChannel((prevChannel) => ({
                ...prevChannel,
                phone: toE164(e.target.value),
              }))
            }
          />
        </Fieldset>
      )}
      {channel.type === ChannelTypes.PHONE &&
        phoneNumberPurchaseType === 'purchase' &&
        providerAccounts &&
        providerAccounts.length === 0 && (
          <VStack gap="2">
            <Box css={{ mt: 10, fontSize: '13px' }}>
              You need to add a provider account before you can purchase a phone number.
            </Box>
            <CreateProviderAccountForm
              setCreateProviderAccount={setCreateProviderAccount}
            />
          </VStack>
        )}
      {channel.type === ChannelTypes.PHONE &&
        phoneNumberPurchaseType === 'purchase' &&
        providerAccounts &&
        providerAccounts.length > 0 && (
          <>
            <Fieldset css={{ mt: 10 }}>
              <Label>Select Provider Account</Label>
              <Select
                value={selectedProviderAccountId || ''}
                onValueChange={(id: string) => {
                  setSelectedProviderAccountId(id);
                  setChannel((prevChannel) => ({
                    ...prevChannel,
                    provider_account_id: id,
                  }));
                }}
              >
                <SelectTrigger aria-label="action-select-trigger">
                  <SelectValue />
                  <SelectIcon>
                    <HiChevronDown />
                  </SelectIcon>
                </SelectTrigger>
                <SelectPrimitive.Portal>
                  <SelectContent css={{ zIndex: '9999' }}>
                    <SelectScrollUpButton>
                      <HiChevronUp />
                    </SelectScrollUpButton>
                    <SelectViewport>
                      <SelectGroup>
                        <SelectLabel>Communication Providers</SelectLabel>
                        {providerAccounts?.map((provider, index) => (
                          <SelectItem key={index} value={provider.id}>
                            <SelectItemIndicator />
                            <SelectItemText>{provider.name}</SelectItemText>
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectViewport>
                  </SelectContent>
                </SelectPrimitive.Portal>
              </Select>
            </Fieldset>
            <Fieldset css={{ mt: 10 }}>
              <Label>Search Phone Numbers by Area Code</Label>
              <Input
                placeholder="Search by area code e.g. 202"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  if (value.length <= 3) {
                    handleSearchPhoneNumbers(value);
                  }
                }}
              />
            </Fieldset>
            {isSearchingPhoneNumbers && <Box css={{ mt: 10 }}>Searching...</Box>}
            {channel.type === ChannelTypes.PHONE &&
              phoneNumberPurchaseType === 'purchase' &&
              providerAccounts &&
              phoneNumbers.length > 0 && (
                <Fieldset css={{ mt: 10 }}>
                  <Label>Available Phone Numbers</Label>
                  <Flex direction="column">
                    <RadioGroup
                      value={selectedPhoneNumber}
                      onValueChange={setSelectedPhoneNumber}
                    >
                      <Flex css={{ flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        {phoneNumbers.slice(0, 10).map((phone) => (
                          <HStack
                            key={phone.phone_number}
                            align="center"
                            onClick={() => {
                              setSelectedPhoneNumber(phone.phone_number);
                              setChannel((prevChannel) => ({
                                ...prevChannel,
                                phone: phone.phone_number,
                              }));
                            }}
                            css={{
                              flex: '1 40%',
                              boxSizing: 'border-box',
                              padding: '10px',
                              pl: '15px',
                              border: '1px solid #ccc',
                              borderRadius: '5px',
                              margin: '5px',
                              ml: '0px',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              cursor: 'pointer',
                              transition: 'background-color 0.3s',
                              '&:hover': {
                                backgroundColor: '#f0f0f0',
                              },
                            }}
                          >
                            <RadioGroupRadio value={phone.phone_number}>
                              <RadioGroupIndicator />
                            </RadioGroupRadio>
                            <VStack css={{ ml: 5, mb: 0 }} gap="1">
                              <Box css={{ fontSize: '13px' }}>
                                {formatPhoneNumber(phone.phone_number)}
                              </Box>
                            </VStack>
                          </HStack>
                        ))}
                      </Flex>
                    </RadioGroup>
                  </Flex>
                </Fieldset>
              )}
          </>
        )}
    </Flex>
  );
};
