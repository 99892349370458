import React, { useState } from 'react';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Box,
  Button,
  Divider,
  Flex,
  Text,
  VStack,
} from '@/shared/ui';

import { useChannels } from '../context/ChannelContext';

export const ChannelWhatsappSettings = () => {
  const { channelsState, importWhatsappTemplates } = useChannels();
  const { current } = channelsState;
  const [isLoading, setIsLoading] = useState(false);

  const handleImportTemplates = async () => {
    if (!current?.id) return;
    setIsLoading(true);
    try {
      await importWhatsappTemplates(current.id);
    } finally {
      setIsLoading(false);
    }
  };

  if (!current || current.provider !== 'meta') {
    return null;
  }

  return (
    <Accordion
      css={{ background: 'white' }}
      type="single"
      collapsible
      data-testid="whatsapp-settings"
    >
      <AccordionItem value="whatsapp-settings" variant="neumorphic">
        <Flex justify="between" align="center">
          <VStack gap={2} css={{ lineHeight: 1.5 }}>
            <Text css={{ fontWeight: 600 }}>WhatsApp Settings</Text>
            <Text>Manage WhatsApp specific settings for this channel</Text>
          </VStack>
          <AccordionTrigger />
        </Flex>
        <AccordionContent variant="neumorphic">
          <Divider css={{ mt: -20, mb: 20 }} />
          <VStack gap={2}>
            <Box>
              <Text css={{ mb: '$2' }}>
                Import or update WhatsApp message templates from Meta
              </Text>
              <Button
                onClick={handleImportTemplates}
                disabled={isLoading}
                data-testid="import-whatsapp-templates-button"
              >
                {isLoading ? 'Importing...' : 'Import Templates'}
              </Button>
            </Box>
          </VStack>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
