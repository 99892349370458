/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-key */
import React from 'react';
import { FaEllipsisV } from 'react-icons/fa';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Flex,
  IconButton,
} from '@/shared/ui';

export const TableActionMenu = ({
  disabled,
  width,
  buttonSize,
  children,
}: {
  children: JSX.Element;
  disabled?: boolean;
  width?: string | number;
  buttonSize?: string | number;
}) => {
  return (
    <Flex align="center" direction="column" css={{ width: '100%' }}>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger
          asChild
          style={{
            pointerEvents: !disabled ? 'auto' : 'none',
          }}
        >
          <IconButton
            data-testid="actions-trigger"
            disabled={disabled}
            variant="outline"
            css={{ width: buttonSize ?? 32, height: buttonSize ?? 32, fontSize: 12 }}
          >
            <FaEllipsisV />
          </IconButton>
        </DropdownMenuTrigger>
        {!!children.props.children.filter((c: boolean | JSX.Element) => c !== false)
          .length && (
          <DropdownMenuContent
            hideWhenDetached
            css={{
              width: width ?? 150,
              minWidth: width ?? 150,
              boxShadow: '0px 12px 60px 0px #0000000E',
            }}
            align="start"
            side="left"
            sideOffset={5}
          >
            {children}
          </DropdownMenuContent>
        )}
      </DropdownMenu>
    </Flex>
  );
};
