import { debounce, DebouncedFunc } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { handleFilterChange, handleSortChange } from '@/pages/data/utils/filterActions';
import { CombinedFilters } from '@/shared/components/filterBuilder/CombinedFilters';
import { default_contact_object } from '@/shared/components/filterBuilder/objects/contact';
import { PageLayout } from '@/shared/layouts/PageLayout';
import { SearchFilters } from '@/shared/types/contacts';
import { FilterType, Sort } from '@/shared/types/filter';
import { Box, Flex } from '@/shared/ui';

import { defaultCallsSort, initialState, useLLMAgent } from '../context/AgentsContext';
import {
  call_message_object,
  current_agent_version_object,
} from '../list/config/filterConfig';
import { TranscriptData } from './AgentCall';
import { CallsTable } from './CallsTable';
import { default_call_object } from './config/filterConfig';
import { sortConfig } from './config/sortConfig';

export const AgentCalls = () => {
  const [quickSearchValue, setQuickSearchValue] = useState('');
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);

  const agentContext = useLLMAgent();
  const { state, updateCallsFilters } = agentContext;
  const { calls, loading } = state;

  useEffect(() => {
    resetFilters();
  }, []);

  const resetFilters = () => {
    setQuickSearchValue('');
    setActiveFilters([]);
    updateCallsFilters({
      ...initialState.callsFilters,
      sort: defaultCallsSort,
    });
  };

  const debouncedUpdate = useCallback(
    debounce((props: SearchFilters) => {
      updateCallsFilters(props);
    }, 1000),
    []
  );

  const handleOffsetChange = (offset: number) => {
    updateCallsFilters({ ...state.callsFilters, offset });
  };

  const handleQuickSearch = (
    debouncedUpdate: DebouncedFunc<(props: SearchFilters) => void>,
    filters: SearchFilters,
    value: string
  ) => {
    setQuickSearchValue(value);
    const formattedValue = value.trim();
    const quickFilters = [
      {
        resource: 'contact',
        column: 'name',
        comparison: 'ilike',
        value: `%${formattedValue}%`,
      },
    ];

    debouncedUpdate({
      ...filters,
      searchFilter: quickFilters,
      offset: 0,
    });
  };

  const history = useHistory();

  const handleCallClick = (id: string) => {
    history.push(`/agents/calls/${id}`);
  };

  return (
    <PageLayout
      breadcrumbs={[
        { title: 'Agents', path: '/agents' },
        { title: 'Calls', path: '/agents/calls' },
      ]}
    >
      <Flex direction="column" css={{ flex: '1 1 auto', p: 30 }}>
        <Box css={{ paddingBottom: 24 }}>
          <CombinedFilters
            quickSearchPlaceholder="Search Calls"
            quickSearchValue={quickSearchValue}
            setQuickSearchValue={(value: string) =>
              handleQuickSearch(debouncedUpdate, state.callsFilters, value)
            }
            defaultObjects={[
              default_call_object,
              call_message_object,
              { ...current_agent_version_object, key: 'agent_version' },
              default_contact_object,
            ]}
            customObjects={[]}
            activeFilters={activeFilters}
            setFilters={(value: Array<FilterType>) => {
              setActiveFilters(value);
              handleFilterChange(debouncedUpdate, state.callsFilters, value);
            }}
            sortConfig={sortConfig}
            activeSort={state.callsFilters.sort}
            onSortUpdate={(value: Array<Sort>) => {
              handleSortChange(updateCallsFilters, state.callsFilters, value);
            }}
          />
        </Box>
        <CallsTable
          data={calls.data}
          loading={loading}
          totalCount={calls.total}
          onCardClick={handleCallClick}
          setOffset={handleOffsetChange}
        />
      </Flex>
    </PageLayout>
  );
};

// Take a transcript url and calculate the duration of the call
// Take a transcript url and calculate the duration of the call
export function CallDuration({ url }: { url: string }) {
  const [duration, setDuration] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setDuration(calculateDuration(data));
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [url]);

  const calculateDuration = (data: TranscriptData) => {
    let minStart = Infinity;
    let maxEnd = -Infinity;

    data.forEach((entry) => {
      if ('words' in entry) {
        // Check if entry has words. Remove tool invocations
        entry.words.forEach((word) => {
          if (word.start < minStart) {
            minStart = word.start;
          }
          if (word.end > maxEnd) {
            maxEnd = word.end;
          }
        });
      }
    });

    return Number((maxEnd - minStart).toFixed(0));
  };

  return <Box>{duration > 0 ? formatDuration(duration) : 'Unknown'}</Box>;
}

export const formatDuration = (duration: number) => {
  // Convert milliseconds to seconds first
  const totalSeconds = Math.floor(duration / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  if (minutes > 0) {
    return `${minutes}m ${seconds.toString().padStart(2, '0')}s`;
  }
  return `${seconds}s`;
};
