import { Call, Device } from '@twilio/voice-sdk';

export enum CallStatusTypes {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
  NEW_OUTGOING = 'new_outgoing',
  INCOMING_ACCEPTED = 'incoming_accepted',
}

export type VoIPStateType = {
  device: Device | null;
  incomingCalls: CallType[];
  calls: CallType[];
  current: CallType | null;
  callStatus: CallStatusTypes | null;
  defaultChannel?: string | null;
  onlineUsers: OnlineUsers;
};

export type CallType = {
  id?: string;
  call?: Call | null;
  callSid?: string | null;
  channelPhone?: string;
  recording?: boolean;
  recording_id?: string | null;
  participants?: Participant[];
};

export type Participant = {
  callSid?: string;
  phone?: string;
  name?: string;
  userId?: number;
  isOnHold?: boolean;
  isMute?: boolean;
  onDelete?: (phone?: string, userId?: number) => void;
  onHold?: (hold: boolean, phone?: string, userId?: number) => void;
  onMute?: (mute: boolean, phone?: string, userId?: number) => void;
};

export type OnlineUsers = {
  joins: Record<number, any>;
  leaves?: Record<number, any>;
};

export enum VoIPActionTypes {
  ADD_DEVICE = 'ADD_DEVICE',
  DESTROY_DEVICE = 'GET_CONTACTS',
  ADD_OUTGOING_CALL = 'ADD_OUTGOING_CALL',
  ACCEPT_OUTGOING_CALL = 'ACCEPT_OUTGOING_CALL',
  DESTROY_OUTGOING_CALL = 'DESTROY_OUTGOING_CALL',
  ADD_INCOMING_CALL = 'ADD_INCOMING_CALL',
  ACCEPT_INCOMING_CALL = 'ACCEPT_INCOMING_CALL',
  DESTROY_INCOMING_CALL = 'DESTROY_INCOMING_CALL',
  ADD_CALL_STATUS = 'ADD_CALL_STATUS',
  START_CALL_RECORDING = 'START_CALL_RECORDING',
  STOP_CALL_RECORDING = 'STOP_CALL_RECORDING',
  SET_DEFAULT_VOIP_CHANNEL = 'SET_DEFAULT_VOIP_CHANNEL',
  ADD_PARTICIPANT = 'ADD_PARTICIPANT',
  DELETE_PARTICIPANT = 'DELETE_PARTICIPANT',
  UPDATE_PARTICIPANT = 'UPDATE_PARTICIPANT',
  SET_USER_ONLINE = 'SET_USER_ONLINE',
}

export type VoIPActions =
  | {
      type: VoIPActionTypes.ADD_DEVICE;
      payload: Device;
    }
  | {
      type: VoIPActionTypes.DESTROY_DEVICE;
    }
  | {
      type: VoIPActionTypes.ADD_OUTGOING_CALL;
      payload: CallType;
    }
  | {
      type: VoIPActionTypes.ACCEPT_OUTGOING_CALL;
      payload: CallType;
    }
  | {
      type: VoIPActionTypes.DESTROY_OUTGOING_CALL;
      payload: string;
    }
  | {
      type: VoIPActionTypes.ADD_INCOMING_CALL;
      payload: CallType;
    }
  | {
      type: VoIPActionTypes.ACCEPT_INCOMING_CALL;
      payload: CallType;
    }
  | {
      type: VoIPActionTypes.DESTROY_INCOMING_CALL;
      payload: string;
    }
  | {
      type: VoIPActionTypes.ADD_CALL_STATUS;
      payload: CallStatusTypes | null;
    }
  | {
      type: VoIPActionTypes.START_CALL_RECORDING;
      payload: { recording_id: string; callSid: string };
    }
  | {
      type: VoIPActionTypes.STOP_CALL_RECORDING;
      payload: string;
    }
  | {
      type: VoIPActionTypes.SET_DEFAULT_VOIP_CHANNEL;
      payload: string;
    }
  | {
      type: VoIPActionTypes.ADD_PARTICIPANT;
      payload: { callSid: string; phone?: string; userId?: number };
    }
  | {
      type: VoIPActionTypes.DELETE_PARTICIPANT;
      payload: { callSid: string; phone?: string; userId?: number };
    }
  | {
      type: VoIPActionTypes.UPDATE_PARTICIPANT;
      payload: {
        callSid: string;
        isOnHold?: boolean;
        phone?: string;
        userId?: number;
        name?: string;
        isMute?: boolean;
      };
    }
  | {
      type: VoIPActionTypes.SET_USER_ONLINE;
      payload: OnlineUsers;
    };

export enum CallTypes {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
}

export enum VoIPWebsocketEventTypes {
  ONLINE_USERS = 'voice:online_users',
  PRESENCE_DIFF = 'presence_diff',
}
