import { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { SettingsLayout } from '@/shared/layouts';
import { DomainSetupType } from '@/shared/types/domains';
import { VStack } from '@/shared/ui';

import { useDomainsContext } from '../context/DomainsContext';
import { SetupProgress } from './StepProgress';

export const Domain = () => {
  const {
    domainsState: { current, loadingDomain },
    getDomain,
    deleteDomain,
    updateDomain,
  } = useDomainsContext();

  const params = useParams<{ id: string }>();
  const history = useHistory();
  const isDomainPage = useRouteMatch('/settings/domains/:id');

  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    if (params?.id && current?.id !== params?.id) {
      getDomain(params?.id);
    }
  }, [params?.id, current?.id]);

  const handleRefresh = useCallback(async () => {
    setRefreshing(true);
    await getDomain(params?.id);
    setRefreshing(false);
  }, [params?.id]);

  const handleDelete = useCallback(async () => {
    try {
      current?.id && (await deleteDomain(current?.id));
      isDomainPage && history.push(`/settings/domains`);
    } catch (error) {
      console.log('error', error);
    }
  }, [deleteDomain, current?.id]);

  const handleUpdate = async (type: DomainSetupType) => {
    await updateDomain({
      id: params?.id ?? '',
      setup_for: current?.setup_for.filter((value) => value !== type),
    });
  };

  return (
    <SettingsLayout
      width="100%"
      breadcrumbs={[
        { title: 'Settings', path: '/settings/domains' },
        { title: 'Domains', path: '/settings/domains' },
        {
          title: current?.name || '',
          path: `/settings/domains/${current?.id}`,
        },
      ]}
    >
      <VStack gap={4} css={{ width: '100%' }}>
        <SetupProgress
          title="Domain Setup"
          loadingDomain={loadingDomain}
          current={current}
          refreshing={refreshing}
          handleRefresh={handleRefresh}
          handleDelete={handleDelete}
          handleUpdate={handleUpdate}
        />
        {current?.setup_for.includes(DomainSetupType.LINKS) && (
          <SetupProgress
            title="Links Setup"
            type={DomainSetupType.LINKS}
            loadingDomain={loadingDomain}
            current={current}
            refreshing={refreshing}
            handleRefresh={handleRefresh}
            handleDelete={handleDelete}
            handleUpdate={handleUpdate}
          />
        )}
        {current?.setup_for.includes(DomainSetupType.EMAILS) && (
          <SetupProgress
            title="Email Setup"
            type={DomainSetupType.EMAILS}
            loadingDomain={loadingDomain}
            current={current}
            refreshing={refreshing}
            handleRefresh={handleRefresh}
            handleDelete={handleDelete}
            handleUpdate={handleUpdate}
          />
        )}
      </VStack>
    </SettingsLayout>
  );
};
