import React from 'react';
import { HiChevronDown } from 'react-icons/hi';

import { useAuth } from '@/auth/context/AuthProvider';
import { useUserWebNotifications } from '@/shared/components/notifications/context/UserWebNotificationsContext';
import { NotificationsStatus } from '@/shared/types/notifications';
import {
  Badge,
  Box,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuItemWarning,
  DropdownMenuTrigger,
} from '@/shared/ui';

const NotificationsBulkActions = () => {
  const { bulkMarkAllNotifications } = useUserWebNotifications();
  const auth = useAuth();
  return (
    <Box css={{ height: 'fit-content' }}>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <Badge size="2" interactive css={{ textTransform: 'capitalize' }}>
            <span>Actions</span>
            <Box css={{ ml: 5 }}>
              <HiChevronDown />
            </Box>
          </Badge>
        </DropdownMenuTrigger>
        <DropdownMenuContent sideOffset={4} align="end">
          <DropdownMenuItem
            onClick={(event) =>
              bulkMarkAllNotifications(
                NotificationsStatus.READ,
                {
                  notification_selection: {
                    organization_ids: auth.organizations.map(
                      (organization) => organization.id
                    ),
                    user_id: auth.tokens?.user_id,
                    status: ['unread'],
                  },
                },
                event
              )
            }
          >
            <span>Mark All As Read</span>
          </DropdownMenuItem>
          <DropdownMenuItemWarning
            onClick={(event) =>
              bulkMarkAllNotifications(
                NotificationsStatus.ARCHIVED,
                {
                  notification_selection: {
                    organization_ids: auth.organizations.map(
                      (organization) => organization.id
                    ),
                    user_id: auth.tokens?.user_id,
                    status: ['read', 'unread'],
                  },
                },
                event
              )
            }
          >
            <span>Archive All</span>
          </DropdownMenuItemWarning>
        </DropdownMenuContent>
      </DropdownMenu>
    </Box>
  );
};

export default NotificationsBulkActions;
