/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';

import { useChannels } from '@/pages/settings/organization/channels/context/ChannelContext';
import { DateSelector } from '@/shared/components/datepicker/DateSelector';
import { MultiSelect } from '@/shared/components/MultiSelect';
import { ConversationScheduledMessageType } from '@/shared/types/conversations';
import { Box, Flex, Text, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import { ConversationHeaderContainer } from '../conversation/header';
import ScheduledMessageState, {
  useScheduledMessages,
} from './context/ScheduledMessagesContext';
import { LoadingScheduledMessage, ScheduledMessage } from './ScheduledMessage';

export const ScheduledMessagesList = () => {
  const scheduled = useScheduledMessages();
  const { getPartOfScheduledMessages } = scheduled;
  const { scheduledMessages, loading } = scheduled.scheduledMessagesState;

  const {
    channelsState: { channels },
  } = useChannels();

  // map locations to multi select
  const [selectedLocations, setSelectedLocations] = useState<{ locations: string[] }>({
    locations: channels.map((channel) => channel.id),
  });

  // prevents locations not being selected on refresh
  useEffect(() => {
    // check if selectedLocations.locations and locations.length are different
    if (selectedLocations.locations.length !== channels.length) {
      // only update the state if it is different from the previous state (prevents extra re-renders)
      setSelectedLocations((prevState) => {
        if (
          JSON.stringify(prevState.locations) ===
          JSON.stringify(channels.map((channel) => channel.id))
        ) {
          return prevState;
        }
        return { locations: channels.map((channel) => channel.id) };
      });
    }
  }, [channels]);

  // set the initial start date of the datepicker to the last 30 days
  // set the initial end date of the datepicker to the current date
  const [dates, setDates] = useState({
    startDate: moment(),
    endDate: moment().add(30, 'day'),
  });

  const [offset, setOffset] = useState<number>(0);

  const getScheduledMessages = (
    offset: number,
    locations: Array<string>,
    startingDate: moment.Moment | string,
    endingDate: moment.Moment | string
  ) => {
    getPartOfScheduledMessages({
      start_date: moment(startingDate).format('YYYY-MM-DD'),
      end_date:
        endingDate === 'Invalid date'
          ? moment().format('YYYY-MM-DD') // if endDate is invalid, set it to today's date
          : moment(endingDate).format('YYYY-MM-DD'),
      location_ids: locations,
      limit: 50,
      offset: offset || 0,
    });
  };

  const handleSetDate = (date: { startDate: moment.Moment; endDate: moment.Moment }) => {
    setOffset(0);
    setDates(date);
    getScheduledMessages(0, selectedLocations.locations, date.startDate, date.endDate);
  };

  const handleSetSelectedLocations = ({ locations }: { locations: string[] }) => {
    setOffset(0);
    getScheduledMessages(0, locations, dates.startDate, dates.endDate);
    setSelectedLocations({ locations: locations });
  };

  const orderedScheduledMessages = scheduledMessages.sort((a, b) => {
    return (a?.execution_time || '') > (b?.execution_time || '') ? 1 : -1;
  });

  return (
    <ScheduledMessagesContainer direction="column">
      <VStack css={{ width: '100%', mb: 20, pr: 20 }} gap="2">
        <Flex justify="between">
          {channels.length > 0 && (
            <Box css={{ width: '25%' }}>
              <MultiSelect
                defaultPlaceholder="Channels"
                defaultSelectedItems={selectedLocations.locations}
                isDropdown={true}
                options={channels.map((channel) => ({
                  type: channel?.name || '',
                  value: channel.id,
                }))}
                setParentSelectedItems={handleSetSelectedLocations}
                isCampaigns={false}
              />
            </Box>
          )}
          <Box>
            <DateSelector
              dates={dates}
              setDates={handleSetDate}
              minDate={dayjs(new Date()).format('YYYY-MM-DD')}
              align="end"
            />
          </Box>
        </Flex>
      </VStack>
      {scheduledMessages.length === 0 && !loading && (
        <EmptyStateContainer>
          <ScheduledMessagesEmptyState />
        </EmptyStateContainer>
      )}
      {loading && (
        <VStack>
          {Array.from({ length: 5 }, (_, k) => (
            <LoadingScheduledMessage key={k} />
          ))}
        </VStack>
      )}
      {orderedScheduledMessages.length > 0 && (
        <Virtuoso
          data={orderedScheduledMessages}
          atBottomStateChange={(atBottom) => {
            if (atBottom && orderedScheduledMessages.length >= 50) {
              setOffset(offset + orderedScheduledMessages?.length);
              getScheduledMessages(
                offset + orderedScheduledMessages?.length || 0,
                selectedLocations?.locations || [],
                dates.startDate,
                dates.endDate
              );
            }
          }}
          itemContent={(i: number, message: ConversationScheduledMessageType) => (
            <ScheduledMessage
              id={message.job_id}
              key={`scheduled_message_${i}`}
              location_id={message.location || ''}
              user_id={message.user_id || 0}
              message={message.message || ''}
              schedule={message.execution_time || ''}
              conversation_id={message.conversation_id || ''}
              // fallback to browser timezone if timezone is not set
              timezone={
                message.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone || ''
              }
              contact={message.contact}
            />
          )}
        />
      )}
    </ScheduledMessagesContainer>
  );
};

export const ScheduledMessages = () => {
  return (
    <ScheduledMessageState>
      <Flex direction="column" css={{ position: 'relative', flexGrow: 1 }}>
        <ConversationHeaderContainer align="center">
          <Text css={{ fontWeight: 600 }}>Scheduled Messages</Text>
        </ConversationHeaderContainer>
        <ScheduledMessagesList />
      </Flex>
    </ScheduledMessageState>
  );
};

export const ScheduledMessagesEmptyState = () => {
  return (
    <ScheduledMessagesContainer align="center" justify="center" direction="column">
      <Text css={{ fontWeight: 600 }}>Scheduled Messages</Text>
      <Text css={{ mt: 10 }}>
        Your scheduled messages will appear here when your create one.
      </Text>
    </ScheduledMessagesContainer>
  );
};

const ScheduledMessagesContainer = styled(Flex, {
  pl: 20,
  pt: 20,
  width: '100%',
  minWidth: '100%',
  height: '100%',
});

const EmptyStateContainer = styled(Flex, {
  p: 20,
  height: '100%',
  width: '100%',
});
