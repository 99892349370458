import { Formik } from 'formik';
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { MultiStepForm } from '@/settings/organization/channels/utils/MultiStepForm';
import {
  campaignValidation,
  editSteps,
  validateEditCampaignStep,
} from '@/settings/organization/trust/campaigns/createCampaignView';
import { useTenDlc } from '@/settings/organization/trust/context/TenDlcContext';
import { editCampaign } from '@/shared/api/tendlc';
import { SettingsLayout } from '@/shared/layouts';
import { CampaignData } from '@/shared/types/trust/campaigns';
import { Flex } from '@/shared/ui';

import { TrustLayout } from '../../TrustLayout';

const breadcrumbs = [
  { title: 'Settings', path: '/settings/trust/campaigns' },
  { title: 'Trust Center', path: `/settings/trust/campaigns` },
  { title: 'Campaigns', path: `/settings/trust/campaigns` },
  { title: 'Edit Campaign', path: `/settings/trust/campaigns/:id` },
];

export const EditCampaign = () => {
  const history = useHistory();
  const tenDlcContext = useTenDlc();
  const { tenDlcState, getCampaign } = tenDlcContext;
  const { campaign, loading } = tenDlcState;
  const { id: campaignId } = useParams<{ id: string }>();
  const { state } = useLocation<{ brandId: string }>();

  useEffect(() => {
    getCampaign(state?.brandId, campaignId);
  }, []);

  const normalizeValues = (values: CampaignData): CampaignData => {
    return Object.keys(values).reduce((acc, key) => {
      const typedKey = key as keyof CampaignData;
      const value = values[typedKey];

      return {
        ...acc,
        [typedKey]: value === null ? '' : value,
        messageSample1: values?.message_samples[0],
        messageSample2: values?.message_samples[1],
        messageSample3: values?.message_samples[2],
      };
    }, {} as CampaignData);
  };

  const handleComplete = async (campaignData: CampaignData) => {
    const { brand, messageSample1, messageSample2, messageSample3, ...rest } =
      campaignData;
    const messageSamples = [messageSample1, messageSample2, messageSample3]?.filter(
      Boolean
    );

    const campaignPayload = {
      ...rest,
      message_samples: messageSamples,
    };

    try {
      await editCampaign(state?.brandId, campaignId, campaignPayload);
      history.push('/settings/trust/campaigns');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <TrustLayout>
      <SettingsLayout
        background="white"
        padding="0"
        width="100%"
        breadcrumbs={breadcrumbs}
      >
        <Flex css={{ p: 30 }} direction="column">
          {campaign && !loading && (
            <Formik
              initialValues={normalizeValues(campaign)}
              validationSchema={campaignValidation}
              onSubmit={(values) => handleComplete(values)}
            >
              {(formikProps) => (
                <form onSubmit={(e) => e.preventDefault()}>
                  <MultiStepForm
                    steps={editSteps}
                    onComplete={formikProps?.handleSubmit}
                    formData={formikProps?.values}
                    onStepValidation={validateEditCampaignStep}
                    onCompleteText="Update Campaign"
                  />
                </form>
              )}
            </Formik>
          )}
        </Flex>
      </SettingsLayout>
    </TrustLayout>
  );
};
