export const stateList = [
  { value: 'AK', type: 'AK' },
  { value: 'AL', type: 'AL' },
  { value: 'AR', type: 'AR' },
  { value: 'AZ', type: 'AZ' },
  { value: 'CA', type: 'CA' },
  { value: 'CO', type: 'CO' },
  { value: 'CT', type: 'CT' },
  { value: 'DC', type: 'DC' },
  { value: 'DE', type: 'DE' },
  { value: 'FL', type: 'FL' },
  { value: 'GA', type: 'GA' },
  { value: 'HI', type: 'HI' },
  { value: 'IA', type: 'IA' },
  { value: 'ID', type: 'ID' },
  { value: 'IL', type: 'IL' },
  { value: 'IN', type: 'IN' },
  { value: 'KS', type: 'KS' },
  { value: 'KY', type: 'KY' },
  { value: 'LA', type: 'LA' },
  { value: 'MA', type: 'MA' },
  { value: 'MD', type: 'MD' },
  { value: 'ME', type: 'ME' },
  { value: 'MI', type: 'MI' },
  { value: 'MN', type: 'MN' },
  { value: 'MO', type: 'MO' },
  { value: 'MS', type: 'MS' },
  { value: 'MT', type: 'MT' },
  { value: 'NC', type: 'NC' },
  { value: 'ND', type: 'ND' },
  { value: 'NE', type: 'NE' },
  { value: 'NH', type: 'NH' },
  { value: 'NJ', type: 'NJ' },
  { value: 'NM', type: 'NM' },
  { value: 'NV', type: 'NV' },
  { value: 'NY', type: 'NY' },
  { value: 'OH', type: 'OH' },
  { value: 'OK', type: 'OK' },
  { value: 'OR', type: 'OR' },
  { value: 'PA', type: 'PA' },
  { value: 'PR', type: 'PR' },
  { value: 'RI', type: 'RI' },
  { value: 'SC', type: 'SC' },
  { value: 'SD', type: 'SD' },
  { value: 'TN', type: 'TN' },
  { value: 'TX', type: 'TX' },
  { value: 'UT', type: 'UT' },
  { value: 'VA', type: 'VA' },
  { value: 'VT', type: 'VT' },
  { value: 'WA', type: 'WA' },
  { value: 'WV', type: 'WV' },
  { value: 'WI', type: 'WI' },
  { value: 'WY', type: 'WY' },
];

export const verticalList = [
  { value: 'AGRICULTURE', type: 'Agriculture' },
  { value: 'AUTOMOTIVE', type: 'Automotive' },
  { value: 'BANKING', type: 'Banking' },
  { value: 'CONSTRUCTION', type: 'Construction' },
  { value: 'CONSUMER', type: 'Consumer' },
  { value: 'EDUCATION', type: 'Education' },
  { value: 'ELECTRONICS', type: 'Electronics' },
  { value: 'ENGINEERING', type: 'Engineering' },
  { value: 'ENERGY', type: 'Energy' },
  { value: 'FAST_MOVING_CONSUMER_GOODS', type: 'Fast Moving Consumer Goods' },
  { value: 'FINANCIAL', type: 'Financial' },
  { value: 'FINTECH', type: 'Fintech' },
  { value: 'FOOD_AND_BEVERAGE', type: 'Food and Beverage' },
  { value: 'GOVERNMENT', type: 'Government' },
  { value: 'HEALTHCARE', type: 'Healthcare' },
  { value: 'HOSPITALITY', type: 'Hospitality' },
  { value: 'INSURANCE', type: 'Insurance' },
  { value: 'JEWELRY', type: 'Jewelry' },
  { value: 'LEGAL', type: 'Legal' },
  { value: 'MANUFACTURING', type: 'Manufacturing' },
  { value: 'MEDIA', type: 'Media' },
  { value: 'NOT_FOR_PROFIT', type: 'Not for Profit' },
  { value: 'OIL_AND_GAS', type: 'Oil and Gas' },
  { value: 'ONLINE', type: 'Online' },
  { value: 'PROFESSIONAL_SERVICES', type: 'Professional Services' },
  { value: 'RAW_MATERIALS', type: 'Raw Materials' },
  { value: 'REAL_ESTATE', type: 'Real Estate' },
  { value: 'RELIGION', type: 'Religion' },
  { value: 'RETAIL', type: 'Retail' },
  { value: 'TECHNOLOGY', type: 'Technology' },
  { value: 'TELECOMMUNICATIONS', type: 'Telecommunications' },
  { value: 'TRANSPORTATION', type: 'Transportation' },
  { value: 'TRAVEL', type: 'Travel' },
];

export const businessTypes = [
  { value: 'CORPORATION', type: 'Corporation' },
  { value: 'LIMITED_LIABILITY_CORPORATION', type: 'Limited Liability Corporation' },
  { value: 'SOLE_PROPRIETORSHIP', type: 'Sole Proprietorship' },
  { value: 'PARTNERSHIP', type: 'Partnership' },
  { value: 'CO_OPERATIVE', type: 'Co-operative' },
  { value: 'NON_PROFIT_CORPORATION', type: 'Non-profit Corporation' },
];

export const brandRelationship = [
  { value: 'BASIC_ACCOUNT', type: 'Basic Account' },
  { value: 'SMALL_ACCOUNT', type: 'Small Account' },
  { value: 'MEDIUM_ACCOUNT', type: 'Medium Account' },
  { value: 'LARGE_ACCOUNT', type: 'Large Account' },
  { value: 'KEY_ACCOUNT', type: 'Key Account' },
];

export const businessUseCase = [
  'Identify & Verification',
  'Asset Management',
  'Lead Generation',
  'Intelligent Routing',
  'Appointment Scheduling',
  'Customer Support',
  'Self-Service',
  'Automated Support',
  'Appointment Reminders',
  'Employee Notifications',
  'Delivery Notifications',
  'Emergency Notifications',
  'Contactless Delivery',
  'Order Notifications',
  'Service Alerts',
  'Purchase Confirmation',
  'Mass Alerts',
  'Fraud Alerts',
  'Contact Tracing',
  'Lead Management',
  'Lead Nurturing',
  'Telemarketing',
  'Marketing Events',
  'Rewards Program',
  'Lead Alerts',
  'Lead Distribution',
  'Abandoned Cart',
  'Call Tracking',
  'Outbound Dialer',
  'Click to Call',
  'Phone System',
  'Meetings/Collaboration',
  'Distance Learning',
  'Shift Management',
  'Field Notifications',
  'Dating/Social',
  'Remote appointments',
  'Group Messaging',
  'Exam Proctoring',
  'Tutoring',
  'Therapy (Individual+Group)',
  'Pharmacy',
  'First Responder',
  'Survey/Research',
];

export const representativeJobPositions = [
  { value: 'DIRECTOR', type: 'Director' },
  { value: 'GM', type: 'GM' },
  { value: 'VP', type: 'VP' },
  { value: 'CEO', type: 'CEO' },
  { value: 'CFO', type: 'CFO' },
  { value: 'GENERAL_COUNSEL', type: 'General Counsel' },
  { value: 'OTHER', type: 'Other' },
];

export const businessRegistrationTypes = [
  { value: 'EIN', type: 'EIN' },
  { value: 'DUNS', type: 'DUNS' },
  { value: 'LEI', type: 'LEI' },
  { value: 'GIIN', type: 'GIIN' },
];

export const altBusinessRegistrationTypes = [
  { value: 'NONE', type: 'None' },
  { value: 'EIN', type: 'EIN' },
  { value: 'DUNS', type: 'DUNS' },
  { value: 'LEI', type: 'LEI' },
  { value: 'GIIN', type: 'GIIN' },
];

export const businessIdentities = [
  {
    type: 'Reseller',
    value: 'RESELLER',
  },
  {
    type: 'Direct Customer',
    value: 'DIRECT_CUSTOMER',
  },
];

export const businessRegionsOfOperation = [
  {
    type: 'USA & Canada',
    value: 'USA_AND_CANADA',
  },
  {
    type: 'Latin America',
    value: 'LATIN_AMERICA',
  },
  {
    type: 'Europe',
    value: 'EUROPE',
  },
  {
    type: 'Asia',
    value: 'ASIA',
  },
  {
    type: 'Africa',
    value: 'AFRICA',
  },
];
