import { SearchFilters } from '../contacts';
import { FilterTypeInputType } from '../filter';

export type CustomDataState = {
  customObjects: Array<CustomObject>;
  totalCount: number;
  propertiesTotalCount: number;
  current: CustomObject | null;
  properties: Array<CustomProperty>;
  error?: any;
  loading: boolean;
  loadingProperties: boolean;
  filterParams: SearchFilters;
  customPropertiesFilterParams: SearchFilters;
};

export type CustomObject = {
  custom_properties: Array<CustomProperty>;
  id: string;
  inserted_at: string;
  key: string;
  label: string;
  updated_at: string;
  associated_resource_type?: CustomResource;
};

export type CustomProperty = {
  custom_object_id: string;
  default?: any;
  id: string;
  inserted_at: string;
  key: string;
  label: string;
  required: boolean;
  type: PropertyType;
  updated_at: string;
  options?: Array<{ label: string; value: string | number; disabled?: boolean }>;
  references: Array<ReferencePropertyType>;
};

/**
 * This is a UI representation/translation of a Whippy Resource and the columns
 * that the resource supports.
 *
 *
 * @example
 For example, if we wanted to represent a Contact resource that supports the name column
 * ```tsx
  {
  id: 'unique-id-for-contact',
  label: 'Contact',
  key: 'contact',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'contact-name',
      label: 'Name',
      type: 'text',
      key: 'name',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    ]
  }
 * ```
*/
export type DefaultObject = {
  custom_properties: Array<DefaultProperty>;
  id: string;
  inserted_at: string;
  key: string;
  label: string;
  updated_at: string;
  associated_resource_type?: CustomResource;
};

export type DefaultProperty = {
  custom_object_id: string;
  default?: any;
  id: string;
  inserted_at: string;
  key: string;
  label: string;
  required: boolean;
  type: PropertyType | string | FilterTypeInputType;
  updated_at: string;
  options?: Array<{ label: string; value: string | number; disabled?: boolean }>;
};

export type CustomObjectRecord = {
  associated_resource_id: string;
  associated_resource_type: string;
  custom_object_id: string;
  id: string;
  inserted_at: string;
  key: string;
  label: string;
  properties: Record<string, string | number | null>;
  updated_at: string;
  created_by_id: number | null;
  updated_by_id: number | null;
};

export enum PropertyType {
  TEXT = 'text',
  NUMBER = 'number',
  FLOAT = 'float',
  BOOLEAN = 'boolean',
  DATE = 'date',
  LIST = 'list',
  MAP = 'map',
}

export enum ReferenceTypes {
  MANY_TO_ONE = 'many_to_one',
  MANY_TO_MANY = 'many_to_many',
  ONE_TO_ONE = 'one_to_one',
  ONE_TO_MANY = 'one_to_many',
}

export type CustomRecord = {
  associated_resource_id: string;
  associated_resource_type: CustomResource;
  custom_object_id: string;
  id: string;
  inserted_at: string;
  key: string;
  label: string;
  properties: string;
  updated_at: string;
};

export type CustomObjectParams = {
  key: string;
  label: string;
  custom_properties?: Array<CustomProperty>;
};

export type UpdateCustomObjectParams = {
  label: string;
};

export type CustomPropertyParams = {
  key: string;
  label: string;
  default: any;
  type?: PropertyType;
  required: boolean;
};

export type CustomRecordParams = {
  associated_resource_id: string;
  associated_resource_type: CustomResource;
  custom_object: Array<CustomProperty>;
};

export enum CustomDataActionsType {
  GET_CUSTOM_OBJECTS = 'GET_CUSTOM_OBJECTS',
  GET_CUSTOM_OBJECT = 'GET_CUSTOM_OBJECT',
  ADD_CUSTOM_OBJECT = 'ADD_CUSTOM_OBJECT',
  UPDATE_CUSTOM_OBJECT = 'UPDATE_CUSTOM_OBJECT',
  DELETE_CUSTOM_OBJECT = 'DELETE_CUSTOM_OBJECT',
  ADD_CUSTOM_OBJECT_PROPERTY = 'ADD_CUSTOM_OBJECT_PROPERTY',
  UPDATE_CUSTOM_OBJECT_PROPERTY = 'UPDATE_CUSTOM_OBJECT_PROPERTY',
  DELETE_CUSTOM_OBJECT_PROPERTY = 'DELETE_CUSTOM_OBJECT_PROPERTY',
  GET_CUSTOM_OBJECT_PROPERTIES = 'GET_CUSTOM_OBJECT_PROPERTIES',
  ADD_CUSTOM_OBJECT_RECORDS = 'ADD_CUSTOM_OBJECT_RECORDS',
  SET_ERROR = 'SET_ERROR',
  SET_CURRENT = 'SET_CURRENT',
  CLEAR_CURRENT = 'CLEAR_CURRENT',
  CLEAR_PROPERTIES = 'CLEAR_PROPERTIES',
  UPDATE_FILTER_PARAMS = 'UPDATE_FILTER_PARAMS',
  UPDATE_CUSTOM_OBJECTS_FILTER_PARAMS = 'UPDATE_CUSTOM_OBJECTS_FILTER_PARAMS',
}

export enum CustomResource {
  CONTACT = 'contact',
  CONVERSATION = 'conversation',
  MESSAGE = 'message',
  TAG = 'tag',
}

export type PropertyFormType = {
  label: string;
  type: PropertyType;
  default: any;
  required: boolean;
  references: Array<ReferencePropertyType>;
};

export type ReferencePropertyType = {
  custom_object_id: string;
  custom_property_id: string;
  type: string;
  id?: string;
  delete?: boolean;
};

export type CustomDataActions =
  | {
      type: CustomDataActionsType.GET_CUSTOM_OBJECTS;
      payload: { data: Array<CustomObject>; total: number };
    }
  | { type: CustomDataActionsType.GET_CUSTOM_OBJECT; payload: CustomObject | null }
  | { type: CustomDataActionsType.ADD_CUSTOM_OBJECT; payload: CustomObject }
  | { type: CustomDataActionsType.UPDATE_CUSTOM_OBJECT; payload: CustomObject }
  | { type: CustomDataActionsType.DELETE_CUSTOM_OBJECT; payload: string }
  | { type: CustomDataActionsType.ADD_CUSTOM_OBJECT_PROPERTY; payload: CustomProperty }
  | { type: CustomDataActionsType.UPDATE_CUSTOM_OBJECT_PROPERTY; payload: CustomProperty }
  | { type: CustomDataActionsType.DELETE_CUSTOM_OBJECT_PROPERTY; payload: string }
  | {
      type: CustomDataActionsType.GET_CUSTOM_OBJECT_PROPERTIES;
      payload: { data: Array<CustomProperty>; total: number };
    }
  | { type: CustomDataActionsType.ADD_CUSTOM_OBJECT_RECORDS; payload: CustomRecord }
  | { type: CustomDataActionsType.SET_CURRENT; payload: CustomObject | null }
  | { type: CustomDataActionsType.CLEAR_CURRENT }
  | { type: CustomDataActionsType.CLEAR_PROPERTIES }
  | { type: CustomDataActionsType.SET_ERROR; payload: any }
  | { type: CustomDataActionsType.UPDATE_FILTER_PARAMS; payload: SearchFilters }
  | {
      type: CustomDataActionsType.UPDATE_CUSTOM_OBJECTS_FILTER_PARAMS;
      payload: SearchFilters;
    };

export type RowValueType = {
  [key: string]: string | number | null;
};
