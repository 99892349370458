export const default_custom_data_object = {
  id: 'unique-id-for-contact',
  label: 'Custom Data',
  key: 'custom_data_type',
  inserted_at: 'current-timestamp',
  updated_at: 'current-timestamp',
  custom_properties: [
    {
      default: '',
      id: 'custom_object-name',
      label: 'Name',
      type: 'text',
      key: 'label',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: 'current-timestamp',
      id: 'custom_object-inserted_at',
      label: 'Inserted At',
      type: 'date',
      key: 'inserted_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
    {
      default: 'current-timestamp',
      id: 'custom_object-updated_at',
      label: 'Updated At',
      type: 'date',
      key: 'updated_at',
      required: true,
      inserted_at: 'current-timestamp',
      updated_at: 'current-timestamp',
      custom_object_id: 'unique-id-for-contact',
    },
  ],
};
