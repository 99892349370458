import React from 'react';
import { Virtuoso } from 'react-virtuoso';

import { CurrentFilters } from '@/pages/campaigns/hooks/useFilterCampaigns';
import { Campaign } from '@/shared/types/campaigns';
import { Box, Flex, Text, VStack } from '@/shared/ui';
import { styled } from '@/stitches.config';

import CampaignCard from '../list/CampaignCard';
import CampaignLoadingCard from '../list/CampaignLoadingCard';

type ListEmailCampaignCardsProp = {
  increaseOffsetCoefficient: () => void;
  isCampaignsListUpdating: boolean;
  totalCount: number;
  isLoading: boolean;
  /** this handles the logic that keeps track of how many campaigns
   * we have loaded and how many total campaigns there are. When we need
   * to there is more data to load this will be true
   */
  hasMoreData: boolean;
  /** the condition as to when to show the loading more data footer.
   * This is a combination of do we need to load more data and are
   * we still in the process of loading
   */
  showLoadingMore: boolean;
  campaigns: Campaign[];
  filters: CurrentFilters;
  didFiltersChange: boolean;
  campaignsRef: React.MutableRefObject<HTMLDivElement | null>;
  filteredCampaigns: Campaign[];
};

const ListEmailCampaignCards = ({
  increaseOffsetCoefficient,
  isCampaignsListUpdating,
  hasMoreData,
  showLoadingMore,
  isLoading,
  campaigns,
  filters,
  didFiltersChange,
  campaignsRef,
  filteredCampaigns,
}: ListEmailCampaignCardsProp) => {
  return (
    <ListContainer ref={campaignsRef} gap={1}>
      {/** Loading state - don't show the loading state when the request is running but we already have some campaigns in state */}
      {(campaigns.length === 0 ||
        (filters.title === '' &&
          filters.locations.length === 0 &&
          filters.users.length === 0)) &&
        !didFiltersChange &&
        isLoading &&
        Array.from({ length: 20 }, (_: any, k: React.Key | null | undefined) => (
          <ItemContainer key={k}>
            <CampaignLoadingCard key={k} />
          </ItemContainer>
        ))}

      {/** Empty state - show the empty state when the request is finished and we don't have any campaigns in state that match the filters */}
      {!didFiltersChange &&
        !isLoading &&
        campaigns.length === 0 &&
        !isCampaignsListUpdating && (
          <>
            {filters.title !== '' ||
            filters.locations.length !== 0 ||
            filters.users.length !== 0 ? (
              <SearchedCampaignEmptyState />
            ) : (
              <CampaignEmptyState />
            )}
          </>
        )}
      {(!isLoading || didFiltersChange) && filteredCampaigns.length > 0 && (
        <Virtuoso
          data={filteredCampaigns}
          atBottomStateChange={(isAtBottom) => {
            if (isAtBottom && hasMoreData) {
              increaseOffsetCoefficient();
            }
          }}
          itemContent={(_index: number, campaign: Campaign) => (
            <ItemContainer key={campaign.id}>
              <CampaignCard key={campaign.id} campaign={campaign} />
            </ItemContainer>
          )}
          totalCount={filteredCampaigns.length}
          components={{
            Footer: () =>
              showLoadingMore ? (
                <ItemContainer>
                  <CampaignLoadingCard />
                </ItemContainer>
              ) : (
                <Box css={{ pb: 10 }} />
              ),
          }}
        />
      )}
    </ListContainer>
  );
};
export const SearchedCampaignEmptyState = () => {
  return (
    <EmptyStateContainer align="center" justify="center" direction="column">
      <Text css={{ fontWeight: 600 }}>No Campaign Matches Your Search</Text>
      <Text css={{ mt: 10 }}>Please try a different filter</Text>
    </EmptyStateContainer>
  );
};

export const CampaignEmptyState = () => {
  return (
    <EmptyStateContainer align="center" justify="center" direction="column">
      <Text css={{ fontWeight: 600 }}>No Campaigns</Text>
      <Text css={{ mt: 10 }}>{`Click "Create Campaign" to get started`}</Text>
    </EmptyStateContainer>
  );
};

const EmptyStateContainer = styled(Flex, {
  p: 20,
  mb: 20,
  height: '100%',
  width: '100%',
});

const ListContainer = styled(VStack, {
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'auto',
  pb: 0,
});

const ItemContainer = styled(Box, {
  px: 30,
  pb: 10,
});

export default ListEmailCampaignCards;
