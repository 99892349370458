import {
  CustomObject,
  CustomObjectRecord,
  CustomProperty,
  CustomPropertyParams,
  CustomRecordParams,
  UpdateCustomObjectParams,
} from '@/shared/types/data';
import { FilterData, FilterItem, Sort } from '@/shared/types/filter';

import { api } from '../api';

export const getCustomObjects = async (
  params: FilterData
): Promise<{ data: Array<CustomObject>; total: number }> => {
  const { data } = await api.post(`v2/custom_objects/search`, { ...params });
  return data;
};

export const createCustomObject = async (custom_object: {
  key?: string;
  label: string;
  custom_properties?: Array<CustomProperty>;
}): Promise<{ data: CustomObject }> => {
  const { data } = await api.post(`v2/custom_objects`, { custom_object });
  return data as { data: CustomObject };
};

export const updateCustomObject = async (
  id: string,
  params: UpdateCustomObjectParams
) => {
  const { data } = await api.put(`v2/custom_objects/${id}`, { custom_object: params });
  return data;
};

export const getCustomObject = async (id: string) => {
  const { data } = await api.get(`v2/custom_objects/${id}`);
  return data;
};

export const deleteCustomObject = async (id: string) => {
  const data = await api.delete(`v2/custom_objects/${id}`);
  return data;
};

export const createCustomObjectProperty = async (
  id: string,
  custom_property: CustomPropertyParams
) => {
  const { data } = await api.post(`v2/custom_objects/${id}/properties`, {
    custom_property,
  });
  return data;
};

export const updateCustomProperty = async (
  id: string,
  property_id: string,
  custom_property: CustomPropertyParams
) => {
  const { data } = await api.put(`v2/custom_objects/${id}/properties/${property_id}`, {
    custom_property,
  });
  return data;
};

export const deleteCustomProperty = async (id: string, propertyId: string) => {
  const data = await api.delete(`v2/custom_objects/${id}/properties/${propertyId}`);
  return data;
};

export const getCustomProperties = async (id: string, params: any) => {
  const { data } = await api.post(`v2/custom_objects/${id}/properties/search`, {
    ...params,
  });
  return data;
};

export const createCustomObjectRecord = async (
  id: string,
  custom_object_record: CustomRecordParams
) => {
  const { data } = await api.post(`v2/custom_objects/${id}/records`, {
    custom_object_record,
  });
  return data;
};

export const getCustomObjectRecords = async (
  id: string,
  limit: number,
  offset: number,
  filter?: Array<FilterItem | []>,
  sort?: Array<Sort | []>
) => {
  const { data } = await api.post(`v2/custom_objects/${id}/records/search/results`, {
    limit,
    offset,
    filter,
    sort,
  });

  return { data: data.data } as { data: Array<CustomObjectRecord> };
};

export const getCustomObjectRecordsCount = async (
  id: string,
  limit: number,
  offset: number,
  filter?: Array<FilterItem | []>,
  sort?: Array<Sort | []>
) => {
  const { data } = await api.post(`v2/custom_objects/${id}/records/search/count`, {
    limit,
    offset,
    filter,
    sort,
  });

  return { total: data.total } as { total: number };
};
