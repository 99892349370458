import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Flex } from '@/shared/ui';
import { styled } from '@/stitches.config';

export enum ResizeDirection {
  TOP_LEFT = 'TOP_LEFT',
  TOP_RIGHT = 'TOP_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
}

export const VoIPDialog = ({
  resizable,
  defaultSize,
  children,
  header,
  defaultStyles,
  sidePanel,
}: {
  resizable?: boolean;
  defaultSize: { width: number; height: number };
  defaultStyles?: {
    minWidth?: number;
    minHeight?: number;
    maxWidth?: number;
    maxHeight?: number;
  };
  children: React.ReactNode;
  header: React.ReactNode;
  sidePanel?: React.ReactNode;
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [resizeDirection, setResizeDirection] = useState<ResizeDirection | null>(null);

  // State to keep track of the popup's position
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: window.innerWidth / 2 - 140, // Center horizontally (140 is half of the default width)
    y: window.innerHeight / 2 - 137.5, // Center vertically (137.5 is half of the default height)
  });

  const [drag, setDrag] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    setSize(defaultSize);
  }, [defaultSize]);

  useEffect(() => {
    setSize(sidePanel ? { width: 600, height: 540 } : defaultSize);
  }, [sidePanel]);

  const [size, setSize] = useState({ width: 280, height: 275 });

  // Ref to store the initial mouse position when dragging starts
  const startPos = useRef<{ x: number; y: number }>({ x: 0, y: 0 });

  // Ref to store the popup element
  const popupRef = useRef<HTMLDivElement | null>(null);

  // Function to handle mouse movement while dragging
  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isDragging) {
        e.preventDefault();
        setPosition({
          x: e.clientX - startPos.current.x,
          y: e.clientY - startPos.current.y,
        });
      } else if (resizeDirection) {
        resizeFrame(e);
      } else {
        return;
      }
    },
    [isDragging, resizeDirection]
  );

  // Function to handle the end of a drag event
  const onMouseUp = () => {
    setIsDragging(false);
    setResizeDirection(null);
  };

  // Function to handle the start of a drag event
  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsDragging(true);
    startPos.current = { x: e.clientX - position.x, y: e.clientY - position.y };
  };

  // Effect to add and clean up event listeners for dragging
  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
    return () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, [onMouseMove]);

  const startResize = (
    e: React.MouseEvent<HTMLDivElement>,
    direction: ResizeDirection
  ) => {
    e.stopPropagation();
    setResizeDirection(direction);
    setDrag({
      x: e.clientX,
      y: e.clientY,
    });
  };

  const resizeFrame = (e: MouseEvent) => {
    const { x, y } = drag;
    if (resizeDirection) {
      const xDiff = Math.abs(x - e.clientX);
      const yDiff = Math.abs(y - e.clientY);

      let data = { width: 0, height: 0, x: position.x, y: position.y };
      if (resizeDirection === ResizeDirection.TOP_LEFT) {
        data = getTopLeftPositions(xDiff, yDiff, e);
      }
      if (resizeDirection === ResizeDirection.TOP_RIGHT) {
        data = getTopRightPositions(xDiff, yDiff, e);
      }

      if (resizeDirection === ResizeDirection.BOTTOM_LEFT) {
        data = getBottomLeftPositions(xDiff, yDiff, e);
      }
      if (resizeDirection === ResizeDirection.BOTTOM_RIGHT) {
        data = getBottomRightPositions(xDiff, yDiff, e);
      }

      setDrag({ x: e.clientX, y: e.clientY });
      setSize({ width: data.width, height: data.height });
      if (position.x !== data.x || position.y !== data.y) {
        setPosition({ x: data.x, y: data.y });
      }
    }
  };

  const getTopRightPositions = (xDiff: number, yDiff: number, e: MouseEvent) => {
    const width = drag.x > e.clientX ? size.width - xDiff : size.width + xDiff;
    const height = drag.y > e.clientY ? size.height + yDiff : size.height - yDiff;
    const y = drag.y > e.clientY ? position.y - yDiff : position.y + yDiff;

    return { width, height, x: position.x, y };
  };

  const getTopLeftPositions = (xDiff: number, yDiff: number, e: MouseEvent) => {
    const width = drag.x > e.clientX ? size.width + xDiff : size.width - xDiff;
    const height = drag.y > e.clientY ? size.height + yDiff : size.height - yDiff;
    const x = drag.x > e.clientX ? position.x - xDiff : position.x + xDiff;
    const y = drag.y > e.clientY ? position.y - yDiff : position.y + yDiff;

    return { width, height, x, y };
  };

  const getBottomLeftPositions = (xDiff: number, yDiff: number, e: MouseEvent) => {
    const width = drag.x > e.clientX ? size.width + xDiff : size.width - xDiff;
    const height = drag.y > e.clientY ? size.height - yDiff : size.height + yDiff;
    const x = drag.x > e.clientX ? position.x - xDiff : position.x + xDiff;

    return { width, height, x, y: position.y };
  };

  const getBottomRightPositions = (xDiff: number, yDiff: number, e: MouseEvent) => {
    const width = drag.x > e.clientX ? size.width - xDiff : size.width + xDiff;
    const height = drag.y > e.clientY ? size.height - yDiff : size.height + yDiff;

    return { width, height, x: position.x, y: position.y };
  };

  return (
    <Flex
      data-testid="voip-dialog"
      ref={popupRef}
      css={{
        width: size.width || 'auto',
        height: size.height || 'auto',
        position: 'absolute',
        top: position.y,
        left: position.x,
        zIndex: 200,
        background: '#1B1B1F',
        boxShadow:
          '0 12px 28px rgba(0, 0, 0, 0.5), 0 0 0 0.5px rgba(0, 0, 0, 0.5), inset 0 0 0 1px rgba(0, 0, 0, 0.15)',
        borderRadius: 8,
        color: '#ADB1B8',
        ...(resizable && defaultStyles),
      }}
    >
      {resizable && (
        <>
          <Box
            onMouseDown={(e) => startResize(e, ResizeDirection.TOP_LEFT)}
            style={{
              background: 'transparent',
              width: 10,
              height: 10,
              position: 'absolute',
              top: 0,
              left: 0,
              cursor: 'nwse-resize',
            }}
          ></Box>
          <Box
            onMouseDown={(e) => startResize(e, ResizeDirection.TOP_RIGHT)}
            style={{
              background: 'transparent',
              width: 10,
              height: 10,
              position: 'absolute',
              top: 0,
              right: 0,
              cursor: 'nesw-resize',
            }}
          ></Box>
          <Box
            onMouseDown={(e) => startResize(e, ResizeDirection.BOTTOM_LEFT)}
            style={{
              background: 'transparent',
              width: 10,
              height: 10,
              position: 'absolute',
              bottom: 0,
              left: 0,
              cursor: 'nesw-resize',
            }}
          ></Box>
          <Box
            onMouseDown={(e) => startResize(e, ResizeDirection.BOTTOM_RIGHT)}
            style={{
              background: 'transparent',
              width: 10,
              height: 10,
              position: 'absolute',
              bottom: 0,
              right: 0,
              cursor: 'nwse-resize',
            }}
          ></Box>
        </>
      )}
      <Box css={{ width: sidePanel ? 'auto' : '100%' }}>
        <Box onMouseDown={onMouseDown}>{header}</Box>
        <Box css={{ height: 'calc(100% - 53px)' }}>{children}</Box>
      </Box>
      {!!sidePanel && <Box css={{ borderLeft: '1px solid #D4E4FE31' }}>{sidePanel}</Box>}
    </Flex>
  );
};

export const CallHeader = styled(Flex, {
  cursor: 'grab',
  px: 12,
  pb: 8,
  pt: 12,
  fontSize: 12,
  fontWeight: 500,
  borderBottom: '1px solid #D4E4FE31',
});
