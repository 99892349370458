import 'reactflow/dist/style.css';

import dagre from 'dagre';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactFlow, {
  Background,
  Controls,
  Edge,
  Handle,
  Node,
  Position,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from 'reactflow';

import * as SequencesAPI from '@/shared/api/sequences';

// -------------------------------------------------------
// TYPES & DEFAULT DATA
// -------------------------------------------------------

interface Step {
  id: string;
  position: number;
  title: string;
  body: string;
  schedule_options: {
    days: string;
    hours: string;
    minutes: string;
    timezone?: string;
  };
  triggers: TriggerWrapper[];
}

interface TriggerWrapper {
  id: string;
  metadata: any;
  trigger: Trigger;
  inserted_at: string;
}

interface Trigger {
  id: string;
  type: 'reply_containing_keyword' | 'reply' | 'no_reply';
  keyword?: {
    enabled: boolean;
    id: string;
    keywords: string[];
    body: string | null;
    inserted_at: string;
    updated_at: string;
    exact_match: boolean;
    organization_id: string;
    negate_keywords: string[];
  };
  actions: Action[];
  inserted_at: string;
  updated_at: string;
  locations: any;
  schedule_options: {
    days?: string;
    hours?: string;
    minutes?: string;
  };
}

interface Action {
  enabled: boolean;
  id: string;
  type: 'move_to_step' | 'remove_from_sequence' | 'send_message';
  metadata: any;
  params: {
    sequence_id?: string;
    step_id?: string;
    body?: string;
    attachment_urls?: string[];
    attachments?: any[];
  };
  inserted_at: string;
  updated_at: string;
  organization_id: string;
  approval_required: boolean;
  approval_required_by_id: string | null;
}

// A simple unique ID generator.
const generateUniqueId = () => `${Date.now()}-${Math.floor(Math.random() * 10000)}`;

// Example default data (v2)
export const example_steps_v2: Step[] = [
  {
    id: '8e0ca702-91f0-4b92-94ac-a70b06054379',
    position: 0,
    title: 'Welcome',
    body: 'Hi, are you available to work tomorrow?',
    schedule_options: {
      days: '1',
      hours: '0',
      minutes: '0',
      timezone: 'America/Detroit',
    },
    triggers: [
      {
        id: '8a626893-025c-466b-81f8-b6875e26a5e6',
        metadata: null,
        inserted_at: new Date().toISOString(),
        trigger: {
          id: '8a626893-025c-466b-81f8-b6875e26a5e6',
          type: 'reply_containing_keyword',
          keyword: {
            enabled: true,
            id: generateUniqueId(),
            keywords: ['YES'],
            body: null,
            inserted_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            exact_match: false,
            organization_id: '',
            negate_keywords: [],
          },
          actions: [
            {
              type: 'move_to_step',
              enabled: true,
              id: generateUniqueId(),
              metadata: null,
              params: {
                sequence_id: '3cf27fbe-1d7e-4a72-80c5-67a7c91de954',
                step_id: '97210949-ae4f-44af-ac9f-8fe5441e18c8',
              },
              inserted_at: new Date().toISOString(),
              updated_at: new Date().toISOString(),
              organization_id: '',
              approval_required: false,
              approval_required_by_id: null,
            },
          ],
          inserted_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          locations: null,
          schedule_options: {},
        },
      },
      {
        id: '16d50351-53cb-4bf1-93d6-3d409f34b490',
        metadata: null,
        inserted_at: new Date().toISOString(),
        trigger: {
          id: '16d50351-53cb-4bf1-93d6-3d409f34b490',
          type: 'no_reply',
          actions: [
            {
              type: 'send_message',
              enabled: true,
              id: generateUniqueId(),
              metadata: null,
              params: {
                body: 'Hey, just following up to see if you are available?',
                attachment_urls: [],
              },
              inserted_at: new Date().toISOString(),
              updated_at: new Date().toISOString(),
              organization_id: '',
              approval_required: false,
              approval_required_by_id: null,
            },
          ],
          inserted_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          locations: null,
          schedule_options: {
            days: '1',
            hours: '0',
            minutes: '0',
          },
        },
      },
    ],
  },
  {
    id: '97210949-ae4f-44af-ac9f-8fe5441e18c8',
    position: 1,
    title: 'Step 2',
    body: 'Great, please be on site for 8am',
    schedule_options: {
      days: '1',
      hours: '0',
      minutes: '0',
      timezone: 'America/Detroit',
    },
    triggers: [],
  },
];

// -------------------------------------------------------
// CUSTOM NODE COMPONENTS
// -------------------------------------------------------

// StepNode component with inline styles instead of Tailwind
function StepNode({
  data,
}: {
  data: {
    title: string;
    body: string;
    stepId: string;
    addTrigger: (stepId: string, triggerType: string) => void;
    onDelete: (stepId: string) => void;
  };
}) {
  const containerStyle = {
    backgroundColor: 'white',
    padding: '16px',
    width: '280px',
    height: '135px',
    border: '1px solid #e5e7eb',
    borderRadius: '6px',
    position: 'relative' as const,
  };

  const deleteButtonStyle = {
    position: 'absolute' as const,
    top: '8px',
    right: '8px',
    color: '#ef4444',
    cursor: 'pointer',
  };

  const titleStyle = {
    fontWeight: '500',
    fontSize: '14px',
    marginBottom: '8px',
  };

  const bodyStyle = {
    fontSize: '14px',
    color: '#4b5563',
    marginBottom: '8px',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical' as const,
  };

  const buttonContainerStyle = {
    display: 'flex',
    gap: '8px',
    marginBottom: '8px',
  };

  const buttonBaseStyle = {
    padding: '4px 8px',
    fontSize: '12px',
    color: 'white',
    borderRadius: '4px',
    cursor: 'pointer',
    border: 'none',
  };

  return (
    <div style={containerStyle}>
      <button onClick={() => data.onDelete(data.stepId)} style={deleteButtonStyle}>
        ×
      </button>
      <div style={titleStyle}>{data.title}</div>
      <div style={bodyStyle}>{data.body}</div>
      <div style={buttonContainerStyle}>
        <button
          style={{ ...buttonBaseStyle, backgroundColor: '#3b82f6' }}
          onClick={() => data.addTrigger(data.stepId, 'reply_containing_keyword')}
        >
          + Keyword
        </button>
        <button
          style={{ ...buttonBaseStyle, backgroundColor: '#22c55e' }}
          onClick={() => data.addTrigger(data.stepId, 'reply')}
        >
          + Reply
        </button>
        <button
          style={{ ...buttonBaseStyle, backgroundColor: '#eab308' }}
          onClick={() => data.addTrigger(data.stepId, 'no_reply')}
        >
          + No Reply
        </button>
      </div>
      <Handle
        type="target"
        position={Position.Top}
        style={{ width: '8px', height: '8px', backgroundColor: '#ec4899' }}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ width: '8px', height: '8px', backgroundColor: '#ec4899' }}
      />
    </div>
  );
}

// ActionNode component shows trigger details and, if applicable, a button to add a new step.
function ActionNode({
  data,
}: {
  data: {
    type: string;
    keywords?: string[];
    schedule?: { days: string; hours: string; minutes: string };
    actionType: string;
    message?: string;
    parentStepId: string;
    triggerId: string;
    addStep?: (parentStepId: string, triggerId: string) => void;
    onDelete: (parentStepId: string, triggerId: string) => void;
  };
}) {
  const containerStyle = {
    backgroundColor: 'white',
    padding: '16px',
    width: '280px',
    height: '135px',
    border: '1px solid #e5e7eb',
    borderRadius: '6px',
    position: 'relative' as const,
  };

  const deleteButtonStyle = {
    position: 'absolute' as const,
    top: '8px',
    right: '8px',
    color: '#ef4444',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
  };

  const labelStyle = {
    fontWeight: '500',
    fontSize: '14px',
    marginBottom: '8px',
  };

  const descriptionStyle = {
    marginTop: '4px',
    color: '#4b5563',
  };

  const addStepButtonStyle = {
    marginTop: '8px',
    padding: '4px 8px',
    fontSize: '12px',
    backgroundColor: '#9333ea',
    color: 'white',
    borderRadius: '4px',
    cursor: 'pointer',
    border: 'none',
  };

  const handleStyle = {
    width: '6px',
    height: '6px',
    backgroundColor: '#ec4899',
  };

  const getActionLabel = () => {
    if (data.type === 'reply_containing_keyword') {
      return `If reply contains: ${data.keywords?.join(', ')}`;
    }
    if (data.type === 'no_reply') {
      const hours = Number.parseInt(data.schedule?.hours || '0');
      const minutes = Number.parseInt(data.schedule?.minutes || '0');
      return `If no reply after: ${data.schedule?.days}d ${hours}h ${minutes}m`;
    }
    if (data.type === 'reply') {
      return 'On any reply';
    }
    return data.type;
  };

  const getActionDescription = () => {
    if (data.actionType === 'move_to_step') {
      return '→ Next Step';
    }
    if (data.actionType === 'remove_from_sequence') {
      return '→ End Sequence';
    }
    if (data.actionType === 'send_message' && data.message) {
      return `Send: "${data.message}"`;
    }
    return '';
  };

  return (
    <div style={containerStyle}>
      <button
        onClick={() => data.onDelete(data.parentStepId, data.triggerId)}
        style={deleteButtonStyle}
      >
        ×
      </button>
      <div style={labelStyle}>{getActionLabel()}</div>
      {getActionDescription() && (
        <div style={descriptionStyle}>{getActionDescription()}</div>
      )}
      {data.addStep && (
        <button
          style={addStepButtonStyle}
          onClick={() => data.addStep!(data.parentStepId, data.triggerId)}
        >
          + Add Step
        </button>
      )}
      <Handle type="target" position={Position.Top} style={handleStyle} />
      <Handle type="source" position={Position.Bottom} style={handleStyle} />
    </div>
  );
}

// -------------------------------------------------------
// LAYOUT & TRANSFORMATION FUNCTIONS
// -------------------------------------------------------

const nodeWidth = 280;
const nodeHeight = 80;

// Lay out nodes using dagre.
function getLayoutedElements(nodes: Node[], edges: Edge[]) {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({
    rankdir: 'TB',
    nodesep: 120,
    ranksep: 200,
    ranker: 'network-simplex',
  });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const layoutedNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    return {
      ...node,
      position: {
        x: nodeWithPosition.x,
        y: nodeWithPosition.y - nodeHeight,
      },
    };
  });

  return { nodes: layoutedNodes, edges };
}

// Transform our workflow data (steps) into React Flow nodes and edges.
// We inject callbacks for adding triggers/steps into the node data.
function transformToNodesAndEdges(
  steps: Step[],
  callbacks: {
    addTriggerToStep: (stepId: string, triggerType: string) => void;
    addStepToAction: (parentStepId: string, triggerId: string) => void;
    deleteStep: (stepId: string) => void;
    deleteTrigger: (stepId: string, triggerId: string) => void;
  }
) {
  const nodes: Node[] = [];
  const edges: Edge[] = [];

  steps.forEach((step) => {
    // Create a step node.
    nodes.push({
      id: step.id,
      type: 'stepNode',
      position: { x: 0, y: 0 },
      data: {
        title: step.title,
        body: step.body,
        stepId: step.id,
        addTrigger: callbacks.addTriggerToStep,
        onDelete: callbacks.deleteStep,
      },
    });

    // Create action nodes for each trigger wrapper
    step.triggers.forEach((triggerWrapper) => {
      const trigger = triggerWrapper.trigger;
      const actionNodeId = `action-${triggerWrapper.id}`;
      nodes.push({
        id: actionNodeId,
        type: 'actionNode',
        position: { x: 0, y: 0 },
        data: {
          type: trigger.type,
          keywords: trigger.keyword?.keywords,
          schedule: trigger.schedule_options,
          actionType: trigger.actions[0]?.type,
          message: trigger.actions.find((a) => a.type === 'send_message')?.params.body,
          parentStepId: step.id,
          triggerId: triggerWrapper.id, // Use wrapper ID
          addStep:
            trigger.actions[0]?.type === 'move_to_step'
              ? callbacks.addStepToAction
              : null,
          onDelete: callbacks.deleteTrigger,
        },
      });

      // Connect step node to action node.
      edges.push({
        id: `edge-${step.id}-${actionNodeId}`,
        source: step.id,
        target: actionNodeId,
        type: 'smoothstep',
      });

      // If the trigger's move_to_step action points to a step, connect them
      const moveToStepAction = trigger.actions.find((a) => a.type === 'move_to_step');
      if (moveToStepAction?.params.step_id) {
        edges.push({
          id: `edge-${actionNodeId}-${moveToStepAction.params.step_id}`,
          source: actionNodeId,
          target: moveToStepAction.params.step_id,
          type: 'step',
        });
      }
    });
  });

  try {
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      nodes,
      edges
    );
    return { nodes: layoutedNodes, edges: layoutedEdges };
  } catch (error) {
    console.error('Error in transformToNodesAndEdges:', error);
    return { nodes, edges };
  }
}

// -------------------------------------------------------
// SIDE PANEL COMPONENT FOR EDITING NODE DATA
// -------------------------------------------------------

interface SidePanelProps {
  selectedNode: Node | null;
  workflowData: Step[];
  updateStep: (stepId: string, newData: Partial<Step>) => void;
  updateTrigger: (stepId: string, triggerId: string, newData: Partial<Trigger>) => void;
  closePanel: () => void;
}

function SidePanel({
  selectedNode,
  workflowData,
  updateStep,
  updateTrigger,
  closePanel,
}: SidePanelProps) {
  // Move useState and useEffect outside conditionals
  const [formData, setFormData] = useState({
    title: '',
    body: '',
    days: '',
    hours: '',
    minutes: '',
    timezone: '',
    keywords: '',
    message: '',
  });

  // Combined useEffect that handles both node types
  useEffect(() => {
    if (!selectedNode) return;

    if (selectedNode.type === 'stepNode') {
      const stepId = selectedNode.data.stepId;
      const step = workflowData.find((s) => s.id === stepId);
      if (!step) return;

      setFormData({
        title: step.title,
        body: step.body,
        days: step.schedule_options.days,
        hours: step.schedule_options.hours,
        minutes: step.schedule_options.minutes,
        timezone: step.schedule_options.timezone || '',
        keywords: '',
        message: '',
      });
    } else if (selectedNode.type === 'actionNode') {
      const { parentStepId, triggerId } = selectedNode.data;
      const step = workflowData.find((s) => s.id === parentStepId);
      if (!step) return;

      const triggerWrapper = step.triggers.find((t) => t.id === triggerId);
      if (!triggerWrapper) return;

      const trigger = triggerWrapper.trigger;

      setFormData({
        title: '',
        body: '',
        days: trigger.schedule_options?.days || '',
        hours: trigger.schedule_options?.hours || '',
        minutes: trigger.schedule_options?.minutes || '',
        timezone: '',
        keywords: trigger.keyword?.keywords?.join(', ') || '',
        message:
          trigger.actions?.find((a) => a.type === 'send_message')?.params?.body || '',
      });
    }
  }, [selectedNode, workflowData]);

  if (!selectedNode) return null;

  const panelStyle = {
    position: 'fixed' as const,
    right: '0',
    top: '0',
    height: '100%',
    width: '33.333333%',
    backgroundColor: '#f9fafb',
    borderLeft: '1px solid #e5e7eb',
    padding: '16px',
    overflowY: 'auto' as const,
  };

  const headingStyle = {
    fontSize: '20px',
    fontWeight: '600',
    marginBottom: '16px',
  };

  const inputStyle = {
    width: '100%',
    border: '1px solid #e5e7eb',
    padding: '4px 8px',
    marginTop: '4px',
  };

  const buttonStyle = {
    padding: '8px 16px',
    color: 'white',
    borderRadius: '4px',
    cursor: 'pointer',
    border: 'none',
    marginRight: '8px',
  };

  // Render form for a step node.
  if (selectedNode.type === 'stepNode') {
    const stepId = selectedNode.data.stepId;
    const step = workflowData.find((s) => s.id === stepId);
    if (!step) return <div className="p-4">Step not found.</div>;

    const handleChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      const { name, value } = e.target;
      setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleUpdate = () => {
      updateStep(stepId, {
        title: formData.title,
        body: formData.body,
        schedule_options: {
          days: formData.days,
          hours: formData.hours,
          minutes: formData.minutes,
          timezone: formData.timezone,
        },
      });
      closePanel();
    };

    return (
      <div style={panelStyle}>
        <h2 style={headingStyle}>Edit Step</h2>
        <label style={{ display: 'block', marginBottom: '8px' }}>
          Title:
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            style={inputStyle}
          />
        </label>
        <label style={{ display: 'block', marginBottom: '8px' }}>
          Body:
          <textarea
            name="body"
            value={formData.body}
            onChange={handleChange}
            style={inputStyle}
          />
        </label>
        <fieldset
          style={{
            marginBottom: '8px',
            padding: '8px',
            border: '1px solid #e5e7eb',
            borderRadius: '4px',
          }}
        >
          <legend style={{ padding: '4px 8px' }}>Schedule Options</legend>
          <label style={{ display: 'block', marginBottom: '4px' }}>
            Days:
            <input
              type="text"
              name="days"
              value={formData.days}
              onChange={handleChange}
              style={inputStyle}
            />
          </label>
          <label style={{ display: 'block', marginBottom: '4px' }}>
            Hours:
            <input
              type="text"
              name="hours"
              value={formData.hours}
              onChange={handleChange}
              style={inputStyle}
            />
          </label>
          <label style={{ display: 'block', marginBottom: '4px' }}>
            Minutes:
            <input
              type="text"
              name="minutes"
              value={formData.minutes}
              onChange={handleChange}
              style={inputStyle}
            />
          </label>
          <label style={{ display: 'block', marginBottom: '4px' }}>
            Timezone:
            <input
              type="text"
              name="timezone"
              value={formData.timezone}
              onChange={handleChange}
              style={inputStyle}
            />
          </label>
        </fieldset>
        <button
          onClick={handleUpdate}
          style={{ ...buttonStyle, backgroundColor: '#22c55e' }}
        >
          Update Step
        </button>
        <button
          onClick={closePanel}
          style={{ ...buttonStyle, backgroundColor: '#6b7280' }}
        >
          Cancel
        </button>
      </div>
    );
  }

  // Render form for an action node (trigger).
  if (selectedNode.type === 'actionNode') {
    const { parentStepId, triggerId } = selectedNode.data;
    const step = workflowData.find((s) => s.id === parentStepId);
    if (!step) return <div className="p-4">Parent step not found.</div>;

    // Find the trigger wrapper that contains the trigger
    const triggerWrapper = step.triggers.find((t) => t.id === triggerId);
    if (!triggerWrapper) return <div className="p-4">Trigger not found.</div>;

    const trigger = triggerWrapper.trigger; // Get the actual trigger from the wrapper

    const handleChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      const { name, value } = e.target;
      setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleUpdate = () => {
      const newData: Partial<Trigger> = {};
      if (trigger.type === 'reply_containing_keyword') {
        newData.keyword = {
          enabled: true,
          id: trigger.keyword?.id || generateUniqueId(),
          keywords: formData.keywords.split(',').map((kw) => kw.trim()),
          body: null,
          inserted_at: trigger.keyword?.inserted_at || new Date().toISOString(),
          updated_at: new Date().toISOString(),
          exact_match: false,
          organization_id: trigger.keyword?.organization_id || '',
          negate_keywords: [],
        };
      }
      if (trigger.type === 'no_reply') {
        newData.schedule_options = {
          days: formData.days,
          hours: formData.hours,
          minutes: formData.minutes,
        };
      }
      // If the trigger has a send_message action, update its message
      if (trigger.actions?.some((a) => a.type === 'send_message')) {
        newData.actions = trigger.actions.map((action) =>
          action.type === 'send_message'
            ? {
                ...action,
                params: { ...action.params, body: formData.message },
              }
            : action
        );
      }
      updateTrigger(parentStepId, triggerId, newData);
      closePanel();
    };

    return (
      <div style={panelStyle}>
        <h2 style={headingStyle}>Edit Trigger</h2>
        {trigger.type === 'reply_containing_keyword' && (
          <label style={{ display: 'block', marginBottom: '8px' }}>
            Keywords (comma separated):
            <input
              type="text"
              name="keywords"
              value={formData.keywords}
              onChange={handleChange}
              style={inputStyle}
            />
          </label>
        )}
        {trigger.type === 'no_reply' && (
          <fieldset
            style={{
              marginBottom: '8px',
              padding: '8px',
              border: '1px solid #e5e7eb',
              borderRadius: '4px',
            }}
          >
            <legend style={{ padding: '4px 8px' }}>Schedule Options</legend>
            <label style={{ display: 'block', marginBottom: '4px' }}>
              Days:
              <input
                type="text"
                name="days"
                value={formData.days}
                onChange={handleChange}
                style={inputStyle}
              />
            </label>
            <label style={{ display: 'block', marginBottom: '4px' }}>
              Hours:
              <input
                type="text"
                name="hours"
                value={formData.hours}
                onChange={handleChange}
                style={inputStyle}
              />
            </label>
            <label style={{ display: 'block', marginBottom: '4px' }}>
              Minutes:
              <input
                type="text"
                name="minutes"
                value={formData.minutes}
                onChange={handleChange}
                style={inputStyle}
              />
            </label>
          </fieldset>
        )}
        {trigger.actions?.some((a) => a.type === 'send_message') && (
          <label style={{ display: 'block', marginBottom: '8px' }}>
            Message:
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              style={inputStyle}
            />
          </label>
        )}
        <button
          onClick={handleUpdate}
          style={{ ...buttonStyle, backgroundColor: '#22c55e' }}
        >
          Update Trigger
        </button>
        <button
          onClick={closePanel}
          style={{ ...buttonStyle, backgroundColor: '#6b7280' }}
        >
          Cancel
        </button>
      </div>
    );
  }

  return null;
}

// -------------------------------------------------------
// MAIN FLOW COMPONENT
// -------------------------------------------------------

const nodeTypes = {
  stepNode: StepNode,
  actionNode: ActionNode,
};

export function SequenceFlow() {
  const { id } = useParams<{ id: string }>();
  const [workflowData, setWorkflowData] = useState<Step[]>([]);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedNode, setSelectedNode] = useState<Node | null>(null);

  // Fetch and format data from API
  useEffect(() => {
    const fetchAndFormatData = async () => {
      if (!id) return;

      try {
        // Fetch steps
        const stepsData = await SequencesAPI.getSequenceSteps(id);

        // Create a map to store steps with their triggers
        const stepsWithTriggers: Step[] = stepsData.map((step) => ({
          id: step.id!,
          position: step.position || 0,
          title: step.title || 'Untitled Step',
          body: step.body || '',
          schedule_options: {
            days: step.schedule_options?.days || '0',
            hours: step.schedule_options?.hours || '0',
            minutes: step.schedule_options?.minutes || '0',
            timezone: step.schedule_options?.timezone,
          },
          triggers: [],
        }));

        // Fetch triggers for each step
        await Promise.all(
          stepsWithTriggers.map(async (step) => {
            if (!step.id) return;

            const triggerData = await SequencesAPI.getSequenceStepTriggers(id, step.id);

            console.log('triggerData', JSON.stringify(triggerData, null, 2));

            // Find and update the step with its triggers
            const stepIndex = stepsWithTriggers.findIndex((s) => s.id === step.id);
            if (stepIndex !== -1) {
              stepsWithTriggers[stepIndex].triggers = triggerData as TriggerWrapper[];
            }
          })
        );

        console.log('stepsWithTriggers', JSON.stringify(stepsWithTriggers, null, 2));
        setWorkflowData(stepsWithTriggers);
      } catch (error) {
        console.error('Error fetching sequence data:', error);
      }
    };

    fetchAndFormatData();
  }, [id]);

  // Callback to add a new trigger to a given step.
  const addTriggerToStep = (stepId: string, triggerType: string) => {
    const newTriggerId = generateUniqueId();
    const newTriggerWrapper: TriggerWrapper = {
      id: newTriggerId,
      metadata: null,
      inserted_at: new Date().toISOString(),
      trigger: {
        id: generateUniqueId(),
        type: triggerType as Trigger['type'],
        keyword:
          triggerType === 'reply_containing_keyword'
            ? {
                enabled: true,
                id: generateUniqueId(),
                keywords: [],
                body: null,
                inserted_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
                exact_match: false,
                organization_id: '', // You'll need to provide this
                negate_keywords: [],
              }
            : undefined,
        actions:
          triggerType === 'no_reply'
            ? [
                {
                  enabled: true,
                  id: generateUniqueId(),
                  type: 'send_message',
                  metadata: null,
                  params: {
                    body: 'New message',
                    attachment_urls: [],
                    attachments: [],
                  },
                  inserted_at: new Date().toISOString(),
                  updated_at: new Date().toISOString(),
                  organization_id: '', // You'll need to provide this
                  approval_required: false,
                  approval_required_by_id: null,
                },
              ]
            : [
                {
                  enabled: true,
                  id: generateUniqueId(),
                  type: 'move_to_step',
                  metadata: null,
                  params: { sequence_id: '', step_id: '' },
                  inserted_at: new Date().toISOString(),
                  updated_at: new Date().toISOString(),
                  organization_id: '', // You'll need to provide this
                  approval_required: false,
                  approval_required_by_id: null,
                },
              ],
        inserted_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        locations: null,
        schedule_options:
          triggerType === 'no_reply' ? { days: '0', hours: '1', minutes: '0' } : {},
      },
    };

    setWorkflowData((prev) =>
      prev.map((step) =>
        step.id === stepId
          ? { ...step, triggers: [...step.triggers, newTriggerWrapper] }
          : step
      )
    );
  };

  // Callback to add a new step via a "move_to_step" action.
  const addStepToAction = (parentStepId: string, triggerId: string) => {
    const newStepId = generateUniqueId();
    const newStep: Step = {
      id: newStepId,
      position: workflowData.length,
      title: 'New Step',
      body: 'Step details...',
      schedule_options: {
        days: '0',
        hours: '0',
        minutes: '0',
        timezone: 'America/Detroit',
      },
      triggers: [],
    };

    setWorkflowData((prev) => {
      // Update the trigger on the parent step.
      const updatedWorkflow = prev.map((step) => {
        if (step.id === parentStepId) {
          return {
            ...step,
            triggers: step.triggers.map((trigger) => {
              if (trigger.id === triggerId) {
                const updatedActions = trigger.trigger.actions.map((action) => {
                  if (action.type === 'move_to_step') {
                    return {
                      ...action,
                      params: { ...action.params, step_id: newStepId },
                    };
                  }
                  return action;
                });
                return {
                  ...trigger,
                  trigger: {
                    ...trigger.trigger,
                    actions: updatedActions,
                  },
                };
              }
              return trigger;
            }),
          };
        }
        return step;
      });
      return [...updatedWorkflow, newStep];
    });
  };

  // Handler to delete a step.
  const deleteStep = (stepId: string) => {
    setWorkflowData((prev) => {
      // Remove triggers in other steps that point to this step.
      const updatedWorkflow = prev.map((step) => ({
        ...step,
        triggers: step.triggers.filter(
          (trigger) =>
            !trigger.trigger.actions.some(
              (action) =>
                action.type === 'move_to_step' && action.params.step_id === stepId
            )
        ),
      }));
      // Remove the step itself.
      return updatedWorkflow.filter((step) => step.id !== stepId);
    });
  };

  // Handler to delete a trigger.
  const deleteTrigger = (stepId: string, triggerId: string) => {
    setWorkflowData((prev) =>
      prev.map((step) =>
        step.id === stepId
          ? {
              ...step,
              triggers: step.triggers.filter((trigger) => trigger.id !== triggerId),
            }
          : step
      )
    );
  };

  // Update function for step nodes.
  const updateStep = (stepId: string, newData: Partial<Step>) => {
    setWorkflowData((prev) =>
      prev.map((step) => (step.id === stepId ? { ...step, ...newData } : step))
    );
  };

  // Update function for trigger nodes.
  const updateTrigger = (
    stepId: string,
    triggerId: string,
    newData: Partial<Trigger>
  ) => {
    setWorkflowData((prev) =>
      prev.map((step) => {
        if (step.id === stepId) {
          return {
            ...step,
            triggers: step.triggers.map((trigger) =>
              trigger.id === triggerId ? { ...trigger, ...newData } : trigger
            ),
          };
        }
        return step;
      })
    );
  };

  // Add useMemo for transform function
  const transformedElements = useMemo(() => {
    try {
      return transformToNodesAndEdges(workflowData, {
        addTriggerToStep,
        addStepToAction,
        deleteStep,
        deleteTrigger,
      });
    } catch (error) {
      console.error('Error transforming data:', error);
      return { nodes: [], edges: [] };
    }
  }, [workflowData]); // Only recompute when workflowData changes

  // Update useEffect to use memoized result
  useEffect(() => {
    setNodes(transformedElements.nodes);
    setEdges(transformedElements.edges);
  }, [transformedElements, setNodes, setEdges]);

  // When a node is clicked, open the side panel.
  const onNodeClick = (_event: React.MouseEvent, node: Node) => {
    setSelectedNode(node);
  };

  return (
    <ReactFlowProvider>
      <div style={{ width: '100%', height: '100vh' }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onNodeClick={onNodeClick}
          nodeTypes={nodeTypes}
          fitView
          fitViewOptions={{ padding: 0.2 }}
          minZoom={0.5}
          maxZoom={1.5}
          nodesDraggable={false}
          defaultEdgeOptions={{ type: 'smoothstep' }}
        >
          <Controls />
          <Background color="#aaa" gap={16} />
        </ReactFlow>
        <SidePanel
          selectedNode={selectedNode}
          workflowData={workflowData}
          updateStep={updateStep}
          updateTrigger={updateTrigger}
          closePanel={() => setSelectedNode(null)}
        />
      </div>
    </ReactFlowProvider>
  );
}
